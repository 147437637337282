import { Button } from "@mui/material";
import { ChangeClient } from "assets/icon";
import { useTranslation } from "react-i18next";

type ChangeClientButtonProps = {
  handleChangeClient: () => void;
  disabled?: boolean;
};

const ChangeClientButton = ({ handleChangeClient, disabled = false }: ChangeClientButtonProps) => {
  const { t } = useTranslation("appointments");

  return (
    <Button
      disabled={disabled}
      variant="text"
      color="primary"
      onClick={handleChangeClient}
      endIcon={<ChangeClient />}
    >
      {t("changeClient")}
    </Button>
  );
};

export default ChangeClientButton;
