import { Box, Grid, Typography, styled } from "@mui/material";
import { ItemPrice } from "components/common";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { dontForwardProps } from "helpers/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActivePromotionsLoading,
  selectLatestPackageVariantActivePromotionByPackageVariantId,
  selectLatestServiceVariantActivePromotionByServiceVariantId,
} from "store/selectors";

const VariantItem = ({
  id,
  isPackage = false,
  title,
  subtitle,
  price,
  duration,
  serviceCount,
  color,
  isSelected,
  handleClick,
  outletId = 0,
  date = "",
  referenceCode = "",
}) => {
  const { t } = useTranslation(["translation", "appointments"]);

  const itemLatestPromotion = useSelector(
    isPackage
      ? selectLatestPackageVariantActivePromotionByPackageVariantId(id || "")
      : selectLatestServiceVariantActivePromotionByServiceVariantId(id || "")
  );

  const areItemLatestPromotionsFetchedLoading = useSelector(
    selectActivePromotionsLoading(outletId, date)
  );

  const shouldShowVariantName = title !== subtitle;

  const formattedDuration = getFormattedDuration(duration);

  const Title = () => (
    <Typography variant="h5" maxWidth="20vw">
      {title}

      <Typography pl={1} variant="secondary">
        {shouldShowVariantName && `${subtitle}, `}
        {serviceCount && `${serviceCount} ${t("services").toLowerCase()}, `}
        {formattedDuration}
      </Typography>

      <Typography fontSize={12} fontWeight={500} color="#8B94A4">
        {referenceCode}
      </Typography>
    </Typography>
  );

  const finalPrice =
    itemLatestPromotion && !areItemLatestPromotionsFetchedLoading
      ? getFinalPriceAfterDiscount({
          originalPrice: Number(price),
          discountValue: Number(itemLatestPromotion.value),
          discountOption: itemLatestPromotion.discountType,
        })
      : price;

  return (
    <VariantItemBox
      borderLeft={4}
      borderLeftColor={color}
      onClick={() => handleClick(finalPrice)}
      isSelected={isSelected}
      bgcolor={isSelected ? "bg.secondary" : "inherit"}
    >
      <Grid
        container
        borderBottom={2}
        borderBottomColor="#E7E9FF"
        px={1.5}
        py={2}
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
      >
        <Grid item md={9} xs={9}>
          <Title />
        </Grid>

        <Grid item md={3} xs={3} display="flex" justifyContent="flex-end" flexGrow={1}>
          <ItemPrice originalPrice={price} finalPrice={finalPrice} />
        </Grid>
      </Grid>
    </VariantItemBox>
  );
};

export default VariantItem;

const VariantItemBox = styled(
  Box,
  dontForwardProps("isSelected")
)(({ theme, isSelected }) => ({
  cursor: "pointer",
  backgroundColor: isSelected ? theme.palette.bg.secondary : "inherit",

  "&:hover": {
    backgroundColor: isSelected ? "#e0e0f5" : "#f2f2f2",
  },
}));
