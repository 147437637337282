import { DeleteX } from "@bookpeep/ui";
import { Box, Grid, IconButton, Typography, TypographyProps } from "@mui/material";
import { ItemPrice } from "components/common";

type PaymentSummaryItemProps = {
  title: string | React.ReactNode;
  titleDescription?: string | React.ReactNode;
  subtitle?: string;
  price?: number;
  disabled?: boolean;

  onRemove?: () => void;

  hasBoldTitle?: boolean;
  hasBoldPrice?: boolean;

  priceProps?: TypographyProps;

  isNegative?: boolean;
};

const PaymentSummaryItem = ({
  title,
  titleDescription,
  subtitle = "",
  price,
  onRemove,
  disabled = false,
  hasBoldTitle = false,
  hasBoldPrice = false,
  priceProps,
  isNegative = false,
}: PaymentSummaryItemProps) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        "&:not(:last-child)": {
          mb: 2,
          ".payment-details": {
            boxShadow: "divider",
          },
        },
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        className="payment-details"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems={subtitle ? "flex-start" : "center"}
        width="95%"
        pb={2}
      >
        <Box>
          <Typography variant="h5" fontWeight={hasBoldTitle ? 700 : 500} align="left">
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="h5" align="left" color="text.label" pt={1}>
              {subtitle}
            </Typography>
          )}
        </Box>

        {titleDescription && (
          <Typography variant="h5" fontWeight={hasBoldTitle ? 700 : 500} align="right">
            {titleDescription}
          </Typography>
        )}

        {price && (
          <ItemPrice
            originalPrice={price}
            finalPrice={price}
            shouldDisplayZeroAsFree={false}
            isNegative={isNegative}
            finalPriceProps={{
              variant: "h5",
              fontWeight: hasBoldPrice ? 700 : 500,
              color: "text.primary",
              align: "right",
              ...priceProps,
            }}
          />
        )}
      </Box>

      <Box width="5%" ml={2} textAlign="center" pb={2}>
        {onRemove && (
          <IconButton disabled={disabled} onClick={onRemove}>
            <DeleteX fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Grid>
  );
};

export default PaymentSummaryItem;
