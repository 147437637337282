import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { areasAdapter } from "./areasSlice";

const { selectById, selectAll } = areasAdapter.getSelectors((state: RootState) => state.areas);

export const selectAreaById = (id: number | string) => (state: RootState) => selectById(state, id);

export const selectAllAreas = selectAll;

export const selectAreasAreLoading = (state: RootState) => state.areas.status === SliceStatus.LOADING;
