import { Box } from "@mui/material";

type InvoiceSwitcherDotProps = {
  isActive: boolean;
  handleClick: () => void;
};

const InvoiceSwitcherDot = ({ isActive, handleClick }: InvoiceSwitcherDotProps) => {
  const diameter = isActive ? 12 : 8;

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: "50%",
        backgroundColor: isActive ? "primary.main" : "#CED2DA",
        display: "inline-block",
        margin: "0px 5px",
        cursor: "pointer",

        "&:hover": {
          boxShadow: (theme) => theme.shadows.deepCard,
          backgroundColor: isActive ? "primary.dark" : "text.placeholder",
        },
      }}
    />
  );
};

export default InvoiceSwitcherDot;
