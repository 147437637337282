import { RefinementCtx, ZodIssueCode } from "zod";

/**
 * This functions checks that string is a valid number
 *
 */

const isStringValidNumber = (field: any, ctx: RefinementCtx): void => {
  if (field) {
    const number = Number(field);

    const isNumber = !Number.isNaN(number);

    if (!isNumber)
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: [`${field}`],
        message: "custom.invalidNumber",
      });
  }
};

export default isStringValidNumber;
