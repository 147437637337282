import { ProductCategory } from "types/ProductCategory";
import http from "utils/http";

export type CreateProductCategory = {
  name: string;
  color: string;
  translations: {
    ar: { name?: string };
  };
};

export type ProductCategoryListResponse = {
  id: number;
  services: [];
  packages: [];
  products: { id: number }[];
  name: string;
  type: string;
  color: string;
  description: string;
  deprecated: boolean;
  position: number;
  organization: number;
}[];

const productCategoriesApi = {
  getAllProductCategories: async () =>
    http.get<ProductCategory[]>("organizations/product_categories/"),

  getProductCategory: async (id: number) =>
    http.get<ProductCategoryListResponse>(`organizations/product_categories/${id}/`),

  createCategory: async (data: CreateProductCategory) =>
    http.post<ProductCategory>("organizations/product_categories/", data),

  updateCategory: async ({ id, data }: { id: number, data: CreateProductCategory }) =>
    http.put<ProductCategory>(`organizations/product_categories/${id}/`, data),

  deleteCategory: async ({ id }: { id: number }) =>
    http.delete(`organizations/product_categories/${id}/`),
};

export default productCategoriesApi;
