import { ClientAddress } from "types/Client";
import http from "utils/http";

type CreateAddressPayload = Pick<
  ClientAddress,
  "area" | "block" | "building" | "floor" | "flatNumber" | "street" | "avenue" | "client"
>;

type GetClientAddressesPayload = {
  clientId: number;
};

const clientAddressesApi = {
  getClientAddresses: async ({ clientId }: GetClientAddressesPayload) =>
    http.get<ClientAddress[]>(`addresses/customer-addresses/?client_id=${clientId}`),

  createClientAddress: async (address: CreateAddressPayload) =>
    http.post<ClientAddress>(`addresses/customer-addresses/`, address),

  updateClientAddress: async (address: ClientAddress) =>
    http.put<ClientAddress>(`addresses/customer-addresses/${address.id}/`, address),

  deleteClientAddress: async (address: ClientAddress) =>
    http.delete(`addresses/customer-addresses/${address.id}/`),
};

export default clientAddressesApi;
