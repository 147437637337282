import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { updateAppointment } from "store/appointments/appointmentsSlice";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { PeepPayLink } from "types/PeepPayLink";

import { SliceStatus } from "../utils";
import api from "./peepPayLinksApi";

export const peepPayLinksAdapter = createEntityAdapter<PeepPayLink>({
  selectId: (peepPayLink) => peepPayLink.id,
});

const initialState = peepPayLinksAdapter.getInitialState<{
  status: SliceStatus;
  count: number;
  next: string;
  previous: string;
  totalPages: number;
  results: PeepPayLink[];
  totalAmount: number;
}>({
  status: SliceStatus.IDLE,
  count: 0,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
  totalAmount: 0,
});

export const getAllPeepPayLinks = createThunkFromApiWithType(
  "peepPayLinks/getAllPeepPayLinks",
  api.getPeepPayLinks
);

export const appendAllPeepPayLinks = createThunkFromApiWithType(
  "peepPayLinks/appendAllPeepPayLinks",
  api.getPeepPayLinks
);

export const peepPayLinksSlice = createSlice({
  name: "expenses",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllPeepPayLinks.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(getAllPeepPayLinks.fulfilled, (state, { payload }) => {
        peepPayLinksAdapter.setAll(state, payload.results);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
        state.status = SliceStatus.IDLE;
      })

      .addCase(getAllPeepPayLinks.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(appendAllPeepPayLinks.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(appendAllPeepPayLinks.fulfilled, (state, { payload }) => {
        peepPayLinksAdapter.upsertMany(state, payload.results);
        state.status = SliceStatus.IDLE;
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })

      .addCase(appendAllPeepPayLinks.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(updateAppointment.fulfilled, (state, { payload: { deposit = null } }) => {
        if (deposit?.peepPay) {
          peepPayLinksAdapter.updateOne(state, {
            id: deposit.peepPay.id,
            changes: { status: deposit.peepPay.status },
          });
        }
      });
  },
});

export default peepPayLinksSlice.reducer;
