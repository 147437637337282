import { Box, Typography } from "@mui/material";
import IndustryCategoryIcon from "components/Services/IndustryCategoryIcon";
import { theme } from "configs";
import { useTranslation } from "react-i18next";

import type { TopCategory } from "./ClientPerformance";

export const TopBookedCategories = ({ categories }: { categories: TopCategory[] }) => {
  const { t } = useTranslation("client");

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Typography
        variant="primary"
        sx={{ textTransform: "uppercase" }}
        color="#848FA1"
        fontWeight={700}
        letterSpacing="0.03em"
      >
        {t("topBookedServices")}
      </Typography>
      {categories.length ? (
        categories.map((category) => (
          <TopBookedCategoryItem key={category.name} category={category} />
        ))
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.label,
            fontWeight: "500",
          }}
        >
          {t("dataGeneratedAfterAppointment")}
        </Typography>
      )}
    </Box>
  );
};

export const TopBookedCategoryItem = ({ category }: { category: TopCategory }) => {
  const { t } = useTranslation("client");
  return (
    <Box display="flex" gap={1.5} justifyContent="space-between">
      <Box display="flex" gap={1}>
        <IndustryCategoryIcon industryCategoryId={category.industryCategory} />
        <Box>
          <Typography fontWeight={700}>{category.name}</Typography>
          <Typography color="#6C798F">{t("times", { count: category.bookings })}</Typography>
        </Box>
      </Box>
      <Box alignItems="flex-end" textAlign="right">
        <Typography variant="h5" color="#6C798F">
          {t("lastBooked")}
        </Typography>
        <Typography>
          {new Date(category.lastBookedOn).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </Typography>
      </Box>
    </Box>
  );
};
