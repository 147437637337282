import http from "utils/http";

const packageCategoriesApi = {
  getAllPackageCategories: async () => http.get("organizations/package_categories/"),

  getPackageCategory: async (id) => http.get(`organizations/package_categories/${id}/`),

  createPackageCategory: async ({ data }) => http.post("organizations/package_categories/", data),

  updatePackageCategory: async ({ id, data }) =>
    http.put(`organizations/package_categories/${id}/`, data),

  updatePackageCategoryPosition: async (data) =>
    http.put(`organizations/update_package_category_positions/`, data),

  removePackageCategory: async (id) => http.delete(`organizations/package_categories/${id}/`),
};

export default packageCategoriesApi;
