import { RootState } from "store";

import { peepTransactionsAdapter } from "./peepTransactionsSlice";

const { selectById, selectAll } = peepTransactionsAdapter.getSelectors(
  (state: RootState) => state.peepTransactions
);

export const selectAllPeepTransactions = selectAll;

export const selectPeepTransactionById = (id: string) => (state: RootState) => selectById(state, id);

export const selectPeepTransactionsByAppointmentId = (id: Nullable<number>) => (state: RootState) => {
  const peepTransactions = selectAllPeepTransactions(state);

  return peepTransactions.filter((peepTransaction) => peepTransaction.appointment === id);
};

