import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";

import { useCheckoutFormContext } from "./CheckoutFormContext";

type CheckoutMobileViewSwitcherProps = {};

const CheckoutMobileViewSwitcher = (props: CheckoutMobileViewSwitcherProps) => {
  const { t } = useTranslation("checkout");
  const { setCheckoutModalView, checkoutModalView } = useCheckoutModal();

  const { cartItemFields, handleSubmit } = useCheckoutFormContext();

  const handleChange = (_: React.MouseEvent<HTMLElement>, newView: CheckoutModalView) => {
    // enforce that at least one button must be active
    if (newView) setCheckoutModalView(newView);
  };

  const handleCheckoutValue = () => {
    return checkoutModalView === CheckoutModalView.AddItems
      ? CheckoutModalView.Cart
      : checkoutModalView;
  };

  const handleCheckoutClicked = () => {
    setCheckoutModalView(CheckoutModalView.Payment);
  };

  const isCartEmpty = !cartItemFields.length;

  return (
    <Box zIndex={900000} p={2} width="100%" boxShadow="topDivider">
      {checkoutModalView === CheckoutModalView.Cart && (
        <Box pb={2} mb={2} sx={{ borderBottom: "1px solid lightgray" }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleCheckoutClicked)}
            disabled={isCartEmpty}
          >
            {t("next")}
          </Button>
        </Box>
      )}

      <ToggleButtonGroup
        color="primary"
        value={checkoutModalView}
        exclusive
        onChange={handleChange}
        fullWidth
      >
        <ToggleButton value={CheckoutModalView.AddItems}>{t("addItems")}</ToggleButton>
        <ToggleButton disabled={!cartItemFields.length} value={handleCheckoutValue()}>
          {t("checkout")} ({cartItemFields.length})
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default CheckoutMobileViewSwitcher;
