import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const AuthBackground = styled(Box)(({ theme }) => ({
  backgroundImage: `url(/images/auth-bg.svg)`,
  backgroundSize: "contain",
  objectFit: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  minHeight: "100vh",
  position: "relative",
  bottom: 0,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

export default AuthBackground;
