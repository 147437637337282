import debounce from "@mui/utils/debounce";
import useIsMobileView from "hooks/useIsMobileView";
import { decamelize } from "humps";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "store";
import {
  appendSubscriptionPurchases,
  getSubscriptionPurchases,
} from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import {
  appendAllSubscriptionsData,
  getAllSubscriptionsData,
} from "store/slices/subscriptions/subscriptionsSlice";
import { SubscriptionSidebarStatusOptions } from "types/Subscription";

export const SubscriptionsStatusOptions = {
  Active: "Active",
  Inactive: "Inactive",
};

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type InitialStateType = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;

  statusTab: string;
  setStatusTab: Dispatch<SetStateAction<string>>;

  statusOptions: string[];
  setStatusOptions: Dispatch<SetStateAction<string[]>>;

  ordering: string[];
  setOrdering: Dispatch<SetStateAction<string[]>>;

  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  handleInputChange: (value: string) => void;

  handleLoadMore: (value: number) => void;

  handlePageChange: (value: number) => void;

  clearAllFilters: () => void;
};

const initialState = {
  page: 1,
  setPage: unimplementedFunction,

  statusTab: SubscriptionSidebarStatusOptions.allSubscriptions,
  setStatusTab: unimplementedFunction,

  statusOptions: [],
  setStatusOptions: unimplementedFunction,

  ordering: [],
  setOrdering: unimplementedFunction,

  searchQuery: "",
  setSearchQuery: unimplementedFunction,

  searchQueryAsParam: "",
  setSearchQueryAsParam: unimplementedFunction,

  handleInputChange: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  handlePageChange: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const SubscriptionsListContext = createContext<InitialStateType>(initialState);

export default SubscriptionsListContext;

type SubscriptionsListContextProviderProps = {
  children: ReactNode;
};

function SubscriptionsListContextProvider({ children }: SubscriptionsListContextProviderProps) {
  const dispatch = useDispatch();

  const isMobileView = useIsMobileView();

  const [page, setPage] = useState<number>(initialState.page);

  const [statusTab, setStatusTab] = useState<string>(initialState.statusTab);

  const [statusOptions, setStatusOptions] = useState<string[]>(initialState.statusOptions);

  const [ordering, setOrdering] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  // the query that will be sent to the backend request after debouncing
  const [searchQueryAsParam, setSearchQueryAsParam] = useState<string>(
    initialState.searchQueryAsParam
  );

  const triggerDebouncedFetch = useMemo(
    () =>
      debounce((searchQuery) => {
        setSearchQueryAsParam(searchQuery);
        setPage(1);
      }, 800),
    []
  );

  const fetchSubscriptions = useCallback(triggerDebouncedFetch, [triggerDebouncedFetch]);

  const handleInputChange = (newValue: string) => {
    setSearchQuery(newValue);
    fetchSubscriptions(newValue);
  };

  const handleLoadMore = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
    setStatusOptions([]);
  };

  useEffect(() => {
    const decamalizedSortingList = ordering.map(decamelize);
    const stringifiedSortingList = decamalizedSortingList.join(",");

    if (statusTab === SubscriptionSidebarStatusOptions.allSubscriptions) {
      if (isMobileView && page !== 1) {
        dispatch(
          appendAllSubscriptionsData({
            page,
            statusOptions,
            ordering: stringifiedSortingList,
            searchQuery: searchQueryAsParam,
          })
        );
      } else {
        dispatch(
          getAllSubscriptionsData({
            page,
            statusOptions,
            ordering: stringifiedSortingList,
            searchQuery: searchQueryAsParam,
          })
        );
      }
    } else {
      if (isMobileView && page !== 1) {
        dispatch(
          appendSubscriptionPurchases({
            page,
            ordering: stringifiedSortingList,
            searchQuery: searchQueryAsParam,
          })
        );
      } else {
        dispatch(
          getSubscriptionPurchases({
            page,
            ordering: stringifiedSortingList,
            searchQuery: searchQueryAsParam,
          })
        );
      }
    }
  }, [isMobileView, dispatch, page, statusOptions, ordering, searchQueryAsParam, statusTab]);

  return (
    <SubscriptionsListContext.Provider
      value={{
        page,
        setPage,

        statusTab,
        setStatusTab,

        statusOptions,
        setStatusOptions,

        ordering,
        setOrdering,

        searchQuery,
        setSearchQuery,

        handleInputChange,

        handleLoadMore,

        handlePageChange,

        clearAllFilters,
      }}
    >
      {children}
    </SubscriptionsListContext.Provider>
  );
}

export { SubscriptionsListContextProvider };
