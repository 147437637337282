import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from "axios";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";

function decamelizeFormData(originalFormData: FormData) {
  const decamelizedFormData = new FormData();
  for (const [key, value] of originalFormData.entries()) {
    decamelizedFormData.append(decamelize(key), value);
  }
  return decamelizedFormData;
}

const defaultRequestTransformers = (): AxiosRequestTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) return [];
  else if (transformRequest instanceof Array) return transformRequest;
  else return [transformRequest];
};

const decamelizeRequest: AxiosRequestTransformer = (data) => {
  if (data instanceof FormData) return decamelizeFormData(data);
  /**
   * By default, decamelize will only split words on capital letters.
   * To customize this behavior, use the split option.
   * For example, to treat numbers as uppercase use: /(?=[A-Z0-9])/
   * decamelize('nowhereLand1', { split: /(?=[A-Z0-9])/ }) //=> 'nowhere_land_1'
   *
   * https://github.com/domchristie/humps#humpsdecamelizestring-options
   */
  return decamelizeKeys(data, { split: /(?=[A-Z0-9])/ });
};

export function prepareRequestTransformers(
  transformer?: AxiosRequestTransformer | AxiosRequestTransformer[]
) {
  if (Array.isArray(transformer))
    return [...transformer, decamelizeRequest, ...defaultRequestTransformers()];
  else if (transformer) return [transformer, decamelizeRequest, ...defaultRequestTransformers()];
  return [decamelizeRequest, ...defaultRequestTransformers()];
}

const defaultResponseTransformers = (): AxiosResponseTransformer[] => {
  const { transformResponse } = axios.defaults;
  if (!transformResponse) return [];
  else if (transformResponse instanceof Array) return transformResponse;
  else return [transformResponse];
};

const camelizeResponse: AxiosResponseTransformer = (data) => camelizeKeys(data);

export function prepareResponseTransformers(
  transformer?: AxiosResponseTransformer | AxiosResponseTransformer[]
) {
  if (Array.isArray(transformer))
    return [...defaultResponseTransformers(), camelizeResponse, ...transformer];
  else if (transformer) return [...defaultResponseTransformers(), camelizeResponse, transformer];
  return [...defaultResponseTransformers(), camelizeResponse];
}

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
  transformRequest: prepareRequestTransformers(),
  transformResponse: prepareResponseTransformers(),
});

export default http;

export function setBearerToken(token: string | null) {
  if (token === null) delete http.defaults.headers.common["Authorization"];
  else http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const createFormData = (data: any) => {
  const blobs = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value instanceof Blob)
  );
  const nonBlobs = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => !(value instanceof Blob))
  );
  const decamelizedData = decamelizeKeys(nonBlobs);

  const formData = new FormData();

  Object.entries(decamelizedData).forEach(([key, value]) => {
    if (value === null) return;

    if (typeof value === "string") {
      formData.append(key, value);
    } else {
      const stringifiedValue = JSON.stringify(value);
      formData.append(key, stringifiedValue);
    }
  });
  Object.entries(blobs).forEach(([key, value]) => {
    if (value === null) return;
    // @ts-ignore
    formData.append(key, value);
  });

  return formData;
};
