import { Notification } from "types/Notification";
import http from "utils/http";

type NotificationListResponse = {
  notifications: Notification[];
  timestamp: string;
};

type GetNotificationsProps = {
  timestamp?: string;
};

type UpdateNotificationsStatusArguments = {
  notificationIds: number[];
};

const notificationsApi = {
  getNotifications: async ({ timestamp }: GetNotificationsProps) =>
    http.get<NotificationListResponse>(
      `notifications/notifications/${timestamp ? `?created_at__gte=${timestamp}` : ""}`
    ),

  updateNotificationsStatus: async ({ notificationIds }: UpdateNotificationsStatusArguments) =>
    http.put<Notification[]>("notifications/update/", { notificationIds }),
};

export default notificationsApi;
