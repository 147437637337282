import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { EditIcon } from "assets/icon";
import { StatusChip } from "components/common";
import getClientStatus from "helpers/getClientStatus";
import { getClientStatusIcon } from "helpers/getClientStatusIcon";
import { getClientStatusColor } from "helpers/styles";
import { memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "store";
import { getClient, selectClientById, selectIsClientLoading } from "store/clients/clientsSlice";
import { Client, ProcessedClient } from "types/Client";
import { CLIENT_LOYALTY_STATUS } from "types/ClientAnalytic";
import useNotify from "utils/notifications/useNotify";

import ChangeClientButton from "../ChangeClientButton";
import ClientSummary from "./ClientSummary";
import ExistingClientAdditionalInfo from "./ExistingClientAdditionalInfo";
import ExistingClientInfoSkeleton from "./ExistingClientInfoSkeleton";

type ExistingClientInfoProps = {
  clientId: number;

  handleChangeClient?: () => void;
  showChangeClientButton?: boolean;

  outletId?: number;

  shouldShowTags?: boolean;

  activateClientForm?: () => void;
  allowEdit?: boolean;

  existingClientActionButton?: React.ReactNode;
};

const ExistingClientInfo = ({
  clientId,

  handleChangeClient,
  showChangeClientButton = false,

  outletId,

  shouldShowTags = true,
  allowEdit = false,

  activateClientForm,
  existingClientActionButton,
}: ExistingClientInfoProps) => {
  const { t } = useTranslation(["translation", "appointments", "client"]);

  const dispatch = useDispatch();
  const notify = useNotify();

  const isFetchClientRequestLoading = useRef(false);

  if (showChangeClientButton && !handleChangeClient)
    throw new Error(
      "showChangeClientButton is set to true, you must pass handleChangeClient function to be able to change the client"
    );

  const fetchedClientIdRef = useRef<Nullable<number>>(null);

  const client = useSelector(selectClientById(clientId)) as ProcessedClient | undefined;

  useEffect(() => {
    // Fetch client if it's not already fetched or not in the store
    if (
      !isFetchClientRequestLoading.current &&
      (!client?.appointmentsLink || (client && client?.id !== clientId))
    ) {
      isFetchClientRequestLoading.current = true;

      dispatch(getClient(clientId))
        .unwrap()
        .then((clientResponse: Client) => {
          if (clientResponse) {
            fetchedClientIdRef.current = clientResponse.id;
          }
        })
        .catch((error) => notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error"))
        .finally(() => {
          isFetchClientRequestLoading.current = false;
        });
    }
  }, [client, clientId, dispatch, notify, t]);

  const isClientLoading = useSelector(selectIsClientLoading);

  if ((isClientLoading && clientId) || !client) return <ExistingClientInfoSkeleton />;

  const {
    fullName,
    phoneCountryCode,
    phoneNumber,
    nationalId,
    fileNumber,
    vip = false,
    blocked = false,
  } = client || {};

  const canShowChangeClientButton = showChangeClientButton && handleChangeClient;

  const clientStatus = getClientStatus({ vip, blocked });

  const shouldShowStatusChip = clientStatus !== "regular";

  return (
    <Grid container alignItems="center" spacing={1.5}>
      {client ? (
        <>
          <Grid item md={showChangeClientButton ? 6 : 12} xs={8}>
            <Box display="flex" flexDirection="row">
              <Box>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h2">{fullName}</Typography>

                  {client.isNew ? (
                    <Chip
                      label={t("newClient")}
                      color="primary"
                      size="small"
                      sx={{
                        color: "white",
                        fontWeight: 600,
                        maxWidth: "125px",
                        backgroundColor: (theme) => theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <>
                      {getClientStatusIcon(client.currentStatus)}

                      {client.reachedCancellationThreshold &&
                        getClientStatusIcon(CLIENT_LOYALTY_STATUS.CANCELLER)}

                      {client.reachedNoShowThreshold &&
                        getClientStatusIcon(CLIENT_LOYALTY_STATUS.NO_SHOWER)}
                    </>
                  )}

                  {shouldShowStatusChip && (
                    <Grid item flex={1}>
                      <StatusChip
                        label={t(clientStatus)}
                        statusColor={getClientStatusColor(clientStatus)}
                        sx={{ minWidth: { xs: "10%", md: "100%" } }}
                        size="small"
                      />
                    </Grid>
                  )}

                  {activateClientForm && allowEdit && (
                    <IconButton
                      size="small"
                      disableRipple
                      sx={{
                        padding: 0,
                      }}
                      onClick={() => {
                        activateClientForm();
                      }}
                    >
                      <EditIcon
                        sx={{ justifySelf: "flex-end", fontSize: "1.25rem", color: "#6C798F" }}
                      />
                    </IconButton>
                  )}
                </Stack>

                <Typography variant="h5" color="text.label">
                  {phoneCountryCode} {phoneNumber.replace(phoneCountryCode, "")}
                </Typography>
              </Box>
            </Box>

            {nationalId && (
              <ExistingClientAdditionalInfo
                title={t("client:forms.nationalId")}
                value={nationalId}
              />
            )}

            {fileNumber && (
              <ExistingClientAdditionalInfo
                title={t("client:forms.fileNumber")}
                value={fileNumber}
              />
            )}
          </Grid>

          {canShowChangeClientButton && (
            <Grid item md={6} xs={4} display="flex" justifyContent="flex-end">
              <ChangeClientButton handleChangeClient={handleChangeClient} />
            </Grid>
          )}

          {shouldShowTags && <ClientSummary clientId={clientId} outletId={outletId} />}
          {existingClientActionButton && existingClientActionButton}
        </>
      ) : (
        <Grid item md={showChangeClientButton ? 6 : 12} xs={12}>
          <Typography variant="h2">{t("walkIn")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ExistingClientInfo);
