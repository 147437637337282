import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const EmptyGraphicSection = ({ description, pt = 10, pb = 10, width = "304px" }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={pt}
      pb={pb}
      gap={3}
    >
      <img alt="Empty data graphic" src="/images/emptyStateTable.svg" width={width} />

      <Typography variant="h3" fontWeight={500} color="text.label">
        {description}
      </Typography>
    </Box>
  );
};

export default EmptyGraphicSection;
