import http from "utils/http";

const ownersApi = {
  getAllOwners: async () => http.get("organizations/owners/"),

  getOwner: async (id) => http.get(`organizations/owners/${id}/`),

  createOwner: async (data) => http.post("organizations/owners/", data),

  updateOwner: async ({ id, data }) => http.put(`organizations/owners/${id}/`, data),

  removeOwner: async (id) => http.delete(`organizations/owners/${id}/`),

  getAllOwnersForExpenses: async () => http.get("organizations/expenses/owners/"),
};

export default ownersApi;
