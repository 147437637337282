import { Typography } from "@mui/material";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import { useTranslation } from "react-i18next";
import { AppointmentDrawerMode } from "store/slices/appointmentDrawer/appointmentDrawerSlice";

const AppointmentDrawerHeader = () => {
  const { t } = useTranslation("appointments");
  const { appointmentDrawerView } = useAppointmentDrawer();

  const getheaderTitle = () => {
    switch (appointmentDrawerView) {
      case AppointmentDrawerMode.ViewAppointment:
        return t("appointments:viewAppointment");

      case AppointmentDrawerMode.EditAppointment:
        return t("appointments:editAppointment");

      default:
        return t("appointments:createNewAppointment");
    }
  };

  return (
    <Typography variant="h3" p={3} boxShadow="divider">
      {getheaderTitle()}
    </Typography>
  );
};

export default AppointmentDrawerHeader;
