import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppointmentSource } from "types/Appointment";

export interface OnlineBookingChipProps {
  minimal?: boolean;
  source: AppointmentSource;
  size?: "small" | "medium";
}

const OnlineBookingChip = ({
  minimal = false,
  source = AppointmentSource.OB,
  size = "medium",
}: OnlineBookingChipProps) => {
  const { t } = useTranslation();

  const isOnlineBooking = source === AppointmentSource.OB;

  if (minimal) {
    return (
      <Chip
        variant="outlined"
        sx={{
          borderWidth: "3px",
          marginLeft: "3px",
          width: "10px",
          height: "10px",
          borderColor: (theme) => (isOnlineBooking ? theme.palette.primary.main : "#6CA1EA"),
        }}
      />
    );
  }
  return (
    <Chip
      label={`• ${t(isOnlineBooking ? "onlineBooking" : "appBooking")}`}
      color="primary"
      size="small"
      sx={{
        color: "white",
        fontWeight: 700,
        maxWidth: size === "small" ? "125px" : "auto",
        padding: (theme) => theme.spacing(0.5),
        backgroundColor: (theme) => (isOnlineBooking ? theme.palette.primary.main : "#6CA1EA"),
      }}
    />
  );
};

export default OnlineBookingChip;
