import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import type { CalendarView } from "pages/CalendarPage/CalendarPageContext";
import { DjangoDetailResponse } from "store/auth/authApi";
import { getRandomCategoryColor } from "utils/categoryColors";

import api, { UserPreference } from "./calendarPresetsApi";

const CALENDAR_PRESETS = "calendarPresets";
const OLD_CALENDAR_PRESETS = "calendar_presets";

type PresetEmployeesOrResources = {
  [key: string | number]: number[];
};

export type CalendarPreset = {
  outletId: number;
  zoneId?: number;
  employeeIds: number[];
  resourceIds?: number[];
  employees?: PresetEmployeesOrResources;
  resources?: PresetEmployeesOrResources;
  calendarView: CalendarView;
  slotRange?: number[];
  zoomLevel?: number;
  color: string;
  categoryIds: number[];
  numOfColumns?: number;
};

type CalendarPresetEntry = [id: string, values: CalendarPreset];
type PartialCalendarPresetEntry = [id: string, values: Partial<CalendarPreset>];

const unparsedStoredPresets = localStorage.getItem(CALENDAR_PRESETS);

const storedPresets: PartialCalendarPresetEntry[] = unparsedStoredPresets
  ? JSON.parse(unparsedStoredPresets)
  : [];

const initialState = storedPresets.map(
  ([id, preset]) => [id, { color: getRandomCategoryColor(), ...preset }] as CalendarPresetEntry
);

function saveIntoLocalStorage(state: CalendarPresetEntry[]) {
  localStorage.setItem(CALENDAR_PRESETS, JSON.stringify(state));
}

export const updateUserPreference = async (updateUserPreferenceArgs: UserPreference) => {
  try {
    const response = await api.updateUserPreference(updateUserPreferenceArgs);

    // The following code is needed to introduce color property to presets created before it existed
    // Basically put, this like allows you to apply "migrations" to the data
    // Also check line getUserPreferences
    // - Just a regular teammate
    const responseCalendarPresets = response.data?.settings?.calendarPresets.map(
      ([id, preset]) =>
        [id, { color: getRandomCategoryColor(), categoryIds: [], ...preset }] as CalendarPresetEntry
    );

    saveIntoLocalStorage(responseCalendarPresets || []);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      // TODO: fix later when implementing actual approach - Anfal (your favorite teammate) 💎
      return getErrorMessage(error.response);
    }
  }
};

export const getUserPreference = createAsyncThunk<
  CalendarPresetEntry[],
  {},
  { rejectValue: string }
>("preferences/getUserPreference", async (_, { rejectWithValue }) => {
  try {
    const response = await api.getUserPreference();
    const oldPresets = localStorage.getItem(OLD_CALENDAR_PRESETS);

    if (oldPresets) {
      const partialPresets: PartialCalendarPresetEntry[] = JSON.parse(oldPresets);

      const presets = partialPresets.map(
        ([id, preset]) =>
          [
            id,
            { color: getRandomCategoryColor(), categoryIds: [], ...preset },
          ] as CalendarPresetEntry
      );

      const userPreference = { settings: { calendarPresets: presets } };
      updateUserPreference(userPreference);

      saveIntoLocalStorage(presets);

      localStorage.removeItem(OLD_CALENDAR_PRESETS);
      return presets;
    } else {
      const responseCalendarPresets = response.data?.settings?.calendarPresets.map(
        ([id, preset]) => {
          // Note: outlet keys are stored with an underscore to get the correct value we had to remove it from employees and resources keys
          const processedEmployees: PresetEmployeesOrResources = {};

          const presetEmployees: PresetEmployeesOrResources = preset.employees || {};

          Object.entries(presetEmployees).forEach(
            ([key, value]) => (processedEmployees[key.replace("_", "")] = value)
          );

          const processedResources: PresetEmployeesOrResources = {};

          const presetResources: PresetEmployeesOrResources = preset.resources || {};

          Object.entries(presetResources).forEach(
            ([key, value]) => (processedResources[key.replace("_", "")] = value)
          );

          return [
            id,
            {
              color: getRandomCategoryColor(),
              categoryIds: [],
              ...preset,
              employees: processedEmployees,
              resources: processedResources,
            },
          ] as CalendarPresetEntry;
        }
      );

      saveIntoLocalStorage(responseCalendarPresets || []);
      return responseCalendarPresets || [];
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 400)
        return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

const calendarPresetSlice = createSlice({
  name: "calendarPresets",
  initialState,

  // TODO: wipe your tears 🤧 this is a temp solution until we come up with a better approach,
  // we tried alternative approaches and we didn't like any of them check this branch  https://github.com/bookpeep/partners/tree/PE-535/EXPERIMENTAL-user-preference - Anfal the Great and Amazing ❤️
  reducers: {
    // @ts-ignore
    setCalendarPreset(state, { payload }: PayloadAction<CalendarPresetEntry>) {
      const [id, presetValues] = payload;

      const indexToSet = state.findIndex((preset) => preset[0] === id);

      if (indexToSet === -1) state.push(payload);
      else state[indexToSet][1] = presetValues;

      updateUserPreference({ settings: { calendarPresets: state } });
    },
    // @ts-ignore
    deleteCalendarPreset(state, { payload }: PayloadAction<string>) {
      const indexToRemove = state.findIndex((preset) => preset[0] === payload);

      state.splice(indexToRemove, 1);

      updateUserPreference({ settings: { calendarPresets: state } });
    },
    // @ts-ignore
    renameCalendarPreset(
      state,
      { payload: { oldId, newId } }: PayloadAction<{ oldId: string; newId: string }>
    ) {
      const allIds = state.map((preset) => preset[0]);

      if (!allIds.includes(oldId))
        throw new Error("Can't rename Calendar Preset ID that doesn't exist");
      if (allIds.includes(newId)) throw new Error("Calendar Preset IDs must be unique");

      const indexToRename = state.findIndex((preset) => preset[0] === oldId);

      state[indexToRename][0] = newId;

      updateUserPreference({ settings: { calendarPresets: state } });
    },
    // @ts-ignore
    moveCalendarPresetForwards(state, { payload }: PayloadAction<string>) {
      const indexToMove = state.findIndex((preset) => preset[0] === payload);

      if (indexToMove >= state.length) return state;

      const pluckedOutEntry = state.splice(indexToMove, 1)[0];

      const newIndex = indexToMove + 1;

      state.splice(newIndex, 0, pluckedOutEntry);

      updateUserPreference({ settings: { calendarPresets: state } });
    },
    // @ts-ignore
    moveCalendarPresetBackwards(state, { payload }: PayloadAction<string>) {
      const indexToMove = state.findIndex((preset) => preset[0] === payload);

      if (indexToMove <= 1) return state;

      const pluckedOutEntry = state.splice(indexToMove, 1)[0];

      const newIndex = indexToMove - 1;

      state.splice(newIndex, 0, pluckedOutEntry);

      updateUserPreference({ settings: { calendarPresets: state } });
    },
  },

  extraReducers: (reducers) =>
    reducers.addCase(getUserPreference.fulfilled, (state, { payload }) => payload || state),
});

export default calendarPresetSlice.reducer;

export const {
  setCalendarPreset,
  deleteCalendarPreset,
  renameCalendarPreset,
  moveCalendarPresetForwards,
  moveCalendarPresetBackwards,
} = calendarPresetSlice.actions;
