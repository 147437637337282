import * as yup from "yup";

export default function resetPasswordSchema(t) {
  return yup
    .object({
      password: yup
        .string()
        .required(t("validation.password"))
        .max(255)
        .min(8, t("validation.minimumPassword", { min: 8 })),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], t("validation.confirmPassword"))
        .max(255)
        .min(8, t("validation.minimumPassword", { min: 8 })),
    })
    .required();
}
