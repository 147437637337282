import { Close } from "@bookpeep/ui";
import {
  IconButton,
  InputAdornment,
  Paper,
  Popper as RawPopper,
  TextField,
  styled,
} from "@mui/material";
import { ChangeEvent, FocusEvent, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectServiceById } from "store/slices/servicesV2/servicesSelectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";

import {
  OnSelectPackageVariantFunction,
  OnSelectServiceVariantFunction,
  ServicesSearchFieldContextProvider,
} from "./ServicesSearchFieldContext";
import SearchServicesTabsView from "./TabsAndPanels/SearchServicesTabsView";

type ServicesSearchFieldProps = {
  serviceVariantId: Nullable<number> | undefined;

  onSelectServiceVariant: OnSelectServiceVariantFunction;
  onSelectPackageVariant: OnSelectPackageVariantFunction;

  staffIdToFilterBy: Nullable<number>;
  resourceToFilterBy: Nullable<number> | undefined;

  disabled?: boolean;

  error?: boolean;
  helperText?: string;
};

const ServicesSearchField = ({
  serviceVariantId = null,

  onSelectServiceVariant,
  onSelectPackageVariant,

  staffIdToFilterBy,
  resourceToFilterBy,
  disabled = false,

  error,
  helperText,
}: ServicesSearchFieldProps) => {
  // Disclaimer: if you break the functionality of this component, your soul will be haunted by the dark side of your worst nightmares till the end of time #TrustMe.. and i won't be able to help you... and i won't be able to wipe your tears. You are on your own. You've been warned. - Anfal

  const { t } = useTranslation("appointments");

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const isOpen = Boolean(anchorEl);

  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery("");
  }, [serviceVariantId]);

  const selectedVariant = useSelector(selectServiceVariantById(serviceVariantId || 0));
  const service = useSelector(selectServiceById(selectedVariant?.service || 0));

  const selectedVariantTitle =
    service?.name !== selectedVariant?.name
      ? `${service?.name}, ${selectedVariant?.name}`
      : selectedVariant?.name;

  const openMenu = (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) =>
    setAnchorEl(event.currentTarget);

  // TODO: we need to change the query to the service name SO THAT THE LIST SHOWS FILTERED SERVICES including the selected service variant
  // useEffect(() => {
  //   if (service) {
  //     setQuery(service.name);
  //   }
  // }, [service]);

  const closeMenu = () => {
    if (anchorEl) anchorEl.blur();

    setAnchorEl(null);

    if (serviceVariantId) setQuery(service?.name || "");
  };

  const keepTextFieldFocused: MouseEventHandler<HTMLDivElement> | undefined = (event) => {
    event.preventDefault();

    if (anchorEl) anchorEl.focus();
  };

  const renderValue = () => {
    if (serviceVariantId) return selectedVariantTitle;
    return query;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const queryValue = event.target.value;

    // if there is a serviceVariantId, ignore changes. otherwise, set the query
    if (!serviceVariantId) setQuery(queryValue);
  };

  const showClearButton = (query || serviceVariantId) && !disabled;

  // TODO: clicking on clear IS CLOSING THE MENU.. It should NOT!!!!!!!!!
  const handleClear = () => {
    onSelectServiceVariant(null, 0, false);
    setQuery("");
  };

  return (
    <>
      <TextField
        disabled={disabled}
        label={t("service")}
        fullWidth
        placeholder={t("searchServicesPlaceholder")}
        value={renderValue()}
        onChange={handleChange}
        onFocus={openMenu}
        onBlur={closeMenu}
        error={error}
        helperText={helperText}
        autoComplete="off"
        InputProps={{
          endAdornment: showClearButton && (
            <InputAdornment position="end">
              <StyledIconButton onClick={handleClear}>
                <Close fontSize="small" />
              </StyledIconButton>
            </InputAdornment>
          ),
        }}
      />

      <Popper anchorEl={anchorEl} open={isOpen} placement="bottom-start">
        <MenuWrapper onMouseDown={keepTextFieldFocused}>
          <ServicesSearchFieldContextProvider
            values={{
              staffIdToFilterBy,
              resourceToFilterBy,
              query,
              setQuery,
              serviceVariantId,
              onSelectServiceVariant,
              onSelectPackageVariant,
              closeMenu,
            }}
          >
            <SearchServicesTabsView />
          </ServicesSearchFieldContextProvider>
        </MenuWrapper>
      </Popper>
    </>
  );
};

export default ServicesSearchField;

const Popper = styled(RawPopper)({ zIndex: 1234 });

const MenuWrapper = styled(Paper)(({ theme }) => ({
  minWidth: "calc(40vw - 2 * 80px)",
  maxHeight: "40vh",
  overflowY: "scroll",
  margin: theme.spacing(1, 0),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // @ts-expect-error
  color: theme.palette.common.gray + "24",
}));
