import type { RootState } from "store";
import { selectAllActiveOutletsIds, selectOutletById } from "store/outlets/outletsSlice";

import { SliceStatus } from "../utils";
import { outletStocksAdapter } from "./outletStocksSlice";

export const selectOutletStocksLoading = (state: RootState) =>
  state.outletStocks.status === SliceStatus.LOADING;

export const selectOutletStocksState = (state: RootState) => state.outletStocks;

const { selectAll } = outletStocksAdapter.getSelectors(selectOutletStocksState);

export const selectAllOutletStocks = selectAll;

export const selectOutletStocksByProductId = (id: number) => (state: RootState) => {
  const activeOutletIds = selectAllActiveOutletsIds(state);
  return selectAll(state)
    .filter(
      (outletStock) => outletStock.product === id && activeOutletIds.includes(outletStock.outlet)
    )
    .map((outletStock) => ({
      ...outletStock,
      outlet: selectOutletById(outletStock.outlet)(state),
    }));
};

export const selectBriefOutletStocksByProductId = (id: number) => (state: RootState) => {
  return selectAll(state).filter((outletStock) => outletStock.product === id);
};
