import { Typography } from "@mui/material";
import { ServicesIcon } from "assets/icon";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  checkIfAppointmentHasAtLeastOneService,
  getAppointmentServicesCount,
  getAppointmentUniqueStaffCount,
} from "../AppointmentFormSummary/AppointmentFormSummaryUtils";
import ServiceSection from "../ServiceSection";
import { AppointmentFormValues } from "../utils";
import AppointmentFormSectionActionButton from "./AppointmentFormSectionActionButton";
import HomeServiceAppointmentFormSection from "./HomeServiceAppointmentFormSection";

type HomeServicesAppointmentFormServicesSectionProps = {
  isExpanded: boolean;
  isDisabled: boolean;
  handleModifyStepClick: () => void;
};

const HomeServicesAppointmentFormServicesSection = ({
  isDisabled,
  isExpanded,
  handleModifyStepClick,
}: HomeServicesAppointmentFormServicesSectionProps) => {
  const { t } = useTranslation(["appointments", "translation"]);

  const { watch } = useFormContext<AppointmentFormValues>();

  const { articles } = watch();

  const doesAppointmentHaveAtLeastOneService =
    /* @ts-expect-error - NOTE: yup schema typing is crap.. it is causing some errors, will switch to zod when i have time for this*/
    checkIfAppointmentHasAtLeastOneService(articles) && !isExpanded;

  const handleModifyServicesClick = () => {
    handleModifyStepClick();
  };

  return (
    <HomeServiceAppointmentFormSection
      shouldHideTitle={isExpanded}
      title={t("services")}
      subtitle={
        <Typography variant="primary" fontSize="0.875" fontWeight={700}>
          {doesAppointmentHaveAtLeastOneService ? (
            <>
              {/* @ts-expect-error - NOTE: yup schema typing is crap.. it is causing some errors, will switch to zod when i have time for this */}
              {`${getAppointmentServicesCount(articles)} ${t("services")}`}

              <Typography variant="secondary" color="text.placeholder" fontWeight={500} px={0.5}>
                {/* @ts-expect-error - NOTE: yup schema typing is crap.. it is causing some errors, will switch to zod when i have time for this*/}
                {`${getAppointmentUniqueStaffCount(articles)} ${t("staff")}` || "-"}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Typography>
      }
      icon={<ServicesIcon viewBox="0 0 16 16" />}
      isDisabled={isDisabled}
      actionButton={
        !isExpanded &&
        doesAppointmentHaveAtLeastOneService && (
          <AppointmentFormSectionActionButton onClick={handleModifyServicesClick}>
            {t("appointments:modify")}
          </AppointmentFormSectionActionButton>
        )
      }
      children={isExpanded && <ServiceSection />}
    />
  );
};

export default HomeServicesAppointmentFormServicesSection;
