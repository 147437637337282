import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, Hidden, TextField, Typography } from "@mui/material";
import { AuthHeading, PasswordField } from "components/common";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login, selectAuth, selectAuthIsRequestPending } from "store/auth/authSlice";
import { loginSchema } from "utils/formValidation/schemas/login";
import useNotify from "utils/notifications/useNotify";

function Login() {
  const { t } = useTranslation(["translation", "authentication"]);

  const dispatch = useDispatch();

  const notify = useNotify();

  const isRequestPending = useSelector(selectAuthIsRequestPending);

  const authState = useSelector(selectAuth);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema(t)),
  });

  const submitLogin = () => {
    const { email, password } = getValues();
    dispatch(login({ username: email, password }))
      .unwrap()
      .then(() => {})
      .catch((error) => {
        if (error === "No active account found with the given credentials")
          notify(t("authentication:invalidCredentials"), "error");
        else notify(`${t("authentication:failedToLogin")} ${error ?? ""}`, "error");
      });
  };

  return (
    <form onSubmit={handleSubmit(submitLogin)}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={6} sm={10} xs={10} display="flex" height="100vh" flexDirection="column">
          <Box p={3} display="flex" justifyContent="flex-start">
            <img src="/images/Logo.svg" alt="peep logo" />
          </Box>

          <Grid
            container
            justifyContent="center"
            alignItems={{ xs: "center", md: "center" }}
            textAlign="center"
            spacing={3}
            pb={3}
            pt={{ md: 12, xs: 3 }}
          >
            <Grid item md={7} xs={12}>
              <AuthHeading sx={{ fontSize: "2.25rem" }}>{t("login.cardTitle")}</AuthHeading>

              {authState.error && (
                <Box pt={2}>
                  <Alert variant="filled" severity="error">
                    {authState.error}
                  </Alert>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <img alt="Reset Password" src="/images/auth-img.svg" width="130px" />
            </Grid>

            <Grid item md={7} xs={12}>
              <TextField
                {...register("email")}
                fullWidth
                label={t("login.form.email")}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </Grid>

            <Grid item md={7} xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <PasswordField
                    value={value}
                    onChange={onChange}
                    fullWidth
                    label={t("login.form.password")}
                    error={!!errors?.password}
                    helperText={errors?.password?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8} display="flex" flexDirection="row" justifyContent="center">
              <Typography component={Link} to="/forgot-password">
                {t("login.form.forgotPassword")}
              </Typography>
            </Grid>

            <Grid item md={7} xs={12}>
              <LoadingButton type="submit" fullWidth variant="contained" loading={isRequestPending}>
                {t("login.form.submit")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>

        <Hidden mdDown>
          <Grid item md={6} xs={12}>
            <Box
              minHeight="100vh"
              bgcolor="#D5DEFF"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img alt="Reset Password" src="/images/calendar.svg" width="70%" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </form>
  );
}

export default Login;
