import * as yup from "yup";

export const loginSchema = (t) => {
  return yup
    .object({
      email: yup
        .string()
        .email(t("login.validation.validEmail"))
        .required(t("mandatoryField", { fieldName: t("login.form.email") })),
      password: yup
        .string()
        .required(t("mandatoryField", { fieldName: t("login.form.password") })),
    })
    .required();
};

export default loginSchema;
