import { Box, Checkbox, styled } from "@mui/material";
import { ReactNode } from "react";

type SideBarFilterCheckboxProps = {
  label: ReactNode;
  value: string;
  isChecked: boolean;
  onClick: () => void;
  menu?: ReactNode;
  shouldShowMenu?: boolean;
};

const SideBarFilterCheckbox = ({
  label,
  value,
  isChecked,
  onClick,
  menu,
  shouldShowMenu = false,
}: SideBarFilterCheckboxProps) => {
  return (
    <FilterItem className="hoverItems">
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box gap={2} py={1} width="100%" sx={{ wordBreak: "break-all" }}>
          {label}
        </Box>

        {shouldShowMenu && menu}
      </Box>

      <Checkbox
        size="small"
        checked={isChecked}
        onClick={onClick}
        value={value}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "1.3rem",
            padding: 0,
            margin: 0,
            color: isChecked ? "#4048D6" : "#B3B6EF",
          },
        }}
      />
    </FilterItem>
  );
};

export default SideBarFilterCheckbox;

const FilterItem = styled(Box)(({ theme }) => ({
  width: "100%",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: theme.spacing(0.5),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),

  "&:hover": {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.text.secondary,
  },
}));
