import { ProcessedSubscriptionPurchase } from "types/Subscription";

import {
  getSortedSubscriptionsByEarliestExpiration,
  getSubscriptionsByItem,
} from "store/clients/utils";
import { PurchasableItem, PurchasableItemOption } from "types/PurchasableItem";
import { RedeemableSubscriptionItemOption } from "types/Subscription";
import { type CheckoutArticle } from "./CheckoutFormContext";

/**
 * Check how many credits of a subscription are being redeemed in the cart
 * @param subscriptionId
 * @param articles
 * @returns Total Credits Being Redeemed Of Subscription
 */

export const getTotalCreditsBeingRedeemedOfSubscription = (
  subscriptionId: number,
  articles: CheckoutArticle[]
) => {
  const totalOfSubscriptionCreditsBeingRedeemed = articles.reduce((total, article) => {
    // we check if the article has been booked with an applied subscription redemption, we don't count it because it has been already considered as redeemed on the backend
    if (article.subscriptionPurchaseId === subscriptionId && !article.subscriptionRedemptionId)
      return total + 1;
    else return total;
  }, 0);

  return totalOfSubscriptionCreditsBeingRedeemed;
};

/**
 * returns true if the subscription can be redeemed in the cart
 * @param subscription
 * @param articles
 * @returns
 */

export const checkIfCanRedeemSubscription = (
  subscription: ProcessedSubscriptionPurchase,
  articles: CheckoutArticle[]
) => {
  const totalOfSubscriptionCreditsBeingRedeemedInCart = getTotalCreditsBeingRedeemedOfSubscription(
    subscription.id,
    articles
  );

  const canRedeemCurrentLoopSubscription =
    subscription.remainingRedemptionsCount - totalOfSubscriptionCreditsBeingRedeemedInCart > 0;

  return canRedeemCurrentLoopSubscription;
};

/**
 * This function returns the default subscription to be applied to the cart, based on the following rules:
 * 1- If we have multiple subscriptions of the same type, we will apply the one that expires first.
 * 2- We consider if the subscription is being redeemed in the cart, we consider the remaining redemptions count and should not be applied if it is 0.
 *
 *
 * @param articles
 * @param clientActiveSubscriptions
 * @param purchasableItemId
 * @param redeemableSubscriptionItemOption
 * @returns
 */
export const getDefaultRedeemableSubscription = (
  articles: CheckoutArticle[],
  clientActiveSubscriptions: ProcessedSubscriptionPurchase[],
  purchasableItemId: PurchasableItem["id"],
  redeemableSubscriptionItemOption: RedeemableSubscriptionItemOption
): Nullable<number> => {
  const itemSubscriptions = getSubscriptionsByItem(
    clientActiveSubscriptions,
    purchasableItemId,
    redeemableSubscriptionItemOption
  );
  const sortedSubscriptionsByExpiryDate =
    getSortedSubscriptionsByEarliestExpiration(itemSubscriptions);

  const subscriptionToBeApplied = sortedSubscriptionsByExpiryDate.find((subscription) =>
    checkIfCanRedeemSubscription(subscription, articles)
  );

  return subscriptionToBeApplied?.id || null;
};

/**
 * This helper is used to convert the purchasable item type to the equivalent redeemable subscription item type, otherwise it returns null for unapplicable types.
 * @param purchasableItemType
 * @returns
 */

export const convertPurchasableItemTypeToRedeemableSubscriptionItemOption = (
  purchasableItemType: PurchasableItemOption
) => {
  if (purchasableItemType === PurchasableItemOption.Service)
    return RedeemableSubscriptionItemOption.ServiceVariants;

  if (purchasableItemType === PurchasableItemOption.Package)
    return RedeemableSubscriptionItemOption.PackageVariant;

  return null;
};
