import { Close } from "@bookpeep/ui";

import { StyledChip } from "./ChipStyles";

const StatusFilteredItem = ({ label, onDelete, chipColor }) => {
  return (
    <StyledChip onDelete={onDelete} label={label} deleteIcon={<Close />} chipColor={chipColor} />
  );
};

export default StatusFilteredItem;
