import { Close } from "@bookpeep/ui";
import { useSelector } from "store";
import { selectEmployeeFullNameById } from "store/employees/employeesSlice";

import { StyledChip } from "./ChipStyles";

type CreatedByFilteredItemProps = {
  employeeId: number;
  onDelete: (event: any) => void;
  chipColor: string;
};

const CreatedByFilteredItem = ({ employeeId, onDelete, chipColor }: CreatedByFilteredItemProps) => {
  const employeeFullName = useSelector(selectEmployeeFullNameById(employeeId));
  return (
    <StyledChip
      onDelete={onDelete}
      label={employeeFullName}
      deleteIcon={<Close />}
      chipColor={chipColor}
    />
  );
};

export default CreatedByFilteredItem;
