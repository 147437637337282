import { Chip, Stack, Typography } from "@mui/material";
import { LoyaltyGiftIcon } from "assets/icon";
import truncate from "helpers/truncate";
import { useTranslation } from "react-i18next";
type LoyaltyCardRewardChipProps = {
  programName: string;
};

const LoyaltyCardRewardChip = ({ programName }: LoyaltyCardRewardChipProps) => {
  const { t } = useTranslation("loyalty");

  return (
    <Chip
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <LoyaltyGiftIcon viewBox="0 0 16 16" sx={{ height: 16, width: 16 }} color="primary" />
          <Typography fontWeight={700} variant="secondary">
            {t("loyaltyCardReward", {
              programName: truncate({ string: programName, maxCharacters: 40 }).string,
            })}
          </Typography>
        </Stack>
      }
      color="primary"
      size="small"
      sx={{
        borderRadius: "8px",
        backgroundColor: "#D9DAF7",
        color: "primary.main",
      }}
    />
  );
};

export default LoyaltyCardRewardChip;
