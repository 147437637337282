import { Box, TextField } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPaymentsSummary from "../CheckoutPaymentsSummary";
import AddPaymentMethodCounter from "./AddPaymentMethodCounter";
import AddPaymentMethodsFooter from "./AddPaymentMethodsFooter";
import CheckoutPaymentMethods from "./CheckoutPaymentMethods";

const AddPaymentMethodsSection = () => {
  const { t } = useTranslation("checkout");
  const isMobileView = useIsMobileView();
  const { register, isRefundInvoice } = useCheckoutFormContext();

  return (
    <>
      <Box flexGrow={1} overflow="scroll">
        <AddPaymentMethodCounter title={t(isRefundInvoice ? "checkout:refund" : "checkout:pay")} />

        <CheckoutPaymentMethods />

        <Box p={3} pt={4}>
          <TextField
            multiline
            maxRows={4}
            fullWidth
            label={t("invoiceNotes")}
            placeholder={t("paymentNotes")}
            {...register(isRefundInvoice ? "refundInvoice.notes" : "notes")}
          />
        </Box>

        {isMobileView && (
          <Box px={3}>
            <CheckoutPaymentsSummary />
          </Box>
        )}
      </Box>

      <AddPaymentMethodsFooter />
    </>
  );
};

export default AddPaymentMethodsSection;
