import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { DateTime, Interval } from "luxon";
import { selectArticleWithAppointmentById } from "store/articles/articlesSlice";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./articleSlotsApi";

const articleSlotsAdapter = createEntityAdapter();

const initialState = articleSlotsAdapter.getInitialState({
  isLoading: false,
  error: "",

  optimisticCache: null,
});

const { selectById, selectAll } = articleSlotsAdapter.getSelectors((state) => state.articleSlots);

export const selectAllArticleSlots = selectAll;

export const selectAllArticleSlotsWithAppointments = (state) =>
  state.articleSlots.ids
    .map((id) => selectArticleSlotWithAppointmentById(id)(state))
    .filter((slot) => !!slot);

export const selectArticleSlotById = (id) => (state) => selectById(state, id);

export const selectArticleSlotWithAppointmentById = (id) => (state) => {
  const slot = selectById(state, id);
  if (!slot) return;

  const article = selectArticleWithAppointmentById(slot.article)(state);
  if (!article) return;

  return { ...slot, article };
};

export const selectArticleSlotLoading = (state) => state.isLoading;

export const selectArticleSlotsByDateAndDays = (date, daysAhead) => (state) => {
  const allArticleSlots = selectAllArticleSlotsWithAppointments(state);

  const minDate = date.startOf("day");
  const maxDate = minDate.plus({ days: daysAhead + 1 });

  const interval = Interval.fromDateTimes(minDate, maxDate);

  return allArticleSlots.filter((articleSlot) =>
    interval.contains(DateTime.fromISO(articleSlot.start))
  );
};

export const updateArticleSlot = createThunkFromApi(
  "articleSlots/updateArticleSlot",
  api.updateArticleSlot
);

export const articleSlotsSlice = createSlice({
  name: "articleSlots",
  initialState,
  reducers: {
    upsertArticleSlots(state, { payload }) {
      articleSlotsAdapter.upsertMany(state, payload);
    },
    removeArticleSlots(state, { payload }) {
      articleSlotsAdapter.removeMany(state, payload);
    },
  },
  extraReducers: {
    [updateArticleSlot.pending]: (state, { meta: { arg } }) => {
      const { id, ...changes } = arg;

      state.optimisticCache = selectArticleSlotById(id)({ articleSlots: state });
      articleSlotsAdapter.updateOne(state, { id, changes });
    },
    [updateArticleSlot.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updateArticleSlot.rejected]: (state) => {
      articleSlotsAdapter.setOne(state, state.optimisticCache);
      state.optimisticCache = null;
    },
  },
});

export const { upsertArticleSlots, removeArticleSlots } = articleSlotsSlice.actions;
export default articleSlotsSlice.reducer;
