import { Calendar } from "@bookpeep/ui";
import CustomIconButton from "components/common/Buttons/CustomIconButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DatePickerContent from "./DatePicker/DatePickerContent";
import DatePickerWrapper from "./DatePicker/DatePickerWrapper";
import { matchTimePeriod } from "./utils";

const DatePickerButton = ({ onConfirm, dateRange }) => {
  const { t } = useTranslation("sales");

  const [localDateRange, setLocalDateRange] = useState(dateRange);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openPopover = (event) => {
    setLocalDateRange(dateRange);
    setAnchorEl(event.currentTarget);
  };
  const closePopover = () => setAnchorEl(null);

  const handleDateRangeChange = (event) => setLocalDateRange(event.target.value);

  const goToDate = (date) => {
    if (!date) return;
    setLocalDateRange(date);
  };

  const handleConfirm = () => {
    onConfirm(localDateRange);
    closePopover();
  };

  return (
    <>
      <CustomIconButton
        label={t(matchTimePeriod(dateRange))}
        icon={<Calendar />}
        onClick={openPopover}
        variant="contained"
      />

      <DatePickerWrapper open={open} closePopover={closePopover} anchorEl={anchorEl}>
        <DatePickerContent
          localDateRange={localDateRange}
          handleDateRangeChange={handleDateRangeChange}
          goToDate={goToDate}
          closePopover={closePopover}
          handleConfirm={handleConfirm}
        />
      </DatePickerWrapper>
    </>
  );
};

export default DatePickerButton;
