import { createSlice } from "@reduxjs/toolkit";
import { getPercentage } from "components/Sales/utils";
import { selectEmployeeById } from "store/employees/employeesSlice";
import { selectAreaById } from "store/slices/areas/areasSelector";
import { selectProductWithBrandById } from "store/slices/products/productsSelectors";
import {
  selectServiceById,
  selectServiceVariantByIdWithService,
} from "store/slices/servicesV2/servicesSelectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";
import { selectZoneById } from "store/slices/zones/zonesSelectors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./salesApi";

const initialState = {
  dashboard: null,
  services: null,
  completedAppointments: null,
  invoices: null,
  isLoading: false,
  error: "",
};

export const selectProductSalesDashboard = (state) => {
  return state.sales.dashboard.productsPerformance.map((productPerformance) => ({
    ...productPerformance,
    product: selectProductWithBrandById(productPerformance.product)(state),
  }));
};

export const selectEmployeeSalesDashboard = (state) => {
  return state.sales.dashboard.employeesPerformance.map((employeePerformance) => ({
    ...employeePerformance,
    employee: selectEmployeeById(employeePerformance.employee)(state),
  }));
};

export const selectAreaSalesDashboard = (state) => {
  return state.sales.dashboard.areasPerformance.map((areaPerformance) => ({
    ...areaPerformance,
    area: selectAreaById(areaPerformance.area)(state),
  }));
};

export const selectZoneSalesDashboard = (state) => {
  return state.sales.dashboard.zonesPerformance.map((zonePerformance) => ({
    ...zonePerformance,
    zone: selectZoneById(zonePerformance.zone)(state),
  }));
};

export const selectServiceVariantSalesDashboard = (state) => {
  return state.sales.dashboard.serviceVariantsPerformance.map((serviceVariantPerformance) => ({
    ...serviceVariantPerformance,
    serviceVariant: selectServiceVariantByIdWithService(serviceVariantPerformance.serviceVariant)(
      state
    ),
  }));
};

export const selectStaffSalesDashboardDataWithDetails = (state) => {
  const dashboard = state.sales.dashboard;
  const totalSales = dashboard.totalSales;
  const staffPerformance = dashboard.staffPerformance;

  const totalSalesValues = Object.values(staffPerformance.sales).reduce(
    (currentTotal, currentValue) => currentTotal + currentValue,
    0
  );

  const noSales = totalSalesValues === 0;

  const staffSalesList = Object.entries(staffPerformance.sales)
    .map(([key, value]) => ({
      staff: key,
      sale: value,
      outOfTotalSales: getPercentage(value, totalSales, noSales),
    }))
    .sort((a, b) => Number(b.sale) - Number(a.sale));

  const totalAppointments = Object.values(staffPerformance.appointments).reduce(
    (currentTotal, currentValue) => currentTotal + currentValue,
    0
  );

  const noSalesAppointments = totalAppointments === 0;

  const numAppointments = Object.values(staffPerformance.appointments).reduce(
    // @ts-expect-error
    (currentTotal, currentValue) => currentTotal + currentValue,
    0
  );

  const staffAppointmentsLists = Object.entries(staffPerformance.appointments).map(
    ([key, value]) => ({
      staff: key,
      appointment: value,
      outOfTotalAppointments: getPercentage(value, numAppointments, noSalesAppointments),
    })
  );

  const staffList = staffSalesList.map((staffSale) => ({
    ...staffAppointmentsLists.find(
      (staffAppointment) => staffAppointment.staff === staffSale.staff && staffAppointment
    ),
    ...staffSale,
    staff: selectEmployeeById(staffSale.staff)(state),
  }));

  return staffList;
};

export const selectServiceSalesDashboardDataWithDetails = (state) => {
  const dashboard = state.sales.dashboard;
  const totalSales = dashboard.totalSales;
  const top5 = dashboard.top5;

  const totalAppointments = Object.values(top5.appointments).reduce(
    // @ts-expect-error
    (currentTotal, currentValue) => currentTotal + currentValue,
    0
  );

  const noSalesAppointments = totalAppointments === 0;

  const serviceAppointmentsList = Object.entries(top5.appointments)
    .map(([key, value]) => ({
      service: key,
      appointment: value,
      outOfTotalAppointments: getPercentage(value, totalAppointments, noSalesAppointments),
    }))
    .sort((a, b) => Number(b.appointment) - Number(a.appointment));

  const totalSalesValues = Object.values(top5.sales).reduce(
    // @ts-expect-error
    (currentTotal, currentValue) => currentTotal + currentValue,
    0
  );
  const noSales = totalSalesValues === 0;

  const serviceSalesList = Object.entries(top5.sales).map(([key, value]) => ({
    service: key,
    sale: value,
    outOfTotalSale: getPercentage(value, totalSales, noSales),
  }));

  const itemList = serviceSalesList.map((serviceSale) => ({
    ...serviceAppointmentsList.find(
      (serviceAppointment) =>
        serviceAppointment.service === serviceSale.service && serviceAppointment
    ),
    ...serviceSale,
    serviceVariant: selectServiceVariantById(serviceSale?.service)(state),
    service: selectServiceById(serviceSale?.service)(state),
  }));

  return itemList;
};

export const selectSalesDashboardData = (state) => state.sales.dashboard;

export const selectSalesServicesData = (state) => state.sales.services;

export const selectCompletedAppointmentsData = (state) => state.sales.completedAppointments;

export const selectInvoicesLogData = (state) => state.sales.invoices;

export const selectSalesLoading = (state) => state.sales.isLoading;

export const getAllSalesDasboardData = createThunkFromApi(
  "sales/salesDashboard/",
  api.getSalesDasboardData
);

export const getServicesDataExcel = createThunkFromApi(
  "sales/salesServicesExcel/",
  api.getServicesExcel
);

export const getAllServicesLogData = createThunkFromApi(
  "sales/salesServices/",
  api.getServicesLogData
);

export const appendServicesLogData = createThunkFromApi(
  "sales/salesServicesAppend/",
  api.getServicesLogData
);

export const getAllCompletedAppointmentsData = createThunkFromApi(
  "sales/salesAppointments/",
  api.getCompletedAppointmentsData
);

export const getCompletedAppointmentsDataExcel = createThunkFromApi(
  "sales/salesAppointmentsExcel/",
  api.getCompletedAppointmentsExcel
);

export const appendAllCompletedAppointmentsData = createThunkFromApi(
  "sales/salesAppointmentsAppend/",
  api.getCompletedAppointmentsData
);

export const getAllInvoicesLogData = createThunkFromApi(
  "sales/salesInvoices/",
  api.getInvoicesData
);

export const getInvoicesDataExcel = createThunkFromApi(
  "sales/salesInvoicesExcel/",
  api.getInvoiceExcel
);

export const appendAllInvoicesLogData = createThunkFromApi(
  "sales/salesInvoicesAppend/",
  api.getInvoicesData
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  extraReducers: {
    [getAllSalesDasboardData.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllSalesDasboardData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.dashboard = payload;
      state.isLoading = false;
    },

    [getAllSalesDasboardData.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getAllServicesLogData.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllServicesLogData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.services = payload;
      state.isLoading = false;
    },

    [appendServicesLogData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.services = { ...payload, results: [...state.services.results, ...payload.results] };
    },

    [appendServicesLogData.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getAllServicesLogData.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getAllCompletedAppointmentsData.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllCompletedAppointmentsData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.completedAppointments = payload;
      state.isLoading = false;
    },

    [appendAllCompletedAppointmentsData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.completedAppointments = {
        ...payload,
        results: [...state.completedAppointments.results, ...payload.results],
      };
      state.isLoading = false;
    },

    [getAllCompletedAppointmentsData.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getAllInvoicesLogData.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllInvoicesLogData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.invoices = payload;
      state.isLoading = false;
    },

    [appendAllInvoicesLogData.fulfilled]: (state, { payload }) => {
      state.error = "";
      state.invoices = {
        ...payload,
        results: [...state.invoices.results, ...payload.results],
      };
      state.isLoading = false;
    },

    [getAllInvoicesLogData.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export default salesSlice.reducer;
