import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectExpensesPaymentTypes,
  selectPosPaymentTypes,
} from "store/paymentType/paymentTypesSelectors";
import { selectPaymentTypeLoading } from "store/paymentType/paymentTypesSlice";

type usePaymentMethodTitleProps = {
  isForPos: boolean;
};

export default function usePaymentMethodTitle({ isForPos }: usePaymentMethodTitleProps) {
  const { t } = useTranslation("invoices");

  const arePaymentMethodsLoading = useSelector(selectPaymentTypeLoading);

  const paymentMethods = useSelector(isForPos ? selectPosPaymentTypes : selectExpensesPaymentTypes);

  const getPaymentMethodTitle = (paymentMethodId: number | null, isPeepPayMethod?: boolean) => {
    if (isPeepPayMethod) return t("peepPay");

    const paymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id === paymentMethodId
    );

    return paymentMethod?.label || "";
  };

  return { getPaymentMethodTitle, paymentMethods, arePaymentMethodsLoading };
}
