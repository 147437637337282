import { PermissionType, PermissionValue } from "types/Role";

type AccessOption = {
  viewAccess: boolean;
  editAccess: boolean;
};

type PermissionValueOption = {
  [key in PermissionValue]: AccessOption;
};

export const options: PermissionValueOption = {
  1: { viewAccess: true, editAccess: true },
  2: { viewAccess: false, editAccess: false },
  3: { viewAccess: true, editAccess: false },
  4: { viewAccess: true, editAccess: true },
  5: { viewAccess: false, editAccess: false },
};

export type PermissionAccess = {
  [key in keyof typeof PermissionType]: AccessOption;
};

export const getInitialPermissions = (defaultValue: boolean) => ({
  hasAllSales: { viewAccess: defaultValue, editAccess: defaultValue },
  hasAppointments: { viewAccess: defaultValue, editAccess: defaultValue },
  hasCheckout: { viewAccess: defaultValue, editAccess: defaultValue },
  hasClientWalletsPermission: { viewAccess: defaultValue, editAccess: defaultValue },
  hasClients: { viewAccess: defaultValue, editAccess: defaultValue },
  hasClosedDates: { viewAccess: defaultValue, editAccess: defaultValue },
  hasDailySales: { viewAccess: defaultValue, editAccess: defaultValue },
  hasDeposits: { viewAccess: defaultValue, editAccess: defaultValue },
  hasDiscount: { viewAccess: defaultValue, editAccess: defaultValue },
  hasExpenses: { viewAccess: defaultValue, editAccess: defaultValue },
  hasLanguageSettings: { viewAccess: defaultValue, editAccess: defaultValue },
  hasManageAcceptingOnlineBookingsAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasNonPersonalExpenses: { viewAccess: defaultValue, editAccess: defaultValue },
  hasNonPersonalAppointment: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPackages: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPackagesManage: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPersonalAppointment: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPersonalManageExpenses: { viewAccess: defaultValue, editAccess: defaultValue },
  hasProducts: { viewAccess: defaultValue, editAccess: defaultValue },
  hasRefundInvoices: { viewAccess: defaultValue, editAccess: defaultValue },
  hasResourceManageAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasSalesDashboard: { viewAccess: defaultValue, editAccess: defaultValue },
  hasSalesDownload: { viewAccess: defaultValue, editAccess: defaultValue },
  hasSalesLog: { viewAccess: defaultValue, editAccess: defaultValue },
  hasServicesAndPackagesView: { viewAccess: defaultValue, editAccess: defaultValue },
  hasServicesAndPackagesManage: { viewAccess: defaultValue, editAccess: defaultValue },
  hasServicesAndPackagesOnlineAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasStaff: { viewAccess: defaultValue, editAccess: defaultValue },
  hasStaffManagement: { viewAccess: defaultValue, editAccess: defaultValue },
  hasStaffShifts: { viewAccess: defaultValue, editAccess: defaultValue },
  hasUnpaid: { viewAccess: defaultValue, editAccess: defaultValue },
  hasVoid: { viewAccess: defaultValue, editAccess: defaultValue },
  hasSubscriptionAndVoucherExtend: { viewAccess: defaultValue, editAccess: defaultValue },
  hasClientAnalyticsAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPeepPay: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPeepPaySendAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasPeepPayCancelAccess: { viewAccess: defaultValue, editAccess: defaultValue },
  hasServicesAndPackagesOnlinePaymentSettingsAccess: {
    viewAccess: defaultValue,
    editAccess: defaultValue,
  },
});
