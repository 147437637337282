import validatePhoneNumberAndCountryCode from "utils/formValidation/validatePhoneNumberAndCountryCode";
import { z } from "zod";

const phoneNumberSchema = z
  .object({
    phoneCountryCode: z.string().trim(),
    phoneNumber: z.string().trim(),
  })
  .superRefine(validatePhoneNumberAndCountryCode());

export type PhoneNumberFormFields = z.infer<typeof phoneNumberSchema>;

export default phoneNumberSchema;
