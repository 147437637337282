import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";

import { SliceStatus } from "../utils";
import api from "./addOnsApi";

export const addOnsAdapter = createEntityAdapter<AddOn>();

const initialState = addOnsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllAddOns = createThunkFromApiWithType("addOns/getAllAddOns", api.getAllAddOns);

export const createAddOnPurchase = createThunkFromApiWithType(
  "addOns/createAddOnPurchase",
  api.createAddOnPurchase
);

export const addOnsSlice = createSlice({
  name: "addOns",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(createAddOnPurchase.fulfilled, (state) => {
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllAddOns.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllAddOns.fulfilled, (state, { payload }) => {
      addOnsAdapter.setAll(state, payload);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllAddOns.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default addOnsSlice.reducer;
