import { ReactNode, createContext } from "react";
import { PackageVariantWithServiceVariantsAndPriceAndDuration } from "types/Package";
import { ServiceVariant } from "types/ServiceV2";

import { FormArticleSlot } from "../../utils";

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

export type OnSelectServiceVariantFunction = (
  serviceVariant: Nullable<ServiceVariant>,
  finalPrice: number,
  isResourceRequired: boolean
) => void;

export type OnSelectPackageVariantFunction = (
  packageVariant: Nullable<PackageVariantWithServiceVariantsAndPriceAndDuration>,
  finalPrice: number
) => void;

type ServicesSearchFieldState = {
  query: string;
  setQuery: (query: string) => void;

  serviceVariantId: number | null;

  onSelectServiceVariant: OnSelectServiceVariantFunction;
  onSelectPackageVariant: OnSelectPackageVariantFunction;

  closeMenu: () => void;

  staffIdToFilterBy?: FormArticleSlot["staffId"];
  resourceToFilterBy?: FormArticleSlot["resourceId"];
};

const initialState: ServicesSearchFieldState = {
  query: "",
  setQuery: unimplementedFunction,

  serviceVariantId: null,

  onSelectServiceVariant: unimplementedFunction,
  onSelectPackageVariant: unimplementedFunction,

  closeMenu: unimplementedFunction,
};

const ServicesSearchFieldContext = createContext<ServicesSearchFieldState>(initialState);

export default ServicesSearchFieldContext;

function ServicesSearchFieldContextProvider({
  children,
  values,
}: {
  children: ReactNode;
  values: ServicesSearchFieldState;
}) {
  return (
    <ServicesSearchFieldContext.Provider
      value={{
        ...values,
      }}
    >
      {children}
    </ServicesSearchFieldContext.Provider>
  );
}

export { ServicesSearchFieldContextProvider };
