import http from "utils/http";

const serviceCategoriesApi = {
  getAllServiceCategories: async () => http.get("organizations/service_categories/"),

  getServiceCategory: async (id) => http.get(`organizations/service_categories/${id}/`),

  createServiceCategory: async ({ data }) => http.post("organizations/service_categories/", data),

  updateServiceCategory: async ({ id, data }) =>
    http.put(`organizations/service_categories/${id}/`, data),

  updateServiceCategoryPosition: async (data) =>
    http.put(`organizations/update_service_category_positions/`, data),

  removeServiceCategory: async (id) => http.delete(`organizations/service_categories/${id}/`),
};

export default serviceCategoriesApi;
