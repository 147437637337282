import { Grid, Stack, Typography } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import usePaymentMethodTitle from "hooks/usePaymentMethodTitle";
import usePromotionDiscountSummary from "hooks/usePromotionDiscountSummary";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { selectActiveVouchersByClientIdAndOutletId } from "store/clients/clientSelectors";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import PaymentSummaryItem from "./PaymentSummaryItem";

const CheckoutInvoicePaymentsSummary = () => {
  const { t } = useTranslation(["checkout", "vouchers", "invoices"]);

  const { getPromotionDiscountSummary } = usePromotionDiscountSummary();

  const { getPaymentMethodTitle, arePaymentMethodsLoading } = usePaymentMethodTitle({
    isForPos: true,
  });

  const {
    watch,
    setValue,
    subtotal,
    totalDiscount,
    balance,
    totalDue,

    onlinePayment,
    peepTransactionTotal,

    deposit,
    walletPayments,
  } = useCheckoutFormContext();

  const { checkoutModalView } = useCheckoutModal();

  const {
    payments,
    voucherRedemptions,
    existingClient,
    outlet,
    refund,
    appointment: appointmentId,
  } = watch();
  const existingClientId = existingClient?.id || null;

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId ?? undefined));

  const clientActiveVouchers = useSelector(
    selectActiveVouchersByClientIdAndOutletId(existingClientId ?? 0, outlet)
  );

  const getVoucherTitle = (voucherId: number) => {
    const voucher = clientActiveVouchers.find((voucher) => voucher.id === voucherId);

    return voucher?.name || "";
  };

  const handelRemovePayment = (paymentIndex: number) => {
    const newPayments = payments.filter((_, index) => index !== paymentIndex);

    setValue("payments", newPayments);
  };

  const handelRemoveVoucherRedemption = (voucherRedemptionIndex: number) => {
    const newVoucherRedemptions = voucherRedemptions.filter(
      (_, index) => index !== voucherRedemptionIndex
    );

    setValue("voucherRedemptions", newVoucherRedemptions);
  };

  const handleRemoveWalletPayment = (walletPaymentIndex: number) => {
    const newWalletPayments = walletPayments.filter((_, index) => index !== walletPaymentIndex);

    setValue("walletPayments", newWalletPayments);
  };

  if (arePaymentMethodsLoading || (!appointment && appointmentId)) return <CentredSpinnerBox />;

  let onlinePaymentPrice = onlinePayment;

  if (peepTransactionTotal > 0) {
    onlinePaymentPrice = onlinePayment - peepTransactionTotal;
  }
  return (
    <Grid item container>
      <PaymentSummaryItem title={t("checkout:subtotal")} price={subtotal} hasBoldTitle />

      <PaymentSummaryItem
        title={t("checkout:discount")}
        price={totalDiscount}
        hasBoldTitle
        isNegative
      />

      <PaymentSummaryItem title={t("checkout:total")} price={totalDue} hasBoldTitle />

      {peepTransactionTotal > 0 && (
        <PaymentSummaryItem title={t("checkout:paidByPeep")} price={peepTransactionTotal} />
      )}

      {onlinePayment - peepTransactionTotal > 0 && (
        <PaymentSummaryItem title={t("checkout:paidOnline")} price={onlinePaymentPrice} />
      )}

      {deposit && (
        <PaymentSummaryItem
          disabled={true}
          key={`${deposit.id}${deposit.amount}${deposit.paymentMethod}`}
          title={t("checkout:downPayment")}
          hasBoldTitle={true}
          subtitle={deposit.paymentMethod}
          price={deposit.amount}
        />
      )}

      {walletPayments.map((walletPayment, index) => (
        <PaymentSummaryItem
          disabled={checkoutModalView === CheckoutModalView.ConfirmSale}
          key={`${index} ${t("clientsCredit")}`}
          title={t("clientsCredit")}
          price={walletPayment.amount}
          onRemove={() => handleRemoveWalletPayment(index)}
        />
      ))}

      {payments.map((payment, index) => {
        const isPeepPayMethod = payment.paymentType === null && !!payment.peepPay;

        return (
          <PaymentSummaryItem
            key={`${index}${payment.paymentType}`}
            title={getPaymentMethodTitle(payment.paymentType, isPeepPayMethod)}
            price={payment.amount}
            onRemove={() => handelRemovePayment(index)}
            disabled={checkoutModalView === CheckoutModalView.ConfirmSale}
          />
        );
      })}

      {voucherRedemptions.map((voucherRedemption, index) => (
        <PaymentSummaryItem
          key={`${index}${voucherRedemption.voucherPurchase}`}
          title={getVoucherTitle(voucherRedemption.voucherPurchase)}
          price={voucherRedemption.value}
          onRemove={() => handelRemoveVoucherRedemption(index)}
          disabled={checkoutModalView === CheckoutModalView.ConfirmSale}
        />
      ))}

      {appointment?.discountCode?.code && !appointment?.discountCode?.isPeepDiscount && (
        <PaymentSummaryItem
          key={`${appointment?.discountCode?.code}`}
          title={`${t("appliedDiscountCode")}:`}
          hasBoldTitle
          titleDescription={
            <Stack gap={1}>
              <Typography variant="h5" fontWeight={700} align="right">
                {appointment?.discountCode?.code}
              </Typography>

              <Typography variant="h5" fontWeight={500} align="right" whiteSpace="pre">
                {`(${getPromotionDiscountSummary(
                  appointment?.discountCode?.discountType,
                  Number(appointment?.discountCode?.discountValue)
                )})`}
              </Typography>
            </Stack>
          }
          subtitle={t("noteThatNewlyAddedItemsWillNotBeAffected")}
          disabled={true}
        />
      )}

      {refund && (
        <PaymentSummaryItem
          key="refund"
          title={
            refund.paymentType
              ? `${t("refund")} (${getPaymentMethodTitle(refund.paymentType)})`
              : `${t("refund")} (${t("checkout:wallet")})`
          }
          onRemove={() => setValue("refund", undefined)}
          price={refund.amount}
          disabled={checkoutModalView === CheckoutModalView.ConfirmSale}
        />
      )}

      <PaymentSummaryItem
        title={t("checkout:balance")}
        price={refund ? balance - refund.amount : balance}
        hasBoldPrice
        hasBoldTitle
      />
    </Grid>
  );
};

export default CheckoutInvoicePaymentsSummary;
