import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type ClientTagProps = {
  label: string | ReactNode;
  value?: string | number | ReactNode;
  isValueColorPrimary?: boolean;
  bgColor?: string;
};

const ClientTag = ({ label, value, isValueColorPrimary = true, bgColor }: ClientTagProps) => {
  return (
    <Stack
      flexDirection="row"
      bgcolor={bgColor || "#F7F8FD"}
      borderRadius={2}
      py={0.5}
      px={2}
      textAlign="center"
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
      gap={1}
      height="100%"
    >
      {value ? (
        <>
          <Typography variant="secondary" fontWeight={500}>
            {label}
          </Typography>

          <Typography
            variant="secondary"
            fontWeight={500}
            color={isValueColorPrimary ? "primary" : "error.main"}
          >
            {value}
          </Typography>
        </>
      ) : (
        label
      )}
    </Stack>
  );
};

export default ClientTag;
