import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, styled } from "@mui/material";
import { StickyBottomActions } from "assets/mixins/StickyBottomActions";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GenericThunk, useDispatch, useSelector } from "store";
import { selectVouchersLoading } from "store/selectors";
import { emailGiftVoucher } from "store/slices/vouchers/vouchersSlice";
import useNotify from "utils/notifications/useNotify";

type EmailGiftVoucherModalActionButtonsProps = {
  onClose: () => void;
};

const EmailGiftVoucherModalActionButtons = ({
  onClose,
}: EmailGiftVoucherModalActionButtonsProps) => {
  const { t } = useTranslation("vouchers");

  const notify = useNotify();

  const dispatch = useDispatch();

  const isRequestPending = useSelector(selectVouchersLoading);

  const { handleSubmit } = useFormContext();

  const handleEmailGiftVoucher = handleSubmit((data) => {
    dispatch((emailGiftVoucher as GenericThunk)({ data }))
      .unwrap()
      .then(() => {
        notify(t("emailSentSuccessfully"), "success");
        onClose();
      })
      .catch((error: any) => {
        notify(`${error?.detail}`, "error");
      });
  });

  return (
    <ActionsWrapper p={3}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <Button fullWidth onClick={onClose} variant="outlined">
            {t("cancel")}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <LoadingButton fullWidth onClick={handleEmailGiftVoucher} loading={isRequestPending}>
            {t("sendEmail")}
          </LoadingButton>
        </Grid>
      </Grid>
    </ActionsWrapper>
  );
};

export default EmailGiftVoucherModalActionButtons;

const ActionsWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.label}25`,
  [theme.breakpoints.down("md")]: {
    ...StickyBottomActions(),
  },
}));
