import { Box, Typography } from "@mui/material";
import { PriceField } from "components/common";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useCheckoutFormContext } from "modals/CheckoutModal/CheckoutFormContext";
import { useTranslation } from "react-i18next";
import { VoucherPurchase } from "types/VoucherPurchase";

import { getMaxRedeemableAmountBasedOnCartAndBalance } from "../utils";
import ApplyVoucherFormSectionTitle from "./ApplyVoucherFormSectionTitle";

type RedeemableAmountSectionProps = {
  redemptionAmount: number;
  onChangeRedemptionAmount: (value: number) => void;

  shouldDisableAmountField?: boolean;
  hasSelectedVoucher: boolean;
  selectedVoucher: Nullable<VoucherPurchase>;
};

const RedeemableAmountSection = ({
  redemptionAmount,
  shouldDisableAmountField,
  onChangeRedemptionAmount,
  selectedVoucher,
  hasSelectedVoucher,
}: RedeemableAmountSectionProps) => {
  const { t } = useTranslation(["translation", "vouchers"]);

  const { balance, watch } = useCheckoutFormContext();

  const { articles, voucherRedemptions } = watch();

  const hasAppliedVoucherRedemptions = voucherRedemptions?.length > 0;

  const maxRedeemableAmountBasedOnBalance = getMaxRedeemableAmountBasedOnCartAndBalance(
    selectedVoucher,
    articles,
    balance
  );

  const maxRedeemableAmount = maxRedeemableAmountBasedOnBalance;

  const remainingAmountToBePaid = balance - redemptionAmount;

  const remainingAmountWithCurrency = `${t("translation:currency")} ${getFormattedPrice(
    remainingAmountToBePaid
  )}`;

  return (
    <Box pt={4}>
      <ApplyVoucherFormSectionTitle
        title={t("vouchers:redeemableAmount")}
        secondaryContent={
          <Typography variant="primary" color="text.placeholder" fontWeight={700}>
            {`${t("translation:currency")} ${getFormattedPrice(maxRedeemableAmount)}`}
          </Typography>
        }
      />

      <PriceField
        label={t("vouchers:redeemAmount")}
        fullWidth
        value={redemptionAmount}
        max={maxRedeemableAmount}
        min={0}
        fallbackValue={0}
        onChange={onChangeRedemptionAmount}
        disabled={shouldDisableAmountField}
      />

      {!hasAppliedVoucherRedemptions && (
        <Typography
          component="p"
          variant="primary"
          color={hasSelectedVoucher ? "text.placeholder" : "error.main"}
          fontWeight={500}
          mt={2}
        >
          {hasSelectedVoucher
            ? t("vouchers:amountLeftToPay", { amount: remainingAmountWithCurrency })
            : t("vouchers:mustSelectVoucher")}
        </Typography>
      )}
    </Box>
  );
};

export default RedeemableAmountSection;
