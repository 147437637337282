import { Stack, debounce } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectClientById, updateClient } from "store/clients/clientsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { trackEvent } from "tracking";
import { Client } from "types/Client";
import useNotify from "utils/notifications/useNotify";

import AllergiesField from "./AllergiesField";
import ClientPreferencesSection from "./ClientPreferencesSection";
import ClientSkinAndHairProfileSection from "./ClientSkinAndHairProfileSection";
import NotesField from "./NotesField";

type ClientBeautyProfileProps = {
  clientId: number;
};

const ClientBeautyProfile = ({ clientId }: ClientBeautyProfileProps) => {
  const { t } = useTranslation(["client", "translation"]);
  const dispatch = useDispatch();
  const notify = useNotify();
  const { pathname } = useLocation();

  const client = useSelector(selectClientById(clientId)) as Client;

  const { hasClients } = useSelector(selectAllPermissions);
  const canUpdate = hasClients.editAccess;

  const handleUpdateClientField = (fieldName: keyof Client) => (newValue: number[] | string) => {
    const delayedUpdate = debounce(() => {
      dispatch(
        updateClient({
          id: clientId,
          data: {
            [fieldName]: newValue,
          },
        })
      )
        .unwrap()
        .then(() => {
          trackEvent("Client Beauty Profile Updated", {
            From: pathname,
          });
          notify(
            t("translation:entitySuccessfullyUpdated", {
              entity: t("client:client"),
            }),
            "success"
          );
        })

        .catch((error) => {
          notify(
            `${t("translation:entityFailedToBeUpdated", {
              entity: t("client:client"),
            })} ${error?.detail ?? ""}`,
            "error"
          );
        });
    }, 300);

    // debouncing the update to avoid sending multiple requests
    return delayedUpdate();
  };

  return (
    <Stack m={3} gap={4}>
      <NotesField
        isDisabled={!canUpdate}
        value={client?.notes || ""}
        handleChange={handleUpdateClientField("notes")}
      />

      <AllergiesField
        isDisabled={!canUpdate}
        value={client?.allergies || ""}
        handleChange={handleUpdateClientField("allergies")}
      />

      <ClientSkinAndHairProfileSection
        clientId={clientId}
        isDisabled={!canUpdate}
        handleChange={handleUpdateClientField}
      />

      <ClientPreferencesSection
        clientId={clientId}
        isDisabled={!canUpdate}
        handleChange={handleUpdateClientField}
      />
    </Stack>
  );
};

export default ClientBeautyProfile;
