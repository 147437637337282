import { Ticket } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import { Switch } from "components/common";
import { useTranslation } from "react-i18next";

type CheckoutCartItemRedeemableSubscriptionProps = {
  title: string;

  remainingRedemptionsCount: number;
  expirationDate: string;

  usedCreditsCount: number;
  totalCreditsCount: number;

  isSubscriptionApplied: boolean;
  setIsSubscriptionApplied: (value: boolean) => void;

  isRedeemedOnAppointment: boolean;
  disableRedeeming?: boolean;
};

const CheckoutCartItemRedeemableSubscription = ({
  title,
  remainingRedemptionsCount,
  expirationDate,

  usedCreditsCount,
  totalCreditsCount,

  isSubscriptionApplied,
  setIsSubscriptionApplied,

  isRedeemedOnAppointment = false,
  disableRedeeming = false,
}: CheckoutCartItemRedeemableSubscriptionProps) => {
  const { t } = useTranslation("subscriptions");

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newSwitchValue = event.target.checked;

    setIsSubscriptionApplied(newSwitchValue);
  };

  return (
    <Box
      bgcolor="bg.secondary"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
      borderRadius={1}
      gap={2}
      flexWrap="wrap"
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" alignItems="center">
          <Ticket
            sx={{
              fontSize: "0.9rem",
              color: "primary.dark",
            }}
          />

          <Typography pl={0.75} variant="primary" fontWeight={700} noWrap>
            {title}
          </Typography>
        </Box>

        <Box>
          <Typography variant="secondary" fontWeight={500} display="inline-flex">
            <Typography
              variant="inherit"
              color={remainingRedemptionsCount ? "inherit" : "error.main"}
            >
              {`${remainingRedemptionsCount} ${t("left")}`}
            </Typography>

            <Typography px={0.5} variant="inherit" color="text.placeholder">
              {t("until")}
            </Typography>

            {expirationDate}
          </Typography>
        </Box>
      </Box>

      <Switch
        checked={isSubscriptionApplied}
        onChange={handleSwitchToggle}
        disabled={disableRedeeming}
        sx={{ order: { xs: 0, md: 1 } }}
      />

      <Typography
        variant="secondary"
        color="text.placeholder"
        fontWeight={500}
        textAlign="center"
        sx={{ minWidth: { xs: "100%", md: 0 } }}
      >
        {isRedeemedOnAppointment
          ? t("redeemedOnAppointment")
          : `${usedCreditsCount} ${t("usedFrom")} ${totalCreditsCount} ${t("credits")}`}
      </Typography>
    </Box>
  );
};

export default CheckoutCartItemRedeemableSubscription;
