import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButton,
  styled,
} from "@mui/material";
import { MouseEvent, ReactNode } from "react";

type Option = {
  value: string;
  label: string | ReactNode;
};

type ToggleButtonGroupProps = Omit<MuiToggleButtonGroupProps, "value" | "onChange"> & {
  value: MuiToggleButtonGroupProps["value"];
  onChange: (value: string) => void;
  options: Option[];
};

export default function ToggleButtonGroup({
  value,
  onChange,
  options,
  fullWidth,
}: ToggleButtonGroupProps) {
  const handleChange = (
    event: MouseEvent<HTMLElement>,
    _newPossessedValueThatYouShouldNotUse: string
  ) => {
    const eventTarget = event.target as HTMLButtonElement;

    const value = eventTarget.value;

    onChange(value);
  };

  return (
    <StyledToggleButtonGroup fullWidth={fullWidth} value={value} onChange={handleChange}>
      {options.map((option) => (
        <ToggleButton
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
          key={option.value}
          value={option.value}
        >
          {option.label}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme, fullWidth }) => ({
  maxHeight: theme.spacing(6),
  minHeight: theme.spacing(6),
  ...(!fullWidth && { maxWidth: "fit-content" }),

  border: "unset !important",
  outline: "1px solid #B3B6EF !important",
  borderRadius: theme.spacing(1),

  "& .MuiToggleButtonGroup-grouped": {
    border: "unset !important",
    outline: "unset !important",

    borderRadius: `${theme.spacing(1)} !important`,
    borderColor: "unset",
    color: "#939BAA",
  },

  "& .MuiToggleButton-root": {
    padding: theme.spacing(2, 1.5),
    minWidth: theme.spacing(13.5),

    border: "none",
    borderRadius: `${theme.spacing(1)} !important`,
    backgroundColor: "transparent",

    fontSize: "0.875rem",
    color: "#939BAA",

    "&:hover": {
      color: "#242BA8 !important",
      backgroundColor: `transparent !important`,
    },
  },

  "& .Mui-selected": {
    color: "#242BA8 !important",
    fontWeight: "700 !important",
    fontSize: "0.875rem",

    border: "unset !important",
    outline: "1px solid #4048D6 !important",
    backgroundColor: `${theme.palette.bg.blank} !important`,

    "&:hover": {
      backgroundColor: `${theme.palette.bg.secondary} !important`,
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
