import { RefinementCtx, ZodIssueCode } from "zod";

import isStringValidNumber from "./isStringValidNumber";

/**
 * This function validates that the value of the field is a string and is a valid number within optional min and max arguments - @kalmohammed56 function
 * @param field - the field to check
 * @param ctx
 * @param min - optional minimum value for the number
 * @param max - optional maximum value for the number
 * @returns void
 */

// TODO: Dear developer, this function error message will not show properly translated because we need to manually translate it, because the values are dynamic - min and max we don't have time to do this now, but if you have time please do it

const isStringNumberWithinRange = (
  field: any,
  ctx: RefinementCtx,
  min?: Nullable<number>,
  max?: Nullable<number>,
  inclusive: boolean = false
) => {
  isStringValidNumber(field, ctx);

  const number = Number(field);

  if (
    min !== null &&
    min !== undefined &&
    ((inclusive && number < min) || (!inclusive && number <= min))
  )
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: [`${field}`],
      message: inclusive ? "custom.number.min.gte" : "custom.number.min.gt",
    });

  if (
    max !== null &&
    max !== undefined &&
    ((inclusive && number > max) || (!inclusive && number >= max))
  )
    ctx.addIssue({
      code: ZodIssueCode.custom,
      path: [`${field}`],
      message: inclusive ? "custom.number.max.lte" : "custom.number.max.lt",
    });
};

export default isStringNumberWithinRange;
