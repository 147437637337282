import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, Typography } from "@mui/material";
import {
  getCountryCodeFromCountryPhonePrefix,
  getCountryPhonePrefixFromCountryCode,
} from "assets/constants/countries";
import { PhoneField } from "components/common";
import useFormValidation from "hooks/useFormValidation";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import phoneNumberSchema, { PhoneNumberFormFields } from "./phoneNumberSchema";

export type PeepPayPhoneNumber = {
  phoneNumber: string;
  phoneCountryCode: string;
};

export type SetPeepPayPhoneNumberFunction = ({
  phoneNumber,
  phoneCountryCode,
}: PeepPayPhoneNumber) => void;

type PeepPayPhoneNumberSectionProps = {
  phoneCountryCode: string;
  phoneNumber: string;

  setPeepPayPhoneNumber: SetPeepPayPhoneNumberFunction;
};

const PeepPayPhoneNumberSection = ({
  phoneCountryCode,
  phoneNumber,
  setPeepPayPhoneNumber,
}: PeepPayPhoneNumberSectionProps) => {
  const { t } = useTranslation(["invoices", "authentication"]);

  const { register, watch, setValue, formState, trigger } = useForm<PhoneNumberFormFields>({
    resolver: zodResolver(phoneNumberSchema),
    defaultValues: {
      phoneCountryCode: getCountryCodeFromCountryPhonePrefix(phoneCountryCode) || "KW",
      phoneNumber: phoneNumber.replace(phoneCountryCode, ""),
    },
  });

  const handleOnBlur = () => {
    trigger("phoneNumber");
    const { phoneNumber: newPhoneNumber, phoneCountryCode: newPhoneCountryCode } = watch();

    const countryCodeFromPrefix =
      `+${getCountryPhonePrefixFromCountryCode(newPhoneCountryCode)}` || "+965";

    setPeepPayPhoneNumber({
      phoneNumber: `${countryCodeFromPrefix}${newPhoneNumber}`,
      phoneCountryCode: countryCodeFromPrefix,
    });
  };

  const countryCode = watch("phoneCountryCode") || "KW";

  const setCountryCode = (code: string) => setValue("phoneCountryCode", code);

  const registerValidation = useFormValidation(formState, { ns: "authentication" });

  return (
    <Stack p={2} gap={2} borderRadius={1} bgcolor="bg.secondary">
      <Typography variant="primary" color="text.primary">
        {t("invoices:sendPeepPayLinkToPhoneNumber")}
      </Typography>

      <PhoneField
        fullWidth
        label={t("authentication:mobile")}
        placeholder="9999 9999"
        CountryCodeAutocompleteProps={{ value: countryCode, onChange: setCountryCode }}
        {...register("phoneNumber")}
        {...registerValidation("phoneNumber", t("authentication:mobile"))}
        onBlur={() => handleOnBlur()}
      />
    </Stack>
  );
};

export default PeepPayPhoneNumberSection;
