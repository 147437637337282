import http from "utils/http";

const rolesApi = {
  getAllRoles: async () => http.get("roles/roles/"),

  getRole: async (id) => http.get(`roles/roles/${id}/`),

  createRole: async ({ data }) => http.post("roles/roles/", data),

  updateRole: async ({ id, data }) => http.put(`roles/roles/${id}/`, data),

  deleteRole: async ({ id }) => http.delete(`roles/roles/${id}/`),

  // Role permission
  updateRolePermission: async (id, data) => http.put(`roles/role_permission/${id}/`, data),
};

export default rolesApi;
