import { ListItem, ListSubheader, Typography } from "@mui/material";
import { Notification, NotificationListSectionType } from "types/Notification";

import NotificationCardTranslator from "../NotificationCard/NotificationCardTranslator";

type NotificationsListSectionProps = {
  title: string;

  type: NotificationListSectionType;

  notifications: Notification[];
  handleClose: () => void;
};

const NotificationsListSection = ({
  title,
  type,
  notifications,
  handleClose,
}: NotificationsListSectionProps) => {
  return (
    <>
      <ListSubheader sx={{ pt: 2, pb: 1 }}>
        <Typography textTransform="uppercase" color="text.placeholder" fontWeight={700}>
          {title}
        </Typography>
      </ListSubheader>

      {notifications.map((notification, index) => (
        <ListItem
          key={`notification-${index}-${notification}`}
          sx={{
            bgcolor: "bg.blank",
            p: 0,
          }}
        >
          <NotificationCardTranslator
            shouldDisplayDot={type === NotificationListSectionType.New}
            notification={notification}
            backgroundColor={type === NotificationListSectionType.New ? "bg.secondary" : "bg.blank"}
            showDivider={index !== notifications.length - 1}
            onClick={handleClose}
          />
        </ListItem>
      ))}
    </>
  );
};

export default NotificationsListSection;
