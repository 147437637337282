import { TFunction } from "react-i18next";
import * as yup from "yup";

import { isValidPhoneNumberForRegion } from "..";

export default function appointmentFormSchema(t: TFunction) {
  yup.addMethod(yup.mixed, "isValidPhoneNumberForRegion", isValidPhoneNumberForRegion);

  return yup
    .object({
      client: yup.object({
        isNewClient: yup.boolean(), // used to determine whether new client fields should become required or not on the schema

        id: yup.number().nullable(),

        firstName: yup
          .string()
          .max(255)
          .when("isNewClient", {
            is: true,
            then: yup.string().required(t("clientModal.validation.firstNameRequired")).nullable(),
            otherwise: yup.string(),
          }),
        lastName: yup.string().max(255).when("isNewClient", {
          is: true,
          then: yup.string().nullable(),
          otherwise: yup.string(),
        }),
        phoneCountryCode: yup.string(),
        phoneNumber: yup.string().when("isNewClient", {
          is: true,
          then: yup.string().required(t("clientModal.validation.phoneNumberRequired")),
          otherwise: yup.string(),
        }),
      }),
      notes: yup.string(),
      createdOnline: yup.boolean(),

      // Home service organization specific fields:
      address: yup.number().nullable(),
      area: yup.number().nullable(),

      articles: yup.array().of(
        yup.object().shape(
          {
            serviceVariantId: yup.number().when(["packageVariantId", "slots"], {
              is: (packageVariantId: number, slots: any[]) => {
                const hasMissingServiceButHasStaffOrResource = slots.some(
                  (slot) =>
                    !slot.serviceVariantId &&
                    (slot.staffId || (slot.isResourceRequired && slot.resourceId))
                );

                return hasMissingServiceButHasStaffOrResource && !Boolean(packageVariantId);
              },

              then: yup.number().required(t("validation.serviceVariantOrPackageVariantRequired")),
              otherwise: yup.number().nullable(),
            }),
            creationSource: yup.string().default("PP"),
            packageVariantId: yup.number().optional().nullable(),

            promotion: yup.number().nullable().default(null),

            slots: yup.array().of(
              yup.object().shape(
                {
                  serviceVariantId: yup.number().when(["resourceId", "staffId"], {
                    is: (resourceId: number | null, staffId: number) =>
                      Boolean(resourceId) || Boolean(staffId),
                    then: yup
                      .number()
                      .required(t("appointments:validation.serviceRequired"))
                      .nullable(),
                    otherwise: yup.number().nullable(),
                  }),
                  isStaffSelectedByClient: yup.boolean(),
                  startTime: yup.date().typeError("appointments:validation.invalidTime"),
                  endTime: yup
                    .date()
                    .typeError("appointments:validation.invalidTime")
                    .min(yup.ref("startTime"), t("appointments:validation.endTimeInvalid")), // validate that end time is after start time
                  staffId: yup.number().when(["serviceVariantId", "resourceId"], {
                    is: (serviceVariantId: number | null, resourceId: number | null) =>
                      Boolean(resourceId) || Boolean(serviceVariantId),
                    then: yup.number().required(t("appointments:validation.staff")).nullable(),
                    otherwise: yup.number().optional().nullable(),
                  }),

                  isResourceRequired: yup.boolean().default(false),
                  resourceId: yup
                    .number()
                    .optional()
                    .nullable()
                    .default(null)
                    .when(["isResourceRequired", "serviceVariantId"], {
                      is: (isResourceRequired: boolean, serviceVariantId: null | number) => {
                        if (serviceVariantId && isResourceRequired) return true;
                        return false;
                      },
                      then: yup
                        .number()
                        .required(t("appointments:validation.resource"))
                        .nullable()
                        .default(null),
                      otherwise: yup.number().default(null).optional().nullable(),
                    }),
                },
                [
                  ["serviceVariantId", "staffId"],
                  ["serviceVariantId", "resourceId"],
                ]
              )
            ),
          },
          // Cyclic dependencies
          [["serviceVariantId", "packageVariantId"]]
        )
      ),
    })
    .required();
}
