import { Service, ServiceDetail, ServiceVariant, ServiceVariantPricingType } from "types/ServiceV2";
import http from "utils/http";

type ServiceVariantArguments = {
  id?: number;
  name: string;
  duration: number;
  pricingType: ServiceVariantPricingType;
  price: string | number;
};

type ServiceArguments = Omit<ServiceDetail, "variants" | "id"> & {
  variants: ServiceVariantArguments[];
};

type UpdateServiceVariantsArguments = {
  id: number;
  online: boolean;
};

type UpdateServiceArguments = {
  online: boolean;
  variants: UpdateServiceVariantsArguments[] | undefined;
};

export type ServiceDetailResponse = {
  service: ServiceDetail;
  variants: ServiceVariant[];
};

const servicesApi = {
  getServiceDetails: async ({ id }: { id: number }) =>
    http.get<ServiceDetailResponse>(`services/v2/services/${id}/`),

  getAllServices: async () => http.get<Service[]>(`services/v2/services/`),

  createService: async ({ data }: { data: ServiceArguments }) =>
    http.post<ServiceDetailResponse>("services/v2/services/", data),

  updateService: async ({
    id,
    data,
  }: {
    id: number;
    data: ServiceArguments | UpdateServiceArguments;
  }) => http.put<ServiceDetailResponse>(`services/v2/services/${id}/`, data),

  updateServicePosition: async ({ data }: { data: Service[] }) =>
    http.put<Service[]>(`services/v2/update_service_positions/`, data),

  updateServiceMpPosition: async ({ data }: { data: Service[] }) =>
    http.put<Service[]>(`services/v2/update_mp_service_positions/`, data),

  removeService: async ({ id }: { id: number }) =>
    http.delete<ServiceDetailResponse>(`services/v2/services/${id}/`),
};

export default servicesApi;
