import {
  Hidden,
  Table as MuiTable,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

const ServiceOrPackageTable = ({ headerTableCellTitle, children }) => {
  const { t } = useTranslation(["services", "offers"]);

  const isMobileView = useIsMobileView();

  return (
    <TableContainer>
      <MuiTable aria-label="collapsible table">
        {!isMobileView && (
          <TableHead>
            <TableRow>
              <TableCellStyle sx={{ width: "1px" }} />

              <TableCellStyle sx={{ pl: 0 }}>
                <Typography color="text.label" fontWeight={700} variant="primary">
                  {headerTableCellTitle}
                </Typography>
              </TableCellStyle>

              <Hidden mdDown>
                <TableCellStyle>
                  <Typography color="text.label" fontWeight={700} variant="primary">
                    {t("duration")}
                  </Typography>
                </TableCellStyle>
              </Hidden>

              <Hidden mdDown>
                <TableCellStyle />
              </Hidden>

              <TableCellStyle align="right">
                <Typography color="text.label" fontWeight={700} variant="primary">
                  {t("price")}
                </Typography>
              </TableCellStyle>

              <TableCellStyle />
            </TableRow>
          </TableHead>
        )}
        {children}
      </MuiTable>
    </TableContainer>
  );
};

const TableCellStyle = styled(TableCell)(() => ({
  padding: "4px",
}));

export default ServiceOrPackageTable;
