import { Close } from "@bookpeep/ui";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions as MuiDialogActions,
  Slide,
  Typography,
  styled,
} from "@mui/material";
import { TransitionHandlerProps } from "@mui/material/transitions";
import { ReactElement, ReactNode, Ref, forwardRef } from "react";

export type DialogModalProps = {
  title: ReactNode;
  description?: ReactNode;

  hasTitleOnTop?: boolean;
  children?: ReactNode;
  dialogFooterActions?: ReactNode;

  isOpen: boolean;
  onClose: () => void;
};

const DialogModal = ({
  title,
  description,

  hasTitleOnTop = false,
  children,

  isOpen,
  onClose,
  dialogFooterActions,
}: DialogModalProps) => {
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          width: "580px",
          minHeight: { xs: "250px", md: "300px" },
        },
      }}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box
        display="flex"
        justifyContent={hasTitleOnTop ? "space-between" : "flex-end"}
        alignItems="center"
        p={{ md: hasTitleOnTop ? 2.5 : 3, xs: 2 }}
        px={{ md: 3, xs: 2 }}
        boxShadow={hasTitleOnTop ? "divider" : "none"}
      >
        {hasTitleOnTop && <Typography variant="h2">{title}</Typography>}

        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <Box px={{ md: 3, xs: 2 }} flex={1}>
        {!hasTitleOnTop && (
          <Typography variant="h1" textAlign="center">
            {title}
          </Typography>
        )}

        {description && (
          <DialogContent
            sx={{
              maxWidth: "460px",
              textAlign: "center",
              margin: "auto",
              pb: 3,
            }}
          >
            {description}
          </DialogContent>
        )}

        {children}
      </Box>

      {dialogFooterActions && <DialogActions>{dialogFooterActions}</DialogActions>}
    </Dialog>
  );
};

export default DialogModal;

const Transition = forwardRef(function Transition(
  props: TransitionHandlerProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogActions = styled(MuiDialogActions)({
  borderTop: `1px solid #CED2DA`,
});
