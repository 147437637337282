import { DateTime } from "luxon";

import { EmployeeWorkingHours } from "./../store/workingHours/workingHoursApi";

const getWorkingHoursTotalDuration = (workingHour: EmployeeWorkingHours) => {
  const startTime = DateTime.fromISO(workingHour.startTime);
  const endTime = DateTime.fromISO(workingHour.endTime);

  const duration = endTime.diff(startTime);

  return duration;
};

export default getWorkingHoursTotalDuration;
