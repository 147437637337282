
import type { RootState } from "store";
import { SliceStatus } from "../utils";
import { flexBookingAdapter } from "./flexBookingSlice";
import { IndustryCategory } from "types/IndustryCategory";

export const selectFlexBookingState = (state: RootState) => state.flexBooking;



export const selectAreFlexServicesLoading = (state: RootState) =>
  selectFlexBookingState(state).status === SliceStatus.LOADING;


const { selectAll, selectById } = flexBookingAdapter.getSelectors(selectFlexBookingState);

export const selectAllFlexServices = selectAll;

export const selectFlexServiceById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectFlexServiceByIndustryCategoryId = (categoryId: IndustryCategory['id']) => (state: RootState) => {
  const allFlexServices = selectAll(state)

  return allFlexServices.find(service => service.industryCategory === categoryId)
}