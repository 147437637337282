import { VoucherPurchase } from "types/VoucherPurchase";

import { AvailableVoucherStatus } from "./AvailableVoucherItem";
import type { VoucherPurchaseWithStatusAndRedeemableItemsTitle } from "./AvailableVouchersSection";

export const getAvailableVoucherStatus = (
  voucher: VoucherPurchase,
  clientRedeemableVouchers: VoucherPurchase[],
  appliedVoucherRedemptions: VoucherPurchase["id"][],
  selectedVoucherId: Nullable<VoucherPurchase["id"]> = null
) => {
  /**
   * Life lesson 🥹:
   * When you wanna compare objects, use something like their ids,
   * i just spend more than 30 minutes. because i used arrayOfObjects.includes(anObject)
   * because i stupidly changed the type by appending a new property and
   * typescript isn't complaining about type mismatch #oceanOfTears
   * - Anfal the lost soul.. 6-4-2023 🥹
   */

  const isRedeemableVoucher = !!clientRedeemableVouchers.find(
    (clientRedeemableVoucher) => clientRedeemableVoucher.id === voucher.id
  );
  const isApplied = appliedVoucherRedemptions.includes(voucher.id);

  if (isApplied) return AvailableVoucherStatus.Applied;

  if (isRedeemableVoucher) {
    if (selectedVoucherId === voucher.id) {
      return AvailableVoucherStatus.Selected;
    } else return AvailableVoucherStatus.Unselected;
  } else {
    return AvailableVoucherStatus.Inapplicable;
  }
};

const orderBasedOnVoucherStatus = {
  [AvailableVoucherStatus.Applied]: 0,
  [AvailableVoucherStatus.Unselected]: 1,
  [AvailableVoucherStatus.Selected]: 1,
  [AvailableVoucherStatus.Inapplicable]: 2,
};

/**
 * This function sorts the client vouchers by voucher redeeming status. if the voucher is redeemable (selected and unselected), it will be on top of the list, then will be the applied vouchers, and then the inapplicable vouchers.
 * @param a
 * @param b
 * @returns
 */

export const sortClientVouchersByVoucherStatus = (
  a: VoucherPurchaseWithStatusAndRedeemableItemsTitle,
  b: VoucherPurchaseWithStatusAndRedeemableItemsTitle
) => {
  return orderBasedOnVoucherStatus[a.voucherStatus] - orderBasedOnVoucherStatus[b.voucherStatus];
};
