import { Grid } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import usePaymentMethodTitle from "hooks/usePaymentMethodTitle";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { Invoice, RefundInvoicePayment } from "types/Checkout";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import PaymentSummaryItem from "./PaymentSummaryItem";

type CheckoutRefundInvoicePaymentsSummaryProps = {
  invoice: Invoice;
};

const CheckoutRefundInvoicePaymentsSummary = ({
  invoice,
}: CheckoutRefundInvoicePaymentsSummaryProps) => {
  const { t } = useTranslation(["checkout"]);

  const { getPaymentMethodTitle, arePaymentMethodsLoading } = usePaymentMethodTitle({
    isForPos: true,
  });

  const { checkoutModalView } = useCheckoutModal();

  const { balance, refundTotal, watch, setValue } = useCheckoutFormContext();
  const { refundPayments = [] } = watch("refundInvoice") || {};
  const invoiceTotal = Number(invoice?.total) || 0;

  const refundPaymentsWithPaymentType = refundPayments.filter((refundPayment) =>
    Boolean(refundPayment.paymentType !== null)
  );

  const refundPaymentsWithWalletType = refundPayments.filter((refundPayment) =>
    Boolean(refundPayment.wallet)
  );

  const getPaymentSummaryItemTitle = (refundPayment: RefundInvoicePayment) => {
    if ("paymentType" in refundPayment && refundPayment.paymentType)
      return getPaymentMethodTitle(refundPayment.paymentType);
    else {
      return t("checkout:wallet");
    }
  };

  const handelRemovePayment = (paymentIndex: number) => {
    const newPayments = refundPayments.filter((_, index) => index !== paymentIndex);

    setValue("refundInvoice.refundPayments", newPayments);
  };

  if (arePaymentMethodsLoading) return <CentredSpinnerBox />;

  return (
    <Grid item container>
      <PaymentSummaryItem
        title={t("checkout:invoiceTotal")}
        price={invoiceTotal}
        hasBoldPrice
        hasBoldTitle
      />

      <PaymentSummaryItem
        title={t("checkout:refundTotal")}
        price={refundTotal}
        hasBoldPrice
        hasBoldTitle
        priceProps={{ color: "error.main" }}
      />

      {refundPaymentsWithPaymentType.map((refundPayment, index) => (
        <PaymentSummaryItem
          key={index}
          title={t("checkout:refunded")}
          subtitle={getPaymentSummaryItemTitle(refundPayment)}
          onRemove={() => handelRemovePayment(index)}
          disabled={checkoutModalView === CheckoutModalView.ConfirmRefund}
          price={refundPayment.amount}
          hasBoldTitle
        />
      ))}

      {refundPaymentsWithWalletType.map((refundPayment, index) => (
        <PaymentSummaryItem
          key={index}
          title={t("checkout:refunded")}
          subtitle={t("checkout:offlineWallet")}
          onRemove={() => handelRemovePayment(index)}
          disabled={checkoutModalView === CheckoutModalView.ConfirmRefund}
          price={refundPayment.amount}
          hasBoldTitle
        />
      ))}

      <PaymentSummaryItem title={t("checkout:balance")} price={balance} hasBoldPrice hasBoldTitle />
    </Grid>
  );
};

export default CheckoutRefundInvoicePaymentsSummary;
