import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Subscription } from "types/Subscription";

import { SliceStatus } from "../utils";
import api from "./subscriptionsApi";

export const subscriptionsAdapter = createEntityAdapter<Subscription>({
  selectId: (subscription) => subscription.id,
});

const initialState = subscriptionsAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  page: number[];
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  page: [],
});

export const getAllSubscriptions = createThunkFromApiWithType(
  "subscriptions/getAllSubscriptions",
  api.getAllSubscriptions
);

export const getAllSubscriptionsData = createThunkFromApiWithType(
  "subscriptions/subscriptionsList",
  api.getSubscriptions
);

export const appendAllSubscriptionsData = createThunkFromApiWithType(
  "subscriptions/appendSubscriptionList",
  api.getSubscriptions
);

export const createSubscription = createThunkFromApiWithType(
  "subscriptions/createSubscription",
  api.createSubscription
);

export const updateSubscription = createThunkFromApiWithType(
  "subscriptions/updateSubscription",
  api.updateSubscription
);

export const deleteSubscription = createThunkFromApiWithType(
  "subscriptions/deleteSubscription",
  api.removeSubscription
);

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllSubscriptions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllSubscriptions.fulfilled, (state, { payload }) => {
      subscriptionsAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllSubscriptions.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getAllSubscriptionsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllSubscriptionsData.fulfilled, (state, { payload }) => {
      subscriptionsAdapter.upsertMany(state, payload.results);
      state.page = payload.results.map((subscription) => subscription.id);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(getAllSubscriptionsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllSubscriptionsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllSubscriptionsData.fulfilled, (state, { payload }) => {
      subscriptionsAdapter.upsertMany(state, payload.results);
      state.page = [...state.page, ...payload.results.map((subscription) => subscription.id)];
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendAllSubscriptionsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createSubscription.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createSubscription.fulfilled, (state, { payload }) => {
      subscriptionsAdapter.upsertOne(state, payload);
      state.page = [payload.id, ...state.page];

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createSubscription.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(updateSubscription.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateSubscription.fulfilled, (state, { payload }) => {
      subscriptionsAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateSubscription.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(deleteSubscription.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteSubscription.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        subscriptionsAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
        state.page = state.page.filter((item) => item !== id);
      }
    );

    reducers.addCase(deleteSubscription.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default subscriptionsSlice.reducer;
