import { Box } from "@mui/material";
import { Bottle } from "assets/icon";
import { theme } from "configs";
import { useNavigate } from "react-router-dom";

import NotificationCard from "./NotificationCard";
import { NotificationCardProps } from "./NotificationCardType";

const ProductShortageNotificationCard = (props: NotificationCardProps) => {
  const { onClick, ...remainingProps } = props;
  const navigate = useNavigate();

  const handleOnClick = () => {
    onClick();
    navigate(`inventory/product/${props.notification.description}`);
  };

  return (
    <NotificationCard
      icon={
        <Box
          sx={{
            backgroundColor: "#64C5B8",
            borderRadius: theme.spacing(1),
            width: 40,
            height: 40,
            padding: theme.spacing(0.5),
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Bottle sx={{ color: "white" }} fontSize="large" />
        </Box>
      }
      {...remainingProps}
      showDescription={false}
      shouldShowDateTimeOfEvent={false}
      onClick={handleOnClick}
    />
  );
};

export default ProductShortageNotificationCard;
