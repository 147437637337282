import { Box, Container, Tab, Tabs, styled, tabsClasses } from "@mui/material";
import { TabsProps } from "@mui/material/Tabs";
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQuery from "utils/navigation/useQuery";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  withContainer: boolean;
}

type TabProps = {
  component: ReactNode;
  id: number;
  label: string;
};

type PageTabProps = {
  tabs: TabProps[];
  tabsProps?: TabsProps;
  withContainer?: boolean;
  withRoutes?: boolean;
  pathnameToSetOnUrl?: string;
};

function TabPanel(props: TabPanelProps) {
  const { withContainer, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`page-tabpanel-${index}`}
      aria-labelledby={`page-tab-${index}`}
      {...other}
    >
      {withContainer
        ? value === index && <Container>{children}</Container>
        : value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `page-tab-${index}`,
    "aria-controls": `page-tabpanel-${index}`,
  };
}

export default function PageTabs({
  tabs,
  withContainer = true,
  withRoutes = true,
  tabsProps,
  pathnameToSetOnUrl = "",
}: PageTabProps) {
  const navigate = useNavigate();
  const query = useQuery();

  const checkIsValidTabIndex = useCallback(
    (index: number) => tabs.some((tab) => tab.id === index),
    [tabs]
  );

  const pageQuery = query.get("page");
  const pageQueryNumber = parseInt(query.get("page") ?? "0");

  const initialTabIndex = tabs[0].id;

  const currentPageIndex =
    pageQuery && checkIsValidTabIndex(pageQueryNumber) ? pageQueryNumber : initialTabIndex;

  const [activeTabIndex, setActiveTabIndex] = useState(
    withRoutes && currentPageIndex ? currentPageIndex : initialTabIndex
  );

  // if the user typed invalid page query in the URL, redirect to the first tab
  useEffect(() => {
    if (withRoutes) {
      if (pageQuery !== activeTabIndex.toString()) {
        navigate({
          pathname: pathnameToSetOnUrl,
          search: `?${createSearchParams({
            page: initialTabIndex.toString(),
          })}`,
        });
      }
    }
  }, [
    withRoutes,
    activeTabIndex,
    checkIsValidTabIndex,
    initialTabIndex,
    navigate,
    pageQuery,
    pathnameToSetOnUrl,
  ]);

  const setPageInUrl = (val: string, pathnameToSetOnUrl: string) => {
    navigate({
      pathname: pathnameToSetOnUrl,
      search: `?${createSearchParams({
        page: val,
      })}`,
    });
  };

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue);

    if (withRoutes) {
      setPageInUrl(String(newValue), pathnameToSetOnUrl);
    }
  };

  const tabLabels = tabs.map((tab) => (
    <Tab key={tab.label} label={tab.label} {...a11yProps(tab.id)} />
  ));

  const panels = tabs.map((tab) => (
    <TabPanel withContainer={withContainer} key={tab.label} value={activeTabIndex} index={tab.id}>
      {tab.component}
    </TabPanel>
  ));

  return (
    <Box sx={{ width: "100%" }}>
      <TabsWrapper sx={{ borderBottom: 1, borderColor: "divider", zIndex: 99 }}>
        <Tabs
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons={false}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
          {...tabsProps}
        >
          {tabLabels}
        </Tabs>
      </TabsWrapper>
      {panels}
    </Box>
  );
}

const TabsWrapper = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: "0",
  backgroundColor: theme.palette.common.white,
}));
