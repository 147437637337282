import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { supplierContactsAdapter } from "./supplierContactsSlice";

export const selectSupplierContactsLoading = (state: RootState) =>
  state.supplierContacts.status === SliceStatus.LOADING;

export const selectSupplierContactsState = (state: RootState) => state.supplierContacts;

const { selectAll } = supplierContactsAdapter.getSelectors(selectSupplierContactsState);

export const selectAllSupplierContacts = selectAll;

export const selectSupplierContactsBySupplierId = (id: number) => (state: RootState) =>
  selectAll(state)
    .filter((supplierContact) => supplierContact.supplier === id)
    .map((supplierContact) => ({
      ...supplierContact,
    }));
