import { Close } from "@bookpeep/ui";
import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import { useWebsocketRequest } from "hooks/useWebsocketRequest";
import { camelizeKeys } from "humps";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "store";
import { selectAllNewNotifications } from "store/selectors";
import { handleWebSocketUpdates } from "store/slices/notifications/notificationsSlice";
import { Notification } from "types/Notification";

import NotificationCardTranslator from "./NotificationCard/NotificationCardTranslator";

const NotificationToast = () => {
  const newNotifications = useSelector(selectAllNewNotifications);

  const [isOpen, setIsOpen] = useState(true);
  const [latestNotification, setLatestNotification] = useState<Nullable<Notification>>(null);

  const { lastMessage } = useWebsocketRequest({
    webSocketUrl: `${process.env.REACT_APP_SOCKET_URL}/ws/organization/`,
  });

  const prevLastMessage = useRef<Nullable<MessageEvent>>(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpen(false);
    setLatestNotification(null);
  };

  useEffect(() => {
    if (lastMessage && lastMessage !== prevLastMessage.current) {
      prevLastMessage.current = lastMessage;

      const data = JSON.parse(lastMessage.data);

      if (data.type === "notification") {
        dispatch(handleWebSocketUpdates(camelizeKeys(data.message)));
        setIsOpen(true);
      }
    }
    if (newNotifications.length > 0 && lastMessage) {
      setLatestNotification(newNotifications[0]);
    }
  }, [dispatch, lastMessage, newNotifications]);

  if (!latestNotification) return null;

  return (
    <Snackbar
      open={isOpen && !!latestNotification}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Alert
        icon={false}
        sx={{
          width: "100%",
          backgroundColor: "bg.blank",
          padding: 0,
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
          margin: 0,
        }}
      >
        <Box display="flex" width="384px">
          <IconButton
            onClick={() => setIsOpen(false)}
            sx={{
              position: "absolute",
              top: -15,
              left: -15,
              zIndex: 9999,
              bgcolor: "bg.blank",
              padding: 2,
              width: "25px",
              height: "25px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
            }}
          >
            <Close fontSize="small" color="primary" />
          </IconButton>

          <NotificationCardTranslator
            notification={latestNotification}
            backgroundColor="bg.blank"
            onClick={handleClose}
          />
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default NotificationToast;
