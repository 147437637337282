import { Box } from "@mui/material";
import { ConfirmationDialogModal } from "components/common";
import { ConfirmationModalSeverity } from "components/common/ConfirmationDialogModal";
import { useCheckoutFormContext } from "modals/CheckoutModal/CheckoutFormContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VoucherPurchase } from "types/VoucherPurchase";

import AvailableVouchersSection from "./AvailableVouchersSection";
import RedeemableAmountSection from "./RedeemableAmountSection";

type ApplyVoucherFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ApplyVoucherFormModal = ({ isOpen, onClose }: ApplyVoucherFormModalProps) => {
  const { t } = useTranslation("vouchers");

  const [selectedVoucher, setSelectedVoucher] = useState<Nullable<VoucherPurchase>>(null);
  const [redemptionAmount, setRedemptionAmount] = useState<number>(0);

  const { watch, setValue } = useCheckoutFormContext();

  const { voucherRedemptions } = watch();

  const currentVoucherRedemptionIndex = voucherRedemptions?.length || 0;
  const currentVoucherRedemptionPath: `voucherRedemptions.${number}` = `voucherRedemptions.${currentVoucherRedemptionIndex}`;

  const handleRedeem = () => {
    if (selectedVoucher) {
      setValue(currentVoucherRedemptionPath, {
        voucherPurchase: selectedVoucher.id,
        value: redemptionAmount,
      });

      handleClose();
    }
  };

  const handleClose = () => {
    onClose();

    setSelectedVoucher(null);
    setRedemptionAmount(0);
  };

  const shouldDisableRedeemButton = !selectedVoucher || !redemptionAmount;

  const handleChangeSelectedVoucherId = (voucherPurchase: VoucherPurchase) => {
    if (!voucherPurchase) return;

    if (selectedVoucher?.id === voucherPurchase.id) {
      setSelectedVoucher(null);
    } else {
      setSelectedVoucher(voucherPurchase);
    }
  };

  const handleChangeRedemptionAmount = (amount: number) => {
    setRedemptionAmount(amount);
  };

  return (
    <ConfirmationDialogModal
      isOpen={isOpen}
      onClose={handleClose}
      title={t("applyVoucher")}
      onConfirmButtonClick={handleRedeem}
      onCancelButtonClick={handleClose}
      confirmButtonLabel={t("redeem")}
      confirmButtonSeverity={ConfirmationModalSeverity.Moderate}
      shouldDisableConfirmButton={shouldDisableRedeemButton}
      hasTitleOnTop
    >
      <Box py={3}>
        <AvailableVouchersSection
          selectedVoucher={selectedVoucher}
          onChangeSelectedVoucher={handleChangeSelectedVoucherId}
        />

        <RedeemableAmountSection
          redemptionAmount={redemptionAmount}
          onChangeRedemptionAmount={handleChangeRedemptionAmount}
          shouldDisableAmountField={!selectedVoucher}
          hasSelectedVoucher={!!selectedVoucher}
          selectedVoucher={selectedVoucher}
        />
      </Box>
    </ConfirmationDialogModal>
  );
};

export default ApplyVoucherFormModal;
