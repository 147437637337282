import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api, { SMSReminder } from './smsRemindersApi'
import { SliceStatus } from '../utils';
import createThunkFromApiWithType from '../../utils/createThunkFromApiWithType';

export enum SMSReminderTypes {
  APPOINTMENT_CONFIRMATION = 'appointmentConfirmation',
  APPOINTMENT_REMINDER = 'appointmentReminder',
  APPOINTMENT_RESCHEDULE = 'appointmentReschedule',
}

const smsRemindersAdapter = createEntityAdapter<SMSReminder>();

const initialState = smsRemindersAdapter.getInitialState<{
  status: SliceStatus;
  smsReminders: SMSReminder
}>({
  status: SliceStatus.IDLE,
  smsReminders: {
    id: 0,
    isSmsActive: false,
    appointmentConfirmation: false,
    appointmentReminder: false,
    appointmentReminderTime: 0,
    appointmentReschedule: false,
    smsCredit: 0,
    organization: 0,
  }
});

export const getSmsReminderOptions = createThunkFromApiWithType(
  "smsReminders/getSmsReminderOptions",
  api.getSmsReminderOptions
);

export const updateSmsReminderOptions = createThunkFromApiWithType(
  "smsReminders/updateSmsReminderOptions",
  api.updateSmsReminderOptions
);

export const smsRemindersSlice = createSlice({
  name: "smsReminders",
  initialState,
  reducers: {},
  extraReducers: (reducers) => {
    reducers.addCase(getSmsReminderOptions.fulfilled, (state, { payload }) => {
      state.smsReminders = payload;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSmsReminderOptions.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(getSmsReminderOptions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateSmsReminderOptions.fulfilled, (state, { payload }) => {
      state.smsReminders = payload;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateSmsReminderOptions.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateSmsReminderOptions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
  }
})

export default smsRemindersSlice.reducer;