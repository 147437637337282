import getFormattedDuration from "helpers/getFormattedDuration";
import getFormattedPrice from "helpers/getFormattedPrice";
import { totalDurationInMinutes, totalPrice, totalServices } from "modals/AppointmentDrawer/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";

type UseAppointmentSummaryProps = {
  appointmentId: number | undefined;
};

const useAppointmentSummary = ({ appointmentId }: UseAppointmentSummaryProps) => {
  const { t } = useTranslation();
  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));

  const onlineBookingPayments =
    appointment?.onlinePayments
      ?.filter((onlineInvoice) => onlineInvoice.status === "PAID")
      .reduce((currentTotal, onlineInvoice) => currentTotal + Number(onlineInvoice.total), 0) || 0;

  const total =
    appointment?.createdOnline && appointment?.onlinePayments?.length
      ? `${t("total")}: ${t("currency")} ${getFormattedPrice(totalPrice(appointment))} (${t(
          "downPayment"
        )}: ${t("currency")} ${getFormattedPrice(onlineBookingPayments)}) `
      : `${t("total")}: ${t("currency")} ${getFormattedPrice(totalPrice(appointment))},`;

  const duration = `${getFormattedDuration(totalDurationInMinutes(appointment))}`;

  const services = `${totalServices(appointment)} ${t("services")}`;

  return `${total} ${services} (${duration})`;
};

export default useAppointmentSummary;
