import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/packagesV2/packagesApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Package } from "types/Package";

import { SliceStatus } from "../utils";

export const packagesAdapter = createEntityAdapter<Package>({
  selectId: (pkg) => pkg.id,
});

const { selectAll } = packagesAdapter.getSelectors();

const initialState = packagesAdapter.getInitialState<{
  status: SliceStatus;
  isRequestPending: SliceStatus;
  optimisticCache: Nullable<Package[]>;
}>({
  status: SliceStatus.IDLE,
  isRequestPending: SliceStatus.IDLE,
  optimisticCache: null,
});

export const getPackageDetails = createThunkFromApiWithType(
  "packagesV2/getPackageDetails",
  api.getPackageDetails
);

export const getAllPackages = createThunkFromApiWithType(
  "packagesV2/getAllPackages",
  api.getAllPackages,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const createPackage = createThunkFromApiWithType(
  "packagesV2/createPackage",
  api.createPackage
);

export const updatePackage = createThunkFromApiWithType(
  "packagesV2/updatePackage",
  api.updatePackage
);

export const deletePackage = createThunkFromApiWithType(
  "packagesV2/deletePackage",
  api.removePackage
);

export const updatePackagePosition = createThunkFromApiWithType(
  "packagesV2/updatePackagePosition",
  api.updatePackagePosition
);

export const packagesSlice = createSlice({
  name: "packagesV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    //  NOTE: we don't need to create getPackageDetails.pending, getPackageDetails.fulfilled, getPackageDetails.rejected, because Here we don't want to append the package details to the list of packages, because they don't have the same shape.

    reducers.addCase(getAllPackages.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllPackages.fulfilled, (state, { payload }) => {
      packagesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllPackages.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createPackage.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(createPackage.fulfilled, (state, { payload }) => {
      const { package: pkg } = payload;

      packagesAdapter.upsertOne(state, pkg);

      state.isRequestPending = SliceStatus.IDLE;
    });

    reducers.addCase(createPackage.rejected, (state) => {
      return { ...state, isRequestPending: SliceStatus.FAILED };
    });

    reducers.addCase(updatePackage.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(updatePackage.fulfilled, (state, { payload }) => {
      const { package: pkg } = payload;

      packagesAdapter.upsertOne(state, pkg);

      state.isRequestPending = SliceStatus.IDLE;
    });

    reducers.addCase(updatePackage.rejected, (state) => {
      return { ...state, isRequestPending: SliceStatus.FAILED };
    });

    reducers.addCase(
      updatePackagePosition.pending,
      (
        state,
        {
          meta: {
            arg: { data },
          },
        }
      ) => {
        const packagesV2 = selectAll(state);
        state.optimisticCache = packagesV2;

        packagesAdapter.upsertMany(state, data);
      }
    );

    reducers.addCase(updatePackagePosition.fulfilled, (state, { payload }) => {
      state.optimisticCache = null;
      packagesAdapter.upsertMany(state, payload);
    });

    reducers.addCase(updatePackagePosition.rejected, (state) => {
      if (state.optimisticCache) {
        packagesAdapter.setAll(state, state.optimisticCache);
        state.optimisticCache = null;
      }
    });

    reducers.addCase(deletePackage.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(deletePackage.fulfilled, (state, { payload: { package: pkg } }) => {
      packagesAdapter.upsertOne(state, pkg);

      state.status = SliceStatus.IDLE;
      state.isRequestPending = SliceStatus.IDLE;
    });

    reducers.addCase(deletePackage.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED, isRequestPending: SliceStatus.FAILED };
    });
  },
});

export default packagesSlice.reducer;
