import { Close, UserReading } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

type PurchasableItemsDrawerHeaderProps = {
  title?: string;
  onClose: () => void;
};

const PurchasableItemsDrawerHeader = ({ title, onClose }: PurchasableItemsDrawerHeaderProps) => {
  const { t } = useTranslation("subscriptions");
  const isMobileView = useIsMobileView();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      py={1.5}
      width="100%"
      boxShadow="divider"
    >
      <Box display="flex" alignItems="center" flex={1}>
        <UserReading sx={{ mr: 1 }} />

        <Typography align={isMobileView ? "center" : "left"} variant="h2">
          {title ? title : t("addItem")}
        </Typography>
      </Box>

      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default PurchasableItemsDrawerHeader;
