import { ChevronDown, ChevronUp } from "@bookpeep/ui";
import { Box, Button, ButtonProps, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dontForwardProps } from "helpers/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";

type NavListItemProps = {
  item: RouteObject;
};

const NavListItem = ({ item }: NavListItemProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(menuAnchor);

  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeUserMenu = () => {
    setMenuAnchor(null);
  };

  const { path = "/", title = "", children = null } = item;

  let match = false;
  if (pathname === "/" && path === "/" && !item.showChildren) match = true;
  else if (path !== "/") match = pathname.includes(path);

  if (children) {
    children.forEach((child) => {
      if (child.path && pathname.includes(child.path)) match = true;
    });
  }

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(path);
  };

  if (item.showChildren && item.children) {
    return (
      <>
        <NavListItemButton onClick={openUserMenu} isSelected={match}>
          <NavListItemText
            primary={
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="primary" mr={1}>
                  {t(title)}
                </Typography>

                {isMenuOpen ? <ChevronUp fontSize="small" /> : <ChevronDown fontSize="small" />}
              </Box>
            }
          />
        </NavListItemButton>

        <StyledMenu anchorEl={menuAnchor} open={isMenuOpen} onClose={closeUserMenu}>
          {item.children
            .filter((child) => child.hasPermission)
            .map((child) => (
              <StyledMenuItem
                key={child.path}
                onClick={() => {
                  closeUserMenu();
                  navigate(child.path || "");
                }}
                isSelected={(child.path && pathname.includes(child.path)) || false}
              >
                {t(child.title || "")}
              </StyledMenuItem>
            ))}
        </StyledMenu>
      </>
    );
  }

  return (
    <NavListItemButton onClick={handleNavigation} isSelected={match}>
      <NavListItemText
        primary={
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="primary">{t(title)}</Typography>
          </Box>
        }
      />
    </NavListItemButton>
  );
};

export default NavListItem;

type NavListItemButtonProps = ButtonProps & {
  isSelected: boolean;
};

const NavListItemButton = styled(
  Button,
  dontForwardProps("isSelected")
)<NavListItemButtonProps>(({ theme, isSelected }) => ({
  borderRadius: theme.spacing(1),

  backgroundColor: isSelected ? "#322C9C" : "inherit",
}));

const NavListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "150px",
    marginRight: theme.spacing(2),
    boxShadow: theme.shadows.deepCard,
  },
}));

const StyledMenuItem = styled(
  MenuItem,
  dontForwardProps("isSelected")
)<NavListItemButtonProps>(({ theme, isSelected }) => ({
  color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.text.secondary,
  },
}));
