import { Card, TableHead, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Dispatch, Fragment, SetStateAction } from "react";

import CentredSpinnerBox from "../CentredSpinnerBox";
import EmptyGraphicSection from "../EmptySectionGraphic";
import PeepTableHeader from "./PeepTableHeader";
import { HeaderOrder, Order, TableHeader, TableItem, TableRowData } from "./TableTypes";

interface PeepTableProps {
  maxHeight?: string;
  emptySectionTitle: string;
  emptySectionPadding?: number;
  emptySectionWidth?: string;
  isLoading: boolean;
  headers: TableHeader[];
  items: TableItem[] | TableRowData[];
  ordering?: string[];
  setOrdering?: Dispatch<SetStateAction<string[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  multipleSort?: boolean;
  rowPadding?: number;
  rowHeight?: string;
}

const PeepTable = ({
  maxHeight,
  isLoading,
  headers,
  items,
  ordering,
  setOrdering,
  setPage,
  emptySectionTitle,
  emptySectionPadding = 10,
  emptySectionWidth = "304px",
  multipleSort = false,
  rowPadding = 14,
  rowHeight = "none",
}: PeepTableProps) => {
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string,
    propertyOrder: Order
  ) => {
    /* 
    Note: ordering list looks like this: ['-invoice', 'reference']
    - Descending order is represented by '-'
    - Ascending order remains as is
    When finding if a sortable property exists we use substring to ignore '-'
    */

    if (ordering && setOrdering) {
      let orderingList: string[] = ordering;

      if (multipleSort) {
        // Check ordering list if an item has substring of the property name
        const propertyIndex = orderingList.findIndex((item) => item.includes(property));

        // if item exists we remove it from the list
        if (propertyIndex > -1) {
          orderingList.splice(propertyIndex, 1);
        }

        // if item is reflected to be sorted we add it to the list with respect to its sorting order
        if (propertyOrder === HeaderOrder.ASC || propertyOrder === HeaderOrder.DESC) {
          orderingList = [
            `${propertyOrder === HeaderOrder.ASC ? "" : "-"}${property}`,
            ...orderingList,
          ];
        } else {
          orderingList = [...orderingList];
        }
      } else {
        // Single item sort overrides ordering list

        if (propertyOrder === HeaderOrder.ASC || propertyOrder === HeaderOrder.DESC) {
          orderingList = [`${propertyOrder === HeaderOrder.ASC ? "" : "-"}${property}`];
        } else {
          orderingList = [];
        }
      }

      if (setPage) setPage(1);
      setOrdering(orderingList);
    } else {
      throw new Error("Ordering and setOrdering props are required for sorting");
    }
  };

  return (
    <TableCard>
      <TableContainer sx={{ display: "flex", flex: 1, maxHeight: maxHeight || "none" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers?.map((header: TableHeader) => (
                <PeepTableHeader
                  orderBy={ordering}
                  key={header.key}
                  header={header}
                  headerCount={headers.length}
                  onRequestSort={handleRequestSort}
                />
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headers?.length}>
                  <CentredSpinnerBox />
                </TableCell>
              </TableRow>
            ) : (
              items.map((item: TableItem | TableRowData, index: number) => {
                const isCollapsibleItem = "cells" in item;

                return (
                  <Fragment key={(item.id as number) || index}>
                    <TableRow
                      sx={{ height: rowHeight, "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {isCollapsibleItem
                        ? headers?.map((header: TableHeader) => {
                            if (!header) return null;
                            const tableItemWithVariants = item as TableRowData;

                            return (
                              <TableCell
                                align={tableItemWithVariants.cells[header.key].align}
                                key={header.key}
                                style={{
                                  width: header.width || "auto",
                                  paddingTop: `${rowPadding}px`,
                                  paddingBottom: `${rowPadding}px`,
                                }}
                                scope="row"
                                onClick={
                                  tableItemWithVariants.cells[header.key].action &&
                                  tableItemWithVariants.cells[header.key].action
                                }
                              >
                                {tableItemWithVariants.cells[header.key].component}
                              </TableCell>
                            );
                          })
                        : headers?.map((header: TableHeader) => {
                            const tableItem = item as TableItem;

                            return (
                              <TableCell
                                align={tableItem[header.key].align}
                                key={header.key}
                                style={{
                                  width: header.width || "auto",
                                  paddingTop: `${rowPadding}px`,
                                  paddingBottom: `${rowPadding}px`,
                                }}
                                scope="row"
                                onClick={
                                  tableItem[header.key].action && tableItem[header.key].action
                                }
                              >
                                {tableItem[header.key].component}
                              </TableCell>
                            );
                          })}
                    </TableRow>

                    <>{item.isCollapsible && item.variants}</>
                  </Fragment>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!isLoading && !items.length && (
        <EmptyGraphicSection
          description={emptySectionTitle}
          pb={emptySectionPadding}
          width={emptySectionWidth}
        />
      )}
    </TableCard>
  );
};

export default PeepTable;

const TableCard = styled(Card)(({ theme }) => ({
  borderRadius: "10px",
  height: "100%",
  backgroundColor: theme.palette.bg.blank,
  "&:hover": {
    boxShadow: "none",
  },
}));
