import { Box, Stack, Typography } from "@mui/material";
import renderNullableValue from "helpers/renderNullableValue";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById } from "store/clients/clientsSlice";

type InfoItemProps = {
  label: string;
  value: Nullable<string>;
};

type ClientInfoTabProps = {
  clientId: number;
};

const ClientInfoTab = ({ clientId }: ClientInfoTabProps) => {
  const { t } = useTranslation("client");

  const client = useSelector(selectClientById(clientId));

  const InfoItem = ({ label, value }: InfoItemProps) => (
    <Box>
      <Typography variant="h4" color="text.label" fontWeight={400}>
        {label}
      </Typography>

      <Typography
        variant="h4"
        fontWeight={500}
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {renderNullableValue(value)}
      </Typography>
    </Box>
  );

  const formatDateOfBirth = client?.dateOfBirth
    ? DateTime.fromISO(client?.dateOfBirth).toFormat("dd MMMM yyyy")
    : null;

  return (
    <Stack p={3} gap={2}>
      <InfoItem label={t("forms.mobile")} value={client?.phoneNumber || ""} />
      <InfoItem label={t("forms.email")} value={client?.email || ""} />
      <InfoItem label={t("forms.dateOfBirth")} value={formatDateOfBirth} />
      <InfoItem
        label={t("forms.gender")}
        value={t(`forms.genderOptions.${client?.gender.toLowerCase()}`)}
      />
      <InfoItem label={t("notes")} value={client?.notes || ""} />
    </Stack>
  );
};

export default ClientInfoTab;
