import { AddPlus } from "@bookpeep/ui";
import { Button, styled } from "@mui/material";

type PeepAddIconButtonProps = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
};

const PeepAddIconButton = ({
  onClick,
  label,
  disabled = false,
  ...buttonProps
}: PeepAddIconButtonProps) => {
  return (
    <StyledButton
      disabled={disabled}
      variant="text"
      color="primary"
      startIcon={<StyledAddIcon color="primary" />}
      onClick={onClick}
      disableRipple
      {...buttonProps}
    >
      {label}
    </StyledButton>
  );
};

export default PeepAddIconButton;

const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },

  "& .MuiButton-textPrimary": {
    fontWeight: 700,
    fontSize: "1rem",
  },
}));

const StyledAddIcon = styled(AddPlus)(({ theme }) => ({
  marginRight: theme.spacing(0.625),
}));
