import { createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./permissionOptionsApi";

const initialState = {
  permissionOptions: [],

  isLoading: false,
  error: "",
};

export const getAllPermissionOptions = createThunkFromApi(
  "permissionOptions/getAllPermissionOptions",
  api.getAllPermissionOptions
);

const permissionOptionsSlice = createSlice({
  name: "permissionOptions",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllPermissionOptions.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllPermissionOptions.fulfilled]: (_, { payload }) => {
      const permissionOptions = payload;

      return {
        permissionOptions,
        isLoading: false,
        error: "",
      };
    },

    [getAllPermissionOptions.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export const selectAllPermissionOptions = ({ permissionOptions }) =>
  permissionOptions;

export default permissionOptionsSlice.reducer;
