import getFormattedPrice from "helpers/getFormattedPrice";
import { getServiceVariantName } from "helpers/getServiceVariantName";
import { TFunction } from "react-i18next";
import { BasicDetailsDataType } from "types/DetailsWithLogs";
import { ServiceVariantWithService } from "types/ServiceV2";
import { UnNormalizedVoucher } from "types/Voucher";

enum OutletTypes {
  SellingOutlets = "sellingOutlets",
  RedeemingOutlets = "redeemingOutlets",
}

const getVoucherServices = (voucherDetails: UnNormalizedVoucher, t: TFunction) => {
  if (voucherDetails.hasAllServices) return t("allServices");
  else if (voucherDetails?.serviceVariants?.length === 1)
    return getServiceVariantName(
      voucherDetails?.serviceVariants[0],
      voucherDetails?.serviceVariants[0].service
    );
  else {
    return voucherDetails?.serviceVariants?.reduce(function (
      acc: string,
      value: ServiceVariantWithService,
      index: number
    ) {
      var comma = voucherDetails?.serviceVariants?.length === index + 1 ? "" : ", ";
      return acc + getServiceVariantName(value, value.service) + comma;
    },
    "");
  }
};

const getLocations = (voucherDetails: UnNormalizedVoucher, outletsType: OutletTypes) => {
  if (voucherDetails?.[outletsType]?.length === 0) return " - ";
  else {
    return voucherDetails?.[outletsType]?.reduce((acc, value, index) => {
      var comma = voucherDetails?.[outletsType]?.length === index + 1 ? "" : ", ";
      return acc + value.name + comma;
    }, "");
  }
};

/* Prepare The data for active voucher details Modal */
export const getActiveVoucherDetailsData = (
  voucherDetails: UnNormalizedVoucher,
  t: TFunction
): BasicDetailsDataType[] => {
  return [
    { id: 1, label: t("name"), value: voucherDetails.name, primaryColor: true },

    {
      id: 2,
      label: t("servicesIncluded"),
      value: getVoucherServices(voucherDetails, t),
    },
    {
      id: 3,
      label: t("locationForSale"),
      value: getLocations(voucherDetails, OutletTypes.SellingOutlets),
    },
    {
      id: 4,
      label: t("locationForRedeeming"),
      value: getLocations(voucherDetails, OutletTypes.RedeemingOutlets),
    },
    {
      id: 5,
      label: t("value"),
      value: `${t("currency")} ${getFormattedPrice(voucherDetails.price)}`,
    },
  ];
};
