import { Box, Stack, Typography, styled } from "@mui/material";
import getFormattedPrice from "helpers/getFormattedPrice";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { VoucherPurchaseInvoice } from "types/VoucherPurchase";

type InvoiceVoucherCardProps = {
  voucherPurchase: VoucherPurchaseInvoice;
};

const InvoiceVoucherCard = ({ voucherPurchase }: InvoiceVoucherCardProps) => {
  const { t } = useTranslation("vouchers");

  if (!voucherPurchase) {
    return null;
  }

  return (
    <SectionBackground
      divider={
        <Box color="white" textAlign="center">
          - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        </Box>
      }
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography color="white" variant="secondary">
            {t("voucherName")}
          </Typography>

          <Typography color="white" variant="secondary">
            {t("voucherValue")}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between" pt={0.5}>
          <Typography color="white" variant="h3" sx={{ wordBreak: "break-word" }}>
            {voucherPurchase.name}
          </Typography>

          <Typography color="white" variant="h3" whiteSpace="nowrap">
            {t("currency")} {getFormattedPrice(voucherPurchase.price)}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box display="flex" justifyContent="space-between">
          <Typography color="white" variant="secondary">
            {t("phoneNumber")}
          </Typography>

          <Typography color="white" variant="secondary">
            {voucherPurchase.redeemableBy.phoneNumber}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography color="white" variant="secondary">
            {t("code")}
          </Typography>

          <Typography color="white" variant="secondary">
            {voucherPurchase.code}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography color="white" variant="secondary">
            {t("validTill")}
          </Typography>

          <Typography color="white" variant="secondary">
            {DateTime.fromISO(voucherPurchase.expiresOn).toFormat("dd MMMM yyyy")}
          </Typography>
        </Box>
      </Box>
    </SectionBackground>
  );
};

export default InvoiceVoucherCard;

const SectionBackground = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(/images/voucher-bg.svg)`,
  backgroundSize: "contain",
  objectFit: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",

  width: "350px",
  height: "200px",
  position: "relative",

  padding: theme.spacing(2),
  justifyContent: "space-between",
}));
