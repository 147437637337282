import { ExistingClient, NewClient } from "types/Checkout";
import { Voucher } from "types/Voucher";
import http from "utils/http";

type VoucherResponse = {
  results: Voucher[];
  next: Nullable<string>;
  previous: Nullable<string>;
  totalPages: number;
};

export type VoucherCreateOrUpdateArguments = Omit<
  Voucher,
  "id" | "deprecated" | "createdAt" | "updatedAt" | "organization"
>;

export type GiftVoucherCreateArguments = {
  voucherPurchase: number;
  client: NewClient | ExistingClient | null;
  message?: string;
  email?: string;
};

export type EmailGiftVoucherArguments = {
  email: string;
  giftVoucherId: number;
};

const vouchersApi = {
  getAllVouchers: async () => http.get<Voucher[]>(`vouchers/brief/`),

  getVouchers: async ({
    page,
    ordering,
    searchQuery,
  }: {
    page: number;
    ordering: string;
    searchQuery: string;
  }) =>
    http.get<VoucherResponse>(
      `vouchers/vouchers/?page=${page}${ordering ? `&order_by=${ordering}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  createVoucher: async ({ data }: { data: VoucherCreateOrUpdateArguments }) =>
    http.post<Voucher>(`vouchers/vouchers/`, data),

  updateVoucher: async ({ id, data }: { id: number; data: VoucherCreateOrUpdateArguments }) =>
    http.put<Voucher>(`vouchers/vouchers/${id}/`, data),

  deleteVoucher: async ({ id }: { id: number }) => http.delete(`vouchers/vouchers/${id}/`),

  emailGiftVoucher: async ({ data }: { data: EmailGiftVoucherArguments }) =>
    http.put(`vouchers/email/`, data),
};

export default vouchersApi;
