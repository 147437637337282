import { Card } from "@mui/material";
import { styled } from "@mui/system";

const SectionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows.card,
}));

export default SectionCard;
