import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { productCategoriesAdapter } from "./productCategoriesSlice";

export const selectProductCategoriesLoading = (state: RootState) =>
  state.productCategories.status === SliceStatus.LOADING;

export const selectProductCategoryState = (state: RootState) => state.productCategories;

const { selectById, selectAll } = productCategoriesAdapter.getSelectors(selectProductCategoryState);

export const selectAllProductCategories = selectAll;

export const selectProductCategoryById = (id: number | string) => (state: RootState) =>
  selectById(state, id);
