import { StyledOptions } from "@emotion/styled";
import { Theme } from "@mui/material";

/**
 * Generates a styled component options object which excludes unwanted props
 * from being forwarded to the styled element.
 *
 * Use this to exclude props passed only for the sake of deriving styles.
 *
 * @params Any number of strings representing the props you want to intercept
 * @returns A styled options object with `shouldForwardProp` function set
 */
export const dontForwardProps = (...unwantedProps: string[]): StyledOptions<Theme> => ({
  shouldForwardProp: (prop: string) => !unwantedProps.includes(prop),
});

/**
 * Get the color based on client status
 *
 * @param {string} status
 * @returns {string} a hex color
 */

export const getClientStatusColor = (status: string): string => {
  if (status === "blocked") {
    return "#FFE7EB";
  }

  if (status === "vip") {
    return "#FCF3E4";
  }

  // otherwise it is "regular"
  return "#EAF4FF";
};

/**
 * Get the color based on status
 *
 * @param {string} status
 * @returns {string} a hex color
 */

export const getInvoiceStatusColor = (status: string): string => {
  switch (status.toUpperCase()) {
    case "UNPAID":
      return "#FFE7EB";

    case "PAID":
    case "ACTIVE":
      return "#E4FAF7";

    case "PARTIALLY":
      return "#EAF4FF";

    case "VOID":
      return "#F6E3FF";

    case "INACTIVE":
      return "#E6E9EC";

    case "SCHEDULED":
      return "#FFEED1";

    case "EXPIRED":
      return "#FEE1DD";

    // otherwise it is "refunded"
    default:
      return "#FCF3E4";
  }
};

export const generateTextWithDots = (name: string, characterCount: number): string => {
  if (name.length > characterCount) {
    return name.slice(0, characterCount) + "...";
  }
  return name;
};
