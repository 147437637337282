// TS NOTE: there are missing fields here i don't have time to waste on them since it's not my main task i'm gonna ignore it for now.

import { IndustryCategory } from "./IndustryCategory";
import { Resource } from "./Resource";

export enum ServiceVariantPricingType {
  From = "FROM",
  Fixed = "FIXED",
  Free = "FREE",
}

export type ServiceVariant = {
  id: number;
  name: string;
  duration: number;
  service: number;
  online: boolean;
  deprecated: boolean;
  price: string;
  pricingType: ServiceVariantPricingType;
  uptoPrice: Nullable<string>;
  arabicTranslation: {
    name: string;
  };
  isLoyaltyEligible?: boolean;
};

export type Service = {
  id: number;
  name: string;
  description: string;
  deprecated: boolean;
  online: boolean;
  outlets: number[];
  category: number;
  position: number;
  referenceCode: string;
  resources: Resource["id"][];
  needResources: boolean;
  resourceTypes: number[];
  industryCategory?: number;
  mpPosition: number;
  arabicTranslation: {
    name: string;
    description: string;
  };
  tag: Nullable<number>;
  hasCustomOnlinePaymentSettings: boolean
  paymentRuleSetting: PaymentRuleSetting
  partialPaymentPercentage: Nullable<number>
};

export enum PaymentRuleSetting {
  FULL_PAYMENT = "FULL_PAYMENT",
  DEPOSIT = "DEPOSIT",
  NO_PAYMENT = "NO_PAYMENT",
  UNDEFINED = "UNDEFINED"
}

export type ServiceDetail = Service & {
  employees?: number[];
};

export type ServiceCategory = {
  id: number;
  name: string;
  description: string;
  position: number;
  color: string;
  industryCategory: Nullable<number>;
  translations: {
    ar: { name?: string };
  };
};

export type ServiceWithVariants = ServiceDetail & {
  variants: ServiceVariant[];

  categoryName?: string;

  shouldShow?: boolean;
};

export type ServiceCategoryWithAllDetails = ServiceCategory & {
  services: ServiceWithVariants[];
};

export type IndustryCategoryWithAllDetails = IndustryCategory & {
  services: ServiceWithVariants[];
};

export type ServiceVariantWithService = Omit<ServiceVariant, "service"> & {
  service: Service;
};
