import { ChevronDown, ChevronUp } from "@bookpeep/ui";
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const NavListItem = ({ item, isSideNavMenuOpen, toggleSideNavMenu }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();

  const hasChildPathThatMatchesCurrentPathname = item?.children?.some((child) =>
    pathname.includes(child.path)
  );

  const shouldExpandNavListItem =
    item?.children?.length > 0 && hasChildPathThatMatchesCurrentPathname;

  const [shouldShowChildren, setShouldShowChildren] = useState(shouldExpandNavListItem);

  const { path, title, icon } = item;

  let match = false;
  if (pathname === "/" && path === "/") match = true;
  else if (path !== "/") match = pathname.includes(path);

  const navigate = useNavigate();

  const handleNavigation = () => {
    const windowSize = window.screen.width;
    const tabletBreakpoint = theme.breakpoints.values.md;
    const checkResponsiveMode = windowSize <= tabletBreakpoint;

    navigate(path);
    if (checkResponsiveMode) {
      toggleSideNavMenu();
    }
  };

  if (item.showChildren && item.children) {
    return (
      <>
        <NavListItemButton
          onClick={() => setShouldShowChildren(!shouldShowChildren)}
          selected={match}
        >
          <NavListItemText
            primary={
              <Box display="flex" alignItems="center">
                <NavListItemIcon>{icon}</NavListItemIcon>

                <Typography px={2}>{t(title)}</Typography>

                {shouldShowChildren ? (
                  <ChevronUp fontSize="small" />
                ) : (
                  <ChevronDown fontSize="small" />
                )}
              </Box>
            }
          />
        </NavListItemButton>

        {shouldShowChildren &&
          item.children
            .filter((child) => child.hasPermission)
            .map((child) => (
              <NavListItemButton
                key={child.title}
                onClick={() => {
                  toggleSideNavMenu();
                  navigate(child.path);
                }}
                selected={pathname.includes(child.path)}
              >
                <NavListItemText primary={<Typography ml={5}>{t(child.title)}</Typography>} />
              </NavListItemButton>
            ))}
      </>
    );
  }

  return (
    <NavListItemButton onClick={handleNavigation} selected={match}>
      <NavListItemText
        primary={
          <Box display="flex" alignItems="center">
            <NavListItemIcon>{icon}</NavListItemIcon>
            {isSideNavMenuOpen && (
              <Box px={2}>
                <Typography>{t(title)}</Typography>
              </Box>
            )}
          </Box>
        }
      />
    </NavListItemButton>
  );
};

export default NavListItem;

const NavListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  "&.Mui-selected , &.Mui-selected:hover": {
    backgroundColor: "#322C9C",
  },
}));

const NavListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const NavListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  minWidth: 0,
}));
