/**
 * This function returns a value within the allowed range.
 * @param value
 * @param minValue
 * @param maxValue
 * @returns
 */

const getValueWithinAllowedRange = (value: number, minValue: number, maxValue?: number) => {
  // prevent infinity or NaN
  if (isNaN(value) || !isFinite(value))
    throw new Error("valueAsNumber must be a number or a string that can be converted to a number");

  if (value < minValue) return minValue;

  if (maxValue !== undefined && value > maxValue) return maxValue;

  return value;
};

export default getValueWithinAllowedRange;
