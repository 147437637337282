import { Divider, Grid, Typography, TypographyProps } from "@mui/material";
import { Languages } from "configs/i18next";
import { useTranslation } from "react-i18next";

import InvoiceItemTitle from "./InvoiceItemTitle";

export type InvoiceItemProps = {
  title: string;
  isTitleTranslated?: boolean;
  subtitle?: string;
  value?: string | JSX.Element;
  valueProps?: TypographyProps;
  description?: string;
  divideIntoTwEqualParts?: boolean;
  hasBottomDivider?: boolean;
  color?: TypographyProps["color"];
};

const InvoiceRowItem = ({
  title,
  isTitleTranslated = true,
  subtitle,
  value,
  valueProps,
  description,
  divideIntoTwEqualParts = false,
  hasBottomDivider = false,
  color,
}: InvoiceItemProps) => {
  const { t } = useTranslation(["invoices"]);

  return (
    <>
      <Grid key={title} container item xs={12} spacing={1} alignItems="space-between">
        <Grid item xs={divideIntoTwEqualParts ? 6 : 9}>
          {isTitleTranslated ? (
            <InvoiceItemTitle
              englishTitle={t(title, { lng: Languages.english })}
              arabicTitle={t(title, { lng: Languages.arabic })}
            />
          ) : (
            <Typography variant="h5" fontWeight={700} color={color}>
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography
              variant="primary"
              fontWeight={700}
              color={color || "text.primary"}
              fontSize="1rem"
              pt={0.5}
            >
              {subtitle}
            </Typography>
          )}

          {description && (
            <Typography
              variant="primary"
              color={color || "text.label"}
              fontWeight={700}
              fontSize="1rem"
              display="block"
              pt={0.5}
            >
              {description}
            </Typography>
          )}
        </Grid>

        {value && (
          <Grid item xs={divideIntoTwEqualParts ? 6 : 3}>
            {typeof value === "string" ? (
              <Typography
                variant="h5"
                align={divideIntoTwEqualParts ? "left" : "right"}
                {...valueProps}
              >
                {value}
              </Typography>
            ) : (
              value
            )}
          </Grid>
        )}
      </Grid>

      {hasBottomDivider && <Divider variant="fullWidth" />}
    </>
  );
};
export default InvoiceRowItem;
