import { CreateInvoiceArgs, CreateRefundInvoiceArgs, Invoice, RefundInvoice } from "types/Checkout";
import http from "utils/http";

// TODO: change the existing invoice js store to this one

// this file has incomplete types for the currently used invoice js store
type UpdateInvoiceArgs = {
  id: number;
  data: {};
};

type ExistingClient = {
  id: number;
};

type NewClient = {
  firstName: string;
  lastName?: string;
  phoneCountryCode: string;
  phoneNumber: string;
};

type CreateGiftVoucherArgs = {
  voucherPurchase: number;
  client: NewClient | ExistingClient;
  message?: string;
  email?: string;
};

type InvoiceResponse = Invoice;

type GetInvoiceArgs = {
  id: number;
};

type VoidRefundInvoiceArgs = {
  id: number;
};

const checkoutInvoicesApi = {
  getAllInvoices: async () => http.get<InvoiceResponse[]>("invoices/invoices/"),

  getInvoice: async ({ id }: GetInvoiceArgs) =>
    http.get<InvoiceResponse>(`invoices/invoices/${id}/`),

  updateInvoice: async ({ id, data }: UpdateInvoiceArgs) =>
    http.put<InvoiceResponse>(`invoices/invoices/${id}/`, data),

  createInvoice: async (data: CreateInvoiceArgs) =>
    http.post<InvoiceResponse>(`invoices/invoices/`, data),

  createGiftVoucher: async (data: CreateGiftVoucherArgs) => http.post(`vouchers/gift/`, data),

  createRefundInvoice: async (data: CreateRefundInvoiceArgs) =>
    http.post<RefundInvoice>(`invoices/refund_invoices/`, data),

  voidRefundInvoice: async ({ id }: VoidRefundInvoiceArgs) =>
    http.put(`invoices/refund_invoices/${id}/`),

  cancelPeepPayDeposit: async ({ id }: { id: number }) => http.put(`peep_payments/invoices/${id}/`),
};

export default checkoutInvoicesApi;
