export function areObjectsEqual(obj1: any, obj2: any) {
  var props1 = obj1 ? Object.getOwnPropertyNames(obj1) : [];
  var props2 = obj2 ? Object.getOwnPropertyNames(obj2) : [];
  if (props1.length !== props2.length) {
    return false;
  }
  for (var i = 0; i < props1.length; i++) {
    const val1 = obj1[props1[i]];
    const val2 = obj2[props1[i]];
    const isObjects = isObject(val1) && isObject(val2);
    if ((isObjects && !areObjectsEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
}
export function isObject(object: any) {
  return object != null && typeof object === "object";
}
