import { Box, Grid, styled } from "@mui/material";
import PeepTable from "components/common/PeepTable/PeepTable";
import TableSideBar from "components/common/PeepTable/TableSideBar";
import { TableItem } from "components/common/PeepTable/TableTypes";
import InfinitePagination from "components/common/Tables/InfinitePagination";
import Pagination from "components/common/Tables/Pagination";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useIsMobileView from "hooks/useIsMobileView";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/organization/organizationSlice";
import {
  selectAllAppointmentsOverview,
  selectAppointmentsOverviewLoading,
  selectAppointmentsOverviewTotalPages,
} from "store/selectors";

import AppointmentsOverviewContext from "../AppointmentsOverviewContext";
import AppointmentsOverviewSideBarFilters from "../AppointmentsOverviewSideBarFilters";
import { appointmentsOverviewRows } from "./appointmentOverviewRows";
import { tableHeaders } from "./tableHeaders";

const AppointmentsOverviewTable = () => {
  const { t } = useTranslation("home");

  const organization = useSelector(selectOrganization);

  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization.industry || 0);

  const isMobileView = useIsMobileView();

  const { page, setPage, ordering, setOrdering, handlePageChange, handleLoadMore } = useContext(
    AppointmentsOverviewContext
  );

  const { openAppointmentDrawerViewMode } = useAppointmentDrawer();

  const results = useSelector(selectAllAppointmentsOverview) || [];
  const totalPages = useSelector(selectAppointmentsOverviewTotalPages);
  const isLoading = useSelector(selectAppointmentsOverviewLoading);

  const isShowingMore = isMobileView && page !== 1;

  const handleOpenAppointmentDetailDrawer = (appointmentId: number) => {
    openAppointmentDrawerViewMode({
      appointmentId,
    });
  };

  const items = appointmentsOverviewRows(
    results,
    handleOpenAppointmentDetailDrawer,
    t
  ) as TableItem[];

  return (
    <PageWrapper>
      <Grid container>
        <Grid container item xs={12} spacing={1}>
          {!isMobileView && (
            <Grid item sm={3} md={2.5} lg={2} xs={12}>
              <TableSideBar toOpenTitle={t("timePeriod")} toCloseTitle={t("backToAppointments")}>
                <AppointmentsOverviewSideBarFilters />
              </TableSideBar>
            </Grid>
          )}

          <Grid item sm={12} md={9.5} lg={10} xs={12}>
            <TableWrapper height="100%" sx={{ backgroundColor: "white" }}>
              <PeepTable
                maxHeight="100%"
                isLoading={isLoading && !isShowingMore}
                headers={tableHeaders(t, isHomeServiceOrganization)}
                items={items}
                ordering={ordering}
                setOrdering={setOrdering}
                setPage={setPage}
                emptySectionTitle={t("noDataAvailable")}
              />
            </TableWrapper>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <InfinitePagination
              totalPages={totalPages}
              page={page}
              loadMore={(e: number) => handleLoadMore(e)}
              loading={isLoading}
            />
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Pagination
              count={totalPages}
              page={page}
              setPage={(e: number) => {
                handlePageChange(e);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default AppointmentsOverviewTable;

const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  [theme.breakpoints.down("md")]: {
    padding: 0,
    ".MuiTableCell-root": {
      minWidth: "112px",
    },
  },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  borderRadius: "10px",
}));
