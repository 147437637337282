import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ExpenseType } from "types/Expense";

import { SliceStatus } from "../utils";
import api from "./expenseTypesApi";

export const expenseTypesAdapter = createEntityAdapter<ExpenseType>({
  selectId: (expenseType) => expenseType.id,
});

const initialState = expenseTypesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllExpenseTypes = createThunkFromApiWithType(
  "expenseTypes/getAllExpenseTypes",
  api.getExpenseTypes
);

export const createExpenseType = createThunkFromApiWithType(
  "expenseTypes/createExpenseType",
  api.createExpenseType
);
export const updateExpenseType = createThunkFromApiWithType(
  "expenseTypes/updateExpenseType",
  api.updateExpenseType
);

export const deleteExpenseType = createThunkFromApiWithType(
  "expenseTypes/deleteExpenseType",
  api.deleteExpenseType
);

export const checkActiveExpenseType = createThunkFromApiWithType(
  "expenseType/checkActiveExpenseType",
  api.checkActiveExpenseType
);

export const expenseTypesSlice = createSlice({
  name: "expenseTypes",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllExpenseTypes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllExpenseTypes.fulfilled, (state, { payload }) => {
      expenseTypesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllExpenseTypes.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createExpenseType.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createExpenseType.fulfilled, (state, { payload }) => {
      expenseTypesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createExpenseType.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateExpenseType.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateExpenseType.fulfilled, (state, { payload }) => {
      expenseTypesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateExpenseType.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(deleteExpenseType.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteExpenseType.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        state.status = SliceStatus.IDLE;
        expenseTypesAdapter.removeOne(state, id);
      }
    );

    reducers.addCase(deleteExpenseType.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default expenseTypesSlice.reducer;
