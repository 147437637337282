import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/serviceCategoriesV2/serviceCategoriesApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ServiceCategory } from "types/ServiceV2";

import { SliceStatus } from "../utils";

export const serviceCategoryAdapter = createEntityAdapter<ServiceCategory>({
  selectId: (serviceCategory) => serviceCategory.id,
});

const { selectAll } = serviceCategoryAdapter.getSelectors();

const initialState = serviceCategoryAdapter.getInitialState<{
  status: SliceStatus;
  optimisticCache: Nullable<ServiceCategory[]>;
}>({
  status: SliceStatus.IDLE,
  optimisticCache: null,
});

export const getAllServiceCategories = createThunkFromApiWithType(
  "serviceCategoriesV2/getAllServiceCategories",
  api.getAllServiceCategories,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const createServiceCategory = createThunkFromApiWithType(
  "serviceCategoriesV2/createServiceCategory",
  api.createServiceCategory
);

export const updateServiceCategory = createThunkFromApiWithType(
  "serviceCategoriesV2/updateServiceCategory",
  api.updateServiceCategory
);

export const deleteServiceCategory = createThunkFromApiWithType(
  "serviceCategoriesV2/deleteServiceCategory",
  api.removeServiceCategory
);

export const updateServiceCategoryPosition = createThunkFromApiWithType(
  "serviceCategoriesV2/updateServiceCategoryPosition",
  api.updateServiceCategoryPosition
);

export const serviceCategoriesSlice = createSlice({
  name: "serviceCategoriesV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllServiceCategories.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllServiceCategories.fulfilled, (state, { payload }) => {
      serviceCategoryAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllServiceCategories.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createServiceCategory.fulfilled, (state, { payload }) => {
      serviceCategoryAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createServiceCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(updateServiceCategory.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateServiceCategory.fulfilled, (state, { payload }) => {
      serviceCategoryAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateServiceCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(
      updateServiceCategoryPosition.pending,
      (
        state,
        {
          meta: {
            arg: { data },
          },
        }
      ) => {
        const serviceCategoriesV2 = selectAll(state);
        state.optimisticCache = serviceCategoriesV2;

        serviceCategoryAdapter.upsertMany(state, data);
      }
    );

    reducers.addCase(updateServiceCategoryPosition.fulfilled, (state, { payload }) => {
      state.optimisticCache = null;
      serviceCategoryAdapter.upsertMany(state, payload);
    });

    reducers.addCase(updateServiceCategoryPosition.rejected, (state) => {
      if (state.optimisticCache) {
        serviceCategoryAdapter.setAll(state, state.optimisticCache);
        state.optimisticCache = null;
      }
    });

    reducers.addCase(
      deleteServiceCategory.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        serviceCategoryAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteServiceCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default serviceCategoriesSlice.reducer;
