import type { RootState } from "store";

import { selectSupplierContactsBySupplierId } from "../supplierContacts/supplierContactsSelectors";
import { SliceStatus } from "../utils";
import { suppliersAdapter } from "./suppliersSlice";

export const selectSuppliersLoading = (state: RootState) =>
  state.suppliers.status === SliceStatus.LOADING;

export const selectSupplierTotalPages = (state: RootState) => state.suppliers.totalPages;

export const selectSuppliersState = (state: RootState) => state.suppliers;

const { selectById, selectAll } = suppliersAdapter.getSelectors(selectSuppliersState);

export const selectAllSuppliers = selectAll;

export const selectSupplierById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectProductSuppliersByIds = (ids: number[]) => (state: RootState) => {
  return selectAll(state).filter((supplier) => ids.includes(supplier.id));
};

type SupplierWithContactsTotal = Supplier & { totalSupplierContacts: number };

// Done this way to match the sorting order the user has selected 
export const selectSuppliersWithTheirContacts = (state: RootState) => {
  const suppliers: SupplierWithContactsTotal[] = [];
  state.suppliers.page.forEach((supplierId) => {
    const supplier = selectById(state, supplierId);
    if (supplier) {
      suppliers.push({
        ...supplier,
        totalSupplierContacts: selectSupplierContactsBySupplierId(supplier.id)(state)?.length || 0,
      });
    }
  });
  return suppliers;
};
