import { Typography, TypographyProps } from "@mui/material";

export default function SectionTitle({ children, ...props }: TypographyProps) {
  return (
    <Typography
      variant="secondary"
      fontWeight={700}
      color="text.label"
      textTransform="uppercase"
      letterSpacing="0.06em"
      {...props}
    >
      {children}
    </Typography>
  );
}
