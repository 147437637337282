import { Box, Button, Typography, styled } from "@mui/material";
import { ItemPrice } from "components/common";
import { dontForwardProps } from "helpers/styles";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { InvoiceArticle } from "types/Checkout";

import InvoiceArticleItemDescription from "../InvoiceDetails/InvoiceArticlesSection/InvoiceArticleItemDescription";

type CheckoutRefundInvoiceArticleProps = {
  invoiceArticle: InvoiceArticle;
  isSelected: boolean;
  onToggleRefund: () => void;
};

const CheckoutRefundInvoiceArticle = ({
  invoiceArticle,
  isSelected,
  onToggleRefund,
}: CheckoutRefundInvoiceArticleProps) => {
  const { t } = useTranslation("checkout");

  const { checkoutModalView } = useCheckoutModal();

  return (
    <ArticleBox isSelected={isSelected}>
      <Box>
        <InvoiceArticleItemDescription
          article={invoiceArticle}
          titleProps={{
            fontWeight: 500,
          }}
          subtitleProps={{
            fontWeight: 400,
          }}
          showDuration
        />

        {!!invoiceArticle.refundBlockedBy && (
          <Typography display="block" pt={1} variant="primary" color="error.main" fontWeight={500}>
            {t("cannotRefoundReason", { entity: invoiceArticle.refundBlockedBy.toLowerCase() })}
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <ItemPrice
          originalPrice={Number(invoiceArticle.finalPrice)}
          finalPrice={Number(invoiceArticle.finalPrice)}
        />

        <Button
          variant={isSelected ? "outlined" : "contained"}
          color="error"
          size="small"
          sx={{
            minHeight: 32,
            // set disabled button color to red
            "&.Mui-disabled": {
              "&.MuiButton-outlined": {
                color: "rgba(244, 51, 25, 0.06)",
                borderColor: "rgba(244, 51, 25, 0.06)",
              },
            },
          }}
          disabled={
            !!invoiceArticle.refundBlockedBy ||
            checkoutModalView === CheckoutModalView.ConfirmRefund
          }
          onClick={onToggleRefund}
        >
          {t(isSelected ? "cancel" : "refund")}
        </Button>
      </Box>
    </ArticleBox>
  );
};

export default CheckoutRefundInvoiceArticle;

type ArticleBoxProps = {
  isSelected?: boolean;
};

const ArticleBox = styled(
  Box,
  dontForwardProps("isSelected")
)<ArticleBoxProps>(({ theme, isSelected }) => ({
  backgroundColor: theme.palette.bg.blank,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  padding: theme.spacing(2),

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  ...(isSelected && {
    borderLeft: `4px solid ${theme.palette.error.main}`,

    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  }),
  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));
