import { List, Typography } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllNewNotifications, selectAllReadNotifications } from "store/selectors";
import { Notification, NotificationListSectionType } from "types/Notification";

import NotificationsListSection from "./NotificationsListSection";

type NotificationListProps = {
  handleClose: () => void;
};

const NotificationsList = ({ handleClose }: NotificationListProps) => {
  const { t } = useTranslation("notifications");
  const isMobileView = useIsMobileView();

  const readNotifications = useSelector(selectAllReadNotifications).sort(
    (a: Notification, b: Notification) =>
      DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()
  );

  const newNotifications = useSelector(selectAllNewNotifications).sort(
    (a: Notification, b: Notification) =>
      DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis()
  );

  const hasNotifications = !!(newNotifications.length + readNotifications.length);

  return (
    <List
      sx={{
        width: (theme) => (isMobileView ? theme.spacing(37) : theme.spacing(60)),
        maxWidth: (theme) => (isMobileView ? theme.spacing(37) : theme.spacing(60)),

        bgcolor: "background.paper",
        position: "relative",
        overflow: "auto",

        maxHeight: (theme) => (isMobileView ? theme.spacing(70) : theme.spacing(60)),
      }}
      subheader={<li />}
    >
      {hasNotifications ? (
        <>
          {!!newNotifications.length && (
            <NotificationsListSection
              title={t("new")}
              type={NotificationListSectionType.New}
              notifications={newNotifications}
              handleClose={handleClose}
            />
          )}

          {!!readNotifications.length && (
            <NotificationsListSection
              title={t("read")}
              type={NotificationListSectionType.Read}
              notifications={readNotifications}
              handleClose={handleClose}
            />
          )}
        </>
      ) : (
        <Typography fontSize="0.75rem" fontWeight={500} color="label" align="center" py={3}>
          {t("noNotifications")}
        </Typography>
      )}
    </List>
  );
};

export default NotificationsList;
