import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./serviceVariantsApi";

export const serviceVariantAdapter = createEntityAdapter();

const initialState = serviceVariantAdapter.getInitialState({
  isLoading: false,
  error: "",
});

export const getAllServiceVariants = createThunkFromApi(
  "serviceVariants/getAllServiceVariants",
  api.getAllServiceVariants
);

export const getServiceVariant = createThunkFromApi(
  "serviceVariants/getServiceVariant",
  api.getServiceVariant
);

export const createServiceVariant = createThunkFromApi(
  "serviceVariants/createServiceVariant",
  api.createServiceVariant
);

export const updateServiceVariant = createThunkFromApi(
  "serviceVariants/updateServiceVariant",
  api.updateServiceVariant
);

export const deleteServiceVariant = createThunkFromApi(
  "serviceVariants/removeServiceVariant",
  api.removeServiceVariant
);

export const serviceVariantsSlice = createSlice({
  name: "serviceVariants",
  initialState,
  extraReducers: {
    [getAllServiceVariants.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllServiceVariants.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      serviceVariantAdapter.setAll(state, payload);
    },
    [getAllServiceVariants.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getServiceVariant.fulfilled]: (state, { payload }) => {
      serviceVariantAdapter.setOne(state, payload);
    },
    // [getService.fulfilled]: (state, { payload }) => {
    //   serviceVariantAdapter.upsertMany(state, payload.variants);
    // },

    [createServiceVariant.fulfilled]: (state, { payload }) => {
      serviceVariantAdapter.addOne(state, payload);
    },

    [updateServiceVariant.fulfilled]: (state, { payload }) => {
      serviceVariantAdapter.updateOne(state, {
        id: payload.id,
        changes: payload.changes,
      });
    },
    // [updateService.fulfilled]: (state, { payload }) => {
    //   serviceVariantAdapter.upsertMany(state, payload.variants);
    // },
    // [createService.fulfilled]: (state, { payload }) => {
    //   serviceVariantAdapter.upsertMany(state, payload.variants);
    // },

    [deleteServiceVariant.fulfilled]: (state, { payload: id }) => {
      serviceVariantAdapter.removeOne(state, id);
    },
  },
});

export default serviceVariantsSlice.reducer;
