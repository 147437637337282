export type ClientAnalytic = {
  id: number;
  name: string;
  phoneNumber: string;

  threeMonthsCount: number;
  sixMonthsCount: number;
  totalVisitsCount: number;

  lastSeen: string;
  firstSeen: string;

  currentStatus: string;
  previousStatus: string;

  totalBookings?: number;

  cancellationPercentage?: number;
  noShowPercentage?: number;

  createdAt: string;
};

export enum CLIENT_LOYALTY_STATUS {
  ONE_TIMER = "ONE TIMER",
  ALMOST_REGULAR = "ALMOST REGULAR",
  REGULAR = "REGULAR",
  LOYAL = "LOYAL",
  SUPER_LOYAL = "SUPER LOYAL",
  LOST = "LOST",
  GHOST = "GHOST",
  NO_SHOWER = "reached_no_show_threshold",
  CANCELLER = "reached_cancellation_threshold",
}

export type ClientAnalyticsSettings = {
  id: number;
  almostRegular: number;
  regular: number;
  loyal: number;
  superLoyal: number;
  lost: number;
  noShowThreshold: number;
  cancellationThreshold: number;
  createdAt: string;
  updatedAt: string;
};
