import { Grid, Skeleton, Stack } from "@mui/material";

const AppointmentDetailsToolbarSkeleton = () => {
  return (
    <Stack gap={1.5} width="100%">
      <Grid container spacing={1} mt={0} alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={34}
            sx={{
              borderRadius: "24px",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={34}
            sx={{
              borderRadius: "24px",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={34}
            sx={{
              borderRadius: "24px",
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AppointmentDetailsToolbarSkeleton;
