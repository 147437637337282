import { checkIfArticleHasSpecialDiscount } from "helpers/articles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Invoice, InvoicePayment } from "types/Checkout";

import InvoiceRowItem from "../InvoiceRowItem";
import InvoiceRowPriceItem from "../InvoiceRowPriceItem";
import InvoiceAdditionalPaymentsToBeAdded from "./InvoiceAdditionalPaymentsToBeAdded";

type InvoicePaymentsSummaryProps = {
  invoice: Invoice;
};

const InvoicePaymentsSummary = ({ invoice }: InvoicePaymentsSummaryProps) => {
  const { t } = useTranslation("checkout");

  const subtotalPrice = invoice.articles
    .filter((article) => !article.subscriptionRedemption)
    .reduce((currentTotal, article) => {
      if (Number(article.finalPrice) > Number(article.markedPrice)) {
        return currentTotal + Number(article.finalPrice);
      }
      // If you have a special discount just like app price, use the original price
      if (
        checkIfArticleHasSpecialDiscount({
          markedPrice: article.markedPrice || 0,
          originalPrice: article.originalPrice,
          finalPrice: article.finalPrice,
        })
      )
        return currentTotal + Number(article.originalPrice);

      return currentTotal + Number(article.markedPrice);
    }, 0);

  const invoiceTotal = Number(invoice.total);

  const discount = subtotalPrice - invoiceTotal;

  const deposit = invoice.deposit;

  const onlinePayments = invoice?.onlinePayments || [];

  const peepTransactions = invoice?.peepTransactions || [];

  const hasDownPayment = Boolean(deposit || onlinePayments.length > 0);

  const hasAppliedVouchers = Boolean(
    invoice.voucherRedemptions && invoice.voucherRedemptions?.length > 0
  );

  const onlinePaymentsTotal = onlinePayments.reduce(
    (currentTotal, payment) => currentTotal + Number(payment.total),
    0
  );

  const peepTransactionTotal = peepTransactions.reduce(
    (currentTotal, payment) => currentTotal + Number(payment.amountDue),
    0
  );

  const getPaymentTitle = (invoicePayment: InvoicePayment) => {
    if (invoicePayment.paymentType) {
      return invoicePayment.paymentType.label;
    }
    if (invoicePayment.wallet) {
      return t(`${invoicePayment.wallet.toLowerCase()}Wallet`);
    }
    if (invoicePayment.peepPaymentInvoice) {
      return t("peepPay");
    } else throw new Error("Invalid payment type, there must be a payment type or a wallet!");
  };

  const depositTitle = deposit?.peepPay ? t("peepPay") : deposit?.paymentMethod;

  let onlinePaymentPrice = onlinePaymentsTotal;

  if (peepTransactionTotal > 0) {
    onlinePaymentPrice = onlinePaymentsTotal - peepTransactionTotal;
  }

  return (
    <>
      <InvoiceRowPriceItem title="invoices:subtotal" amount={subtotalPrice} hasBottomDivider />

      <InvoiceRowPriceItem title="invoices:discount" amount={-discount} hasBottomDivider />

      <InvoiceRowPriceItem title="invoices:total" amount={invoiceTotal} hasBottomDivider />

      {hasDownPayment && <InvoiceRowItem title="invoices:downPayment" />}

      {deposit && (
        <InvoiceRowPriceItem
          title={depositTitle}
          isTitleTranslated={false}
          amount={-Number(deposit.amount)}
          description={
            deposit.createdAt
              ? DateTime.fromISO(deposit.createdAt).toFormat("EEEE, dd MMMM hh:mm a")
              : ""
          }
          hasBottomDivider
        />
      )}

      {Boolean(onlinePayments.length) && (
        <InvoiceRowPriceItem
          title={t("paidOnline")}
          amount={-Number(onlinePaymentPrice)}
          description={DateTime.fromISO(onlinePayments[0].createdAt).toFormat(
            "EEEE, dd MMMM hh:mm a"
          )}
          hasBottomDivider
        />
      )}

      {peepTransactionTotal > 0 && (
        <InvoiceRowPriceItem
          title={t("paidByPeep")}
          amount={-Number(peepTransactionTotal)}
          description={DateTime.fromISO(peepTransactions[0].createdAt).toFormat(
            "EEEE, dd MMMM hh:mm a"
          )}
          hasBottomDivider
        />
      )}

      {invoice.payments.map((payment, index) => (
        <InvoiceRowPriceItem
          key={payment.id}
          title={getPaymentTitle(payment)}
          isTitleTranslated={false}
          hasBottomDivider={index === invoice.payments.length - 1}
          amount={-Number(payment.amount)}
          description={
            payment.createdAt
              ? DateTime.fromISO(payment.createdAt).toFormat("EEEE, dd MMMM hh:mm a")
              : ""
          }
        />
      ))}

      {/* Additional payments when adding payment for an unpaid or partially paid invoice  */}
      <InvoiceAdditionalPaymentsToBeAdded />

      {hasAppliedVouchers && <InvoiceRowItem title="invoices:appliedVoucher" />}

      {invoice.voucherRedemptions?.map((voucherRedemption) => (
        <InvoiceRowPriceItem
          key={voucherRedemption.id}
          title={`${voucherRedemption.name} (${voucherRedemption.code})`}
          isTitleTranslated={false}
          hasBottomDivider
          amount={-Number(voucherRedemption.value)}
          description={
            voucherRedemption.createdAt
              ? DateTime.fromISO(voucherRedemption.createdAt).toFormat("EEEE, dd MMMM hh:mm a")
              : ""
          }
        />
      ))}

      {invoice.refund?.map((refund) => (
        <InvoiceRowPriceItem
          key={refund.id}
          title={t("refund")}
          isTitleTranslated={false}
          hasBottomDivider
          amount={-1 * Number(refund.amount)}
          description={
            refund.wallet ? t(`${refund.wallet.toLowerCase()}Wallet`) : refund?.paymentType?.label
          }
        />
      ))}
    </>
  );
};

export default InvoicePaymentsSummary;
