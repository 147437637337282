// flex booking slice has list of all services

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SliceStatus } from "../utils";
import { FlexService } from "types/FlexBooking";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { default as api } from "./flexBookingApi";

export const flexBookingAdapter = createEntityAdapter<FlexService>({
  selectId: (service) => service.id,
});

const initialState = flexBookingAdapter.getInitialState<{
  status: SliceStatus;
  flexServices: FlexService[];
  isRequestPending: SliceStatus;
}>({
  status: SliceStatus.IDLE,
  flexServices: [],
  isRequestPending: SliceStatus.IDLE,
});

export const getFlexServices = createThunkFromApiWithType(
  "flexBooking/getFlexServices",
  api.getFlexServices
);

export const createFlexService = createThunkFromApiWithType(
  "flexBooking/createFlexService",
  api.createFlexService
);

export const updateFlexService = createThunkFromApiWithType(
  "flexBooking/updateFlexService",
  api.updateFlexService
);

export const flexBookingSlice = createSlice({
  name: "flexBooking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlexServices.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getFlexServices.fulfilled, (state, action) => {
        flexBookingAdapter.setAll(
          state,
          action.payload.map((service) => ({
            ...service,
            priceFrom: Number(service.priceFrom),
            priceTo: Number(service.priceTo),
          }))
        );
        state.status = SliceStatus.IDLE;
      })
      .addCase(getFlexServices.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(createFlexService.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(createFlexService.fulfilled, (state, { payload }) => {
        flexBookingAdapter.upsertOne(state, {
          ...payload,
          priceFrom: Number(payload.priceFrom),
          priceTo: Number(payload.priceTo),
        });
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(createFlexService.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(updateFlexService.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateFlexService.fulfilled, (state, { payload }) => {
        flexBookingAdapter.upsertOne(state, {
          ...payload,
          priceFrom: Number(payload.priceFrom),
          priceTo: Number(payload.priceTo),
        });
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateFlexService.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      });
  },
});

export default flexBookingSlice.reducer