import { Close } from "@bookpeep/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import ResponsiveModal from "components/common/Modals/ResponsiveModal";
import PriceField from "components/common/PriceField";
import PeepPayPhoneNumberSection, {
  type SetPeepPayPhoneNumberFunction,
} from "components/SendPeepPayLinkModal/PeepPayPhoneNumberSection";
import useFormValidation from "hooks/useFormValidation";
import useResetForm from "hooks/useResetForm";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAppointmentById } from "store/appointments/appointmentsSlice";
import { Appointment } from "types/Appointment";
import { z } from "zod";

import depositFormSchema from "./depositFormSchema";
import FormActionButtons from "./FormActionButtons";
import PaymentTypeDropdown from "./PaymentTypeDropdown";
import { Client } from "types/Client";

export type DepositFormInput = z.infer<typeof depositFormSchema>;

type DepositFormModalProps = {
  appointmentId: number;
  isDepositModalOpen: boolean;
  setIsDepositModalOpen: Dispatch<SetStateAction<boolean>>;
  client: Client;
};

const DepositFormModal = ({
  appointmentId,
  isDepositModalOpen,
  setIsDepositModalOpen,
  client,
}: DepositFormModalProps) => {
  const { t } = useTranslation("deposits");

  const appointment = useSelector(selectAppointmentById(appointmentId)) as Appointment;

  const methods = useForm<DepositFormInput>({
    defaultValues: {
      appointment: 0,

      paymentType: null,
      wallet: null,
      peepPay: null,

      amount: 0,
    },
    resolver: zodResolver(depositFormSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { watch, reset, setValue, formState } = methods;

  useEffect(() => {
    setValue("appointment", appointmentId);
  }, [setValue, appointmentId]);

  const { peepPay, amount } = watch();

  const resetForm = useResetForm(reset, () => setIsDepositModalOpen(false));

  const registerValidation = useFormValidation(formState, { ns: "deposits" });

  if (!appointment) return <CentredSpinnerBox />;

  const hasSelectedPeepPay = !!peepPay;

  const setPeepPayPhoneNumber: SetPeepPayPhoneNumberFunction = ({
    phoneNumber,
    phoneCountryCode,
  }) => {
    setValue("peepPay", {
      phoneNumber,
      phoneCountryCode,
    });
  };

  return (
    <FormProvider {...methods}>
      <ResponsiveModal
        title={t("addDeposit")}
        onClose={resetForm}
        open={isDepositModalOpen}
        mobileActions={
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsDepositModalOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        <form>
          <Grid container spacing={1} padding={3}>
            <Grid item xs={12} md={6}>
              <PaymentTypeDropdown client={client} />
            </Grid>

            <Grid item xs={12} md={6}>
              <PriceField
                max={Number(appointment.total)}
                fullWidth
                label={t("amount")}
                value={amount || 0}
                onChange={(value) => setValue("amount", value)}
                {...registerValidation("amount")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="text.label" variant="secondary">
                {t("paymentWillBeAddedToCheckout")}
              </Typography>
            </Grid>

            {hasSelectedPeepPay && (
              <Grid item xs={12}>
                <PeepPayPhoneNumberSection
                  setPeepPayPhoneNumber={setPeepPayPhoneNumber}
                  phoneCountryCode={peepPay.phoneCountryCode}
                  phoneNumber={peepPay.phoneNumber}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormActionButtons
              clientId={appointment.client}
              onClose={resetForm}
              appointment={appointment}
            />
          </Grid>
        </form>
      </ResponsiveModal>
    </FormProvider>
  );
};
export default DepositFormModal;
