export enum NotificationType {
  NewOnlineAppointment = "NEW ONLINE APPOINTMENT",
  OnlineBookingResumed = "ONLINE BOOKING RESUMED",
  ProductThresholdReached = "PRODUCT THRESHOLD REACHED"
}

export enum NotificationListSectionType {
  New = "NEW",
  Read = "READ",
}

export type Notification = {
  id: number;
  isRead: boolean;
  title: string;
  subtitle: string;
  description: string;
  type: NotificationType;
  startDate: string;
  endDate: string;
  poiId: number;
  createdAt: string;
  clientId: Nullable<number>;
};
