import { Box, Grid, Stack, Typography } from "@mui/material";
import { ItemPrice } from "components/common";
import OnlineBookingChip from "components/common/Chips/OnlineBookingChip";
import { getAppointmentStatusIcon } from "helpers/getAppointmentStatusIcon";
import renderNullableValue from "helpers/renderNullableValue";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";
import { ClientAppointment } from "types/Client";
import Outlet from "types/Outlet";

import AppointmentsHistoryArticle from "./AppointmentsHistoryArticle";

type AppointmentHistoryItemProps = {
  appointment: ClientAppointment;
};

const AppointmentsHistoryItem = ({ appointment }: AppointmentHistoryItemProps) => {
  const { t } = useTranslation(["translation", "appointments"]);

  const { date, status, total, createdOnline, outlet, source } = appointment;

  const formattedDate = DateTime.fromISO(date).toFormat("dd MMMM");
  const formattedDay = DateTime.fromISO(date).toFormat("EEEE");

  const articles = appointment.articles.map((article) => (
    <AppointmentsHistoryArticle key={`${article.id}`} article={article} />
  ));

  const currentOutlet = useSelector(selectOutletById(outlet)) as Outlet | undefined;

  return (
    <Box px={3}>
      <Grid container pb={2} spacing={1.5}>
        <Grid item md={4} xs={12}>
          <Stack gap={1}>
            <Typography variant="secondary" display="block">
              {formattedDate}
            </Typography>

            <Typography variant="secondary" color="text.label" display="block">
              {formattedDay}
            </Typography>

            <Typography variant="secondary" color="#848FA1;">
              {renderNullableValue(currentOutlet?.name)}
            </Typography>

            {createdOnline && <OnlineBookingChip source={source} size="small" />}
          </Stack>
        </Grid>

        <Grid item md={6} xs={12}>
          <Stack gap={2}>{articles}</Stack>
        </Grid>

        <Grid item md={2} xs={12}>
          <ItemPrice originalPrice={Number(total)} finalPrice={Number(total)} />
        </Grid>

        {appointment.notes && (
          <Grid item md={12} xs={12} gap={1} mt={3} borderRadius="8px">
            <Stack gap={1}>
              <Typography variant="primary" fontWeight={500} textTransform="uppercase">
                {t("appointments:appointmentNotes")}
              </Typography>

              <Typography variant="secondary" color="#53627C" sx={{ whiteSpace: "pre-line" }}>
                {appointment.notes}
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>

      <Box
        width="100%"
        bgcolor="bg.secondary"
        p={2}
        borderRadius={1}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        {getAppointmentStatusIcon(status)}
        <Typography variant="h5">
          {t(status.toLowerCase(), {
            ns: "translation",
          })}
        </Typography>
      </Box>
    </Box>
  );
};

export default AppointmentsHistoryItem;
