import { Grid, Skeleton, Stack, Typography } from "@mui/material";

const ClientInvoicesHistoryItemSkeleton = () => {
  return (
    <Grid container spacing={2} p={3}>
      <Grid item md={10} xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" display="block">
            <Skeleton variant="text" width="40%" />
          </Typography>
          <Typography variant="primary" color="text.label">
            <Skeleton variant="text" width="50%" />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" color="text.label">
            <Skeleton variant="text" width="50%" />
          </Typography>
        </Grid>
      </Grid>

      <Grid item md={2} xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Stack alignItems="center">
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={32} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientInvoicesHistoryItemSkeleton;
