import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type AppointmentAdditionalInfoSectionProps = {
  title: string | ReactNode;
  description: string | ReactNode;
};

const AppointmentAdditionalInfoSection = ({
  title,
  description,
}: AppointmentAdditionalInfoSectionProps) => {
  return (
    <Stack gap={0.5}>
      <Typography variant="primary" color="text.primary" fontWeight={700}>
        {title}
      </Typography>

      <Typography
        variant="secondary"
        color="text.primary"
        sx={{ whiteSpace: "pre-line" }}
        fontWeight={400}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default AppointmentAdditionalInfoSection;
