import { Box, Button, Stack } from "@mui/material";
import { SectionTitle } from "components/common";
import AreaStatusChip from "components/common/AreaStatusChip";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { getClientAddresses, selectClientById } from "store/clients/clientsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectAllAreas } from "store/slices/areas/areasSelector";
import { Client, ClientAddress } from "types/Client";
import useNotify from "utils/notifications/useNotify";

import { type AreaAutocompleteAdditionalProps } from "components/AreaAutocomplete";
import ClientAddressForm from "./ClientAddressForm";
import ClientAddressSection from "./ClientAddressSection";
import ClientAddressSectionSkeleton from "./ClientAddressSection.skeleton";

type ClientAddressesProps = {
  clientId: number;
  areaId?: number;
  showAreaStatusChip?: boolean;
  areaAutoCompleteProps?: AreaAutocompleteAdditionalProps;

  handleSaveForm?: (address: ClientAddress) => void;
  handleCancelEditForm?: () => void;

  hasRadioButton?: boolean;
  handleToggleSelection?: (addressId: Nullable<number>) => void;
  addressId?: Nullable<number>;

  title?: string;

  shouldShowManageButtons?: boolean;
  shouldShowAddAddressButton?: boolean;
  shouldShowEmptyGraphic?: boolean;
};

const ClientAddresses = ({
  clientId,
  areaId,
  showAreaStatusChip = false,
  areaAutoCompleteProps,

  title,

  hasRadioButton,
  handleToggleSelection,
  addressId,

  handleSaveForm,
  handleCancelEditForm,
  shouldShowManageButtons,
  shouldShowAddAddressButton = true,
  shouldShowEmptyGraphic = true,
}: ClientAddressesProps) => {
  const { t } = useTranslation(["client", "errors"]);
  const notify = useNotify();
  const dispatch = useDispatch();

  const hasFetchedClientAddressesRef = useRef(false);

  const [isFetchingClientAddresses, setIsFetchingClientAddresses] = useState(false);

  const { hasClients } = useSelector(selectAllPermissions);
  const areas = useSelector(selectAllAreas);

  const client = useSelector(selectClientById(clientId)) as Client | undefined;

  const { customerAddressesLink, addresses: allAddresses } = client || {};

  const addresses = allAddresses?.filter((address) => !address.deprecated) || [];

  useEffect(() => {
    if (
      customerAddressesLink &&
      !hasFetchedClientAddressesRef.current &&
      !isFetchingClientAddresses
    ) {
      setIsFetchingClientAddresses(true);

      dispatch(getClientAddresses({ clientId }))
        .unwrap()
        .then(() => {
          setIsFetchingClientAddresses(false);
        })
        .catch((error) => {
          notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error");
          setIsFetchingClientAddresses(false);
        });

      hasFetchedClientAddressesRef.current = true;
    }
  }, [clientId, customerAddressesLink, dispatch, isFetchingClientAddresses, notify, t]);

  const [isAddAddressFormOpen, setIsAddAddressFormOpen] = useState(false);

  const [addressBeingEdited, setAddressBeingEdited] = useState<Nullable<number>>(null);

  const openEditForm = (addressId: number) => {
    setAddressBeingEdited(addressId);
  };

  const closeEditForm = () => setAddressBeingEdited(null);

  const hasEditAccess = hasClients.editAccess;
  const canShowAddAddressButton =
    hasEditAccess && !isAddAddressFormOpen && shouldShowAddAddressButton;

  const openAddAddressForm = () => setIsAddAddressFormOpen(true);
  const closeAddAddressForm = () => setIsAddAddressFormOpen(false);

  // This should show that the address is unsupported because the area is not assigned to any zone
  const checkIsAddressAreaIsNotAssignedToAnyZone = (address: ClientAddress) => {
    const addressArea = areas.find((area) => area.id === address.area);
    return !addressArea?.zone;
  };

  // This should show that the address has an invalid zone
  const checkIsAddressZoneDoesNotMatchSelectedAreaZone = (address: ClientAddress) => {
    const addressArea = areas.find((area) => area.id === address.area);
    const addressZone = areas.find((area) => area.id === addressArea?.zone);

    const selectedAreaZone = areas.find((area) => area.id === areaId)?.zone;

    return areaId && addressZone && addressZone?.id !== selectedAreaZone;
  };

  const getAddressStatusChip = (address: ClientAddress) => {
    if (checkIsAddressAreaIsNotAssignedToAnyZone(address)) {
      return <AreaStatusChip status="unsupported" />;
    }

    if (checkIsAddressZoneDoesNotMatchSelectedAreaZone(address)) {
      return <AreaStatusChip status="anotherZone" />;
    }

    return null;
  };

  const renderAddresses = () => {
    if (isFetchingClientAddresses) {
      return Array.from({ length: 3 }).map((_, index) => (
        <ClientAddressSectionSkeleton key={index} />
      ));
    }

    if (!addresses?.length && !isAddAddressFormOpen && shouldShowEmptyGraphic) {
      return <EmptyGraphicSection description={t("client:noAddressesYet")} />;
    }

    const checkIfAddressCanBeSelected = (address: ClientAddress) =>
      !(
        showAreaStatusChip &&
        (checkIsAddressAreaIsNotAssignedToAnyZone(address) ||
          checkIsAddressZoneDoesNotMatchSelectedAreaZone(address))
      );

    // Addresses that can be selected should be shown first
    const sortedAddressBasedOnIfAddressCanBeSelected = [...(addresses || [])]?.sort((a, b) => {
      const aCanBeSelected = checkIfAddressCanBeSelected(a);
      const bCanBeSelected = checkIfAddressCanBeSelected(b);

      return aCanBeSelected === bCanBeSelected ? 0 : aCanBeSelected ? -1 : 1;
    });

    const addressesList = hasRadioButton ? sortedAddressBasedOnIfAddressCanBeSelected : addresses;

    return addressesList?.map((address) => (
      <ClientAddressSection
        key={address.id}
        clientId={clientId}
        areaStatusChip={showAreaStatusChip && getAddressStatusChip(address)}
        isSelected={!!address?.id && addressId === address?.id}
        handleToggleSelection={() =>
          handleToggleSelection && handleToggleSelection(address.id || null)
        }
        hasRadioButton={hasRadioButton}
        handleSaveForm={handleSaveForm}
        shouldShowManageButtons={shouldShowManageButtons}
        address={address}
        isDisabled={
          isAddAddressFormOpen ||
          (!!addressBeingEdited && address.id !== addressBeingEdited) ||
          !checkIfAddressCanBeSelected(address)
        }
        isEditFormOpen={address.id === addressBeingEdited}
        openEditForm={openEditForm}
        closeEditForm={closeEditForm}
        handleCancelEditForm={handleCancelEditForm}
      />
    ));
  };

  return (
    <Stack>
      <SectionTitle>{title || t("addresses")}</SectionTitle>

      {canShowAddAddressButton && (
        <Button
          sx={{ mt: 3 }}
          variant="outlined"
          onClick={openAddAddressForm}
          disabled={isAddAddressFormOpen || !!addressBeingEdited}
        >
          {t("client:addNewAddress")}
        </Button>
      )}

      <Stack gap={1} pt={2}>
        {isAddAddressFormOpen && (
          <Box pb={1}>
            <ClientAddressForm
              clientId={clientId}
              handleSaveForm={handleSaveForm}
              handleCancelEditForm={handleCancelEditForm}
              closeForm={closeAddAddressForm}
              areaAutoCompleteProps={areaAutoCompleteProps}
            />
          </Box>
        )}

        {renderAddresses()}
      </Stack>
    </Stack>
  );
};

export default ClientAddresses;
