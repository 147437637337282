import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  createSupplier,
  getSuppliersBasedOnCurrentPage,
  getSuppliersForInfiniteLoading,
  updateSupplier,
} from "../suppliers/suppliersSlice";
import { SliceStatus } from "../utils";

export const supplierContactsAdapter = createEntityAdapter<SupplierContact>({
  selectId: (supplierContact) => supplierContact.id,
});

const initialState = supplierContactsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const supplierContactsSlice = createSlice({
  name: "supplierContacts",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getSuppliersBasedOnCurrentPage.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getSuppliersBasedOnCurrentPage.fulfilled, (state, { payload }) => {
      supplierContactsAdapter.upsertMany(state, payload.results.supplierContacts);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSuppliersBasedOnCurrentPage.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(getSuppliersForInfiniteLoading.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getSuppliersForInfiniteLoading.fulfilled, (state, { payload }) => {
      supplierContactsAdapter.upsertMany(state, payload.results.supplierContacts);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSuppliersForInfiniteLoading.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createSupplier.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createSupplier.fulfilled, (state, { payload }) => {
      const { supplierContacts } = payload;

      supplierContactsAdapter.upsertMany(state, supplierContacts);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createSupplier.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateSupplier.fulfilled, (state, { payload }) => {
      const { supplierContacts, deletedContacts } = payload;

      supplierContactsAdapter.upsertMany(state, supplierContacts);

      supplierContactsAdapter.removeMany(
        state,
        deletedContacts.map((deletedContact) => deletedContact.id)
      );
    });
  },
});

export default supplierContactsSlice.reducer;
