import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const EmptyCart = () => {
  const { t } = useTranslation("checkout");

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} height="90%">
      <img alt="Reset Password" src="/images/empty-cart-img.svg" width="225px" />

      <Typography variant="h1">{t("yourCartIsEmpty")}</Typography>
      <Typography variant="primary">{t("youHaveNotAddItems")}</Typography>
    </Stack>
  );
};

export default EmptyCart;
