import {
  CreateLoyaltyProgramRequestArgs,
  LoyaltyProgram,
  LoyaltyProgramListResponse,
  UpdateLoyaltyProgram,
  UpdateLoyaltyProgramArgs,
} from "types/LoyaltyProgram";
import http from "utils/http";

const loyaltyProgramsApi = {
  getLoyaltyProgramsBrief: async () => http.get<LoyaltyProgram[]>("loyalty/brief/"),

  getLoyaltyPrograms: async ({
    page,
    ordering,
    searchQuery,
  }: {
    page: number;
    ordering: string;
    searchQuery: string;
  }) =>
    http.get<LoyaltyProgramListResponse>(
      `loyalty/loyalty-programs/?page=${page}${ordering ? `&order_by=${ordering}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  getLoyaltyProgramDetails: async ({ id }: { id: number }) =>
    http.get<LoyaltyProgram>(`loyalty/loyalty-programs/${id}/`),

  createLoyaltyProgram: async ({ data }: { data: CreateLoyaltyProgramRequestArgs }) =>
    http.post<UpdateLoyaltyProgram>("loyalty/loyalty-programs/", data),

  updateLoyaltyProgram: async ({ id, data }: { id: number; data: UpdateLoyaltyProgramArgs }) =>
    http.put<UpdateLoyaltyProgram>(`loyalty/loyalty-programs/${id}/`, data),

  deleteLoyaltyProgram: async ({ id }: { id: number }) =>
    http.delete(`loyalty/loyalty-programs/${id}/`),
};

export default loyaltyProgramsApi;
