import { Remove } from "@bookpeep/ui";
import { Box, IconButton } from "@mui/material";
import { LoyaltyGiftIcon } from "assets/icon";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import auth from "utils/auth";

import { FormArticle } from "../utils";
import PackageArticleItem from "./PackageArticleItem";
import SlotForm from "./SlotForm";

type ArticleItemProps = {
  article: FormArticle;
  articleIndex: number;
  articleCount: number;
  addNewArticle: () => void;
};

function ArticleItem({ article, articleIndex, articleCount, addNewArticle }: ArticleItemProps) {
  const isArticleAlone = articleCount === 1; // 😢

  const { remove } = useFieldArray({ name: "articles" });
  const removeArticle = () => remove(articleIndex);

  const { hasPersonalAppointment, hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const isTheOnlyStaffAssignedToTheArticle =
    article.slots?.every((slot) => slot.staffId === authenticatedUserEmployeeId) || false;

  const canEditArticle =
    hasNonPersonalAppointment.editAccess ||
    (isTheOnlyStaffAssignedToTheArticle && hasPersonalAppointment.editAccess);

  const canRemoveArticle = !isArticleAlone && canEditArticle;

  const shouldShowLoyaltyRewardIcon = Boolean(article.rewardLoyaltyCard);

  return (
    <Box display="flex" gap={1.5}>
      {article.packageVariantId ? (
        <PackageArticleItem articleIndex={articleIndex} addNewArticle={addNewArticle} />
      ) : (
        <SlotForm
          articleIndex={articleIndex}
          disableVariantField={shouldShowLoyaltyRewardIcon}
          addNewArticle={addNewArticle}
        />
      )}

      <Box height="48px" display="flex" alignItems="center">
        {shouldShowLoyaltyRewardIcon ? (
          <IconButton
            sx={{
              "&.Mui-disabled": {
                backgroundColor: "#D9DAF7",
              },
            }}
            disableFocusRipple
            disableTouchRipple
            disabled
          >
            <LoyaltyGiftIcon viewBox="0 0 16 16" sx={{ height: 18, width: 18 }} color="primary" />
          </IconButton>
        ) : (
          <IconButton onClick={removeArticle} disabled={!canRemoveArticle} color="error">
            <Remove />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default ArticleItem;
