import InvoiceArticleRow from "./InvoiceArticleRow";
import InvoiceArticlesHeaderItem from "./InvoiceArticlesHeaderItem";

const InvoiceArticlesHeader = () => {
  return (
    <InvoiceArticleRow
      quantityColumnContent={<InvoiceArticlesHeaderItem titleTranslation="qty" align="left" />}
      itemsDescriptionColumnContent={
        <InvoiceArticlesHeaderItem titleTranslation="itemName" align="left" />
      }
      priceColumnContent={<InvoiceArticlesHeaderItem titleTranslation="price" align="right" />}
    />
  );
};

export default InvoiceArticlesHeader;
