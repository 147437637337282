import { Grid } from "@mui/material";
import { DiscountField, PriceField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import useFormValidation from "hooks/useFormValidation";
import EmployeeAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/EmployeeAutoComplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectVoucherById } from "store/selectors";

import { useCheckoutFormContext } from "../CheckoutFormContext";

type CheckoutCartItemVoucherFieldsProps = {
  cartItemIndex: number;
  shouldDisableEditing: boolean;
};

const CheckoutCartItemVoucherFields = ({
  cartItemIndex,
  shouldDisableEditing,
}: CheckoutCartItemVoucherFieldsProps) => {
  const { t } = useTranslation("checkout");

  const { outletId } = useCheckoutModal();

  const { hasDiscount } = useSelector(selectAllPermissions);

  const { setValue, formState, watch } = useCheckoutFormContext();

  const registerValidation = useFormValidation(formState, { ns: "checkout" });

  const articleIndex: `articles.${number}` = `articles.${cartItemIndex}`;
  const discountValueFieldPath: `articles.${number}.discountValue` = `${articleIndex}.discountValue`;

  const article = watch(articleIndex);

  const { discountValue, originalPrice, markedPrice, finalPrice } = article;

  const employeeFieldPath: `articles.${number}.details.${number}.saleEmployee` = `${articleIndex}.details.0.saleEmployee`;

  const finalPriceFieldPath: `articles.${number}.finalPrice` = `${articleIndex}.finalPrice`;

  const { appliedClientSpecialDiscount } = watch();
  const hasAppliedClientSpecialDiscount = appliedClientSpecialDiscount > 0;

  const selectedStaff = watch(`${employeeFieldPath}`);

  const voucherId = watch(`${articleIndex}.purchasedItem.voucher`);

  const voucher = useSelector(selectVoucherById(voucherId || 0));

  const canApplyDiscount = hasDiscount.editAccess && !hasAppliedClientSpecialDiscount;

  const maxPrice = originalPrice;
  const minPrice = canApplyDiscount ? 0 : originalPrice;

  const canModifyPrice = maxPrice !== minPrice && !hasAppliedClientSpecialDiscount;

  const onStaffChange = (staffId: number | null) => {
    setValue(`${employeeFieldPath}`, staffId || undefined);
  };

  const handleDiscountChange = (value: number) => {
    setValue(discountValueFieldPath, value);

    const discountedPrice = markedPrice * ((100 - value) / 100);
    setValue(finalPriceFieldPath, discountedPrice);
    setValue("payments", []);
  };

  const handleFinalPriceChange = (value: number) => {
    if (value > originalPrice) {
      setValue(discountValueFieldPath, 0);
      setValue(finalPriceFieldPath, markedPrice);
    } else {
      const discountedPercentage = 100 - (value / markedPrice) * 100;

      setValue(discountValueFieldPath, Number(discountedPercentage.toFixed(2)));
      setValue(finalPriceFieldPath, value);
    }

    setValue("payments", []);
  };

  if (!voucher) return <CentredSpinnerBox />;

  return (
    <>
      <Grid item md={3} xs={12}>
        <EmployeeAutoComplete
          outletId={outletId}
          value={selectedStaff || null}
          onChange={onStaffChange}
          hasAllOutletEmployees
          {...registerValidation(employeeFieldPath, "staff")}
          disabled={shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <PriceField
          label={t("price")}
          fullWidth
          value={finalPrice}
          max={maxPrice}
          min={minPrice}
          fallbackValue={originalPrice}
          {...registerValidation(finalPriceFieldPath)}
          onChange={handleFinalPriceChange}
          disabled={!canModifyPrice || shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <DiscountField
          value={discountValue}
          onChange={handleDiscountChange}
          label={t("discount")}
          fullWidth
          disabled={!canApplyDiscount || shouldDisableEditing}
        />
      </Grid>
    </>
  );
};

export default CheckoutCartItemVoucherFields;
