import useCheckoutModal from "hooks/useCheckoutModal";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { Invoice } from "types/Checkout";

import CheckoutInvoicePaymentsSummary from "./CheckoutInvoicePaymentsSummary";
import CheckoutRefundInvoicePaymentsSummary from "./CheckoutRefundInvoicePaymentsSummary";

type CheckoutPaymentsSummaryProps = {
  invoice?: Invoice;
};

const CheckoutPaymentsSummary = ({ invoice }: CheckoutPaymentsSummaryProps) => {
  const { checkoutModalView } = useCheckoutModal();

  return (checkoutModalView === CheckoutModalView.Refund ||
    checkoutModalView === CheckoutModalView.ConfirmRefund) &&
    invoice ? (
    <CheckoutRefundInvoicePaymentsSummary invoice={invoice} />
  ) : (
    <CheckoutInvoicePaymentsSummary />
  );
};

export default CheckoutPaymentsSummary;
