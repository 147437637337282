import { ReactNode, createContext, useContext } from "react";
import { PurchasableItem, PurchasableItemOption } from "types/PurchasableItem";

export type OnSelectItemFunction = (
  purchasableItem: PurchasableItem,
  purchasableItemType: PurchasableItemOption
) => void;

export type OnSelectAllCategoryItemsFunction = (
  purchasableItems: PurchasableItem[],
  purchasableItemType: PurchasableItemOption
) => void;

export type SelectedItems = {
  [key in PurchasableItemOption]: number[];
};

export type PurchasableItemsSelectorContextValues = {
  onSelectItem: OnSelectItemFunction;

  // To highlight selected items, you need to pass the selectedItems object
  shouldHighlightSelectedItems?: boolean;
  selectedItems?: SelectedItems;

  clientId?: Nullable<number>;
  outletId?: Nullable<number>;

  shouldShowPromotionTag?: boolean;

  shouldAllowSelectAllCategoryItems?: boolean;
  onSelectAllCategoryItems?: OnSelectAllCategoryItemsFunction;

  shouldDisableLoyaltyIneligibleItems?: boolean;
};

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

const initialState: PurchasableItemsSelectorContextValues = {
  onSelectItem: unimplementedFunction,

  clientId: null,
  outletId: null,
};

export const PurchasableItemsSelectorContext =
  createContext<PurchasableItemsSelectorContextValues>(initialState);

/**
 *
 * 🧼 To My Precious Teammates:
 * Please use this hook to access the PurchasableItemsSelectorContext values - Anfal
 *
 */
export function usePurchasableItemsSelector() {
  const context = useContext(PurchasableItemsSelectorContext);

  if (context === undefined)
    throw new Error(
      "The usePurchasableItemsSelector hook must be used within a PurchasableItemsSelectorContext.Provider"
    );

  return context;
}

type PurchasableItemsSelectorProviderProps = {
  children: ReactNode;
  values: PurchasableItemsSelectorContextValues;
};

const PurchasableItemsSelectorProvider = ({
  children,
  values,
}: PurchasableItemsSelectorProviderProps) => {
  if (values.shouldHighlightSelectedItems && !values.selectedItems)
    throw new Error("You must pass the selectedItems object to highlight selected items");

  if (values.shouldAllowSelectAllCategoryItems && !values.onSelectAllCategoryItems)
    throw new Error(
      "You must pass the onSelectAllCategoryItems function to allow selecting all category items"
    );

  return (
    <PurchasableItemsSelectorContext.Provider
      value={{
        ...values,
      }}
    >
      {children}
    </PurchasableItemsSelectorContext.Provider>
  );
};

export default PurchasableItemsSelectorProvider;
