import { Close, Filter } from "@bookpeep/ui";
import { Box, Button, Drawer, Grid, IconButton, Typography, styled } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { cloneElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function createObjectFromArray(filters) {
  return filters.reduce(
    (state, filterComponent, index) => ({
      ...state,
      [index]: filterComponent.props.value,
    }),
    {}
  );
}

const FiltersDrawer = ({ open, onClose, onClear, filterComponents }) => {
  const { t } = useTranslation("translation");

  const isMobileView = useIsMobileView();

  const [filterValues, setFilterValues] = useState(createObjectFromArray(filterComponents));

  useEffect(() => {
    if (open) setFilterValues(createObjectFromArray(filterComponents));
  }, [open, filterComponents]);

  const replaceFilterValue = (newValue) => {
    setFilterValues(newValue);
  };
  const setSpecificFilterValue = (index) => (newValue) =>
    setFilterValues({ ...filterValues, [index]: newValue });

  const handleConfirm = () => {
    filterComponents.forEach((filterComponent, index) =>
      filterComponent.props.setValue(filterValues[index])
    );

    onClose();
  };

  const handleClear = () => {
    onClear();
    onClose();
  };

  return (
    <Drawer
      anchor={isMobileView ? "bottom" : "right"}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: paperStyles }}
    >
      <Grid container p={{ xs: 2, md: 3 }}>
        <Grid display="flex" flexDirection="row" alignItems="center" item xs={10}>
          <Grid item>
            <Filter color="primary" />
          </Grid>
          <Grid item>
            <Typography fontSize="1.375rem" fontWeight={700}>
              {t("filters")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={2} textAlign="right">
          <StyledIconButton onClick={onClose}>
            <StyledCloseIcon />
          </StyledIconButton>
        </Grid>

        <Grid container spacing={4} pt={5} pb={5}>
          {filterComponents.map((filter, index) => (
            <Grid item xs={12} key={index}>
              {cloneElement(filter, {
                unconfirmedValue: filterValues[index],
                setUnconfirmedValue: setSpecificFilterValue(index),
                replaceFilterValue,
              })}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ActionsWrapper
        sx={{
          padding: 2,
          marginTop: { xs: 5, md: 0 },
        }}
      >
        <Grid
          container
          spacing={2.5}
          sx={{ display: "flex", flexDirection: { xs: "row-reverse", md: "row" } }}
        >
          <Grid item md={12} xs={6}>
            <Button fullWidth variant="contained" onClick={handleConfirm}>
              {t("applyFilters")}
            </Button>
          </Grid>

          <Grid item md={12} xs={6}>
            <Button fullWidth variant="outlined" onClick={handleClear}>
              {t("clear")}
            </Button>
          </Grid>
        </Grid>
      </ActionsWrapper>
    </Drawer>
  );
};

const ActionsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    borderTop: `1px solid ${theme.palette.common.gray}25`,
  },
}));

export default FiltersDrawer;

const paperStyles = {
  maxWidth: { md: "27.5rem", xs: "100%" },
  width: "100%",
  borderTopLeftRadius: {
    md: 0,
    xs: 4,
  },
  borderTopRightRadius: {
    md: 0,
    xs: 4,
  },
};

const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  width: ".5rem",
  height: ".5rem",
  "&:hover": { backgroundColor: "transparent" },
}));

const StyledCloseIcon = styled(Close)(() => ({
  fontSize: "1rem",
}));
