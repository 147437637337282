export type PeepPayDepositArguments = {
  phoneNumber: string;
  phoneCountryCode: string;
};

export enum PeepPayLinkStatus {
  Pending = "PAYMENT PENDING",
  Paid = "PAID",
  Expired = "EXPIRED",
  Canceled = "CANCELLED",
}

export type PeepPayDeposit = {
  id: number;
  status: PeepPayLinkStatus;
};

export type AppointmentDeposit = {
  id: number;

  amount: number;
  paymentMethod: string;

  peepPay: Nullable<PeepPayDeposit>;

  appointment: number;

  createdAt: string;
};
