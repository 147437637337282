import { ButtonProps, Button as MuiButton, styled } from "@mui/material";

const AppointmentFormSectionActionButton = (props: ButtonProps) => (
  <Button variant="text" size="small" {...props} />
);

const Button = styled(MuiButton)(({ theme }) => ({
  height: theme.spacing(3),
  minHeight: theme.spacing(3),
  maxHeight: theme.spacing(3),
  fontSize: ".875rem",
  padding: 0,
}));

export default AppointmentFormSectionActionButton;
