import { Box, Grid, Typography, styled } from "@mui/material";

type TotalsSectionProps = {
  totalCountText: string;
  totalCount: number;
  walletsCreditsText: string;
  walletCredits: string;
};

const TotalsSection = ({
  totalCountText,
  totalCount,
  walletsCreditsText,
  walletCredits,
}: TotalsSectionProps) => {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item md={6} xs={12}>
        <WrapperBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Typography fontWeight={700} fontSize="1.125rem">
            {totalCountText}
          </Typography>

          <Typography fontWeight={700} color="primary" fontSize="1.5rem">
            {totalCount}
          </Typography>
        </WrapperBox>
      </Grid>

      <Grid item md={6} xs={12}>
        <WrapperBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Typography fontWeight={700} fontSize="1.125rem">
            {walletsCreditsText}
          </Typography>

          <Typography fontWeight={700} color="primary" fontSize="1.5rem">
            {walletCredits}
          </Typography>
        </WrapperBox>
      </Grid>
    </Grid>
  );
};

export default TotalsSection;

const WrapperBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#F7F8FD",
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  height: "100%",
}));
