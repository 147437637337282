import { Box } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganization } from "store/organization/organizationSlice";
import { getCalendarCards } from "store/slices/calendar/calendarSlice";

import AppointmentFormClientSection from "./AppointmentFormClientSection";
import AppointmentFormSummary from "./AppointmentFormSummary";
import { HomeServicesAppointmentForm } from "./HomeServicesAppointmentForm/HomeServicesAppointmentForm";
import ServiceSection from "./ServiceSection";

type AppointmentFormProps = {
  onClose: () => void;
  existingClientActionButton: React.ReactNode;
};

const AppointmentForm = ({ onClose, existingClientActionButton }: AppointmentFormProps) => {
  const { outletId, date, calendarView } = useContext(CalendarPageContext);

  const organization = useSelector(selectOrganization) as Organization;
  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization?.industry || 0);

  const { isAppointmentDrawerOpen, isAppointmentLoading, isEditAppointmentLoading } =
    useAppointmentDrawer();

  const isLoading = isAppointmentLoading;

  const dispatch = useDispatch();

  //Fetches only the date clicked on whenever the appointment form is open whether in create or edit mode
  useEffect(() => {
    if (isAppointmentDrawerOpen) {
      const startDate = date.toFormat("yyyy-MM-dd");
      const endDate = date.plus({ days: 1 }).toFormat("yyyy-MM-dd");
      dispatch(
        // @ts-expect-error
        getCalendarCards({
          startDate,
          endDate,
          outletId: String(outletId),
        })
      );
    }
  }, [isAppointmentDrawerOpen, date, outletId, calendarView, dispatch]);

  const isLoadingAppointmentForm = isLoading || isEditAppointmentLoading;

  return isHomeServiceOrganization ? (
    <HomeServicesAppointmentForm
      isLoading={isLoadingAppointmentForm}
      onClose={onClose}
      existingClientActionButton={existingClientActionButton}
    />
  ) : (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: "100%",
        height: "100%",
      }}
    >
      {isLoadingAppointmentForm ? (
        <CentredSpinnerBox />
      ) : (
        <>
          <AppointmentFormClientSection existingClientActionButton={existingClientActionButton} />

          <Box overflow="scroll" width="100%" p={3} flexGrow={1}>
            <ServiceSection />
          </Box>

          <Box p={3} boxShadow="topDivider">
            <AppointmentFormSummary onClose={onClose} />
          </Box>
        </>
      )}
    </form>
  );
};

export { AppointmentForm };
