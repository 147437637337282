import { Box } from "@mui/material";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import { useSelector } from "store";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectAllOnlineBookingStatuses } from "store/selectors";
import { selectAllActiveZones } from "store/slices/zones/zonesSelectors";

import OnlineBookingStatusItem from "./OnlineBookingStatusItem";
import ZoneOnlineBookingStatusItem from "./ZoneOnlineBookingStatusItem";

const OnlineBookingStatusList = () => {
  const onlineBookingStatus = useSelector(selectAllOnlineBookingStatuses);

  const organization = useSelector(selectOrganization);

  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization.industry || 0);

  const zones = useSelector(selectAllActiveZones);

  return (
    <Box pt={2}>
      {isHomeServiceOrganization
        ? zones.map((zone) => (
            <ZoneOnlineBookingStatusItem
              key={zone.id}
              zoneId={zone.id}
              zoneName={zone.name}
              isAcceptingOnlineBookings={zone.isAcceptingOnlineBookings}
            />
          ))
        : onlineBookingStatus.map((outlet) => (
            <OnlineBookingStatusItem
              key={outlet.id}
              outletId={outlet.id}
              outletName={outlet.name}
              isAcceptingOnlineBookings={outlet.isAcceptingOnlineBookings}
            />
          ))}
    </Box>
  );
};

export default OnlineBookingStatusList;
