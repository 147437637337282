import { z } from "zod";

const depositFormSchema = z
  .object({
    appointment: z.number().min(1),

    paymentType: z.number().nullable(),
    wallet: z.boolean().nullable(),

    peepPay: z
      .object({
        phoneNumber: z.string(),
        phoneCountryCode: z.string(),
      })
      .nullable(),

    amount: z.number(),
  })
  .partial()
  .superRefine(({ paymentType, wallet, peepPay }, ctx) => {
    if (!paymentType && !wallet && !peepPay) {
      return ctx.addIssue({
        code: "custom",
        path: ["paymentType"],
        message: "mixed.required",
      });
    }
  });

export default depositFormSchema;
