import { FullWidthModal } from "@bookpeep/ui";
import { Box, Popover } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

function DatePickerWrapper({
  open,
  anchorEl,
  closePopover,
  children,
  isSchedulePromotionModal = false,
}) {
  const { t } = useTranslation("sales");

  const isMobileView = useIsMobileView();

  if (isMobileView) {
    return (
      <FullWidthModal onClose={closePopover} open={open} title={t("dataRange")}>
        <Box px={2} py={3} overflow="auto">
          {children}
        </Box>
      </FullWidthModal>
    );
  }
  return (
    <Popover
      PaperProps={{
        sx: {
          width: "41rem",
          minHeight: isSchedulePromotionModal ? "26rem" : "37.5rem",
          padding: "24px",
        },
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </Popover>
  );
}

export default DatePickerWrapper;
