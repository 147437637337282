import { Grid } from "@mui/material";
import { DiscountField, PriceField } from "components/common";
import NumberField from "components/common/NumberField";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import useCheckoutModal from "hooks/useCheckoutModal";
import useFormValidation from "hooks/useFormValidation";
import EmployeeAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/EmployeeAutoComplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import {
  selectAllProductActivePromotionsByProductIdBasedOnDate,
  selectProductPromotionByProductIdAndPromotionId,
} from "store/selectors";
import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";
import { isProductActivePromotion } from "types/typeGuards/ActivePromotionsTypeGuards";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPromotionSelectField from "./CheckoutPromotionSelectField";

type CheckoutCartItemProductFieldsProps = {
  cartItemIndex: number;
  shouldDisableEditing: boolean;
};

const CheckoutCartItemProductFields = ({
  cartItemIndex,
  shouldDisableEditing,
}: CheckoutCartItemProductFieldsProps) => {
  const { t } = useTranslation("checkout");

  const { outletId } = useCheckoutModal();

  const { hasDiscount } = useSelector(selectAllPermissions);

  const { formState, setValue, watch, date } = useCheckoutFormContext();

  const registerValidation = useFormValidation(formState, { ns: "checkout" });

  const articleIndex: `articles.${number}` = `articles.${cartItemIndex}`;

  const promotionIdFieldPath: `articles.${number}.promotionId` = `${articleIndex}.promotionId`;
  const quantityFieldPath: `articles.${number}.quantity` = `${articleIndex}.quantity`;
  const employeeFieldPath: `articles.${number}.details.${number}.saleEmployee` = `${articleIndex}.details.0.saleEmployee`;
  const productFieldPath: `articles.${number}.details.${number}.purchasedItem.product` = `${articleIndex}.details.0.purchasedItem.product`;

  const markedPriceFieldPath: `articles.${number}.markedPrice` = `${articleIndex}.markedPrice`;
  const finalPriceFieldPath: `articles.${number}.finalPrice` = `${articleIndex}.finalPrice`;
  const discountValueFieldPath: `articles.${number}.discountValue` = `${articleIndex}.discountValue`;

  const article = watch(articleIndex);

  const { discountValue, promotionId = null, originalPrice, markedPrice, finalPrice } = article;

  const { appliedClientSpecialDiscount } = watch();
  const hasAppliedClientSpecialDiscount = appliedClientSpecialDiscount > 0;

  const canApplyDiscount = hasDiscount.editAccess && !hasAppliedClientSpecialDiscount;

  const maxPrice = originalPrice;
  const minPrice = canApplyDiscount ? 0 : originalPrice;

  const canModifyPrice = maxPrice !== minPrice && !hasAppliedClientSpecialDiscount;
  const canApplyPromotion = !hasAppliedClientSpecialDiscount;

  const productId = watch(productFieldPath);

  const productActivePromotions = useSelector(
    selectAllProductActivePromotionsByProductIdBasedOnDate(productId || 0, date)
  );

  const selectedPromotion = useSelector(
    selectProductPromotionByProductIdAndPromotionId(productId || 0, promotionId || 0)
  );

  const isSelectedPromotionInActivePromotions = !!productActivePromotions.find(
    (activePromotion) => activePromotion.promotion === promotionId
  );

  const selectedStaff = watch(`${employeeFieldPath}`);

  const onStaffChange = (staffId: number | null) => {
    setValue(`${employeeFieldPath}`, staffId || undefined);
  };

  const handleDiscountChange = (value: number) => {
    setValue(discountValueFieldPath, value);
    const discountedPrice = markedPrice * ((100 - value) / 100);

    setValue(finalPriceFieldPath, discountedPrice);
    setValue("payments", []);
    setValue(promotionIdFieldPath, null);
  };

  const handleFinalPriceChange = (value: number) => {
    if (value > originalPrice) {
      setValue(discountValueFieldPath, 0);
    } else {
      const discountedPercentage = 100 - (value / originalPrice) * 100;

      setValue(discountValueFieldPath, Number(discountedPercentage.toFixed(2)));
      setValue(promotionIdFieldPath, null);
      setValue(markedPriceFieldPath, originalPrice);
    }

    setValue(finalPriceFieldPath, value);

    setValue("payments", []);
  };

  const quantity = Number(watch(quantityFieldPath));

  const handleQuantityChange = (value: number) => {
    setValue(quantityFieldPath, value);
  };

  const handlePromotionChange = (
    value: Nullable<
      ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
    >
  ) => {
    if (value) {
      if (isProductActivePromotion(value)) {
        const finalPriceAfterPromotion = getFinalPriceAfterDiscount({
          originalPrice: markedPrice,
          discountOption: value.discountType,
          discountValue: value.value,
        });

        setValue(promotionIdFieldPath, value.promotion);
        setValue(finalPriceFieldPath, finalPriceAfterPromotion);
        setValue(discountValueFieldPath, 0);
      }
    } else {
      setValue(promotionIdFieldPath, null);
      setValue(finalPriceFieldPath, markedPrice);
      setValue(discountValueFieldPath, 0);
    }
  };

  return (
    <>
      <Grid item md={1} xs={12}>
        <NumberField
          label={t("qty")}
          min={1}
          fullWidth
          {...registerValidation(`${articleIndex}.quantity`)}
          value={quantity}
          onChange={handleQuantityChange}
          disabled={shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <EmployeeAutoComplete
          outletId={outletId}
          value={selectedStaff || null}
          onChange={onStaffChange}
          hasAllOutletEmployees
          {...registerValidation(employeeFieldPath, "staff")}
          disabled={shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <PriceField
          label={t("price")}
          fullWidth
          value={finalPrice}
          max={maxPrice}
          min={minPrice}
          fallbackValue={originalPrice}
          {...registerValidation(finalPriceFieldPath)}
          onChange={handleFinalPriceChange}
          disabled={!canModifyPrice || shouldDisableEditing}
        />
      </Grid>

      <Grid item md={2} xs={12}>
        <DiscountField
          value={discountValue}
          onChange={handleDiscountChange}
          label={t("discount")}
          fullWidth
          disabled={!canApplyDiscount || shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <CheckoutPromotionSelectField
          value={promotionId}
          onChange={handlePromotionChange}
          promotions={
            !isSelectedPromotionInActivePromotions && selectedPromotion
              ? [...productActivePromotions, selectedPromotion]
              : productActivePromotions
          }
          arePromotionsLoading={false}
          disabled={!canApplyPromotion || shouldDisableEditing}
        />
      </Grid>
    </>
  );
};

export default CheckoutCartItemProductFields;
