import type { RootState } from "store";
import { selectArticleDetailsWithSlotsById } from "store/articles/articlesSlice";
import { selectOnlinePaymentsByAppointmentId } from "store/onlinePayments/onlinePaymentsSlice";
import { selectInvoicesByAppointmentId } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import {
  Appointment,
  AppointmentArticleWithDetails,
  CheckoutLoyaltyCard,
  GetAppointmentDetailsResponse,
} from "types/Appointment";
import { Invoice, OnlinePayment } from "types/Checkout";

import { selectAppointmentById } from "./appointmentsSlice";

export const selectCheckoutAppointmentById = (id: number | undefined) => (state: RootState) => {
  const appointment = selectAppointmentById(id)(state) as Appointment | undefined;

  if (!appointment) return;

  const articles = appointment.articles
    .map((articleId) => selectArticleDetailsWithSlotsById(articleId)(state))
    .filter((article) => !!article) as AppointmentArticleWithDetails[];

  const invoices = selectInvoicesByAppointmentId(appointment.id)(state) as Invoice[];

  const onlinePayments = selectOnlinePaymentsByAppointmentId(id)(state) as OnlinePayment[];

  if (!articles.length) return;

  const appointmentDetails = selectAppointmentDetails(
    state
  ) as Nullable<GetAppointmentDetailsResponse>;
  // TODO: We should be able to return appointmentDetails directly we just need to make sure the types are correct, but for we just return the missing stuff we are not processing on the redux store
  if (appointmentDetails?.appointment?.id === id)
    return {
      ...appointmentDetails,
      ...appointment,
      articles,
      slots: appointmentDetails?.slots,
      invoices,
      onlinePayments,
    };

  // fallback if appointmentDetails is not in the store for some reason
  return { ...appointment, articles, invoices, onlinePayments };
};

export const selectAppointmentLoyaltyCards =
  (id: number) =>
  (state: RootState): Appointment["rewardLoyaltyCards"] | undefined =>
    selectCheckoutAppointmentById(id)(state)?.rewardLoyaltyCards;

export const selectAppointmentLoyaltyCardByAppointmentIdAndLoyaltyCardId =
  (appointmentId: number, loyaltyCardId: number) =>
  (state: RootState): CheckoutLoyaltyCard | undefined =>
    selectAppointmentLoyaltyCards(appointmentId)(state)?.find(
      (loyaltyCard) => loyaltyCard.id === loyaltyCardId
    );

export const selectAppointmentDetails = (
  state: RootState
): Nullable<GetAppointmentDetailsResponse> => state.appointments.appointmentDetails;
