import { ArrowLeft } from "@bookpeep/ui";
import { AppBar, Dialog, Grid, IconButton, Slide, Typography, styled } from "@mui/material";
import * as React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function FullWidthModal({ open, onClose, children, actions, title }) {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            overflow: "hidden",
          },
        }}
      >
        <ModalHeader elevation={0} position="sticky">
          <HeaderWrapper container alignItems="center">
            <Grid item xs={2} md={4}>
              {onClose && (
                <IconButton onClick={() => onClose()} aria-label="close">
                  <ArrowLeft sx={{ color: "black", width: { xs: "16px", md: "auto" } }} />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={8} md={4}>
              <Title sx={{ textAlign: "center", flex: 1 }} variant="h3" component="h3">
                {title}
              </Title>
            </Grid>
            <Grid item xs={2} md={4}>
              {actions}
            </Grid>
          </HeaderWrapper>
        </ModalHeader>
        {children}
      </Dialog>
    </div>
  );
}

const ModalHeader = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.common.gray}25`,
  backgroundColor: "white",
  minHeight: "81px",
  [theme.breakpoints.down("md")]: {
    minHeight: "60px",
  },
}));

const HeaderWrapper = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.common.gray}25`,
  backgroundColor: "white",
  padding: theme.spacing(2, 3),
  minHeight: "81px",
  [theme.breakpoints.down("md")]: {
    minHeight: "60px",
    padding: theme.spacing(2, 2),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

export default FullWidthModal;
