import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./packageVariantsApi";

const packageVariantAdapter = createEntityAdapter();

const initialState = packageVariantAdapter.getInitialState({
  isLoading: false,
  error: "",
});

export const getAllPackageVariants = createThunkFromApi(
  "packageVariants/getAllPackageVariants",
  api.getAllPackageVariants
);

export const getPackage = createThunkFromApi(
  "packageVariants/getPackageVariant",
  api.getPackageVariant
);

export const createPackageVariant = createThunkFromApi(
  "packageVariants/createPackageVariant",
  api.createPackageVariant
);

export const updatePackageVariant = createThunkFromApi(
  "packageVariants/updatePackageVariant",
  api.updatePackageVariant
);

export const deletePackageVariant = createThunkFromApi(
  "packageVariants/removePackageVariant",
  api.removePackageVariant
);

export const packageVariantsSlice = createSlice({
  name: "packageVariants",
  initialState,
  extraReducers: {
    [getAllPackageVariants.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPackageVariants.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      packageVariantAdapter.setAll(state, payload);
    },
    [getAllPackageVariants.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getPackage.fulfilled]: (state, { payload }) => {
      packageVariantAdapter.setOne(state, payload);
    },

    [createPackageVariant.fulfilled]: (state, { payload }) => {
      packageVariantAdapter.addOne(state, payload);
    },

    [updatePackageVariant.fulfilled]: (state, { payload }) => {
      packageVariantAdapter.updateOne(state, {
        id: payload,
        changes: payload,
      });
    },

    [deletePackageVariant.fulfilled]: (state, { payload: id }) => {
      packageVariantAdapter.removeOne(state, id);
    },
  },
});

export default packageVariantsSlice.reducer;
