import { useMediaQuery, useTheme } from "@mui/material";

/**
 * @returns True if screen is smaller than the "md" breakpoint (default 900px width)
 */
export default function useIsMobileView() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  return isMobileView;
}
