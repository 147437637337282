import { checkIsDateInThePast } from "helpers/checkIsDateInThePast";
import { callSortDescendingByKey } from "helpers/sort";
import { DateTime } from "luxon";
import { RootState } from "store";
import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";

import { SliceStatus } from "../utils";
import {
  activePromotionsAdapter,
  packageVariantActivePromotionsAdapter,
  productActivePromotionsAdapter,
  serviceVariantActivePromotionsAdapter,
} from "./activePromotionsSlice";

export const selectActivePromotionsLoading = (outlet: number, date: string) => (state: RootState) =>
  state.activePromotions.status === SliceStatus.LOADING ||
  state.activePromotions.date !== date ||
  state.activePromotions.outlet !== outlet;

export const selectActivePromotionsState = (state: RootState) =>
  state.activePromotions.basicPromotions;
export const selectServiceVariantActivePromotionsState = (state: RootState) =>
  state.activePromotions.serviceVariants;
export const selectPackageVariantActivePromotionsState = (state: RootState) =>
  state.activePromotions.packageVariants;
export const selectProductActivePromotionsState = (state: RootState) =>
  state.activePromotions.products;

export const {
  selectById: selectActivePromotionByIdAdapterSelector,
  selectAll: selectAllActivePromotionsAdapterSelector,
} = activePromotionsAdapter.getSelectors(selectActivePromotionsState);

const { selectAll: selectAllServiceVariantActivePromotionsAdapterSelector } =
  serviceVariantActivePromotionsAdapter.getSelectors(selectServiceVariantActivePromotionsState);

const { selectAll: selectAllPackageVariantActivePromotionsAdapterSelector } =
  packageVariantActivePromotionsAdapter.getSelectors(selectPackageVariantActivePromotionsState);

const { selectAll: selectAllProductActivePromotionsAdapterSelector } =
  productActivePromotionsAdapter.getSelectors(selectProductActivePromotionsState);

export const selectAllActivePromotions = selectAllActivePromotionsAdapterSelector;

export const selectAllServiceVariantPromotions =
  selectAllServiceVariantActivePromotionsAdapterSelector;

export const selectAllPackageVariantPromotions =
  selectAllPackageVariantActivePromotionsAdapterSelector;

export const selectAllProductPromotions = selectAllProductActivePromotionsAdapterSelector;

// IF YOU ONLY NEED ACTIVE PROMOTIONS and you want them sorted by lastScheduleUpdatedAt, USE THESE SELECTORS
export const selectAllServiceVariantActivePromotions = (state: RootState) => {
  const serviceVariantActivePromotions = selectAllServiceVariantPromotions(state).filter(
    (promotion) => promotion.active
  );

  const sortedServiceVariantActivePromotionsByLatestUpdatedSchedule = serviceVariantActivePromotions
    .map((serviceVariantPromotion) => {
      const promotion = selectActivePromotionByIdAdapterSelector(
        state,
        serviceVariantPromotion.promotion
      );

      return {
        ...serviceVariantPromotion,
        lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
      };
    })
    .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
    //  now remove the lastScheduleUpdatedAt property
    .map(({ lastScheduleUpdatedAt, ...rest }) => rest);

  return sortedServiceVariantActivePromotionsByLatestUpdatedSchedule;
};

export const selectAllPackageVariantActivePromotions = (state: RootState) => {
  const packageVariantActivePromotions = selectAllPackageVariantPromotions(state).filter(
    (promotion) => promotion.active
  );

  const sortedPackageVariantActivePromotionsByLatestUpdatedSchedule = packageVariantActivePromotions
    .map((packageVariantPromotion) => {
      const promotion = selectActivePromotionByIdAdapterSelector(
        state,
        packageVariantPromotion.promotion
      );

      return {
        ...packageVariantPromotion,
        lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
      };
    })
    .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
    //  now remove the lastScheduleUpdatedAt property
    .map(({ lastScheduleUpdatedAt, ...rest }) => rest);

  return sortedPackageVariantActivePromotionsByLatestUpdatedSchedule;
};

export const selectAllProductActivePromotions = (state: RootState) => {
  const productActivePromotions = selectAllProductPromotions(state).filter(
    (promotion) => promotion.active
  );

  const sortedProductActivePromotionsByLatestUpdatedSchedule = productActivePromotions
    .map((productPromotion) => {
      const promotion = selectActivePromotionByIdAdapterSelector(state, productPromotion.promotion);

      return {
        ...productPromotion,
        lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
      };
    })
    .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
    //  now remove the lastScheduleUpdatedAt property
    .map(({ lastScheduleUpdatedAt, ...rest }) => rest);

  return sortedProductActivePromotionsByLatestUpdatedSchedule;
};

const selectAllServiceVariantPromotionsByServiceVariantId =
  (id: string | number) =>
  (state: RootState): ServiceVariantActivePromotion[] => {
    return id
      ? selectAllServiceVariantPromotions(state)
          .filter((promotion) => promotion.serviceVariant === id || promotion.hasAllServices)
          .map((servicePromotion) => {
            const promotion = selectActivePromotionByIdAdapterSelector(
              state,
              servicePromotion.promotion
            );

            return {
              ...servicePromotion,
              name: promotion?.name || "",
              lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
            };
          })
          .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
      : [];
  };

const selectAllPackageVariantPromotionsByPackageVariantId =
  (id: string | number) =>
  (state: RootState): PackageVariantActivePromotion[] => {
    return id
      ? selectAllPackageVariantPromotions(state)
          .filter((promotion) => promotion.packageVariant === id || promotion.hasAllPackages)
          .map((packagePromotion) => {
            const promotion = selectActivePromotionByIdAdapterSelector(
              state,
              packagePromotion.promotion
            );

            return {
              ...packagePromotion,
              name: promotion?.name || "",
              lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
            };
          })
          .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
      : [];
  };

const selectAllProductPromotionsByProductId =
  (id: string | number) =>
  (state: RootState): ProductActivePromotion[] => {
    return id
      ? selectAllProductPromotions(state)
          .filter((promotion) => promotion.product === id || promotion.hasAllProducts)
          .map((productPromotion) => {
            const promotion = selectActivePromotionByIdAdapterSelector(
              state,
              productPromotion.promotion
            );

            return {
              ...productPromotion,
              name: promotion?.name || "",
              lastScheduleUpdatedAt: promotion?.lastScheduleUpdatedAt || DateTime.now().toISO(),
            };
          })
          .sort(callSortDescendingByKey("lastScheduleUpdatedAt"))
      : [];
  };

export const selectAllServiceVariantActivePromotionsByServiceVariantId =
  (id: string | number) =>
  (state: RootState): ServiceVariantActivePromotion[] => {
    const serviceVariantActivePromotions = selectAllServiceVariantPromotionsByServiceVariantId(id)(
      state
    ).filter((promotion) => promotion.active);

    return serviceVariantActivePromotions;
  };

const selectAllPackageVariantActivePromotionsByPackageVariantId =
  (id: string | number) =>
  (state: RootState): PackageVariantActivePromotion[] => {
    const packageVariantActivePromotions = selectAllPackageVariantPromotionsByPackageVariantId(id)(
      state
    ).filter((promotion) => promotion.active);

    return packageVariantActivePromotions;
  };

const selectAllProductActivePromotionsByProductId =
  (id: string | number) =>
  (state: RootState): ProductActivePromotion[] => {
    const productActivePromotions = selectAllProductPromotionsByProductId(id)(state).filter(
      (promotion) => promotion.active
    );

    return productActivePromotions;
  };

export const selectAllServiceVariantActivePromotionsByServiceVariantIdBasedOnDate =
  (id: string | number, date: string) => (state: RootState) =>
    checkIsDateInThePast(date)
      ? []
      : selectAllServiceVariantActivePromotionsByServiceVariantId(id)(state);

export const selectAllPackageVariantActivePromotionsByPackageVariantIdBasedOnDate =
  (id: string | number, date: string) => (state: RootState) =>
    checkIsDateInThePast(date)
      ? []
      : selectAllPackageVariantActivePromotionsByPackageVariantId(id)(state);

export const selectAllProductActivePromotionsByProductIdBasedOnDate =
  (id: string | number, date: string) => (state: RootState) =>
    checkIsDateInThePast(date) ? [] : selectAllProductActivePromotionsByProductId(id)(state);

export const selectAllServiceVariantActivePromotionsBasedOnDate =
  (date: string) => (state: RootState) =>
    checkIsDateInThePast(date) ? [] : selectAllServiceVariantActivePromotions(state);

export const selectAllPackageVariantActivePromotionsBasedOnDate =
  (date: string) => (state: RootState) =>
    checkIsDateInThePast(date) ? [] : selectAllPackageVariantActivePromotions(state);

export const selectAllProductActivePromotionsBasedOnDate = (date: string) => (state: RootState) =>
  checkIsDateInThePast(date) ? [] : selectAllProductActivePromotions(state);

export const selectLatestServiceVariantActivePromotionByServiceVariantId =
  (id: string | number) => (state: RootState) => {
    const allRelatedPromotions =
      selectAllServiceVariantActivePromotionsByServiceVariantId(id)(state);

    return allRelatedPromotions.length ? allRelatedPromotions[0] : null;
  };

export const selectLatestPackageVariantActivePromotionByPackageVariantId =
  (id: string | number) => (state: RootState) => {
    const allRelatedPromotions =
      selectAllPackageVariantActivePromotionsByPackageVariantId(id)(state);

    return allRelatedPromotions.length ? allRelatedPromotions[0] : null;
  };

export const selectLatestProductActivePromotionByProductId =
  (id: string | number) => (state: RootState) => {
    const allRelatedPromotions = selectAllProductActivePromotionsByProductId(id)(state);

    return allRelatedPromotions.length ? allRelatedPromotions[0] : null;
  };

/**
 * Selectors used to get specific promotion by id, for cases of no longer active promotions
 */

export const selectServiceVariantPromotionByServiceVariantIdAndPromotionId =
  (serviceVariantId: string | number, promotionId: string | number) => (state: RootState) => {
    const selectedPromotion = selectAllServiceVariantPromotionsByServiceVariantId(serviceVariantId)(
      state
    ).filter((promotion) => promotion.promotion === Number(promotionId));
    return selectedPromotion.length ? selectedPromotion[0] : null;
  };

export const selectPackageVariantPromotionByPackageVariantIdAndPromotionId =
  (packageVariantId: string | number, promotionId: string | number) => (state: RootState) => {
    const selectedPromotion = selectAllPackageVariantPromotionsByPackageVariantId(packageVariantId)(
      state
    ).filter((promotion) => promotion.promotion === Number(promotionId));
    return selectedPromotion.length ? selectedPromotion[0] : null;
  };

export const selectProductPromotionByProductIdAndPromotionId =
  (productId: string | number, promotionId: string | number) => (state: RootState) => {
    const selectedPromotion = selectAllProductPromotionsByProductId(productId)(state).filter(
      (promotion) => promotion.promotion === Number(promotionId)
    );
    return selectedPromotion.length ? selectedPromotion[0] : null;
  };
