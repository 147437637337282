import NotificationAlert from "components/NotificationAlert";
import { ReactNode, createContext, useState } from "react";

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type NotificationAlertSeverity = "success" | "warning" | "error";

export type NotificationProps = {
  message?: string;

  severity?: NotificationAlertSeverity;

  isAlertVisible: boolean;

  alertDuration: number;
};

type NotificationAlertState = {
  notificationProps: NotificationProps;
  setNotificationProps: (notificationProps: NotificationProps) => void;

  clearNotification: () => void;
};

const initialState: NotificationAlertState = {
  notificationProps: {
    isAlertVisible: false,
    alertDuration: 3000,
  },
  setNotificationProps: unimplementedFunction,
  clearNotification: unimplementedFunction,
};

const NotificationAlertContext = createContext<NotificationAlertState>(initialState);

function NotificationAlertContextProvider({ children }: { children: ReactNode }) {
  const [notificationProps, setNotificationProps] = useState(initialState.notificationProps);

  const clearNotification = () => setNotificationProps(initialState.notificationProps);

  return (
    <NotificationAlertContext.Provider
      value={{
        notificationProps,
        setNotificationProps,

        clearNotification,
      }}
    >
      <NotificationAlert />

      {children}
    </NotificationAlertContext.Provider>
  );
}

export { NotificationAlertContextProvider, NotificationAlertContext };
