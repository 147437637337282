import { Box, Grid, Typography } from "@mui/material";
import { checkIfArticleHasSpecialDiscount } from "helpers/articles";
import formatTime from "helpers/formatTime";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { DateTime } from "luxon";
import { AppointmentWithArticleDetails } from "types/Appointment";

import PriceSection from "./PriceSection";
import ServiceRow from "./ServiceRow";

type PackageItemProps = {
  article: AppointmentWithArticleDetails["articles"][0];
};

const PackageItem = ({ article }: PackageItemProps) => {
  const subscriptionRedemption = article.subscriptionRedemption;

  const description = `${article.slots.length} services, ${getFormattedDuration(
    article.totalDuration
  )}`;

  const getStartAndEndTime = () => {
    const minStartTime = DateTime.min(...article.slots.map((slot) => DateTime.fromISO(slot.start)));
    const maxEndTime = DateTime.max(...article.slots.map((slot) => DateTime.fromISO(slot.end)));

    return `${formatTime(minStartTime.toString())} - ${formatTime(maxEndTime)}`;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" minWidth="100%" alignItems="flex-start">
        <Box>
          <Typography variant="primary" fontWeight={500} whiteSpace="nowrap">
            {getStartAndEndTime()}
          </Typography>

          <Typography variant="h5" fontWeight={500} noWrap sx={{ py: 0.5 }}>
            {article?.packageVariant?.name}
          </Typography>

          <Typography variant="secondary" color="text.label" noWrap>
            {description}
          </Typography>
        </Box>

        <PriceSection
          originalPrice={
            checkIfArticleHasSpecialDiscount({
              markedPrice: article.markedPrice || 0,
              originalPrice: article.originalPrice,
              finalPrice: article.finalPrice,
            })
              ? Number(article.originalPrice)
              : Number(article.markedPrice)
          }
          finalPrice={Number(article.finalPrice)}
          hasSubscriptionRedemption={!!subscriptionRedemption}
        />
      </Box>

      <Grid
        item
        container
        md={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: "#F5F4F4",
          paddingLeft: 3,
          paddingRight: 3,
          marginTop: 2,
        }}
      >
        {article.slots.map((slot, idx) => {
          return <ServiceRow slot={slot} key={idx} />;
        })}
      </Grid>
    </>
  );
};

export default PackageItem;
