import type { CalendarView } from "pages/CalendarPage/CalendarPageContext";
import http from "utils/http";

import { EmptyObject } from "./../../types/Misc";

export type UserPreferenceSettings = {
  settings: {
    calendarPresets: [
      string,
      {
        calendarView: CalendarView;
        employees?: {
          [key: string | number]: number[];
        };
        resources?: { [key: string | number]: number[] };
        employeeIds: number[];
        resourceIds?: number[];
        outletId: number;
        zoneId?: number;
        color?: string;
        categoryId?: number;
      }
    ][];
  };
};

export type UserPreference = UserPreferenceSettings | EmptyObject;

const calendarPresetsApi = {
  getUserPreference: async () => http.get<UserPreference>(`preferences/preference/`),

  updateUserPreference: async (data: UserPreference) =>
    http.put<UserPreference>(`preferences/preference/`, data),
};

export default calendarPresetsApi;
