import Outlet from "./Outlet";

export enum DiscountOption {
  Percentage = "PERCENTAGE",
  Fixed = "FIXED",
}

export enum PromotionScheduleStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Scheduled = "SCHEDULED",
  Expired = "EXPIRED",
}

export enum GlobalPromotionDiscountOption {
  Service = "SERVICE",
  Package = "PACKAGE",
  Product = "PRODUCT",
}

export type PromotionSchedule = {
  id: number;
  startDate: string;
  endDate: string;
  status: PromotionScheduleStatus;
};

export type Promotion = {
  id: number;
  name: string;
  redeemingOutlets: number[];
  schedule: PromotionSchedule;
  usedCount?: number;
};

export type UnNormalizedPromotion = Omit<Promotion, "redeemingOutlets"> & {
  redeemingOutlets: Outlet[];
};

export type PromotionDetail = Promotion & {
  online: boolean;
  description: string;

  hasAllServices: boolean;
  hasAllPackages: boolean;
  hasAllProducts: boolean;

  // This specify the discount when promotion items has a custom discount for Purchasable items included in the promotion
  serviceVariants: ServiceVariantPromotion[];
  packageVariants: PackageVariantPromotion[];
  products: ProductPromotion[];

  // This specify the discount when promotion is applied to all items of a specific Purchasable items type
  globalPromotionDiscounts: GlobalPromotionDiscount[];
};

type PromotionDiscount = {
  discountValue: string;
  discountType: DiscountOption;
};

export type ServiceVariantPromotion = PromotionDiscount & {
  serviceVariant: number;
};

export type PackageVariantPromotion = PromotionDiscount & {
  packageVariant: number;
};

export type ProductPromotion = PromotionDiscount & {
  product: number;
};

export type GlobalPromotionDiscount = PromotionDiscount & {
  id?: number;
  type: GlobalPromotionDiscountOption;
};

export enum PromotionDiscountItemOption {
  ServiceVariants = "serviceVariants",
  PackageVariants = "packageVariants",
  Products = "products",
}
