import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import {
  createService,
  deleteService,
  updateService,
  updateServicePosition,
} from "store/services/servicesSlice";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./serviceCategoriesApi";

const serviceCategoryAdapter = createEntityAdapter();

const initialState = serviceCategoryAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: null,
  optimisticCache: null,
});

const { selectAll } = serviceCategoryAdapter.getSelectors((state) => state.serviceCategories);

export const getAllServiceCategories = createThunkFromApi(
  "serviceCategories/getAllServiceCategories",
  api.getAllServiceCategories
);

export const getServiceCategory = createThunkFromApi(
  "serviceCategories/getServiceCategory",
  api.getServiceCategory
);

export const updateServiceCategoryPosition = createThunkFromApi(
  "packageCategory/updateServiceCategoryPosition",
  api.updateServiceCategoryPosition
);

export const createServiceCategory = createThunkFromApi(
  "serviceCategories/createServiceCategory",
  api.createServiceCategory
);

export const updateServiceCategory = createThunkFromApi(
  "serviceCategories/updateServiceCategory",
  api.updateServiceCategory
);

export const deleteServiceCategory = createThunkFromApi(
  "serviceCategories/removeServiceCategory",
  api.removeServiceCategory
);

export const serviceCategoriesSlice = createSlice({
  name: "serviceCategories",
  initialState,

  extraReducers: {
    [getAllServiceCategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllServiceCategories.fulfilled]: (state, { payload }) => {
      state.error = "";
      serviceCategoryAdapter.setAll(state, payload);
      state.isLoading = false;
    },
    [getAllServiceCategories.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getServiceCategory.fulfilled]: (state, { payload }) => {
      serviceCategoryAdapter.setOne(state, payload);
    },
    [updateServiceCategoryPosition.pending]: (state, { meta: { arg } }) => {
      state.optimisticCache = selectAll({ serviceCategories: state });

      serviceCategoryAdapter.upsertMany(state, arg);
    },
    [updateServiceCategoryPosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updateServiceCategoryPosition.rejected]: (state) => {
      serviceCategoryAdapter.setAll(state, state.optimisticCache);
      state.optimisticCache = null;
    },

    [updateServicePosition.pending]: (state, { meta: { arg } }) => {
      const categoryId = arg.length ? arg[0].category : null;
      // state.optimisticCache = selectServiceCategoryById(categoryId)({
      //   serviceCategories: state,
      // });

      const modifiedServices = state.optimisticCache.services.map((service) => ({
        ...service,
        position: arg.find((servicePosition) => servicePosition.id === service.id).position,
      }));

      serviceCategoryAdapter.upsertOne(state, {
        id: categoryId,
        services: modifiedServices,
      });
    },
    [updateServicePosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updateServicePosition.rejected]: (state) => {
      serviceCategoryAdapter.setOne(state, state.optimisticCache);
      state.optimisticCache = null;
    },
    [createServiceCategory.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createServiceCategory.fulfilled]: (state, { payload }) => {
      serviceCategoryAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createServiceCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updateServiceCategory.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateServiceCategory.fulfilled]: (state, { payload }) => {
      serviceCategoryAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updateServiceCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [deleteServiceCategory.pending]: (state) => {
      state.isRequestPending = true;
    },

    [deleteServiceCategory.fulfilled]: (state, { meta: { arg } }) => {
      serviceCategoryAdapter.removeOne(state, arg);
      state.isRequestPending = false;
    },
    [deleteServiceCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [createService.fulfilled]: (state, { payload }) => {
      const categoryToAddService = serviceCategoryAdapter
        .getSelectors()
        .selectById(state, payload.category);

      const newCategoryServices = [...categoryToAddService.services, payload];

      serviceCategoryAdapter.updateOne(state, {
        id: payload.category,
        changes: { ...categoryToAddService, services: newCategoryServices },
      });
    },

    [updateService.fulfilled]: (state, { payload }) => {
      if (payload.category === payload.oldCategory || !payload.oldCategory) {
        const categoryToUpdate = serviceCategoryAdapter
          .getSelectors()
          .selectById(state, payload.category);

        serviceCategoryAdapter.updateOne(state, {
          id: payload.category,
          changes: {
            ...categoryToUpdate,
            services: categoryToUpdate.services.map((service) => {
              if (service.id === payload.id) return payload;
              return service;
            }),
          },
        });
      } else {
        // get old category
        const oldCategoryToUpdate = serviceCategoryAdapter
          .getSelectors()
          .selectById(state, payload.oldCategory);

        // Get newly selected category
        const newCategoryToUpdate = serviceCategoryAdapter
          .getSelectors()
          .selectById(state, payload.category);

        // Update old category
        serviceCategoryAdapter.updateOne(state, {
          id: payload.oldCategory,
          changes: {
            ...oldCategoryToUpdate,
            services: oldCategoryToUpdate.services.filter((service) => service.id !== payload.id),
          },
        });

        // Update newly selected category
        serviceCategoryAdapter.updateOne(state, {
          id: payload.category,
          changes: {
            ...newCategoryToUpdate,
            services: [...newCategoryToUpdate.services, payload],
          },
        });
      }
    },

    [deleteService.fulfilled]: (state, { payload: { id, categoryId } }) => {
      const categoryToRemoveService = serviceCategoryAdapter
        .getSelectors()
        .selectById(state, categoryId);

      const modifiedServices = categoryToRemoveService.services.filter(
        (service) => service.id !== id
      );

      serviceCategoryAdapter.updateOne(state, {
        id: categoryId,
        changes: { ...categoryToRemoveService, services: modifiedServices },
      });
    },
  },
});

export default serviceCategoriesSlice.reducer;
