import { InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import NumberField, { NumberFieldProps } from "./NumberField";

export type PriceFieldProps = Omit<NumberFieldProps, "maxDecimalPlaces">;
const PriceField = ({ ...priceFieldProps }: PriceFieldProps) => {
  const { t } = useTranslation("translation");

  return (
    <NumberField
      min={0}
      {...priceFieldProps}
      maxDecimalPlaces={3}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="24px"
              color="text.placeholder"
            >
              {t("currency")}
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PriceField;
