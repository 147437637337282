import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Area } from "types/Area";

import { SliceStatus } from "../utils";
import api from "./areasApi";

export const areasAdapter = createEntityAdapter<Area>({
  selectId: (area) => area.id,
});

const initialState = areasAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAreas = createThunkFromApiWithType("areas/getAreas", api.getAreas);

export const areasSlice = createSlice({
  name: "areas",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAreas.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAreas.fulfilled, (state, { payload }) => {
      areasAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAreas.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default areasSlice.reducer;
