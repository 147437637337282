import { Stack, Typography } from "@mui/material";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { DateTime } from "luxon";
import FlexServiceChip from "pages/CalendarPage/components/AppointmentForm/ServiceSection/FlexServiceChip";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectResourceById } from "store/selectors";
import { selectIndustryCategoryById } from "store/slices/industryCategories/industryCategoriesSelectors";
import { ClientAppointment } from "types/Client";

type AppointmentArticleProps = {
  article: ClientAppointment["articles"][0];
};

const AppointmentsHistoryArticle = ({ article }: AppointmentArticleProps) => {
  const { t } = useTranslation();

  const { packageVariant, serviceVariant, flexService, slots } = article;

  const industryCategory = useSelector(
    selectIndustryCategoryById(flexService?.industryCategory || 0)
  );

  const bookedSlot = slots[0];

  const resource = useSelector(selectResourceById(bookedSlot?.resource));

  if (!bookedSlot) return null;

  const { staff, start } = bookedSlot;

  const formattedStartTime = DateTime.fromISO(start)?.toFormat("h:mm a");

  const showVariantName = serviceVariant?.name !== serviceVariant?.service.name;

  const description = `${!!showVariantName ? serviceVariant?.name : ""} ${getFormattedDuration(
    flexService ? flexService.duration : serviceVariant?.duration || 0
  )}`;

  const staffName = staff?.user
    ? `${staff.user?.firstName} ${staff.user?.lastName}`
    : `${staff.profile?.firstName} ${staff.profile?.lastName}`;

  const resourceName = resource ? `, ${resource.name}` : "";

  const articleResourceDescription = `${staffName}${resourceName}, ${formattedStartTime}`;

  const isPackage = !!packageVariant;

  const title = isPackage
    ? packageVariant?.name
    : serviceVariant
    ? serviceVariant?.service.name
    : industryCategory?.name;

  const subtitle = isPackage
    ? `${article.slots.length} ${t("services")}, ${getFormattedDuration(article.totalDuration)} `
    : description;

  const shouldShowFlexServiceChip = !!flexService;

  return (
    <Stack gap={0.5}>
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
        <Typography variant="h5">{title}</Typography>

        {shouldShowFlexServiceChip && <FlexServiceChip />}
      </Stack>

      <Typography variant="secondary" color="text.label">
        {subtitle}
      </Typography>

      {!isPackage && (
        <Typography variant="secondary" color="text.label">
          {articleResourceDescription}
        </Typography>
      )}
    </Stack>
  );
};

export default AppointmentsHistoryArticle;
