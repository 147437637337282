import { RootState } from "store";

import { SliceStatus } from "../utils";
import { clientHairTypesAdapter } from "./clientHairTypesSlice";

export const selectClientHairTypesLoading = (state: RootState) =>
  state.clientHairTypes.status === SliceStatus.LOADING;

export const selectClientHairTypesState = (state: RootState) => state.clientHairTypes;

const { selectAll } = clientHairTypesAdapter.getSelectors(selectClientHairTypesState);

export const selectAllClientHairTypes = selectAll;
