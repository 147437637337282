import { DoubleChevronLeft, DoubleChevronRight } from "@bookpeep/ui";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type AppointmentDrawerExpansionButtonProps = {
  isExpanded: boolean;
  onClick: () => void;
};

const AppointmentDrawerExpansionButton = ({
  isExpanded,
  onClick,
}: AppointmentDrawerExpansionButtonProps) => {
  const { t } = useTranslation("appointments");

  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={isExpanded ? <DoubleChevronRight /> : <DoubleChevronLeft />}
      size="small"
      sx={{ minHeight: 0, textTransform: "capitalize", mt: 1 }}
    >
      {isExpanded ? t("appointments:hideClientProfile") : t("appointments:showClientProfile")}
    </Button>
  );
};

export default AppointmentDrawerExpansionButton;
