import { RootState } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";
import Outlet from "types/Outlet";
import { PeepPayLinkWithDetail } from "types/PeepPayLink";

import { SliceStatus } from "../utils";
import { peepPayLinksAdapter } from "./peepPayLinksSlice";

export const selectPeepPayLinksState = (state: RootState) => state.peepPayLinks;

export const selectArePeepPayLinksLoading = (state: RootState) =>
  selectPeepPayLinksState(state).status === SliceStatus.LOADING;

export const selectPeepPayLinksTotalPages = (state: RootState) =>
  selectPeepPayLinksState(state).totalPages;

export const selectPeepPayLinksCount = (state: RootState) => selectPeepPayLinksState(state).count;

const { selectAll, selectById } = peepPayLinksAdapter.getSelectors(selectPeepPayLinksState);

export const selectAllPeepPayLinks = selectAll;

export const selectPeepPayLinkById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectPeepPayLinksWithDetails = (state: RootState): PeepPayLinkWithDetail[] => {
  const peepPayLinks = selectAll(state);

  return peepPayLinks.map((peepPayLink) => {
    const outlet = selectOutletById(peepPayLink.outlet)(state) as Outlet;

    return {
      ...peepPayLink,
      outlet,
    };
  });
};
