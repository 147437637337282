import { Stack, TextField, Typography } from "@mui/material";
import { WarningIcon } from "assets/icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type AllergiesFieldProps = {
  isDisabled?: boolean;
  value: string;
  handleChange: (newValue: string) => void;
};

const AllergiesField = ({ value, isDisabled = false, handleChange }: AllergiesFieldProps) => {
  const { t } = useTranslation("client");

  const [allergies, setAllergies] = useState(value);

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAllergies(event.target.value);

  const handleSubmit = () => handleChange(allergies);

  return (
    <Stack key="allergies-field" borderRadius={1} bgcolor="#FEE1DD" p={3}>
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <WarningIcon
          sx={{
            height: 16,
            width: 16,
            color: "#F43319",
          }}
        />

        <Typography variant="primary" color="text.label" fontWeight={500}>
          {t("allergies")}
        </Typography>
      </Stack>

      <TextField
        key="allergies"
        value={allergies}
        onChange={handleChangeText}
        onBlur={handleSubmit}
        placeholder={t("enterAllergies")}
        fullWidth
        multiline
        rows={2}
        inputProps={{
          maxLength: "3000000",
        }}
        disabled={isDisabled}
      />
    </Stack>
  );
};

export default AllergiesField;
