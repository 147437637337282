import debounce from "@mui/utils/debounce";
import { formatDate, timePeriodOptions } from "components/Sales/utils";
import useIsMobileView from "hooks/useIsMobileView";
import { DateTime } from "luxon";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "store";
import { PeepPayLinkType } from "store/slices/peepPayLinks/peepPayLinksApi";
import {
  appendAllPeepPayLinks,
  getAllPeepPayLinks,
} from "store/slices/peepPayLinks/peepPayLinksSlice";
import { PeepPayLinkStatus } from "types/Deposit";

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialisation");
};

type InitialStateType = {
  dateRange: string | DateTime[];
  setDateRange: Dispatch<SetStateAction<string | DateTime[]>>;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;

  outletId: number;
  setOutletId: Dispatch<SetStateAction<number>>;

  linkStatuses: PeepPayLinkStatus[];
  setLinkStatuses: Dispatch<SetStateAction<PeepPayLinkStatus[]>>;

  type: PeepPayLinkType;
  setType: Dispatch<SetStateAction<PeepPayLinkType>>;

  ordering: string[];
  setOrdering: Dispatch<SetStateAction<string[]>>;

  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  handleInputChange: (value: string) => void;

  handleLoadMore: (value: number) => void;

  handlePageChange: (value: number) => void;

  clearAllFilters: () => void;
};

const initialState = {
  dateRange: timePeriodOptions[3][1],
  setDateRange: unimplementedFunction,

  page: 1,
  setPage: unimplementedFunction,

  outletId: 0,
  setOutletId: unimplementedFunction,

  linkStatuses: [],
  setLinkStatuses: unimplementedFunction,

  type: PeepPayLinkType.Invoice,
  setType: unimplementedFunction,

  ordering: [],
  setOrdering: unimplementedFunction,

  searchQuery: "",
  setSearchQuery: unimplementedFunction,

  searchQueryAsParam: "",
  setSearchQueryAsParam: unimplementedFunction,

  handleInputChange: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  handlePageChange: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const PeepPayLinksContext = createContext<InitialStateType>(initialState);

export default PeepPayLinksContext;

type PeepPayLinksContextProviderProps = {
  children: ReactNode;
};

function PeepPayLinksContextProvider({ children }: PeepPayLinksContextProviderProps) {
  const dispatch = useDispatch();

  const isMobileView = useIsMobileView();

  const [dateRange, setDateRange] = useState(initialState.dateRange);

  const [page, setPage] = useState<number>(initialState.page);

  const [outletId, setOutletId] = useState<number>(initialState.outletId);

  const [linkStatuses, setLinkStatuses] = useState<PeepPayLinkStatus[]>(initialState.linkStatuses);

  const [type, setType] = useState<PeepPayLinkType>(initialState.type);

  const [ordering, setOrdering] = useState<string[]>(initialState.ordering);

  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  // the query that will be sent to the backend request after debouncing
  const [searchQueryAsParam, setSearchQueryAsParam] = useState<string>(
    initialState.searchQueryAsParam
  );

  const triggerDebouncedFetch = useMemo(
    () =>
      debounce((searchQuery) => {
        setSearchQueryAsParam(searchQuery);
        setPage(1);
      }, 800),
    []
  );

  const fetchExpenses = useCallback(triggerDebouncedFetch, [triggerDebouncedFetch]);

  const handleInputChange = (newValue: string) => {
    setSearchQuery(newValue);
    fetchExpenses(newValue);
  };

  const handleLoadMore = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
  };

  useEffect(() => {
    const startDate = formatDate(dateRange[0]) as string;
    const endDate = formatDate(dateRange[1]) as string;

    if (isMobileView && page !== 1) {
      dispatch(
        appendAllPeepPayLinks({
          page,
          startDate,
          endDate,
          outletId,
          linkStatuses,
          type,
          ordering,
          searchQuery: searchQueryAsParam,
        })
      );
    } else {
      dispatch(
        getAllPeepPayLinks({
          page,
          startDate,
          endDate,
          outletId,
          linkStatuses,
          type,
          ordering,
          searchQuery: searchQueryAsParam,
        })
      );
    }
  }, [
    isMobileView,
    dateRange,
    dispatch,
    page,
    outletId,
    linkStatuses,
    ordering,
    searchQueryAsParam,
    type,
  ]);

  return (
    <PeepPayLinksContext.Provider
      value={{
        dateRange,
        setDateRange,

        page,
        setPage,

        outletId,
        setOutletId,

        linkStatuses,
        setLinkStatuses,

        type,
        setType,

        ordering,
        setOrdering,

        searchQuery,
        setSearchQuery,

        handleInputChange,

        handleLoadMore,

        handlePageChange,

        clearAllFilters,
      }}
    >
      {children}
    </PeepPayLinksContext.Provider>
  );
}

export { PeepPayLinksContextProvider };
