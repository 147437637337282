import { useTranslation } from "react-i18next";

import StatusChip from "./StatusChip";

export enum AreaChipStatus {
  Unsupported = "unsupported",
  AnotherZone = "anotherZone",
}
type AreaStatusChipProps = {
  status: AreaChipStatus | `${AreaChipStatus}`; //To allow both using string literals and enum values
};

const AreaStatusChip = ({ status }: AreaStatusChipProps) => {
  const { t } = useTranslation("appointments");

  if (status === AreaChipStatus.Unsupported) {
    return (
      <StatusChip
        label={t("unsupported")}
        statusColor="#FEE1DD"
        size="small"
        sx={{
          "& .MuiChip-label": {
            color: "#DB230A !important",
            fontWeight: 700,
          },
        }}
      />
    );
  }
  if (status === AreaChipStatus.AnotherZone) {
    return (
      <StatusChip
        label={t("anotherZone")}
        statusColor="#FFEED1"
        size="small"
        sx={{
          "& .MuiChip-label": {
            color: "#FF9800 !important",
            fontWeight: 700,
          },
        }}
      />
    );
  }

  return null;
};

export default AreaStatusChip;
