import { IconButton, styled } from "@mui/material";
import { DragAndDropIcon } from "assets/icon";
import { dontForwardProps } from "helpers/styles";

const StyledDragButton = styled(
  IconButton,
  dontForwardProps("noBg")
)(({ theme, noBg }) => ({
  margin: 0,
  marginRight: theme.spacing(2),
  padding: 0,
  width: theme.spacing(3),
  borderRadius: 2,
  cursor: "grab",
  backgroundColor: noBg ? "transparent" : theme.palette.bg.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    borderRadius: 2,
  },
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(1),
    width: noBg ? theme.spacing(2) : theme.spacing(3),
  },
}));

const StyledDragIcon = styled(DragAndDropIcon)(({ theme }) => ({
  fontSize: theme.spacing(9),
  width: "100%",
  maxWidth: "8px",
}));

const PeepDragButton = ({ color, noBg = false, ...props }) => {
  return (
    <StyledDragButton noBg={noBg} disableTouchRipple size="large" {...props}>
      <StyledDragIcon fill="none" viewBox="0 0 8 10" color={color} />
    </StyledDragButton>
  );
};

export default PeepDragButton;
