/**
 * This function checks if an article has a special discount for instance: service app price
 * @param article
 * @returns
 */
type ArticlePrice = {
  markedPrice: number | string;
  finalPrice: number | string;
  originalPrice: number | string;
};

export const checkIfArticleHasSpecialDiscount = ({
  markedPrice,
  finalPrice,
  originalPrice,
}: ArticlePrice): boolean => {
  const hasDiscount =
    Number(markedPrice) === Number(finalPrice) && Number(finalPrice) < Number(originalPrice);

  return hasDiscount;
};

/**
 * This function gets the special discount value of an article as a number so that we display it as money amount eg: 10.00 KD
 * @param article
 * @returns
 */

export const getArticleSpecialDiscountValue = ({
  finalPrice,
  originalPrice,
}: Omit<ArticlePrice, "markedPrice">): number => {
  const discountValue = Number(originalPrice) - Number(finalPrice);

  return Number(discountValue.toFixed(2));
};

/**
 * This function gets the special discount percentage of an article, so that we display it as discount percentage 10.00 %
 * @param article
 * @returns
 */

export const getArticleSpecialDiscountPercentage = ({
  finalPrice,
  originalPrice,
}: Omit<ArticlePrice, "markedPrice">): number => {
  const discountPercentage =
    (getArticleSpecialDiscountValue({
      finalPrice,
      originalPrice,
    }) /
      Number(originalPrice)) *
    100;

  return Number(discountPercentage.toFixed(2));
};
