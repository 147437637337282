import { AddPlus } from "@bookpeep/ui";
import {
  Autocomplete,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuItemProps,
  Paper,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { styled } from "@mui/system";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GenericDispatch, GenericThunk } from "store";
import {
  getClient,
  getClientSearchResults,
  selectAllClients,
  selectAllClientsAreLoading,
} from "store/clients/clientsSlice";

import type { SetExistingClientId } from "./ClientSection";

type ClientsSearchProps = {
  activateNewClientForm: (searchQuery?: string) => void;
  setSelectedClientId: SetExistingClientId;
  error?: boolean;
  helperText?: string;
  disablePortalForAutocomplete?: boolean;
};

type ClientOption = {
  id: number;
  fullName: string;
  phoneNumber: string;
  phoneCountryCode: string;

  nationalId: Nullable<string>;
  fileNumber: Nullable<string>;
};

const ClientsSearch = ({
  activateNewClientForm,
  setSelectedClientId,
  error = false,
  helperText = "",
  disablePortalForAutocomplete = true,
}: ClientsSearchProps) => {
  const { t } = useTranslation("appointments");

  const dispatch = useDispatch<GenericDispatch>();

  const isLoading = useSelector(selectAllClientsAreLoading);

  const clients = useSelector(selectAllClients);

  const [isAutoCompleteDropDownOpen, setIsAutoCompleteDropDownOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState<Nullable<ClientOption>>(null);

  const [inputValue, setInputValue] = useState("");

  const debouncedFetch = useMemo(
    () =>
      debounce((query) => {
        !!query && dispatch((getClientSearchResults as GenericThunk)({ query, resultCount: 50 }));
      }, 500),
    [dispatch]
  );

  const fetchEvents = useCallback(debouncedFetch, [debouncedFetch]);

  const handleChange = (_event: SyntheticEvent, value: ClientOption | null) => {
    // the selected value from the list of options
    setSelectedOption(value);

    const clientId = value?.id || null;

    // fetch the client detail to get client info
    if (clientId) dispatch((getClient as GenericThunk)(clientId));

    setSelectedClientId(clientId);
  };

  const handleInputChange = (_event: SyntheticEvent, newValue: string) => {
    fetchEvents(newValue);
    setInputValue(newValue);
  };

  const isOptionEqualToValue = (option: ClientOption, value: ClientOption) => {
    return option.id === value.id;
  };

  const getFilteredOptions = (options: ClientOption[]) => {
    const isInputValueNumber = Number(inputValue);

    const nameResults = isInputValueNumber
      ? []
      : clients.filter((client) => {
          const fullName = `${client.firstName} ${client.lastName}`;

          return fullName.toLowerCase().includes(inputValue.toLowerCase().trim());
        });

    const digitsInTheInput = inputValue.replace(/^\D+/g, "");

    const nationalIdResults = clients.filter(
      (client) => client.nationalId && client.nationalId.includes(inputValue)
    );

    const fileNumberResults = clients.filter(
      (client) => client.fileNumber && client.fileNumber.includes(inputValue)
    );

    // we need to get the digits only of the inputValue
    const filteredOptionsByPhoneNumber = digitsInTheInput
      ? options.filter((client) =>
          client.phoneNumber
            .replace(client.phoneCountryCode, "")
            .startsWith(digitsInTheInput.trim())
        )
      : [];

    // we need to return the filtered options based on the results of search
    const filteredOptionsByName = options.filter((option: ClientOption) =>
      nameResults.some((result) => result.id === option.id)
    );

    const filteredOptionsByNationalId = options.filter((option: ClientOption) =>
      nationalIdResults.some((result) => result.id === option.id)
    );

    const filteredOptionsByFileNumber = options.filter((option: ClientOption) =>
      fileNumberResults.some((result) => result.id === option.id)
    );

    const getFilteredOptionsBasedOnInput = () => {
      const filteredClientResultsSet = new Set([
        ...filteredOptionsByName,
        ...filteredOptionsByPhoneNumber,
        ...filteredOptionsByNationalId,
        ...filteredOptionsByFileNumber,
      ]);

      const filteredClientResults = [...filteredClientResultsSet];

      return filteredClientResults;
    };

    return inputValue ? getFilteredOptionsBasedOnInput().slice(0, 30) : options.slice(0, 30);
  };

  const getOptionLabel = (option: ClientOption) => option.fullName;

  const renderOption = (props: MenuItemProps, client: ClientOption) => {
    return (
      <MenuItem
        {...props}
        key={client.id}
        sx={{ flexDirection: "column", alignItems: "start !important" }}
        onTouchStart={(event) => handleChange(event, client)}
      >
        <Typography variant="primary" fontWeight={500}>
          {client.fullName}
        </Typography>

        <Typography variant="secondary" color="text.label">
          {client.phoneNumber}
        </Typography>
      </MenuItem>
    );
  };

  const onClear = () => {
    setSelectedClientId(null);

    setSelectedOption(null);
    setInputValue("");
  };

  return (
    <ClickAwayListener onClickAway={() => setIsAutoCompleteDropDownOpen(false)}>
      <Autocomplete
        PaperComponent={({ children, ...props }) => (
          <Paper {...props} onMouseDown={(event) => event.preventDefault()}>
            {children}
            <AddClientButton
              variant="text"
              color="primary"
              fullWidth
              startIcon={<AddPlus />}
              onClick={() => activateNewClientForm(inputValue)}
              onTouchStart={() => activateNewClientForm(inputValue)}
            >
              {t("addNewClient")}
            </AddClientButton>
          </Paper>
        )}
        loading={isLoading}
        loadingText={t("loading")}
        open={isAutoCompleteDropDownOpen}
        onOpen={() => setIsAutoCompleteDropDownOpen(true)}
        options={clients.map((client) => ({
          id: client.id,
          fullName: `${client.firstName} ${client.lastName}`,
          phoneNumber: client.phoneNumber,
          phoneCountryCode: client.phoneCountryCode,
          nationalId: client.nationalId,
          fileNumber: client.fileNumber,
        }))}
        disablePortal={disablePortalForAutocomplete}
        value={selectedOption}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        noOptionsText={t("noMatchingResults")}
        filterOptions={getFilteredOptions}
        renderOption={renderOption}
        getOptionLabel={getOptionLabel}
        defaultValue={null}
        onChange={(event, value) => handleChange(event, value)}
        isOptionEqualToValue={isOptionEqualToValue}
        onClose={() => setIsAutoCompleteDropDownOpen(false)}
        renderInput={(params) => (
          <TextField
            {...params}
            ref={params.InputProps.ref}
            label={t("client")}
            autoFocus
            fullWidth
            placeholder={t("searchClientsPlaceholder")}
            value={inputValue}
            onClick={onClear}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </ClickAwayListener>
  );
};

export default ClientsSearch;

const AddClientButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.bg.secondary,
}));
