import { Box, Button, ButtonProps, Typography, styled } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Download, EmailIcon, GiftIcon } from "assets/icon";
import { processText } from "helpers/formatArabicDigits";
import EmailGiftVoucherModal from "pages/Offers/Vouchers/EmailGiftVoucherModal";
import GiftVoucherPDF from "pages/Offers/Vouchers/GiftVoucherPDF/GiftVoucherPDF";
import VouchersGiftModal from "pages/Offers/Vouchers/VouchersGiftModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectVouchersLoading } from "store/selectors";
import { Invoice } from "types/Checkout";
import { VoucherPurchaseInvoice } from "types/VoucherPurchase";

import InvoiceVoucherCard from "./InvoiceVoucherCard";
import InvoiceVouchersScrollArrows from "./InvoiceVouchersScrollArrows";

type InvoicePurchasedVouchersSectionProps = {
  voucherPurchases: VoucherPurchaseInvoice[];
  client: Invoice["client"];
};

const InvoicePurchasedVouchersSection = ({
  voucherPurchases,
  client,
}: InvoicePurchasedVouchersSectionProps) => {
  const { t } = useTranslation("invoices");

  const { name: orgName, avatar: orgAvatar } = useSelector(selectOrganization) as Organization;

  const isEmailVoucherRequestPending = useSelector(selectVouchersLoading);

  const [selectedVoucherPurchaseIndex, setSelectedVoucherPurchaseIndex] = useState(0);
  const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const shouldShowScrollArrows = voucherPurchases.length > 1;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={3}
        p={3}
        order={{ xs: 2, md: 1 }}
      >
        <InvoiceVoucherCard voucherPurchase={voucherPurchases[selectedVoucherPurchaseIndex]} />

        {voucherPurchases[selectedVoucherPurchaseIndex].redeemableBy.id === client ? (
          <VoucherActionButton fullWidth onClick={() => setIsGiftModalOpen(true)}>
            <Typography
              color="primary"
              variant="h4"
              fontWeight={700}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <GiftIcon />
              {t("gift")}
            </Typography>
          </VoucherActionButton>
        ) : (
          <Box display="flex" width="100%" gap={2}>
            <VoucherActionButton
              fullWidth
              onClick={() => setIsEmailModalOpen(true)}
              disabled={isEmailVoucherRequestPending}
            >
              <Typography
                color="primary"
                variant="h4"
                fontWeight={700}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <EmailIcon />
                {t("email")}
              </Typography>
            </VoucherActionButton>

            <PDFDownloadLink
              style={{ textDecoration: "none", width: "100%" }}
              document={
                <GiftVoucherPDF
                  organizationData={{ name: orgName, avatar: orgAvatar || "" }}
                  voucherPurchase={voucherPurchases[selectedVoucherPurchaseIndex]}
                  voucherName={processText(voucherPurchases[selectedVoucherPurchaseIndex].name)}
                />
              }
              fileName={`${orgName}-${voucherPurchases[selectedVoucherPurchaseIndex].name}`}
            >
              {/* @ts-expect-error i don't know to to fix this hell 👹 */}
              {({ loading }) => (
                <VoucherActionButton fullWidth disabled={loading} onClick={() => {}}>
                  <Typography
                    color="primary"
                    variant="h4"
                    fontWeight={700}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Download />
                    {t("download")}
                  </Typography>
                </VoucherActionButton>
              )}
            </PDFDownloadLink>
          </Box>
        )}

        {shouldShowScrollArrows && (
          <InvoiceVouchersScrollArrows
            shouldDisableLeftArrow={selectedVoucherPurchaseIndex === 0}
            shouldDisableRightArrow={selectedVoucherPurchaseIndex === voucherPurchases.length - 1}
            currentVoucherPurchaseIndex={selectedVoucherPurchaseIndex}
            setCurrentVoucherPurchaseIndex={setSelectedVoucherPurchaseIndex}
            voucherPurchasesLength={voucherPurchases.length}
          />
        )}
      </Box>

      {isGiftModalOpen && (
        <VouchersGiftModal
          voucherPurchaseId={voucherPurchases[selectedVoucherPurchaseIndex].id}
          isGiftModalOpen={isGiftModalOpen}
          setIsGiftModalOpen={setIsGiftModalOpen}
        />
      )}

      {isEmailModalOpen && (
        <EmailGiftVoucherModal
          giftVoucherPurchase={voucherPurchases[selectedVoucherPurchaseIndex].gift}
          isEmailModalOpen={isEmailModalOpen}
          setIsEmailModalOpen={setIsEmailModalOpen}
        />
      )}
    </>
  );
};

export default InvoicePurchasedVouchersSection;

const VoucherActionButton = styled((props: ButtonProps) => (
  <Button variant="text" disableRipple {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.bg.secondary,
  padding: theme.spacing(1),

  "&:hover": {
    backgroundColor: theme.palette.bg.secondary,
  },
}));
