import { Box, Chip, Typography } from "@mui/material";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";

type StartsFromChipProps = {
  price: number;
};

export default function StartsFromChip({ price }: StartsFromChipProps) {
  const { t } = useTranslation("appointments");

  return (
    <Chip
      label={
        <Box display="flex">
          <Typography variant="secondary">
            {t("startsFrom")}{" "}
            <Typography component="span" variant="secondary" fontWeight="bold">
              {t("currency")} {getFormattedPrice(price)}
            </Typography>
          </Typography>
        </Box>
      }
    />
  );
}
