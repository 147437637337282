import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SubscriptionPurchase } from "types/SubscriptionPurchase";

type RemainingSubscriptionBoxProps = {
  totalCreditsCount: SubscriptionPurchase["totalCreditsCount"];
  remainingRedemptionsCount: SubscriptionPurchase["remainingRedemptionsCount"];
};

const RemainingSubscriptionBox = ({
  totalCreditsCount,
  remainingRedemptionsCount,
}: RemainingSubscriptionBoxProps) => {
  const { t } = useTranslation("subscriptions");

  return (
    <Typography variant="primary" color="text.label" fontWeight="500">
      {`${remainingRedemptionsCount} ${t("of")} ${totalCreditsCount}`}
    </Typography>
  );
};

export default RemainingSubscriptionBox;
