import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ClientAnalytic } from "types/ClientAnalytic";

import { SliceStatus } from "../utils";
import api from "./clientAnalyticsApi";

export const clientAnalyticsAdapter = createEntityAdapter<ClientAnalytic>({
  selectId: (client) => client.id,
});

const initialState = clientAnalyticsAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  count: number;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  count: 0,
});

export const getAllClientAnalytics = createThunkFromApiWithType(
  "clientAnalytics/getAllClientAnalytics",
  api.getClientAnalytics
);

export const appendAllClientAnalytics = createThunkFromApiWithType(
  "clientAnalytics/appendAllClientAnalytics",
  api.getClientAnalytics
);

export const clientAnalyticsSlice = createSlice({
  name: "clientAnalytics",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload ? SliceStatus.LOADING : SliceStatus.IDLE;
    },
  },
  extraReducers: (reducers) => {
    reducers.addCase(getAllClientAnalytics.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllClientAnalytics.fulfilled, (state, { payload }) => {
      clientAnalyticsAdapter.setAll(state, payload.results);
      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllClientAnalytics.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllClientAnalytics.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllClientAnalytics.fulfilled, (state, { payload }) => {
      clientAnalyticsAdapter.upsertMany(state, payload.results);
      state.count = payload.count;
      state.totalPages = payload.totalPages;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendAllClientAnalytics.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export const { setLoading } = clientAnalyticsSlice.actions;

export default clientAnalyticsSlice.reducer;
