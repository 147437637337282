import { useTranslation } from "react-i18next";

import InvoiceRowItem, { InvoiceItemProps } from "./InvoiceRowItem";

type InvoiceHeaderRowItemProps = Omit<InvoiceItemProps, "title" | "divideIntoTwEqualParts"> & {
  titleTranslation: string;
};

const InvoiceHeaderRowItem = ({
  titleTranslation,
  ...invoiceItemProps
}: InvoiceHeaderRowItemProps) => {
  const { t } = useTranslation("invoices");

  return (
    <InvoiceRowItem
      title={titleTranslation}
      value={invoiceItemProps.value || t("n/a")}
      divideIntoTwEqualParts
      {...invoiceItemProps}
    />
  );
};

export default InvoiceHeaderRowItem;
