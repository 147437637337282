import getIsStaffAssignedToTheAppointment from "helpers/getIsStaffAssignedToTheAppointment";
import getIsTheOnlyStaffAssignedToTheAppointment from "helpers/getIsTheOnlyStaffAssignedToTheAppointment";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { AppointmentStatusOptions } from "types/Appointment";
import auth from "utils/auth";

type UseAppointmentEditAccessProps = {
  appointmentId: number | undefined;
};

const useAppointmentEditAccess = ({ appointmentId }: UseAppointmentEditAccessProps) => {
  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));

  const { hasPersonalAppointment, hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  if (!appointment)
    return {
      canEditAppointmentStatus: false,
      canEditAppointment: false,
      isAppointmentEditable: false,
    };

  const isAppointmentEditable =
    appointment.status !== AppointmentStatusOptions.Completed &&
    appointment.status !== AppointmentStatusOptions.Canceled &&
    appointment.status !== AppointmentStatusOptions.NoShow;

  // if the user has hasPersonalAppointment.editAccess permission we need to check if he can edit status of this appointment, he can edit the status only if he is the only staff working on it
  const isTheOnlyStaffAssignedToTheAppointment = getIsTheOnlyStaffAssignedToTheAppointment({
    // @ts-expect-error
    articles: appointment?.articles,
    authenticatedUserEmployeeId,
  });

  const canEditAppointmentStatus =
    isAppointmentEditable &&
    (hasNonPersonalAppointment.editAccess ||
      (isTheOnlyStaffAssignedToTheAppointment && hasPersonalAppointment.editAccess));

  const isAssignedToTheAppointment = getIsStaffAssignedToTheAppointment({
    articles: appointment?.articles,
    authenticatedUserEmployeeId,
  });

  const canEditAppointment =
    isAppointmentEditable &&
    (hasNonPersonalAppointment.editAccess ||
      (isAssignedToTheAppointment && hasPersonalAppointment.editAccess));

  return {
    canEditAppointmentStatus,
    canEditAppointment,
    isAppointmentEditable,
  };
};

export default useAppointmentEditAccess;
