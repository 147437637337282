import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { DiscountCode } from "types/DiscountCode";

import { SliceStatus } from "../utils";
import api from "./discountCodesApi";

export const discountCodesAdapter = createEntityAdapter<DiscountCode>({
  selectId: (discountCode) => discountCode.id,
});

const initialState = discountCodesAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  count: number;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  count: 0,
});

export const getDiscountCodes = createThunkFromApiWithType(
  "discountCodes/getDiscountCodes",
  api.getDiscountCodes
);

export const appendDiscountCodes = createThunkFromApiWithType(
  "discountCodes/appendDiscountCodes",
  api.getDiscountCodes
);

export const createDiscountCode = createThunkFromApiWithType(
  "zones/createDiscountCode",
  api.createDiscountCode
);

export const updateDiscountCode = createThunkFromApiWithType(
  "zones/updateDiscountCode",
  api.updateDiscountCode
);

export const deprecateDiscountCode = createThunkFromApiWithType(
  "zones/deprecateDiscountCode",
  api.deprecateDiscountCode
);

export const discountCodesSlice = createSlice({
  name: "discountCodes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload ? SliceStatus.LOADING : SliceStatus.IDLE;
    },
  },
  extraReducers: (reducers) => {
    reducers.addCase(getDiscountCodes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getDiscountCodes.fulfilled, (state, { payload }) => {
      discountCodesAdapter.setAll(state, payload.results);
      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getDiscountCodes.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(appendDiscountCodes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendDiscountCodes.fulfilled, (state, { payload }) => {
      discountCodesAdapter.upsertMany(state, payload.results);
      state.count = payload.count;
      state.totalPages = payload.totalPages;
      state.status = SliceStatus.IDLE;
    });

    reducers
      .addCase(appendDiscountCodes.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(createDiscountCode.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createDiscountCode.fulfilled, (state, { payload }) => {
        discountCodesAdapter.upsertOne(state, {
          ...payload,
        });
        state.status = SliceStatus.IDLE;
      })
      .addCase(createDiscountCode.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(updateDiscountCode.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(updateDiscountCode.fulfilled, (state, { payload }) => {
        discountCodesAdapter.upsertOne(state, {
          ...payload,
        });
        state.status = SliceStatus.IDLE;
      })
      .addCase(updateDiscountCode.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(deprecateDiscountCode.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(
        deprecateDiscountCode.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => {
          discountCodesAdapter.removeOne(state, id);
          state.status = SliceStatus.IDLE;
        }
      )

      .addCase(deprecateDiscountCode.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      });
  },
});

export const { setLoading } = discountCodesSlice.actions;

export default discountCodesSlice.reducer;
