import { User } from "@bookpeep/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, TextField, Typography } from "@mui/material";
import {
  getCountryCodeFromCountryPhonePrefix,
  getCountryPhonePrefixFromCountryCode,
} from "assets/constants/countries";
import PhoneField from "components/common/PhoneField";
import useFormValidation from "hooks/useFormValidation";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientById } from "store/clients/clientsSlice";
import { InferType } from "yup";

import ChangeClientButton from "./ChangeClientButton";
import { clientFormSchema } from "./clientFormSchema";
import type { EditClientInfo, NewClient } from "./ClientSection";

type AddNewClientFormProps = {
  setUpdatedClientData?: (newClient: Nullable<EditClientInfo>) => void;
  clientId?: number;

  handleChangeClient: () => void;

  newClient: Nullable<NewClient>;
  setNewClient: (newClient: Nullable<NewClient>) => void;

  shouldTriggerValidation?: boolean;

  addNewClientSectionHeader?: React.ReactNode;
};

type ClientSchema = InferType<typeof clientFormSchema>;

const AddNewClientForm = ({
  clientId,
  handleChangeClient,

  newClient,
  setNewClient,

  setUpdatedClientData,
  shouldTriggerValidation = false,

  addNewClientSectionHeader,
}: AddNewClientFormProps) => {
  const { t } = useTranslation(["authentication", "appointments"]);

  const existingClient = useSelector(selectClientById(clientId));

  const defaultValues = useMemo(() => {
    if (existingClient) {
      const { firstName, lastName, phoneNumber, phoneCountryCode } = existingClient;
      const countryCodeFromPrefix =
        `${getCountryCodeFromCountryPhonePrefix(phoneCountryCode)}` || "KW";

      return {
        firstName: firstName || "",
        lastName: lastName || "",
        phoneNumber: phoneNumber.replace(phoneCountryCode, "") || "",
        phoneCountryCode: countryCodeFromPrefix,
      };
    } else if (newClient) return newClient;

    return {
      phoneCountryCode: "KW",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    };
  }, [existingClient, newClient]);

  const { register, watch, formState, setValue, trigger } = useForm({
    defaultValues,
    resolver: yupResolver(clientFormSchema),
  });

  useEffect(() => {
    if (shouldTriggerValidation) {
      trigger();
    }
  }, [trigger, shouldTriggerValidation]);

  const registerValidation = useFormValidation(formState, { ns: "authentication" });

  const countryCode = watch("phoneCountryCode") || "KW";

  const setCountryCode = (code: string) => setValue("phoneCountryCode", code);

  const handleOnBlur = (name: keyof ClientSchema) => {
    trigger(name);
    const { phoneNumber, phoneCountryCode, firstName, lastName } = watch();

    const countryCodeFromPrefix =
      `+${getCountryPhonePrefixFromCountryCode(phoneCountryCode)}` || "+965";

    if (clientId && setUpdatedClientData) {
      setUpdatedClientData({
        id: clientId,
        phoneNumber: `${countryCodeFromPrefix}${phoneNumber}`,
        phoneCountryCode: countryCodeFromPrefix,
        firstName,
        lastName,
      });
    } else {
      setNewClient({
        phoneNumber: `${countryCodeFromPrefix}${phoneNumber}`,
        phoneCountryCode: countryCodeFromPrefix,
        firstName,
        lastName,
      });
    }
  };

  return (
    <form>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
          {addNewClientSectionHeader || (
            <Box display="flex" alignItems="center">
              <User sx={{ mr: 1 }} />

              <Typography variant="h2">{t("appointments:createNewClient")}</Typography>
            </Box>
          )}

          <ChangeClientButton handleChangeClient={handleChangeClient} />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PhoneField
              fullWidth
              label={t("authentication:mobile")}
              placeholder="9999 9999"
              CountryCodeAutocompleteProps={{ value: countryCode, onChange: setCountryCode }}
              {...register("phoneNumber")}
              {...registerValidation("phoneNumber")}
              onBlur={() => handleOnBlur("phoneNumber")}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t("authentication:firstName")}
              fullWidth
              {...register("firstName")}
              {...registerValidation("firstName")}
              onBlur={() => handleOnBlur("firstName")}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={t("authentication:lastName")}
              fullWidth
              {...register("lastName")}
              {...registerValidation("lastName")}
              onBlur={() => handleOnBlur("lastName")}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AddNewClientForm;
