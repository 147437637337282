import { Typography } from "@mui/material";
import ConfirmationDialogModal from "components/common/ConfirmationDialogModal";
import getFormattedPrice from "helpers/getFormattedPrice";
import { Trans, useTranslation } from "react-i18next";
import { DepositRemovalOptions } from "store/slices/deposits/depositsApi";

type HandleRefundModalProps = {
  isHandleRefundModalOpen: boolean;
  onClose: () => void;
  amount: number;

  updateAppointmentWithRefundHandling: (option: DepositRemovalOptions) => void;
  isInvoice?: boolean;
};

const HandleRefundModal = ({
  isHandleRefundModalOpen,
  onClose,
  amount,
  updateAppointmentWithRefundHandling,
  isInvoice = false,
}: HandleRefundModalProps) => {
  const { t } = useTranslation("appointments");

  const modalDescription = () => {
    return (
      <Typography fontWeight={400} variant="primary">
        <Trans
          i18nKey={
            isInvoice
              ? "appointments:invoicePaidAmountDescription"
              : "appointments:appointmentPaidAmountDescription"
          }
          values={{ amount: `${t("currency")} ${getFormattedPrice(amount)}` }}
          components={[
            <Typography
              key={
                isInvoice
                  ? "invoice-paid-amount-description"
                  : "appointment-paid-amount-description"
              }
              display="inline"
              fontWeight={900}
            />,
          ]}
        />
      </Typography>
    );
  };

  return (
    <ConfirmationDialogModal
      title={t(isInvoice ? "invoiceHasPaidAmount" : "appointmentHasPaidAmount")}
      description={modalDescription()}
      isOpen={isHandleRefundModalOpen}
      onConfirmButtonClick={() =>
        updateAppointmentWithRefundHandling(DepositRemovalOptions.NEGLECT)
      }
      onCancelButtonClick={() => updateAppointmentWithRefundHandling(DepositRemovalOptions.REFUND)}
      confirmButtonLabel={t("remove")}
      cancelButtonLabel={t("addToWallet")}
      cancelButtonVariant="contained"
      onClose={onClose}
    />
  );
};

export default HandleRefundModal;
