import { Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import getServiceSubtitle from "helpers/getServiceSubtitle";
import useFormValidation from "hooks/useFormValidation";
import { useCheckoutFormContext } from "modals/CheckoutModal/CheckoutFormContext";
import EmployeeAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/EmployeeAutoComplete";
import ResourceAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/ResourceAutoComplete";
import { useSelector } from "store";
import { selectServiceById } from "store/slices/servicesV2/servicesSelectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";

type CheckoutCartItemPackageServiceArticleFieldsProps = {
  articleDetailsFieldPath: `articles.${number}.details.${number}`;
  serviceVariantId: Nullable<number> | undefined;
  shouldDisableEditing: boolean;
};

const CheckoutCartItemPackageServiceArticleFields = ({
  articleDetailsFieldPath,

  serviceVariantId,
  shouldDisableEditing,
}: CheckoutCartItemPackageServiceArticleFieldsProps) => {
  const { setValue, watch, formState } = useCheckoutFormContext();

  const registerValidation = useFormValidation(formState, { ns: "checkout" });

  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId || 0));
  const service = useSelector(selectServiceById(serviceVariant?.service || 0));

  const isResourceRequired = service?.needResources;

  const outletId = watch("outlet");

  const employeeFieldPath: `${typeof articleDetailsFieldPath}.saleEmployee` = `${articleDetailsFieldPath}.saleEmployee`;

  const resourceFieldPath: `${typeof articleDetailsFieldPath}.resource` = `${articleDetailsFieldPath}.resource`;

  const onStaffChange = (staffId: number | null) => {
    setValue(employeeFieldPath, staffId || undefined);
  };

  const onResourceChange = (resourceId: number | null) => {
    setValue(resourceFieldPath, resourceId || undefined);
  };

  const selectedEmployeeId = watch(employeeFieldPath);

  const selectedResourceId = watch(resourceFieldPath);

  if (!serviceVariant) return <CentredSpinnerBox />;

  const serviceVariantWithService = { ...serviceVariant, service: service || null };

  return (
    <Grid item container justifyContent="space-between" spacing={3}>
      <Grid item md={5} xs={12}>
        <Typography variant="h5" noWrap>
          {serviceVariantWithService.service?.name || ""}
        </Typography>

        <Typography variant="secondary" color="text.label" fontSize="0.875rem" pt={1}>
          {getServiceSubtitle(
            serviceVariantWithService.service?.name || "",
            serviceVariantWithService.name,
            serviceVariantWithService.duration
          )}
        </Typography>
      </Grid>

      <Grid container item md={7} xs={12} spacing={1} justifyContent="flex-end">
        <Grid item md={6} xs={12}>
          <EmployeeAutoComplete
            outletId={outletId}
            serviceVariantIdToFilterBy={serviceVariantId}
            value={selectedEmployeeId || null}
            onChange={onStaffChange}
            {...registerValidation(employeeFieldPath, "staff")}
            disabled={shouldDisableEditing}
          />
        </Grid>

        {isResourceRequired && (
          <Grid item md={6} xs={12}>
            <ResourceAutoComplete
              outletId={outletId}
              serviceId={service.id}
              value={selectedResourceId || null}
              handleSelectValue={onResourceChange}
              {...registerValidation(resourceFieldPath, "resource")}
              disabled={shouldDisableEditing}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CheckoutCartItemPackageServiceArticleFields;
