import { Box, Typography } from "@mui/material";
import { Bottle } from "assets/icon";
import { theme } from "configs";
import { useTranslation } from "react-i18next";

import type { TopProduct } from "./ClientPerformance";

export const TopPurchasedProducts = ({ products }: { products: TopProduct[] }) => {
  const { t } = useTranslation("client");
  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Typography
        variant="primary"
        sx={{ textTransform: "uppercase" }}
        color="#848FA1"
        fontWeight={700}
        letterSpacing="0.03em"
      >
        {t("topProducts")}
      </Typography>
      {products.length ? (
        products.map((product) => <TopPurchasedProductsItem key={product.name} product={product} />)
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.label,
            fontWeight: "500",
          }}
        >
          {t("dataGeneratedAfterPurchase")}
        </Typography>
      )}
    </Box>
  );
};

export const TopPurchasedProductsItem = ({ product }: { product: TopProduct }) => {
  const { t } = useTranslation("client");

  return (
    <Box display="flex" gap={1.5} justifyContent="space-between">
      <Box display="flex" gap={1}>
        <Box
          sx={{
            backgroundColor: "#64C5B8",
            borderRadius: theme.spacing(1),
            width: 40,
            height: 40,
            padding: theme.spacing(0.5),
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Bottle sx={{ color: "white" }} fontSize="large" />
        </Box>
        <Box>
          <Typography fontWeight={700}>{product.name}</Typography>
          <Typography color="#6C798F">{t("times", { count: product.totalPurchases })}</Typography>
        </Box>
      </Box>
      <Box alignItems="flex-end" textAlign="right">
        <Typography variant="h5" color="#6C798F">
          {t("purchased")}
        </Typography>
        <Typography>
          {new Date(product.lastPurchasedDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </Typography>
      </Box>
    </Box>
  );
};
