import http from "utils/http";

const organizationApi = {
  getOrganization: async () => http.get(`/organizations/organization/`),

  createOrganization: async (data) => http.post("/organizations/organization/", data),

  updateOrganization: async (data) => http.put(`/organizations/organization/`, data),

  removeOrganization: async () => http.delete(`/organizations/organization/`),
};

export default organizationApi;
