import { Typography } from "@mui/material";
import { PeepPayIcon } from "assets/icon";
import { ActionChip } from "components/common";
import { camelize } from "humps";
import CancelPeepPayLinkModal from "modals/AppointmentDrawer/AppointmentDetails/DepositSection/CancelPeepPayLinkModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { InvoicePayment } from "types/Checkout";
import { PeepPayLinkStatus } from "types/Deposit";

type PeepPayPaymentStatusChipProps = {
  pendingPeepPayment: InvoicePayment;
};

const PeepPayPaymentStatusChip = ({ pendingPeepPayment }: PeepPayPaymentStatusChipProps) => {
  const { t } = useTranslation("deposits");

  const { hasPeepPay } = useSelector(selectOrganization) as Organization;
  const [isCancelPeepPaymentLinkModalOpen, setIsCancelPeepPaymentLinkModalOpen] = useState(false);

  const { hasPeepPayCancelAccess } = useSelector(selectAllPermissions);

  const isPeepPayLinkExpired = pendingPeepPayment?.status === PeepPayLinkStatus.Expired;

  const isPeepPayLinkStatusCancellable =
    pendingPeepPayment?.status === PeepPayLinkStatus.Pending ||
    pendingPeepPayment?.status === PeepPayLinkStatus.Expired;

  const canCancelPeepPayLink =
    hasPeepPay && hasPeepPayCancelAccess.editAccess && isPeepPayLinkStatusCancellable;

  const hasRemovePermission = isPeepPayLinkStatusCancellable && canCancelPeepPayLink;

  const handleRemoveClick = () => {
    setIsCancelPeepPaymentLinkModalOpen(true);
  };

  return (
    <>
      {canCancelPeepPayLink && pendingPeepPayment.peepPaymentInvoice && (
        <CancelPeepPayLinkModal
          isOpen={isCancelPeepPaymentLinkModalOpen}
          onClose={() => setIsCancelPeepPaymentLinkModalOpen(false)}
          peepPaymentInvoiceId={pendingPeepPayment.peepPaymentInvoice}
        />
      )}

      <ActionChip
        icon={
          <PeepPayIcon
            viewBox="0 0 56 24"
            sx={{
              width: 56,
              height: 24,
            }}
          />
        }
        title={
          <Typography
            variant="primary"
            fontWeight={700}
            color={isPeepPayLinkExpired ? "error.main" : "text.primary"}
          >
            {pendingPeepPayment?.status && (
              <Typography
                variant="primary"
                color={isPeepPayLinkExpired ? "error.main" : "text.primary"}
              >
                {t(`${camelize(pendingPeepPayment?.status.toLowerCase() || "")}`)}
              </Typography>
            )}
          </Typography>
        }
        handleRemoveAction={handleRemoveClick}
        hasAppliedAction
        hasPermission={hasRemovePermission}
        hasFullWidth={false}
      />
    </>
  );
};

export default PeepPayPaymentStatusChip;
