import { Box, Typography } from "@mui/material";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "store/employees/employeesSlice";
import { selectFlexServiceById } from "store/slices/flexBooking/flexBookingSelectors";
import { selectIndustryCategoryById } from "store/slices/industryCategories/industryCategoriesSelectors";
import { AppointmentArticleSlot, AppointmentArticleWithDetails } from "types/Appointment";

import PriceSection from "./PriceSection";

const FlexServiceItem = ({
  article,
  slot,
}: {
  article: AppointmentArticleWithDetails;
  slot: AppointmentArticleSlot;
}) => {
  const subscriptionRedemption = article.subscriptionRedemption;

  const { t } = useTranslation("calendar");

  const flexService = useSelector(selectFlexServiceById(article?.flexService!));
  const industryCategory = useSelector(
    selectIndustryCategoryById(flexService?.industryCategory || 0)
  );
  const staff = useSelector(selectEmployeeById(slot?.staff));

  const timeSlot = `${DateTime.fromISO(slot.start).toFormat("h:mm a")} - ${DateTime.fromISO(
    slot.end
  ).toFormat("h:mm a")}`;

  const staffName = staff?.user
    ? `${staff.user.firstName}${staff.user.lastName && ` ${staff.user.lastName}`}`
    : staff?.profile
    ? `${staff.profile.firstName}${staff.profile.lastName && ` ${staff.profile.lastName}`}`
    : "";

  const description = `${getFormattedDuration(article?.totalDuration)}, ${staffName}`;

  return (
    <Box position="relative" minWidth="100%" mt={3}>
      <Box
        position="absolute"
        width="100%"
        top="-35px"
        bgcolor="#4048D6"
        borderRadius="8px 8px 0 0 "
        p={1}
        color="white"
        height="60px"
        zIndex={0}
      >
        {t("flexService")}
      </Box>
      <Box
        display="flex"
        bgcolor="#D9DAF7"
        justifyContent="space-between"
        minWidth="100%"
        position="relative"
        zIndex={1}
        alignItems="center"
        borderRadius="8px"
        py={1}
        px={2}
      >
        <Box>
          <Typography variant="primary" fontWeight={500} whiteSpace="nowrap">
            {timeSlot}
          </Typography>

          <Typography variant="h5" fontWeight={500} sx={{ py: 0.5 }}>
            {industryCategory?.name || ""}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography variant="secondary" color="text.label">
              {description}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap={2}>
          <PriceSection
            originalPrice={Number(article.originalPrice)}
            finalPrice={Number(article.finalPrice)}
            hasSubscriptionRedemption={!!subscriptionRedemption}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FlexServiceItem;
