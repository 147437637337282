import { FormArticle } from "pages/CalendarPage/components/AppointmentForm/utils";
import { AppointmentArticleWithSlot } from "types/Appointment";
import { isAppointmentArticleWithSlot } from "types/typeGuards/AppointmentTypeGuards";

// can have either staffId or staff key because it depends on the shape of the article "either backend data or form data"
type Article = AppointmentArticleWithSlot | FormArticle;

type getIsTheOnlyStaffAssignedToTheAppointmentProps = {
  articles: Article[];
  authenticatedUserEmployeeId: Nullable<number> | undefined;
};

/*
 * use this function with wisdom.. or you'll be sad and you'll be sorry -Anfal "The Tears Saver"
 * This function is used to check if the authenticated user is the only staff assigned to the appointment
 * you need to be aware of the shape of the articles slots "either backend data or form data" to know which staffKeyName to use
 */

const getIsTheOnlyStaffAssignedToTheAppointment = ({
  articles,
  authenticatedUserEmployeeId,
}: getIsTheOnlyStaffAssignedToTheAppointmentProps) => {
  if (!authenticatedUserEmployeeId) return false;

  return articles.every((article: Article) => {
    if (isAppointmentArticleWithSlot(article)) {
      return article.slots.every((slot) => slot?.staff === authenticatedUserEmployeeId);
    }

    return article.slots.every((slot) => slot?.staffId === authenticatedUserEmployeeId);
  });
};

export default getIsTheOnlyStaffAssignedToTheAppointment;
