import { Box, Typography } from "@mui/material";
import {
  filterCategoryByServiceOrVariantName,
  filterOutEmptyCategories,
} from "helpers/servicesSearch";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "store/employees/employeesSlice";
import { selectAllServiceCategoriesWithAllData } from "store/slices/serviceCategoriesV2/serviceCategoriesSelectors";

import ServicesSearchFieldContext from "../../ServicesSearchFieldContext";
import ServiceCategory from "./ServiceCategory";

// StaffId should be from form context
const filterServicesByStaffId = (staff) => (service) =>
  staff ? service?.employees?.includes(staff.id) || staff.services.includes(service.id) : true;

const filterCategoryServicesByStaffId = (staff, resourceId) => (category) => {
  const filteredServicesByStaff = staff
    ? category.services.filter(filterServicesByStaffId(staff))
    : category.services;

  const filteredServicesByResourceAndStaff = resourceId
    ? filteredServicesByStaff.filter((service) => service.resources.includes(resourceId))
    : filteredServicesByStaff;

  return { ...category, services: filteredServicesByResourceAndStaff };
};

const renderCategoryServices = (category, date, outletId) => (
  <ServiceCategory key={category.id} category={category} date={date} outletId={outletId} />
);

const ServicesPanel = () => {
  const { t } = useTranslation("appointments");

  const { query, staffIdToFilterBy, resourceToFilterBy } = useContext(ServicesSearchFieldContext);

  const { outletId, date } = useContext(CalendarPageContext);

  const formattedCalendarDateToIso = date.toFormat("yyyy-MM-dd");

  const staffSelected = useSelector(selectEmployeeById(staffIdToFilterBy));

  const serviceCategories = useSelector(selectAllServiceCategoriesWithAllData(outletId || 0));
  const processedQuery = query.toLowerCase().trim();

  const filteredCategories = serviceCategories.map(
    filterCategoryByServiceOrVariantName(processedQuery)
  );

  const servicesCategories = filteredCategories
    .map(filterCategoryServicesByStaffId(staffSelected, resourceToFilterBy))

    .filter(filterOutEmptyCategories)
    .map((category) => renderCategoryServices(category, formattedCalendarDateToIso, outletId));

  const showNoServicesMessage = !servicesCategories.length;

  return (
    <Box>
      {/* add ugly categories filter here  */}

      {showNoServicesMessage ? (
        <Typography p={3}>{t("noMatchingResults")} </Typography>
      ) : (
        servicesCategories
      )}
    </Box>
  );
};

export default ServicesPanel;
