import { Stack } from "@mui/material";
import { BeverageIcon } from "assets/icon";
import { SectionTitle } from "components/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById } from "store/clients/clientsSlice";
import {
  selectAllClientPreferredBeverages,
  selectClientPreferredBeveragesLoading,
} from "store/slices/clientPreferredBeverages/clientPreferredBeveragesSelectors";
import { Client } from "types/Client";

import ClientProfileMultiselectField from "./ClientProfileMultiselectField";

type ClientPreferencesSectionProps = {
  clientId: number;
  isDisabled?: boolean;
  handleChange: (fieldName: keyof Client) => (newValue: number[]) => void;
};

const ClientPreferencesSection = ({
  clientId,
  isDisabled,
  handleChange,
}: ClientPreferencesSectionProps) => {
  const { t } = useTranslation("client");

  const client = useSelector(selectClientById(clientId)) as Client;

  const beverages = useSelector(selectAllClientPreferredBeverages);

  const areBeveragesLoading = useSelector(selectClientPreferredBeveragesLoading);

  const beverageOptions = beverages.map((beverage) => ({
    value: beverage.id,
    label: beverage.name,
  }));

  return (
    <Stack gap={2}>
      <SectionTitle>{t("preferences")}</SectionTitle>

      <ClientProfileMultiselectField
        title={t("beverages")}
        value={client?.preferredBeverages}
        setValue={handleChange("preferredBeverages")}
        options={beverageOptions}
        isLoading={areBeveragesLoading}
        isDisabled={isDisabled}
        icon={
          <BeverageIcon
            sx={{
              height: 16,
              width: 16,
              color: (theme) => theme.palette.primary.main,
            }}
          />
        }
      />
    </Stack>
  );
};

export default ClientPreferencesSection;
