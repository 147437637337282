import type { RootState } from "store";
import { ProductCategoryWithProducts } from "types/ProductCategory";

import {
  selectBriefOutletStocksByProductId,
  selectOutletStocksByProductId,
} from "../outletStocks/outletStocksSelectors";
import { selectProductBrandById } from "../productBrands/productBrandSelectors";
import {
  selectAllProductCategories,
  selectProductCategoryById,
} from "../productCategories/productCategoriesSelectors";
import {
  selectAllSuppliers,
  selectProductSuppliersByIds,
  selectSupplierById,
} from "../suppliers/suppliersSelectors";
import { SliceStatus } from "../utils";
import { productsAdapter } from "./productsSlice";

export const selectProductsLoading = (state: RootState) =>
  state.products.status === SliceStatus.LOADING;

export const selectIsProductDetailLoading = (state: RootState) =>
  state.products.detailStatus === SliceStatus.LOADING;

export const selectProductTotalPages = (state: RootState) => state.products.totalPages;

export const selectProductsState = (state: RootState) => state.products;

const { selectById, selectAll } = productsAdapter.getSelectors(selectProductsState);

export const selectAllProducts = selectAll;

export const selectProductById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectProductWithBrandById = (id: number) => (state: RootState) => {
  const product = selectById(state, id);

  if (!product) return;

  const brandName = product?.brand ? selectProductBrandById(product.brand)(state)?.name : undefined;
  return {
    ...product,
    brand: brandName,
  };
};

// Product with details are paginated, so page array consists of ids that are to be displayed in rendered page.
// The page array is sorted based on user input, rather than backend order, so the products are mapped like to this to match
// the order in which they should appear

export const selectAllProductsWithListDetails = (state: RootState) => {
  const products: UnNormalizedProduct[] = [];
  state.products.page.forEach((productId) => {
    const product = selectById(state, productId);
    if (product)
      products.push({
        ...product,
        category: selectProductCategoryById(product.category)(state),
        brand: selectProductBrandById(product.brand || "")(state),
        suppliers: selectProductSuppliersByIds(product.suppliers || [])(state),
        outletStocks: selectOutletStocksByProductId(product.id)(state),
      });
  });

  return products;
};

export const selectProductByIdWithSupplierCostDetails =
  (id: number | string) => (state: RootState) => {
    const product = selectById(state, id);

    return {
      ...product,
      suppliersCost: product?.suppliersCost?.map((supplierCost) => ({
        ...supplierCost,
        supplier: selectSupplierById(supplierCost.supplier)(state),
      })),
    };
  };

export const selectSuppliersCostByProductId = (id: number | string) => (state: RootState) => {
  const product = selectById(state, id);

  return product?.suppliersCost?.map((supplierCost) => ({
    ...supplierCost,
    supplier: selectSupplierById(supplierCost.supplier)(state),
  }));
};

export const selectProductWithOutletStocksById = (id: number | string) => (state: RootState) => {
  const product = selectById(state, id);
  if (product)
    return {
      ...product,

      outletStocks: selectBriefOutletStocksByProductId(product.id)(state),
    };
  else return null;
};

export const selectSuppliersByProductId = (productId: number) => (state: RootState) => {
  const product = selectById(state, productId);
  const supplierIds = product?.suppliersCost?.map((supplierCost) => supplierCost.supplier) || [];

  return selectAllSuppliers(state).filter((supplier) => supplierIds.includes(supplier.id));
};

export const selectProductsByCategoryId = (categoryId: number | string) => (state: RootState) => {
  return selectAll(state).filter((product) => product.category === categoryId);
};

export const selectCategoriesWithRetailProductsByOutletId =
  (outletId: number) =>
  (state: RootState): ProductCategoryWithProducts[] => {
    const categoriesWithProductsWithBrands = selectAllProductCategories(state).map((category) => ({
      ...category,
      products: selectProductsByCategoryId(category.id)(state)
        .filter((product) => product.isPurchasable && product.outlets.includes(outletId))
        .map((product) => ({
          ...product,
          brand: selectProductBrandById(product.brand || "")(state),
        })),
    }));

    return categoriesWithProductsWithBrands;
  };
