import { Gear } from "assets/icon";
import { NotificationType } from "types/Notification";

import AppointmentNotificationCard from "./AppointmentNotificationCard";
import NotificationCard from "./NotificationCard";
import { NotificationCardProps } from "./NotificationCardType";
import ProductShortageNotificationCard from "./ProductShortageNotificationCard";

const NotificationCardTranslator = (props: NotificationCardProps) => {
  const shouldShowDateTimeOfEvent =
    props.notification.type !== NotificationType.OnlineBookingResumed;

  const descriptionIcon =
    props.notification.type === NotificationType.OnlineBookingResumed ? (
      <Gear
        sx={{
          width: "14px",
          height: "16px",
          color: "text.label",
        }}
      />
    ) : null;

  if (props.notification.type === NotificationType.NewOnlineAppointment)
    return <AppointmentNotificationCard {...props} />;

  if (props.notification.type === NotificationType.ProductThresholdReached)
    return <ProductShortageNotificationCard {...props} />;

  return (
    <NotificationCard
      descriptionIcon={descriptionIcon}
      shouldShowDateTimeOfEvent={shouldShowDateTimeOfEvent}
      {...props}
    />
  );
};

export default NotificationCardTranslator;
