import { Box, Typography } from "@mui/material";
import { IncrementalCounter } from "components/common";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCheckoutFormContext } from "../CheckoutFormContext";

type AddPaymentMethodCounterProps = {
  title: string;
};

const AddPaymentMethodCounter = ({ title }: AddPaymentMethodCounterProps) => {
  const { t } = useTranslation(["checkout", "inventory"]);

  const { balance, watch, setValue, isRefundInvoice } = useCheckoutFormContext();

  const { paymentAmount } = watch();

  const setPaymentAmount = useCallback(
    (value: number) => {
      setValue("paymentAmount", value);
    },
    [setValue]
  );

  // Notice dear that based on the current balance we automatically set the payment amount, so that whenever a payment method is added, the payment amount is set to the balance.
  useEffect(() => {
    setPaymentAmount(balance);
  }, [balance, setPaymentAmount]);

  const currencyPrefix = `${t("checkout:currency")} `;

  return (
    <Box p={4} textAlign="center">
      <Typography variant="h3">{title}</Typography>

      <Typography variant="h5" color="text.label" pt={1} pb={2}>
        *{t("inventory:clickToEditTheAmount")}
      </Typography>

      <IncrementalCounter
        prefix={currencyPrefix}
        textColor={Number(paymentAmount) < 0 || isRefundInvoice ? "error.main" : "primary.main"}
        value={Number(paymentAmount)}
        onChange={setPaymentAmount}
        min={0 > balance ? balance : 0}
        max={balance}
        maxDecimalPlaces={3}
        fallbackValue={balance}
      />
    </Box>
  );
};

export default AddPaymentMethodCounter;
