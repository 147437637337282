import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { selectAppointmentById } from "store/appointments/appointmentsSlice";
import { selectArticleSlotById } from "store/articleSlots/articleSlotsSlice";
import { selectPackageVariantById } from "store/slices/packageVariantsV2/packageVariantsSelectors";
import { selectServiceVariantByIdWithService } from "store/slices/servicesV2/servicesSelectors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./articlesApi";

const articlesAdapter = createEntityAdapter();

const initialState = articlesAdapter.getInitialState({
  isLoading: false,
  error: "",
});

const { selectById, selectAll } = articlesAdapter.getSelectors((state) => state.articles);

export const selectAllArticles = selectAll;

export const selectArticleById = (id) => (state) => selectById(state, id);

export const selectArticleWithAppointmentById = (id) => (state) => {
  const article = selectById(state, id);
  if (!article) return;

  const appointment = selectAppointmentById(article.appointment)(state);
  if (!appointment) return;

  return { ...article, appointment };
};

export const selectArticleWithSlotsById = (id) => (state) => {
  const article = selectById(state, id);
  if (!article) return;

  const slots = article.slots
    .map((slotId) => selectArticleSlotById(slotId)(state))
    .filter((slot) => !!slot);
  if (!slots.length) return;

  return { ...article, slots };
};

const selectArticleWithPurchasedItemDetails = (id) => (state) => {
  const article = selectById(state, id);
  if (!article) return;
  const serviceVariant = selectServiceVariantByIdWithService(article.serviceVariant || 0)(state);
  const packageVariant = selectPackageVariantById(article.packageVariant)(state);

  return {
    ...article,
    serviceVariant,
    packageVariant,
  };
};

export const selectArticleDetailsWithSlotsById = (id) => (state) => {
  const article = selectArticleWithPurchasedItemDetails(id)(state);
  if (!article) return;

  const slots = article.slots
    .map((slotId) => selectArticleSlotById(slotId)(state))
    .filter((slot) => !!slot);
  if (!slots.length) return;

  return { ...article, slots };
};

export const selectArticleLoading = (state) => state.isLoading;

export const getAllArticles = createThunkFromApi("articles/getAllArticles", api.getAllArticles);

export const getArticle = createThunkFromApi("articles/getArticle", api.getArticle);

export const createArticle = createThunkFromApi("articles/createArticle", api.createArticle);

export const updateArticle = createThunkFromApi("articles/updateArticle", api.updateArticle);

export const deleteArticle = createThunkFromApi("articles/removeArticle", api.removeArticle);

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    upsertArticles(state, { payload }) {
      articlesAdapter.upsertMany(state, payload);
    },
    removeArticles(state, { payload }) {
      articlesAdapter.removeMany(state, payload);
    },
  },
  extraReducers: {
    [getAllArticles.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllArticles.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      articlesAdapter.setAll(state, payload);
    },
    [getAllArticles.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getArticle.fulfilled]: (state, { payload }) => {
      articlesAdapter.setOne(state, payload);
    },

    [createArticle.fulfilled]: (state, { payload }) => {
      articlesAdapter.addOne(state, payload);
    },

    [updateArticle.fulfilled]: (state, { payload }) => {
      articlesAdapter.updateOne(state, {
        id: payload.id,
        changes: payload.changes,
      });
    },

    [deleteArticle.fulfilled]: (state, { payload: id }) => {
      articlesAdapter.removeOne(state, id);
    },
  },
});

export const { upsertArticles, removeArticles } = articlesSlice.actions;
export default articlesSlice.reducer;
