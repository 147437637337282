import { createSlice } from "@reduxjs/toolkit";
import { type RootState } from "store";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import Role, { PermissionType } from "types/Role";
import auth from "utils/auth";

import api from "./myPermissionsApi";
import { PermissionAccess, getInitialPermissions, options } from "./utils";
import { getEnumKeyByEnumValue } from "types/helpers/enum";

export type MyPermissionsSliceState = {
  permissions: PermissionAccess;
  isLoading: boolean;
  error: string;
};

const defaultAccess: PermissionAccess = getInitialPermissions(false);
const ownerOrAdminAccess: PermissionAccess = getInitialPermissions(true);

const processRole = (role: Role): PermissionAccess => {
  // Based on our permission endpoint, this function will be used for employees and not admins or owners, but i made this functions flexible
  if (auth.user?.id && role) {
    if (role.isSuperAdmin) {
      auth.updateUser({ ...auth.user, isOwnerOrAdmin: true });

      return ownerOrAdminAccess;
    } else {
      auth.updateUser({ ...auth.user, isOwnerOrAdmin: false });

      const userAccess = role.permissions.reduce<PermissionAccess>(
        (currentAccessPermissions, permission) => ({
          ...currentAccessPermissions,
          [getEnumKeyByEnumValue(PermissionType, permission.type)]: options[permission.value],
        }),
        defaultAccess
      );

      return userAccess;
    }
  }
  return defaultAccess;
};

export const selectAllPermissions = (state: RootState) => state.myPermissions.permissions;

export const selectAllPermissionsLoading = (state: RootState) => state.myPermissions.isLoading;

const initialState: MyPermissionsSliceState = {
  permissions: auth.user?.isOwnerOrAdmin ? ownerOrAdminAccess : defaultAccess,
  isLoading: auth.user?.isOwnerOrAdmin ? false : true,
  error: "",
};

export const getMyPermissions = createThunkFromApiWithType(
  "roles/getMyPermissions",
  api.getMyPermissions
);

export const myPermissionsSlice = createSlice({
  name: "myPermissions",
  initialState,
  reducers: {
    setOwnerOrAdminPermissions(state) {
      state.permissions = ownerOrAdminAccess;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyPermissions.fulfilled, (state, { payload }) => {
        state.permissions = processRole(payload);

        state.isLoading = false;
      })
      .addCase(getMyPermissions.rejected, (state, { error }) => {
        state.error = error.message || "";
        state.isLoading = false;
      });
  },
});

export default myPermissionsSlice.reducer;

export const { setOwnerOrAdminPermissions } = myPermissionsSlice.actions;
