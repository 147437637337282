import { createAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import getWorkingHoursTotalDuration from "helpers/getWorkingHoursTotalDuration";
import { DateTime, Duration, Interval } from "luxon";
import type { RootState } from "store";

import api, {
  CreateEmployeeWorkingHoursResponse,
  CreateEmployeesWorkingHoursArguments,
  DeleteEmployeeWorkingHoursArguments,
  DjangoDetailResponse,
  EmployeeTimeConflictArguments,
  EmployeeTimeConflictResponse,
  EmployeeWorkingHours,
  EmployeeWorkingHoursResponse,
  EmployeesWorkingHoursArguments,
  UpdateEmployeeWorkingHoursResponse,
  UpdateEmployeesWorkingHoursArguments,
} from "./workingHoursApi";

const workingHoursAdapter = createEntityAdapter<EmployeeWorkingHours>({
  selectId: ({ id, employee, outlet, date }) => `${id}:${outlet}:${employee}:${date}`,
});

const initialState = workingHoursAdapter.getInitialState<{
  isLoading: boolean;
  isRequestPending: boolean;
  error: string;
  timeConflicts: EmployeeTimeConflictResponse;
}>({
  isLoading: false,
  isRequestPending: false,
  error: "",
  timeConflicts: {},
});

const { selectById, selectAll } = workingHoursAdapter.getSelectors<RootState>(
  (state) => state.workingHours
);

export const selectWorkingHourById = (id: string) => (state: RootState) => selectById(state, id);

export const selectAllWorkingHours = selectAll;

export const selectTimeConflict = (state: RootState) => state.workingHours.timeConflicts;

export const selectTimeConflictByEmployeeId = (employeeId: number) => (state: RootState) =>
  state.workingHours.timeConflicts[employeeId];

export const selectWorkingHoursByDateRange =
  (startDate: DateTime, endDate: DateTime) => (state: RootState) => {
    const allWorkingHours = selectAllWorkingHours(state);

    return allWorkingHours.filter((workingHour) => {
      const workingHourDate = DateTime.fromISO(workingHour.date);
      return startDate.startOf("day") <= workingHourDate && endDate.endOf("day") >= workingHourDate;
    });
  };

export const selectWorkingHoursByStaff = (staffId: Number) => (state: RootState) => {
  const allWorkingHours = selectAllWorkingHours(state);
  return allWorkingHours.filter((workingHour) => workingHour.employee === staffId);
};

export const selectWorkingHoursByEmployeeAndDateForOutlet =
  (employeeId: Number, outletId: Number, date: DateTime) => (state: RootState) => {
    const employeeWorkingHours = selectWorkingHoursByStaff(employeeId)(state);

    const employeeWorkingHoursByDate = employeeWorkingHours.filter(
      (workingHour) => workingHour.date === date.toFormat("yyyy-MM-dd")
    );

    const employeeWorkingHoursByDateAndOutlet = employeeWorkingHoursByDate.filter(
      (workingHour) => workingHour.outlet === outletId
    );
    return employeeWorkingHoursByDateAndOutlet;
  };

export const selectEmployeeTotalHoursByStartAndEndDate =
  (employeeId: Number, outletId: Number, startDate: DateTime, endDate: DateTime) =>
  (state: RootState) => {
    const datesInterval = Interval.fromDateTimes(startDate.startOf("day"), endDate.endOf("day"));

    const allWorkingHours = selectAllWorkingHours(state);

    const workingHours = allWorkingHours.filter(
      (workingHour) =>
        workingHour.employee === employeeId &&
        workingHour.outlet === outletId &&
        datesInterval.contains(DateTime.fromISO(workingHour.date))
    );

    const totalWorkingHours = workingHours
      .map(getWorkingHoursTotalDuration)
      .reduce(
        (partialTotalHours, workingHourDuration) => partialTotalHours.plus(workingHourDuration),
        Duration.fromObject({ hours: 0, minutes: 0 })
      );

    return totalWorkingHours;
  };

export const selectTotalHoursAndEmployeeCountByDateAndOutlet =
  (outletId: Number, date: DateTime) => (state: RootState) => {
    const allWorkingHours = selectAllWorkingHours(state);

    const workingHours = allWorkingHours.filter(
      (workingHour) =>
        workingHour.outlet === outletId && workingHour.date === date.toFormat("yyyy-MM-dd")
    );

    const totalWorkingHours = workingHours
      .map(getWorkingHoursTotalDuration)
      .reduce(
        (partialTotalHours, workingHourDuration) => partialTotalHours.plus(workingHourDuration),
        Duration.fromObject({ hours: 0, minutes: 0 })
      );

    const allEmployees = workingHours.map((workingHour) => workingHour.employee);
    const uniqueEmployees = new Set(allEmployees);

    const employeeCount = uniqueEmployees.size;

    const result: [Duration, Number] = [totalWorkingHours, employeeCount];
    return result;
  };

export const getAllEmployeesWorkingHours = createAsyncThunk<
  EmployeeWorkingHoursResponse,
  EmployeesWorkingHoursArguments,
  {
    rejectValue: string;
  }
>("workingHours/getAllEmployeesWorkingHours", async (workingHoursArgs, { rejectWithValue }) => {
  try {
    const { startDate, endDate, outletId } = workingHoursArgs;

    const response = await api.getEmployeesWorkingHours({ startDate, endDate, outletId });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const createEmployeesWorkingHours = createAsyncThunk<
  CreateEmployeeWorkingHoursResponse,
  CreateEmployeesWorkingHoursArguments,
  {
    rejectValue: string;
  }
>("workingHours/createEmployeesWorkingHours", async (workingHoursArgs, { rejectWithValue }) => {
  try {
    const { data } = workingHoursArgs;

    const response = await api.createEmployeesWorkingHours(data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const updateEmployeesWorkingHours = createAsyncThunk<
  UpdateEmployeeWorkingHoursResponse,
  UpdateEmployeesWorkingHoursArguments,
  {
    rejectValue: string;
  }
>("workingHours/updateEmployeesWorkingHours", async (workingHoursArgs, { rejectWithValue }) => {
  try {
    const { data, id } = workingHoursArgs;

    const response = await api.updateEmployeesWorkingHours(data, id);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const getEmployeeTimeConflicts = createAsyncThunk<
  EmployeeTimeConflictResponse,
  EmployeeTimeConflictArguments,
  {
    rejectValue: string;
  }
>("workingHours/getEmployeeTimeConflicts", async (workingHoursArgs, { rejectWithValue }) => {
  try {
    const response = await api.getTimeConflicts(workingHoursArgs);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const deleteEmployeeWorkingHour = createAsyncThunk<
  null,
  DeleteEmployeeWorkingHoursArguments,
  {
    rejectValue: string;
  }
>("workingHours/deleteEmployeeWorkingHour", async ({ data }, { rejectWithValue }) => {
  try {
    const response = await api.deleteEmployeeWorkingHour(data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const clearTimeConflicts = createAction("workingHours/clearTimeConflicts");

export const workingHoursSlice = createSlice({
  name: "workingHours",
  initialState,
  reducers: {},
  extraReducers: (reducers) =>
    reducers
      .addCase(getAllEmployeesWorkingHours.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(getAllEmployeesWorkingHours.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        workingHoursAdapter.setAll(state, payload);
      })
      .addCase(getAllEmployeesWorkingHours.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })
      .addCase(clearTimeConflicts, (state) => {
        state.timeConflicts = {};
      })
      .addCase(getEmployeeTimeConflicts.fulfilled, (state, { payload }) => {
        state.timeConflicts = payload;
      })
      .addCase(updateEmployeesWorkingHours.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
      }),
});

export default workingHoursSlice.reducer;
