import { DeleteX } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import { ItemPrice } from "components/common";

import InvoiceRowItem, { InvoiceItemProps } from "./InvoiceRowItem";

type InvoiceRowPriceItemProps = Omit<InvoiceItemProps, "value"> & {
  amount: number;
  onRemove?: () => void;
  shouldDisableRemove?: boolean;
};

const InvoiceRowPriceItem = ({
  amount,
  color,
  onRemove,
  shouldDisableRemove,
  ...rest
}: InvoiceRowPriceItemProps) => {
  const isNegative = amount < 0;

  const absoluteAmount = Math.abs(amount);

  return (
    <InvoiceRowItem
      value={
        <Box display="flex" gap={1} justifyContent="flex-end" alignItems="flex-start">
          {isNegative && (
            <Typography variant="h5" fontWeight={700} color={color}>
              -
            </Typography>
          )}

          <ItemPrice
            shouldDisplayZeroAsFree={false}
            originalPrice={absoluteAmount}
            finalPrice={absoluteAmount}
            alignItems="end"
            finalPriceProps={{
              fontWeight: 700,
              color: color || "text.primary",
              variant: "h5",
            }}
          />

          {onRemove && (
            <IconButton onClick={onRemove} disabled={shouldDisableRemove} sx={{ p: 0 }}>
              <DeleteX fontSize="small" />
            </IconButton>
          )}
        </Box>
      }
      color={color}
      {...rest}
      valueProps={{
        fontWeight: 700,
      }}
    />
  );
};

export default InvoiceRowPriceItem;
