import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import getServiceSubtitle from "helpers/getServiceSubtitle";
import { DateTime } from "luxon";
import { CheckoutArticle } from "modals/CheckoutModal/CheckoutFormContext";
import {
  PackageVariantActivePromotionItem,
  ProductActivePromotionItem,
  ServiceVariantActivePromotionItem,
} from "types/ActivePromotion";
import { DiscountOption } from "types/promotions";
import { PurchasableItem, PurchasableItemOption } from "types/PurchasableItem";
import { Service } from "types/ServiceV2";
import {
  isPackageVariant,
  isProductWithBrand,
  isServiceVariant,
  isSubscription,
  isVoucherWithRedeemableItemsTitle,
} from "types/typeGuards/PurchasableItemTypeGuards";

/**
 * This function is used to get the final price based on the subscription
 * @param purchasableItem
 * @param subscriptionToBeApplied
 * @returns
 */
const getFinalPriceBasedOnSubscription = (
  purchasableItem: PurchasableItem,
  subscriptionToBeApplied: Nullable<number>
) => {
  if (!isServiceVariant(purchasableItem) && !isPackageVariant(purchasableItem))
    throw new Error("The type of purchasableItem is not supported for this function.");

  if (subscriptionToBeApplied) return 0;
  else return Number(purchasableItem.price);
};

/**
 * This function is used to get the final price based on the offer (subscription or promotion)
 * @param purchasableItem
 * @param purchasableItemOriginalPrice
 * @param subscriptionToBeApplied
 * @param promotionToBeApplied
 * @returns
 */
const getFinalPriceBasedOnOffer = (
  purchasableItem: PurchasableItem,
  purchasableItemOriginalPrice: number,
  subscriptionToBeApplied: Nullable<number>,
  promotionToBeApplied: Nullable<
    | ServiceVariantActivePromotionItem
    | ProductActivePromotionItem
    | PackageVariantActivePromotionItem
  >,
  discountValue: number
) => {
  if (subscriptionToBeApplied)
    return getFinalPriceBasedOnSubscription(purchasableItem, subscriptionToBeApplied);

  if (discountValue)
    return getFinalPriceAfterDiscount({
      originalPrice: purchasableItemOriginalPrice,
      discountValue,
      discountOption: DiscountOption.Percentage,
    });

  if (promotionToBeApplied)
    return getFinalPriceAfterDiscount({
      originalPrice: purchasableItemOriginalPrice,
      discountValue: promotionToBeApplied.value,
      discountOption: promotionToBeApplied.discountType,
    });

  return purchasableItemOriginalPrice;
};

export const initializeCheckoutArticle = (
  purchasableItem: PurchasableItem,
  type: PurchasableItemOption,
  appointmentTime: string,
  subscriptionToBeApplied: Nullable<number>,
  promotionToBeApplied: Nullable<
    | ServiceVariantActivePromotionItem
    | ProductActivePromotionItem
    | PackageVariantActivePromotionItem
  >,
  discountValue: number
): CheckoutArticle => {
  const hasAppointmentTime = Boolean(appointmentTime);

  // check if appointmentTime is a valid luxon DateTime
  const isAppointmentTimeValid = hasAppointmentTime && DateTime.fromISO(appointmentTime).isValid;

  if (hasAppointmentTime && !isAppointmentTimeValid) throw new Error("Invalid appointment time");

  const saleTime = isAppointmentTimeValid ? appointmentTime : DateTime.now().toISO();

  const promotionId = promotionToBeApplied?.promotion || null;

  const getInitialResourceBasedOnService = (itemService: Service) =>
    itemService?.needResources ? undefined : null;

  switch (type) {
    case PurchasableItemOption.Service:
      if (isServiceVariant(purchasableItem))
        return {
          title: purchasableItem.service.name,
          subtitle: getServiceSubtitle(
            purchasableItem.service.name,
            purchasableItem.name,
            purchasableItem.duration
          ),

          finalPrice: getFinalPriceBasedOnOffer(
            purchasableItem,
            Number(purchasableItem.price),
            subscriptionToBeApplied,
            promotionToBeApplied,
            discountValue
          ),
          originalPrice: Number(purchasableItem.price),
          markedPrice: Number(purchasableItem.price),
          discountValue,

          purchasedItem: {
            serviceVariant: purchasableItem.id,
          },

          promotionId,

          purchasableItemType: PurchasableItemOption.Service,

          subscriptionPurchaseId: subscriptionToBeApplied,

          details: [
            {
              purchasedItem: {
                serviceVariant: purchasableItem.id,
                product: null,
              },
              saleTime,
              duration: purchasableItem.duration,
              resource: getInitialResourceBasedOnService(purchasableItem.service),
            },
          ],
        };

      throw new Error("Invalid purchasableItem 'Service' type");

    case PurchasableItemOption.Package:
      if (isPackageVariant(purchasableItem))
        return {
          title: purchasableItem.name,
          subtitle: `${getFormattedDuration(purchasableItem.totalDuration)}`,

          finalPrice: getFinalPriceBasedOnOffer(
            purchasableItem,
            Number(purchasableItem.price),
            subscriptionToBeApplied,
            promotionToBeApplied,
            discountValue
          ),
          originalPrice: Number(purchasableItem.price),
          markedPrice: Number(purchasableItem.price),
          discountValue,

          purchasedItem: {
            packageVariant: purchasableItem.id,
          },
          purchasableItemType: PurchasableItemOption.Package,

          subscriptionPurchaseId: subscriptionToBeApplied,
          promotionId,

          details: purchasableItem.serviceVariants.map((serviceVariant) => ({
            purchasedItem: {
              serviceVariant: serviceVariant.id,
            },
            saleTime,
            duration: serviceVariant.duration,
            resource: getInitialResourceBasedOnService(serviceVariant.service),
          })),
        };

      throw new Error("Invalid purchasableItem 'Package' type");

    case PurchasableItemOption.Product:
      if (isProductWithBrand(purchasableItem))
        return {
          title: purchasableItem.name,
          subtitle: `${purchasableItem.brand?.name || ""}`,

          quantity: 1,

          finalPrice: getFinalPriceBasedOnOffer(
            purchasableItem,
            Number(purchasableItem.retailPrice),
            subscriptionToBeApplied,
            promotionToBeApplied,
            discountValue
          ),
          originalPrice: Number(purchasableItem.retailPrice),
          markedPrice: Number(purchasableItem.retailPrice),
          discountValue,

          promotionId,

          purchasedItem: {
            product: purchasableItem.id,
          },
          purchasableItemType: PurchasableItemOption.Product,
          details: [
            {
              purchasedItem: {
                product: purchasableItem.id,
              },
              saleTime,
              duration: 0,
            },
          ],
        };

      throw new Error("Invalid purchasableItem 'Product' type");

    case PurchasableItemOption.Subscription:
      if (isSubscription(purchasableItem))
        return {
          title: purchasableItem.name,
          subtitle: purchasableItem.itemName,
          subtitleAdditionalInfo: `x ${purchasableItem.count}`,

          finalPrice: getFinalPriceBasedOnOffer(
            purchasableItem,
            Number(purchasableItem.price),
            subscriptionToBeApplied,
            promotionToBeApplied,
            discountValue
          ),
          originalPrice: Number(purchasableItem.price),
          markedPrice: Number(purchasableItem.price),
          discountValue,

          purchasedItem: {
            subscription: purchasableItem.id,
          },
          purchasableItemType: PurchasableItemOption.Subscription,
          details: [
            {
              purchasedItem: {
                subscription: purchasableItem.id,
              },
              saleTime,
              duration: 0,
            },
          ],
        };

      throw new Error("Invalid purchasableItem 'Subscription' type");

    case PurchasableItemOption.Voucher:
      if (isVoucherWithRedeemableItemsTitle(purchasableItem))
        return {
          title: purchasableItem.name,
          subtitle: purchasableItem.redeemableItemsTitle,

          finalPrice: getFinalPriceBasedOnOffer(
            purchasableItem,
            Number(purchasableItem.price),
            subscriptionToBeApplied,
            promotionToBeApplied,
            discountValue
          ),
          originalPrice: Number(purchasableItem.price),
          markedPrice: Number(purchasableItem.price),
          discountValue,

          purchasedItem: {
            voucher: purchasableItem.id,
          },
          purchasableItemType: PurchasableItemOption.Voucher,
          details: [
            {
              purchasedItem: {
                voucher: purchasableItem.id,
              },
              saleTime,
              duration: 0,
            },
          ],
        };
      throw new Error("Invalid purchasableItem 'Voucher' type");

    default:
      throw new Error("Invalid PurchasableItemOption");
  }
};
