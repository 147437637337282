import type { RootState } from "store";
import { selectEmployeesByIds } from "store/employees/employeesSlice";
import { ServiceWithVariants } from "types/ServiceV2";

import {
  selectServiceVariantById,
  selectVariantsByServiceId,
} from "../serviceVariantsV2/serviceVariantsSelectors";
import { SliceStatus } from "../utils";
import { servicesAdapter } from "./servicesSlice";

const { selectById, selectAll } = servicesAdapter.getSelectors(
  (state: RootState) => state.servicesV2
);

export const selectServiceVariantByIdWithService = (id: number | string) => (state: RootState) => {
  const serviceVariant = selectServiceVariantById(id)(state);
  if (!serviceVariant) return;

  const service = selectServiceById(serviceVariant.service)(state);
  if (!service) return;

  return {
    ...serviceVariant,
    service,
  };
};

export const selectAllServices = selectAll;

export const selectAllActiveServices = (state: RootState) =>
  selectAllServices(state).filter((service) => !service.deprecated);

export const selectServicesByCategoryId = (categoryId: string | number) => (state: RootState) => {
  const allServices = selectAllActiveServices(state);

  return allServices.filter((service) => service.category === Number(categoryId));
};

export const selectServiceById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectServiceEmployeesDetailsById =
  (id: number | string, outletId: number | string) => (state: RootState) => {
    const service = selectById(state, id);

    if (!!service?.employees) return selectEmployeesByIds(service.employees, outletId)(state);

    return [];
  };

export const selectServicesLoading = (state: RootState) =>
  state.servicesV2.status === SliceStatus.LOADING;

export const selectIsServiceRequestLoading = (state: RootState) =>
  state.servicesV2.isRequestPending === SliceStatus.LOADING;

export const selectServiceByIdWithActiveVariants = (id: number | string) => (state: RootState) => {
  const service = selectById(state, id);
  if (!service) return;

  const variants = selectVariantsByServiceId(service.id)(state).filter(
    (variant) => !variant.deprecated
  );
  // Don't return a service that has no active variants
  if (variants.length < 1) return;
  return { ...service, variants };
};

export const selectAllActiveServicesWithActiveVariants = (state: RootState) => {
  const activeServices = selectAllActiveServices(state);

  return activeServices.map((service) => selectServiceByIdWithActiveVariants(service.id)(state));
};

export const selectOnlineServicesByIndustryCategory =
  (industryCategoryId: number) => (state: RootState) => {
    const activeServices = selectAllActiveServices(state).filter(
      (service) =>
        service.industryCategory === industryCategoryId && service.online && !service.deprecated
    );

    return activeServices.map((service) =>
      selectServiceByIdWithActiveVariants(service.id)(state)
    ) as ServiceWithVariants[];
  };

export const selectAllServiceVariantsWithService = (state: RootState) => {
  const allServices = selectAllServices(state);

  const allVariants = allServices
    .map((service) => selectVariantsByServiceId(service.id)(state))
    .flat();

  const allVariantsWithServices = allVariants.map((variant) =>
    selectServiceVariantByIdWithService(variant.id)(state)
  );

  return allVariantsWithServices;
};

export const selectAllActiveServicesByOutletId = (outletId: number) => (state: RootState) =>
  selectAllServices(state).filter(
    (service) => !service.deprecated && service.online && service.outlets.includes(outletId)
  );
