import { Box, Grid, Skeleton, Typography } from "@mui/material";

const AppointmentsHistoryItemSkeleton = () => {
  return (
    <Box m={3}>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12}>
          <Typography variant="secondary" display="block">
            <Skeleton variant="text" width="100%" />
          </Typography>

          <Typography variant="secondary" color="text.label">
            <Skeleton variant="text" width="70%" />
          </Typography>

          <Typography variant="secondary" color="text.label">
            <Skeleton variant="text" width="90%" />
          </Typography>
        </Grid>

        <Grid item md={8} xs={12}>
          <Typography variant="secondary" display="block">
            <Skeleton variant="text" height={40} width="80%" />
          </Typography>
          <Typography variant="secondary" color="text.label">
            <Skeleton variant="text" width="40%" />
          </Typography>
        </Grid>

        <Grid item md={2} xs={12}>
          <Typography variant="h5" display="flex" justifyContent="flex-end">
            <Skeleton variant="text" height={40} width="100%" />
          </Typography>
        </Grid>
      </Grid>

      <Box
        width="100%"
        borderRadius={1}
        my={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    </Box>
  );
};

export default AppointmentsHistoryItemSkeleton;
