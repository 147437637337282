import { Box, Typography } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";

import { useCheckoutFormContext } from "./CheckoutFormContext";

const DATE_FORMAT = "EEEE, d MMMM yyyy";

export default function CheckoutDate() {
  const { t } = useTranslation("checkout");

  const { appointmentId, checkoutModalView } = useCheckoutModal();

  const { date } = useCheckoutFormContext();

  const hasAppointment = !!appointmentId;

  const formattedDate = DateTime.fromISO(date).toFormat(DATE_FORMAT);

  const isRefundView =
    checkoutModalView === CheckoutModalView.Refund ||
    checkoutModalView === CheckoutModalView.ConfirmRefund;

  const getDateTitle = () => {
    if (isRefundView) return t("refundDate");
    else return t(hasAppointment ? "appointmentDate" : "saleDate");
  };

  return (
    <Box display="inline-flex" px={4} pt={4}>
      <Typography variant="h1">{`${getDateTitle()}:`}</Typography>
      <Typography variant="h1" color="primary" pl={1}>
        {formattedDate}
      </Typography>
    </Box>
  );
}
