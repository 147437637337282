import { Box, BoxProps, FormHelperText, Typography, styled } from "@mui/material";
import { categoryColors } from "utils/categoryColors";

type ColorSelectionProps = {
  value: string;
  onChange: (color: string) => void;
  error?: boolean;
  helperText?: string;
  title: string;
  colorOptions?: string[];
};

export default function ColorSelection({
  value,
  onChange,
  error = false,
  helperText,
  title,
  colorOptions = categoryColors,
}: ColorSelectionProps) {
  const ColorDot = styled(({ bgcolor, ...props }: BoxProps) => (
    <Box bgcolor={bgcolor} onClick={() => onChange(bgcolor as string)} {...props} />
  ))(({ theme, bgcolor }) => ({
    borderRadius: "100%",
    width: "26px",
    height: "26px",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      marginRight: "30px",
    },

    ...(bgcolor === value && {
      outline: `2px solid #4E5D7860`,
      border: `2px solid white`,
    }),
  }));

  return (
    <>
      <Typography variant="h4" fontWeight={600}>
        {title}
      </Typography>
      <Box display="flex" columnGap={1} rowGap={2} flexWrap="wrap" pt={1.5} pb={1.5}>
        {colorOptions.map((color) => (
          <ColorDot key={color} bgcolor={color} />
        ))}
      </Box>

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
}
