import { Box, Button } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";

import { useCheckoutFormContext } from "../CheckoutFormContext";

const AddPurchasableItemsFooter = () => {
  const { t } = useTranslation("checkout");

  const { setCheckoutModalView } = useCheckoutModal();

  const { cartItemFields, handleSubmit } = useCheckoutFormContext();

  const handleCheckoutClicked = () => {
    setCheckoutModalView(CheckoutModalView.Payment);
  };

  const isCartEmpty = !cartItemFields.length;

  return (
    <Box width="100%" boxShadow="topDivider" p={3}>
      {/* used handle submit here to trigger form validation before the next step */}
      <Button fullWidth onClick={handleSubmit(handleCheckoutClicked)} disabled={isCartEmpty}>
        {t("checkout")}
      </Button>
    </Box>
  );
};

export default AddPurchasableItemsFooter;
