import debounce from "@mui/utils/debounce";
import useIsMobileView from "hooks/useIsMobileView";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { appendAllInvoicesLogData, getAllInvoicesLogData } from "store/sales/salesSlice";

import { formatDate, timePeriodOptions } from "../utils";

export const InvoiceStatusOptions = {
  PAID: "Paid",
  UNPAID: "Unpaid",
  PARTIALLY: "Partially",
  VOID: "Void",
  REFUNDED: "Refunded",
  "PARTIALLY REFUNDED": "Partially Refunded",
};

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

const initialState = {
  dateRange: timePeriodOptions[3][1],
  setDateRange: unimplementedFunction,

  page: 1,
  setPage: unimplementedFunction,

  searchQuery: "",
  setSearchQuery: unimplementedFunction,

  searchQueryAsParam: "",
  setSearchQueryAsParam: unimplementedFunction,

  handleInputChange: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  outletIds: [],
  setOutletIds: unimplementedFunction,

  statusOptions: [],
  setStatusOptions: unimplementedFunction,

  paymentTypeIds: [],
  setPaymentTypeOptions: unimplementedFunction,

  bookingSourceOptions: [],
  setBookingSourceOptions: unimplementedFunction,

  zoneIds: [],
  setZoneIds: unimplementedFunction,

  areaIds: [],
  setAreaIds: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const InvoicesLogContext = createContext(initialState);

export default InvoicesLogContext;

function InvoicesLogContextProvider({ children }) {
  const isMobileView = useIsMobileView();

  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState(initialState.dateRange);
  const [page, setPage] = useState(initialState.page);

  const [outletIds, setOutletIds] = useState([]);

  const [statusOptions, setStatusOptions] = useState([]);

  const [typeOptions, setTypeOptions] = useState([]);

  const [bookingSourceOptions, setBookingSourceOptions] = useState(
    initialState.bookingSourceOptions
  );
  const [paymentTypeIds, setPaymentTypeIds] = useState([]);

  const [zoneIds, setZoneIds] = useState([]);

  const [areaIds, setAreaIds] = useState([]);

  const [searchQuery, setSearchQuery] = useState(initialState.searchQuery);

  const [searchQueryAsParam, setSearchQueryAsParam] = useState(initialState.searchQueryAsParam);

  const triggerDebouncedFetch = useMemo(
    () =>
      debounce((searchQuery) => {
        setSearchQueryAsParam(searchQuery);
        setPage(1);
      }, 800),
    []
  );

  const fetchInvoices = useCallback(triggerDebouncedFetch, [triggerDebouncedFetch]);

  const handleInputChange = (newValue) => {
    setPage(1);
    setSearchQuery(newValue);
    fetchInvoices(newValue);
  };

  const handleLoadMore = async (pageNumber) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
    setOutletIds([]);
    setTypeOptions([]);
    setStatusOptions([]);
    setPaymentTypeIds([]);
    setBookingSourceOptions([]);
    setZoneIds([]);
    setAreaIds([]);
  };

  useEffect(() => {
    const startDate = formatDate(dateRange[0]);
    const endDate = formatDate(dateRange[1]);

    if (isMobileView && page !== 1) {
      dispatch(
        appendAllInvoicesLogData({
          startDate,
          endDate,
          outlets: outletIds,
          status: statusOptions.map((status) => status.toUpperCase()),
          type: typeOptions,
          paymentType: paymentTypeIds,
          bookingSource: bookingSourceOptions,
          zones: zoneIds,
          areas: areaIds,
          page,
          searchQuery: searchQueryAsParam,
        })
      );
    } else {
      dispatch(
        getAllInvoicesLogData({
          startDate,
          endDate,
          outlets: outletIds,
          status: statusOptions.map((status) => status.toUpperCase()),
          type: typeOptions,
          paymentType: paymentTypeIds,
          bookingSource: bookingSourceOptions,
          zones: zoneIds,
          areas: areaIds,
          page,
          searchQuery: searchQueryAsParam,
        })
      );
    }
  }, [
    dispatch,
    dateRange,
    outletIds,
    statusOptions,
    typeOptions,
    paymentTypeIds,
    page,
    bookingSourceOptions,
    searchQueryAsParam,
    zoneIds,
    areaIds,
    isMobileView,
  ]);

  return (
    <InvoicesLogContext.Provider
      value={{
        dateRange,
        setDateRange,

        page,
        setPage,

        outletIds,
        setOutletIds,

        typeOptions,
        setTypeOptions,

        statusOptions,
        setStatusOptions,

        paymentTypeIds,
        setPaymentTypeIds,

        zoneIds,
        setZoneIds,

        areaIds,
        setAreaIds,

        bookingSourceOptions,
        setBookingSourceOptions,

        searchQuery,
        setSearchQuery,

        handleInputChange,

        handleLoadMore,

        clearAllFilters,
      }}
    >
      {children}
    </InvoicesLogContext.Provider>
  );
}

export { InvoicesLogContextProvider };
