import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { serviceVariantsAdapter } from "./serviceVariantsSlice";

const { selectById, selectAll } = serviceVariantsAdapter.getSelectors(
  (state: RootState) => state.serviceVariantsV2
);

export const selectAllServiceVariants = selectAll;

export const selectAllActiveServiceVariants = (state: RootState) =>
  selectAllServiceVariants(state).filter((serviceVariant) => !serviceVariant.deprecated);

export const selectVariantsByServiceId = (serviceId: string | number) => (state: RootState) => {
  const allServiceVariants = selectAllServiceVariants(state);

  return allServiceVariants.filter(
    (serviceVariant) => serviceVariant.service === Number(serviceId) && !serviceVariant.deprecated
  );
};

export const selectServiceVariantById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectServiceVariantsLoading = (state: RootState) =>
  state.serviceVariantsV2.status === SliceStatus.LOADING;
