import http from "utils/http";

const employeesApi = {
  getAllEmployees: async () => http.get("employees/employees/"),

  getEmployee: async (id) => http.get(`employees/employees/${id}/`),

  createEmployee: async ({ data }) => http.post("employees/employees/", data),

  updateEmployee: async ({ id, data }) => http.put(`employees/employees/${id}/`, data),

  removeEmployee: async ({ id }) => http.delete(`employees/employees/${id}/`),
};

export default employeesApi;
