import { Dialog, Drawer } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { ReactNode } from "react";

type ResponsiveDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

const ResponsiveDetailsModal = ({
  isOpen,
  onClose: handleClose,
  children,
}: ResponsiveDetailsModalProps) => {
  const isMobileView = useIsMobileView();

  return isMobileView ? (
    <Drawer
      anchor="bottom"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: (theme) => theme.spacing(1, 1, 0, 0),
        },
      }}
    >
      {children}
    </Drawer>
  ) : (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      {children}
    </Dialog>
  );
};

export default ResponsiveDetailsModal;
