import { IconButton } from "@mui/material";
import { InfoDetailsIcon } from "assets/icon";
import humanizeDayDuration from "helpers/humanizeDayDuration";
import useVouchersWithRedeemableItemsTitle from "hooks/useVouchersWithRedeemableItemsTitle";
import VouchersDetailsModal from "pages/Offers/Vouchers/VouchersListPage/VouchersDetailsModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PurchasableItemOption } from "types/PurchasableItem";
import { Voucher } from "types/Voucher";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItem from "../PurchasableItem";

type VouchersPurchasableItemsProps = {
  vouchers: Voucher[];
};

const VouchersPurchasableItems = ({ vouchers }: VouchersPurchasableItemsProps) => {
  const { t } = useTranslation("checkout");
  const { onSelectItem, selectedItems, shouldHighlightSelectedItems } =
    usePurchasableItemsSelector();

  const selectedVoucherIds = selectedItems?.[PurchasableItemOption.Voucher] || [];

  const { vouchersWithRedeemableItemsTitle } = useVouchersWithRedeemableItemsTitle({ vouchers });

  const [isVouchersDetailsModalOpen, setIsVouchersDetailsModalOpen] = useState(false);
  const [selectedVoucherId, setSelectedVoucherId] = useState<Nullable<number>>(null);

  const handleCloseVouchersDetailsModal = () => {
    setIsVouchersDetailsModalOpen(false);
    setSelectedVoucherId(null);
  };

  const handleClickVoucherDetails = (voucherId: number) => {
    setSelectedVoucherId(voucherId);
    setIsVouchersDetailsModalOpen(true);
  };

  return (
    <>
      <VouchersDetailsModal
        isOpen={isVouchersDetailsModalOpen}
        onClose={handleCloseVouchersDetailsModal}
        voucherId={selectedVoucherId || 0}
      />

      {vouchersWithRedeemableItemsTitle?.map((voucher) => {
        const subtitle = `${voucher.redeemableItemsTitle}, ${t(
          "expiresIn"
        )} - ${humanizeDayDuration(voucher.validityInDays)}`;

        const shouldHighlight =
          shouldHighlightSelectedItems && selectedVoucherIds.includes(voucher.id);

        return (
          <PurchasableItem
            key={`Voucher-${voucher.id}-${voucher.name}`}
            isHighlighted={shouldHighlight}
            title={voucher.name}
            subtitle={subtitle}
            price={voucher.price}
            handleClick={() => onSelectItem(voucher, PurchasableItemOption.Voucher)}
            icon={
              <IconButton onClick={() => handleClickVoucherDetails(voucher.id)}>
                <InfoDetailsIcon color="primary" sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            }
          />
        );
      })}
    </>
  );
};

export default VouchersPurchasableItems;
