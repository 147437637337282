import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { onlineBookingStatusAdapter } from "./onlineBookingStatusSlice";

export const selectOnlineBookingStatusLoading = (state: RootState) =>
  state.onlineBookingStatus.status === SliceStatus.LOADING;

const { selectAll } = onlineBookingStatusAdapter.getSelectors(
  (state: RootState) => state.onlineBookingStatus
);

export const selectAllOnlineBookingStatuses = selectAll;
