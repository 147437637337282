import stringifyArray from "helpers/stringifyArray";
import { decamelize } from "humps";
import { PeepPayLinkStatus } from "types/Deposit";
import { PeepPayLink } from "types/PeepPayLink";
import http from "utils/http";

type GetPeepPayLinksResponse = {
  results: PeepPayLink[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
};

export enum PeepPayLinkType {
  Invoice = "INVOICE",
  Deposit = "DEPOSIT",
}

type GetPeepPayLinksArgs = {
  page: number;
  startDate: string;
  endDate: string;
  outletId: number;
  linkStatuses: PeepPayLinkStatus[];
  type: PeepPayLinkType;
  ordering: string[];
  searchQuery: string;
};

const peepPayLinksApi = {
  getPeepPayLinks: async ({
    page,
    startDate,
    endDate,
    outletId,
    type,
    linkStatuses: status,
    searchQuery,
    ordering,
  }: GetPeepPayLinksArgs) => {
    const decamelizedSortingList = ordering.map(decamelize);
    const stringifiedSortingList = decamelizedSortingList.join(",");

    const orderingParam =
      ordering && !!ordering.length ? `&order_by=${stringifiedSortingList}` : "";

    const searchQueryParam = searchQuery ? `&search=${searchQuery}` : "";
    const outletsParam = outletId ? `&outlets=${outletId}` : "";

    const statusParam =
      status && !!status.length ? `&status=${stringifyArray(status.join().split(","))}` : "";

    const typeParam = type && !!type.length ? `&type=${type}` : "";

    return http.get<GetPeepPayLinksResponse>(
      `peep_payments/invoices/?page=${page}&start_date=${startDate}&end_date=${endDate}${outletsParam}${statusParam}${typeParam}${orderingParam}${searchQueryParam}`
    );
  },
};

export default peepPayLinksApi;
