import { Remove } from "@bookpeep/ui";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ItemPrice } from "components/common";
import LoyaltyCardRewardChip from "components/common/Chips/LoyaltyCardRewardChip";
import StartsFromChip from "components/common/Chips/StartsFromChip";
import { checkIfArticleHasSpecialDiscount } from "helpers/articles";
import useCheckoutModal from "hooks/useCheckoutModal";
import useIsMobileView from "hooks/useIsMobileView";
import { memo } from "react";
import { useSelector } from "store";
import { selectAppointmentLoyaltyCardByAppointmentIdAndLoyaltyCardId } from "store/appointments/appointmentsSelectors";
import { selectActiveSubscriptionByClientIdAndOutletIdAndItemId } from "store/clients/clientsSlice";
import { selectServiceVariantById } from "store/selectors";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { PurchasableItemOption } from "types/PurchasableItem";
import { ServiceVariantPricingType } from "types/ServiceV2";
import { ProcessedSubscriptionPurchase } from "types/Subscription";
import { z } from "zod";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import { purchasableItemsSchema } from "../checkoutFormSchema";
import CartItemImage from "./CartItemImage";
import CheckoutCartItemRedeemableSubscriptions from "./CheckoutCartItemRedeemableSubscriptions";
import { getCartItemFields, getPurchasableItemId, getSubscriptionItemType } from "./utils";

export type PurchasableItemsFields = z.infer<typeof purchasableItemsSchema>;

type CheckoutCartItemProps = {
  purchasableItem: PurchasableItemsFields;
  purchasableItemType: PurchasableItemOption;

  cartItemIndex: number;

  disableRemove?: boolean;

  removeItem: () => void;
};

const CheckoutCartItem = ({
  purchasableItemType,
  cartItemIndex,
  removeItem,
  disableRemove = false,
}: CheckoutCartItemProps) => {
  const { checkoutModalView } = useCheckoutModal();
  const isMobileView = useIsMobileView();

  const { watch, balance } = useCheckoutFormContext();

  const { existingClient, outlet: outletId } = watch();
  const clientId = existingClient?.id;

  const article = watch(`articles.${cartItemIndex}`);
  const { appointment } = watch();
  const {
    purchasedItem,
    finalPrice,
    originalPrice,
    markedPrice,
    quantity = 1,
    subscriptionRedemptionId,
    subscriptionPurchaseId,
    rewardLoyaltyCard,
  } = article;

  const serviceVariant = useSelector(
    selectServiceVariantById(article.purchasedItem.serviceVariant || 0)
  );

  const rewardLoyaltyCardDetails = useSelector(
    selectAppointmentLoyaltyCardByAppointmentIdAndLoyaltyCardId(
      appointment || 0,
      rewardLoyaltyCard || 0
    )
  );
  const hasLoyaltyRewardRedemption = Boolean(rewardLoyaltyCard);

  const hasBeenBookedWithSubscriptionRedemption = !!subscriptionRedemptionId;

  // To avoid refunding money to the client, therefor, we disable applying the subscription redemption if the total balance would be negative, until we build the refunding feature
  const wouldApplyingRedemptionRequireRefunding = balance - finalPrice < 0;

  const shouldDisableRedeemingSubscription =
    hasBeenBookedWithSubscriptionRedemption ||
    wouldApplyingRedemptionRequireRefunding ||
    hasLoyaltyRewardRedemption;

  const shouldPriceFieldBeDisabled = !!subscriptionPurchaseId;

  const subscriptionItemType = getSubscriptionItemType(purchasableItemType);
  const subscriptionItemId = getPurchasableItemId(purchasedItem, purchasableItemType);

  const clientActiveSubscriptionsOfCartItem = useSelector(
    selectActiveSubscriptionByClientIdAndOutletIdAndItemId(
      clientId,
      outletId,
      subscriptionItemId,
      subscriptionItemType
    )
  ) as ProcessedSubscriptionPurchase[];

  const title = watch(`articles.${cartItemIndex}.title`) || "";
  const subtitle = watch(`articles.${cartItemIndex}.subtitle`) || "";
  const subtitleAdditionalInfo = watch(`articles.${cartItemIndex}.subtitleAdditionalInfo`) || "";

  const shouldShowRedeemableSubscriptions =
    !!existingClient?.id &&
    !!clientActiveSubscriptionsOfCartItem?.length &&
    (purchasableItemType === PurchasableItemOption.Service ||
      purchasableItemType === PurchasableItemOption.Package);

  const shouldDisableEditing = checkoutModalView === CheckoutModalView.ConfirmSale;

  const shouldShowRewardLoyaltyCardChip = !!rewardLoyaltyCard && !!rewardLoyaltyCardDetails;

  const shouldDisableRemoveItem =
    disableRemove || hasLoyaltyRewardRedemption || shouldDisableEditing;

  <CartItemImage purchasableItemType={purchasableItemType} />;
  const shouldShowCartImage =
    !isMobileView && checkIfPurchasableTypeHasAnImage(purchasableItemType);

  return (
    <Box
      bgcolor="bg.blank"
      border={1}
      borderRadius={1.25}
      borderColor="#4E5D783D"
      display="flex"
      sx={{ "&:not(:last-child)": { mb: 3 } }}
    >
      {shouldShowCartImage && <CartItemImage purchasableItemType={purchasableItemType} />}

      <Grid container p={{ xs: 2, md: 3 }} justifyContent="space-between" alignItems="center">
        {shouldShowRewardLoyaltyCardChip && (
          <Grid item xs={12} pb={2.5}>
            <LoyaltyCardRewardChip programName={rewardLoyaltyCardDetails?.name} />
          </Grid>
        )}

        <Grid item md={8} xs={8}>
          <Typography variant="h5" noWrap>
            {title}
          </Typography>

          <Typography variant="secondary" fontSize="0.875rem" color="text.label" pt={1}>
            {subtitle}

            <Typography
              variant="secondary"
              fontSize="0.875rem"
              color="primary.main"
              display="inline"
              px={1}
            >
              {subtitleAdditionalInfo}
            </Typography>
          </Typography>
        </Grid>

        <Grid item md={4} xs={4} display="flex" justifyContent="end" alignItems="center" gap={2}>
          {serviceVariant?.pricingType === ServiceVariantPricingType.From && (
            <StartsFromChip price={Number(originalPrice)} />
          )}

          <ItemPrice
            quantity={quantity}
            originalPrice={
              checkIfArticleHasSpecialDiscount({
                markedPrice: article.markedPrice || 0,
                originalPrice: article.originalPrice,
                finalPrice: article.finalPrice,
              })
                ? Number(originalPrice)
                : Number(markedPrice)
            }
            finalPrice={Number(finalPrice)}
          />

          <IconButton disabled={shouldDisableRemoveItem} onClick={removeItem} color="error">
            <Remove />
          </IconButton>
        </Grid>

        <Grid item container xs={12} spacing={2} pt={3}>
          {getCartItemFields(
            purchasableItemType,
            cartItemIndex,
            shouldPriceFieldBeDisabled,
            shouldDisableEditing
          )}
        </Grid>

        {shouldShowRedeemableSubscriptions && (
          <Grid item container xs={12} spacing={2} pt={3}>
            <CheckoutCartItemRedeemableSubscriptions
              clientActiveSubscriptionsOfCartItem={clientActiveSubscriptionsOfCartItem}
              cartItemIndex={cartItemIndex}
              shouldDisableRedeemingSubscription={shouldDisableRedeemingSubscription}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default memo(CheckoutCartItem);

const checkIfPurchasableTypeHasAnImage = (purchasableItemType: PurchasableItemOption) => {
  return [PurchasableItemOption.Subscription, PurchasableItemOption.Voucher].includes(
    purchasableItemType
  );
};
