import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import RedeemVoucherButton from "./RedeemVoucherButton";

const CheckoutVouchersSection = () => {
  const { t } = useTranslation("vouchers");

  return (
    <Box p={3} boxShadow="divider">
      <Typography variant="secondary" fontWeight={700}>
        {t("vouchers").toUpperCase()}
      </Typography>

      <Box pt={1}>
        <RedeemVoucherButton />
      </Box>
    </Box>
  );
};

export default CheckoutVouchersSection;
