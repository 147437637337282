import { RootState } from "store";

import { SliceStatus } from "../utils";
import { clientAnalyticsAdapter } from "./clientAnalyticsSlice";

export const selectClientAnalyticsLoading = (state: RootState) =>
  state.clientAnalytics.status === SliceStatus.LOADING;

export const selectClientAnalyticsTotalPages = (state: RootState) =>
  state.clientAnalytics.totalPages;

export const selectClientAnalyticsCount = (state: RootState) => state.clientAnalytics.count;

export const selectClientAnalyticsState = (state: RootState) => state.clientAnalytics;

const { selectAll } = clientAnalyticsAdapter.getSelectors(selectClientAnalyticsState);

export const selectAllClientAnalytics = selectAll;
