import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SliceStatus } from "../utils";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { default as api } from "./zonesApi";
import { Zone } from "types/Zone";

export const zonesAdapter = createEntityAdapter<Zone>({
  selectId: (zone) => zone.id,
});

const initialState = zonesAdapter.getInitialState<{
  status: SliceStatus;
  zones: Zone[];
  isRequestPending: SliceStatus;
}>({
  status: SliceStatus.IDLE,
  zones: [],
  isRequestPending: SliceStatus.IDLE,
});

export const getZones = createThunkFromApiWithType("zones/getZones", api.getZones);

export const createZone = createThunkFromApiWithType("zones/createZone", api.createZone);

export const updateZone = createThunkFromApiWithType("zones/updateZone", api.updateZone);

export const updateZoneBookingStatus = createThunkFromApiWithType("zones/updateZoneBookingStatus", api.updateOnlineBookingsStatus);

export const deprecateZone = createThunkFromApiWithType("zones/deprecateZone", api.deprecateZone);

export const updateWorkingHours = createThunkFromApiWithType(
  "zones/updateWorkingHours",
  api.updateWorkingHours
);

export const zonesSlice = createSlice({
  name: "zones",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getZones.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getZones.fulfilled, (state, action) => {
        zonesAdapter.setAll(
          state,
          action.payload.map((zone) => ({
            ...zone,
            minimumCharge: Number(zone.minimumCharge),
          }))
        );
        state.status = SliceStatus.IDLE;
      })
      .addCase(getZones.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(createZone.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(createZone.fulfilled, (state, { payload }) => {
        zonesAdapter.upsertOne(state, {
          ...payload,
          minimumCharge: Number(payload.minimumCharge),
        });
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(createZone.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(updateZone.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateZone.fulfilled, (state, { payload }) => {
        zonesAdapter.upsertOne(state, {
          ...payload,
          minimumCharge: Number(payload.minimumCharge),
        });
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateZone.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(deprecateZone.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(
        deprecateZone.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => {
          zonesAdapter.removeOne(state, id);
          state.status = SliceStatus.IDLE;
        }
      )

      .addCase(deprecateZone.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(updateWorkingHours.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateWorkingHours.fulfilled, (state, action) => {
        const zone = state.entities[action.meta.arg.zoneId];
        if (zone) {
          const workingHours = zone.workingHours.map((workingHour) => ({
            ...workingHour,
            ...action.payload.find((newWorkingHour) => newWorkingHour.id === workingHour.id),
          }));

          zone.workingHours = workingHours;
          zonesAdapter.upsertOne(state, zone);
        }
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateWorkingHours.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })
      .addCase(updateZoneBookingStatus.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateZoneBookingStatus.fulfilled, (state, { payload }) => {
        zonesAdapter.upsertOne(state, payload);
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateZoneBookingStatus.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      });
  },
});

export default zonesSlice.reducer;
