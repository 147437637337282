import { Close } from "@bookpeep/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, IconButton } from "@mui/material";
import ResponsiveModal from "components/common/Modals/ResponsiveModal";
import useResetForm from "hooks/useResetForm";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormActionButtons from "./FormActionButtons";
import GiftForm from "./GiftForm";
import GiftFormClientSection from "./GiftFormClientSection";
import voucherGiftSchema, { VoucherGiftFields } from "./voucherGiftSchema";

type VouchersGiftModalProps = {
  voucherPurchaseId: number;
  isGiftModalOpen: boolean;
  setIsGiftModalOpen: Dispatch<SetStateAction<boolean>>;
};

const VouchersGiftModal = ({
  voucherPurchaseId,
  isGiftModalOpen,
  setIsGiftModalOpen,
}: VouchersGiftModalProps) => {
  const { t } = useTranslation("vouchers");

  const methods = useForm<VoucherGiftFields>({
    defaultValues: {
      voucherPurchase: 0,
      message: "",
      email: "",
      existingClient: null,
      newClient: null,
    },
    resolver: zodResolver(voucherGiftSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { watch, reset, setValue } = methods;

  useEffect(() => {
    setValue("voucherPurchase", voucherPurchaseId);
  }, [setValue, voucherPurchaseId]);

  const client = watch("existingClient") || watch("newClient");

  const resetForm = useResetForm(reset, () => setIsGiftModalOpen(false));

  return (
    <FormProvider {...methods}>
      <ResponsiveModal
        title={t("giftVoucher")}
        onClose={() => setIsGiftModalOpen(false)}
        open={isGiftModalOpen}
        mobileActions={
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsGiftModalOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        <form>
          <Box>
            <GiftFormClientSection />

            {!!client && (
              <Box display="flex" flexDirection="column" width="100%" alignItems="center" pt={2}>
                <GiftForm />
              </Box>
            )}

            <FormActionButtons onClose={resetForm} />
          </Box>
        </form>
      </ResponsiveModal>
    </FormProvider>
  );
};

export default VouchersGiftModal;
