import debounce from "@mui/utils/debounce";
import { formatDate, timePeriodOptions } from "components/Sales/utils";
import useIsMobileView from "hooks/useIsMobileView";
import { decamelize } from "humps";
import { DateTime } from "luxon";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "store";
import {
  appendAllAppointmentsOverview,
  getAllAppointmentsOverview,
} from "store/slices/appointmentsOverview/appointmentsOverviewSlice";
import { AppointmentSource } from "types/Appointment";

export enum AppointmentDurationOptions {
  Today = "TODAY",
  Upcoming = "UPCOMING",
  Past = "PAST",
  Custom = "CUSTOM",
}

export enum DepositBooleanOptions {
  Yes = "YES",
  No = "NO",
}

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type InitialStateType = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;

  dateRange: string | DateTime[];
  setDateRange: Dispatch<SetStateAction<string | DateTime[]>>;

  outletId: number;
  setOutletId: Dispatch<SetStateAction<number>>;

  duration: string;
  setDuration: Dispatch<SetStateAction<string>>;

  statusOptions: string[];
  setStatusOptions: Dispatch<SetStateAction<string[]>>;

  invoiceStatusOptions: string[];
  setInvoiceStatusOptions: Dispatch<SetStateAction<string[]>>;

  bookingSourceOptions: AppointmentSource[];
  setBookingSourceOptions: Dispatch<SetStateAction<AppointmentSource[]>>;

  depositOptions: string[];
  setDepositOptions: Dispatch<SetStateAction<string[]>>;

  ordering: string[];
  setOrdering: Dispatch<SetStateAction<string[]>>;

  createdBy: number[];
  setCreatedBy: Dispatch<SetStateAction<number[]>>;

  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  areas: number[];
  setAreas: Dispatch<SetStateAction<number[]>>;

  zone: number;
  setZone: Dispatch<SetStateAction<number>>;

  handleInputChange: (value: string) => void;

  handleLoadMore: (value: number) => void;

  handlePageChange: (value: number) => void;

  clearAllFilters: () => void;
};

const initialState = {
  page: 1,
  setPage: unimplementedFunction,

  dateRange: timePeriodOptions[0][1],
  setDateRange: unimplementedFunction,

  outletId: 0,
  setOutletId: unimplementedFunction,

  duration: AppointmentDurationOptions.Today,
  setDuration: unimplementedFunction,

  statusOptions: [],
  setStatusOptions: unimplementedFunction,

  invoiceStatusOptions: [],
  setInvoiceStatusOptions: unimplementedFunction,

  createdBy: [],
  setCreatedBy: unimplementedFunction,

  bookingSourceOptions: [],
  setBookingSourceOptions: unimplementedFunction,

  depositOptions: [],
  setDepositOptions: unimplementedFunction,

  ordering: [],
  setOrdering: unimplementedFunction,

  searchQuery: "",
  setSearchQuery: unimplementedFunction,

  areas: [],
  setAreas: unimplementedFunction,

  zone: 0,
  setZone: unimplementedFunction,

  searchQueryAsParam: "",
  setSearchQueryAsParam: unimplementedFunction,

  handleInputChange: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  handlePageChange: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const AppointmentsOverviewContext = createContext<InitialStateType>(initialState);

export default AppointmentsOverviewContext;

type AppointmentsOverviewContextProviderProps = {
  children: ReactNode;
};

function AppointmentsOverviewContextProvider({
  children,
}: AppointmentsOverviewContextProviderProps) {
  const dispatch = useDispatch();

  const isMobileView = useIsMobileView();

  const [page, setPage] = useState<number>(initialState.page);

  const [dateRange, setDateRange] = useState(initialState.dateRange);

  const [outletId, setOutletId] = useState<number>(initialState.outletId);

  const [zone, setZone] = useState<number>(initialState.zone);

  const [duration, setDuration] = useState<string>(initialState.duration);

  const [statusOptions, setStatusOptions] = useState<string[]>(initialState.statusOptions);

  const [areas, setAreas] = useState<number[]>(initialState.statusOptions);

  const [invoiceStatusOptions, setInvoiceStatusOptions] = useState<string[]>(
    initialState.invoiceStatusOptions
  );

  const [createdBy, setCreatedBy] = useState<number[]>([]);

  const [bookingSourceOptions, setBookingSourceOptions] = useState<AppointmentSource[]>(
    initialState.bookingSourceOptions
  );

  const [depositOptions, setDepositOptions] = useState<string[]>(initialState.depositOptions);

  const [ordering, setOrdering] = useState<string[]>(initialState.ordering);

  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  // the query that will be sent to the backend request after debouncing
  const [searchQueryAsParam, setSearchQueryAsParam] = useState<string>(
    initialState.searchQueryAsParam
  );

  const triggerDebouncedFetch = useMemo(
    () =>
      debounce((searchQuery) => {
        setSearchQueryAsParam(searchQuery);
        setPage(1);
      }, 800),
    []
  );

  const fetchAppointments = useCallback(triggerDebouncedFetch, [triggerDebouncedFetch]);

  const handleInputChange = (newValue: string) => {
    setSearchQuery(newValue);
    fetchAppointments(newValue);
  };

  const handleLoadMore = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
    setZone(0);
    setOutletId(0);
    setInvoiceStatusOptions([]);
    setBookingSourceOptions([]);
    setDepositOptions([]);
    setCreatedBy([]);
  };

  useEffect(() => {
    const decamelizedSortingList = ordering.map(decamelize);

    const startDate = formatDate(dateRange[0]);
    const endDate = formatDate(dateRange[1]);

    if (isMobileView && page !== 1) {
      dispatch(
        appendAllAppointmentsOverview({
          page,
          startDate,
          endDate,
          outletId,
          zone,
          duration,
          statusOptions,
          invoiceStatusOptions: invoiceStatusOptions.map((status) => status.toUpperCase()),
          bookingSourceOptions,
          depositOptions,
          ordering: decamelizedSortingList,
          searchQuery: searchQueryAsParam,
          createdBy,
          areas,
        })
      );
    } else {
      dispatch(
        getAllAppointmentsOverview({
          page,
          startDate,
          endDate,
          outletId,
          zone,
          duration,
          statusOptions,
          areas,
          invoiceStatusOptions: invoiceStatusOptions.map((status) => status.toUpperCase()),
          bookingSourceOptions,
          depositOptions,
          ordering: decamelizedSortingList,
          searchQuery: searchQueryAsParam,
          createdBy,
        })
      );
    }
  }, [
    dispatch,
    zone,
    areas,
    isMobileView,
    page,
    dateRange,
    outletId,
    duration,
    statusOptions,
    invoiceStatusOptions,
    depositOptions,
    ordering,
    searchQueryAsParam,
    createdBy,
    bookingSourceOptions,
  ]);

  return (
    <AppointmentsOverviewContext.Provider
      value={{
        page,
        setPage,

        dateRange,
        setDateRange,

        outletId,
        setOutletId,

        duration,
        setDuration,

        statusOptions,
        setStatusOptions,

        invoiceStatusOptions,
        setInvoiceStatusOptions,

        bookingSourceOptions,
        setBookingSourceOptions,

        depositOptions,
        setDepositOptions,

        ordering,
        setOrdering,

        areas,
        setAreas,

        zone,
        setZone,

        createdBy,
        setCreatedBy,

        searchQuery,
        setSearchQuery,

        handleInputChange,

        handleLoadMore,

        handlePageChange,

        clearAllFilters,
      }}
    >
      {children}
    </AppointmentsOverviewContext.Provider>
  );
}

export { AppointmentsOverviewContextProvider };
