/**
 * Checks if the text contains any Arabic characters
 * @param text
 * @returns True if the text contains Arabic characters, otherwise false
 */
function containsArabic(text: string): boolean {
  const arabicPattern = /[\u0600-\u06FF]/;
  return arabicPattern.test(text);
}

/**
 * Reverses the digits in the given text.
 * @param text
 * @returns The text with digits reversed
 */
function reverseDigits(text: string): string {
  const digitPattern = /[0-9\u0660-\u0669\u06F0-\u06F9]/g;
  const digits = text.match(digitPattern);

  if (!digits) {
    return text; // No digits to reverse
  }

  const reversedDigits = digits.reverse();
  let digitIndex = 0;
  const result = text.replace(digitPattern, () => reversedDigits[digitIndex++]);

  return result;
}

/**
 * Processes the text by checking for Arabic characters and reversing the digits if found.
 * This is needed for rendering Arabic text in libraries like react-pdf-renderer.
 * @param text
 * @returns The processed text with digits reversed if it contains Arabic characters
 */
export function processText(text: string): string {
  if (containsArabic(text)) {
    return reverseDigits(text);
  }
  return text;
}
