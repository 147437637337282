import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { OnlineBookingCircleIcon, PauseIcon, PlayIcon } from "assets/icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { updateZoneBookingStatus } from "store/slices/zones/zonesSlice";

type OnlineBookingStatusItemProps = {
  zoneId: number;
  zoneName: string;
  isAcceptingOnlineBookings: boolean;
};

const ZoneOnlineBookingStatusItem = ({
  zoneId,
  zoneName,
  isAcceptingOnlineBookings,
}: OnlineBookingStatusItemProps) => {
  const { t } = useTranslation("onlineBooking");
  const dispatch = useDispatch();

  const [isRequestPending, setIsRequestPending] = useState(false);

  const handleToggleOnlineBookingStatus = () => {
    setIsRequestPending(true);

    dispatch(
      updateZoneBookingStatus({
        zoneId,
        isAcceptingOnlineBookings: !isAcceptingOnlineBookings,
      })
    )
      .unwrap()
      .then(() => {
        setIsRequestPending(false);
      })
      .catch((error) => {
        setIsRequestPending(false);
      });
  };

  return (
    <Box display="flex" alignItems="center" gap={2} sx={{ "&:not(:last-child)": { mb: 2 } }}>
      <OnlineBookingCircleIcon
        viewBox="0 0 12 12"
        sx={{
          width: (theme) => theme.spacing(1.25),
          height: (theme) => theme.spacing(1.25),
          color: isAcceptingOnlineBookings ? "#53BFB0" : "#FFC166",
        }}
      />

      <Typography variant="primary" fontWeight={500} flex={1}>
        {zoneName}
        <Typography variant="primary" fontWeight={500} color="text.placeholder" pl={1}>
          {isAcceptingOnlineBookings ? t("active") : t("paused")}
        </Typography>
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleToggleOnlineBookingStatus}
            disabled={isRequestPending}
            sx={{
              p: 0,
            }}
          >
            {isAcceptingOnlineBookings ? (
              <PauseIcon viewBox="0 0 20 20" sx={{ color: "#848FA1" }} />
            ) : (
              <PlayIcon viewBox="0 0 20 20" sx={{ color: "#848FA1" }} />
            )}
          </IconButton>

          {isRequestPending && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ZoneOnlineBookingStatusItem;
