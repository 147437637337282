import { Box, BoxProps, styled } from "@mui/material";
import { Add, PromotionIcon } from "assets/icon";
import { dontForwardProps } from "helpers/styles";
import { ReactNode } from "react";

import PurchasableItemPrice from "./PurchasableItemPrice";
import PurchasableItemTitleContent from "./PurchasableItemTitleContent";

type PurchasableItemProps = {
  title: string;
  subtitle?: string | ReactNode;
  descriptionTitle?: string;
  description?: string;

  price: string | number;
  shouldShowPricingFrom?: boolean;

  shouldShowSubscriptionTag?: boolean;
  shouldShowPromotionTag?: boolean;

  isSingleVariant?: boolean;
  isHighlighted?: boolean;

  isDisabled?: boolean;
  helperText?: string;

  handleClick: () => void;

  icon?: ReactNode;
};

const PurchasableItem = ({
  title,
  subtitle = "",
  descriptionTitle = "",
  description = "",

  price,
  shouldShowPricingFrom,

  shouldShowSubscriptionTag = false,
  shouldShowPromotionTag = false,

  isSingleVariant = true,
  isHighlighted = false,

  isDisabled = false,
  helperText = "",

  handleClick,

  icon,
}: PurchasableItemProps) => {
  return (
    <PurchasableItemContainer
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      isSingleVariant={isSingleVariant}
    >
      <PurchasableItemBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={isDisabled ? () => {} : handleClick}
        py={1}
        px={2}
        columnGap={1}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
      >
        <Box display="flex" alignItems="center" columnGap={2}>
          <Add />

          <PurchasableItemTitleContent
            title={title}
            subtitle={subtitle}
            description={description}
            descriptionTitle={descriptionTitle}
            isSingleVariant={isSingleVariant}
            shouldShowSubscriptionTag={shouldShowSubscriptionTag}
            helperText={isDisabled ? helperText : ""}
          />
        </Box>

        <Box display="inline-flex" columnGap={1} alignItems="center">
          <PurchasableItemPrice
            price={price}
            isSingleVariant={isSingleVariant}
            shouldShowPricingFrom={shouldShowPricingFrom}
          />

          {shouldShowPromotionTag && <PromotionIcon sx={{ width: 16, height: 16 }} />}
        </Box>
      </PurchasableItemBox>

      {icon && <Box pl={1}>{icon}</Box>}
    </PurchasableItemContainer>
  );
};

export default PurchasableItem;

const PurchasableItemContainer = styled(
  Box,
  dontForwardProps("isSingleVariant")
)<BoxProps & { isSingleVariant: PurchasableItemProps["isSingleVariant"] }>(
  ({ theme, isSingleVariant }) => ({
    padding: theme.spacing(0, 2),
    "&:not(:last-child)": {
      marginBottom: theme.spacing(isSingleVariant ? 2 : 1),
    },
  })
);

type PurchasableItemBoxProps = BoxProps & {
  isHighlighted: PurchasableItemProps["isHighlighted"];
  isDisabled: PurchasableItemProps["isDisabled"];
};
const PurchasableItemBox = styled(
  Box,
  dontForwardProps("isHighlighted", "isDisabled")
)<PurchasableItemBoxProps>(({ theme, isHighlighted, isDisabled }) => ({
  background: isHighlighted
    ? theme.palette.primary.dark
    : isDisabled
    ? `${(theme.palette.bg as any).disabled} !important`
    : "#FBFBFB",

  borderRadius: theme.shape.borderRadius,

  flex: 1,

  cursor: isDisabled ? "not-allowed" : "pointer",

  "& .MuiSvgIcon-root": {
    color: isHighlighted ? theme.palette.common.white : theme.palette.text.placeholder,
    fontSize: "1.1rem",
  },

  "& .MuiTypography-root": {
    color: isHighlighted ? theme.palette.common.white : theme.palette.text.primary,
  },

  "&:hover": isDisabled
    ? {}
    : {
        backgroundColor: isHighlighted ? theme.palette.text.primary : "#F3F4F6", // a new color from hell -> add to UI Package Palette

        "& .MuiSvgIcon-root": {
          color: isHighlighted ? "#F3F4F6" : theme.palette.primary.main,
          fontSize: "1.5rem",
        },
      },

  "&:active": isDisabled
    ? {}
    : {
        backgroundColor: theme.palette.primary.dark,

        "& .MuiTypography-root": {
          color: theme.palette.common.white,
        },

        "& .MuiSvgIcon-root": {
          color: theme.palette.common.white,
        },
      },
}));
