import http from "utils/http";

const articlesApi = {
  getAllArticles: async () => http.get("appointments/articles/"),

  getArticle: async (id) => http.get(`appointments/articles/${id}/`),

  createArticle: async (data) => http.post("appointments/articles/", data),

  updateArticle: async (id, data) =>
    http.put(`appointments/articles/${id}/`, data),

  removeArticle: async (id) => http.delete(`appointments/articles/${id}/`),
};

export default articlesApi;
