import { Grid } from "@mui/material";
import { DiscountField, PriceField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import useCheckoutModal from "hooks/useCheckoutModal";
import useFormValidation from "hooks/useFormValidation";
import EmployeeAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/EmployeeAutoComplete";
import ResourceAutoComplete from "pages/CalendarPage/components/AppointmentForm/ServiceSection/SlotForm/ResourceAutoComplete";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import {
  selectAllServiceVariantActivePromotionsByServiceVariantIdBasedOnDate,
  selectServiceById,
  selectServiceVariantPromotionByServiceVariantIdAndPromotionId,
} from "store/selectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";
import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";
import { DiscountOption } from "types/promotions";
import { ServiceVariantPricingType } from "types/ServiceV2";
import { isServiceVariantActivePromotion } from "types/typeGuards/ActivePromotionsTypeGuards";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPromotionSelectField from "./CheckoutPromotionSelectField";

type CheckoutCartItemServiceFieldsProps = {
  cartItemIndex: number;
  isPriceChangeDisabled: boolean;
  shouldDisableEditing: boolean;
};

const CheckoutCartItemServiceFields = ({
  cartItemIndex,
  isPriceChangeDisabled,
  shouldDisableEditing,
}: CheckoutCartItemServiceFieldsProps) => {
  const { t } = useTranslation("checkout");

  const { outletId } = useCheckoutModal();

  const { hasDiscount } = useSelector(selectAllPermissions);

  const { setValue, formState, watch, date } = useCheckoutFormContext();

  const registerValidation = useFormValidation(formState, { ns: "checkout" });

  const appliedClientSpecialDiscount = watch("appliedClientSpecialDiscount");

  const hasAppliedClientSpecialDiscount = appliedClientSpecialDiscount > 0;

  const articleIndex: `articles.${number}` = `articles.${cartItemIndex}`;

  const article = watch(articleIndex);

  const employeeFieldPath: `articles.${number}.details.${number}.saleEmployee` = `${articleIndex}.details.0.saleEmployee`;

  const resourceFieldPath: `articles.${number}.details.${number}.resource` = `${articleIndex}.details.0.resource`;

  const markedPriceFieldPath: `articles.${number}.markedPrice` = `${articleIndex}.markedPrice`;
  const finalPriceFieldPath: `articles.${number}.finalPrice` = `${articleIndex}.finalPrice`;

  const promotionIdFieldPath: `articles.${number}.promotionId` = `${articleIndex}.promotionId`;

  const {
    discountValue,
    promotionId = null,
    originalPrice,
    markedPrice,
    subscriptionPurchaseId,
    rewardLoyaltyCard,
  } = article;

  const discountValueFieldPath: `articles.${number}.discountValue` = `${articleIndex}.discountValue`;

  const serviceVariantId = watch(`${articleIndex}.purchasedItem.serviceVariant`);

  const serviceVariantActivePromotions = useSelector(
    selectAllServiceVariantActivePromotionsByServiceVariantIdBasedOnDate(
      serviceVariantId || 0,
      date
    )
  );

  const selectedPromotion = useSelector(
    selectServiceVariantPromotionByServiceVariantIdAndPromotionId(
      serviceVariantId || 0,
      promotionId || 0
    )
  );

  const isSelectedPromotionInActivePromotions = !!serviceVariantActivePromotions.find(
    (activePromotion) => activePromotion.promotion === promotionId
  );

  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId || 0));

  const service = useSelector(selectServiceById(serviceVariant?.service || 0));

  const isResourceRequired = service?.needResources;

  const selectedStaff = watch(`${employeeFieldPath}`);

  const selectedResource = watch(resourceFieldPath);

  const canIncreasePrice = serviceVariant?.pricingType === ServiceVariantPricingType.From;

  const hasLoyaltyRewardRedemption = Boolean(rewardLoyaltyCard);

  const canApplyDiscount =
    hasDiscount.editAccess &&
    !isPriceChangeDisabled &&
    !hasAppliedClientSpecialDiscount &&
    !hasLoyaltyRewardRedemption;

  const maxPrice = canIncreasePrice ? Infinity : originalPrice;
  const minPrice = canApplyDiscount ? 0 : originalPrice;

  const canModifyPrice =
    maxPrice !== minPrice && !isPriceChangeDisabled && !hasAppliedClientSpecialDiscount;

  const canApplyPromotion =
    !subscriptionPurchaseId && !hasAppliedClientSpecialDiscount && !hasLoyaltyRewardRedemption;

  const onStaffChange = (staffId: number | null) => {
    setValue(`${employeeFieldPath}`, staffId || undefined);
  };

  const onResourceChange = (resourceId: number | null) => {
    setValue(`${resourceFieldPath}`, resourceId || undefined);
  };

  const handleDiscountChange = (value: number) => {
    setValue(discountValueFieldPath, value);

    const discountedPrice = markedPrice * ((100 - value) / 100);

    setValue(finalPriceFieldPath, discountedPrice);
    setValue("payments", []);
    setValue("voucherRedemptions", []);
    setValue(promotionIdFieldPath, null);
  };

  const handleFinalPriceChange = (value: number) => {
    // manage discount when final price has increased price to more than original price
    if (value > originalPrice) {
      if (!hasLoyaltyRewardRedemption) setValue(discountValueFieldPath, 0);

      if (canIncreasePrice) {
        setValue(markedPriceFieldPath, value);
      }
    } else {
      // has reduced price
      if (canApplyDiscount) {
        const discountedPercentage = 100 - (value / originalPrice) * 100;

        setValue(discountValueFieldPath, Number(discountedPercentage.toFixed(2)));
        setValue(promotionIdFieldPath, null);
        setValue(markedPriceFieldPath, originalPrice);
      }
    }

    const newFinalPriceBasedOnLoyaltyReward = getFinalPriceAfterDiscount({
      originalPrice: value,
      discountOption: DiscountOption.Percentage,
      discountValue,
    });

    const newFinalPrice = hasLoyaltyRewardRedemption ? newFinalPriceBasedOnLoyaltyReward : value;
    setValue(finalPriceFieldPath, newFinalPrice);

    setValue("payments", []);
    setValue("voucherRedemptions", []);
  };

  if (!serviceVariant) return <CentredSpinnerBox />;

  const handlePromotionChange = (
    value: Nullable<
      ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
    >
  ) => {
    if (value) {
      if (isServiceVariantActivePromotion(value)) {
        const finalPriceAfterPromotion = getFinalPriceAfterDiscount({
          originalPrice: markedPrice,
          discountOption: value.discountType,
          discountValue: value.value,
        });

        setValue(promotionIdFieldPath, value.promotion);
        setValue(finalPriceFieldPath, finalPriceAfterPromotion);
        setValue(discountValueFieldPath, 0);
      }
    } else {
      setValue(promotionIdFieldPath, null);
      setValue(finalPriceFieldPath, markedPrice);
      setValue(discountValueFieldPath, 0);
    }
  };

  return (
    <>
      <Grid item md={isResourceRequired ? 6 : 12} xs={12}>
        <EmployeeAutoComplete
          outletId={outletId}
          serviceVariantIdToFilterBy={serviceVariantId}
          value={selectedStaff || null}
          onChange={onStaffChange}
          {...registerValidation(employeeFieldPath, "staff")}
          disabled={shouldDisableEditing}
        />
      </Grid>

      {isResourceRequired && (
        <Grid item md={6} xs={12}>
          <ResourceAutoComplete
            label={t("resource")}
            outletId={outletId}
            serviceId={service.id}
            value={selectedResource || null}
            handleSelectValue={onResourceChange}
            {...registerValidation(resourceFieldPath, "resource")}
            disabled={shouldDisableEditing}
          />
        </Grid>
      )}

      {serviceVariant.pricingType !== ServiceVariantPricingType.Free && (
        <>
          <Grid item md={3} xs={12}>
            <PriceField
              label={t("price")}
              fullWidth
              value={markedPrice}
              max={maxPrice}
              min={minPrice}
              fallbackValue={originalPrice}
              {...registerValidation(finalPriceFieldPath)}
              onChange={handleFinalPriceChange}
              disabled={!canModifyPrice || shouldDisableEditing}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <DiscountField
              value={discountValue}
              onChange={handleDiscountChange}
              label={t("discount")}
              fullWidth
              disabled={!canApplyDiscount || shouldDisableEditing}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <CheckoutPromotionSelectField
              value={promotionId}
              onChange={handlePromotionChange}
              promotions={
                !isSelectedPromotionInActivePromotions && selectedPromotion
                  ? [...serviceVariantActivePromotions, selectedPromotion]
                  : serviceVariantActivePromotions
              }
              arePromotionsLoading={false}
              disabled={!canApplyPromotion || shouldDisableEditing}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default CheckoutCartItemServiceFields;
