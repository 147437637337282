import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Promotion } from "types/promotions";

import { SliceStatus } from "../utils";
import api from "./promotionsApi";

export const promotionsAdapter = createEntityAdapter<Promotion>({
  selectId: (promotion) => promotion.id,
});

const initialState = promotionsAdapter.getInitialState<{
  status: SliceStatus;
  detailStatus: SliceStatus;
  count: number;
  next: string;
  previous: string;
  totalPages: number;
  results: Promotion[];
}>({
  status: SliceStatus.IDLE,
  count: 0,
  detailStatus: SliceStatus.IDLE,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
});

export const getPromotions = createThunkFromApiWithType(
  "promotions/getPromotions",
  api.getPromotions
);

export const appendPromotions = createThunkFromApiWithType(
  "promotions/appendPromotions",
  api.getPromotions
);

export const createPromotion = createThunkFromApiWithType(
  "promotions/createPromotion",
  api.createPromotion
);

export const updatePromotion = createThunkFromApiWithType(
  "promotions/updatePromotion",
  api.updatePromotion
);

export const deletePromotion = createThunkFromApiWithType(
  "promotions/deletePromotion",
  api.deletePromotion
);

export const scheduleUpdatePromotion = createThunkFromApiWithType(
  "promotions/scheduleUpdatePromotion",
  api.scheduleUpdatePromotion
);

export const getPromotionDetails = createThunkFromApiWithType(
  "promotions/getPromotionDetails",
  api.getPromotionDetails
);

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Get all promotions reducers
    reducers.addCase(getPromotions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getPromotions.fulfilled, (state, { payload }) => {
      promotionsAdapter.setAll(state, payload.results);

      state.totalPages = payload.totalPages;
      state.count = payload.count;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getPromotions.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Append promotions reducers
    reducers.addCase(appendPromotions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendPromotions.fulfilled, (state, { payload }) => {
      promotionsAdapter.upsertMany(state, payload.results);

      state.totalPages = payload.totalPages;
      state.count = payload.count;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendPromotions.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // Create promotion reducers
    reducers.addCase(createPromotion.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createPromotion.fulfilled, (state, { payload }) => {
      promotionsAdapter.upsertOne(state, payload);

      state.count = state.count + 1;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createPromotion.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Update promotion reducer
    reducers.addCase(updatePromotion.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updatePromotion.fulfilled, (state, { payload }) => {
      promotionsAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updatePromotion.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Delete promotion reducer
    reducers.addCase(deletePromotion.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deletePromotion.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        promotionsAdapter.removeOne(state, id);

        state.count = state.count - 1;

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deletePromotion.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // GET promotion details reducers
    reducers.addCase(getPromotionDetails.pending, (state) => {
      state.detailStatus = SliceStatus.LOADING;
    });

    reducers.addCase(getPromotionDetails.fulfilled, (state, { payload }) => {
      promotionsAdapter.upsertOne(state, payload);
      state.detailStatus = SliceStatus.IDLE;
    });

    reducers.addCase(getPromotionDetails.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    // Schedule update promotion reducers
    reducers.addCase(scheduleUpdatePromotion.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(scheduleUpdatePromotion.fulfilled, (state, { payload }) => {
      promotionsAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(scheduleUpdatePromotion.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default promotionsSlice.reducer;
