import { Modal } from "@bookpeep/ui";
import useIsMobileView from "hooks/useIsMobileView";

import { FullWidthModal } from "./FullWidthModal";

function ResponsiveModal({ title, children, onClose, open, mobileActions }) {
  const isMobileView = useIsMobileView();

  return (
    <>
      {isMobileView ? (
        <FullWidthModal actions={mobileActions} open={open} title={title} onClose={onClose}>
          {children}
        </FullWidthModal>
      ) : (
        <Modal onClose={onClose} open={open} title={title}>
          {children}
        </Modal>
      )}
    </>
  );
}

export default ResponsiveModal;
