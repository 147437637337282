import stringifyArray from "helpers/stringifyArray";
import { Expense } from "types/Expense";
import http from "utils/http";

type ExpenseResponse = {
  results: Expense[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  totalAmount: number;
};

export type ExpenseCreateArguments = Omit<
  Expense,
  "id" | "createdBy" | "deletedNotes" | "deletedBy" | "deletedAt"
>;

type ExpenseUpdateArguments = {
  deletedNotes: string;
};

const expensesApi = {
  getExpenses: async ({
    page,
    startDate,
    endDate,
    outletId,
    status,
    expenseType,
    paymentType,
    createdBy,
    searchQuery,
    ordering,
  }: {
    page: number;
    startDate: string;
    endDate: string;
    outletId: number;
    status: string;
    expenseType: number[];
    paymentType: number[];
    createdBy: number[];
    ordering: string[];
    searchQuery: string;
  }) =>
    http.get<ExpenseResponse>(
      `expenses/expenses/?page=${page}&start_date=${startDate}&end_date=${endDate}${
        outletId !== 0 ? `&outlet=${outletId}` : ""
      }&status=${status}${
        expenseType && !!expenseType.length
          ? `&expense_type=${stringifyArray(expenseType.join().split(","))}`
          : ""
      }${
        paymentType && !!paymentType.length
          ? `&payment_type=${stringifyArray(paymentType.join().split(","))}`
          : ""
      }${
        createdBy && !!createdBy.length
          ? `&created_by=${stringifyArray(createdBy.join().split(","))}`
          : ""
      }${ordering && !!ordering.length ? `&order_by=${ordering[0]}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  createExpense: async ({ data }: { data: ExpenseCreateArguments }) =>
    http.post<Expense>(`expenses/expenses/`, data),

  updateExpense: async ({ id, data }: { id: number; data: ExpenseUpdateArguments }) =>
    http.put<Expense>(`expenses/expenses/${id}/`, data),

  getExpensesExcel: async ({
    page,
    startDate,
    endDate,
    outletId,
    status,
    expenseType,
    paymentType,
    createdBy,
  }: {
    page: number;
    startDate: string;
    endDate: string;
    outletId: number;
    status: string;
    expenseType: number[];
    paymentType: number[];
    createdBy: number[];
  }) =>
    http.get<ArrayBuffer>(
      `expenses/expenses/?start_date=${startDate}&end_date=${endDate}${
        outletId !== 0 ? `&outlet=${outletId}` : ""
      }&status=${status}${
        expenseType && !!expenseType.length
          ? `&expense_type=${stringifyArray(expenseType.join().split(","))}`
          : ""
      }${
        paymentType && !!paymentType.length
          ? `&payment_type=${stringifyArray(paymentType.join().split(","))}`
          : ""
      }${
        createdBy && !!createdBy.length
          ? `&created_by=${stringifyArray(createdBy.join().split(","))}`
          : ""
      }&excel_download=true&page=${page}`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),
};

export default expensesApi;
