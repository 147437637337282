import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { OutletOnlineBookingAcceptanceStatus } from "types/Outlet";

import { SliceStatus } from "../utils";
import api from "./onlineBookingStatusApi";

export const onlineBookingStatusAdapter = createEntityAdapter<OutletOnlineBookingAcceptanceStatus>({
  selectId: (outletOnlineBookingAcceptanceStatus) => outletOnlineBookingAcceptanceStatus.id,
});

const initialState = onlineBookingStatusAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllOnlineBookingsStatuses = createThunkFromApiWithType(
  "onlineBookingStatus/getOutletsAcceptingOnlineBookingsStatus",
  api.getOnlineBookingsStatus
);

export const updateOnlineBookingsStatus = createThunkFromApiWithType(
  "onlineBookingStatus/updateOutletAcceptingOnlineBookingsStatus",
  api.updateOnlineBookingsStatus
);

const onlineBookingStatusSlice = createSlice({
  name: "onlineBookingStatus",
  initialState,

  reducers: {},
  extraReducers: (reducers) => {
    reducers.addCase(getAllOnlineBookingsStatuses.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllOnlineBookingsStatuses.fulfilled, (state, { payload }) => {
      onlineBookingStatusAdapter.upsertMany(state, payload);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllOnlineBookingsStatuses.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateOnlineBookingsStatus.fulfilled, (state, { payload }) => {
      onlineBookingStatusAdapter.upsertOne(state, payload);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateOnlineBookingsStatus.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateOnlineBookingsStatus.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });
  },
});

export default onlineBookingStatusSlice.reducer;
