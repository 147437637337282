import { Box, Typography } from "@mui/material";
import useVouchersWithRedeemableItemsTitle from "hooks/useVouchersWithRedeemableItemsTitle";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveVouchersByClientIdAndOutletId,
  selectRedeemableVouchersByClientIdAndOutletIdAndServiceVariantIds,
} from "store/clients/clientSelectors";
import { VoucherPurchase } from "types/VoucherPurchase";

import { useCheckoutFormContext } from "../../CheckoutFormContext";
import { getCartServiceVariantIds } from "../utils";
import ApplyVoucherFormSectionTitle from "./ApplyVoucherFormSectionTitle";
import AvailableVoucherItem, { AvailableVoucherStatus } from "./AvailableVoucherItem";
import { getAvailableVoucherStatus, sortClientVouchersByVoucherStatus } from "./utils";

type AvailableVouchersSectionProps = {
  selectedVoucher: Nullable<VoucherPurchase>;
  onChangeSelectedVoucher: (voucherPurchase: VoucherPurchase) => void;
};

export type VoucherPurchaseWithStatusAndRedeemableItemsTitle = VoucherPurchase & {
  voucherStatus: AvailableVoucherStatus;
  redeemableItemsTitle: string;
};

const AvailableVouchersSection = ({
  selectedVoucher,
  onChangeSelectedVoucher,
}: AvailableVouchersSectionProps) => {
  const { t } = useTranslation("vouchers");

  const { watch } = useCheckoutFormContext();

  const { existingClient, outlet, articles, voucherRedemptions } = watch();

  const appliedVoucherRedemptions =
    voucherRedemptions?.map((voucherRedemption) => voucherRedemption.voucherPurchase) ?? [];

  const hasAppliedVoucherRedemptions = appliedVoucherRedemptions.length > 0;

  const existingClientId = existingClient?.id || null;

  const cartServiceVariantIds = getCartServiceVariantIds(articles);

  const clientActiveVouchers = useSelector(
    selectActiveVouchersByClientIdAndOutletId(existingClientId ?? 0, outlet)
  );

  const { voucherPurchasesWithRedeemableItemsTitle: clientActiveVouchersWithRedeemableItemsTitle } =
    useVouchersWithRedeemableItemsTitle({
      voucherPurchases: clientActiveVouchers,
    });

  const clientRedeemableVouchers = useSelector(
    selectRedeemableVouchersByClientIdAndOutletIdAndServiceVariantIds(
      existingClientId ?? 0,
      outlet,
      cartServiceVariantIds
    )
  );

  const clientActiveVouchersWithVoucherStatus = clientActiveVouchersWithRedeemableItemsTitle?.map(
    (voucher) => {
      const voucherWithoutRedeemableItemsTitle = {
        ...voucher,
        redeemableItemsTitle: undefined,
      };

      return {
        ...voucher,
        voucherStatus: getAvailableVoucherStatus(
          voucherWithoutRedeemableItemsTitle,
          clientRedeemableVouchers,
          appliedVoucherRedemptions,
          selectedVoucher?.id
        ),
      };
    }
  );

  const clientActiveVouchersSortedByVoucherStatus = clientActiveVouchersWithVoucherStatus?.sort(
    sortClientVouchersByVoucherStatus
  );

  const clientActiveVouchersTotal = clientActiveVouchersWithVoucherStatus?.length;

  const clientRedeemableVouchersTotal = clientRedeemableVouchers.length;

  return (
    <>
      <ApplyVoucherFormSectionTitle
        title={t("availableVouchers")}
        secondaryContent={
          <Typography variant="primary" color="primary" fontWeight={500}>
            <Trans
              i18nKey="vouchers:applicableVouchersOutOf"
              values={{
                clientRedeemableVouchersTotal,
                clientActiveVouchersTotal,
              }}
              components={[
                <Typography
                  key="applicable-vouchers-out-of"
                  display="inline"
                  variant="primary"
                  color="text.placeholder"
                />,
              ]}
            />
          </Typography>
        }
      />

      {hasAppliedVoucherRedemptions && (
        <Typography component="p" variant="primary" color="error.main" fontWeight={500} my={1}>
          {t("vouchers:voucherAlreadyApplied")}
        </Typography>
      )}

      <Box pt={1}>
        {clientActiveVouchersSortedByVoucherStatus?.map((voucher) => {
          return (
            <AvailableVoucherItem
              key={voucher.id}
              purchasedVoucher={voucher}
              voucherStatus={voucher.voucherStatus}
              onClick={onChangeSelectedVoucher}
              disabled={hasAppliedVoucherRedemptions}
            />
          );
        })}
      </Box>
    </>
  );
};

export default AvailableVouchersSection;
