import { Heart } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import { EditIcon } from "assets/icon";
import EditArticleModal from "components/common/Modals/EditArticleModal";
import { checkIfArticleHasSpecialDiscount } from "helpers/articles";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "store/employees/employeesSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectResourceById } from "store/selectors";
import { selectServiceById } from "store/slices/servicesV2/servicesSelectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";
import { ServiceVariantPricingType } from "types/ServiceV2";
import auth from "utils/auth";

import PriceSection from "./PriceSection";

const ServiceItem = ({ article, slot }) => {
  const subscriptionRedemption = article.subscriptionRedemption;

  const serviceVariant = useSelector(selectServiceVariantById(slot?.serviceVariant));
  const service = useSelector(selectServiceById(serviceVariant?.service));
  const staff = useSelector(selectEmployeeById(slot?.staff));
  const resource = useSelector(selectResourceById(slot?.resource));

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const { hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const [isEditArticleModalOpen, setIsEditArticleModalOpen] = useState(false);

  const timeSlot = `${DateTime.fromISO(slot.start).toFormat("h:mm a")} - ${DateTime.fromISO(
    slot.end
  ).toFormat("h:mm a")}`;

  const staffName = staff?.user
    ? `${staff.user.firstName}${staff.user.lastName && ` ${staff.user.lastName}`}`
    : staff?.profile
    ? `${staff.profile.firstName}${staff.profile.lastName && ` ${staff.profile.lastName}`}`
    : "";

  const resourceName = resource ? `, ${resource.name}` : "";

  const description = `${serviceVariant?.name}, ${getFormattedDuration(article?.totalDuration)}`;

  const articleResourceDescription = `${staffName}${resourceName}`;

  const isAssignedToSlot = slot.staff === authenticatedUserEmployeeId;
  const isTypeFrom = serviceVariant?.pricingType === ServiceVariantPricingType.From;
  const canEditService = (isAssignedToSlot || hasNonPersonalAppointment.editAccess) && isTypeFrom;

  return (
    <>
      <Box display="flex" justifyContent="space-between" minWidth="100%" alignItems="flex-start">
        <Box>
          <Typography variant="primary" fontWeight={500} whiteSpace="nowrap">
            {timeSlot}
          </Typography>

          <Typography variant="h5" fontWeight={500} noWrap sx={{ py: 0.5 }}>
            {service?.name}
          </Typography>

          <Box display="flex" alignItems="center" noWrap>
            <Typography variant="secondary" color="text.label">
              {description}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" noWrap>
            <Typography variant="secondary" color="text.label">
              {articleResourceDescription}
            </Typography>

            {slot.isStaffSelectedByClient && (
              <Heart sx={{ fontSize: "12px" }} solid color="error" />
            )}
          </Box>
        </Box>

        <Box display="flex" gap={2} alignItems="center">
          <PriceSection
            isPricingTypeFrom={isTypeFrom}
            originalPrice={Number(
              (article.hasUpdatedPricing && !article.promotion) ||
                checkIfArticleHasSpecialDiscount({
                  markedPrice: article.markedPrice || 0,
                  originalPrice: article.originalPrice,
                  finalPrice: article.finalPrice,
                })
                ? article.originalPrice
                : article.markedPrice
            )}
            finalPrice={Number(article.finalPrice)}
            hasSubscriptionRedemption={!!subscriptionRedemption}
          />

          {canEditService && (
            <IconButton
              size="small"
              disableRipple
              sx={{
                padding: 0,
              }}
              onClick={() => {
                setIsEditArticleModalOpen(true);
              }}
            >
              <EditIcon sx={{ justifySelf: "flex-end", fontSize: "1.25rem", color: "#6C798F" }} />
            </IconButton>
          )}
        </Box>
      </Box>

      <EditArticleModal
        articleId={article.id}
        slotId={slot.id}
        isEditArticleModalOpen={isEditArticleModalOpen}
        setIsEditArticleModalOpen={setIsEditArticleModalOpen}
      />
    </>
  );
};

export default ServiceItem;
