import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Invoice } from "types/Checkout";

import { SliceStatus } from "../utils";
import api from "./checkoutInvoicesApi";

export const checkoutInvoiceAdapter = createEntityAdapter<Invoice>({
  selectId: (invoice) => invoice.id,
});

const initialState = checkoutInvoiceAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const createInvoice = createThunkFromApiWithType(
  "checkoutInvoices/createInvoice",
  api.createInvoice
);

export const getInvoice = createThunkFromApiWithType("checkoutInvoices/getInvoice", api.getInvoice);

export const updateInvoice = createThunkFromApiWithType(
  "checkoutInvoices/updateInvoice",
  api.updateInvoice
);

export const createGiftVoucher = createThunkFromApiWithType(
  "checkoutInvoices/createGiftVoucher",
  api.createGiftVoucher
);

export const createRefundInvoice = createThunkFromApiWithType(
  "checkoutInvoices/createRefundInvoice",
  api.createRefundInvoice
);

export const voidRefundInvoice = createThunkFromApiWithType(
  "checkoutInvoices/voidRefundInvoice",
  api.voidRefundInvoice
);

export const deactivatePeepPaymentLink = createThunkFromApiWithType(
  "deposits/deactivatePeepPaymentLink",
  api.cancelPeepPayDeposit
);

export const checkoutInvoicesSlice = createSlice({
  name: "checkoutInvoices",
  initialState,

  reducers: {
    upsertInvoices(state, { payload }) {
      checkoutInvoiceAdapter.upsertMany(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvoice.fulfilled, (state, { payload }) => {
        checkoutInvoiceAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(createInvoice.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createInvoice.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(getInvoice.fulfilled, (state, { payload }) => {
        checkoutInvoiceAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getInvoice.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getInvoice.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(updateInvoice.fulfilled, (state, { payload }) => {
        checkoutInvoiceAdapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });
        state.status = SliceStatus.IDLE;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(updateInvoice.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(createGiftVoucher.fulfilled, (state, { payload }) => {
        checkoutInvoiceAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })
      .addCase(createGiftVoucher.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createGiftVoucher.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      // createRefundInvoice response does not match the invoice type so we cannot append it to the store
      .addCase(createRefundInvoice.fulfilled, (state) => {
        state.status = SliceStatus.IDLE;
      })
      .addCase(createRefundInvoice.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createRefundInvoice.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(voidRefundInvoice.fulfilled, (state) => {
        state.status = SliceStatus.IDLE;
      })
      .addCase(voidRefundInvoice.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(voidRefundInvoice.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      // cancelling peep payment link
      .addCase(deactivatePeepPaymentLink.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(deactivatePeepPaymentLink.fulfilled, (state, { payload }) => {
        checkoutInvoiceAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })

      .addCase(deactivatePeepPaymentLink.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default checkoutInvoicesSlice.reducer;

export const { upsertInvoices } = checkoutInvoicesSlice.actions;
