import { Box, Typography } from "@mui/material";
import { WarningShieldIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientById } from "store/clients/clientsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectDepositsByAppointmentId } from "store/selectors";
import { Client } from "types/Client";
import { PeepPayLinkStatus } from "types/Deposit";

import AddDepositChip from "./AddDepositChip";
import ExistingDepositChip from "./ExistingDepositChip";

type DepositSectionProps = {
  appointmentId: number;
  clientId: Nullable<number>;
  shouldShowAddDepositSection?: boolean;
};

const DepositSection = ({
  appointmentId,
  clientId,
  shouldShowAddDepositSection = true,
}: DepositSectionProps) => {
  const { hasDeposits } = useSelector(selectAllPermissions);

  const client = useSelector(selectClientById(clientId)) as Client | undefined;

  const { hasPeepPay } = useSelector(selectOrganization);

  const deposit = useSelector(selectDepositsByAppointmentId(appointmentId || 0));
  const peepPayLink = deposit?.peepPay;

  const hasActivePeepPayLink = peepPayLink?.status !== PeepPayLinkStatus.Canceled;

  const hasActiveDeposit = Boolean(deposit?.id) && hasActivePeepPayLink;

  const { t } = useTranslation("deposits");

  const canShowAddDepositSection =
    shouldShowAddDepositSection && !hasActiveDeposit && hasDeposits.editAccess && client;

  if (canShowAddDepositSection)
    return (
      <>
        <AddDepositChip appointmentId={appointmentId} client={client} />

        {hasPeepPay && (client?.reachedNoShowThreshold || client?.reachedCancellationThreshold) && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningShieldIcon color="error" viewBox="-5 -5 20 20" width={20} height={20} />
            <Typography color="error" variant="secondary">
              {t("peepPayRecommended")}
            </Typography>
          </Box>
        )}
      </>
    );

  if (deposit && hasActiveDeposit) return <ExistingDepositChip depositId={deposit.id} />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default DepositSection;
