import type { RootState } from "store";
import { selectAllCalendarEmployees } from "store/employees/employeesSlice";
import Employee from "types/Employee";
import { Resource } from "types/Resource";

import { selectActiveOutletResourcesByOutletId } from "../resources/resourcesSelectors";
import { selectAllServices, selectServicesByCategoryId } from "../servicesV2/servicesSelectors";
import { selectVariantsByServiceId } from "../serviceVariantsV2/serviceVariantsSelectors";
import { SliceStatus } from "../utils";
import { serviceCategoryAdapter } from "./serviceCategoriesSlice";

const { selectById, selectAll } = serviceCategoryAdapter.getSelectors(
  (state: RootState) => state.serviceCategoriesV2
);

export const selectAllServiceCategories = selectAll;

export const selectServiceCategoryById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectServiceCategoryLoading = (state: RootState) =>
  state.serviceCategoriesV2.status === SliceStatus.LOADING;

export const selectAllServiceCategoriesWithAllData =
  (outletId?: Nullable<number>) => (state: RootState) => {
    const allCategories = selectAll(state);
    return allCategories.map((category) => {
      const filteredServices = selectServicesByCategoryId(category.id)(state)
        .filter((service) =>
          outletId ? service.outlets.includes(outletId) && !service.deprecated : !service.deprecated
        )
        .map((service) => {
          const filteredVariants = selectVariantsByServiceId(service.id)(state).filter(
            (variant) => !variant.deprecated
          );

          return {
            ...service,
            variants: filteredVariants,
          };
        });

      return {
        ...category,
        services: filteredServices,
      };
    });
  };

export const selectServiceCategoriesWithEmployeeIds = (outletId: number) => (state: RootState) => {
  const allCategories = selectAll(state);
  const allServices = selectAllServices(state);
  const allEmployees = selectAllCalendarEmployees(state) as Employee[];

  const categoriesWithServiceData = allCategories.map((category) => {
    const categoryServices = allServices.filter(
      (service) => service.category === category.id && service.outlets.includes(outletId)
    );

    const categoryEmployeeIds = categoryServices.reduce<number[]>((employeeIds) => {
      const newIds = allEmployees
        .filter(
          (employee) =>
            categoryServices.some((service) => employee.services.includes(service.id)) &&
            !employeeIds.includes(employee.id) &&
            employee.outlets.includes(outletId)
        )
        .map(({ id }) => id);

      return [...employeeIds, ...newIds];
    }, []);

    return { ...category, employeeIds: categoryEmployeeIds, services: categoryServices };
  });

  const categoriesWithEmployees = categoriesWithServiceData.filter(
    (category) => category.employeeIds.length
  );
  const categoriesWithoutEmployees = categoriesWithServiceData.filter(
    (category) => !category.employeeIds.length
  );

  return [...categoriesWithEmployees, ...categoriesWithoutEmployees];
};

export const selectServiceCategoriesWithResourceIds = (outletId: number) => (state: RootState) => {
  const allCategories = selectAll(state);
  const allServices = selectAllServices(state);
  const allResources = selectActiveOutletResourcesByOutletId(outletId)(state) as Resource[];

  const categoriesWithServiceData = allCategories.map((category) => {
    const categoryServices = allServices.filter(
      (service) => service.category === category.id && service.outlets.includes(outletId)
    );

    const categoryResourceIds = categoryServices.reduce<number[]>((resourceIds) => {
      const newIds = allResources
        .filter((resource) =>
          categoryServices.some((service) => (service.resources || []).includes(resource.id))
        )
        .map(({ id }) => id);

      return [...resourceIds, ...newIds];
    }, []);

    return { ...category, resourceIds: categoryResourceIds, services: categoryServices };
  });

  const categoriesWithResources = categoriesWithServiceData.filter(
    (category) => category.resourceIds.length
  );
  const categoriesWithoutResources = categoriesWithServiceData.filter(
    (category) => !category.resourceIds.length
  );

  return [...categoriesWithResources, ...categoriesWithoutResources];
};
