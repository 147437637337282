import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveSubscriptionByClientId,
  selectActiveSubscriptionByClientIdAndOutletId,
} from "store/clients/clientsSlice";
import { ClientSubscriptionPurchase } from "types/Subscription";

import ClientTag from "./ClientTag";

type ClientSubscriptionTagsProps = {
  clientId: number;
  outletId?: number;
};

/**
 * returns Subscription tags filtered by outletId if provided otherwise all active subscriptions
 */

const ClientSubscriptionTags = ({ clientId, outletId }: ClientSubscriptionTagsProps) => {
  const { t } = useTranslation("subscriptions");

  const clientSubscriptions = useSelector(
    outletId
      ? selectActiveSubscriptionByClientIdAndOutletId(clientId, outletId)
      : selectActiveSubscriptionByClientId(clientId)
  ) as ClientSubscriptionPurchase[];

  return (
    <>
      {clientSubscriptions.map((subscription) => (
        // don't mess with the grid item unless you know what you're doing - Anfal
        <Grid item flex={1} md xs={12} key={`ClientSubscriptionTag-${subscription.id}`}>
          <ClientTag
            bgColor="#EEFAFF"
            label={
              <>
                <Typography variant="secondary" color="primary" fontWeight={500} noWrap>
                  {subscription.name}
                </Typography>

                <Typography variant="secondary" color="text.label" fontWeight={500}>
                  {`${subscription.remainingRedemptionsCount}\u00A0/\u00A0${
                    subscription.totalCreditsCount
                  }\u00A0${t("left")}`}
                </Typography>
              </>
            }
          />
        </Grid>
      ))}
    </>
  );
};

export default ClientSubscriptionTags;
