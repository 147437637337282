import Employee, { User } from "types/Employee";

function getEmployeeFirstName(employee: Employee) {
  if (employee.profile) return `${employee.profile.firstName}`;

  if (employee.user) return `${employee.user.firstName}`;

  return "واحد";
}

function getEmployeeFullName(employee: Employee) {
  if (employee.profile) return `${employee.profile.firstName} ${employee.profile.lastName}`;

  if (employee.user) return `${employee.user.firstName} ${employee.user.lastName}`;

  return "واحد غامض";
}

function getUserFullName(user: User) {
  return `${user.firstName} ${user.lastName}`;
}

export { getEmployeeFirstName, getEmployeeFullName, getUserFullName };
