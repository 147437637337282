import { ProductCategory } from "types/ProductCategory";
import { StockTransaction } from "types/Stock";
import http from "utils/http";

// The backend expects an empty string not an empty array string
function stringifyArray(array: string[]) {
  return array.length < 1 ? "" : array.toString();
}

type CreateSupplierCostArguments = Pick<SupplierCost, "supplier" | "costPerUnit">;

type CreateProductOutletStock = Pick<OutletStock, "outlet" | "reStockThreshold"> & {
  currentStock: number;
  costPerUnit: number;
};

type ProductArguments = {
  name: Product["name"];
  description?: Product["description"];
  brand?: Product["brand"];
  category: Product["category"];
  barcode?: Product["barcode"];
  sku?: Product["sku"];
  supplierCosts?: CreateSupplierCostArguments[];
  isPurchasable: Product["isPurchasable"];
  retailPrice?: Product["retailPrice"];
  isStockTracked: Product["isStockTracked"];
  outletStocks: { outlet: number; reStockThreshold: number }[];
  outlets: Product["outlets"];
};

// for creating a product, outletStocks field has a different type than the product response
export type CreateProductArguments = Omit<ProductArguments, "outletStocks"> & {
  outletStocks: CreateProductOutletStock[];
};

export type ProductResponse = {
  product: Product;

  brand: Nullable<ProductBrand>;
  category: ProductCategory;
  suppliers: Supplier[];

  outletStocks: OutletStock[]; // this does not match the request type
  stockTransactions: StockTransaction[];

  invoices: ProductInvoices[];
};

export type ProductListResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  results: {
    products: Product[];
    brands: ProductBrand[];
    categories: ProductCategory[];
    suppliers: Supplier[];
    outletStocks: OutletStock[]; // this does not match the request type}
  };
};

const productsApi = {
  getProduct: async ({ id }: { id: number }) =>
    http.get<ProductResponse>(`products/products/${id}/`),

  getAllProducts: async () => http.get<Product[]>(`products/brief/`),

  getProducts: async ({
    page,
    outlet,
    stockStatus,
    categories,
    brands,
    suppliers,
    retailStatus,
    ordering,
    searchQuery,
  }: {
    page?: number;
    outlet?: number;
    stockStatus?: string[];
    categories?: number[];
    brands?: number[];
    suppliers?: number[];
    retailStatus?: string[];
    ordering?: string;
    searchQuery?: string;
  }) =>
    http.get<ProductListResponse>(
      `products/products/${page ? `?page=${page}` : ""}${
        outlet && outlet !== 0 ? `&outlet=${outlet}` : ""
      }${
        categories && !!categories.length
          ? `&categories=${stringifyArray(categories.join().split(","))}`
          : ""
      }${brands && !!brands.length ? `&brands=${stringifyArray(brands.join().split(","))}` : ""}${
        suppliers && !!suppliers.length
          ? `&suppliers=${stringifyArray(suppliers.join().split(","))}`
          : ""
      }${stockStatus ? `&stock_status=${stringifyArray(stockStatus.join().split(","))}` : ""}${
        retailStatus ? `&is_retail=${stringifyArray(retailStatus.join().split(","))}` : ""
      }${ordering ? `&order_by=${ordering}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}`
    ),

  createProduct: async ({ data }: { data: CreateProductArguments }) =>
    http.post<ProductResponse>("products/products/", data),

  updateProduct: async ({ id, data }: { id: number; data: ProductArguments }) =>
    http.put<ProductResponse>(`products/products/${id}/`, data),

  removeProduct: async ({ id }: { id: number }) => http.delete(`products/products/${id}/`),

  getProductsExcel: async ({
    page,
    outlet,
    stockStatus,
    categories,
    brands,
    suppliers,
    retailStatus,
    selectedDate,
  }: {
    page?: number;
    outlet?: number;
    stockStatus?: string[];
    categories?: number[];
    brands?: number[];
    suppliers?: number[];
    retailStatus?: string[];
    selectedDate: string;
  }) =>
    http.get<ProductListResponse>(
      `products/products/${page ? `?page=${page}` : ""}${
        outlet && outlet !== 0 ? `&outlet=${outlet}` : ""
      }${
        categories && !!categories.length
          ? `&categories=${stringifyArray(categories.join().split(","))}`
          : ""
      }${brands && !!brands.length ? `&brands=${stringifyArray(brands.join().split(","))}` : ""}${
        suppliers && !!suppliers.length
          ? `&suppliers=${stringifyArray(suppliers.join().split(","))}`
          : ""
      }${stockStatus ? `&stock_status=${stringifyArray(stockStatus.join().split(","))}` : ""}${
        retailStatus ? `&is_retail=${stringifyArray(retailStatus.join().split(","))}` : ""
      }&date=${selectedDate}&excel_download=true`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),
};

export default productsApi;
