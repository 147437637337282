import type {} from "@mui/lab/themeAugmentation";

import { peepTheme } from "@bookpeep/ui";
import { ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";

const themeOverrides: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },

    // The following will fix the ugly white background for input fields that override the border radius and it appears when the background color behind the input field is not white. You're welcome honey. - Anfal The Great and soon to be the Greatest CSS master in the universe 🤪.
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            backgroundColor: peepTheme.palette.bg.blank,
            borderRadius: peepTheme.spacing(1),
          },
        },
      },
    },
  },
};

const theme = deepmerge(peepTheme, themeOverrides);

export default theme;
