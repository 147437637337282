import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

type ApplyVoucherFormSectionTitleProps = {
  title: string;
  secondaryContent: ReactElement;
};

const ApplyVoucherFormSectionTitle = ({
  title,
  secondaryContent,
}: ApplyVoucherFormSectionTitleProps) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
      <Typography
        variant="primary"
        fontWeight={700}
        textTransform="uppercase"
        color="text.placeholder"
      >
        {title}
      </Typography>

      {secondaryContent}
    </Box>
  );
};

export default ApplyVoucherFormSectionTitle;
