import { QueryParams } from "react-use-websocket/dist/lib/types";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { useSelector } from "store";
import { selectAuth } from "store/auth/authSlice";
import { ACCESS_TOKEN } from "utils/auth";

type UseWebsocketRequestArgs = {
  webSocketUrl: string;
  queryParams?: QueryParams;
};

export const useWebsocketRequest = ({ webSocketUrl, queryParams }: UseWebsocketRequestArgs) => {
  const user = useSelector(selectAuth);
  const isAuthenticated = !!user.id;

  const userAccessTokenValue = localStorage.getItem(ACCESS_TOKEN);

  const canMakeWebSocketRequest = isAuthenticated && userAccessTokenValue;

  const { readyState, lastMessage } = useWebSocket(canMakeWebSocketRequest ? webSocketUrl : null, {
    queryParams: {
      token: canMakeWebSocketRequest ? `${userAccessTokenValue}` : "",
      ...queryParams,
    },

    // Will attempt to reconnect on all close events, such as server shutting down if the user has permission to access the socket
    shouldReconnect: (closeEvent) => (canMakeWebSocketRequest ? true : false),
  });

  return { readyState, lastMessage };
};
