import { LoadingButton } from "@mui/lab";
import setTimezone from "helpers/setTimezoneToKw";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext } from "react";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GenericDispatch, GenericThunk } from "store";
import {
  createAppointment,
  selectAppointmentById,
  updateAppointment,
} from "store/appointments/appointmentsSlice";
import { trackEvent } from "tracking";
import useNotify from "utils/notifications/useNotify";

import AppointmentFormContext from "../AppointmentFormContext";
import { AppointmentFormValues } from "../utils";

type SubmitAppointmentFormButtonProps = {
  onClose: () => void;
};

const SubmitAppointmentFormButton = ({ onClose }: SubmitAppointmentFormButtonProps) => {
  const { t } = useTranslation(["translation", "appointments"]);
  const dispatch = useDispatch<GenericDispatch>();
  const notify = useNotify();

  const { outletId, date } = useContext(CalendarPageContext);

  const { closeAndResetForm, isRequestPending, setIsRequestPending } =
    useContext(AppointmentFormContext);
  const { handleSubmit, watch } = useFormContext<AppointmentFormValues>();

  const { articles } = watch();

  const isEditingAppointment = !!useWatch({ name: "appointmentId" });

  const oldAppointmentData = useSelector(
    selectAppointmentById(useWatch({ name: "appointmentId" }))
  );

  const handleCloseAndResetForm = () => {
    closeAndResetForm();
    onClose();
  };

  const handleCreateAppointment: SubmitHandler<AppointmentFormValues> = (formValues) => {
    setIsRequestPending(true);

    const { articles: rawArticles, client, status, appointmentId, notes, address } = formValues;

    const nonEmptyArticles = rawArticles.filter(
      (article) => Boolean(article.serviceVariantId) || Boolean(article.packageVariantId)
    );

    const formattedArticles = nonEmptyArticles.map((article) => {
      const {
        slots,
        serviceVariantId: serviceVariant,
        packageVariantId: packageVariant,
        subscriptionRedemptionId,
        promotion,
        finalPrice,
        rewardLoyaltyCard,
        creationSource,
      } = article;

      const formattedSlots = slots.map(
        ({
          startTime,
          endTime,
          staffId,
          resourceId,
          serviceVariantId,
          isStaffSelectedByClient,
        }) => ({
          staff: staffId,
          serviceVariant: serviceVariantId,
          isStaffSelectedByClient,
          resource: resourceId,

          // need to format start and end times to match the backend format
          start: setTimezone(startTime),
          end: setTimezone(endTime),
        })
      );

      return {
        creationSource,
        rewardLoyaltyCard,
        subscriptionRedemptionId,
        serviceVariant,
        packageVariant,
        promotion,
        finalPrice,
        slots: formattedSlots,
      };
    });

    // need to format the data to match the backend requirements
    const formattedDate = date.toFormat("yyyy-MM-dd");

    const { isNewClient, id, phoneCountryCode, phoneNumber, ...newClientData } = client;

    const hasNoClient = !client.isNewClient && !client.id;

    const clientObj = hasNoClient
      ? {}
      : isNewClient
      ? {
          phoneCountryCode,
          phoneNumber,
          ...newClientData,
        }
      : {
          lastName: newClientData.lastName,
          firstName: newClientData.firstName,
          id,
          phoneCountryCode,
          phoneNumber,
        };

    const data = {
      client: clientObj,
      status,
      outlet: outletId,
      articles: formattedArticles,
      date: formattedDate,
      address,
      ...(notes && { notes }),
      ...(appointmentId && { appointmentId }),
    };

    if (isEditingAppointment) {
      dispatch((updateAppointment as GenericThunk)({ id: appointmentId, data }))
        .unwrap()
        .then(() => {
          if (oldAppointmentData && oldAppointmentData.status !== status)
            trackEvent("Appointment Status Changed", {
              "Appointment Id": appointmentId,
              "Appointment Date": formattedDate,
              Trigger: "Appointment Edit",
            });
          setIsRequestPending(false);

          handleCloseAndResetForm();

          notify(t("entitySuccessfullyUpdated", { entity: t("appointment") }), "success");
        })
        .catch((error) => {
          notify(`${t("appointments:failedToUpdateAppointment")}. ${error?.detail ?? ""}`, "error");
          setIsRequestPending(false);
        });
    } else {
      dispatch((createAppointment as GenericThunk)({ data }))
        .unwrap()
        .then(() => {
          setIsRequestPending(false);

          handleCloseAndResetForm();

          notify(t("entitySuccessfullyCreated", { entity: t("appointment") }), "success");
        })
        .catch((error) => {
          notify(`${t("appointments:failedToCreateAppointment")}. ${error?.detail ?? ""}`, "error");
          setIsRequestPending(false);
        });
    }
  };

  const doesAllArticlesDoNotHaveServiceOrPackage = articles.every((article) => {
    const { packageVariantId, serviceVariantId } = article;
    const hasPackageOrService = packageVariantId || serviceVariantId;

    return !hasPackageOrService;
  });

  // disable button if all articles do not have packageVariantId or serviceVariantId
  const shouldDisableButton = doesAllArticlesDoNotHaveServiceOrPackage;

  return (
    <LoadingButton
      fullWidth
      onClick={handleSubmit(handleCreateAppointment)}
      loading={isRequestPending}
      disabled={shouldDisableButton}
    >
      {t(isEditingAppointment ? "appointments:save" : "appointments:create")}
    </LoadingButton>
  );
};

export default SubmitAppointmentFormButton;
