import { PForPeep } from "@bookpeep/ui";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

import peepLogo from "../../assets/images/peep-logo.svg";

const LogoSectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#322C9C33", //  #322C9C with opacity 0.2
  height: theme.spacing(31),
}));

const PForPeepIcon = styled(PForPeep)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const LogoSection = ({ shouldShowFullLogo }) => {
  const navigate = useNavigate();

  return (
    <LogoSectionBox display="flex" justifyItems="center" alignItems="center">
      <Grid
        container
        justifyContent="center"
        onClick={() => navigate("/")}
        sx={{ cursor: "pointer" }}
      >
        {shouldShowFullLogo ? <img src={peepLogo} alt="peep logo" /> : <PForPeepIcon />}
      </Grid>
    </LogoSectionBox>
  );
};

export default LogoSection;
