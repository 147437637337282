import debounce from "@mui/utils/debounce";
import useIsMobileView from "hooks/useIsMobileView";
import { decamelize } from "humps";
import { DateTime } from "luxon";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "store";
import { appendAllProductsData, getAllProductsData } from "store/slices/products/productsSlice";

export const ProductStockStatusOptions = {
  RequiresAttention: "Requires Attention",
  OutOfStock: "Out of Stock",
};

export const RetailFilterOptions = {
  Yes: "Yes",
  No: "No",
};

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type InitialStateType = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;

  outlet: number;
  setOutlet: Dispatch<SetStateAction<number>>;

  statusOptions: string[];
  setStatusOptions: Dispatch<SetStateAction<string[]>>;

  categoryIds: number[];
  setCategoryIds: Dispatch<SetStateAction<number[]>>;

  brandIds: number[];
  setBrandIds: Dispatch<SetStateAction<number[]>>;

  supplierIds: number[];
  setSupplierIds: Dispatch<SetStateAction<number[]>>;

  retailOptions: string[];
  setRetailOptions: Dispatch<SetStateAction<string[]>>;

  ordering: string[];
  setOrdering: Dispatch<SetStateAction<string[]>>;

  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;

  handleInputChange: (value: string) => void;

  handleLoadMore: (value: number) => void;

  handlePageChange: (value: number) => void;

  clearAllFilters: () => void;
};

const initialState = {
  page: 1,
  setPage: unimplementedFunction,

  outlet: 0,
  setOutlet: unimplementedFunction,

  statusOptions: [],
  setStatusOptions: unimplementedFunction,

  categoryIds: [],
  setCategoryIds: unimplementedFunction,

  brandIds: [],
  setBrandIds: unimplementedFunction,

  supplierIds: [],
  setSupplierIds: unimplementedFunction,

  retailOptions: [],
  setRetailOptions: unimplementedFunction,

  ordering: [],
  setOrdering: unimplementedFunction,

  searchQuery: "",
  setSearchQuery: unimplementedFunction,

  searchQueryAsParam: "",
  setSearchQueryAsParam: unimplementedFunction,

  selectedDate: DateTime.now().toISODate(),
  setSelectedDate: unimplementedFunction,

  handleInputChange: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  handlePageChange: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const ProductListContext = createContext<InitialStateType>(initialState);

export default ProductListContext;

type ProductListContextProviderProps = {
  children: ReactNode;
};

function ProductListContextProvider({ children }: ProductListContextProviderProps) {
  const dispatch = useDispatch();

  const isMobileView = useIsMobileView();

  const [page, setPage] = useState<number>(initialState.page);

  const [outlet, setOutlet] = useState<number>(initialState.outlet);

  const [statusOptions, setStatusOptions] = useState<string[]>([]);

  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  const [brandIds, setBrandIds] = useState<number[]>([]);

  const [supplierIds, setSupplierIds] = useState<number[]>([]);

  const [retailOptions, setRetailOptions] = useState<string[]>([]);

  const [ordering, setOrdering] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>(initialState.searchQuery);

  const [selectedDate, setSelectedDate] = useState<string>(initialState.selectedDate);

  // the query that will be sent to the backend request after debouncing
  const [searchQueryAsParam, setSearchQueryAsParam] = useState<string>(
    initialState.searchQueryAsParam
  );

  const triggerDebouncedFetch = useMemo(
    () =>
      debounce((searchQuery) => {
        setSearchQueryAsParam(searchQuery);
        setPage(1);
      }, 800),
    []
  );

  const fetchProducts = useCallback(triggerDebouncedFetch, [triggerDebouncedFetch]);

  const handleInputChange = (newValue: string) => {
    setSearchQuery(newValue);
    fetchProducts(newValue);
  };

  const handleLoadMore = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
    setStatusOptions([]);
    setCategoryIds([]);
    setBrandIds([]);
    setSupplierIds([]);
    setRetailOptions([]);
  };

  useEffect(() => {
    const decamelizedSortingList = ordering.map(decamelize);
    const stringifiedSortingList = decamelizedSortingList.join(",");

    if (isMobileView && page !== 1) {
      dispatch(
        appendAllProductsData({
          page,
          outlet,
          stockStatus: statusOptions,
          categories: categoryIds,
          brands: brandIds,
          suppliers: supplierIds,
          retailStatus: retailOptions,
          ordering: stringifiedSortingList,
          searchQuery: searchQueryAsParam,
        })
      );
    } else {
      dispatch(
        getAllProductsData({
          outlet,
          stockStatus: statusOptions,
          categories: categoryIds,
          brands: brandIds,
          suppliers: supplierIds,
          retailStatus: retailOptions,
          ordering: stringifiedSortingList,
          searchQuery: searchQueryAsParam,
          page,
        })
      );
    }
  }, [
    isMobileView,
    dispatch,
    outlet,
    statusOptions,
    categoryIds,
    brandIds,
    supplierIds,
    ordering,
    page,
    searchQueryAsParam,
    retailOptions,
  ]);

  return (
    <ProductListContext.Provider
      value={{
        page,
        setPage,

        outlet,
        setOutlet,

        statusOptions,
        setStatusOptions,

        categoryIds,
        setCategoryIds,

        brandIds,
        setBrandIds,

        supplierIds,
        setSupplierIds,

        retailOptions,
        setRetailOptions,

        ordering,
        setOrdering,

        searchQuery,
        setSearchQuery,

        selectedDate,
        setSelectedDate,

        handleInputChange,

        handleLoadMore,

        handlePageChange,

        clearAllFilters,
      }}
    >
      {children}
    </ProductListContext.Provider>
  );
}

export { ProductListContextProvider };
