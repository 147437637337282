import { EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import {
  ActivePromotionBasic,
  PackageVariantActivePromotionItem,
  ProductActivePromotionItem,
  ServiceVariantActivePromotionItem,
} from "types/ActivePromotion";

import { SliceStatus } from "../utils";
import api from "./activePromotionsApi";

export const activePromotionsAdapter = createEntityAdapter<ActivePromotionBasic>({
  selectId: (promotion) => promotion.id,
});

export const serviceVariantActivePromotionsAdapter =
  createEntityAdapter<ServiceVariantActivePromotionItem>({
    selectId: (serviceVariantPromotion) => serviceVariantPromotion.id,
  });

export const packageVariantActivePromotionsAdapter =
  createEntityAdapter<PackageVariantActivePromotionItem>({
    selectId: (packageVariantPromotion) => packageVariantPromotion.id,
  });
export const productActivePromotionsAdapter = createEntityAdapter<ProductActivePromotionItem>({
  selectId: (productPromotion) => productPromotion.id,
});

export interface ActivePromotionsState extends EntityState<ActivePromotionBasic> {}
export interface ServiceVariantActivePromotionState
  extends EntityState<ServiceVariantActivePromotionItem> {}
export interface PackageVariantActivePromotionState
  extends EntityState<PackageVariantActivePromotionItem> {}
export interface ProductActivePromotionState extends EntityState<ProductActivePromotionItem> {}

export type ActivePromotionsSliceState = {
  outlet: Nullable<number>;
  date: Nullable<string>;
  basicPromotions: ActivePromotionsState;
  serviceVariants: ServiceVariantActivePromotionState;
  packageVariants: PackageVariantActivePromotionState;
  products: ProductActivePromotionState;
  status: SliceStatus;
};

const initialState: ActivePromotionsSliceState = {
  outlet: null,
  date: null,
  basicPromotions: activePromotionsAdapter.getInitialState(),
  serviceVariants: serviceVariantActivePromotionsAdapter.getInitialState(),
  packageVariants: packageVariantActivePromotionsAdapter.getInitialState(),
  products: productActivePromotionsAdapter.getInitialState(),
  status: SliceStatus.IDLE,
};

export const getActivePromotions = createThunkFromApiWithType(
  "activePromotions/getActivePromotions",
  api.getActivePromotions
);

export const activePromotionsSlice = createSlice({
  name: "activePromotions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getActivePromotions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      getActivePromotions.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { outletId, date },
          },
        }
      ) => {
        const serviceVariantsList: ServiceVariantActivePromotionItem[] = [];
        const packageVariantsList: PackageVariantActivePromotionItem[] = [];
        const productsList: ProductActivePromotionItem[] = [];
        const activePromotionsList: ActivePromotionBasic[] = [];

        payload.forEach((promotion) => {
          const { serviceVariants, packageVariants, products, ...rest } = promotion;
          serviceVariantsList.push(...serviceVariants);
          packageVariantsList.push(...packageVariants);
          productsList.push(...products);
          activePromotionsList.push(rest);
        });

        activePromotionsAdapter.setAll(state.basicPromotions, activePromotionsList);
        serviceVariantActivePromotionsAdapter.setAll(
          state.serviceVariants,
          serviceVariantsList.map((promotion, index) => ({ ...promotion, id: index }))
        );
        packageVariantActivePromotionsAdapter.setAll(
          state.packageVariants,
          packageVariantsList.map((promotion, index) => ({ ...promotion, id: index }))
        );
        productActivePromotionsAdapter.setAll(
          state.products,
          productsList.map((promotion, index) => ({ ...promotion, id: index }))
        );

        state.outlet = outletId;
        state.date = date;

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(getActivePromotions.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default activePromotionsSlice.reducer;
