import { PurchasableItemOption } from "types/PurchasableItem";
import { RedeemableSubscriptionItemOption } from "types/Subscription";

import type { PurchasableItemsFields } from "./CheckoutCartItem";
import CheckoutCartItemPackageFields from "./CheckoutCartItemPackageFields/CheckoutCartItemPackageFields";
import CheckoutCartItemProductFields from "./CheckoutCartItemProductFields";
import CheckoutCartItemServiceFields from "./CheckoutCartItemServiceFields";
import CheckoutCartItemSubscriptionFields from "./CheckoutCartItemSubscriptionFields";
import CheckoutCartItemVoucherFields from "./CheckoutCartItemVoucherFields";

export const getCartItemFields = (
  purchasableItemType: PurchasableItemOption,
  cartItemIndex: number,
  isPriceChangeDisabled: boolean,
  shouldDisableEditing: boolean
) => {
  switch (purchasableItemType) {
    case PurchasableItemOption.Service:
      return (
        <CheckoutCartItemServiceFields
          cartItemIndex={cartItemIndex}
          isPriceChangeDisabled={isPriceChangeDisabled}
          shouldDisableEditing={shouldDisableEditing}
        />
      );

    case PurchasableItemOption.Package:
      return (
        <CheckoutCartItemPackageFields
          cartItemIndex={cartItemIndex}
          isPriceChangeDisabled={isPriceChangeDisabled}
          shouldDisableEditing={shouldDisableEditing}
        />
      );

    case PurchasableItemOption.Product:
      return (
        <CheckoutCartItemProductFields
          cartItemIndex={cartItemIndex}
          shouldDisableEditing={shouldDisableEditing}
        />
      );

    case PurchasableItemOption.Subscription:
      return (
        <CheckoutCartItemSubscriptionFields
          cartItemIndex={cartItemIndex}
          shouldDisableEditing={shouldDisableEditing}
        />
      );

    case PurchasableItemOption.Voucher:
      return (
        <CheckoutCartItemVoucherFields
          cartItemIndex={cartItemIndex}
          shouldDisableEditing={shouldDisableEditing}
        />
      );

    default:
      throw new Error(`${purchasableItemType} is an invalid purchasable item type`);
  }
};

/**
 * This function returns the id of the purchasable item based on the type of the item
 * @param purchasedItem
 * @param purchasableItemType
 * @returns the id of the purchasable item
 */

// DEAR ANFAL, REFACTOR THIS FUNCTION
export const getPurchasableItemId = (
  purchasedItem: PurchasableItemsFields,
  purchasableItemType: PurchasableItemOption
) => {
  if (purchasedItem?.serviceVariant) return purchasedItem.serviceVariant;

  if (purchasedItem?.packageVariant) return purchasedItem.packageVariant;

  if (purchasedItem?.product) return purchasedItem.product;

  if (purchasedItem?.subscription) return purchasedItem.subscription;

  if (purchasedItem?.voucher) return purchasedItem.voucher;

  throw new Error(`${purchasableItemType} is an invalid purchasable item type`);
};

export const getSubscriptionItemType = (purchasableItemType: PurchasableItemOption) => {
  switch (purchasableItemType) {
    case PurchasableItemOption.Service:
      return RedeemableSubscriptionItemOption.ServiceVariants;

    case PurchasableItemOption.Package:
      return RedeemableSubscriptionItemOption.PackageVariant;

    default:
      new Error("Invalid purchasable item type");
      return RedeemableSubscriptionItemOption.ServiceVariants;
  }
};
