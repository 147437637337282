import { Chip, ChipProps, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

type AppointmentActionChipProps = ChipProps & ActionChipProps;

const AppointmentActionChip = (props: AppointmentActionChipProps) => {
  return <ActionChip {...props} />;
};

export default AppointmentActionChip;

type ActionChipProps = ChipProps & {
  iconColor?: string;
  fullWidth?: boolean;
};

const ActionChip = styled(
  Chip,
  dontForwardProps("iconColor", "fullWidth")
)<ActionChipProps>(({ theme, iconColor, fullWidth }) => ({
  backgroundColor: "transparent",
  border: "1px solid #DFDFDF",
  height: theme.spacing(4.25),
  padding: theme.spacing(0.5, 1.5),
  fontSize: "12px",
  fontWeight: 500,
  fontColor: "#0A1F44",
  width: fullWidth ? "100%" : "auto",
  justifyContent: "flex-start",
  "& .MuiChip-icon": {
    color: iconColor ? iconColor : "inherit",
    margin: 0,
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
