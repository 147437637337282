import { DiscountOption } from "./promotions";

export enum InvoiceType {
  Sale = "SALE",
  Refund = "REFUND",
}

// Note that this should be capitalized, the other one called PurchasableItemOption and it is not capitalized i'm talking about the "values" of the enum
export enum InvoicePurchasableItemType {
  Service = "SERVICE",
  Package = "PACKAGE",
  Product = "PRODUCT",
  Subscription = "SUBSCRIPTION",
  Voucher = "VOUCHER",
}

type InvoicePurchaseable = {
  id: number;
  purchasableType: InvoicePurchasableItemType;

  serviceVariant: Nullable<number>;
  packageVariant: Nullable<number>;
  product: Nullable<number>;
  subscription: Nullable<number>;
  voucher: Nullable<number>;
};

type InvoiceArticleSubscriptionRedemption = {
  name: string;
  credits: number;
};

type InvoiceArticleDetail = {
  id: number;
  duration: number;
  name: string;
  employee: string;
};

export type InvoiceArticle = {
  id: number;
  invoice: number;

  purchasedItem: InvoicePurchaseable;

  subscriptionRedemption: Nullable<InvoiceArticleSubscriptionRedemption>;

  details: InvoiceArticleDetail[];

  articleName: string;
  articleSecondaryName: string;

  totalDuration: number;
  noOfServices: number;

  creditPrice: number;
  originalPrice: number;
  markedPrice: number;
  finalPrice: number;

  promotion: Nullable<number>;
  promotionName: Nullable<string>;
  promotionDiscountType: Nullable<DiscountOption>;
  promotionDiscountValue: Nullable<string>;

  createdAt: string;
  updatedAt: string;
};
