import { TFunction } from "i18next";

export const tableHeaders = (t: TFunction) => [
  {
    align: "left",
    key: "reference",
    name: t("reference"),
    width: "10%",
  },
  {
    align: "left",
    key: "clientName",
    name: t("clientName"),
    width: "15%",
  },
  {
    align: "left",
    key: "createdAt",
    name: t("linkSentDate"),
    isSortable: true,
    width: "15%",
  },
  {
    align: "left",
    key: "mobile",
    name: t("mobileNumber"),
    width: "10%",
  },
  {
    align: "left",
    key: "location",
    name: t("location"),
    width: "15%",
  },
  {
    align: "left",
    key: "amount",
    name: t("linkAmount"),
    isSortable: true,
    width: "10%",
  },
  {
    align: "left",
    key: "status",
    name: t("status"),
    width: "12%",
    isSortable: true,
  },
  {
    align: "left",
    key: "action",
    name: t("action"),
    width: "13%",
  },
];
