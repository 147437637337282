import { z } from "zod";

const refundInvoicePayment = z
  .object({
    amount: z.number(),
    paymentType: z.number().nullable(),
    wallet: z.boolean().optional(),
  })
  .superRefine(({ paymentType, wallet }, ctx) => {
    if ((paymentType === undefined || paymentType === null) && !wallet) {
      return ctx.addIssue({
        code: "custom",
        path: ["paymentType", "wallet"],
        message: "mixed.required",
      });
    }
  });

export const refundInvoiceSchema = z.object({
  originalInvoice: z.number().min(1),
  articlesToRefund: z.array(z.number().min(1)),
  notes: z.string(),
  refundPayments: z.array(refundInvoicePayment),
});

export type RefundInvoiceFields = z.infer<typeof refundInvoiceSchema>;
