import { Close, MagnifyingGlass } from "@bookpeep/ui";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputProps,
  Input as MuiInput,
  styled,
} from "@mui/material";
import { dontForwardProps } from "helpers/styles";
import { MouseEventHandler, forwardRef } from "react";

type PeepSearchFieldProps = InputProps & {
  backgroundColor?: string;
  borderColor?: string;
  label?: string;
  helperText?: string;
  onClear?: MouseEventHandler<HTMLButtonElement>;
};

const PeepSearchField = forwardRef(
  (
    {
      // SearchField extra props
      backgroundColor,
      borderColor = "#B3B6EF",
      label,
      onClear,
      placeholder,
      helperText,

      // Input props
      value,
      disabled,
      fullWidth,
      onChange,
      error,
      ...props
    }: PeepSearchFieldProps,
    ref
  ) => {
    const shouldShowClearSearchButton = !!value && !!onClear;

    return (
      <>
        <SearchInput
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          ref={ref}
          fullWidth={fullWidth}
          disableUnderline
          type="text"
          value={value}
          onChange={onChange}
          disabled={disabled}
          error={error}
          placeholder={label || placeholder}
          startAdornment={
            <InputAdornment position="start">
              <MagnifyingGlass />
            </InputAdornment>
          }
          endAdornment={
            shouldShowClearSearchButton ? (
              <InputAdornment position="end">
                <StyledIconButton onClick={onClear}>
                  <Close fontSize="small" />
                </StyledIconButton>
              </InputAdornment>
            ) : null
          }
          {...props}
        />

        {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </>
    );
  }
);

export default PeepSearchField;

type SearchInputProps = Pick<PeepSearchFieldProps, "backgroundColor" | "borderColor">;

const SearchInput = styled(
  MuiInput,
  dontForwardProps("InputProps", "InputLabelProps", "backgroundColor", "borderColor")
)<SearchInputProps>(({ theme, backgroundColor, borderColor }) => ({
  backgroundColor: backgroundColor || theme.palette.bg.blank,
  borderRadius: theme.spacing(1),
  border: borderColor ? `1px solid ${borderColor}` : "none",

  input: {
    padding: theme.spacing(1.5),
    paddingLeft: 0,
  },

  fieldset: {
    border: "none",
  },

  "& .MuiInputAdornment-root": {
    // for the MagnifyingGlass and Close icons to have proper margins
    margin: theme.spacing(1.5),
    color: theme.palette.text.placeholder,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.placeholder + "24",
}));
