import { AddPlus } from "@bookpeep/ui";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuItemProps,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { dontForwardProps } from "helpers/styles";
import {
  MouseEventHandler,
  SyntheticEvent,
  TouchEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

import CentredSpinnerBox from "./CentredSpinnerBox";

export type Option = {
  value: number | null;
  label: string;

  additionalInfo?: string | React.ReactNode;
  groupBy?: string;
  isDisabled?: boolean;
};

// i did this because i wanted to get the type of some props of the Autocomplete component - Anfal The Great
type AutocompleteWithCustomOptionProps = AutocompleteProps<Option, true, boolean, true>;

// Or Maybe i should convert this component to extend Autocomplete props instead of Textfield props? 🤔 🤔 🤔
export type SearchableSelectFieldProps = Partial<TextFieldProps> & {
  disablePortal?: boolean;
  disableClearable?: AutocompleteWithCustomOptionProps["disableClearable"];

  label: string;
  disabled?: boolean;

  handleSelectValue: (value: number | null) => void;

  options: Option[];
  noOptionsText?: AutocompleteWithCustomOptionProps["noOptionsText"];
  areOptionsLoading?: AutocompleteWithCustomOptionProps["loading"];
  optionsLoadingText?: AutocompleteWithCustomOptionProps["loadingText"];
  value: Option["value"];
  groupBy?: AutocompleteWithCustomOptionProps["groupBy"];

  addButtonLabel?: string;
  shouldShowActionButton?: boolean;
  onAddButtonClick?: MouseEventHandler<HTMLButtonElement>;
  onAddButtonTouch?: TouchEventHandler<HTMLButtonElement>;
};

const SearchableSelectField = forwardRef(
  (
    {
      variant = "outlined",

      disablePortal = true,
      disableClearable,

      label,
      disabled,

      handleSelectValue,

      options,
      noOptionsText,
      areOptionsLoading,
      optionsLoadingText,
      value: currentValue,
      groupBy,

      addButtonLabel,
      shouldShowActionButton = false,
      onAddButtonClick,
      onAddButtonTouch,

      ...textFieldProps
    }: SearchableSelectFieldProps,
    ref
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState<EventTarget | null>(null);

    const getSelectedOptionFromCurrentValue = useCallback(
      (currentValue: Option["value"]) => {
        return options.find((option) => option.value === currentValue) ?? null;
      },
      [options]
    );

    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
      if (currentValue) {
        setSelectedOption(getSelectedOptionFromCurrentValue(currentValue));
      } else {
        setInputValue("");
        setSelectedOption(null);
      }
    }, [currentValue, getSelectedOptionFromCurrentValue]);

    const handleOpenMenu = (event: SyntheticEvent) => setIsMenuOpen(event.target);

    const handleCloseMenu = () => setIsMenuOpen(null);

    const handleChange = (_event: SyntheticEvent, newOption: Option | null) => {
      // the selected value from the list of options
      setSelectedOption(newOption);

      handleSelectValue(newOption?.value || null);
    };

    const handleSearchChange = (_event: SyntheticEvent, query: string) => setInputValue(query);

    const getOptionLabel = (option: Option) => option.label;

    const isOptionEqualToValue = (option: Option, selectedOption: Option) =>
      option.value === selectedOption.value;

    const renderOption = (props: MenuItemProps, option: Option) => {
      return (
        <MenuItem
          {...props}
          key={option.value}
          value={option.value || -1}
          onTouchStart={(event) => handleChange(event, option)}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography noWrap variant="primary" color="text.primary">
              {option.label}
            </Typography>

            {option.additionalInfo &&
              (typeof option.additionalInfo === "string" ? (
                <Typography noWrap textAlign="right" variant="secondary" color="text.secondary">
                  {option.additionalInfo}
                </Typography>
              ) : (
                option.additionalInfo
              ))}
          </Box>
        </MenuItem>
      );
    };

    const keepMenuOpen: MouseEventHandler<HTMLDivElement> | undefined = (event) => {
      event.preventDefault();
    };

    return (
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <Autocomplete
          disableClearable={disableClearable}
          disablePortal={disablePortal}
          ref={ref}
          disabled={disabled}
          getOptionDisabled={(option) => option.isDisabled || false}
          groupBy={groupBy}
          PaperComponent={({ children, ...props }) => (
            <Paper {...props} onMouseDown={keepMenuOpen}>
              {children}
              {shouldShowActionButton && (
                <AddButton
                  variant="text"
                  color="primary"
                  fullWidth
                  startIcon={<AddPlus />}
                  onClick={onAddButtonClick}
                  onTouchStart={onAddButtonTouch}
                >
                  {addButtonLabel}
                </AddButton>
              )}
            </Paper>
          )}
          open={!!isMenuOpen}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          openOnFocus
          options={options}
          loading={areOptionsLoading}
          loadingText={optionsLoadingText ?? <CentredSpinnerBox />}
          value={selectedOption}
          inputValue={inputValue}
          onChange={(event, value) => handleChange(event, value)}
          onInputChange={handleSearchChange}
          noOptionsText={noOptionsText}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          defaultValue={null}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              {...textFieldProps}
              variant={variant}
              ref={params.InputProps.ref}
              label={label}
              value={inputValue}
              onBlur={handleCloseMenu}
              autoComplete="off"
            />
          )}
        />
      </ClickAwayListener>
    );
  }
);

export default SearchableSelectField;

const AddButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.bg.secondary,
}));

type CustomTextFieldProps = TextFieldProps & {
  warning?: boolean;
};
const CustomTextField = styled(
  TextField,
  dontForwardProps("warning")
)<CustomTextFieldProps>(({ theme, warning }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: warning && "#FF9800",
  },
  "&:hover .MuiOutlinedInput-input": { borderColor: warning && "#FF9800" },
  "&:hover .MuiInputLabel-root": { borderColor: warning && "#FF9800" },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: warning && "#FF9800",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    borderColor: warning && "#FF9800",
  },
  "& .css-1o0qud-MuiFormHelperText-root": {
    color: warning && "#FF9800",
  },
}));
