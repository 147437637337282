import http from "utils/http";

const appointmentsApi = {
  // /?start_date=2022-02-15&end_date=2022-02-15&outlet=16
  getAppointmentsAndDetailsByDates: async ({ startDate, endDate, outletId }) =>
    http.get(
      `appointments/calendar/?start_date=${startDate}&end_date=${endDate}&outlet=${outletId}`
    ),

  getAppointmentDetails: async (appointmentId) =>
    http.get(`appointments/v2/appointment_details/?appointment_id=${appointmentId}`),

  getAllAppointments: async () => http.get("appointments/v2/appointments/"),

  getAppointment: async (id) => http.get(`appointments/v2/appointments/${id}/`),

  createAppointment: async ({ data }) => http.post("appointments/v2/appointments/", data),

  updateAppointment: async ({ id, data }) => http.put(`appointments/v2/appointments/${id}/`, data),

  removeAppointment: async (id) => http.delete(`appointments/v2/appointments/${id}/`),

  updateArticleSlotPrice: async ({ data }) => http.put(`appointments/slot_pricing/`, data),
};

export default appointmentsApi;
