import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { zonesAdapter } from "./zonesSlice";

export const selectZonesState = (state: RootState) => state.zones;

export const selectAreZonesLoading = (state: RootState) =>
  selectZonesState(state).status === SliceStatus.LOADING;

const { selectAll, selectById } = zonesAdapter.getSelectors(selectZonesState);

export const selectAllZones = selectAll;

export const selectAllActiveZones = (state: RootState) =>
  selectAllZones(state).filter((zone) => !zone.deprecated);

export const selectZoneById = (id: number | string) => (state: RootState) => selectById(state, id);
