import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { SubscriptionPurchase } from "types/SubscriptionPurchase";

import { SliceStatus } from "../utils";
import api from "./subscriptionPurchasesApi";

export const subscriptionPurchasesAdapter = createEntityAdapter<SubscriptionPurchase>({
  selectId: (subscriptionPurchase) => subscriptionPurchase.id,
});

const initialState = subscriptionPurchasesAdapter.getInitialState<{
  status: SliceStatus;
  detailStatus: SliceStatus;
  next: string;
  previous: string;
  totalPages: number;
  results: SubscriptionPurchase[];
}>({
  status: SliceStatus.IDLE,
  detailStatus: SliceStatus.IDLE,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
});

export const getSubscriptionPurchases = createThunkFromApiWithType(
  "subscriptionPurchases/getSubscriptionPurchases",
  api.getSubscriptionPurchases
);

export const appendSubscriptionPurchases = createThunkFromApiWithType(
  "subscriptionPurchases/appendSubscriptionPurchases",
  api.getSubscriptionPurchases
);

export const getSubscriptionPurchasesExcel = createThunkFromApiWithType(
  "subscriptionPurchases/getSubscriptionPurchasesExcel",
  api.getSubscriptionPurchasesExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const extendSubscriptionPurchase = createThunkFromApiWithType(
  "subscriptionPurchases/extendSubscriptionPurchase",
  api.extendSubscriptionPurchase
);

export const subscriptionPurchasesSlice = createSlice({
  name: "subscriptionPurchases",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getSubscriptionPurchases.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getSubscriptionPurchases.fulfilled, (state, { payload }) => {
      subscriptionPurchasesAdapter.setAll(state, payload.results.results);
      state.totalPages = payload.totalPages;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSubscriptionPurchases.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendSubscriptionPurchases.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendSubscriptionPurchases.fulfilled, (state, { payload }) => {
      subscriptionPurchasesAdapter.upsertMany(state, payload.results.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendSubscriptionPurchases.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(extendSubscriptionPurchase.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(extendSubscriptionPurchase.fulfilled, (state, { payload }) => {
      subscriptionPurchasesAdapter.upsertOne(state, payload)
      state.status = SliceStatus.IDLE;
    })
    reducers.addCase(extendSubscriptionPurchase.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default subscriptionPurchasesSlice.reducer;
