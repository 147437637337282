import { Box, FormHelperText, Typography } from "@mui/material";
import StatusChip from "components/common/StatusChip";
import truncate from "helpers/truncate";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type PurchasableItemTitleContentProps = {
  title: string;
  subtitle?: string | ReactNode;
  description?: string;
  descriptionTitle?: string;
  isSingleVariant: boolean;
  shouldShowSubscriptionTag: boolean;
  helperText?: string;
};

const PurchasableItemTitleContent = ({
  title,
  subtitle = "",
  description = "",
  descriptionTitle = "",
  isSingleVariant,
  shouldShowSubscriptionTag,
  helperText,
}: PurchasableItemTitleContentProps) => {
  const { t } = useTranslation("subscriptions");

  const hasSecondaryTitle = !!subtitle && !!description;

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        columnGap={2}
        rowGap={1}
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" columnGap={0.5} rowGap={2} flexWrap="wrap">
          <Typography
            variant="primary"
            fontWeight={500}
            fontSize={isSingleVariant ? "initial" : "0.8rem"}
          >
            {truncate({ string: title, maxCharacters: 20 }).string}
          </Typography>

          {hasSecondaryTitle && (
            <Typography color="text.placeholder" variant="secondary">
              {subtitle}
            </Typography>
          )}
        </Box>

        {shouldShowSubscriptionTag && (
          <StatusChip label={t("subscriptions:subscription")} statusColor="#E4FAF7" size="small" />
        )}
      </Box>

      <Box display="block">
        {descriptionTitle && (
          <Typography
            color="primary.main"
            variant="secondary"
            fontSize="0.785rem"
            fontWeight={500}
            display="block"
            pt={1}
          >
            {descriptionTitle}
          </Typography>
        )}

        <Typography color="text.placeholder" variant="secondary" sx={{ whiteSpace: "pre-line" }}>
          {hasSecondaryTitle ? description : subtitle}
        </Typography>

        {helperText && (
          <FormHelperText
            sx={{
              fontSize: "0.625rem",
              color: "error.main",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default PurchasableItemTitleContent;
