import { RootState } from "store";

import { SliceStatus } from "../utils";
import { subscriptionsAdapter } from "./subscriptionsSlice";

export const selectSubscriptionsLoading = (state: RootState) =>
  state.subscriptions.status === SliceStatus.LOADING;

export const selectSubscriptionsTotalPages = (state: RootState) => state.subscriptions.totalPages;

export const selectSubscriptionsState = (state: RootState) => state.subscriptions;

const { selectById, selectAll } = subscriptionsAdapter.getSelectors(selectSubscriptionsState);

export const selectAllSubscriptions = selectAll;

export const selectAllSubscriptionsInPage = (state: RootState) => {
  return state.subscriptions.page.map((subscriptionId: number) => {
    return selectById(state, subscriptionId);
  });
};

export const selectSubscriptionById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectActivePurchasableSubscriptionsByOutletId =
  (outletId: number) => (state: RootState) => {
    const allSubscriptions = selectAllSubscriptions(state);

    const purchasableSubscriptions = allSubscriptions.filter(
      (subscription) => subscription.sellingOutlets.includes(outletId) && subscription.active
    );

    return purchasableSubscriptions;
  };

export const selectRedeemableSubscriptionsByOutletId = (outletId: number) => (state: RootState) => {
  const allSubscriptions = selectAllSubscriptions(state);

  const redeemableSubscriptions = allSubscriptions.filter((subscription) =>
    subscription.redeemingOutlets.includes(outletId)
  );

  return redeemableSubscriptions;
};
