import http from "utils/http";

export type DjangoDetailResponse = {
  detail: string;
};

type LoginCredentials = {
  username: string;
  password: string;
};

export type LoginArguments = LoginCredentials & {
  shouldRememberUser: boolean;
};

export type LoginResponse = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  access: string;
  refresh: string;
  isOwnerOrAdmin: boolean;
  isEmployee: boolean;
  isTracked: boolean;
  employeeId: number | null;
  organizationName: string;
  organizationId: string;
  dailyPerformanceEmail: boolean;
  weeklyPerformanceEmail: boolean;
  onlineBookingConfirmationEmail: boolean;
};

export type LoginRejected = DjangoDetailResponse | string;

export type RegisterArguments = {
  username: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  usernameType: string;
};

export type RegisterResponse = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  usernameType: string;
};

export type ChangePasswordArguments = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type RefreshResponse = {
  access: string;
  refresh: string;
};

export type ForgotPasswordArguments = {
  username: string;
};

export type ForgotPasswordResponse = {
  status: string;
};

export type ResetPasswordArguments = {
  token: string;
  password: string;
};

export type ResetPasswordResponse = {
  status: string;
};

export type ValidateTokenArguments = {
  token: string;
};

export type ValidateTokenResponse = {
  status: string;
};

export type UpdateUserArguments = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
};

export type VerifyArguments = {
  token: string;
};

export type VerifyResponse = {
  detail: string;
};

export type RequestVerifyArguments = {};

export type UpdateUserResponse = {
  id: number;
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneCountryCode: string;
  usernameType?: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  isOwnerOrAdmin: boolean;
  isEmployee: boolean;
  isTracked: boolean;
  employeeId: number | null;
  organizationName: string;
  organizationId: string;
  dailyPerformanceEmail: boolean;
  weeklyPerformanceEmail: boolean;
  onlineBookingConfirmationEmail: boolean;
};

const authApi = {
  login: async (credentials: LoginCredentials) =>
    http.post<LoginResponse>("users/signin/", credentials),

  register: async (formValues: RegisterArguments) =>
    http.post<RegisterResponse>("users/signup/", formValues),

  verifyEmail: async (data: VerifyArguments) =>
    http.get<VerifyResponse>(`users/email-verify/?token=${data.token}`),

  requestVerifyEmail: async () => http.post<VerifyResponse>(`users/request-verify-email/`),

  changePassword: async (formValues: ChangePasswordArguments) =>
    http.put<DjangoDetailResponse>("users/change-password/", formValues),

  refreshToken: async (refresh: string) =>
    http.post<RefreshResponse>("users/token/refresh/", { refresh }),

  forgotPassword: async (data: ForgotPasswordArguments) =>
    http.post<ForgotPasswordResponse>("users/forget-password/", data),

  resetPassword: async (data: ResetPasswordArguments) =>
    http.post<ResetPasswordResponse>("users/forget-password/confirm/", data),

  validateResetToken: async (data: ValidateTokenArguments) =>
    http.post<ValidateTokenResponse>("users/forget-password/validate_token/", data),

  updateUser: async (data: UpdateUserArguments) =>
    http.put<UpdateUserResponse>("users/user/", data),
};

export default authApi;
