import { Box } from "@mui/material";
import ClientSection from "components/ClientSection";
import {
  ClientSectionProps,
  EditClientInfo,
  NewClient,
} from "components/ClientSection/ClientSection";
import getIsTheOnlyStaffAssignedToTheAppointment from "helpers/getIsTheOnlyStaffAssignedToTheAppointment";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useFormValidation from "hooks/useFormValidation";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import auth from "utils/auth";

import AppointmentFormClientSectionHeader from "./AppointmentFormClientSectionHeader";
import { AppointmentFormValues, DEFAULT_APPOINTMENT_FORM_CLIENT } from "./utils";

type AppointmentFormClientSectionProps = {
  existingClientActionButton: React.ReactNode;

  // Add additional functionality to the existing behavior
  handleResetClient?: () => void;
  onChooseExistingClient?: () => void;
  clientSectionProps?: Partial<ClientSectionProps>;
};
const AppointmentFormClientSection = ({
  existingClientActionButton,
  handleResetClient = () => {},
  onChooseExistingClient = () => {},
  clientSectionProps,
}: AppointmentFormClientSectionProps) => {
  const { t } = useTranslation("appointments");

  const { setAppointmentDrawerClient, setIsAppointmentDrawerExpanded, clientId } =
    useAppointmentDrawer();
  const { outletId } = useContext(CalendarPageContext);
  const { setValue, watch, formState } = useFormContext<AppointmentFormValues>();

  const registerValidation = useFormValidation(formState);

  const { hasPersonalAppointment, hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const { createdOnline: isAppointmentCreatedOnline, appointmentId, client } = watch();

  // Deconstructing articles above is causing a type issue because for some reason the startTime and endTime in slots are
  // being typed as functions of DateTime but doing it like the following solves the issue
  const articles = watch("articles");

  const { firstName, lastName, phoneNumber, phoneCountryCode } = client;

  const newClient = {
    firstName,
    lastName,
    phoneNumber,
    phoneCountryCode,
  };

  const isEditingAppointment = !!appointmentId;

  // if the user has hasPersonalAppointment.editAccess permission we need to check if he can edit status of this appointment, he can edit the status only if he is the only staff working on it
  const isTheOnlyStaffAssignedToTheAppointment = authenticatedUserEmployeeId
    ? getIsTheOnlyStaffAssignedToTheAppointment({
        articles,
        authenticatedUserEmployeeId,
      })
    : false;

  const canChangeClient =
    !isAppointmentCreatedOnline &&
    (hasNonPersonalAppointment.editAccess ||
      (isTheOnlyStaffAssignedToTheAppointment && hasPersonalAppointment.editAccess));

  const shouldShowNoClient = !clientId && !newClient && !canChangeClient && isEditingAppointment;

  const setExistingClientId = (clientId: Nullable<number>) => {
    setValue("client", { ...DEFAULT_APPOINTMENT_FORM_CLIENT, id: clientId });

    setAppointmentDrawerClient(clientId);
    if (clientId) onChooseExistingClient();

    // collapse appointment drawer if no client is selected
    if (!clientId) setIsAppointmentDrawerExpanded(false);
  };

  const setNewClient = (client: Nullable<NewClient>) => {
    if (client)
      setValue("client", {
        ...client,
        isNewClient: true,
        id: null,
      });
  };

  const setUpdatedClientData = (client: Nullable<EditClientInfo>) => {
    if (client) {
      setValue("client", {
        ...client,
        isNewClient: false,
      });
    }
  };

  return (
    <Box p={3} boxShadow="divider">
      <ClientSection
        canEditClientDetails={!isAppointmentCreatedOnline}
        setUpdatedClientData={setUpdatedClientData}
        newClient={newClient}
        shouldShowTags={false}
        setNewClient={setNewClient}
        existingClientId={clientId}
        setExistingClientId={setExistingClientId}
        outletId={outletId}
        allowedToChangeClient={canChangeClient}
        shouldShowNoClient={shouldShowNoClient}
        shouldTriggerValidation={formState.isSubmitting}
        existingClientActionButton={existingClientActionButton}
        {...registerValidation("phoneNumber")} // i think this is not needed
        searchClientsSectionHeader={
          <AppointmentFormClientSectionHeader title={t("chooseClient")} pb={2} />
        }
        addNewClientSectionHeader={
          <AppointmentFormClientSectionHeader title={t(clientId ? "editClient" : "addClient")} />
        }
        {...clientSectionProps}
      />
    </Box>
  );
};

export default AppointmentFormClientSection;
