import http from "utils/http";

const servicesApi = {
  getAllServices: async () => http.get("services/services/"),

  getService: async ({ id }) => http.get(`services/services/${id}/`),

  createService: async ({ data }) => http.post("services/services/", data),

  updateService: async (id, data) => {
    return http.put(`services/services/${id}/`, data);
  },
  updateServicePosition: async (data) => {
    return http.put(`services/update_service_positions/`, data);
  },

  removeService: async (id) => http.delete(`services/services/${id}/`),
};

export default servicesApi;
