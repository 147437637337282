import http from "utils/http";

const invoicePaymentsApi = {
  getAllInvoicePayments: async () => http.get("invoices/payments/"),

  getInvoicePayment: async (id) => http.get(`invoices/payments/${id}/`),

  createInvoicePayment: async (data) => http.post("invoices/payments/", data),

  updateInvoicePayment: async (id, data) =>
    http.put(`invoices/payments/${id}/`, data),

  removeInvoicePayment: async (id) => http.delete(`invoices/payments/${id}/`),
};

export default invoicePaymentsApi;
