import { Divider, Grid, Typography } from "@mui/material";
import { checkIfArticleHasSpecialDiscount } from "helpers/articles";
import { Invoice } from "types/Checkout";

import InvoiceArticleItemDescription from "./InvoiceArticleItemDescription";
import InvoiceArticlePrice from "./InvoiceArticlePrice";
import InvoiceArticleRow from "./InvoiceArticleRow";
import InvoiceArticlesHeader from "./InvoiceArticlesHeader";

type InvoiceArticlesSectionProps = {
  articles: Invoice["articles"];
};

const InvoiceArticlesSection = ({ articles }: InvoiceArticlesSectionProps) => {
  return (
    <>
      <Grid container spacing={3}>
        <InvoiceArticlesHeader />
        {articles.map((article, index) => (
          <InvoiceArticleRow
            key={index}
            quantityColumnContent={<Typography variant="h5">1 X</Typography>}
            itemsDescriptionColumnContent={<InvoiceArticleItemDescription article={article} />}
            priceColumnContent={
              <InvoiceArticlePrice
                finalPrice={Number(article.finalPrice)}
                originalPrice={
                  checkIfArticleHasSpecialDiscount({
                    markedPrice: article.markedPrice || 0,
                    originalPrice: article.originalPrice,
                    finalPrice: article.finalPrice,
                  })
                    ? Number(article.originalPrice)
                    : Number(article.markedPrice)
                }
              />
            }
          />
        ))}
      </Grid>

      <Divider variant="fullWidth" />
    </>
  );
};

export default InvoiceArticlesSection;
