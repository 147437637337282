import getFormattedDuration from "helpers/getFormattedDuration";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectActiveSubscriptionByClientIdAndOutletId } from "store/clients/clientsSlice";
import { selectAllServiceVariantActivePromotions } from "store/selectors";
import { PurchasableItemOption } from "types/PurchasableItem";
import { ServiceVariantPricingType, ServiceWithVariants } from "types/ServiceV2";
import { ClientSubscriptionPurchase } from "types/Subscription";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItem from "../PurchasableItem";
import PurchasableItemWithMultiVariants from "../PurchasableItemWithMultiVariants";

type ServicesPurchasableItemsProps = {
  services: ServiceWithVariants[];
};

const ServicesPurchasableItems = ({ services }: ServicesPurchasableItemsProps) => {
  const { t } = useTranslation(["loyalty"]);

  const {
    onSelectItem,
    clientId,
    outletId,
    selectedItems,
    shouldHighlightSelectedItems,
    shouldShowPromotionTag,
    shouldDisableLoyaltyIneligibleItems,
  } = usePurchasableItemsSelector();

  const clientSubscriptions = useSelector(
    selectActiveSubscriptionByClientIdAndOutletId(clientId, outletId)
  ) as ClientSubscriptionPurchase[];

  const serviceVariantActivePromotions = useSelector(selectAllServiceVariantActivePromotions);

  const selectedServiceIds = selectedItems?.[PurchasableItemOption.Service] || [];

  return (
    <>
      {services.map((service) => {
        const { variants, ...rest } = service;

        const firstVariant = variants?.[0];

        const serviceVariantIds = variants.map(({ id }) => id);

        const variantSubscriptions = clientSubscriptions.filter((subscription) =>
          subscription?.serviceVariants?.some((variantId) => serviceVariantIds.includes(variantId))
        );

        const serviceVariantsActivePromotions = shouldShowPromotionTag
          ? serviceVariantActivePromotions.filter((promotion) =>
              promotion.serviceVariant
                ? serviceVariantIds.includes(promotion.serviceVariant)
                : promotion.hasAllServices
            )
          : [];
        const doesFirstVariantHaveSubscription = variantSubscriptions.some((subscription) => {
          return subscription.serviceVariants.includes(firstVariant.id);
        });

        const doesFirstVariantHaveActivePromotion = serviceVariantsActivePromotions.some(
          (promotion) => promotion.serviceVariant === firstVariant.id || promotion.hasAllServices
        );

        const isServiceVariantSelected = selectedServiceIds.includes(firstVariant.id);

        const selectedVariantsOfCurrentService = selectedServiceIds.filter((id) =>
          serviceVariantIds.includes(id)
        );

        const shouldHighlight = shouldHighlightSelectedItems && isServiceVariantSelected;

        const helperText = shouldDisableLoyaltyIneligibleItems
          ? t("thisItemExistsInAnotherLoyaltyProgram")
          : "";

        return variants.length > 1 || firstVariant.name !== service.name ? (
          <PurchasableItemWithMultiVariants
            key={`MultiVariantService-${service.id}-${service.name}`}
            title={service.name}
            selectedVariantIds={
              shouldHighlightSelectedItems ? selectedVariantsOfCurrentService : []
            }
            shouldDisableLoyaltyIneligibleItems={Boolean(shouldDisableLoyaltyIneligibleItems)}
            helperText={helperText}
            variants={variants}
            variantSubscriptions={variantSubscriptions}
            variantPromotions={serviceVariantsActivePromotions}
            handleClick={(serviceVariant) =>
              onSelectItem(
                { ...serviceVariant, service: { ...rest } },
                PurchasableItemOption.Service
              )
            }
          />
        ) : (
          <PurchasableItem
            key={`SingleVariantService-${service.id}-${firstVariant.name}`}
            isHighlighted={shouldHighlight}
            isDisabled={firstVariant.isLoyaltyEligible && shouldDisableLoyaltyIneligibleItems}
            helperText={helperText}
            title={service.name}
            subtitle={getFormattedDuration(firstVariant.duration)}
            price={firstVariant.price}
            shouldShowPromotionTag={doesFirstVariantHaveActivePromotion}
            shouldShowSubscriptionTag={doesFirstVariantHaveSubscription}
            shouldShowPricingFrom={firstVariant.pricingType === ServiceVariantPricingType.From}
            handleClick={() =>
              onSelectItem({ ...firstVariant, service: { ...rest } }, PurchasableItemOption.Service)
            }
          />
        );
      })}
    </>
  );
};

export default ServicesPurchasableItems;
