// Dear teammate, whenever a new Permission is add to the BE please add it here as well, for it to work properly. Or else the new permission will not be recognized by the FE. - Anfal The great
export enum PermissionType {
  hasAppointments = 1,
  hasPersonalAppointment = 2,
  hasNonPersonalAppointment = 3,
  hasCheckout = 4,
  hasVoid = 5,
  hasUnpaid = 6,
  hasClients = 7,
  hasStaff = 8,
  hasStaffManagement = 9,
  hasStaffShifts = 10,
  hasClosedDates = 11,
  hasServicesAndPackagesView = 36,
  hasPackages = 13,
  hasPackagesManage = 14,
  hasSalesDashboard = 15,
  hasSalesLog = 16,
  hasSalesDownload = 17,
  hasLanguageSettings = 18,
  hasDiscount = 19,
  hasProducts = 20,
  hasDailySales = 21,
  hasAllSales = 22,
  hasExpenses = 23,
  hasDeposits = 24,
  hasPersonalManageExpenses = 25,
  hasNonPersonalExpenses = 26,
  hasManageAcceptingOnlineBookingsAccess = 27,
  hasClientWalletsPermission = 28,
  hasResourceManageAccess = 29,
  hasRefundInvoices = 30,
  hasSubscriptionAndVoucherExtend = 31,
  hasClientAnalyticsAccess = 32,
  hasPeepPay = 33,
  hasPeepPaySendAccess = 34,
  hasPeepPayCancelAccess = 35,
  hasServicesAndPackagesManage = 37,
  hasServicesAndPackagesOnlineAccess = 38,
  hasServicesAndPackagesOnlinePaymentSettingsAccess = 39,
}

export type PermissionValue = 1 | 2 | 3 | 4 | 5;

type Permission = {
  id: number;
  type: PermissionType;
  value: PermissionValue;
  role: number;
};

type Role = {
  id: number;
  permissions: Permission[];
  totalEmployees: number;
  name: string;
  description: string;
  isSuperAdmin: boolean;
  isGlobal: boolean;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  organization: number;
};

export default Role;
