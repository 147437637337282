import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Tag } from "types/Tag";

import { SliceStatus } from "../utils";
import api from "./tagsApi";

export const tagAdapter = createEntityAdapter<Tag>({
  selectId: (tag) => tag.id,
});

const initialState = tagAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getTags = createThunkFromApiWithType("tags/getTags", api.getTags);

export const tagsSlice = createSlice({
  name: "tags",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getTags.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getTags.fulfilled, (state, { payload }) => {
      tagAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getTags.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default tagsSlice.reducer;
