import { Close } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";

type DetailsModalHeaderProps = {
  title: string;
  onClose: () => void;
};

const DetailsModalHeader = ({ title, onClose }: DetailsModalHeaderProps) => {
  const isMobileView = useIsMobileView();

  return (
    <Box
      boxShadow="divider"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      py={2}
    >
      <Typography variant={isMobileView ? "h4" : "h3"}>{title}</Typography>

      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default DetailsModalHeader;
