import { ArrowLeft } from "@bookpeep/ui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  DialogTitle as MuiDialogTitle,
  Slide,
  SlideProps,
  Typography,
  styled,
} from "@mui/material";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props?.children}
    </Slide>
  );
});

type FullWidthModalProps = {
  open: boolean;
  onClose: () => void;

  title?: string;
  children: React.ReactNode;
  navActions?: React.ReactNode;
  footer?: React.ReactNode;
};

export default function FullWidthModal({
  open,
  onClose,
  title,
  children,
  navActions,
  footer,
}: FullWidthModalProps) {
  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="paper"
    >
      <DialogTitle>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            {onClose && (
              <IconButton onClick={() => onClose()} aria-label="close">
                <ArrowLeft sx={{ fontColor: "black" }} />
              </IconButton>
            )}
          </Grid>

          <Grid item xs={4}>
            {title && (
              <Typography variant="h3" align="center">
                {title}
              </Typography>
            )}
          </Grid>

          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {navActions && navActions}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent
        sx={{
          backgroundColor: (theme) => theme.palette.bg.secondary,
          padding: 0,
        }}
      >
        {children}
      </DialogContent>

      {footer && <DialogActions sx={{ padding: 0 }}>{footer}</DialogActions>}
    </Dialog>
  );
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: theme.palette.bg.blank,
  borderBottom: `1px solid #4E5D7825`,
  boxShadow: theme.shadows.divider,

  padding: theme.spacing(1, 2),
  width: "100%",
  maxHeight: "81px",
  minHeight: "81px",
}));
