import { PageTabs } from "components/common";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import AppointmentFormContext from "../../../AppointmentFormContext";
import PackagesPanel from "./PackagesPanel";
import ServicesPanel from "./ServicesPanel";

const SearchServicesTabsView = () => {
  const { t } = useTranslation("appointments");

  const { activeTabIndex, setActiveTabIndex } = useContext(AppointmentFormContext);

  const tabs = [
    {
      id: 0,
      label: t("services"),
      component: <ServicesPanel isActiveTab={activeTabIndex === 0} />,
    },
    {
      id: 1,
      label: t("packages"),
      component: <PackagesPanel isActiveTab={activeTabIndex === 1} />,
    },
  ];

  return (
    <PageTabs
      withRoutes={false}
      tabs={tabs}
      withContainer={false}
      activeTabIndex={activeTabIndex}
      setActiveTabIndex={setActiveTabIndex}
    />
  );
};

export default SearchServicesTabsView;
