import { DoubleChevronLeft, DoubleChevronRight } from "@bookpeep/ui";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Slide,
  SlideProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { ReactNode, forwardRef, useState } from "react";
import useQuery from "utils/navigation/useQuery";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return (
    <Slide direction="right" ref={ref} {...props}>
      {props?.children}
    </Slide>
  );
});

type TableSideBarProps = {
  children: ReactNode;
  toOpenTitle: string;
  toCloseTitle: string;
};

const TableSideBar = ({ children, toOpenTitle, toCloseTitle }: TableSideBarProps) => {
  const theme = useTheme();

  const query = useQuery();

  const isMobileView = useIsMobileView();

  const [isOpenOnMobile, setIsOpenOnMobile] = useState(false);

  const pageQuery = query.get("page");

  const getToggleSideBarIcon = () => {
    if (theme.direction === "ltr") {
      return isOpenOnMobile ? (
        <DoubleChevronRight sx={{ width: 13, height: 13 }} />
      ) : (
        <DoubleChevronLeft sx={{ width: 13, height: 13 }} />
      );
    } else {
      return isOpenOnMobile ? (
        <DoubleChevronLeft sx={{ width: 13, height: 13 }} />
      ) : (
        <DoubleChevronRight sx={{ width: 10, height: 10 }} />
      );
    }
  };

  if (isMobileView)
    return (
      <>
        <Box onClick={() => setIsOpenOnMobile(true)}>
          <StyledOpenButton>
            <Typography
              color="text.secondary"
              fontWeight={700}
              display="flex"
              alignItems="center"
              columnGap={1}
            >
              {getToggleSideBarIcon()} {toOpenTitle}
            </Typography>
          </StyledOpenButton>
        </Box>

        <Dialog
          sx={{ zIndex: 50 }}
          fullScreen
          fullWidth
          open={isOpenOnMobile}
          TransitionComponent={Transition}
        >
          <DialogContent
            sx={{
              backgroundColor: (theme) => theme.palette.bg.secondary,
              padding: 0,
              paddingTop: pageQuery ? (theme) => theme.spacing(15) : theme.spacing(7),
            }}
          >
            <Box onClick={() => setIsOpenOnMobile(false)} p={2} sx={{ cursor: "pointer" }}>
              <Typography
                color="text.secondary"
                justifyContent="right"
                display="flex"
                alignItems="center"
                columnGap={1}
              >
                {toCloseTitle} {getToggleSideBarIcon()}
              </Typography>
            </Box>

            <PageWrapper isMobileView={isMobileView}>{children}</PageWrapper>
          </DialogContent>
        </Dialog>
      </>
    );

  return <PageWrapper>{children}</PageWrapper>;
};

export default TableSideBar;

type PageWrapperProps = {
  isMobileView?: boolean;
};

const PageWrapper = styled(Box)<PageWrapperProps>(({ theme, isMobileView = false }) => ({
  height: isMobileView ? "86vh" : "100%",
  backgroundColor: theme.palette.bg.blank,
  overflow: "scroll",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),

  marginLeft: isMobileView ? theme.spacing(3) : theme.spacing(0),
  marginRight: isMobileView ? theme.spacing(3) : theme.spacing(0),
  marginBottom: 0,
}));

const StyledOpenButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  borderRadius: theme.spacing(1),
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
  "&:hover": {
    backgroundColor: theme.palette.bg.blank,
  },
}));
