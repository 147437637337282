import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { selectAllActiveOutlets } from "store/outlets/outletsSlice";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./organizationOpeningHoursApi";

const organizationOpeningHoursAdapter = createEntityAdapter();

const initialState = organizationOpeningHoursAdapter.getInitialState({
  isLoading: false,
  error: "",
});

const { selectAll } = organizationOpeningHoursAdapter.getSelectors(
  (state) => state.organizationOpeningHours
);

export const selectAllOrganizationOpeningHours = selectAll;

export const selectAllOutletsWithOpeningHours = (state) => {
  const allOutlets = selectAllActiveOutlets(state);
  const allOpeningHours = selectAllOrganizationOpeningHours(state);

  return allOutlets.map((outlet) => {
    const openingHours = allOpeningHours.filter((hour) => hour.outlet === outlet.id);
    return {
      ...outlet,
      openingHours,
    };
  });
};

export const selectOpeningHoursByOutletId = (id) => (state) => {
  const allOpeningHours = selectAll(state);
  const openingHours = allOpeningHours?.filter((day) => day.outlet === id);
  return openingHours;
};

export const selectOpeningHoursLoading = () => (state) => {
  return state.organizationOpeningHours.isLoading;
};

export const getOrganizationOpeningHours = createThunkFromApi(
  "organization/getOrganizationOpeningHours",
  api.getOrganizationOpeningHours
);

export const createOrUpdateOutletOpeningHours = createThunkFromApi(
  "outlets/createOrUpdateOrganizationOpeningHours",
  api.createOrUpdateOutletOpeningHours
);

export const organizationOpeningHoursSlice = createSlice({
  name: "organizationOpeningHours",
  initialState,

  extraReducers: {
    [getOrganizationOpeningHours.pending]: (state) => {
      state.isLoading = true;
    },

    [getOrganizationOpeningHours.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      organizationOpeningHoursAdapter.setAll(state, payload);
    },

    [getOrganizationOpeningHours.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [createOrUpdateOutletOpeningHours.fulfilled]: (state, { payload }) => {
      organizationOpeningHoursAdapter.upsertMany(state, payload);
    },
  },
});

export default organizationOpeningHoursSlice.reducer;
