import { RootState } from "store";

import { SliceStatus } from "../utils";
import { appointmentsOverviewAdapter } from "./appointmentsOverviewSlice";

export const selectAppointmentsOverviewLoading = (state: RootState) =>
  state.appointmentsOverview.status === SliceStatus.LOADING;


export const selectAppointmentsOverviewTotalPages = (state: RootState) =>
  state.appointmentsOverview.totalPages;

export const selectAppointmentsOverviewState = (state: RootState) => state.appointmentsOverview;

const { selectAll } = appointmentsOverviewAdapter.getSelectors(selectAppointmentsOverviewState);

export const selectAllAppointmentsOverview = selectAll;

export const selectAppointmentsCount = (state: RootState) => {
  return {
    today: state.appointmentsOverview.todaysCount,
    upcoming: state.appointmentsOverview.upcomingCount,
  };
};