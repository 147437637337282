import { ChevronDown } from "@bookpeep/ui";
import { DatePicker } from "@mui/lab";
import { Button } from "@mui/material";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext, useState } from "react";

import AppointmentFormContext from "../AppointmentFormContext";

const DatePickerButton = ({ disabled }) => {
  const { date, setDate } = useContext(CalendarPageContext);
  const { setAppointmentDate } = useContext(AppointmentFormContext);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleDateChange = (date) => {
    setDate(date);
    setAppointmentDate(date);
  };

  return (
    <DatePicker
      disableHighlightToday
      value={date}
      open={isCalendarOpen}
      onChange={handleDateChange}
      onClose={() => setIsCalendarOpen(false)}
      container="inline"
      renderInput={(params) => (
        <Button
          disabled={disabled}
          onClick={() => setIsCalendarOpen(true)}
          variant="text"
          endIcon={<ChevronDown color={disabled ? "disabled" : "primary"} />}
          ref={params.inputRef}
        >
          {date.toFormat("EEEE, dd MMM yyyy")}
        </Button>
      )}
    />
  );
};

export default DatePickerButton;
