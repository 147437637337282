import stringifyArray from "helpers/stringifyArray";
import { ProductBrandDetails } from "types/Brand";
import http from "utils/http";

export type CreateOrEditProductBrandType = {
  name: ProductBrand["name"];
};

type BrandsResponse = {
  results: {
    results: ProductBrandDetails[];
  };
  next: Nullable<string>;
  previous: Nullable<string>;
  totalPages: number;
};

type EditBrandArguments = {
  id: number;
  data: CreateOrEditProductBrandType;
};

const productBrandApi = {
  getAllProductBrands: async () => http.get<ProductBrand[]>("products/brands-brief/"),

  getBrands: async ({
    page,
    searchQuery,
    categoriesSelection,
    suppliersIds,
  }: {
    page: number;
    searchQuery: string;
    categoriesSelection: number[];
    suppliersIds: number[];
  }) =>
    http.get<BrandsResponse>(
      `products/brands/?page=${page}${searchQuery ? `&search=${searchQuery}` : ""}${
        categoriesSelection && !!categoriesSelection.length
          ? `&categories=${stringifyArray(categoriesSelection.join().split(","))}`
          : ""
      }${
        suppliersIds && !!suppliersIds.length
          ? `&suppliers=${stringifyArray(suppliersIds.join().split(","))}`
          : ""
      }`
    ),

  createProductBrand: async (data: CreateOrEditProductBrandType) =>
    http.post<ProductBrand>(`products/brands/`, data),

  updateBrand: async ({ id, data }: EditBrandArguments) =>
    http.put<ProductBrand>(`products/brands/${id}/`, data),

  deleteBrand: async ({ id }: { id: number }) => http.delete(`products/brands/${id}/`),

  getProductBrandDetails: async ({ id }: { id: number }) =>
    http.get<ProductBrand>(`products/brands/${id}/`),
};

export default productBrandApi;
