import { AddPlus, DeleteX } from "@bookpeep/ui";
import { Box, BoxProps, Button, Typography, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

type ActionChipProps = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  secondTitle?: string | React.ReactNode;

  shouldShowAction?: boolean;
  applyActionTitle?: string | React.ReactNode;
  removeActionTitle?: string | React.ReactNode;
  handleApplyAction?: () => void;
  handleRemoveAction?: () => void;

  hasAppliedAction: boolean;

  hasFullWidth?: boolean;

  hasPermission?: boolean;
  gap?: number;
};

const ActionChip = ({
  icon,
  title,
  secondTitle,

  hasFullWidth = true,

  shouldShowAction = true,
  applyActionTitle,
  removeActionTitle,
  handleApplyAction,
  handleRemoveAction,
  hasAppliedAction,
  hasPermission = true,
  gap = 3,
}: ActionChipProps) => {
  return (
    <Chip
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      shouldApplyBackground={hasAppliedAction}
      hasFullWidth={hasFullWidth}
      gap={2}
    >
      <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" gap={gap}>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={gap}>
          {icon}

          <Typography variant="secondary" fontWeight={700}>
            {title}
          </Typography>
        </Box>

        {secondTitle && (
          <Typography variant="secondary" fontWeight={700}>
            {secondTitle}
          </Typography>
        )}
      </Box>

      {hasPermission &&
        shouldShowAction &&
        (hasAppliedAction ? (
          <Button
            variant="text"
            endIcon={<DeleteX />}
            sx={{
              color: "#848FA1",
            }}
            onClick={handleRemoveAction}
            disableRipple
          >
            {removeActionTitle}
          </Button>
        ) : (
          <Button
            variant="text"
            color="primary"
            endIcon={<AddPlus />}
            onClick={handleApplyAction}
            disableRipple
          >
            {applyActionTitle}
          </Button>
        ))}
    </Chip>
  );
};

export default ActionChip;

type ChipProps = BoxProps & {
  shouldApplyBackground: boolean;
  hasFullWidth: boolean;
};

const Chip = styled(
  Box,
  dontForwardProps("shouldApplyBackground", "hasFullWidth")
)<ChipProps>(({ theme, shouldApplyBackground, hasFullWidth }) => ({
  backgroundColor: shouldApplyBackground ? "#F7F8FD" : "transparent",
  border: shouldApplyBackground ? "none" : "1px solid #D9DAF7",
  borderRadius: theme.spacing(3),

  height: theme.spacing(4.25),
  padding: theme.spacing(0.5, 1.5),

  width: hasFullWidth ? "100%" : "auto",

  "& .MuiButton-root": {
    padding: 0,
    fontWeight: 700,
    fontSize: "0.75rem",
    minHeight: "1px",
    minWidth: "fit-content",
    justifyContent: "flex-end",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },

  // Disable selecting text, because when we click RedeemVoucherButton it is awkwardly selecting the text.
  MozUserSelect: "none" /* firefox */,
  WebkitUserSelect: "none" /* Safari */,
  msUserSelect: "none" /* IE*/,
  userSelect: "none" /* Standard syntax */,
}));
