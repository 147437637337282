import {
  Box,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { memo, useEffect, useState } from "react";

import { countries } from "../../resources";

function PeepPhoneField({
  defaultCountry = "+965",
  value,
  name,
  setValue,
  error,
  helperText,
  countryCodeFieldName = "countryCode",
  ...props
}) {
  const defaultCountryObject = countries.find(
    //removing the + sign from default country
    (country) => country.prefix === defaultCountry.substring(1)
  );

  // setting the initial value of countryCode to the defaultCountry
  useEffect(() => {
    setValue(countryCodeFieldName, defaultCountry);
  }, [setValue, defaultCountry, countryCodeFieldName]);

  const isDefaultCountryValid = !!defaultCountryObject;

  const kuwaitCountryObject = countries.find(
    //removing the + sign from default country
    (country) => country.prefix === "965"
  );

  const [selectedCountry, setSelectedCountry] = useState(
    isDefaultCountryValid ? defaultCountryObject : kuwaitCountryObject
  );

  const [phone, setPhone] = useState(value || "");

  const phoneWithoutCountryPrefix = phone?.replace(defaultCountry, "");

  const handleSelectCountryCode = (event) => {
    const newCountry = event.target.value;

    setSelectedCountry(newCountry);

    setValue(countryCodeFieldName, `+${newCountry}`);
  };

  useEffect(() => {
    setValue(name, phone);
  }, [name, phone, setValue]);

  useEffect(() => {
    if (defaultCountry && value !== "") {
      setPhone(value);
      const defaultCountryObject = countries.find(
        //removing the + sign from default country
        (country) => country.prefix === defaultCountry.substring(1)
      );
      setSelectedCountry(
        defaultCountryObject ? defaultCountryObject : { code: "KW", label: "Kuwait", prefix: "965" }
      );
    } else {
      setSelectedCountry({ code: "KW", label: "Kuwait", prefix: "965" });
    }
  }, [value, defaultCountry]);

  const countryCodeItems = countries.map((country) => (
    <MenuItem key={country.code} value={country.prefix}>
      <Box display="flex" width="100%">
        <Typography variant="primary">{country.flag} </Typography>
        <Typography px={1} variant="primary">{`${country.code} +${country.prefix}`}</Typography>
      </Box>
    </MenuItem>
  ));

  return (
    <StyledTextField
      value={phoneWithoutCountryPrefix}
      onChange={(e) => setPhone(e.target.value)}
      placeholder="9999 9999"
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Select
              input={<FlagSelect />}
              onChange={handleSelectCountryCode}
              value={selectedCountry ? selectedCountry.prefix : "965"}
            >
              {countryCodeItems}
            </Select>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default memo(PeepPhoneField);

const FlagSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRight: `1px solid ${theme.palette.border.lightGray}`,
    borderRadius: 0,
  },
}));

const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  "& .MuiInputBase-root": {
    padding: 0,
  },
}));
