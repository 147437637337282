import { Close } from "@bookpeep/ui";
import { Grid, IconButton } from "@mui/material";
import { FullWidthModal } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import useIsMobileView from "hooks/useIsMobileView";
import useResetForm from "hooks/useResetForm";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";

import CheckoutCart from "./CheckoutCart";
import CheckoutDate from "./CheckoutDate";
import { useCheckoutForm } from "./CheckoutFormContext";
import CheckoutMobileViewSwitcher from "./CheckoutMobileViewSwitcher";
import CheckoutRefund from "./CheckoutRefund";
import CheckoutSideBarActions from "./CheckoutSideBarActions";
import EmptyCart from "./EmptyCart";
import InvoiceDetails from "./InvoiceDetails";
import InvoiceSideMenuActionsFooter from "./InvoiceSideMenuActionsSection/InvoiceSideMenuActionsFooter";

const CheckoutModal = () => {
  const { t } = useTranslation("checkout");

  const checkoutForm = useCheckoutForm();

  const { reset, cartItemFields, areCheckoutDetailsLoading, isRefundInvoice } = checkoutForm;

  const { closeCheckoutModal, isCheckoutModalOpen, checkoutModalView, invoiceId } =
    useCheckoutModal();

  const invoice = useSelector(selectInvoiceById(invoiceId || 0));

  const isMobileView = useIsMobileView();

  const resetForm = useResetForm(reset, closeCheckoutModal);

  const checkIsCurrentView = (view: CheckoutModalView) => checkoutModalView === view;

  if (!areCheckoutDetailsLoading) return <CentredSpinnerBox />;

  const getMobileViewFooter = () => {
    if (checkIsCurrentView(CheckoutModalView.Invoice)) return <InvoiceSideMenuActionsFooter />;
    else return <CheckoutMobileViewSwitcher />;
  };

  const isInvoiceView =
    checkIsCurrentView(CheckoutModalView.Invoice) ||
    (checkIsCurrentView(CheckoutModalView.InvoiceAddPayments) && !isRefundInvoice && !!invoice) ||
    (checkIsCurrentView(CheckoutModalView.ConfirmSale) && invoiceId);

  const getRenderedView = () => {
    switch (checkoutModalView) {
      case CheckoutModalView.Invoice:
      case CheckoutModalView.InvoiceAddPayments:
        return <InvoiceDetails />;

      case CheckoutModalView.Refund:
      case CheckoutModalView.ConfirmRefund: {
        return (
          <>
            <CheckoutDate />
            <CheckoutRefund />
          </>
        );
      }
      case CheckoutModalView.ConfirmSale:
        return invoiceId ? (
          <InvoiceDetails />
        ) : (
          <>
            <CheckoutDate />
            {cartItemFields.length > 0 ? <CheckoutCart /> : <EmptyCart />}
          </>
        );

      default:
        return (
          <>
            <CheckoutDate />
            {cartItemFields.length > 0 ? <CheckoutCart /> : <EmptyCart />}
          </>
        );
    }
  };

  return (
    <FormProvider {...checkoutForm}>
      <form>
        <FullWidthModal
          open={isCheckoutModalOpen}
          onClose={resetForm}
          title={t(invoiceId ? "viewInvoice" : "checkout")}
          navActions={
            <IconButton onClick={resetForm}>
              <Close />
            </IconButton>
          }
          footer={isMobileView && getMobileViewFooter()}
        >
          <Grid container p={0} overflow="hidden" height={{ md: "100%" }}>
            {(!isMobileView ||
              checkIsCurrentView(CheckoutModalView.Cart) ||
              checkIsCurrentView(CheckoutModalView.Refund) ||
              checkIsCurrentView(CheckoutModalView.ConfirmRefund) ||
              isInvoiceView) && (
              <Grid
                item
                md={8}
                xs={12}
                maxHeight={{ md: "100%" }}
                overflow="scroll"
                order={{ xs: 2, md: 1 }}
                sx={{
                  ...(isInvoiceView && {
                    display: "grid",
                    placeItems: "center",
                  }),
                }}
              >
                {getRenderedView()}
              </Grid>
            )}

            <CheckoutSideBarActions />
          </Grid>
        </FullWidthModal>
      </form>
    </FormProvider>
  );
};

export default CheckoutModal;
