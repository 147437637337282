import stringifyArray from "helpers/stringifyArray";
import { Subscription } from "types/Subscription";
import http from "utils/http";

export type CreateSubscriptionArguments = Omit<
  Subscription,
  "id" | "deprecated" | "createdAt" | "updatedAt"
>;

export type UpdateSubscriptionArguments = Partial<CreateSubscriptionArguments>;

export type SubscriptionListResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  results: Subscription[];
};

type SubscriptionListParams = {
  page?: number;
  statusOptions?: string[];
  ordering?: string;
  searchQuery?: string;
};

const subscriptionsApi = {
  getAllSubscriptions: async () => http.get<Subscription[]>(`subscriptions/brief/`),

  getSubscriptions: async ({
    page,
    statusOptions,
    ordering,
    searchQuery,
  }: SubscriptionListParams) =>
    http.get<SubscriptionListResponse>(
      `subscriptions/subscriptions/${page ? `?page=${page}` : ""}${
        statusOptions ? `&status=${stringifyArray(statusOptions.join().split(","))}` : ""
      }${ordering ? `&order_by=${ordering}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}`
    ),

  createSubscription: async ({ data }: { data: CreateSubscriptionArguments }) =>
    http.post<Subscription>("subscriptions/subscriptions/", data),

  updateSubscription: async ({ id, data }: { id: number; data: UpdateSubscriptionArguments }) =>
    http.put<Subscription>(`subscriptions/subscriptions/${id}/`, data),

  removeSubscription: async ({ id }: { id: number }) =>
    http.delete(`subscriptions/subscriptions/${id}/`),
};

export default subscriptionsApi;
