import { Box, Typography } from "@mui/material";
import truncate from "helpers/truncate";
import { useContext } from "react";

import ServicesSearchFieldContext from "../../ServicesSearchFieldContext";
import VariantItem from "../VariantItem";

const PackageCategory = ({ category, date = "", outletId = 0 }) => {
  const { name, packages, color } = category;

  const { onSelectPackageVariant } = useContext(ServicesSearchFieldContext);

  const packageVariants = packages
    .map((_package) =>
      _package.variants.map((variant) => {
        const packageTotalDuration = variant.serviceVariants?.reduce(
          (partialTotal, serviceVariant) => partialTotal + serviceVariant?.duration,
          0
        );

        return (
          <VariantItem
            key={variant.id}
            name={variant.name}
            title={_package.name}
            handleClick={(finalPrice) => onSelectPackageVariant(variant, finalPrice)}
            subtitle={_package.name}
            price={variant.price}
            serviceCount={variant.serviceVariants.length}
            color={color}
            duration={packageTotalDuration}
            id={variant.id}
            isPackage
            date={date}
            outletId={outletId}
            referenceCode={_package.referenceCode}
          />
        );
      })
    )
    .reduce((allVariants, packageVariants) => [...allVariants, ...packageVariants], []);

  return (
    <Box pt={2}>
      <Typography variant="h3" px={2}>
        {truncate({ string: name, maxCharacters: 40 }).string}
      </Typography>
      {packageVariants}
    </Box>
  );
};

export default PackageCategory;
