import { Box, Typography } from "@mui/material";
import { LocationIcon } from "assets/icon";
import ClientAddresses from "components/Clients/ClientProfile/ClientHistory/ClientAddresses";
import useAddressLabel from "hooks/useAddressLabel";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientAddressByClientIdAndAddressId } from "store/clients/clientSelectors";
import { selectAreaById } from "store/slices/areas/areasSelector";

import { checkIfAppointmentHasAtLeastOneService } from "../AppointmentFormSummary/AppointmentFormSummaryUtils";
import { AppointmentFormValues } from "../utils";
import AppointmentFormSectionActionButton from "./AppointmentFormSectionActionButton";
import HomeServiceAppointmentFormSection from "./HomeServiceAppointmentFormSection";

type HomeServicesAppointmentFormAddressSectionProps = {
  isExpanded: boolean;
  isDisabled: boolean;
  handleModifyStepClick: () => void;
};

const HomeServicesAppointmentFormAddressSection = ({
  isDisabled,
  isExpanded,
  handleModifyStepClick,
}: HomeServicesAppointmentFormAddressSectionProps) => {
  const { t } = useTranslation(["appointments", "translation"]);

  const { setValue, watch } = useFormContext<AppointmentFormValues>();

  const {
    area: areaId,
    client: { id: clientId },
    address: addressId,
    articles,
  } = watch();

  const address = useSelector(
    selectClientAddressByClientIdAndAddressId(clientId || 0, addressId || 0)
  );
  const area = useSelector(selectAreaById(areaId || address?.area || 0));

  const addressLabel = useAddressLabel(address || undefined);

  const shouldDisplaySubtitle = !!area && !isExpanded;

  const doesAppointmentHaveAtLeastOneService =
    /* @ts-expect-error - NOTE: yup schema typing is crap.. it is causing some errors, will switch to zod when i have time for this*/
    checkIfAppointmentHasAtLeastOneService(articles) && !isExpanded;

  // NOTE: We considered having one service is because if there is no service and they clicked on modify address they won't be able to go back to services step, that's we we are checking if there is at least one service to avoid this issue.
  const shouldShowModifyButton =
    !isExpanded && doesAppointmentHaveAtLeastOneService && areaId && addressId;

  const handleSelectAddress = (newAddressValue: Nullable<number>) => {
    if (newAddressValue === addressId) {
      setValue("address", null);
      return;
    }

    setValue("address", newAddressValue);
  };
  const handleModifyAddressClick = () => {
    handleModifyStepClick();
  };

  const hasClient = !!clientId;

  return (
    <HomeServiceAppointmentFormSection
      title={t("address")}
      subtitle={
        <Typography variant="primary" fontSize="0.875" fontWeight={700}>
          {shouldDisplaySubtitle ? (
            <>
              {area?.name}

              <Typography variant="secondary" color="text.placeholder" fontWeight={500} px={0.5}>
                {`${addressLabel}` || "-"}
              </Typography>
            </>
          ) : (
            ""
          )}
        </Typography>
      }
      icon={<LocationIcon viewBox="0 0 16 16" />}
      isDisabled={isDisabled}
      actionButton={
        shouldShowModifyButton && (
          <AppointmentFormSectionActionButton onClick={handleModifyAddressClick}>
            {t("appointments:modify")}
          </AppointmentFormSectionActionButton>
        )
      }
      children={
        isExpanded &&
        hasClient &&
        !!areaId && (
          <Box>
            <ClientAddresses
              clientId={clientId}
              areaId={areaId}
              title={t("appointments:savedAddresses")}
              showAreaStatusChip
              hasRadioButton
              handleToggleSelection={handleSelectAddress}
              addressId={addressId}
              shouldShowManageButtons={false}
              shouldShowEmptyGraphic={false}
              areaAutoCompleteProps={{
                shouldShowAnotherZoneChip: true,
                selectedZoneId: area?.zone,
                shouldShowOnlyAreasWithZone: true,
              }}
              handleSaveForm={(address) => {
                if (address?.id) setValue("address", address.id);
              }}
            />
          </Box>
        )
      }
    />
  );
};

export default HomeServicesAppointmentFormAddressSection;
