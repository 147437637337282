import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { checkoutInvoiceAdapter } from "./checkoutInvoicesSlice";

export const selectCheckoutInvoicesState = (state: RootState) => state.checkoutInvoices;

const { selectById, selectAll } = checkoutInvoiceAdapter.getSelectors(selectCheckoutInvoicesState);

export const selectIsCheckoutInvoiceLoading = (state: RootState) =>
  state.checkoutInvoices.status === SliceStatus.LOADING;

export const selectInvoiceById = (id: number) => (state: RootState) => selectById(state, id);

export const selectAllInvoices = selectAll;

export const selectInvoicesByAppointmentId = (id: number) => (state: RootState) => {
  const invoices = selectAllInvoices(state);

  return invoices.filter((invoice) => invoice.appointment === id);
};
