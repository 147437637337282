import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./rolesApi";

const roleAdapter = createEntityAdapter();

const { selectById, selectAll } = roleAdapter.getSelectors((state) => state.roles);

export const selectAllRoles = selectAll;

export const selectRoleById = (id) => (state) => selectById(state, id);

export const selectRoleLoading = (state) => state.isLoading;

export const singleRole = (state) => state.roles.singleRole;

export const getAllRoles = createAsyncThunk(
  "roles/getAllRoles",

  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getAllRoles();
      const { data } = response;
      return data;
    } catch (error) {
      // Use `error.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(error.response.data);
    }
  }
);

const rolePermissionAdapter = createEntityAdapter({
  selectId: (permission) => permission.id,
});

const initialState = roleAdapter.getInitialState({
  permissions: rolePermissionAdapter.getInitialState(),
  isLoading: false,
  error: "",
});

export const getRole = createAsyncThunk(
  "roles/getRole",
  async (getRoleArgs, { rejectWithValue }) => {
    const { roleId, setEditLoading, setOpenCreateRole } = getRoleArgs;
    setEditLoading(roleId);
    try {
      const response = await api.getRole(roleId);
      const { data } = response;
      setEditLoading(null);
      setOpenCreateRole(true);
      return data;
    } catch (error) {
      // Use `error.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      setEditLoading(null);
      return rejectWithValue(error.response.data);
    }
  }
);

export const createRole = createThunkFromApi("roles/createRole", api.createRole);

export const updateRole = createThunkFromApi("roles/updateRole", api.updateRole);

export const deleteRole = createThunkFromApi("roles/deleteRole", api.deleteRole);

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    clearRole: (state) => {
      state.singleRole = null;
    },
  },
  extraReducers: {
    [getAllRoles.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllRoles.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      roleAdapter.setAll(state, payload);
    },

    [getAllRoles.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getRole.fulfilled]: (state, { payload }) => {
      roleAdapter.setAll(state, { ...state, singleRole: payload });
    },
    [createRole.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createRole.fulfilled]: (state, { payload }) => {
      roleAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createRole.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [deleteRole.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deleteRole.fulfilled]: (state, { meta: { arg } }) => {
      const { id } = arg;
      roleAdapter.removeOne(state, id);

      state.isRequestPending = false;
    },
    [deleteRole.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updateRole.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateRole.fulfilled]: (state, { payload }) => {
      roleAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.isRequestPending = false;
    },
    [updateRole.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default rolesSlice.reducer;
