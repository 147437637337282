import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/packageCategoriesV2/packageCategoriesApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { PackageCategory } from "types/Package";

import { SliceStatus } from "../utils";

export const packageCategoryAdapter = createEntityAdapter<PackageCategory>({
  selectId: (packageCategory) => packageCategory.id,
});

const { selectAll } = packageCategoryAdapter.getSelectors();

const initialState = packageCategoryAdapter.getInitialState<{
  status: SliceStatus;
  optimisticCache: Nullable<PackageCategory[]>;
}>({
  status: SliceStatus.IDLE,
  optimisticCache: null,
});

export const getAllPackageCategories = createThunkFromApiWithType(
  "packageCategoriesV2/getAllPackageCategories",
  api.getAllPackageCategories,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const createPackageCategory = createThunkFromApiWithType(
  "packageCategoriesV2/createPackageCategory",
  api.createPackageCategory
);

export const updatePackageCategory = createThunkFromApiWithType(
  "packageCategoriesV2/updatePackageCategory",
  api.updatePackageCategory
);

export const deletePackageCategory = createThunkFromApiWithType(
  "packageCategoriesV2/deletePackageCategory",
  api.removePackageCategory
);

export const updatePackageCategoryPosition = createThunkFromApiWithType(
  "packageCategoriesV2/updatePackageCategoryPosition",
  api.updatePackageCategoryPosition
);

export const packageCategoriesSlice = createSlice({
  name: "packageCategoriesV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllPackageCategories.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllPackageCategories.fulfilled, (state, { payload }) => {
      packageCategoryAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllPackageCategories.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createPackageCategory.fulfilled, (state, { payload }) => {
      packageCategoryAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createPackageCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(updatePackageCategory.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updatePackageCategory.fulfilled, (state, { payload }) => {
      packageCategoryAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updatePackageCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(
      updatePackageCategoryPosition.pending,
      (
        state,
        {
          meta: {
            arg: { data },
          },
        }
      ) => {
        const packageCategoriesV2 = selectAll(state);
        state.optimisticCache = packageCategoriesV2;

        packageCategoryAdapter.upsertMany(state, data);
      }
    );

    reducers.addCase(updatePackageCategoryPosition.fulfilled, (state, { payload }) => {
      state.optimisticCache = null;
      packageCategoryAdapter.upsertMany(state, payload);
    });

    reducers.addCase(updatePackageCategoryPosition.rejected, (state) => {
      if (state.optimisticCache) {
        packageCategoryAdapter.setAll(state, state.optimisticCache);
        state.optimisticCache = null;
      }
    });

    reducers.addCase(
      deletePackageCategory.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        packageCategoryAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deletePackageCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default packageCategoriesSlice.reducer;
