import ConfirmationDialogModal from "components/common/ConfirmationDialogModal";
import { useTranslation } from "react-i18next";
import { GenericThunk, useDispatch, useSelector } from "store";
import { selectAppointmentById, updateAppointmentLogs } from "store/appointments/appointmentsSlice";
import { getClient } from "store/clients/clientsSlice";
import { selectDepositById } from "store/selectors";
import { deactivatePeepPaymentLink } from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import { DepositRemovalOptions } from "store/slices/deposits/depositsApi";
import { updateDeposit } from "store/slices/deposits/depositsSlice";
import useNotify from "utils/notifications/useNotify";

type CancelPeepPayLinkModalBasicProps = {
  isOpen: boolean;
  onClose: () => void;
};

type CancelPeepPayLinkModalProps =
  | (CancelPeepPayLinkModalBasicProps & {
      depositId: number;
      clientId: Nullable<number>;
    })
  | (CancelPeepPayLinkModalBasicProps & {
      peepPaymentInvoiceId: number;
    });

/**
 * This component is used to cancel a PeepPay link, it can handle the case of canceling a PeepPay link from a deposit or from an invoice, the props are defined accordingly
 *
 * For deposit case you must define:
 * @param depositId
 * @param clientId
 *
 * For invoice case you must define:
 * @param peepPaymentInvoiceId
 *
 *
 * @returns
 */

const CancelPeepPayLinkModal = ({ isOpen, onClose, ...rest }: CancelPeepPayLinkModalProps) => {
  const depositId = "depositId" in rest ? rest.depositId : null;
  const clientId = "clientId" in rest ? rest.clientId : null;

  const peepPaymentInvoiceId = "peepPaymentInvoiceId" in rest ? rest.peepPaymentInvoiceId : null;

  const { t } = useTranslation("deposits");

  const dispatch = useDispatch();

  const notify = useNotify();

  const deposit = useSelector(selectDepositById(depositId || 0));

  const appointment = useSelector(selectAppointmentById(deposit?.appointment));

  const handleCancelPeepPayLink = () => {
    if (depositId) {
      dispatch(updateDeposit({ id: depositId, data: { option: DepositRemovalOptions.NEGLECT } }))
        .unwrap()
        .then((response) => {
          if (clientId) {
            dispatch((getClient as GenericThunk)(clientId));
          }

          dispatch(updateAppointmentLogs({ logs: response.logs, appointment }));

          notify(
            t("translation:entitySuccessfullyDeactivated", {
              entity: t("peepPaymentLink"),
              ns: "deposits",
            }),
            "success"
          );

          onClose();
        })
        .catch((error) => {
          notify(
            `${t("translation:entityFailedToBeDeactivated", {
              entity: t("peepPaymentLink"),
              ns: "deposits",
            })} ${error?.detail ?? ""}`,
            "error"
          );
        });
    } else if (peepPaymentInvoiceId) {
      dispatch(deactivatePeepPaymentLink({ id: peepPaymentInvoiceId }))
        .unwrap()
        .then(() => {
          notify(
            t("translation:entitySuccessfullyDeactivated", {
              entity: t("peepPaymentLink"),
              ns: "deposits",
            }),
            "success"
          );
          onClose();
        })
        .catch((error) => {
          notify(
            `${t("translation:entityFailedToBeDeactivated", {
              entity: t("peepPaymentLink"),
              ns: "deposits",
            })} ${error?.detail ?? ""}`,
            "error"
          );
        });
    } else {
      throw new Error("depositId or peepPaymentInvoiceId is required");
    }
  };

  return (
    <ConfirmationDialogModal
      onClose={onClose}
      isOpen={isOpen}
      title={t("deactivatePaymentLink")}
      description={t("deactivatePaymentLinkDescription")}
      cancelButtonLabel={t("cancel")}
      confirmButtonLabel={t("deactivateLink")}
      onCancelButtonClick={onClose}
      onConfirmButtonClick={handleCancelPeepPayLink}
    />
  );
};

export default CancelPeepPayLinkModal;
