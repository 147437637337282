import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";

export function isServiceVariantActivePromotion(
  item: ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
): item is ServiceVariantActivePromotion {
  return item.hasOwnProperty("serviceVariant") && item.hasOwnProperty("hasAllServices");
}

export function areServiceVariantActivePromotions(
  items: Array<
    ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
  >
): items is ServiceVariantActivePromotion[] {
  return items.every(isServiceVariantActivePromotion);
}

export function isPackageVariantActivePromotion(
  item: ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
): item is PackageVariantActivePromotion {
  return item.hasOwnProperty("packageVariant") && item.hasOwnProperty("hasAllPackages");
}

export function arePackageVariantActivePromotions(
  items: Array<
    ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
  >
): items is PackageVariantActivePromotion[] {
  return items.every(isPackageVariantActivePromotion);
}

export function isProductActivePromotion(
  item: ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
): item is ProductActivePromotion {
  return item.hasOwnProperty("product") && item.hasOwnProperty("hasAllProducts");
}

export function areProductActivePromotions(
  items: Array<
    ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
  >
): items is ProductActivePromotion[] {
  return items.every(isProductActivePromotion);
}
