import { useSelector } from "store";
import { selectAllProductActivePromotions } from "store/selectors";
import { PurchasableItemOption } from "types/PurchasableItem";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItem from "../PurchasableItem";

type ProductsPurchasableItemsProps = {
  products: ProductWithBrand[];
};

const ProductsPurchasableItems = ({ products }: ProductsPurchasableItemsProps) => {
  const { onSelectItem, selectedItems, shouldHighlightSelectedItems, shouldShowPromotionTag } =
    usePurchasableItemsSelector();

  const selectedProductIds = selectedItems?.[PurchasableItemOption.Product] || [];

  const productActivePromotions = useSelector(selectAllProductActivePromotions);

  return (
    <>
      {products.map((product) => {
        const shouldHighlight =
          shouldHighlightSelectedItems && selectedProductIds.includes(product.id);

        const hasPromotionTag =
          shouldShowPromotionTag &&
          productActivePromotions.some(
            (promotion) => promotion.product === product.id || promotion.hasAllProducts
          );

        return (
          <PurchasableItem
            key={`Product-${product.id}-${product.name}`}
            title={product.name}
            subtitle={product?.brand?.name}
            price={product.retailPrice}
            handleClick={() => onSelectItem(product, PurchasableItemOption.Product)}
            isHighlighted={shouldHighlight}
            shouldShowPromotionTag={hasPromotionTag}
          />
        );
      })}
    </>
  );
};

export default ProductsPurchasableItems;
