import { Box, Slider } from "@mui/material";
import ConfirmationDialogModal, {
  ConfirmationModalSeverity,
} from "components/common/ConfirmationDialogModal";
import { useCallback, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import getCroppedImg from "utils/crop/imageCrop";

type ImageCropperProps = {
  photoURL: string | undefined;
  setPhotoUrl: Dispatch<SetStateAction<string | undefined>>;
  isImageCropperOpen: boolean;
  setIsImageCropperOpen: Dispatch<SetStateAction<boolean>>;
};

const ImageCropper = ({
  photoURL,
  setPhotoUrl,
  isImageCropperOpen,
  setIsImageCropperOpen,
}: ImageCropperProps) => {
  const { t } = useTranslation("onlineBooking");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Nullable<Area>>(null);

  const { setValue } = useFormContext();

  const onCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = async () => {
    // Make sure we have cropped area pixels, or Add a notify message or error message
    if (!croppedAreaPixels) return;

    if (photoURL) {
      try {
        const croppedImg = await getCroppedImg(photoURL, croppedAreaPixels);
        if (!croppedImg) throw new Error("Could not crop image");

        setPhotoUrl(croppedImg.url);

        setIsImageCropperOpen(false);
        setValue("image", croppedImg.file);
      } catch (error) {
        throw new Error(`Could not crop image. Error: ${error}`);
      }
    }
  };

  const handleCloseCropModal = () => {
    setIsImageCropperOpen(false);
  };

  const handleSliderChange = (_event: Event, zoom: number | number[]) => {
    if (typeof zoom === "number") {
      setZoom(zoom);
    }

    // if the type of zoom is an array
    if (typeof zoom === "object") {
      if (zoom?.length) setZoom(zoom[0]);
    }
  };

  return (
    <ConfirmationDialogModal
      isOpen={isImageCropperOpen}
      onClose={handleCloseCropModal}
      title={t("serviceImage")}
      onConfirmButtonClick={cropImage}
      onCancelButtonClick={handleCloseCropModal}
      confirmButtonLabel={t("confirm")}
      confirmButtonSeverity={ConfirmationModalSeverity.Moderate}
      hasTitleOnTop
    >
      <Box
        sx={{
          background: "#333 ",
          position: "relative",
          width: "auto",
          height: 400,
          minWidth: { sm: 500 },
        }}
      >
        <Cropper
          image={photoURL}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </Box>

      <Box sx={{ width: "100%", mb: 1 }}>
        <Slider
          valueLabelDisplay="auto"
          valueLabelFormat={zoomPercent}
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={handleSliderChange}
        />
      </Box>
    </ConfirmationDialogModal>
  );
};

export default ImageCropper;

const zoomPercent = (value: number) => {
  return `${Math.round(value * 100)}%`;
};
