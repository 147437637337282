import { ResourceBlockTime } from "types/BlockTime";
import http from "utils/http";

export type DjangoDetailResponse = {
  detail: string;
};

export type CreateResourceBlockTime = {
  startTime: string;
  endTime: string;
  resource: number;
  blocksOnline: boolean;
  description: string;
};

export type UpdateResourceBlockTime = {
  resource: number;
  startTime: string;
  endTime: string;
  blocksOnline?: boolean;
  description?: string;
};

export type CreateResourceBlockTimeResponse = ResourceBlockTime;

export type UpdateResourceBlockTimeResponse = ResourceBlockTime;

export type ResourceBlockTimeResponse = ResourceBlockTime[];

export type ResourceBlockTimeArguments = {
  startDate: string;
  endDate: string;
  outletId: number;
};

export type CreateResourceBlockTimeArguments = {
  data: CreateResourceBlockTime;
};

export type UpdateResourceBlockTimeArguments = {
  data: UpdateResourceBlockTime;
  id: number;
};

export type DeleteResourceBlockTime = {
  id: number;
};

export type DeleteResourceBlockTimeArguments = {
  data: DeleteResourceBlockTime;
};

export type UpdateResourceBlockTimeRequestArgs = {
  id: number,
  data: UpdateResourceBlockTime
}

const resourceBlockTimesApi = {
  getResourceBlockTimes: async ({ startDate, endDate, outletId }: ResourceBlockTimeArguments) =>
    http.get<ResourceBlockTimeResponse>(
      `resources/resources-block-times/?start_date=${startDate}&end_date=${endDate}&outlet=${outletId}`
    ),

  createResourceBlockTime: (data: CreateResourceBlockTime) =>
    http.post<CreateResourceBlockTimeResponse>("resources/resources-block-times/", data),

  updateResourceBlockTime: ({ id, data }: UpdateResourceBlockTimeRequestArgs) =>
    http.put<UpdateResourceBlockTimeResponse>(`resources/resources-block-times/${id}/`, data),

  deleteResourceBlockTime: ({ id }: DeleteResourceBlockTime) =>
    http.delete(`resources/resources-block-times/${id}/`),
};

export default resourceBlockTimesApi;
