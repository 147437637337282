import { PhoneNumberUtil } from "google-libphonenumber";
import { z } from "zod";

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * This function is used to validate the phone number and the country code for zod schema
 * @returns a function that validates the phone number and the country code
 */

export default function validatePhoneNumberAndCountryCode(): (
  arg: { phoneCountryCode: string; phoneNumber: string },
  ctx: z.RefinementCtx
) => void {
  return ({ phoneCountryCode, phoneNumber }, ctx) => {
    if (!phoneCountryCode)
      return ctx.addIssue({
        code: "custom",
        path: ["phoneCountryCode"],
        message: "mixed.required",
      });

    if (!phoneNumber)
      return ctx.addIssue({
        code: "custom",
        path: ["phoneNumber"],
        message: "mixed.required",
      });

    // if the phoneNumber is not a valid number the parsing will explode and ruin the validation of all the fields -- Anfal
    try {
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber, phoneCountryCode);
      const phoneNumberRawValue = parsedPhoneNumber.getNationalNumber();

      const isPossibleMobileNumber = phoneUtil.isPossibleNumber(parsedPhoneNumber);

      const isValidMobileNumber = phoneUtil.isValidNumber(parsedPhoneNumber);

      // To ensure that the phoneNumber does not include countryCode, because phoneUtil.parse will remove the additional country code from the phoneNumber when parsing, which will make validating phoneNumber always successful, which we don't want -- Anfal
      const doesParsedPhoneMatchesFieldValue = phoneNumberRawValue === Number(phoneNumber);

      if (isPossibleMobileNumber && isValidMobileNumber && doesParsedPhoneMatchesFieldValue)
        return true;

      throw new Error(); // Go to catch phrase
    } catch {
      return ctx.addIssue({
        code: "custom",
        path: ["phoneNumber"],
        message: "invalidPhoneNumber",
      });
    }
  };
}
