import { SearchableSelectField } from "components/common";
import { SearchableSelectFieldProps } from "components/common/SearchableSelectField";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveResourcesByOutletIdAndServiceId,
  selectResourcesByOutletIdAndServiceId,
  selectResourcesLoading,
} from "store/selectors";

type ResourceAutoCompleteProps = Optional<SearchableSelectFieldProps, "options" | "label"> & {
  outletId: number | null;
  warning?: boolean;

  serviceId: number | null;
};

const ResourceAutoComplete = ({
  outletId,
  serviceId,
  ...searchableSelectFieldProps
}: ResourceAutoCompleteProps) => {
  const { t } = useTranslation();
  const areResourcesLoading = useSelector(selectResourcesLoading);

  const { value } = searchableSelectFieldProps;

  const resources = useSelector(selectResourcesByOutletIdAndServiceId(outletId || 0, serviceId));

  const activeResources = useSelector(
    selectActiveResourcesByOutletIdAndServiceId(outletId || 0, serviceId)
  );

  const selectedResource = resources.find((resource) => resource.id === value)?.id;

  const isSelectedResourceActive = activeResources.some(
    (resource) => resource.id === selectedResource
  );

  // If the appointment has an inactive resource, we want to show it in the dropdown, but if he selects something else it should be hidden
  const resourcesBasedOnSelectedResource =
    selectedResource && !isSelectedResourceActive ? resources : activeResources;

  const options = resourcesBasedOnSelectedResource.map((resource) => ({
    value: resource.id,
    label: resource.name,
  }));

  return (
    <SearchableSelectField
      disablePortal={false}
      {...searchableSelectFieldProps}
      label={t("resource")}
      value={selectedResource || null}
      options={options}
      areOptionsLoading={areResourcesLoading}
    />
  );
};

export default ResourceAutoComplete;
