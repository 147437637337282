import { Box, Typography } from "@mui/material";
import { SpecialDiscountIcon } from "assets/icon";
import { ActionChip } from "components/common";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientById } from "store/clients/clientsSlice";
import { Client } from "types/Client";
import { DiscountOption } from "types/promotions";

import { useCheckoutFormContext } from "./CheckoutFormContext";

const CheckoutClientDiscountSection = () => {
  const { t } = useTranslation(["client", "checkout"]);

  const { watch, setValue } = useCheckoutFormContext();

  const { articles, existingClient, appliedClientSpecialDiscount } = watch();
  const existingClientId = existingClient?.id || null;

  const client = useSelector(selectClientById(existingClientId)) as Nullable<Client>;

  const clientDiscountAmount = Number(client?.discountAmount);

  const hasAppliedClientDiscount = Boolean(appliedClientSpecialDiscount);

  const handleApplyDiscount = () => {
    setValue("appliedClientSpecialDiscount", clientDiscountAmount);
    // modify all articles final price with discount
    const articlesAfterApplyingDiscount = articles.map((article) => {
      // if article is a subscription redemption or loyalty reward, do not apply discount
      if (
        article.subscriptionPurchaseId ||
        article.subscriptionRedemptionId ||
        article.rewardLoyaltyCard
      )
        return {
          ...article,
        };
      else
        return {
          ...article,
          finalPrice: getFinalPriceAfterDiscount({
            originalPrice: article.markedPrice,
            discountOption: DiscountOption.Percentage,
            discountValue: clientDiscountAmount,
          }),
          discountValue: clientDiscountAmount,
          promotionId: null,
        };
    });

    setValue("articles", articlesAfterApplyingDiscount);
    setValue("payments", []);
  };

  const handleRemoveDiscount = () => {
    setValue("appliedClientSpecialDiscount", 0);
    // modify all articles final price with discount
    const articlesAfterRemovingDiscount = articles.map((article) => {
      if (article.subscriptionPurchaseId || article.subscriptionRedemptionId)
        return {
          ...article,
        };
      else
        return {
          ...article,
          finalPrice: article.markedPrice,
          discountValue: 0,
        };
    });

    setValue("articles", articlesAfterRemovingDiscount);
    setValue("payments", []);
  };

  return (
    <Box p={3} boxShadow="divider">
      <ActionChip
        icon={
          <SpecialDiscountIcon color="primary" viewBox="0 0 16 17" sx={{ height: 17, width: 16 }} />
        }
        title={
          <Typography variant="secondary" fontWeight={700}>
            <Trans
              i18nKey={
                hasAppliedClientDiscount
                  ? "checkout:specialDiscountApplied"
                  : "checkout:thisClientHasASpecialDiscount"
              }
              values={{ discount: clientDiscountAmount }}
              components={[
                <Typography
                  key="discount-value"
                  display="inline"
                  fontWeight={700}
                  color="primary"
                  variant="secondary"
                />,
              ]}
            />
          </Typography>
        }
        applyActionTitle={t("checkout:apply")}
        removeActionTitle={t("remove")}
        handleApplyAction={handleApplyDiscount}
        handleRemoveAction={handleRemoveDiscount}
        hasAppliedAction={hasAppliedClientDiscount}
      />
    </Box>
  );
};

export default CheckoutClientDiscountSection;
