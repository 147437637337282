import { Box, Stack, Typography } from "@mui/material";
import { ConfirmationDialogModal, SectionTitle } from "components/common";
import { ConfirmationModalSeverity } from "components/common/ConfirmationDialogModal";
import { getFormattedPrice } from "helpers/getFormattedPrice";
import useIsMobileView from "hooks/useIsMobileView";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPosPaymentTypes } from "store/paymentType/paymentTypesSelectors";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutRefundAdditionalPaymentMethod from "./CheckoutRefundMethod";

type CheckoutRefundAdditionalPaymentsModalProps = {
  isModalOpen: boolean;
  onCloseModal: (moveForward?: boolean) => void;
  refundAmount: number;
  hasDeposit: boolean;
  hasOnlinePayment: boolean;
};

enum WalletOption {
  Offline = "offline",
  Online = "online",
}

const CheckoutRefundAdditionalPaymentsModal = ({
  isModalOpen,
  onCloseModal,
  refundAmount,
  hasDeposit,
  hasOnlinePayment,
}: CheckoutRefundAdditionalPaymentsModalProps) => {
  const { t } = useTranslation("checkout");
  const isMobileView = useIsMobileView();

  const { setValue } = useCheckoutFormContext();

  const paymentMethods = useSelector(selectPosPaymentTypes);

  const refundAmountAsPositive = Math.abs(refundAmount);

  const refundValue = `${t("currency")} ${getFormattedPrice(Math.abs(refundAmountAsPositive))}`;

  const [selectedWallet, setSelectedWallet] = useState<Nullable<WalletOption>>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Nullable<number>>(null);

  const checkIsPaymentMethodSelected = (paymentMethodId: number) => {
    return selectedPaymentMethod === paymentMethodId;
  };

  const checkIsWalletSelected = (wallet: WalletOption) => {
    return selectedWallet === wallet;
  };

  const shouldShowOfflineWallet = hasDeposit;
  const shouldShowOnlineWallet = hasOnlinePayment;

  const onRefundButtonClick = () => {
    setValue("refund", {
      wallet: !!selectedWallet,
      paymentType: selectedPaymentMethod,
      amount: refundAmount,
    });

    onCloseModal(true);
  };

  return (
    <ConfirmationDialogModal
      title={t("refundModalTitle")}
      description={
        <Trans
          i18nKey="checkout:refundModalDescription"
          values={{ refundValue }}
          components={[<Typography key="refund-amount" display="inline" fontWeight={700} />]}
        />
      }
      confirmButtonLabel={t("refund")}
      confirmButtonSeverity={ConfirmationModalSeverity.Moderate}
      onConfirmButtonClick={onRefundButtonClick}
      isOpen={isModalOpen}
      onClose={() => onCloseModal(false)}
    >
      <Stack py={3} gap={2}>
        <Box px={{ xs: 1 }}>
          <SectionTitle>{t("refundMethod")}</SectionTitle>
        </Box>

        <Stack gap={2} overflow="scroll" height={isMobileView ? "30svh" : 300} p={1}>
          {shouldShowOfflineWallet && (
            <CheckoutRefundAdditionalPaymentMethod
              key="offline-wallet"
              title={t("offlineWallet")}
              isSelected={checkIsWalletSelected(WalletOption.Offline)}
              onClick={() => {
                setSelectedWallet(WalletOption.Offline);
                setSelectedPaymentMethod(null);
              }}
            />
          )}

          {shouldShowOnlineWallet && (
            <CheckoutRefundAdditionalPaymentMethod
              key="online-wallet"
              title={t("onlineWallet")}
              isSelected={checkIsWalletSelected(WalletOption.Online)}
              onClick={() => {
                setSelectedWallet(WalletOption.Online);
                setSelectedPaymentMethod(null);
              }}
            />
          )}

          {paymentMethods.map((paymentMethod) => (
            <CheckoutRefundAdditionalPaymentMethod
              key={paymentMethod.id}
              title={paymentMethod.label}
              isSelected={checkIsPaymentMethodSelected(paymentMethod.id)}
              onClick={() => {
                setSelectedPaymentMethod(paymentMethod.id);
                setSelectedWallet(null);
              }}
            />
          ))}
        </Stack>
      </Stack>
    </ConfirmationDialogModal>
  );
};

export default CheckoutRefundAdditionalPaymentsModal;
