import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ClientHairType } from "types/Client";

import { SliceStatus } from "../utils";
import api from "./clientHairTypesApi";

export const clientHairTypesAdapter = createEntityAdapter<ClientHairType>({
  selectId: (client) => client.id,
});

const initialState = clientHairTypesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllClientHairTypes = createThunkFromApiWithType(
  "clientHairTypes/getAllClientHairTypes",
  api.getAllClientHairTypes
);

export const clientHairTypesSlice = createSlice({
  name: "clientHairTypes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload ? SliceStatus.LOADING : SliceStatus.IDLE;
    },
  },
  extraReducers: (reducers) => {
    reducers.addCase(getAllClientHairTypes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllClientHairTypes.fulfilled, (state, { payload }) => {
      clientHairTypesAdapter.setAll(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllClientHairTypes.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export const { setLoading } = clientHairTypesSlice.actions;

export default clientHairTypesSlice.reducer;
