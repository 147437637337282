import * as yup from "yup";

export default function changePasswordSchema(t) {
  return yup
    .object({
      currentPassword: yup.string().required(t("validation.currentPassword")),
      newPassword: yup
        .string()
        .required(t("validation.password"))
        .max(255)
        .min(8, t("validation.minimumPassword", { min: 8 })),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("newPassword"), null], t("validation.confirmPassword"))
        .max(255)
        .min(8, t("validation.minimumPassword", { min: 8 })),
    })
    .required();
}
