import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Voucher } from "types/Voucher";

import { SliceStatus } from "../utils";
import api from "./vouchersApi";

export const vouchersAdapter = createEntityAdapter<Voucher>({ selectId: (voucher) => voucher.id });

const initialState = vouchersAdapter.getInitialState<{
  status: SliceStatus;
  next: string;
  previous: string;
  totalPages: number;
  results: Voucher[];
  page: number[];
}>({
  status: SliceStatus.IDLE,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
  page: [],
});

export const getVouchers = createThunkFromApiWithType("vouchers/getVouchers", api.getVouchers);

export const getAllVouchers = createThunkFromApiWithType(
  "vouchers/getAllVouchers",
  api.getAllVouchers
);

export const appendAllVouchers = createThunkFromApiWithType(
  "vouchers/appendAllVouchers",
  api.getVouchers
);

export const createVoucher = createThunkFromApiWithType(
  "vouchers/createVoucher",
  api.createVoucher
);

export const updateVoucher = createThunkFromApiWithType(
  "vouchers/updateVoucher",
  api.updateVoucher
);

export const deleteVoucher = createThunkFromApiWithType(
  "vouchers/deleteVoucher",
  api.deleteVoucher
);

export const emailGiftVoucher = createThunkFromApiWithType(
  "vouchers/emailGiftVoucher",
  api.emailGiftVoucher
);

export const vouchersSlice = createSlice({
  name: "vouchers",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Get all brief vouchers reducers
    reducers.addCase(getAllVouchers.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllVouchers.fulfilled, (state, { payload }) => {
      vouchersAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllVouchers.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    //Get All Vouchers reducers
    reducers.addCase(getVouchers.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getVouchers.fulfilled, (state, { payload }) => {
      vouchersAdapter.upsertMany(state, payload.results);
      state.page = payload.results.map((voucher) => voucher.id);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(getVouchers.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Append voucher reducers
    reducers.addCase(appendAllVouchers.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllVouchers.fulfilled, (state, { payload }) => {
      vouchersAdapter.upsertMany(state, payload.results);
      state.page = [...state.page, ...payload.results.map((voucher) => voucher.id)];
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendAllVouchers.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // Create voucher reducers
    reducers.addCase(createVoucher.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createVoucher.fulfilled, (state, { payload }) => {
      vouchersAdapter.upsertOne(state, payload);
      state.page = [...state.page, payload.id];
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createVoucher.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Update voucher reducer
    reducers.addCase(updateVoucher.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateVoucher.fulfilled, (state, { payload }) => {
      vouchersAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateVoucher.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Delete voucher reducer
    reducers.addCase(deleteVoucher.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteVoucher.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        state.page = state.page.filter((p) => p !== id);
        vouchersAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteVoucher.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // Email gift voucher reducers
    reducers.addCase(emailGiftVoucher.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(emailGiftVoucher.fulfilled, (state) => {
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(emailGiftVoucher.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default vouchersSlice.reducer;
