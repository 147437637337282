import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllOutlets } from "store/outlets/outletsSlice";
import Outlet from "types/Outlet";
import { SubscriptionPurchase } from "types/SubscriptionPurchase";

type OutletsNamesBoxProps = {
  outlets: SubscriptionPurchase["outlets"];
};

const OutletsNamesBox = ({ outlets }: OutletsNamesBoxProps) => {
  const { t } = useTranslation();
  const allOutlets = useSelector(selectAllOutlets) as Outlet[];

  return (
    <Box display="flex" flexDirection="column">
      {outlets.map((outletId) => (
        <Typography key={outletId} variant="primary">
          {allOutlets.find((outlet) => outlet.id === outletId)?.name ||
            t("translation:unauthorizedLocation")}
        </Typography>
      ))}
    </Box>
  );
};

export default OutletsNamesBox;
