import { Typography } from "@mui/material";

type InvoiceItemTitleProps = {
  englishTitle: string;
  arabicTitle: string;
};

const InvoiceItemTitle = ({ englishTitle, arabicTitle }: InvoiceItemTitleProps) => {
  return (
    <Typography
      variant="h3"
      align="left"
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      gap={1}
      pr={1}
      whiteSpace="pre"
    >
      {`${englishTitle} /`}
      <Typography color="text.label" align="left" fontWeight={700}>
        {arabicTitle}
      </Typography>
    </Typography>
  );
};

export default InvoiceItemTitle;
