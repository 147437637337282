import { Divider, Grid } from "@mui/material";
import { DiscountField, PriceField } from "components/common";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import useFormValidation from "hooks/useFormValidation";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import {
  selectAllPackageVariantActivePromotionsByPackageVariantIdBasedOnDate,
  selectPackageVariantPromotionByPackageVariantIdAndPromotionId,
} from "store/selectors";
import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";
import { isPackageVariantActivePromotion } from "types/typeGuards/ActivePromotionsTypeGuards";

import { useCheckoutFormContext } from "../../CheckoutFormContext";
import CheckoutPromotionSelectField from "../CheckoutPromotionSelectField";
import CheckoutCartItemPackageServiceArticleFields from "./CheckoutCartItemPackageServiceArticleFields";

type CheckoutCartItemPackageFieldsProps = {
  cartItemIndex: number;
  isPriceChangeDisabled: boolean;
  shouldDisableEditing: boolean;
};

const CheckoutCartItemPackageFields = ({
  cartItemIndex,
  isPriceChangeDisabled,
  shouldDisableEditing,
}: CheckoutCartItemPackageFieldsProps) => {
  const { t } = useTranslation("checkout");

  const { formState, watch, setValue, date } = useCheckoutFormContext();

  const appliedClientSpecialDiscount = watch("appliedClientSpecialDiscount");

  const hasAppliedClientSpecialDiscount = appliedClientSpecialDiscount > 0;

  const articleIndex: `articles.${number}` = `articles.${cartItemIndex}`;
  const article = watch(articleIndex);

  const {
    discountValue,
    promotionId = null,
    originalPrice,
    markedPrice,
    finalPrice,
    subscriptionPurchaseId,
    rewardLoyaltyCard,
  } = article;

  const packageVariantFieldPath: `articles.${number}.purchasedItem.packageVariant` = `${articleIndex}.purchasedItem.packageVariant`;

  const promotionIdFieldPath: `articles.${number}.promotionId` = `${articleIndex}.promotionId`;

  const markedPriceFieldPath: `articles.${number}.markedPrice` = `${articleIndex}.markedPrice`;
  const finalPriceFieldPath: `articles.${number}.finalPrice` = `${articleIndex}.finalPrice`;
  const discountValueFieldPath: `articles.${number}.discountValue` = `${articleIndex}.discountValue`;

  const packageVariantId = watch(packageVariantFieldPath);

  const packageVariantActivePromotions = useSelector(
    selectAllPackageVariantActivePromotionsByPackageVariantIdBasedOnDate(
      packageVariantId || 0,
      date
    )
  );

  const selectedPromotion = useSelector(
    selectPackageVariantPromotionByPackageVariantIdAndPromotionId(
      packageVariantId || 0,
      promotionId || 0
    )
  );

  const isSelectedPromotionInActivePromotions = !!packageVariantActivePromotions.find(
    (activePromotion) => activePromotion.promotion === promotionId
  );

  const articleDetails = watch(`${articleIndex}.details`);

  const { hasDiscount } = useSelector(selectAllPermissions);

  const canApplyDiscount =
    hasDiscount.editAccess &&
    !isPriceChangeDisabled &&
    !hasAppliedClientSpecialDiscount &&
    !rewardLoyaltyCard;

  const canApplyPromotion =
    !subscriptionPurchaseId && !hasAppliedClientSpecialDiscount && !rewardLoyaltyCard;

  const maxPrice = Infinity;
  const minPrice = canApplyDiscount ? 0 : originalPrice;

  const canModifyPrice = maxPrice !== minPrice && !isPriceChangeDisabled;

  const handleDiscountChange = (value: number) => {
    setValue(discountValueFieldPath, value);
    const discountedPrice = originalPrice * ((100 - value) / 100);

    setValue(finalPriceFieldPath, discountedPrice);
    setValue("payments", []);
    setValue(promotionIdFieldPath, null);
  };

  const registerValidation = useFormValidation(formState, { ns: "authentication" });

  const handleFinalPriceChange = (value: number) => {
    if (value > originalPrice) {
      setValue(discountValueFieldPath, 0);
      setValue(markedPriceFieldPath, value);
    } else {
      const discountedPercentage = 100 - (value / originalPrice) * 100;

      setValue(discountValueFieldPath, Number(discountedPercentage.toFixed(2)));
      setValue(promotionIdFieldPath, null);
      setValue(markedPriceFieldPath, originalPrice);
    }

    setValue(finalPriceFieldPath, value);

    setValue("payments", []);
  };

  const handlePromotionChange = (
    value: Nullable<
      ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
    >
  ) => {
    if (value) {
      if (isPackageVariantActivePromotion(value)) {
        const finalPriceAfterPromotion = getFinalPriceAfterDiscount({
          originalPrice: markedPrice,
          discountOption: value.discountType,
          discountValue: value.value,
        });

        setValue(promotionIdFieldPath, value.promotion);
        setValue(finalPriceFieldPath, finalPriceAfterPromotion);
        setValue(discountValueFieldPath, 0);
      }
    } else {
      setValue(promotionIdFieldPath, null);
      setValue(finalPriceFieldPath, markedPrice);
      setValue(discountValueFieldPath, 0);
    }
  };

  return (
    <>
      <Grid item md={3} xs={12}>
        <PriceField
          label={t("price")}
          fullWidth
          value={finalPrice}
          max={maxPrice}
          min={minPrice}
          fallbackValue={originalPrice}
          {...registerValidation(finalPriceFieldPath)}
          onChange={handleFinalPriceChange}
          disabled={!canModifyPrice || shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <DiscountField
          value={discountValue}
          onChange={handleDiscountChange}
          label={t("discount")}
          fullWidth
          disabled={!canApplyDiscount || shouldDisableEditing}
        />
      </Grid>

      <Grid item md={3} xs={12}>
        <CheckoutPromotionSelectField
          value={promotionId}
          onChange={handlePromotionChange}
          promotions={
            !isSelectedPromotionInActivePromotions && selectedPromotion
              ? [...packageVariantActivePromotions, selectedPromotion]
              : packageVariantActivePromotions
          }
          arePromotionsLoading={false}
          disabled={!canApplyPromotion || shouldDisableEditing}
        />
      </Grid>

      <Grid item xs={12} my={1}>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item xs={12} container spacing={6}>
        {articleDetails.map((detail, index) => (
          <CheckoutCartItemPackageServiceArticleFields
            key={index}
            serviceVariantId={detail.purchasedItem?.serviceVariant}
            articleDetailsFieldPath={`articles.${cartItemIndex}.details.${index}`}
            shouldDisableEditing={shouldDisableEditing}
          />
        ))}
      </Grid>
    </>
  );
};

export default CheckoutCartItemPackageFields;
