import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/calendar/calendarApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { CalendarCard } from "types/Calendar";

import { SliceStatus } from "../utils";

export const calendarAdapter = createEntityAdapter<CalendarCard>({
  selectId: (calendarCard) => calendarCard.id,
});

const initialState = calendarAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const HANDLE_WEBSOCKET_UPDATES = "calendar/handleWebSocketUpdates";

export const handleCalendarWebSocketUpdates = createAction(
  HANDLE_WEBSOCKET_UPDATES,
  (calendarCard) => {
    return { payload: { calendarCard } };
  }
);

export const getCalendarCards = createThunkFromApiWithType(
  "calendar/getCalendarCards",
  api.getCalendarCards
);

export const updateCalendarCard = createThunkFromApiWithType(
  "calendar/updateCalendarCard",
  api.updateCalendarCard
);

export const updateAppointmentStartOnCalendar = createThunkFromApiWithType(
  "calendar/updateAppointmentStartOnCalendar",
  api.updateAppointmentStartOnCalendar
);

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,

  reducers: {
    upsertCalendarCards(state, { payload }) {
      const updatedAppointmentIds = payload.map((slot: CalendarCard) => slot.appointmentId);
      const currentCalendarCards = Object.values(state.entities).filter(
        (slot) => !updatedAppointmentIds.includes(slot?.appointmentId)
      );
      calendarAdapter.setAll(state, [...currentCalendarCards, ...payload]);
    },
    removeCalendarCards(state, { payload }) {
      calendarAdapter.removeMany(state, payload);
    },
  },

  extraReducers: (reducers) => {
    reducers.addCase(handleCalendarWebSocketUpdates, (state, { payload }) => {
      const updatedAppointmentIds = payload.calendarCard.map(
        (slot: CalendarCard) => slot.appointmentId
      );
      const currentCalendarCards = Object.values(state.entities).filter(
        (slot) => !updatedAppointmentIds.includes(slot?.appointmentId)
      );
      calendarAdapter.setAll(state, [...currentCalendarCards, ...payload.calendarCard]);
    });

    reducers.addCase(getCalendarCards.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getCalendarCards.fulfilled, (state, { payload }) => {
      calendarAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getCalendarCards.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(updateCalendarCard.fulfilled, (state, { payload }) => {
      calendarAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateAppointmentStartOnCalendar.fulfilled, (state, { payload }) => {
      calendarAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });
  },
});

export const { upsertCalendarCards, removeCalendarCards } = calendarSlice.actions;

export default calendarSlice.reducer;
