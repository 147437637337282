/**
 * Get the client status based on client vip and blocked fields
 *
 * @param {boolean} vip
 * @param {boolean} blocked
 *
 * @returns {string} a client status
 */

const getClientStatus = ({ vip, blocked }: { vip: boolean; blocked: boolean }): string => {
  if (blocked) return "blocked";
  else if (vip) return "vip";
  else return "regular";
};

export default getClientStatus;
