import { z } from "zod";

const newClientSchema = z.object({
  firstName: z.string().max(255),
  lastName: z.string().max(255).optional(),

  phoneCountryCode: z.string(),
  phoneNumber: z.string(),
});

const existingClientSchema = z.object({
  id: z.number().min(1),
});

const voucherGiftSchema = z
  .object({
    voucherPurchase: z.number().min(1),

    email: z.string().optional(),
    message: z.string().optional(),

    newClient: newClientSchema.nullable(),
    existingClient: existingClientSchema.nullable(),
  })
  .superRefine(({ newClient, existingClient }, ctx) => {
    // at least one field is required,
    if (!existingClient && !newClient) {
      return ctx.addIssue({
        code: "custom",
        path: ["existingClient", "newClient"],
        message: "mixed.required",
      });
    }
  });

export default voucherGiftSchema;

export type VoucherGiftFields = z.infer<typeof voucherGiftSchema>;
