import * as Sentry from "@sentry/react";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getErrorMessage } from "helpers/errors";
import api, { LoginResponse, UpdateUserResponse } from "store/auth/authApi";

import http, { setBearerToken } from "./http";
import report from "./report";

type User = {
  id: number;

  firstName: string;
  lastName: string;

  email: string;
  emailVerified: boolean;

  isOwnerOrAdmin: boolean;
  isEmployee: boolean;
  isTracked: boolean;
  employeeId: number | null;

  organizationName: string;
  organizationId: string;

  phoneCountryCode: string;
  phoneNumber: string;
  phoneVerified: boolean;

  dailyPerformanceEmail: boolean;
  weeklyPerformanceEmail: boolean;
  onlineBookingConfirmationEmail: boolean;
};

export const ACCESS_TOKEN = "auth_access";
const USER_DATA = "auth_user_data";
const REFRESH_TOKEN = "auth_refresh";

class AuthenticationManager {
  constructor() {
    if (!!this.accessToken && !!this.user) setBearerToken(this.accessToken);
    else this.logout();
  }

  login(loginResponse: LoginResponse, shouldRememberUser: boolean) {
    const { access, refresh, ...user } = loginResponse;

    this.user = user;
    this.accessToken = access;

    // identify user in Sentry
    const { id, firstName, lastName } = user;

    Sentry.setUser({ id: `${id}`, username: `${firstName} ${lastName}` });

    if (shouldRememberUser) this.refreshToken = refresh;
    else this.sessionRefreshToken = refresh;
  }

  logout() {
    this.user = null;
    this.accessToken = null;
    this.refreshToken = null;
    this.sessionRefreshToken = null;

    localStorage.clear();

    // clear user in Sentry
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  async refreshTokens() {
    if (!this.refreshToken) {
      auth.logout();
      return;
    }

    try {
      const response = await api.refreshToken(this.refreshToken);

      this.accessToken = response.data.access;

      setBearerToken(this.accessToken);

      return this.accessToken;
    } catch (error) {
      this.logout();

      report(getErrorMessage(error));
    }
  }

  updateUser(updateResponse: UpdateUserResponse) {
    this.user = updateResponse;
  }

  set user(user: User | null) {
    if (user) localStorage.setItem(USER_DATA, JSON.stringify(user));
    else localStorage.removeItem(USER_DATA);
  }

  get user() {
    const stringifiedUser = localStorage.getItem(USER_DATA);
    return stringifiedUser ? JSON.parse(stringifiedUser) : null;
  }

  get userFullName() {
    return this.user ? `${this.user.firstName} ${this.user.lastName}` : "";
  }

  get userEmployeeId() {
    return this.user?.employeeId;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  private set accessToken(token: string | null) {
    if (token) localStorage.setItem(ACCESS_TOKEN, token);
    else localStorage.removeItem(ACCESS_TOKEN);

    setBearerToken(token);
  }

  private get accessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  private set refreshToken(token: string | null) {
    if (token) localStorage.setItem(REFRESH_TOKEN, token);
    else localStorage.removeItem(REFRESH_TOKEN);
  }

  private set sessionRefreshToken(token: string | null) {
    if (token) sessionStorage.setItem(REFRESH_TOKEN, token);
    else sessionStorage.removeItem(REFRESH_TOKEN);
  }

  private get refreshToken() {
    return localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN);
  }
}

const auth = new AuthenticationManager();

createAuthRefreshInterceptor(http, async (failedRequest) => {
  const newToken = await auth.refreshTokens();

  failedRequest.response.config.headers["Authorization"] = `Bearer ${newToken}`;

  return Promise.resolve();
});

export default auth;
