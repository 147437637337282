import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Resource } from "types/Resource";

import { SliceStatus } from "../utils";
import api from "./resourcesApi";

export const resourcesAdapter = createEntityAdapter<Resource>({
  selectId: (resource) => resource.id,
});

const initialState = resourcesAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  page: number[];
  isRequestPending: SliceStatus;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  page: [],
  isRequestPending: SliceStatus.IDLE,
});

export const getResourceDetails = createThunkFromApiWithType(
  "resources/getResourceDetails",
  api.getResourceDetails
);

export const getResources = createThunkFromApiWithType("resources/getResources", api.getResources);

export const appendResources = createThunkFromApiWithType(
  "resources/appendResources",
  api.getResources
);

export const getAllResources = createThunkFromApiWithType(
  "resources/getAllResources",
  api.getAllResources
);

export const createResource = createThunkFromApiWithType(
  "resources/createResource",
  api.createResource
);

export const updateResource = createThunkFromApiWithType(
  "resources/updateResource",
  api.updateResource
);

export const deleteResource = createThunkFromApiWithType(
  "resources/deleteResource",
  api.deleteResource
);

export const resourcesSlice = createSlice({
  name: "resources",
  initialState,

  reducers: {},
  // This follows the the 'builder callback' notation instead: https://redux-toolkit.js.org/api/createSlice, instead of the object notation that will be deprecated in the future
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getResources.fulfilled, (state, { payload }) => {
        resourcesAdapter.upsertMany(state, payload.results);
        state.page = payload.results.map((resource) => resource.id);
        state.status = SliceStatus.IDLE;
        state.totalPages = payload.totalPages;
      })
      .addCase(getResources.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(getAllResources.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getAllResources.fulfilled, (state, { payload }) => {
        resourcesAdapter.upsertMany(state, payload);

        state.status = SliceStatus.IDLE;
      })
      .addCase(getAllResources.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(appendResources.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(appendResources.fulfilled, (state, { payload }) => {
        resourcesAdapter.upsertMany(state, payload.results);
        state.page = [...state.page, ...payload.results.map((resource) => resource.id)];
        state.status = SliceStatus.IDLE;
        state.totalPages = payload.totalPages;
      })
      .addCase(appendResources.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      .addCase(createResource.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(createResource.fulfilled, (state, { payload }) => {
        resourcesAdapter.upsertOne(state, payload);
        state.page = [payload.id, ...state.page];
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(createResource.rejected, (state) => {
        return { ...state, isRequestPending: SliceStatus.FAILED };
      })

      .addCase(updateResource.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateResource.fulfilled, (state, { payload }) => {
        resourcesAdapter.upsertOne(state, payload);
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateResource.rejected, (state) => {
        return { ...state, isRequestPending: SliceStatus.FAILED };
      })

      .addCase(deleteResource.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(
        deleteResource.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => {
          state.page = state.page.filter((p) => p !== id);
          resourcesAdapter.updateOne(state, { id, changes: { deprecated: true } });

          state.status = SliceStatus.IDLE;
        }
      )
      .addCase(deleteResource.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      });
  },
});

export default resourcesSlice.reducer;
