import { TableCellProps } from "@mui/material";
import type { ReactNode } from "react";

export enum HeaderOrder {
  ASC = "asc",
  DESC = "desc",
}

export type TableHeader = {
  align: any;
  key: string;
  name: string;
  width?: string;
  isSortable?: boolean;
};

export type TableItem = {
  [key: string]: { align: TableCellProps["align"]; component: ReactNode; action?: () => void };
};

export type TableRowData = {
  id: number;
  cells: TableItem;
  isCollapsible?: boolean;
  variants?: ReactNode;
};

export type Order = HeaderOrder.ASC | HeaderOrder.DESC | "";
