import { createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./appointmentsOverviewApi";

const initialState = {
  data: {
    todaysUpcomingAppointments: [],
    pastAppointments: [],
    upcomingAppointmentsExcludingToday: [],
    pendingAppointmentsThatHavePassed: [],
  },
  isLoading: false,
  error: "",
};

export const selectAppointmentsDataOverview = (state) => state.appointmentsOverview.data;

export const selectAppointmentOverviewDataLoading = (state) => state.appointmentsOverview.isLoading;

export const getAllAppointmentsOverview = createThunkFromApi(
  "appointments/appointments_overview/",
  api.getAppointmentsOverview
);

export const appointmentsOverviewSlice = createSlice({
  name: "appointmentsOverview",
  initialState,
  extraReducers: {
    [getAllAppointmentsOverview.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllAppointmentsOverview.fulfilled]: (
      state,
      {
        payload: {
          todaysUpcomingAppointments,
          pastAppointments,
          upcomingAppointmentsExcludingToday,
          pendingAppointmentsThatHavePassed,
        },
      }
    ) => {
      state.error = "";
      state.data = {
        todaysUpcomingAppointments,
        pastAppointments,
        upcomingAppointmentsExcludingToday,
        pendingAppointmentsThatHavePassed,
      };
      state.isLoading = false;
    },
    [getAllAppointmentsOverview.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export default appointmentsOverviewSlice.reducer;
