import { Autocomplete, TextField } from "@mui/material";
import FilteredItem from "components/common/Chips/FilteredItem";
import { AppointmentInvoiceStatusOptions } from "components/Sales/Context/CompletedAppointmentsContext";
import { InvoiceStatusOptions } from "components/Sales/Context/InvoicesLogContext";
import { ProductStockStatusOptions } from "pages/Inventory/Products/ProductListPage/ProductListContext/ProductListContext";
import { SubscriptionsStatusOptions } from "pages/Offers/Subscriptions/SubscriptionsListPage/SubscriptionsListContext/SubscriptionsListContext";
import { useTranslation } from "react-i18next";

const StatusFilter = ({ statusOptionsFor, unconfirmedValue, setUnconfirmedValue }) => {
  const { t } = useTranslation();

  const appointmentInvoiceStatusOptions = Object.values(AppointmentInvoiceStatusOptions);
  const invoiceStatusOptions = Object.values(InvoiceStatusOptions);
  const productStockStatusOptions = Object.values(ProductStockStatusOptions);
  const subscriptionStatusOptions = Object.values(SubscriptionsStatusOptions);

  let statusOptions = [];

  switch (statusOptionsFor) {
    case "completedAppointments":
      statusOptions = appointmentInvoiceStatusOptions;
      break;
    case "invoicesLog":
      statusOptions = invoiceStatusOptions;
      break;
    case "productsList":
      statusOptions = productStockStatusOptions;
      break;
    case "subscriptionsList":
      statusOptions = subscriptionStatusOptions;
      break;
    default:
      break;
  }

  statusOptions = statusOptions.map((status) => t(status));

  const addSelectedOptions = (_, newStatusOptions) => setUnconfirmedValue(newStatusOptions);

  return (
    <Autocomplete
      disableClearable={true}
      multiple
      options={statusOptions}
      getOptionLabel={(option) => option}
      value={unconfirmedValue}
      onChange={addSelectedOptions}
      renderInput={(params) => <TextField focused={true} label={t("status")} {...params} />}
      renderTags={(selectedOptions, getTagProps) =>
        selectedOptions.map((status, index) => (
          <FilteredItem
            key={status}
            variant="outlined"
            color="primary"
            label={status}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default StatusFilter;
