import { Box, Card, Divider, styled } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "store";
import { getAppointmentDetails } from "store/appointments/appointmentsSlice";
import { getClient } from "store/clients/clientsSlice";
import {
  selectInvoiceById,
  selectIsCheckoutInvoiceLoading,
} from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { getInvoice } from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import { PeepPayLinkStatus } from "types/Deposit";
import useNotify from "utils/notifications/useNotify";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import InvoiceArticlesSection from "./InvoiceArticlesSection";
import InvoiceHeaderSection from "./InvoiceHeaderSection";
import InvoicePaymentsSummary from "./InvoicePaymentsSummary";
import InvoicePendingPaymentsSection from "./InvoicePendingPaymentsSection";
import InvoiceRowItem from "./InvoiceRowItem";
import InvoiceRowPriceItem from "./InvoiceRowPriceItem";
import InvoiceSwitcherDots from "./InvoiceSwitcherDots";

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const hasFetchedInvoiceRef = useRef(false);
  const { invoiceId, setInvoiceId } = useCheckoutModal();

  const isInvoiceLoading = useSelector(selectIsCheckoutInvoiceLoading);
  const invoice = useSelector(selectInvoiceById(invoiceId ?? -1));

  const {
    setValue,
    invoiceCardRef,
    balance,
    shouldShowInvoiceTabs,
    originalInvoiceId,

    refundInvoiceId,
  } = useCheckoutFormContext();

  const fetchedInvoiceIdRef = useRef<Nullable<number>>(null);

  useEffect(() => {
    if (!invoiceId) return;

    if (invoiceId === fetchedInvoiceIdRef.current) return;

    if (isInvoiceLoading) return;

    // fetch the invoice

    dispatch(getInvoice({ id: invoiceId }))
      .unwrap()
      .then((invoice) => {
        hasFetchedInvoiceRef.current = true;

        if (invoice.client) {
          setValue("existingClient.id", invoice.client);
          setValue("notes", invoice.notes);

          dispatch(getClient(invoice.client));
        }

        if (invoice.refundInvoice) {
          dispatch(getInvoice({ id: invoice.refundInvoice.id }))
            .unwrap()
            .catch((error) => {
              notify(`${error?.detail}`, "error");
            });
        }

        if (invoice.originalInvoice) {
          dispatch(getInvoice({ id: invoice.originalInvoice }))
            .unwrap()
            .catch((error) => {
              notify(`${error?.detail}`, "error");
            });
        }

        setValue("outlet", invoice.outlet.id);

        fetchedInvoiceIdRef.current = invoiceId;

        // fetch invoice appointment, so that it updates the appointment state on the calendar
        // @ts-expect-error
        if (invoice.appointment) dispatch(getAppointmentDetails(invoice.appointment));
      })
      .catch((error) => {
        notify(`${error?.detail}`, "error");
      });
  }, [dispatch, invoiceId, isInvoiceLoading, notify, setValue]);

  if (isInvoiceLoading || !invoice) return <CentredSpinnerBox />;

  const handleInvoiceTabClick = (invoiceId: Nullable<number>) => {
    if (!invoiceId) return;
    setInvoiceId(invoiceId);
  };

  const shouldShowPendingPaymentsSection = Boolean(
    invoice.pendingPayments?.filter(
      (pendingPayment) => pendingPayment.status === PeepPayLinkStatus.Pending
    ).length
  );

  return (
    <Box display="flex" alignItems="center">
      {shouldShowInvoiceTabs && refundInvoiceId && originalInvoiceId && (
        <InvoiceSwitcherDots
          options={[
            {
              id: 0,
              // TODO: when you have time, investigate why this is not working with activeTabInvoiceId
              isActive: invoiceId === refundInvoiceId,

              onClick: () => {
                handleInvoiceTabClick(refundInvoiceId);
              },
            },
            {
              id: 1,
              // TODO: when you have time, investigate why this is not working with activeTabInvoiceId
              isActive: invoiceId === originalInvoiceId,
              onClick: () => {
                handleInvoiceTabClick(originalInvoiceId);
              },
            },
          ]}
        />
      )}

      <Box p={3}>
        <InvoiceCard id="invoice-details" ref={invoiceCardRef}>
          <InvoiceHeaderSection invoice={invoice} />
          <Divider variant="fullWidth" />

          <InvoiceArticlesSection articles={invoice.articles} />

          <InvoicePaymentsSummary invoice={invoice} />

          <InvoiceRowItem
            title="invoices:invoiceNotes"
            description={invoice.notes}
            hasBottomDivider
          />

          <InvoiceRowPriceItem
            title="invoices:balance"
            amount={balance}
            hasBottomDivider={shouldShowPendingPaymentsSection}
          />

          {shouldShowPendingPaymentsSection && <InvoicePendingPaymentsSection invoice={invoice} />}
        </InvoiceCard>
      </Box>
    </Box>
  );
};

export default InvoiceDetails;

const InvoiceCard = styled(Card)(({ theme }) => ({
  maxWidth: "497px",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  boxShadow: "0px 4px 8px 0px #00000040",
  alignSelf: "center",
  padding: theme.spacing(4, 3),
}));
