import { Box, Button, Divider, Stack } from "@mui/material";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { sortByDateDescending } from "helpers/sort";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById, selectIsClientLoading } from "store/clients/clientsSlice";
import { Client, GetClientInvoicesResponse } from "types/Client";
import http from "utils/http";
import useNotify from "utils/notifications/useNotify";

import ClientInvoicesHistoryItem from "./ClientInvoicesHistoryItem";
import ClientInvoicesHistoryItemSkeleton from "./ClientInvoicesHistoryItem.skeleton";

type ClientInvoicesTabProps = {
  clientId: number;
};

const ClientInvoicesHistoryTab = ({ clientId }: ClientInvoicesTabProps) => {
  const notify = useNotify();
  const { t } = useTranslation(["translation", "appointments", "errors"]);

  const hasFetchInvoicesRef = useRef(false);

  const [invoicesData, setInvoicesData] = useState<GetClientInvoicesResponse>();

  const client = useSelector(selectClientById(clientId)) as Client;
  const isClientLoading = useSelector(selectIsClientLoading) as boolean;

  const { invoicesLink } = client;

  useEffect(() => {
    if (invoicesLink && !hasFetchInvoicesRef.current) {
      http
        .get<GetClientInvoicesResponse>(invoicesLink)
        .then((response) => setInvoicesData(response.data))
        .catch((error) => notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error"));
      hasFetchInvoicesRef.current = true;
    }
  }, [invoicesLink, notify, t]);

  const handleLoadMore = () => {
    if (invoicesData?.next) {
      http
        .get(invoicesData.next)
        .then(({ data: { results, next } }) =>
          setInvoicesData({
            ...invoicesData,
            next,
            results: [...invoicesData.results, ...results],
          })
        )
        .catch((error) => notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error"));
    }
  };

  if (!invoicesData || isClientLoading)
    return (
      <Stack gap={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <ClientInvoicesHistoryItemSkeleton key={index} />
        ))}
      </Stack>
    );

  const invoiceItems = sortByDateDescending(invoicesData.results, "createdAt").map((invoice) => (
    <ClientInvoicesHistoryItem invoice={invoice} key={invoice.id} />
  ));

  const hasInvoices = !!invoicesData.results.length;

  if (!hasInvoices) return <EmptyGraphicSection description={t("appointments:noInvoices")} />;

  return (
    <Stack gap={3} py={3} flexGrow={1}>
      <Stack gap={3} divider={<Divider />} width="100%">
        {invoiceItems}
      </Stack>

      {invoicesData.next && (
        <Box display="flex" flexDirection="row" width="100%" px={3}>
          <Button fullWidth variant="outlined" onClick={handleLoadMore}>
            {t("loadMore")}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default ClientInvoicesHistoryTab;
