import { Box, Grid, MenuItem, TextField, styled } from "@mui/material";
import DatePickerButton from "components/Sales/DatePickerButton";
import { PeepSearchField } from "components/common";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useIsMobileView from "hooks/useIsMobileView";
import { DateTime } from "luxon";
import { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectAllOutlets } from "store/outlets/outletsSlice";

import PeepPayLinksContext from "./PeepPayLinksContext";

const PeepPayLinksToolbar = () => {
  const { t } = useTranslation("home");

  const {
    setPage,
    dateRange,
    setDateRange,
    outletId,
    setOutletId,
    searchQuery,
    handleInputChange,
  } = useContext(PeepPayLinksContext);

  const isMobileView = useIsMobileView();

  const outletsState = useSelector(selectAllOutlets);

  const organization = useSelector(selectOrganization);

  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization.industry || 0);

  const onChangeOutlet = (event: { target: { value: any } }) => {
    setPage(1);
    setOutletId(event.target.value);
  };

  return (
    <PageWrapper isMobileView={isMobileView}>
      <Grid container spacing={1} mb={isMobileView ? 1 : 0}>
        <Grid item md={2} xs={6} order={{ xs: 2, md: 1 }}>
          <DatePickerButton
            onConfirm={(value: DateTime[]) => {
              setPage(1);
              setDateRange(value);
            }}
            dateRange={dateRange}
          />
        </Grid>

        {!isHomeServiceOrganization && (
          <Grid item md={2} xs={6} order={{ xs: 2, md: 2 }}>
            <TextField
              select
              fullWidth
              label={t("location")}
              value={outletId || 0}
              onChange={onChangeOutlet}
              placeholder="location"
            >
              <MenuItem value={0}>{t("allLocations")}</MenuItem>
              {outletsState.map((outlet) => (
                <MenuItem value={outlet.id} key={outlet.id}>
                  {outlet.deprecated ? `${outlet.name} (${t("deleted")})` : outlet.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item md={3} xs={12} order={{ xs: 1, md: 3 }}>
          <PeepSearchField
            fullWidth
            label={t("searchByClientNameOrMobileNumber")}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
            value={searchQuery}
            onClear={() => handleInputChange("")}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default PeepPayLinksToolbar;

type PageWrapperProps = {
  isMobileView: boolean;
};

const PageWrapper = styled(Box)<PageWrapperProps>(({ theme, isMobileView }) => ({
  paddingLeft: isMobileView ? theme.spacing(0) : theme.spacing(3),
  paddingRight: isMobileView ? theme.spacing(0) : theme.spacing(3),
}));
