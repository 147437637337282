import { Box } from "@mui/material";
import { PurchasableItemsSelector } from "components/common";
import { OnSelectItemFunction } from "components/common/PurchasableItemsSelector/PurchasableItemsSelectorContext";
import { checkIsDateInThePast } from "helpers/checkIsDateInThePast";
import useIsMobileView from "hooks/useIsMobileView";
import { useSelector } from "store";
import { selectActiveSubscriptionByClientIdAndOutletId } from "store/clients/clientsSlice";
import {
  selectAllPackageVariantActivePromotionsBasedOnDate,
  selectAllProductActivePromotionsBasedOnDate,
  selectAllServiceVariantActivePromotionsBasedOnDate,
} from "store/selectors";
import { PurchasableItemOption } from "types/PurchasableItem";
import { isRedeemableSubscriptionItemOption } from "types/typeGuards/SubscriptionTypeGuards";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import {
  convertPurchasableItemTypeToRedeemableSubscriptionItemOption,
  getDefaultRedeemableSubscription,
} from "../RedeemSubscriptionsUtils";
import AddPurchasableItemsFooter from "./AddPurchasableItemsFooter";
import AddPurchasableItemsHeader from "./AddPurchasableItemsHeader";
import { initializeCheckoutArticle } from "./utils";

const AddPurchasableItems = () => {
  const isMobileView = useIsMobileView();

  const { appendCartItem, appointmentStartTime, watch, date } = useCheckoutFormContext();

  const { existingClient, outlet, articles, appliedClientSpecialDiscount } = watch();

  const clientId = existingClient?.id;

  const clientActiveSubscriptions = useSelector(
    selectActiveSubscriptionByClientIdAndOutletId(clientId, outlet)
  );

  const serviceVariantActivePromotions = useSelector(
    selectAllServiceVariantActivePromotionsBasedOnDate(date)
  );

  const packageActivePromotions = useSelector(
    selectAllPackageVariantActivePromotionsBasedOnDate(date)
  );
  const productActivePromotions = useSelector(selectAllProductActivePromotionsBasedOnDate(date));

  const getActivePromotions = (
    purchasableItemId: number,
    purchasableItemType: PurchasableItemOption
  ) => {
    switch (purchasableItemType) {
      case PurchasableItemOption.Service:
        return serviceVariantActivePromotions.filter(
          (promotion) => promotion.serviceVariant === purchasableItemId || promotion.hasAllServices
        );

      case PurchasableItemOption.Package:
        return packageActivePromotions.filter(
          (promotion) => promotion.packageVariant === purchasableItemId || promotion.hasAllPackages
        );

      case PurchasableItemOption.Product:
        return productActivePromotions.filter(
          (promotion) => promotion.product === purchasableItemId || promotion.hasAllProducts
        );

      default:
        return [];
    }
  };

  const addPurchasableItemToCart: OnSelectItemFunction = (purchasableItem, purchasableItemType) => {
    const redemptionItemType =
      convertPurchasableItemTypeToRedeemableSubscriptionItemOption(purchasableItemType);

    const subscriptionIdToBeApplied = isRedeemableSubscriptionItemOption(redemptionItemType)
      ? getDefaultRedeemableSubscription(
          articles,
          clientActiveSubscriptions,
          purchasableItem.id,
          redemptionItemType
        )
      : null;

    const activePromotions = getActivePromotions(purchasableItem.id, purchasableItemType);

    // If there are active promotions, and there is no subscription and no client special discount to be applied, then auto apply the first promotion, you cannot have an applied promotion with a subscription or client special discount  at the same time
    const promotionToBeApplied =
      activePromotions.length && !subscriptionIdToBeApplied && !appliedClientSpecialDiscount
        ? activePromotions[0]
        : null;

    appendCartItem(
      initializeCheckoutArticle(
        purchasableItem,
        purchasableItemType,
        appointmentStartTime,
        subscriptionIdToBeApplied,
        promotionToBeApplied,
        appliedClientSpecialDiscount
      )
    );
  };

  const shouldShowPromotionTag = !checkIsDateInThePast(date);

  return (
    <>
      <AddPurchasableItemsHeader />

      <Box flexGrow={1} overflow="scroll">
        <PurchasableItemsSelector
          onSelectItem={addPurchasableItemToCart}
          clientId={clientId}
          outletId={outlet}
          shouldShowPromotionTag={shouldShowPromotionTag}
        />
      </Box>

      {!isMobileView && <AddPurchasableItemsFooter />}
    </>
  );
};

export default AddPurchasableItems;
