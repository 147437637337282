import { ExpanderButton } from "@bookpeep/ui";
import { Stack } from "@mui/material";
import useIsMobileView from "hooks/useIsMobileView";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectActiveSubscriptionByClientId,
  selectActiveSubscriptionByClientIdAndOutletId,
  selectClientById,
} from "store/clients/clientsSlice";
import { Client } from "types/Client";
import { ClientSubscriptionPurchase } from "types/Subscription";

import ClientTags from "./ClientTags";

type ClientSummaryProps = {
  clientId: number;
  outletId?: number;
  shouldShowToggleTagsVisibilityButton?: boolean;
  initialShouldShowClientTags?: boolean;
};

const ClientSummary = ({
  clientId,
  outletId,

  shouldShowToggleTagsVisibilityButton = true,
  initialShouldShowClientTags = false,
}: ClientSummaryProps) => {
  const { t } = useTranslation(["translation", "appointments", "client"]);
  const isMobileView = useIsMobileView();

  const client = useSelector(selectClientById(clientId)) as Client;

  const clientSubscriptions = useSelector(
    outletId
      ? selectActiveSubscriptionByClientIdAndOutletId(clientId, outletId)
      : selectActiveSubscriptionByClientId(clientId)
  ) as ClientSubscriptionPurchase[];

  const { appointmentsData, invoicesData } = client || {};

  const hasInvoiceData = !!invoicesData?.totalSales || !!invoicesData?.totalUnpaid;

  const hasAppointmentsData = !!appointmentsData?.appointmentsCount;

  // on mobile we hide total unpaid and total bookings
  const doesClientHaveStatistics =
    ((hasAppointmentsData || hasInvoiceData) && !isMobileView) || clientSubscriptions.length > 0;

  const shouldShowClientStatisticsExpanderButton =
    doesClientHaveStatistics && shouldShowToggleTagsVisibilityButton;

  const [shouldShowClientTags, setShouldShowClientTags] = useState(initialShouldShowClientTags);

  const toggleClientTags = () => setShouldShowClientTags((value) => !value);

  return (
    <Stack pt={1} gap={1} flexGrow={1}>
      {shouldShowClientStatisticsExpanderButton && (
        <ExpanderButton
          expanded={shouldShowClientTags}
          onClick={toggleClientTags}
          variant="text"
          size="small"
          fullWidth
          sx={{ minHeight: 0, textTransform: "capitalize" }}
        >
          {shouldShowClientTags ? t("client:hideClientSummary") : t("client:showClientSummary")}
        </ExpanderButton>
      )}

      {shouldShowClientTags && <ClientTags clientId={clientId} outletId={outletId} />}
    </Stack>
  );
};

export default ClientSummary;
