import { Button, ButtonGroup, CircularProgress, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

const CustomIconButton = ({
  label,
  icon,
  onClick,
  variant,
  maxWidth,
  ariaDescribedBy = "",
  disabled = false,
  loading = false,
}) => {
  const isContained = variant === "contained";

  return (
    <StyledButtonGroup
      disableElevation
      fullWidth={true}
      variant={variant}
      aria-label="split button"
      maxWidth={maxWidth}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <StyledButton
        variant={variant}
        aria-describedby={ariaDescribedBy}
        disableTouchRipple
        fullWidth={true}
        size="small"
        isContained={isContained}
      >
        {loading ? (
          <CircularProgress
            sx={{
              color: "rgba(0, 0, 0, 0.26)",
              width: "16px !important", // "important" is needed to override the default "40px"
              height: "16px !important", // "important" is needed to override the default "40px"
            }}
          />
        ) : (
          label
        )}
      </StyledButton>

      <StyledIconButton
        variant={variant}
        disableTouchRipple
        fullWidth={false}
        size="large"
        isContained={isContained}
      >
        {icon}
      </StyledIconButton>
    </StyledButtonGroup>
  );
};

export default CustomIconButton;

const StyledButtonGroup = styled(
  ButtonGroup,
  dontForwardProps("maxWidth")
)(({ maxWidth }) => ({
  maxWidth,
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "& :last-child": {
    borderLeft: "none",
  },
}));

const StyledButton = styled(
  Button,
  dontForwardProps("isContained")
)(({ theme, isContained }) => ({
  justifyContent: "flex-start",
  borderRight: "none",
  fontWeight: 700,
  "&:hover": {
    borderRight: "none",
    backgroundColor: isContained ? theme.palette.primary.main : "transparent",
  },
}));

const StyledIconButton = styled(
  Button,
  dontForwardProps("isContained")
)(({ theme, isContained }) => ({
  maxWidth: "3em",
  justifyContent: "center",
  opacity: isContained && 0.8,
  borderLeft: "none",

  "&:hover": {
    borderLeft: "none",
    opacity: isContained && 0.8,
    backgroundColor: isContained ? theme.palette.primary.main : "transparent",
  },

  //  remove border from disabled button
  "&.Mui-disabled": {
    borderLeft: "none",
  },
}));
