import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Quicksand",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18E.ttf",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf",
      fontWeight: "700",
    },
  ],
});

Font.register({
  family: "Cairo",
  fonts: [
    {
      src: "/fonts/Cairo.ttf",
    },
  ],
});

// Create styles
export const styles = StyleSheet.create({
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  topHalf: {
    height: "55%",
    display: "flex",
    alignItems: "center",
    paddingTop: "40px",
    position: "absolute",
    left: "0px",
    right: "0px",
    marginHorizontal: "auto",
    textAlign: "center",
    paddingHorizontal: "20px",
  },
  bottomHalf: {
    height: "45%",
    display: "flex",
    alignItems: "center",
    paddingVertical: "20px",
    paddingHorizontal: "60px",
    top: "55%",
    position: "absolute",
    left: "0px",
    right: "0px",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "space-evenly",
  },
  logo: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
  },
  codeSection: {
    backgroundColor: "#F3F3FB",
    borderRadius: "8px",
    height: "40px",
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  page: {
    padding: "10px",
  },
});
