import { Grid } from "@mui/material";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { getSubscriptionExpirationDate } from "helpers/getSubscriptionExpirationDate";
import { useCallback } from "react";
import { DiscountOption } from "types/promotions";
import { ProcessedSubscriptionPurchase } from "types/Subscription";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import { getTotalCreditsBeingRedeemedOfSubscription } from "../RedeemSubscriptionsUtils";
import CheckoutCartItemRedeemableSubscription from "./CheckoutCartItemRedeemableSubscription";

type CheckoutCartItemRedeemableSubscriptionsProps = {
  clientActiveSubscriptionsOfCartItem: ProcessedSubscriptionPurchase[];

  cartItemIndex: number;
  shouldDisableRedeemingSubscription: boolean;
};

const CheckoutCartItemRedeemableSubscriptions = ({
  clientActiveSubscriptionsOfCartItem,
  cartItemIndex,
  shouldDisableRedeemingSubscription,
}: CheckoutCartItemRedeemableSubscriptionsProps) => {
  const { setValue, watch } = useCheckoutFormContext();

  const { articles, appliedClientSpecialDiscount } = watch();

  const hasAppliedClientSpecialDiscount = appliedClientSpecialDiscount > 0;

  const articleIndex: `articles.${number}` = `articles.${cartItemIndex}`;

  const article = watch(articleIndex);

  const subscriptionPurchaseIdFieldPath: `articles.${number}.subscriptionPurchaseId` = `${articleIndex}.subscriptionPurchaseId`;

  const finalPriceFieldPath: `articles.${number}.finalPrice` = `${articleIndex}.finalPrice`;
  const promotionIdFieldPath: `articles.${number}.promotionId` = `${articleIndex}.promotionId`;
  const discountValueFieldPath: `articles.${number}.discountValue` = `${articleIndex}.discountValue`;

  const { markedPrice, subscriptionRedemptionId, subscriptionPurchaseId } = article;

  const selectedSubscriptionPurchaseId = watch(subscriptionPurchaseIdFieldPath);

  const applySubscriptionToArticle = useCallback(
    (subscriptionId: number) => {
      setValue(subscriptionPurchaseIdFieldPath, subscriptionId);
      setValue(finalPriceFieldPath, 0);
      setValue(promotionIdFieldPath, null);
      setValue(discountValueFieldPath, 0);
    },
    [
      discountValueFieldPath,
      finalPriceFieldPath,
      promotionIdFieldPath,
      setValue,
      subscriptionPurchaseIdFieldPath,
    ]
  );

  const handleToggleSubscriptionActivation = (subscriptionId: number) => {
    const isSubscriptionApplied = subscriptionId === selectedSubscriptionPurchaseId;

    if (isSubscriptionApplied) {
      setValue(subscriptionPurchaseIdFieldPath, null);
      if (hasAppliedClientSpecialDiscount) {
        setValue(
          finalPriceFieldPath,
          getFinalPriceAfterDiscount({
            originalPrice: markedPrice,
            discountOption: DiscountOption.Percentage,
            discountValue: appliedClientSpecialDiscount,
          })
        );
        setValue(discountValueFieldPath, appliedClientSpecialDiscount);
      } else setValue(finalPriceFieldPath, markedPrice);
    } else {
      applySubscriptionToArticle(subscriptionId);
    }
  };

  return (
    <>
      {clientActiveSubscriptionsOfCartItem.map((subscription) => {
        const {
          id: subscriptionId,
          name,
          createdAt,
          validityInDays,
          remainingRedemptionsCount,
          totalCreditsCount,
        } = subscription;

        const totalOfSubscriptionCreditsBeingRedeemed = getTotalCreditsBeingRedeemedOfSubscription(
          subscriptionId,
          articles
        );

        const isSubscriptionAppliedOnAppointment =
          subscriptionId === subscriptionPurchaseId && !!subscriptionRedemptionId;

        const totalRemainingRedemptionsCount =
          remainingRedemptionsCount - totalOfSubscriptionCreditsBeingRedeemed;

        const previouslyRedeemedSubscriptionCredits = totalCreditsCount - remainingRedemptionsCount;

        const usedCreditsCount =
          totalOfSubscriptionCreditsBeingRedeemed + previouslyRedeemedSubscriptionCredits;

        const isSubscriptionApplied = subscriptionId === selectedSubscriptionPurchaseId;

        const subscriptionExpirationDate = getSubscriptionExpirationDate(createdAt, validityInDays);
        const formattedSubscriptionExpirationDate =
          subscriptionExpirationDate.toFormat("dd MMMM yyyy");

        const shouldDisableRedeeming =
          (totalRemainingRedemptionsCount === 0 && !isSubscriptionApplied) ||
          shouldDisableRedeemingSubscription;

        return (
          <Grid item xs={12} key={`RedeemableSubscription-${subscriptionId}`}>
            <CheckoutCartItemRedeemableSubscription
              title={name}
              expirationDate={formattedSubscriptionExpirationDate}
              remainingRedemptionsCount={totalRemainingRedemptionsCount}
              usedCreditsCount={usedCreditsCount}
              totalCreditsCount={totalCreditsCount}
              isSubscriptionApplied={isSubscriptionApplied}
              setIsSubscriptionApplied={() => handleToggleSubscriptionActivation(subscriptionId)}
              disableRedeeming={shouldDisableRedeeming}
              isRedeemedOnAppointment={isSubscriptionAppliedOnAppointment}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default CheckoutCartItemRedeemableSubscriptions;
