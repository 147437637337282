import { Box, Typography } from "@mui/material";
import truncate from "helpers/truncate";
import { useContext } from "react";

import ServicesSearchFieldContext from "../../ServicesSearchFieldContext";
import VariantItem from "../VariantItem";

const ServiceCategory = ({ category, date = "", outletId = 0 }) => {
  const { name, services, color } = category;

  const { closeMenu, setQuery, serviceVariantId, onSelectServiceVariant } = useContext(
    ServicesSearchFieldContext
  );

  const handleVariantClick = (serviceVariant, serviceName, isResourceRequired, finalPrice) => {
    onSelectServiceVariant(serviceVariant, finalPrice, isResourceRequired);

    // since we are filtering by the service name we need to set the query to the service name to filter the list and show the selected service variant
    setQuery(() => serviceName);

    closeMenu();
  };

  const serviceVariants = services
    ?.map((service) =>
      service.variants.map((variant) => (
        <VariantItem
          key={variant.id}
          isSelected={variant.id === serviceVariantId}
          handleClick={(finalPrice) =>
            handleVariantClick(variant, service.name, service.needResources, finalPrice)
          }
          color={color}
          date={date}
          outletId={outletId}
          title={service.name}
          subtitle={variant.name}
          price={variant.price}
          duration={variant.duration}
          id={variant.id}
          isPackage={false}
          referenceCode={service.referenceCode}
        />
      ))
    )
    .reduce((allVariants, serviceVariants) => [...allVariants, ...serviceVariants], []);

  return (
    <Box pt={2}>
      <Typography variant="h3" px={2}>
        {truncate({ string: name, maxCharacters: 40 }).string}
      </Typography>
      {serviceVariants}
    </Box>
  );
};

export default ServiceCategory;
