import { zodResolver } from "@hookform/resolvers/zod";
import useCheckoutModal from "hooks/useCheckoutModal";
import { DateTime } from "luxon";
import { useEffect, useRef } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { selectOnlinePaymentsByAppointmentId } from "store/onlinePayments/onlinePaymentsSlice";
import { selectDepositsByAppointmentId } from "store/selectors";
import { getActivePromotions } from "store/slices/activePromotions/activePromotionsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { selectPeepTransactionsByAppointmentId } from "store/slices/peepTransactions/peepTransactionsSelectors";
import { InvoiceStatus, InvoiceType, OnlinePayment } from "types/Checkout";
import { PeepPayLinkStatus } from "types/Deposit";
import { z } from "zod";

import checkoutFormSchema, {
  checkoutArticleSchema,
  voucherRedemptionSchema,
} from "./checkoutFormSchema";
import {
  callGetCheckoutTotalBalanceAmount,
  callGetCheckoutTotalMarkedPrice,
  callGetCheckoutTotalPrice,
} from "./utils";

export type CheckoutCartFormInputs = z.infer<typeof checkoutFormSchema>;
export type CheckoutArticle = z.infer<typeof checkoutArticleSchema>;
export type CheckoutVoucherRedemption = z.infer<typeof voucherRedemptionSchema>;

const useCheckoutForm = () => {
  const {
    outletId,
    invoiceId,
    appointmentId,
    checkoutArticles,
    checkoutModalView,
    setCheckoutModalView,
    isCheckoutModalOpen,
  } = useCheckoutModal();

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId ?? undefined));

  const invoice = useSelector(selectInvoiceById(invoiceId || 0));
  const shouldShowInvoiceTabs =
    invoice?.status !== InvoiceStatus.Void && (invoice?.refundInvoice || invoice?.originalInvoice);

  const onlinePayments = useSelector(
    selectOnlinePaymentsByAppointmentId(appointmentId)
  ) as OnlinePayment[];

  const peepTransactions = useSelector(
    selectPeepTransactionsByAppointmentId(appointmentId)
  )
  

  const deposit = useSelector(selectDepositsByAppointmentId(appointmentId || 0));

  const hasFetchedActivePromotionForDate = useRef("");
  const hasSetAppointmentCartArticles = useRef<Nullable<number>>(null);

  const dispatch = useDispatch();

  const hasAppointment = !!appointment;

  const date = hasAppointment ? appointment?.date : DateTime.now().toFormat("yyyy-MM-dd");

  useEffect(() => {
    if (isCheckoutModalOpen && outletId && hasFetchedActivePromotionForDate.current !== date) {
      hasFetchedActivePromotionForDate.current = date;
      dispatch(getActivePromotions({ date, outletId }));
    }
  }, [date, dispatch, isCheckoutModalOpen, outletId]);

  const methods = useForm<CheckoutCartFormInputs>({
    // NOTE that default values are not being updated properly, so we use use effect of fetching the the appointment of invoice to initialize the form
    defaultValues: {
      id: invoiceId || undefined,
      outlet: outletId || undefined,
      appointment: appointmentId || null,

      // this should be modified to be conditional based on the appointment invoice
      paymentAmount: 0,

      deletedArticles: [],
      newClient: null,
      // this could be invoice client, but it is being set on src/modals/CheckoutModal/InvoiceDetails/InvoiceDetails.tsx
      existingClient: appointment?.client ? { id: appointment.client } : null,
      notes: "",
      appliedClientSpecialDiscount: 0,

      payments: [],
      voucherRedemptions: [],

      articles: appointment ? checkoutArticles : [],

      walletPayments: [],
    },

    resolver: zodResolver(checkoutFormSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { control, watch, setValue } = methods;

  useEffect(() => {
    if (
      appointment &&
      checkoutArticles.length &&
      appointment.id !== hasSetAppointmentCartArticles.current
    ) {
      setValue("existingClient", appointment?.client ? { id: appointment.client } : null);
      setValue("appointment", appointmentId);
      setValue("articles", checkoutArticles);

      setCheckoutModalView(CheckoutModalView.Payment);

      hasSetAppointmentCartArticles.current = appointment.id;
    }
  }, [appointment, setValue, watch, appointmentId, checkoutArticles, setCheckoutModalView]);

  const {
    fields: cartItemFields,
    append: appendCartItem,
    remove: removeCartItem,
  } = useFieldArray({
    control,
    name: "articles",
  });

  const { payments, articles, voucherRedemptions, walletPayments, refundInvoice } = watch();

  const { articlesToRefund = [], refundPayments = [] } = refundInvoice || {};

  const articlesTotalFinalPrice = articles.reduce(callGetCheckoutTotalPrice, 0);

  const articleTotalMarkedPriceWithoutSubscriptionRedemption = articles
    .filter((article) => !article.subscriptionPurchaseId)
    .reduce(callGetCheckoutTotalMarkedPrice, 0);

  //  total price before discount
  const subtotal = Number(articleTotalMarkedPriceWithoutSubscriptionRedemption.toFixed(3));

  const onlinePayment = onlinePayments.reduce(
    (currentTotal, payment) => currentTotal + Number(payment.total),
    0
  );

  const couponOnlinePaymentTotal = onlinePayments.filter(
    onlinePayment => onlinePayment.paymentType === 'coupon'
  ).reduce(
    (currentTotal, payment) => currentTotal + Number(payment.total),
    0
  );

  const peepTransactionTotal = peepTransactions.reduce(
    (currentTotal, payment) => currentTotal + Number(payment.amountDue),
    0
  );
  

  const depositPayment = deposit?.amount || 0;

  // paid amount using payment methods
  const paid = payments.reduce(callGetCheckoutTotalBalanceAmount, 0);

  const paidFromWallet =
    walletPayments?.reduce(
      (walletPaidTotal, walletPayment) => walletPaidTotal + walletPayment.amount,
      0
    ) || 0;

  const hasPendingPayments = !!invoice?.pendingPayments?.[0];

  const appliedVoucherRedemptionsAmount = voucherRedemptions.reduce(
    (currentTotal, redemption) => currentTotal + redemption.value,
    0
  );

  // After creating the invoice, main invoice balance comes from the backend
  const invoiceBalance = Number(invoice?.balance) || 0;

  // had to do the following to avoid weird javascript rounding errors
  const totalDue = invoice ? invoiceBalance : Number(articlesTotalFinalPrice.toFixed(3));

  const totalDiscount = Number(
    (articleTotalMarkedPriceWithoutSubscriptionRedemption - totalDue).toFixed(3)
  );

  const refundTotal =
    invoice?.articles
      .filter((article) => articlesToRefund?.includes(article.id))
      .reduce((currentTotal, article) => currentTotal + Number(article.finalPrice), 0) || 0;

  const getBalance = () => {
    if (invoice) {
      if (checkoutModalView === CheckoutModalView.Refund && refundInvoice) {
        const totalRefundPaymentsAmountBeforeRounding = refundPayments.reduce(
          (currentTotal, payment) => currentTotal + Number(payment.amount),
          0
        );

        const totalRefundPaymentsAmount = Number(
          totalRefundPaymentsAmountBeforeRounding.toFixed(3)
        );

        return refundTotal - totalRefundPaymentsAmount;
      } else {
        let pendingPeepPaymentAmount = 0;
        if (
          invoice.pendingPayments?.filter(
            (pendingPayment) => pendingPayment.status === PeepPayLinkStatus.Pending
          ).length
        ) {
          pendingPeepPaymentAmount = Number(invoice.pendingPayments?.[0].amount);
        }

        const invoiceBalanceWhileAddingPaymentsBeforeRounding =
          invoiceBalance - (paid + paidFromWallet);
        const invoiceBalanceWhileAddingPayments = Number(
          invoiceBalanceWhileAddingPaymentsBeforeRounding.toFixed(3)
        );
        return invoiceBalanceWhileAddingPayments - pendingPeepPaymentAmount;
      }
    } else {
      // Balance before creating a new invoice:
      const checkoutBalanceBeforeRounding =
        totalDue -
        (paid +
          Number(onlinePayment) +
          Number(depositPayment) +
          paidFromWallet +
          appliedVoucherRedemptionsAmount);

      // had to do the following to avoid weird javascript rounding errors
      const checkoutBalance = Number(checkoutBalanceBeforeRounding.toFixed(3));

      return checkoutBalance;
    }
  };

  const balance = getBalance();

  const areCheckoutDetailsLoading =
    !appointmentId || appointmentId === Number(hasSetAppointmentCartArticles.current);

  // used to initialize new checkout articles saleTime
  const appointmentStartTime = appointment?.appointmentTimeRange?.startTime || "";

  const invoiceCardRef = useRef(null);

  const isRefundInvoice =
    invoice?.type === InvoiceType.Refund ||
    (!!refundInvoice &&
      (checkoutModalView === CheckoutModalView.Refund ||
        checkoutModalView === CheckoutModalView.ConfirmRefund));

  const originalInvoiceId = invoice?.originalInvoice || invoice?.id;
  const refundInvoiceId = isRefundInvoice ? invoice?.id : invoice?.refundInvoice?.id;

  return {
    ...methods,
    cartItemFields,
    appendCartItem,
    removeCartItem,

    // appointment or sale date
    date,

    totalDiscount,
    subtotal,
    balance,
    totalDue,
    paid,

    isRefundInvoice,
    refundTotal,

    originalInvoiceId,
    refundInvoiceId,

    onlinePayment,
    onlinePayments,

    couponOnlinePaymentTotal,
    peepTransactionTotal,

    walletPayments,

    hasPendingPayments,

    deposit,

    areCheckoutDetailsLoading,

    appointmentStartTime,

    invoiceCardRef,

    shouldShowInvoiceTabs,
  };
};

type UseCheckoutFormReturnType = ReturnType<typeof useCheckoutForm>;

const useCheckoutFormContext = () => {
  const methods = useFormContext() as UseCheckoutFormReturnType;

  return methods;
};

/**
 * History Lesson Time ⏳📜:
 *
 * useFieldArray cannot be used across multiple components, except by passing its methods and array across multiple components.
 *
 * To avoid passing them across several trees of components the are wrapped by the FormProvider,
 * the suggested solution was to pass UseFieldArray methods and array to the FormProvider and use them using the FormContext.
 * And since TypeScript is a boss, i had to type everything properly to make it work properly with TypeScript - Anfal
 *
 * Gigantic thanks to @theHasanas for the idea and the implementation of this solution.
 */

export { useCheckoutForm, useCheckoutFormContext };
