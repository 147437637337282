import AppointmentFormContext from "pages/CalendarPage/components/AppointmentForm/AppointmentFormContext";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { getAppointmentDetails } from "store/appointments/appointmentsSlice";
import { getClient, getClientAddresses, selectClientById } from "store/clients/clientsSlice";
import { selectAppointmentDrawer } from "store/selectors";
import type {
  OpenAppointmentDrawerCreateModeOptions,
  OpenAppointmentDrawerEditModeOptions,
  OpenAppointmentDrawerViewModeOptions,
} from "store/slices/appointmentDrawer/appointmentDrawerSlice";
import {
  initiateAppointmentDrawerCreateMode,
  initiateAppointmentDrawerEditMode,
  initiateAppointmentDrawerViewMode,
  terminateAppointmentDrawer,
  updateAppointmentDrawerClient,
  updateAppointmentDrawerOutlet,
  updateIsDrawerExpanded,
} from "store/slices/appointmentDrawer/appointmentDrawerSlice";
import { GetAppointmentDetailsResponse } from "types/Appointment";
import { ProcessedClient } from "types/Client";
import useNotify from "utils/notifications/useNotify";

const useAppointmentDrawer = () => {
  const { t } = useTranslation("errors");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotify();
  const { pathname } = useLocation();
  const { editAppointment } = useContext(AppointmentFormContext);

  const {
    outletId,
    isDrawerOpen,
    currentView,
    appointmentId,
    clientId,
    isDrawerExpanded,
    activeHomeServiceFormStep: homeServiceFormStep,
  } = useSelector(selectAppointmentDrawer);

  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  const [isEditAppointmentLoading, setIsEditAppointmentLoading] = useState(false);

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId ?? undefined));

  const appointmentClient = useSelector(selectClientById(clientId ?? 0)) as
    | ProcessedClient
    | undefined;

  const setAppointmentDrawerClient = useCallback(
    (clientId: Nullable<number>) => {
      dispatch(updateAppointmentDrawerClient(clientId));
    },
    [dispatch]
  );

  const setAppointmentDrawerOutlet = useCallback(
    (outletId: Nullable<number>) => {
      dispatch(updateAppointmentDrawerOutlet(outletId));
    },
    [dispatch]
  );

  const setIsAppointmentDrawerExpanded = useCallback(
    (isExpanded: boolean) => {
      dispatch(updateIsDrawerExpanded(isExpanded));
    },
    [dispatch]
  );

  const openAppointmentDrawerViewMode = useCallback(
    (openAppointmentDrawerViewModeOptions: OpenAppointmentDrawerViewModeOptions) => {
      dispatch(initiateAppointmentDrawerViewMode(openAppointmentDrawerViewModeOptions));

      setIsAppointmentLoading(true);
      //@ts-expect-error
      dispatch(getAppointmentDetails(openAppointmentDrawerViewModeOptions?.appointmentId))
        .unwrap()
        .then((response: GetAppointmentDetailsResponse) => {
          setAppointmentDrawerClient(response?.appointment?.client);
          setAppointmentDrawerOutlet(response?.appointment?.outlet);
        })
        .catch(() => {
          notify("error", t("errors:failedToLoad"));
        });

      // fetch client
      if (appointment?.client) {
        if (!appointmentClient?.appointmentsLink) {
          dispatch(getClient(appointment?.client));
        }
        dispatch(getClient(appointment.client));
        dispatch(getClientAddresses({ clientId: appointment.client }))
          .unwrap()
          .catch((error) => {
            notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error");
          });
      }

      setIsAppointmentLoading(false);
    },
    [
      appointment?.client,
      appointmentClient?.appointmentsLink,
      dispatch,
      notify,
      setAppointmentDrawerClient,
      setAppointmentDrawerOutlet,
      t,
    ]
  );

  const openAppointmentDrawerCreateMode = useCallback(
    (openAppointmentDrawerCreateModeOptions: OpenAppointmentDrawerCreateModeOptions) => {
      dispatch(initiateAppointmentDrawerCreateMode(openAppointmentDrawerCreateModeOptions));
    },
    [dispatch]
  );

  const openAppointmentDrawerCreateModeForClient = useCallback(
    (openAppointmentDrawerCreateModeOptions: OpenAppointmentDrawerCreateModeOptions) => {
      dispatch(initiateAppointmentDrawerCreateMode(openAppointmentDrawerCreateModeOptions));

      const { clientId } = openAppointmentDrawerCreateModeOptions;

      if (clientId) navigate(`/calendar/create-appointment/${clientId}`);
    },
    [dispatch, navigate]
  );

  const openAppointmentDrawerEditMode = useCallback(
    (openAppointmentDrawerEditModeOptions: OpenAppointmentDrawerEditModeOptions) => {
      dispatch(initiateAppointmentDrawerEditMode(openAppointmentDrawerEditModeOptions));
      setIsEditAppointmentLoading(true);
      // @ts-expect-error
      dispatch(getAppointmentDetails(openAppointmentDrawerEditModeOptions.appointmentId))
        .unwrap()
        .then((response: GetAppointmentDetailsResponse) => {
          setAppointmentDrawerClient(response?.appointment?.client);
          setAppointmentDrawerOutlet(response?.appointment?.outlet);
          editAppointment(response);
          // fetch client
          if (response?.appointment?.client) {
            if (!appointmentClient?.appointmentsLink) {
              dispatch(getClient(response?.appointment.client));
            }
            dispatch(getClientAddresses({ clientId: response?.appointment.client }))
              .unwrap()
              .catch((error) => {
                notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error");
              });
          }
        })
        .catch(() => {
          notify("error", t("errors:failedToLoad"));
        });
      setIsEditAppointmentLoading(false);

      navigate(`/calendar/edit-appointment/${appointmentId}`);
    },
    [
      appointmentClient?.appointmentsLink,
      appointmentId,
      dispatch,
      editAppointment,
      navigate,
      notify,
      setAppointmentDrawerClient,
      setAppointmentDrawerOutlet,
      t,
    ]
  );

  const closeAppointmentDrawer = useCallback(() => {
    // TODO: Test this logic & clear the logic related to removing create appointment path after creating an appointment it could be in a useEffect
    if (pathname.includes("create-appointment")) {
      navigate("/calendar");
    }

    dispatch(terminateAppointmentDrawer());
  }, [dispatch, navigate, pathname]);

  return {
    outletId,
    appointmentId,

    clientId,
    setAppointmentDrawerClient,

    isAppointmentDrawerExpanded: isDrawerExpanded,
    setIsAppointmentDrawerExpanded,

    appointmentDrawerView: currentView,
    isAppointmentDrawerOpen: isDrawerOpen,
    isAppointmentLoading,

    isEditAppointmentLoading,

    homeServiceFormStep,

    openAppointmentDrawerViewMode,
    openAppointmentDrawerCreateMode,
    openAppointmentDrawerCreateModeForClient,
    openAppointmentDrawerEditMode,
    closeAppointmentDrawer,
  };
};

export default useAppointmentDrawer;
