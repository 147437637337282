import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  createStockDown,
  createStockTransfer,
  createStockUp,
  getProductData,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";

export const productInvoicesAdapter = createEntityAdapter<ProductInvoices>({
  selectId: (invoice) => invoice.id,
});

const initialState = productInvoicesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const productInvoicesSlice = createSlice({
  name: "productInvoices",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getProductData.fulfilled, (state, { payload: { invoices } }) => {
      productInvoicesAdapter.setAll(state, invoices);
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { invoices } }) => {
      productInvoicesAdapter.upsertMany(state, invoices);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { invoices } }) => {
      productInvoicesAdapter.upsertMany(state, invoices);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { invoices } }) => {
      productInvoicesAdapter.upsertMany(state, invoices);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default productInvoicesSlice.reducer;
