import { Box, Skeleton, styled } from "@mui/material";

export default function NavHeaderSkeleton() {
  return (
    <StyledBox
      display="flex"
      width="100%"
      height="70px"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="divider"
      p={2}
    >
      <Box display="flex" columnGap={1}>
        <Skeleton height="1.125rem" width="150px" />
        <Skeleton height="1.125rem" width="150px" />
        <Skeleton height="1.125rem" width="150px" />
        <Skeleton height="1.125rem" width="150px" />
        <Skeleton height="1.125rem" width="150px" />
      </Box>

      <Skeleton variant="circular" height="40px" width="40px" />
    </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: theme.spacing(7),
}));
