import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, styled } from "@mui/material";
import { StickyBottomActions } from "assets/mixins/StickyBottomActions";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch as useOldDispatch } from "react-redux";
import { GenericDispatch, GenericThunk, useDispatch } from "store";
import { updateAppointmentLogs } from "store/appointments/appointmentsSlice";
import { getClient } from "store/clients/clientsSlice";
import { CreateDepositArguments } from "store/slices/deposits/depositsApi";
import { createDeposit } from "store/slices/deposits/depositsSlice";
import { Appointment } from "types/Appointment";
import useNotify from "utils/notifications/useNotify";

type FormActionButtonsProps = {
  onClose: () => void;
  clientId: Nullable<number>;
  appointment: Appointment;
};

const FormActionButtons = ({ onClose, clientId, appointment }: FormActionButtonsProps) => {
  const { t } = useTranslation("deposits");

  const notify = useNotify();

  const dispatch = useDispatch();

  const oldDispatch = useOldDispatch<GenericDispatch>();

  const { watch, handleSubmit } = useFormContext<CreateDepositArguments>();

  const { paymentType, wallet, amount, peepPay } = watch();

  const canSubmit = (paymentType || wallet || peepPay) && amount > 0;

  const handleConfirmDeposit: SubmitHandler<CreateDepositArguments> = (
    data: CreateDepositArguments
  ) => {
    dispatch(createDeposit({ data }))
      .unwrap()
      .then((response) => {
        if (clientId) {
          oldDispatch((getClient as GenericThunk)(clientId));
        }

        dispatch(
          updateAppointmentLogs({ logs: response.logs, deposit: response.deposit, appointment })
        );
        notify(t("translation:entitySuccessfullyCreated", { entity: t("deposit") }), "success");
        onClose();
      })
      .catch((error) => {
        notify(
          `${t("translation:entityFailedToBeCreated", { entity: t("deposit") })} ${
            error?.detail ?? ""
          }`,
          "error"
        );
      });
  };

  return (
    <ActionsWrapper p={3}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <Button fullWidth onClick={onClose} variant="outlined">
            {t("cancel")}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <LoadingButton
            fullWidth
            onClick={handleSubmit(handleConfirmDeposit)}
            disabled={!canSubmit}
          >
            {t("confirm")}
          </LoadingButton>
        </Grid>
      </Grid>
    </ActionsWrapper>
  );
};

export default FormActionButtons;

const ActionsWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.label}25`,
  [theme.breakpoints.down("md")]: {
    ...StickyBottomActions(),
  },
}));
