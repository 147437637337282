import { Typography } from "@mui/material";
import { DepositIcon } from "assets/icon";
import { ActionChip } from "components/common";
import getFormattedPrice from "helpers/getFormattedPrice";
import { Trans, useTranslation } from "react-i18next";

type OnlineBookingPaymentChipProps = {
  paymentTotal: number;
};

const OnlineBookingPaymentChip = ({ paymentTotal }: OnlineBookingPaymentChipProps) => {
  const { t } = useTranslation("deposits");

  return (
    <ActionChip
      icon={<DepositIcon fontSize="small" sx={{ mr: 1, fill: "#4048D6", stroke: "#4048D6" }} />}
      title={
        <Typography variant="secondary" fontWeight={700}>
          <Trans
            i18nKey="deposits:downPaymentAdded"
            values={{ amount: `${t("currency")} ${getFormattedPrice(paymentTotal)}` }}
            components={[
              <Typography
                key="amount-value"
                display="inline"
                fontWeight={700}
                color="primary"
                variant="secondary"
              />,
            ]}
          />
        </Typography>
      }
      hasAppliedAction
      shouldShowAction={false}
      gap={0}
    />
  );
};

export default OnlineBookingPaymentChip;
