import http from "utils/http";

type CreateAddOnPurchaseArguments = {
  addOnId: number;
};

export type AddOnPurchaseResponse = {
  payment: Nullable<{ status: string; paymentURL: string }>;
};

const addOnsApi = {
  getAllAddOns: async () => http.get<AddOn[]>("tiers/add_ons_list/"),

  createAddOnPurchase: async (data: CreateAddOnPurchaseArguments) =>
    http.post<AddOnPurchaseResponse>("/tiers/create_add_ons_invoice/", data),
};

export default addOnsApi;
