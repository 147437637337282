import { Skeleton, Stack, Typography } from "@mui/material";

const AppointmentDetailsAddressSectionSkeleton = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Stack>
        <Stack direction="row" alignItems="center" gap={0.25}>
          <Typography variant="primary" fontSize="0.875" fontWeight={700}>
            <Skeleton variant="text" width={100} />
          </Typography>
        </Stack>

        <Typography variant="secondary" color="text.label" fontWeight={500}>
          <Skeleton variant="text" width={120} />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AppointmentDetailsAddressSectionSkeleton;
