import { Box } from "@mui/material";
import AppNavMenu from "components/AppNavMenu";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import { Suspense } from "react";
import { Outlet as RouterOutlet } from "react-router-dom";
import useMustBeAuthenticated from "utils/useMustBeAuthenticated";

export default function NavigationMenuLayout() {
  useMustBeAuthenticated(true);

  return (
    <>
      <AppNavMenu />

      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 65px)"
        width="100%"
        flexGrow={1}
        overflow="hidden"
        position="absolute"
      >
        <Box component="main" flexGrow={1} overflow="scroll">
          <Suspense fallback={<CentredSpinnerBox />}>
            <RouterOutlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}
