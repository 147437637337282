import { MenuItem, Select, TextField, TextFieldProps, styled } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  borderRadius: theme.spacing(1),
}));

type PeepSelectFieldProps = TextFieldProps & {
  items: any;
  itemValue: string;
  itemText: string;
  helperText?: string;
  placeholder?: string;
  error?: boolean;
  fullWidth: boolean;
  action?: { (): void } | null;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  focused?: boolean;
  disabled?: boolean;
  label?: string;
  value?: any;
};

const PeepSelectField = ({
  items,
  itemValue = "id",
  itemText = "name",
  helperText,
  placeholder,
  error,
  fullWidth = false,
  action = null,
  focused = false,
  disabled = false,
  label,
  value,
  ...props
}: PeepSelectFieldProps) => {
  const menuOptions = items?.map((item: any) => (
    <MenuItem key={item[itemValue]} value={item[itemValue]}>
      {item[itemText]}
    </MenuItem>
  ));

  return (
    <TextField
      disabled={disabled}
      focused={focused}
      fullWidth={fullWidth}
      select
      label={label}
      id={`${label}-select`}
      {...props} // don't mess with this order it will show a line under the label
      value={value || 0} // don't mess with this, no values will cause a line under the label
      helperText={helperText}
      error={error && error}
    >
      {placeholder && (
        <MenuItem disabled value={0}>
          {placeholder}
        </MenuItem>
      )}
      {menuOptions}
      {action && action}
    </TextField>
  );
};

export default PeepSelectField;
