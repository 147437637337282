import { Box } from "@mui/material";
import { PeepSearchField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActivePurchasableSubscriptionsByOutletId,
  selectSubscriptionsLoading,
} from "store/selectors";
import { Subscription } from "types/Subscription";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import SubscriptionsPurchasableItems from "./SubscriptionsPurchasableItems";

const PurchasableItemsSubscriptionsPanel = () => {
  const { t } = useTranslation(["checkout"]);

  const [searchQuery, setSearchQuery] = useState("");

  const { outletId } = usePurchasableItemsSelector();

  const subscriptions = useSelector(selectActivePurchasableSubscriptionsByOutletId(outletId || 0));

  const areSubscriptionsLoading = useSelector(selectSubscriptionsLoading);

  const processedQuery = searchQuery.toLowerCase().trim();

  const filteredSubscriptions = subscriptions.filter(filterBySubscriptionName(processedQuery));

  if (areSubscriptionsLoading) return <CentredSpinnerBox />;

  if (!subscriptions.length)
    return <EmptyGraphicSection description={t("noSubscriptionsAvailableForTheCurrentLocation")} />;

  return (
    <Box p={3}>
      <Box pb={3}>
        <PeepSearchField
          placeholder={t("searchBySubscriptionNameOrItemName")}
          onClear={() => setSearchQuery("")}
          fullWidth
          value={searchQuery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>

      {filteredSubscriptions.length ? (
        <SubscriptionsPurchasableItems subscriptions={filteredSubscriptions} />
      ) : (
        <EmptyGraphicSection description={t("noSubscriptions")} />
      )}
    </Box>
  );
};

export default PurchasableItemsSubscriptionsPanel;

const filterBySubscriptionName = (searchQuery: string) => (subscription: Subscription) =>
  subscription.name.toLowerCase().includes(searchQuery) ||
  subscription.itemName.toLowerCase().includes(searchQuery);
