import { createEntityAdapter, createSlice, } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { AppointmentHome } from "types/Appointment";

import { SliceStatus } from "../utils";
import api from "./appointmentsOverviewApi";
import { updateAppointment } from "store/appointments/appointmentsSlice";

export const appointmentsOverviewAdapter = createEntityAdapter<AppointmentHome>({
  selectId: (appointment) => appointment.id,
});

const initialState = appointmentsOverviewAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  todaysCount: number;
  upcomingCount: number;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  todaysCount: 0,
  upcomingCount: 0,
});

export const getAllAppointmentsOverview = createThunkFromApiWithType(
  "appointments/getAppointmentsOverview",
  api.getAppointmentsOverview
);

export const appendAllAppointmentsOverview = createThunkFromApiWithType(
  "appointments/appendAppointmentsOverview",
  api.getAppointmentsOverview
);

export const appointmentsOverviewSlice = createSlice({
  name: "appointmentsOverview",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllAppointmentsOverview.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllAppointmentsOverview.fulfilled, (state, { payload }) => {
      appointmentsOverviewAdapter.setAll(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
      state.todaysCount = payload.todaysCount;
      state.upcomingCount = payload.upcomingCount;
    });

    reducers.addCase(getAllAppointmentsOverview.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllAppointmentsOverview.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllAppointmentsOverview.fulfilled, (state, { payload }) => {
      appointmentsOverviewAdapter.upsertMany(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendAllAppointmentsOverview.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(updateAppointment.fulfilled, (state, { payload: { appointment } }) => {


      const { id, ...changes } = appointment;
      appointmentsOverviewAdapter.updateOne(state, {
        id,
        changes,
      });
    });
  },

});

export default appointmentsOverviewSlice.reducer;
