import { Autocomplete, TextField } from "@mui/material";
import FilteredItem from "components/common/Chips/FilteredItem";
import { getEmployeeFullName } from "helpers/employees";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllEmployees } from "store/employees/employeesSlice";

const CreatedByFilter = ({ unconfirmedValue, setUnconfirmedValue }) => {
  const { t } = useTranslation();

  const allEmployees = useSelector(selectAllEmployees);

  const { entities: employees } = useSelector((state) => state.employees);

  const employeeIdsWithUsers = allEmployees
    .filter((employee) => employee.user)
    .map((employee) => employee.id);

  const addSelectedOptions = (_, employeeOptions) => {
    setUnconfirmedValue(employeeOptions);
  };

  return (
    <Autocomplete
      disableClearable={true}
      multiple
      options={employeeIdsWithUsers}
      getOptionLabel={(id) => getEmployeeFullName(employees[id])}
      value={unconfirmedValue}
      onChange={addSelectedOptions}
      renderInput={(params) => <TextField focused={true} label={t("createdBy")} {...params} />}
      renderTags={(selectedOptions, getTagProps) =>
        selectedOptions.map((option, index) => (
          <FilteredItem
            variant="outlined"
            color="primary"
            label={getEmployeeFullName(employees[option])}
            {...getTagProps({ index })}
            key={option}
          />
        ))
      }
    />
  );
};

export default CreatedByFilter;
