import http from "utils/http";

const packagesApi = {
  getAllPackages: async () => http.get("packages/packages/"),

  getPackage: async (id) => http.get(`packages/packages/${id}/`),

  createPackage: async ({ data }) => http.post("packages/packages/", data),

  updatePackage: async (id, data) => http.put(`packages/packages/${id}/`, data),

  updatePackagePosition: async (data) => {
    return http.put(`packages/update_package_positions/`, data);
  },

  removePackage: async (id) => {
    return http.delete(`packages/packages/${id}/`);
  },
};

export default packagesApi;
