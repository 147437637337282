import { Close } from "@bookpeep/ui";
import { Chip } from "@mui/material";
import { styled } from "@mui/system";

function FilteredItem({ label, onDelete, ...props }) {
  return (
    <StyledChip
      label={label}
      {...props}
      deleteIcon={<Close />}
      onDelete={onDelete}
    />
  );
}

export default FilteredItem;

const StyledChip = styled(Chip)(({ theme }) => ({
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px",
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  fontWeight: 500,
  borderLeft: `3px solid ${theme.palette.primary.main} `,
  "& .MuiChip-outlined": {
    marginLeft: 10,
  },
  "& .MuiChip-deleteIcon": {
    color: "#919AAB",
    height: "9px",
    width: "9px",
  },
  "& .MuiChip-label": {
    color: "#0A1F44",
    fontWeight: 500,
    fontSize: ".875rem",
  },
}));
