import { Box } from "@mui/material";
import { PeepSearchField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import {
  filterCategoryByServiceOrVariantName,
  filterOutEmptyCategories,
} from "helpers/servicesSearch";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectAllServiceCategoriesWithAllData,
  selectServiceCategoryLoading,
} from "store/slices/serviceCategoriesV2/serviceCategoriesSelectors";
import { PurchasableItemOption } from "types/PurchasableItem";
import { ServiceCategoryWithAllDetails } from "types/ServiceV2";
import { translateColor } from "utils/categoryColors";

import {
  OnSelectAllCategoryItemsFunction,
  usePurchasableItemsSelector,
} from "../../PurchasableItemsSelectorContext";
import PurchasableItemCategory from "../PurchasableItemCategory";
import ServicesPurchasableItems from "./ServicesPurchasableItems";

const PurchasableItemsServicesPanel = () => {
  const { t } = useTranslation(["checkout", "services"]);

  const [searchQuery, setSearchQuery] = useState("");

  const {
    outletId,
    shouldAllowSelectAllCategoryItems,
    selectedItems,
    onSelectAllCategoryItems,
    shouldDisableLoyaltyIneligibleItems,
  } = usePurchasableItemsSelector();

  const serviceCategories = useSelector(
    selectAllServiceCategoriesWithAllData(outletId)
  ) as ServiceCategoryWithAllDetails[];

  const areServiceCategoriesLoading = useSelector(selectServiceCategoryLoading);

  const processedQuery = searchQuery.toLowerCase().trim();

  const filteredCategories = serviceCategories
    .map(filterCategoryByServiceOrVariantName(processedQuery))
    .filter(filterOutEmptyCategories);

  if (areServiceCategoriesLoading) return <CentredSpinnerBox />;

  if (!serviceCategories.length)
    return <EmptyGraphicSection description={t("noServicesAvailableForTheCurrentLocation")} />;

  return (
    <Box p={3}>
      <Box pb={3}>
        <PeepSearchField
          placeholder={t("checkout:searchByServiceOrOptionName")}
          onClear={() => setSearchQuery("")}
          fullWidth
          value={searchQuery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>

      {filteredCategories.length ? (
        filteredCategories.map((serviceCategory) => {
          const totalServicesVariantsWithinCategoryCount = serviceCategory.services.reduce(
            (currentTotal, service) => {
              return currentTotal + service.variants.length;
            },
            0
          );

          const totalSelectedServiceVariantsWithinCategoryCount = serviceCategory.services.reduce(
            (currentTotal, service) => {
              const selectedServiceVariants = selectedItems?.[
                PurchasableItemOption.Service
              ]?.filter((selectedItem) =>
                service.variants.map(({ id }) => id).includes(selectedItem)
              );

              return currentTotal + (selectedServiceVariants?.length || 0);
            },
            0
          );

          const shouldDisableSelectAllCategoryItemsButton =
            shouldDisableLoyaltyIneligibleItems &&
            !serviceCategory.services.every((service) =>
              service.variants.every((variant) => !variant.isLoyaltyEligible)
            );

          return (
            <PurchasableItemCategory
              key={`${serviceCategory.id}-${serviceCategory.name}`}
              name={serviceCategory.name as string}
              color={translateColor(serviceCategory.color)}
              totalItemsDescription={`${serviceCategory.services.length} ${t("services:services")}`}
              items={<ServicesPurchasableItems services={serviceCategory.services} />}
              shouldAllowSelectAllCategoryItems={shouldAllowSelectAllCategoryItems}
              shouldDisableSelectAllCategoryItemsButton={shouldDisableSelectAllCategoryItemsButton}
              areAllItemsSelected={
                totalServicesVariantsWithinCategoryCount ===
                totalSelectedServiceVariantsWithinCategoryCount
              }
              handleClickSelectAllCategoryItems={
                shouldAllowSelectAllCategoryItems && onSelectAllCategoryItems
                  ? addAllCategoryItems(serviceCategory, onSelectAllCategoryItems)
                  : undefined
              }
            />
          );
        })
      ) : (
        <EmptyGraphicSection description={t("noServices")} />
      )}
    </Box>
  );
};

export default PurchasableItemsServicesPanel;

function addAllCategoryItems(
  serviceCategory: ServiceCategoryWithAllDetails,
  onSelectAllCategoryItems: OnSelectAllCategoryItemsFunction
): (() => void) | undefined {
  return () => {
    const categoryServiceVariants = serviceCategory.services.map((service) => {
      const { variants, ...rest } = service;

      return service.variants.map((variant) => {
        const serviceVariantWithService = { ...variant, service: { ...rest } };

        return serviceVariantWithService;
      });
    });

    onSelectAllCategoryItems(categoryServiceVariants.flat(), PurchasableItemOption.Service);
  };
}
