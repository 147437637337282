import { Box, TextField, Typography } from "@mui/material";
import useFormValidation from "hooks/useFormValidation";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const GiftForm = () => {
  const { t } = useTranslation("vouchers");

  const { register, formState } = useFormContext();

  const registerValidation = useFormValidation(formState, { ns: "vouchers" });

  return (
    <Box display="flex" flexDirection="column" width="90%" gap={3} mb={3}>
      <Typography variant="secondary" color="text.label">
        {t("emailVoucherMessage")}
      </Typography>

      <TextField
        fullWidth
        label={t("emailOptional")}
        {...register("email")}
        {...registerValidation("email")}
      />

      <TextField
        fullWidth
        label={t("messageOptional")}
        {...register("message")}
        {...registerValidation("message")}
      />
    </Box>
  );
};

export default GiftForm;
