import {
  Package,
  PackageVariant,
  PackageVariantWithServiceVariantPricingValue,
} from "types/Package";
import http from "utils/http";

type PackageVariantArguments = Omit<
  PackageVariantWithServiceVariantPricingValue,
  "package" | "id" | "deprecated"
> & {
  id?: number;
};

type PackageArguments = Partial<Omit<Package, "variants" | "id" | "arabicTranslation"> & {
  variants: Partial<PackageVariantArguments>[];
  translations: {
    ar: {
      name: string,
      description: string
    }
  }
}>;

export type PackageNormalizedResponse = {
  package: Package;
  variants: PackageVariant[];
};

export type PackageDetailsResponse = {
  package: Package;
  variants: PackageVariantWithServiceVariantPricingValue[];
};

const packagesApi = {
  getPackageDetails: async ({ id }: { id: number }) =>
    http.get<PackageDetailsResponse>(`packages/v2/packages/${id}/`),

  getAllPackages: async () => http.get<Package[]>(`packages/v2/packages/`),

  createPackage: async ({ data }: { data: PackageArguments }) =>
    http.post<PackageNormalizedResponse>("packages/v2/packages/", data),

  updatePackage: async ({ id, data }: { id: number; data: PackageArguments }) =>
    http.put<PackageNormalizedResponse>(`packages/v2/packages/${id}/`, data),

  updatePackagePosition: async ({ data }: { data: Package[] }) =>
    http.put<Package[]>(`packages/v2/update_package_positions/`, data),

  removePackage: async ({ id }: { id: number }) =>
    http.delete<PackageNormalizedResponse>(`packages/v2/packages/${id}/`),
};

export default packagesApi;
