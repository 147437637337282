import { Autocomplete, TextField } from "@mui/material";
import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentSource } from "types/Appointment";

import FilteredItem from "../Chips/FilteredItem";

type BookingSourceFilterProps = {
  unconfirmedValue: AppointmentSource[];
  setUnconfirmedValue: Dispatch<SetStateAction<AppointmentSource[]>>;
};

type BookingSourceFilterOption = {
  value: AppointmentSource;
  label: string;
};

function BookingSourceFilter({ unconfirmedValue, setUnconfirmedValue }: BookingSourceFilterProps) {
  const { t } = useTranslation();

  const options: BookingSourceFilterOption[] = Object.values(AppointmentSource).map((source) => ({
    value: source,
    label: t(source.toLocaleLowerCase()),
  }));

  const selectedBookingSources = options.filter((option) =>
    unconfirmedValue.includes(option.value)
  );

  const handleAddSelectedOptions = (
    _: SyntheticEvent<Element, Event>,
    newStatusOptions: BookingSourceFilterOption[]
  ) => setUnconfirmedValue(newStatusOptions.map((option) => option.value));

  return (
    <Autocomplete
      disableClearable
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedBookingSources}
      onChange={handleAddSelectedOptions}
      renderInput={(params) => <TextField focused={true} label={t("bookingSource")} {...params} />}
      renderTags={(selectedOptions, getTagProps) =>
        selectedOptions.map((option, index: number) => (
          <FilteredItem
            variant="outlined"
            color="primary"
            label={option.label}
            {...getTagProps({ index })}
            key={option.value}
          />
        ))
      }
    />
  );
}

export default BookingSourceFilter;
