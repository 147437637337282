import { Box, StepIconProps, styled } from "@mui/material";
import { Add, Checkout, DeleteIcon, Minus, VoucherIcon } from "assets/icon";

const IconFrame = (props: StepIconProps) => {
  const { className, icon } = props;

  const isDelete = icon === "delete";
  const isDeduct = icon === "deduct";
  const isAdd = icon === "add";
  const isCheckout = icon === "checkout";
  const isPurchase = icon === "purchase";

  return (
    <IconFrameRoot
      className={className}
      sx={{
        border:
          isDelete || isDeduct
            ? "1px solid #F1A79D"
            : isAdd || isPurchase
            ? "1px solid #9FA3EB"
            : isCheckout
            ? "1px solid #BAE5DF"
            : "",
      }}
    >
      {isDelete && (
        <DeleteIcon
          sx={{
            color: "#F43319",
          }}
        />
      )}

      {isDeduct && <Minus sx={{ color: "#F43319" }} />}

      {isAdd && (
        <Add
          sx={{
            color: "#4048D6",
          }}
        />
      )}

      {isCheckout && (
        <Checkout
          sx={{
            color: "#53BFB0",
          }}
        />
      )}

      {isPurchase && (
        <VoucherIcon
          sx={{
            color: "#4048D6",
            fontSize: "1.5rem",
          }}
        />
      )}
    </IconFrameRoot>
  );
};

const IconFrameRoot = styled(Box)(({ theme }) => ({
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: theme.spacing(4),
  width: theme.spacing(4),

  "& .MuiSvgIcon-root": {
    padding: theme.spacing(0.5),
  },
}));

export default IconFrame;
