import { Service, ServiceVariant } from "types/Service";

import getFormattedDuration from "./getFormattedDuration";

/**
 * This function returns the subtitle for a service based on the service name, service variant name and duration.
 *
 * @param serviceName
 * @param serviceVariantName
 * @param duration
 * @returns subtitle
 */

const getServiceSubtitle = (
  serviceName: Service["name"],
  serviceVariantName: ServiceVariant["name"],
  duration: ServiceVariant["duration"]
) => {
  const variantTitle = serviceName === serviceVariantName ? "" : `${serviceVariantName},`;
  const subtitle = `${variantTitle} ${getFormattedDuration(duration)}`;

  return subtitle;
};

export default getServiceSubtitle;
