import { Stack, Typography } from "@mui/material";
import { CircleIcon } from "assets/icon";
import useAddressLabel from "hooks/useAddressLabel";
import AppointmentFormSectionActionButton from "pages/CalendarPage/components/AppointmentForm/HomeServicesAppointmentForm/AppointmentFormSectionActionButton";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "store";
import { selectClientAddressByClientIdAndAddressId } from "store/clients/clientSelectors";
import { getClientAddresses } from "store/clients/clientsSlice";
import { selectAreaById } from "store/slices/areas/areasSelector";
import { selectZoneById } from "store/slices/zones/zonesSelectors";
import useNotify from "utils/notifications/useNotify";

import AppointmentDetailsAddressSectionSkeleton from "./AppointmentDetailsAddressSection.skeleton";

type AppointmentDetailsAddressSectionProps = {
  clientId: Nullable<number>;
  addressId: Nullable<number>;
  areaId: Nullable<number>;

  handleModifyClick?: () => void;
};

const AppointmentDetailsAddressSection = ({
  clientId,
  addressId,
  areaId,

  handleModifyClick,
}: AppointmentDetailsAddressSectionProps) => {
  const { t } = useTranslation(["appointments", "errors"]);

  const dispatch = useDispatch();
  const notify = useNotify();

  const hasFetchedClientAddressesRef = useRef<boolean>(false);

  const [isAddressLoading, setIsAddressLoading] = useState(false);

  const address = useSelector(
    selectClientAddressByClientIdAndAddressId(clientId || 0, addressId || 0)
  );

  const addressLabel = useAddressLabel(address || undefined);

  const area = useSelector(selectAreaById(areaId || address?.area || 0));

  const zone = useSelector(selectZoneById(area?.zone || 0));

  useEffect(() => {
    if (
      !address &&
      clientId &&
      addressId &&
      !hasFetchedClientAddressesRef.current &&
      !isAddressLoading
    ) {
      setIsAddressLoading(true);

      dispatch(getClientAddresses({ clientId }))
        .unwrap()
        .catch((error) => {
          notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error");
        })
        .finally(() => {
          setIsAddressLoading(false);
          hasFetchedClientAddressesRef.current = true;
        });
    }
  }, [address, addressId, clientId, dispatch, isAddressLoading, notify, t]);

  if (isAddressLoading) return <AppointmentDetailsAddressSectionSkeleton />;

  return (
    <Stack direction="row" alignItems="center">
      <Stack>
        <Stack direction="row" alignItems="center" gap={0.25}>
          <CircleIcon sx={{ color: zone?.color }} />

          <Typography variant="primary" fontSize="0.875" fontWeight={700}>
            {area?.name}

            <Typography variant="secondary" color="text.label" fontWeight={500} px={0.5}>
              {`${zone?.name}${zone?.deprecated ? " (Deleted)" : ""}` || "(Area Zone Unassigned)"}
            </Typography>
          </Typography>
        </Stack>

        <Typography variant="secondary" color="text.label" fontWeight={500}>
          {addressLabel}
        </Typography>
      </Stack>

      {!!handleModifyClick && (
        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          <AppointmentFormSectionActionButton onClick={handleModifyClick}>
            {t("appointments:modify")}
          </AppointmentFormSectionActionButton>
        </Stack>
      )}
    </Stack>
  );
};

export default AppointmentDetailsAddressSection;
