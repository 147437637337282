import { ButtonProps, Button as MuiButton, styled } from "@mui/material";
import { OnlineBookingCircleIcon, PartiallyOnlineIcon } from "assets/icon";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

import { OnlineBookingStatus } from "./types";

type ManageOnlineBookingStatusButtonProps = ButtonProps & {
  onlineBookingStatusForAllLocations: OnlineBookingStatus;
};

const ManageOnlineBookingStatusButton = ({
  onlineBookingStatusForAllLocations,
  ...props
}: ManageOnlineBookingStatusButtonProps) => {
  const { t } = useTranslation("onlineBooking");
  const isMobileView = useIsMobileView();

  const icon =
    onlineBookingStatusForAllLocations === OnlineBookingStatus.PartiallyActive ? (
      <PartiallyOnlineIcon viewBox="0 0 12 12" />
    ) : (
      <OnlineBookingCircleIcon
        viewBox="0 0 12 12"
        sx={{
          color:
            onlineBookingStatusForAllLocations === OnlineBookingStatus.Active
              ? "#53BFB0"
              : "#FFC166",
        }}
      />
    );

  return (
    <Button color="inherit" size="small" startIcon={icon} {...props}>
      {t(isMobileView ? "ob" : "onlineBooking")}
    </Button>
  );
};

export default ManageOnlineBookingStatusButton;

const Button = styled(MuiButton)(({ theme }) => ({
  color: "#3B4C69",
  backgroundColor: theme.palette.bg.blank,
  height: theme.spacing(3),
  minHeight: theme.spacing(3),
  maxHeight: theme.spacing(3),
  fontSize: ".75rem",
  padding: theme.spacing(0.5, 1),

  "& .MuiButton-startIcon > :nth-of-type(1)": {
    fontSize: ".75rem",
  },
}));
