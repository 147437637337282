import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";

type OriginalInvoiceTabSubtitleProps = {
  createdBy: string;
  createdAt: string;
};

const OriginalInvoiceTabSubtitle = ({ createdBy, createdAt }: OriginalInvoiceTabSubtitleProps) => (
  <Trans
    i18nKey={
      createdBy ? "invoices:fullPaymentReceived" : "invoices:fullPaymentReceivedWithoutByWhom"
    }
    values={{
      createdBy,
      createdAt: DateTime.fromISO(createdAt).toFormat("EEEE, dd MMMM yyyy hh:mm a"),
    }}
    components={[
      <Typography
        key="0"
        display="inline"
        variant="primary"
        fontSize="0.875rem"
        fontWeight={600}
        color="primary"
      />,
      <Typography
        key="1"
        display="inline"
        variant="primary"
        fontSize="0.875rem"
        fontWeight={600}
        color="text.primary"
      />,
    ]}
  />
);

export default OriginalInvoiceTabSubtitle;
