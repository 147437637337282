import { Autocomplete, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { ClientProfileIcon } from "assets/icon";
import FilteredItem from "components/common/Chips/FilteredItem";
import { SyntheticEvent } from "react";

type ClientSkinTypeFieldProps = {
  value: number[];
  setValue: (newValue: number[]) => void;
  options: ClientSkinTypeFieldOption[];

  title: string;
  icon?: React.ReactNode;

  isLoading?: boolean;
  isDisabled?: boolean;
};

type ClientSkinTypeFieldOption = {
  value: number;
  label: string;
};

function ClientProfileMultiselectField({
  title,
  value,
  setValue,
  options,
  isLoading,
  isDisabled,
  icon,
}: ClientSkinTypeFieldProps) {
  const selectedBookingSources = options.filter((option) => value.includes(option.value));

  const handleAddSelectedOptions = (
    _: SyntheticEvent<Element, Event>,
    newStatusOptions: ClientSkinTypeFieldOption[]
  ) => setValue(newStatusOptions.map((option) => option.value));

  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        {icon ?? (
          <ClientProfileIcon
            sx={{
              height: 16,
              width: 16,
              color: (theme) => theme.palette.primary.main,
            }}
          />
        )}

        <Typography variant="primary" fontWeight={500}>
          {title}
        </Typography>
      </Stack>

      {isLoading ? (
        <Skeleton variant="rectangular" height={48} />
      ) : (
        <Autocomplete
          disableClearable
          multiple
          options={options}
          getOptionLabel={(option) => option.label}
          disabled={isDisabled}
          value={selectedBookingSources}
          onChange={handleAddSelectedOptions}
          renderInput={(params) => <TextField focused={true} {...params} />}
          renderTags={(selectedOptions, getTagProps) =>
            selectedOptions.map((option, index: number) => (
              <FilteredItem
                variant="outlined"
                color="primary"
                label={option.label}
                {...getTagProps({ index })}
                key={option.value}
              />
            ))
          }
        />
      )}
    </Stack>
  );
}

export default ClientProfileMultiselectField;
