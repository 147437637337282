import { RootState } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";

import { selectServiceVariantByIdWithService } from "../servicesV2/servicesSelectors";
import { SliceStatus } from "../utils";
import { vouchersAdapter } from "./vouchersSlice";

export const selectVouchersLoading = (state: RootState) =>
  state.vouchers.status === SliceStatus.LOADING;

export const selectVoucherTotalPages = (state: RootState) => state.vouchers.totalPages;

export const selectVouchersState = (state: RootState) => state.vouchers;

const { selectById, selectAll } = vouchersAdapter.getSelectors(selectVouchersState);

export const selectAllVouchers = selectAll;

export const selectVoucherById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

/**
 * Vouchers with details are paginated, so page array consists of ids that are to be displayed in rendered page.
 * The page array is sorted based on user input, rather than backend order, so the vouchers are mapped like to this to match the order in which they should appear
 */

export const selectAllVouchersWithListDetail = (state: RootState) => {
  return state.vouchers.page.map((voucherId: number) => {
    const voucher = selectById(state, voucherId);
    return {
      ...voucher,
      serviceVariants:
        voucher?.serviceVariants.map((serviceVariantId: number) =>
          selectServiceVariantByIdWithService(serviceVariantId)(state)
        ) || [],
    };
  });
};

export const selectAllPurchasableVouchersByOutletId = (outletId: number) => (state: RootState) => {
  return selectAllVouchers(state).filter((voucher) => voucher.sellingOutlets.includes(outletId));
};

export const selectVoucherWithDetailsById = (id: number | string) => (state: RootState) => {
  const voucher = selectById(state, id);

  return {
    ...voucher,
    serviceVariants:
      voucher?.serviceVariants.map((serviceVariantId: number) =>
        selectServiceVariantByIdWithService(serviceVariantId)(state)
      ) || [],
    sellingOutlets: voucher?.sellingOutlets.map(
      (sellingOutletId: number) => selectOutletById(sellingOutletId)(state) || []
    ),
    redeemingOutlets: voucher?.redeemingOutlets.map(
      (redeemingOutletId: number) => selectOutletById(redeemingOutletId)(state) || []
    ),
  };
};

export const selectAllRedeemableVouchersByOutletId = (outletId: number) => (state: RootState) => {
  return selectAllVouchers(state).filter((voucher) => voucher.redeemingOutlets.includes(outletId));
};
