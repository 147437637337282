import { Typography } from "@mui/material";
import ConfirmationDialogModal from "components/common/ConfirmationDialogModal";
import getFormattedPrice from "helpers/getFormattedPrice";
import { Dispatch, SetStateAction } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch as useOldDispatch } from "react-redux";
import { GenericDispatch, GenericThunk, useDispatch, useSelector } from "store";
import { selectAppointmentById, updateAppointmentLogs } from "store/appointments/appointmentsSlice";
import { getClient } from "store/clients/clientsSlice";
import { selectDepositById } from "store/selectors";
import { DepositRemovalOptions } from "store/slices/deposits/depositsApi";
import { updateDeposit } from "store/slices/deposits/depositsSlice";
import useNotify from "utils/notifications/useNotify";

type RemoveDepositModalProps = {
  depositId: number;
  isRemoveDepositModalOpen: boolean;
  setIsRemoveDepositModalOpen: Dispatch<SetStateAction<boolean>>;
  clientId: Nullable<number>;
};

const RemoveDepositModal = ({
  depositId,
  isRemoveDepositModalOpen,
  setIsRemoveDepositModalOpen,
  clientId,
}: RemoveDepositModalProps) => {
  const { t } = useTranslation("deposits");

  const dispatch = useDispatch();
  const oldDispatch = useOldDispatch<GenericDispatch>();

  const notify = useNotify();

  const deposit = useSelector(selectDepositById(depositId));

  const appointment = useSelector(selectAppointmentById(deposit?.appointment));

  const deleteModalDescription = () => {
    return (
      <Typography fontWeight={400} variant="primary">
        <Trans
          i18nKey="deposits:confirmRemoveDeposit"
          values={{ amount: `${t("currency")} ${getFormattedPrice(deposit?.amount || 0)}` }}
          components={[
            <Typography key="confirm-remove-deposit" display="inline" fontWeight={700} />,
          ]}
        />
      </Typography>
    );
  };

  const handleDelete = (option: DepositRemovalOptions) => {
    dispatch(updateDeposit({ id: depositId, data: { option } }))
      .unwrap()
      .then((response) => {
        setIsRemoveDepositModalOpen(false);
        if (clientId) {
          oldDispatch((getClient as GenericThunk)(clientId));
        }
        dispatch(updateAppointmentLogs({ logs: response.logs, appointment }));
        notify(
          t("translation:entitySuccessfullyDeleted", { entity: t("deposit"), ns: "deposits" }),
          "success"
        );
      })
      .catch((error) => {
        notify(
          `${t("translation:entityFailedToBeDeleted", { entity: t("deposit"), ns: "deposits" })} ${
            error?.detail ?? ""
          }`,
          "error"
        );
      });
  };

  return (
    <ConfirmationDialogModal
      onClose={() => setIsRemoveDepositModalOpen(false)}
      isOpen={isRemoveDepositModalOpen}
      title={t("removeDeposit")}
      description={deleteModalDescription()}
      cancelButtonVariant="contained"
      cancelButtonLabel={t("addToCredits")}
      confirmButtonLabel={t("remove")}
      onCancelButtonClick={() => handleDelete(DepositRemovalOptions.REFUND)}
      onConfirmButtonClick={() => handleDelete(DepositRemovalOptions.NEGLECT)}
    />
  );
};

export default RemoveDepositModal;
