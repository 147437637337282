import { Duration } from "luxon";

/**
 * Format the duration to 1h 30min format
 *
 * @param {number} durationInMinutes
 *
 * @returns {string} formatted duration
 */

export const getFormattedDuration = (durationInMinutes: number): string => {
  let format = "h'h' m'm'";

  const isMoreThanZero = durationInMinutes > 0;

  if (isMoreThanZero) {
    const isLessThanAnHour = durationInMinutes < 60;
    const hasNoRemainingMinutes = durationInMinutes % 60 === 0;

    if (isLessThanAnHour) format = "m'm'";
    else if (hasNoRemainingMinutes && isMoreThanZero) format = "h'h'";
  }

  const formattedDuration = Duration.fromObject({
    minutes: durationInMinutes,
  }).toFormat(format);

  return formattedDuration;
};

export default getFormattedDuration;
