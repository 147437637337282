import { Grid, Typography } from "@mui/material";
import { VoucherIcon } from "assets/icon";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveVouchersByClientId,
  selectActiveVouchersByClientIdAndOutletId,
} from "store/clients/clientSelectors";

import ClientTag from "./ClientTag";

type ClientSubscriptionTagsProps = {
  clientId: number;
  outletId?: number;
};

/**
 * returns Voucher tags filtered by outletId if provided otherwise all active vouchers
 */

const ClientVoucherTags = ({ clientId, outletId }: ClientSubscriptionTagsProps) => {
  const { t } = useTranslation("subscriptions");

  const clientActiveVouchers = useSelector(
    outletId
      ? selectActiveVouchersByClientIdAndOutletId(clientId ?? 0, outletId ?? 0)
      : selectActiveVouchersByClientId(clientId ?? 0)
  );

  return (
    <>
      {clientActiveVouchers.map((voucher) => (
        // don't mess with the grid item unless you know what you're doing - Anfal
        <Grid item flex={1} md xs={12} key={`ClientVoucherTag-${voucher.id}`}>
          <ClientTag
            bgColor="#F6E3FF"
            label={
              <>
                <VoucherIcon
                  sx={{
                    fontSize: "0.875rem",
                    color: "primary.main",
                  }}
                />

                <Typography variant="secondary" fontWeight={500} color="primary" noWrap>
                  {voucher.name}
                </Typography>

                <Typography variant="secondary" fontWeight={500} color="text.label">
                  {`${t("currency")}\u00A0${getFormattedPrice(voucher.availableBalance)}\u00A0${t(
                    "left"
                  )}`}
                </Typography>
              </>
            }
          />
        </Grid>
      ))}
    </>
  );
};

export default ClientVoucherTags;
