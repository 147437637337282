import ConfirmationDialogModal, {
  ConfirmationModalSeverity,
} from "components/common/ConfirmationDialogModal";
import { useTranslation } from "react-i18next";

type RestartAppointmentConfirmationModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  description: string;
  handleConfirmClick: () => void;
};

const RestartAppointmentConfirmationModal = ({
  isModalOpen,
  closeModal,
  description,
  handleConfirmClick,
}: RestartAppointmentConfirmationModalProps) => {
  const { t } = useTranslation("appointments");

  const restartAppointment = () => {
    handleConfirmClick();
    closeModal();
  };

  return (
    <ConfirmationDialogModal
      title={t("restartAppointmentTitle")}
      description={description}
      confirmButtonLabel={t("restartAppointment")}
      confirmButtonSeverity={ConfirmationModalSeverity.Critical}
      onConfirmButtonClick={restartAppointment}
      isOpen={isModalOpen}
      onClose={closeModal}
    />
  );
};

export default RestartAppointmentConfirmationModal;
