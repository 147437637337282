import {
  Box,
  BoxProps,
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
  styled,
} from "@mui/material";
import { dontForwardProps } from "helpers/styles";

type FormSelectItemProps = {
  value: boolean;
  onChange: () => void;
  label: string;
  isSingleSelect?: boolean;
  isDisabled?: boolean;
};

function FormSelectItem({
  value,
  onChange,
  label,
  isSingleSelect,
  isDisabled,
}: FormSelectItemProps) {
  return (
    <FormSelectItemBox
      width="100%"
      isDisabled={!!isDisabled}
      flex={{ md: "1 0 45%", xs: "1 0 100%" }}
      onClick={(event) => {
        if (isDisabled) return;

        event.stopPropagation();
        onChange();
      }}
    >
      <FormControlLabel
        sx={{ width: "100%" }}
        control={
          isSingleSelect ? (
            <Radio checked={value} disabled={isDisabled} />
          ) : (
            <Checkbox checked={value} disabled={isDisabled} />
          )
        }
        label={
          <Typography
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            variant="primary"
            fontWeight={500}
            onClick={() => {
              if (isDisabled) return;

              onChange();
            }}
            component={Box}
          >
            {label}
          </Typography>
        }
      />
    </FormSelectItemBox>
  );
}

export default FormSelectItem;

type FormSelectItemBoxProps = BoxProps & {
  isDisabled: boolean;
};

const FormSelectItemBox = styled(
  Box,
  dontForwardProps("isDisabled")
)<FormSelectItemBoxProps>(({ theme, isDisabled }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.bg.secondary,
  cursor: isDisabled ? "default" : "pointer",

  // Disable text selection
  MozUserSelect: "none" /* firefox */,
  WebkitUserSelect: "none" /* Safari */,
  msUserSelect: "none" /* IE*/,
  userSelect: "none" /* Standard syntax */,
}));
