import { Box, Button, Divider, Stack } from "@mui/material";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { sortByDateDescending } from "helpers/sort";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById, selectIsClientLoading } from "store/clients/clientsSlice";
import { Client, GetClientAppointmentsHistoryResponse } from "types/Client";
import http from "utils/http";
import useNotify from "utils/notifications/useNotify";

import AppointmentsHistoryItem from "./AppointmentsHistoryItem";
import AppointmentsHistoryItemSkeleton from "./AppointmentsHistoryItem.skeleton";

type AppointmentsHistoryTabProps = {
  clientId: number;
};

const AppointmentsHistoryTab = ({ clientId }: AppointmentsHistoryTabProps) => {
  const notify = useNotify();
  const { t } = useTranslation(["translation", "appointments", "errors"]);

  const [appointmentsData, setAppointmentsData] = useState<GetClientAppointmentsHistoryResponse>();

  const client = useSelector(selectClientById(clientId)) as Client;
  const isClientLoading = useSelector(selectIsClientLoading) as boolean;

  const { appointmentsLink } = client;

  const hasFetchAppointmentsRef = useRef(false);

  useEffect(() => {
    if (appointmentsLink && !hasFetchAppointmentsRef.current) {
      http
        .get<GetClientAppointmentsHistoryResponse>(appointmentsLink)
        .then((response) => setAppointmentsData(response.data))
        .catch((error) => notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error"));
      hasFetchAppointmentsRef.current = true;
    }
  }, [appointmentsLink, notify, t]);

  const handleLoadMore = () => {
    if (appointmentsData?.next) {
      http
        .get<GetClientAppointmentsHistoryResponse>(appointmentsData.next)
        .then(({ data: { results, next } }) =>
          setAppointmentsData({
            ...appointmentsData,
            next,
            results: [...appointmentsData.results, ...results],
          })
        )
        .catch((error) => notify(`${t("errors:failedToLoad")}  ${error?.detail ?? ""}`, "error"));
    }
  };

  if (isClientLoading || !appointmentsData || !appointmentsLink)
    return (
      <Stack gap={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <AppointmentsHistoryItemSkeleton key={index} />
        ))}
      </Stack>
    );

  const appointmentsWithStartTime = appointmentsData.results?.map((appointment) => ({
    ...appointment,
    startTime: appointment?.articles?.[0]?.slots?.[0]?.start,
  }));

  const appointmentItems = sortByDateDescending(appointmentsWithStartTime, "startTime").map(
    (appointment) => <AppointmentsHistoryItem key={appointment.id} appointment={appointment} />
  );

  const hasAppointments = !!appointmentsData.results?.length;

  if (!hasAppointments)
    return <EmptyGraphicSection description={t("appointments:noAppointments")} />;

  return (
    <Stack gap={3} width="100%" flexGrow={1} py={3}>
      <Stack gap={3} divider={<Divider />} width="100%">
        {appointmentItems}
      </Stack>

      {appointmentsData.next && (
        <Box px={3}>
          <Button fullWidth variant="outlined" onClick={handleLoadMore}>
            {t("loadMore")}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default AppointmentsHistoryTab;
