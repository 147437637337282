import { Stack } from "@mui/material";
import { SectionTitle } from "components/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById } from "store/clients/clientsSlice";
import {
  selectAllClientHairTypes,
  selectClientHairTypesLoading,
} from "store/slices/clientHairTypes/clientHairTypesSelectors";
import {
  selectAllClientScalpTypes,
  selectClientScalpTypesLoading,
} from "store/slices/clientScalpTypes/clientScalpTypesSelectors";
import {
  selectAllClientSkinTypes,
  selectClientSkinTypesLoading,
} from "store/slices/clientSkinTypes/clientSkinTypesSelectors";
import { Client } from "types/Client";

import ClientProfileMultiselectField from "./ClientProfileMultiselectField";

type ClientSkinAndHairProfileSectionProps = {
  clientId: number;
  isDisabled?: boolean;
  handleChange: (fieldName: keyof Client) => (newValue: number[]) => void;
};

const ClientSkinAndHairProfileSection = ({
  clientId,
  isDisabled,
  handleChange,
}: ClientSkinAndHairProfileSectionProps) => {
  const { t } = useTranslation("client");

  const client = useSelector(selectClientById(clientId)) as Client;

  const skinTypes = useSelector(selectAllClientSkinTypes);
  const hairTypes = useSelector(selectAllClientHairTypes);
  const scalpTypes = useSelector(selectAllClientScalpTypes);

  const areSkinTypesLoading = useSelector(selectClientSkinTypesLoading);
  const areHairTypesLoading = useSelector(selectClientHairTypesLoading);
  const areScalpTypesLoading = useSelector(selectClientScalpTypesLoading);

  const skinTypeOptions = skinTypes.map((skinType) => ({
    value: skinType.id,
    label: skinType.name,
  }));

  const hairTypeOptions = hairTypes.map((hairType) => ({
    value: hairType.id,
    label: hairType.name,
  }));

  const scalpTypeOptions = scalpTypes.map((scalpType) => ({
    value: scalpType.id,
    label: scalpType.name,
  }));

  return (
    <Stack gap={2}>
      <SectionTitle>{t("profile")}</SectionTitle>

      <ClientProfileMultiselectField
        title={t("skinType")}
        value={client?.skinTypes}
        setValue={handleChange("skinTypes")}
        options={skinTypeOptions}
        isLoading={areSkinTypesLoading}
        isDisabled={isDisabled}
      />

      <ClientProfileMultiselectField
        title={t("hairType")}
        value={client?.hairTypes}
        setValue={handleChange("hairTypes")}
        options={hairTypeOptions}
        isLoading={areHairTypesLoading}
        isDisabled={isDisabled}
      />

      <ClientProfileMultiselectField
        title={t("scalpType")}
        value={client?.scalpTypes}
        setValue={handleChange("scalpTypes")}
        options={scalpTypeOptions}
        isLoading={areScalpTypesLoading}
        isDisabled={isDisabled}
      />
    </Stack>
  );
};

export default ClientSkinAndHairProfileSection;
