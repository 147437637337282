import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { createClient } from "store/clients/clientsSlice";
import {
  AppointmentDrawerMode,
  HomeServicesAppointmentStep,
} from "store/slices/appointmentDrawer/appointmentDrawerSlice";
import { Client } from "types/Client";
import useNotify from "utils/notifications/useNotify";

import AppointmentFormClientSection from "../AppointmentFormClientSection";
import AppointmentFormSummary from "../AppointmentFormSummary";
import {
  AppointmentFormValues,
  createAppointmentFormDefaultValues,
  createNewFormArticle,
} from "../utils";
import HomeServiceAppointmentFormAreaSection from "./HomeServiceAppointmentFormAreaSection";
import HomeServicesAppointmentFormAddressSection from "./HomeServicesAppointmentFormAddressSection";
import HomeServicesAppointmentFormServicesSection from "./HomeServicesAppointmentFormServicesSection";
import RestartAppointmentConfirmationModal from "./RestartAppointmentConfirmationModal";

type HomeServicesAppointmentFormProps = {
  isLoading: boolean;
  onClose: () => void;
  existingClientActionButton: React.ReactNode;
};

const HomeServicesAppointmentForm = ({
  isLoading,
  onClose,
  existingClientActionButton,
}: HomeServicesAppointmentFormProps) => {
  const { t } = useTranslation(["appointments", "translation"]);
  const dispatch = useDispatch();
  const notify = useNotify();

  const [confirmRestartAppointmentForStep, setConfirmRestartAppointmentForStep] = useState<
    HomeServicesAppointmentStep.Client | HomeServicesAppointmentStep.Area | null
  >(null);

  const { setAppointmentDrawerClient, homeServiceFormStep, appointmentDrawerView } =
    useAppointmentDrawer();
  const [isCreateClientRequestLoading, setIsCreateClientRequestLoading] = useState(false);

  const { watch, setValue, reset, trigger } = useFormContext<AppointmentFormValues>();

  const initialActiveStep = homeServiceFormStep || HomeServicesAppointmentStep.Client;

  const { client, area, articles, address } = watch();
  const [activeStep, setActiveStep] = useState<HomeServicesAppointmentStep>(
    homeServiceFormStep || initialActiveStep
  );

  const getNextStep = () => {
    switch (activeStep) {
      case HomeServicesAppointmentStep.Client:
        return HomeServicesAppointmentStep.Area;

      case HomeServicesAppointmentStep.Area:
        return HomeServicesAppointmentStep.Services;

      case HomeServicesAppointmentStep.Services:
        return HomeServicesAppointmentStep.Address;

      case HomeServicesAppointmentStep.Address:
        return HomeServicesAppointmentStep.Client;
    }
  };

  const isInEditMode = appointmentDrawerView === AppointmentDrawerMode.EditAppointment;

  const handleActivateNextStep = () => {
    if (activeStep === HomeServicesAppointmentStep.Client && client?.isNewClient) {
      setIsCreateClientRequestLoading(true);
      // Make a request to create a new client
      dispatch(
        createClient({
          data: {
            firstName: client.firstName,
            lastName: client.lastName,
            phoneNumber: client.phoneNumber,
            phoneCountryCode: client.phoneCountryCode,
          },
        })
      )
        .unwrap()
        .then((client: Client) => {
          setValue("client", {
            id: client.id,
            firstName: client.firstName,
            lastName: client.lastName,
            phoneNumber: client.phoneNumber,
            phoneCountryCode: client.phoneCountryCode,
            isNewClient: false,
          });

          notify(
            t("translation:entitySuccessfullyCreated", {
              entity: t("client"),
            }),
            "success"
          );
          setIsCreateClientRequestLoading(false);
          setActiveStep(getNextStep());
        })
        .catch((error) => {
          notify(
            `${t("translation:entityFailedToBeCreated", {
              entity: t("client"),
            })} ${error?.detail ?? ""}`,
            "error"
          );
          setIsCreateClientRequestLoading(false);
        });
    }
    if (activeStep === HomeServicesAppointmentStep.Services) {
      trigger("articles").then((isValid) => {
        if (isValid) {
          setActiveStep(getNextStep());
        }
      });
    } else {
      setActiveStep(getNextStep());
    }
  };

  const handleModifyStepClick = (step: HomeServicesAppointmentStep) => {
    switch (step) {
      case HomeServicesAppointmentStep.Client:
        if (activeStep !== HomeServicesAppointmentStep.Client) {
          setConfirmRestartAppointmentForStep(HomeServicesAppointmentStep.Client);
        }
        break;

      case HomeServicesAppointmentStep.Area:
        setConfirmRestartAppointmentForStep(HomeServicesAppointmentStep.Area);
        break;

      case HomeServicesAppointmentStep.Services:
        setActiveStep(HomeServicesAppointmentStep.Services);
        break;

      case HomeServicesAppointmentStep.Address:
        setActiveStep(HomeServicesAppointmentStep.Address);
        break;
    }
  };
  const hasSpecifiedClient = !!client.id || client.phoneNumber || client.firstName;

  const hasSelectedSomeServices =
    !!articles.length &&
    articles.some((article) => article.serviceVariantId || article.packageVariantId);

  // or Save button in case of editing an appointment
  const shouldShowSubmitButton =
    activeStep === HomeServicesAppointmentStep.Address ||
    (isInEditMode && hasSelectedSomeServices && !!area && !!address && !!hasSpecifiedClient);

  const checkIfNextButtonShouldBeDisabled = () => {
    switch (activeStep) {
      case HomeServicesAppointmentStep.Client:
        return !hasSpecifiedClient;

      case HomeServicesAppointmentStep.Area:
        return !area;

      case HomeServicesAppointmentStep.Services:
        return !hasSelectedSomeServices;

      case HomeServicesAppointmentStep.Address:
        return !address;
    }
  };

  const isConfirmRestartAppointmentModalOpen = !!confirmRestartAppointmentForStep;

  const closeConfirmRestartAppointmentModal = () => setConfirmRestartAppointmentForStep(null);

  const getRestartAppointmentConfirmationModalSettings = () => {
    switch (confirmRestartAppointmentForStep) {
      case HomeServicesAppointmentStep.Client:
        return {
          action: () => {
            handleModifyStepClick(HomeServicesAppointmentStep.Client);
            setAppointmentDrawerClient(null);
            reset(createAppointmentFormDefaultValues());
            setActiveStep(HomeServicesAppointmentStep.Client);
          },
          description: t("appointments:restartClientStepDescription"),
        };

      case HomeServicesAppointmentStep.Area:
        return {
          action: () => {
            // reset appointment articles to default article
            setValue("articles", [createNewFormArticle()]);

            setActiveStep(HomeServicesAppointmentStep.Area);
          },
          description: t("appointments:restartAreaStepDescription"),
        };

      default:
        return {
          action: () => {},
          description: "",
        };
    }
  };

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: "100%",
        height: "100%",
      }}
    >
      {isLoading ? (
        <CentredSpinnerBox />
      ) : (
        <>
          <RestartAppointmentConfirmationModal
            isModalOpen={isConfirmRestartAppointmentModalOpen}
            closeModal={closeConfirmRestartAppointmentModal}
            description={getRestartAppointmentConfirmationModalSettings().description}
            handleConfirmClick={getRestartAppointmentConfirmationModalSettings().action}
          />

          <AppointmentFormClientSection
            existingClientActionButton={existingClientActionButton}
            onChooseExistingClient={() => setActiveStep(HomeServicesAppointmentStep.Area)}
            handleResetClient={() => {
              handleModifyStepClick(HomeServicesAppointmentStep.Client);
            }}
            clientSectionProps={{
              allowedToChangeClient: isInEditMode,
            }}
          />

          <Box
            overflow="scroll"
            width="100%"
            flexGrow={1}
            sx={{
              // add border to the bottom of each section
              "& > *": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            <HomeServiceAppointmentFormAreaSection
              isDisabled={activeStep !== HomeServicesAppointmentStep.Area}
              isExpanded={activeStep === HomeServicesAppointmentStep.Area}
              handleModifyStepClick={() => handleModifyStepClick(HomeServicesAppointmentStep.Area)}
            />

            <HomeServicesAppointmentFormServicesSection
              isDisabled={activeStep !== HomeServicesAppointmentStep.Services}
              isExpanded={activeStep === HomeServicesAppointmentStep.Services}
              handleModifyStepClick={() =>
                handleModifyStepClick(HomeServicesAppointmentStep.Services)
              }
            />

            <HomeServicesAppointmentFormAddressSection
              isDisabled={activeStep !== HomeServicesAppointmentStep.Address}
              isExpanded={activeStep === HomeServicesAppointmentStep.Address}
              handleModifyStepClick={() =>
                handleModifyStepClick(HomeServicesAppointmentStep.Address)
              }
            />
          </Box>

          <Box p={3} boxShadow="topDivider">
            <AppointmentFormSummary
              onClose={onClose}
              shouldShowSubmitButton={shouldShowSubmitButton}
              shouldShowAppointmentSummary={shouldShowSubmitButton}
              actionButton={
                <LoadingButton
                  onClick={handleActivateNextStep}
                  disabled={checkIfNextButtonShouldBeDisabled()}
                  fullWidth
                  loading={isCreateClientRequestLoading}
                >
                  {`${t("next")}: ${t(getNextStep().toLowerCase())}`}
                </LoadingButton>
              }
            />
          </Box>
        </>
      )}
    </form>
  );
};

export { HomeServicesAppointmentForm };
