import { ChevronLeft, ChevronRight } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";

type InvoiceVouchersScrollArrowsProps = {
  shouldDisableLeftArrow: boolean;
  shouldDisableRightArrow: boolean;
  currentVoucherPurchaseIndex: number;
  setCurrentVoucherPurchaseIndex: (index: number) => void;
  voucherPurchasesLength: number;
};

const InvoiceVouchersScrollArrows = ({
  shouldDisableLeftArrow,
  shouldDisableRightArrow,
  currentVoucherPurchaseIndex,
  setCurrentVoucherPurchaseIndex,
  voucherPurchasesLength,
}: InvoiceVouchersScrollArrowsProps) => {
  const decrementCurrentVoucherPurchaseIndex = () => {
    setCurrentVoucherPurchaseIndex(currentVoucherPurchaseIndex - 1);
  };

  const incrementCurrentVoucherPurchaseIndex = () => {
    setCurrentVoucherPurchaseIndex(currentVoucherPurchaseIndex + 1);
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={shouldDisableLeftArrow}
        onClick={decrementCurrentVoucherPurchaseIndex}
        color="primary"
      >
        <ChevronLeft />
      </IconButton>

      <Typography variant="primary" fontWeight={500} color="text.label">
        <Typography variant="primary" fontWeight={500} color="primary" display="inline">
          {currentVoucherPurchaseIndex + 1}
        </Typography>
        {` / ${voucherPurchasesLength}`}
      </Typography>

      <IconButton
        disabled={shouldDisableRightArrow}
        onClick={incrementCurrentVoucherPurchaseIndex}
        color="primary"
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default InvoiceVouchersScrollArrows;
