import { getImageSrc } from "helpers/getImageSrc";
import { DateTime } from "luxon";
import http, { prepareResponseTransformers } from "utils/http";

import type {
  BillingPeriod,
  OrganizationTierData,
  TierInvoice,
  TierInvoicePaginatedResponse,
} from "./PeepSubscriptionsTypes";

type ResponseOrganizationTier = {
  id: number;
  billingPeriod: BillingPeriod;
  activationDate: string;
  tierEndDate: string;
  addOnsEndDate: string;
  createdAt: string;
  updatedAt: string;
  organization: number;
  nextCycleTier: number;
  activeTier: number;
  nextCycleAddOns: number[];
  activeAddOns: number[];
};

type ResponseTier = {
  id: number;
  name: string;
  description: string;
  icon: string;
  pricePerMonth: string;
  pricePerYear: string;
  smsCredit: number;
  createdAt: string;
  updatedAt: string;
};

type ResponseAddOn = {
  id: number;
  name: string;
  pricePerMonth: string;
  createdAt: string;
  updatedAt: string;
};

type OrganizationTierDataResponse = {
  organizationTier: ResponseOrganizationTier;
  tiers: ResponseTier[];
  addOns: ResponseAddOn[];
};

type PeepSubscriptionPayment = {
  payment: {
    status: string;
    paymentURL: string;
  };
};

export const peepSubscriptionsApi = {
  getOrganizationAndTierDetails: async () =>
    http.get<OrganizationTierData>("tiers/organization_tier_data/", {
      transformResponse: prepareResponseTransformers(
        ({ addOns, tiers, organizationTier }: OrganizationTierDataResponse) => ({
          addOns: prepareAddOns(addOns),
          tiers: prepareTiers(tiers),
          organizationTier: prepareOrganizationTier(organizationTier),
        })
      ),
    }),

  getInvoiceList: async () =>
    http.get<TierInvoicePaginatedResponse>("tiers/invoices/", {
      transformResponse: prepareResponseTransformers(),
    }),

  getInvoicePaymentUrl: async (invoiceId: number) =>
    http.get<PeepSubscriptionPayment>(
      `tiers/get_payment_link_for_pending_invoice/?tier_invoice_id=${invoiceId}`
    ),
  getSubscriptionActiveTierInvoices: async () =>
    http.get<TierInvoice[]>("tiers/subscription_active_tier_invoices/"),

};

export default peepSubscriptionsApi;

const prepareAddOns = (addOns: ResponseAddOn[]) =>
  addOns.reduce(
    (fullMap, { id, name, pricePerMonth }) => ({
      ...fullMap,
      [id]: { id, name, pricePerMonth: Number(pricePerMonth) },
    }),
    {}
  );

const prepareTiers = (tiers: ResponseTier[]) =>
  tiers.reduce(
    (fullMap, { name, description, icon, id, smsCredit, pricePerMonth, pricePerYear }) => ({
      ...fullMap,
      [id]: {
        id,
        name,
        description,
        icon: getImageSrc(icon),
        smsCredit,
        pricePerMonth: Number(pricePerMonth),
        pricePerYear: Number(pricePerYear),
      },
    }),
    {}
  );

const prepareOrganizationTier = ({
  tierEndDate,
  addOnsEndDate,
  activationDate,

  createdAt,
  updatedAt,

  ...rest
}: ResponseOrganizationTier) => ({
  tierEndDate: DateTime.fromISO(tierEndDate),
  addOnsEndDate: DateTime.fromISO(addOnsEndDate),
  activationDate: DateTime.fromISO(activationDate),
  ...rest,
});
