import { PackageVariantWithServiceVariantsAndPriceAndDuration } from "./Package";
import { ServiceVariantWithService } from "./ServiceV2";
import { Subscription } from "./Subscription";
import { Voucher } from "./Voucher";

export enum PurchasableItemOption {
  Service = "service",
  Product = "product",
  Package = "package",
  Subscription = "subscription",
  Voucher = "voucher",
}

export type PurchasableItem =
  | ServiceVariantWithService
  | PackageVariantWithServiceVariantsAndPriceAndDuration
  | ProductWithBrand
  | Subscription
  | Voucher;
