export const categoryColors = [
  "#B3B6EF",
  "#A6DED6",
  "#E9A9CE",
  "#FFEA80",
  "#FFCB80",
  "#F6BEBB",
  "#D7CAB9",
  "#DAA1F3",
  "#AB96D5",
  "#BED982",
  "#A0E078",
  "#B6D0F5",
];

export const zoneColors = [
  "#FBEEF5",
  "#F7EBFC",
  "#E9F7F5",
  "#EEF8E7",
  "#FFFBE5",
  "#FFF4E3",
  "#FCF0F0",
  "#EEEAF7",
  "#F7FDEA",
  "#EAEBFA",
  "#F0F6FD",
  "#F6F3EF",
];

export const getRandomCategoryColor = () =>
  categoryColors[Math.floor(Math.random() * categoryColors.length)];

export const translateColor = (color: string | undefined) => {
  switch (color?.toLowerCase()) {
    case "#4048d6".toLowerCase():
      return "#B3B6EF";
    case "#A2E1ED".toLowerCase():
      return "#A6DED6";
    case "#EDA2D8".toLowerCase():
      return "#E9A9CE";
    case "#f5c271".toLowerCase():
      return "#FFEA80";
    case "#ff9900".toLowerCase():
      return "#FFCB80";
    case "#FF9800".toLowerCase():
      return "#FFCB80";
    case "#f43319".toLowerCase():
      return "#F6BEBB";
    case "#970426".toLowerCase():
      return "#D7CAB9";
    case "#970427".toLowerCase():
      return "#D7CAB9";
    default:
      return color || "#A2E1ED";
  }
};
