import getFormattedDuration from "helpers/getFormattedDuration";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectActiveSubscriptionByClientIdAndOutletId } from "store/clients/clientsSlice";
import { selectAllPackageVariantActivePromotions } from "store/selectors";
import {
  PackageVariantWithServiceVariantsAndPriceAndDuration,
  PackageWithVariantWithServiceVariantsWithService,
} from "types/Package";
import { PurchasableItemOption } from "types/PurchasableItem";
import { ClientSubscriptionPurchase } from "types/Subscription";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItem from "../PurchasableItem";

type PackagesPurchasableItemsProps = {
  packages: PackageWithVariantWithServiceVariantsWithService[];
};

const PackagesPurchasableItems = ({ packages }: PackagesPurchasableItemsProps) => {
  const { t } = useTranslation(["services", "loyalty"]);
  const {
    onSelectItem,
    clientId,
    outletId,
    selectedItems,
    shouldHighlightSelectedItems,
    shouldShowPromotionTag,
    shouldDisableLoyaltyIneligibleItems,
  } = usePurchasableItemsSelector();

  const selectedPackageVariantIds = selectedItems?.[PurchasableItemOption.Package] || [];

  const packageVariantActivePromotions = useSelector(selectAllPackageVariantActivePromotions);

  const clientSubscriptions = useSelector(
    selectActiveSubscriptionByClientIdAndOutletId(clientId, outletId)
  ) as ClientSubscriptionPurchase[];

  const getPackageDescription = (
    serviceVariants: PackageVariantWithServiceVariantsAndPriceAndDuration["serviceVariants"]
  ) => {
    const serviceVariantsDescription = serviceVariants.map(
      (serviceVariant) => `${serviceVariant.name}`
    );

    // creates a string from array elements separated by new line
    const servicesVariantsDescriptionString = serviceVariantsDescription.join("\n");

    return servicesVariantsDescriptionString;
  };

  return (
    <>
      {packages.map((_package) => {
        const packageVariant = _package.variants?.[0];

        const packageDescription = getPackageDescription(packageVariant.serviceVariants);

        const packageSubtitle = `${getFormattedDuration(packageVariant.totalDuration)}`;

        const numberOfServicesSummary = `${packageVariant.serviceVariants.length} ${t(
          "services:services"
        )}`;

        const hasActiveSubscription = clientSubscriptions.some(
          (subscription) => subscription.packageVariant === packageVariant.id
        );

        const shouldHighlight =
          shouldHighlightSelectedItems && selectedPackageVariantIds.includes(packageVariant.id);

        const hasPromotionTag =
          shouldShowPromotionTag &&
          packageVariantActivePromotions.some(
            (promotion) =>
              (promotion.packageVariant === packageVariant.id || promotion.hasAllPackages) &&
              promotion.active
          );

        const helperText = shouldDisableLoyaltyIneligibleItems
          ? t("loyalty:thisItemExistsInAnotherLoyaltyProgram")
          : "";

        return (
          <PurchasableItem
            key={`Package-${_package.id}-${_package.name}`}
            title={_package.name}
            subtitle={packageSubtitle}
            descriptionTitle={numberOfServicesSummary}
            description={packageDescription}
            helperText={helperText}
            isDisabled={
              packageVariant.isLoyaltyEligible && Boolean(shouldDisableLoyaltyIneligibleItems)
            }
            price={packageVariant.price}
            shouldShowSubscriptionTag={hasActiveSubscription}
            handleClick={() => onSelectItem(packageVariant, PurchasableItemOption.Package)}
            isHighlighted={shouldHighlight}
            shouldShowPromotionTag={hasPromotionTag}
          />
        );
      })}
    </>
  );
};

export default PackagesPurchasableItems;
