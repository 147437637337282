import { LoadingButton } from "@mui/lab";
import { ButtonProps, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import DialogModal, { DialogModalProps } from "./DialogModal";

export enum ConfirmationModalSeverity {
  Moderate = "Moderate", // Primary colors
  Critical = "Critical", // Error colors
}

export type ConfirmationDialogModalProps = DialogModalProps & {
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;

  cancelButtonVariant?: ButtonProps["variant"];

  confirmButtonSeverity?: ConfirmationModalSeverity;
  cancelButtonSeverity?: ConfirmationModalSeverity;

  shouldDisableConfirmButton?: boolean;

  isCancelButtonLoading?: boolean;
  isConfirmButtonLoading?: boolean;

  onConfirmButtonClick: () => void;
  onCancelButtonClick?: () => void;
};

const ConfirmationDialogModal = ({
  confirmButtonLabel,
  cancelButtonLabel,

  cancelButtonVariant = "outlined",

  confirmButtonSeverity = ConfirmationModalSeverity.Critical,
  cancelButtonSeverity = ConfirmationModalSeverity.Moderate,

  shouldDisableConfirmButton = false,

  isCancelButtonLoading = false,
  isConfirmButtonLoading = false,

  onConfirmButtonClick,
  onCancelButtonClick,

  ...dialogModalProps
}: ConfirmationDialogModalProps) => {
  const { t } = useTranslation();

  const getButtonColorBasedOnSeverity = (severity: ConfirmationModalSeverity) =>
    severity === ConfirmationModalSeverity.Critical ? "error" : "primary";

  const { onClose } = dialogModalProps;
  return (
    <DialogModal
      {...dialogModalProps}
      dialogFooterActions={
        <Grid container spacing={{ md: 3, xs: 2 }} p={{ md: 3, xs: 2 }}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              variant={cancelButtonVariant}
              color={getButtonColorBasedOnSeverity(cancelButtonSeverity)}
              onClick={onCancelButtonClick || onClose}
              loading={isCancelButtonLoading}
            >
              {cancelButtonLabel || t("cancel")}
            </LoadingButton>
          </Grid>

          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              color={getButtonColorBasedOnSeverity(confirmButtonSeverity)}
              onClick={onConfirmButtonClick}
              disabled={shouldDisableConfirmButton}
              loading={isConfirmButtonLoading}
            >
              {confirmButtonLabel || t("confirm")}
            </LoadingButton>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ConfirmationDialogModal;
