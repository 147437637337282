import { PackageVariantWithServiceVariantsAndPriceAndDuration } from "types/Package";
import { PurchasableItem } from "types/PurchasableItem";
import { ServiceVariantWithService } from "types/ServiceV2";
import { Subscription } from "types/Subscription";
import { VoucherWithRedeemableItemsTitle } from "types/Voucher";

export function isServiceVariant(
  purchasableItem: PurchasableItem
): purchasableItem is ServiceVariantWithService {
  return (purchasableItem as ServiceVariantWithService).service !== undefined;
}

export function isPackageVariant(
  purchasableItem: PurchasableItem
): purchasableItem is PackageVariantWithServiceVariantsAndPriceAndDuration {
  return (
    (purchasableItem as PackageVariantWithServiceVariantsAndPriceAndDuration).package !== undefined
  );
}

export function isProductWithBrand(
  purchasableItem: PurchasableItem
): purchasableItem is ProductWithBrand {
  return Boolean(purchasableItem);
}

export function isSubscription(purchasableItem: PurchasableItem): purchasableItem is Subscription {
  return (purchasableItem as Subscription).validityInDays !== undefined;
}

export function isVoucherWithRedeemableItemsTitle(
  purchasableItem: PurchasableItem
): purchasableItem is VoucherWithRedeemableItemsTitle {
  return (purchasableItem as VoucherWithRedeemableItemsTitle).redeemableItemsTitle !== undefined;
}
