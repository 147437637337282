import { DoubleChevronLeft, DoubleChevronRight } from "@bookpeep/ui";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type ToggleActiveDrawerSideSectionProps = {
  isAppointmentSectionActive: boolean;
  handleToggleActiveDrawerSide: () => void;
};

const ToggleActiveDrawerSideSection = ({
  isAppointmentSectionActive,
  handleToggleActiveDrawerSide,
}: ToggleActiveDrawerSideSectionProps) => {
  const { t } = useTranslation(["appointments"]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isAppointmentSectionActive ? "flex-end" : "flex-start"}
      gap={2}
      p={3}
      boxShadow="divider"
      sx={{
        color: "primary.main",
        cursor: "pointer",
        "&. MuiTypography-root": {
          fontWeight: 700,
        },
      }}
      onClick={handleToggleActiveDrawerSide}
    >
      {isAppointmentSectionActive ? (
        <>
          <Typography fontWeight={700}>{t("viewAppointment")}</Typography>
          <DoubleChevronRight />
        </>
      ) : (
        <>
          <DoubleChevronLeft />
          <Typography fontWeight={700}>{t("viewClientProfile")}</Typography>
        </>
      )}
    </Stack>
  );
};

export default ToggleActiveDrawerSideSection;
