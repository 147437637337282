import { Box } from "@mui/material";
import { Calendar } from "assets/icon";
import { theme } from "configs";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";

import NotificationCard from "./NotificationCard";
import { NotificationCardProps } from "./NotificationCardType";

const AppointmentNotificationCard = (props: NotificationCardProps) => {
  const { onClick, ...remainingProps } = props;
  const { openAppointmentDrawerViewMode } = useAppointmentDrawer();

  const handleOpenAppointmentDetailDrawer = (poiId: number) => {
    openAppointmentDrawerViewMode({
      appointmentId: poiId,
    });
  };

  const handleOnClick = () => {
    onClick();

    handleOpenAppointmentDetailDrawer(props.notification.poiId);
  };

  return (
    <NotificationCard
      icon={
        <Box
          sx={{
            backgroundColor: "#4048D6",
            borderRadius: theme.spacing(1),
            width: 40,
            height: 40,
            padding: theme.spacing(0.5),
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Calendar sx={{ color: "white" }} width={20} height={22} viewBox="0 0 20 22" />
        </Box>
      }
      {...remainingProps}
      onClick={handleOnClick}
    />
  );
};

export default AppointmentNotificationCard;
