import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./invoiceSequencingApi";

const invoiceSequencingAdapter = createEntityAdapter();

const initialState = invoiceSequencingAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = invoiceSequencingAdapter.getSelectors(
  (state) => state.invoiceSequencing
);

export const selectAllInvoiceSequences = selectAll;

export const selectInvoiceSequencingById = (id) => (state) => selectById(state, id);

export const selectInvoiceSequencingLoading = (state) => state.isLoading;

export const getAllInvoiceSequences = createThunkFromApi(
  "invoiceSequencing/getAllInvoiceSequences",
  api.getAllInvoiceSequences
);

export const getInvoiceSequencing = createThunkFromApi(
  "invoiceSequencing/getInvoiceSequencing",
  api.getInvoiceSequencing
);

export const updateInvoiceSequencing = createThunkFromApi(
  "invoiceSequencing/updateInvoiceSequencing",
  api.updateInvoiceSequencing
);

export const invoiceSequencingSlice = createSlice({
  name: "invoiceSequencing",
  initialState,
  extraReducers: {
    [getAllInvoiceSequences.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllInvoiceSequences.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      invoiceSequencingAdapter.setAll(state, payload);
    },

    [getAllInvoiceSequences.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getInvoiceSequencing.fulfilled]: (state, { payload }) => {
      invoiceSequencingAdapter.setOne(state, payload);
    },
    [updateInvoiceSequencing.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateInvoiceSequencing.fulfilled]: (state, { payload }) => {
      invoiceSequencingAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updateInvoiceSequencing.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default invoiceSequencingSlice.reducer;
