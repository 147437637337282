import { AppointmentArticleSlot, AppointmentArticleWithSlot } from "types/Appointment";

export function isAppointmentArticleWithSlot(
  appointmentArticle: any
): appointmentArticle is AppointmentArticleWithSlot {
  return (
    appointmentArticle &&
    typeof appointmentArticle === "object" &&
    "slots" in appointmentArticle &&
    Array.isArray(appointmentArticle.slots) &&
    appointmentArticle.slots.every((slot: any) => isAppointmentArticleSlot(slot))
  );
}

function isAppointmentArticleSlot(slot: any): slot is AppointmentArticleSlot {
  return typeof slot === "object" && "staff" in slot;
}
