import { EmployeeBlockTime } from "types/BlockTime";
import http from "utils/http";

export type DjangoDetailResponse = {
  detail: string;
};

export type CreateEmployeeBlockTime = {
  startTime: string;
  endTime: string;
  employee: number;
  outlet: number;
  blocksOnline: boolean;
  description: string;
};

export type UpdateEmployeeBlockTime = {
  startTime: string;
  endTime: string;
  employee?: number;
  outlet?: number;
  blocksOnline?: boolean;
  description?: string;
};

export type CreateEmployeeBlockTimeResponse = EmployeeBlockTime;

export type UpdateEmployeeBlockTimeResponse = EmployeeBlockTime;

export type EmployeeBlockTimeResponse = EmployeeBlockTime[];

export type EmployeesBlockTimeArguments = {
  startDate: string;
  endDate: string;
  outletId: number;
};

export type CreateEmployeeBlockTimeArguments = {
  data: CreateEmployeeBlockTime;
};

export type UpdateEmployeeBlockTimeArguments = {
  data: UpdateEmployeeBlockTime;
  id: number;
};

export type EmployeeTimeConflictArguments = {
  startTime: string;
  endTime: string;
  employees: number[];
  dates: string[];
};

export type DeleteEmployeeBlockTime = {
  id: number;
};

export type DeleteEmployeeBlockTimeArguments = {
  data: DeleteEmployeeBlockTime;
};

const employeeBlockTimesApi = {
  getEmployeeBlockTimes: async ({ startDate, endDate, outletId }: EmployeesBlockTimeArguments) =>
    http.get<EmployeeBlockTimeResponse>(
      `employees/employee-block-times/?start_date=${startDate}&end_date=${endDate}&outlet=${outletId}`
    ),

  createEmployeeBlockTime: (data: CreateEmployeeBlockTime) =>
    http.post<CreateEmployeeBlockTimeResponse>(`employees/employee-block-times/`, data),

  updateEmployeeBlockTime: (data: UpdateEmployeeBlockTime, id: number) =>
    http.put<UpdateEmployeeBlockTimeResponse>(`employees/employee-block-times/${id}/`, data),

  deleteEmployeeBlockTime: ({ id }: DeleteEmployeeBlockTime) =>
    http.delete(`employees/employee-block-times/${id}/`),
};

export default employeeBlockTimesApi;
