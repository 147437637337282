import http from "utils/http";

export type DjangoDetailResponse = {
  detail: string;
};

export type EmployeeWorkingHours = {
  id: number;
  shift: number | null;
  employee: number;
  date: string;
  startTime: string;
  endTime: string;
  type: string;
  outlet: number;
};

export type CreateEmployeeWorkingHours = {
  startTime: string;
  endTime: string;
  employees: number[];
  dates: string[];
  outlet: number;
  shift: number | null;
};

export type UpdateEmployeesWorkingHours = {
  shift: number | null;
  employee: number;
  date: string;
  startTime: string;
  endTime: string;
  type: string;
  outlet: number;
  updateAll: boolean;
};

export type CreateEmployeeWorkingHoursResponse = CreateEmployeeWorkingHours[];

export type UpdateEmployeeWorkingHoursResponse = {
  id: number;
  startTime: string;
  endTime: string;
  dates: string[];
  shift: number | null;
  employee: number;
  outlet: number;
};

export type EmployeeWorkingHoursResponse = EmployeeWorkingHours[];

export type EmployeesWorkingHoursArguments = {
  startDate: string;
  endDate: string;
  outletId: number;
};

export type CreateEmployeesWorkingHoursArguments = {
  data: CreateEmployeeWorkingHours;
};

export type UpdateEmployeesWorkingHoursArguments = {
  data: UpdateEmployeesWorkingHours;
  id: number;
};

export type EmployeeTimeConflictArguments = {
  startTime: string;
  endTime: string;
  employees: number[];
  dates: string[];
};

export type DeleteEmployeeWorkingHours = {
  date: string;
  updateAll: boolean;
  id: number;
};

export type DeleteEmployeeWorkingHoursArguments = {
  data: DeleteEmployeeWorkingHours;
};

type SingleConflict = {
  date: string;
  startTime: string;
  endTime: string;
  shift: number | null;
  outlet: number;
};

export interface EmployeeTimeConflictResponse {
  [key: string]: SingleConflict[];
}

const workingHoursApi = {
  getEmployeesWorkingHours: async ({
    startDate,
    endDate,
    outletId,
  }: EmployeesWorkingHoursArguments) =>
    http.get<EmployeeWorkingHoursResponse>(
      `shifts/working_hours/?start_date=${startDate}&end_date=${endDate}&outlet=${outletId}`
    ),

  createEmployeesWorkingHours: (data: CreateEmployeeWorkingHours) =>
    http.post<CreateEmployeeWorkingHoursResponse>(`shifts/working_hours/`, data),

  updateEmployeesWorkingHours: (data: UpdateEmployeesWorkingHours, id: number) =>
    http.put<UpdateEmployeeWorkingHoursResponse>(`shifts/working_hours/${id}/`, data),

  getTimeConflicts: (data: EmployeeTimeConflictArguments) =>
    http.post<EmployeeTimeConflictResponse>("shifts/conflicts/", data),

  deleteEmployeeWorkingHour: ({ id, date, updateAll }: DeleteEmployeeWorkingHours) =>
    http.delete(`shifts/working_hours/${id}/?update_all=${updateAll}&date=${date}`),
};

export default workingHoursApi;
