import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, styled } from "@mui/material";
import { StickyBottomActions } from "assets/mixins/StickyBottomActions";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { createGiftVoucher } from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import useNotify from "utils/notifications/useNotify";

import { VoucherGiftFields } from "./voucherGiftSchema";

type FormActionButtonsProps = {
  onClose: () => void;
};

const FormActionButtons = ({ onClose }: FormActionButtonsProps) => {
  const { t } = useTranslation("vouchers");

  const notify = useNotify();

  const dispatch = useDispatch();

  const [isGiftVoucherRequestPending, setIsGiftVoucherRequestPending] = useState(false);

  const { handleSubmit, watch } = useFormContext<VoucherGiftFields>();

  const { existingClient, newClient } = watch();

  const canSubmit = existingClient || newClient;

  const handleConfirmGiftVoucher = handleSubmit((data) => {
    if (!canSubmit) return;

    setIsGiftVoucherRequestPending(true);
    const { existingClient, newClient, voucherPurchase, message, email } = data;

    const client = existingClient || newClient;

    if (!client) throw new Error("Client must be defined!");

    const submittedData = {
      voucherPurchase,
      client,
      message,
      email,
    };

    dispatch(createGiftVoucher(submittedData))
      .unwrap()
      .then(() => {
        notify(t("translation:entitySuccessfullyCreated", { entity: t("giftVoucher") }), "success");
        setIsGiftVoucherRequestPending(false);
        onClose();
      })
      .catch((error) => {
        notify(
          `${t("translation:entityFailedToBeCreated", { entity: t("giftVoucher") })} ${
            error?.detail
          }`,
          "error"
        );
      });
  });

  return (
    <ActionsWrapper p={3}>
      <Grid container spacing={2.5}>
        <Grid item xs={6}>
          <Button fullWidth onClick={onClose} variant="outlined">
            {t("cancel")}
          </Button>
        </Grid>

        <Grid item xs={6}>
          <LoadingButton
            fullWidth
            onClick={handleConfirmGiftVoucher}
            disabled={!canSubmit}
            loading={isGiftVoucherRequestPending}
          >
            {t("giftVoucher")}
          </LoadingButton>
        </Grid>
      </Grid>
    </ActionsWrapper>
  );
};

export default FormActionButtons;

const ActionsWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.text.label}25`,
  [theme.breakpoints.down("md")]: {
    ...StickyBottomActions(),
  },
}));
