import http from "utils/http";

const outletsApi = {
  getAllOutlets: async () => http.get("organizations/outlets/"),

  getOutlet: async (id) => http.get(`organizations/outlets/${id}/`),

  createOutlet: async (data) => http.post("organizations/outlets/", data),

  updateOutlet: async (id, data) => http.put(`organizations/outlets/${id}/`, data),

  removeOutlet: async (id) => http.delete(`organizations/outlets/${id}/`),

  createSecondaryImage: async (data) => http.post("organizations/outlet_images/", data),

  removeSecondaryImage: async ({ id, outletId }) =>
    http.delete(`organizations/outlet_images/${id}/`, { outletId }),
};

export default outletsApi;
