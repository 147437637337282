import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

type FlexServiceChipProps = {
  variant?: "small" | "medium";
};
const FlexServiceChip = ({ variant = "medium" }: FlexServiceChipProps) => {
  const { t } = useTranslation("calendar");

  return (
    <Chip
      label={t("flexService")}
      color="primary"
      size="small"
      sx={{
        color: "white",
        fontWeight: 700,
        maxWidth: "125px",
        width: variant === "medium" ? "min-content" : "100px",
        padding: (theme) => (variant === "medium" ? theme.spacing(0.5) : theme.spacing(0)),
      }}
    />
  );
};

export default FlexServiceChip;
