import { Box, Typography } from "@mui/material";
import { getFormattedPrice } from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";

type PurchasableItemPriceProps = {
  isSingleVariant: boolean;
  shouldShowPricingFrom?: boolean;
  price: string | number;
};

const PurchasableItemPrice = ({
  isSingleVariant,
  shouldShowPricingFrom,
  price,
}: PurchasableItemPriceProps) => {
  const { t } = useTranslation("checkout");

  return (
    <Box>
      <Typography align="right" fontSize={isSingleVariant ? "initial" : "0.8rem"} noWrap>
        <Typography variant="secondary" color="text.placeholder" display="inline" px={1}>
          {shouldShowPricingFrom && t("checkout:from")}
        </Typography>
        {t("checkout:currency")} {getFormattedPrice(price)}
      </Typography>
    </Box>
  );
};

export default PurchasableItemPrice;
