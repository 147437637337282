import { Close } from "@bookpeep/ui";
import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { CheckIcon, LoyaltyGiftIcon } from "assets/icon";
import { dontForwardProps } from "helpers/styles";
import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { trackEvent } from "tracking";
import type { CheckoutLoyaltyCard } from "types/Appointment";
import http from "utils/http";
import useNotify from "utils/notifications/useNotify";

type InvoiceLoyaltyCardProps = {
  loyaltyCard: CheckoutLoyaltyCard;
  clientName?: string;
  clientPhoneNumber?: string;
};

const InvoiceLoyaltyCard = ({
  loyaltyCard,
  clientName,
  clientPhoneNumber,
}: InvoiceLoyaltyCardProps) => {
  const { t } = useTranslation("invoices");
  const notify = useNotify();
  const [isLoyaltyCardActivated, setIsLoyaltyCardActivated] = useState(loyaltyCard.isMpUser);

  const [showModal, setShowModal] = useState(!isLoyaltyCardActivated);

  const totalConfirmedPunches =
    Number(loyaltyCard.totalEarnedPunches) > Number(loyaltyCard.totalEarningsToReward)
      ? Number(loyaltyCard.totalEarningsToReward)
      : Number(loyaltyCard.totalEarnedPunches);

  const remainingPunches = Number(loyaltyCard.totalEarningsToReward) - totalConfirmedPunches;

  const getLoyaltyCardUserStatus = useCallback(() => {
    if (clientPhoneNumber)
      http
        .get(`/users/is-mp-user/?phone_number=${clientPhoneNumber.trim().replace("+", "%2b")}`)
        .then(({ data: { hasFoundMpUserAccount } }) => {
          setIsLoyaltyCardActivated(hasFoundMpUserAccount);
          setShowModal(!hasFoundMpUserAccount);
          if (hasFoundMpUserAccount) {
            trackEvent("Loyalty Card Activated", {
              "Loyalty Card ID": loyaltyCard.id,
              "Is Mp User": loyaltyCard.isMpUser,
              "Client Name": clientName || "",
              "Client Phone Number": clientPhoneNumber || "",
              Type: loyaltyCard.earningsType,
            });
            notify(t("cardActivated"), "success");
          } else {
            notify(t("userHasNotJoinedForBookPeepApp"), "error");
          }
        });
  }, [
    clientName,
    clientPhoneNumber,
    loyaltyCard.earningsType,
    loyaltyCard.id,
    loyaltyCard.isMpUser,
    notify,
    t,
  ]);

  const cardDescription = useMemo(() => {
    if (isLoyaltyCardActivated) {
      return (
        <Typography fontWeight={400} variant="secondary">
          <Trans
            i18nKey="invoices:activeLoyaltyCard"
            values={{ clientName }}
            components={[
              <Typography
                key="activate loyalty card"
                display="inline"
                variant="primary"
                fontWeight={700}
              />,
            ]}
          />
        </Typography>
      );
    } else {
      return (
        <Typography fontWeight={400} variant="secondary">
          <Trans
            i18nKey="invoices:inactiveLoyaltyCard"
            values={{ loyaltyName: loyaltyCard.name }}
            components={[
              <Typography
                key="Inactive loyalty card"
                display="inline"
                variant="primary"
                fontWeight={700}
              />,
            ]}
          />
        </Typography>
      );
    }
  }, [isLoyaltyCardActivated, loyaltyCard.name, clientName]);

  return (
    <>
      <Dialog
        open={showModal}
        maxWidth="md"
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Box p={2} position="absolute" top="0" right="0">
          <IconButton
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <img src="/images/inactiveLoyaltyModal.png" alt="inactive loyalty card dialog" />
      </Dialog>
      <StyledCard>
        <img alt="Loyalty Card Header" src="/images/loyaltyCardHeader.svg" width="115px" />
        <Box py={2}>{cardDescription}</Box>
        <PreviewBoxWrapper>
          {Array.from({ length: totalConfirmedPunches }, (_, index) => (
            <DottedCardWrapper isConfirmed key={`Confirmed - ${index}`}>
              <StyledCheckIcon />
            </DottedCardWrapper>
          ))}
          {Array.from({ length: remainingPunches }, (_, index) => (
            <DottedCardWrapper key={`Remaining - ${index}`}>
              {totalConfirmedPunches + index + 1}
            </DottedCardWrapper>
          ))}

          <DottedCardWrapper isGiftCard>
            <LoyaltyGiftIcon
              sx={{
                mt: 1,
                ml: 1,
                color: "#D3529D",
              }}
            />
          </DottedCardWrapper>
        </PreviewBoxWrapper>
        {!isLoyaltyCardActivated && (
          <Box py={2}>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              onClick={getLoyaltyCardUserStatus}
            >
              {t("activate")}
            </Button>
          </Box>
        )}
      </StyledCard>
    </>
  );
};

export default InvoiceLoyaltyCard;

const PreviewBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.bg.secondary,
  borderRadius: theme.spacing(2),
  gap: theme.spacing(1),
}));

type DottedCardWrapperProps = TypographyProps & {
  isGiftCard?: boolean;
  isConfirmed?: boolean;
};

const DottedCardWrapper = styled(
  Box,
  dontForwardProps("isGiftCard", "isConfirmed")
)<DottedCardWrapperProps>(({ theme, isGiftCard, isConfirmed }) => ({
  fontWeight: 500,
  borderRadius: theme.spacing(2),
  width: theme.spacing(4),
  height: theme.spacing(4),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: isGiftCard ? "1px dotted #D3529D" : isConfirmed ? "none" : "1px dotted #666DDE",
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: theme.spacing(10),
  color: "#4048D6",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#F7F8FD",
  borderRadius: 12,
  padding: theme.spacing(3),
  width: "100%",
}));
