import { Box, Pagination as MuiPagination, PaginationItem, styled } from "@mui/material";

const Pagination = ({ count, page, setPage, ...props }) => {
  if (count <= 0) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="right" {...props} py={5}>
      <MuiPagination
        count={count}
        onChange={(_, value) => {
          setPage(value);
        }}
        page={page}
        renderItem={(item) => <StyledPaginationItem {...item} color="primary" shape="rounded" />}
      />
    </Box>
  );
};

export default Pagination;

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
  "&.MuiPaginationItem-root": {
    height: "20px",
    width: "7px",
    minWidth: "0px",
    padding: theme.spacing(1),
    borderRadius: "6px",
  },
}));
