import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./paymentTypesApi";

const paymentTypesAdapter = createEntityAdapter();

const initialState = paymentTypesAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = paymentTypesAdapter.getSelectors((state) => state.paymentTypes);

export const selectAllPaymentTypes = selectAll;

export const selectPaymentTypeById = (id) => (state) => selectById(state, id);

export const selectPaymentTypeLoading = (state) => state.paymentTypes.isLoading;

export const getAllPaymentTypes = createThunkFromApi(
  "paymentTypes/getAllPaymentTypes",
  api.getAllPaymentTypes
);

export const getPaymentType = createThunkFromApi("paymentTypes/getPaymentType", api.getPaymentType);

export const createPaymentType = createAsyncThunk(
  "paymentTypes/createPaymentType",
  async (createPaymentType, { rejectWithValue }) => {
    try {
      const { submittedData, handleClose } = createPaymentType;
      const response = await api.createPaymentType(submittedData);

      handleClose();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updatePaymentType = createAsyncThunk(
  "paymentTypes/updatePaymentType",
  async (updatePaymentTypeArgs, { rejectWithValue }) => {
    try {
      const { id, submittedData, handleClose } = updatePaymentTypeArgs;
      const response = await api.updatePaymentType(id, submittedData);

      if (handleClose) {
        handleClose();
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deletePaymentType = createAsyncThunk(
  "paymentTypes/removePaymentType",
  async (deletePaymentTypeArgs, { rejectWithValue }) => {
    try {
      const { id, handleCloseDelete } = deletePaymentTypeArgs;
      await api.removePaymentType(id);

      handleCloseDelete();
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const paymentTypesSlice = createSlice({
  name: "paymentTypes",
  initialState,
  extraReducers: {
    [getAllPaymentTypes.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllPaymentTypes.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      paymentTypesAdapter.setAll(state, payload);
    },

    [getAllPaymentTypes.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getPaymentType.fulfilled]: (state, { payload }) => {
      paymentTypesAdapter.setOne(state, payload);
    },
    [createPaymentType.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createPaymentType.fulfilled]: (state, { payload }) => {
      paymentTypesAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createPaymentType.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updatePaymentType.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updatePaymentType.fulfilled]: (state, { payload }) => {
      paymentTypesAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updatePaymentType.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [deletePaymentType.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deletePaymentType.fulfilled]: (state, { payload: { id } }) => {
      paymentTypesAdapter.removeOne(state, id);
      state.isRequestPending = false;
    },
    [deletePaymentType.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default paymentTypesSlice.reducer;
