import { DateTime } from "luxon";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectAllCalendarPresets } from "store/calendarPresets/calendarPresetsSelectors";
import { selectAllCalendarEmployees } from "store/employees/employeesSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectFirstActiveOutlet, selectOutletById } from "store/outlets/outletsSlice";
import { selectActiveOutletResourcesByOutletId } from "store/selectors";
import Employee from "types/Employee";
import { Industry } from "types/Industries";
import Outlet from "types/Outlet";
import { areObjectsEqual } from "utils/object";

type ZoomLevel = [slotDuration: string, slotInterval: string];

export const ZOOM_LEVELS: ZoomLevel[] = [
  ["02:00:00", "02:00:00"],
  ["01:30:00", "01:30:00"],
  ["01:00:00", "01:00:00"],
  ["00:30:00", "01:00:00"],
  ["00:15:00", "00:30:00"],
];

export const TIME: string[] = [
  "00:00:00",
  "01:00:00",
  "02:00:00",
  "03:00:00",
  "04:00:00",
  "05:00:00",
  "06:00:00",
  "07:00:00",
  "08:00:00",
  "09:00:00",
  "10:00:00",
  "11:00:00",
  "12:00:00",
  "13:00:00",
  "14:00:00",
  "15:00:00",
  "16:00:00",
  "17:00:00",
  "18:00:00",
  "19:00:00",
  "20:00:00",
  "21:00:00",
  "22:00:00",
  "23:00:00",
  "24:00:00",
];

export const DEFAULT_ZOOM_LEVEL_INDEX = 4;

export enum CalendarView {
  ONE_DAY = "oneDayView",
  THREE_DAYS = "threeDayView",
  WEEK = "weekView",
}

export enum CalendarEditMode {
  SERVICE = "service",
  APPOINTMENT = "appointment",
}

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type CalendarPageState = {
  outletId: number;
  setOutletId: (id: number) => void;

  zoneId: number | undefined;
  setZoneId: (id: number | undefined) => void;

  activeSelectedOutletResources: number[];
  resourceIds: number[];
  setResourceIds: (ids: number[]) => void;

  activeSelectedOutletEmployees: number[];
  employeeIds: number[];
  setEmployeeIds: (ids: number[]) => void;
  presetEmployeesObject?: { [key: string]: number[] };
  presetResourcesObject?: { [key: string]: number[] };

  categoryIds: number[];
  setCategoryIds: (ids: number[]) => void;

  calendarView: CalendarView;
  setCalendarView: (view: CalendarView) => void;

  calendarEditMode: CalendarEditMode;
  setCalendarEditMode: (view: CalendarEditMode) => void;

  isSingleView: boolean;

  date: DateTime;
  setDate: (date: DateTime) => void;

  isNonBusinessHoursModalOpen: boolean;
  setIsNonBusinessHoursModalOpen: (modalState: boolean) => void;

  appointmentStaffId: Number;
  setAppointmentStaffId: (id: Number) => void;

  appointmentStartTime: DateTime;
  setAppointmentStartTime: (startTime: DateTime) => void;

  isHomeServiceOrganization: boolean;
  isDefaultCalendar: boolean;
  setIsDefaultCalendar: Dispatch<SetStateAction<boolean>>;

  zoomLevel: number;
  zoomIn: () => void;
  zoomOut: () => void;
  setZoomLevel: Dispatch<SetStateAction<number>>;

  numOfColumns: number;
  setNumOfColumns: Dispatch<SetStateAction<number>>;

  maxNumberOfColumns: number;

  slotRange: number[];
  setSlotRange: Dispatch<SetStateAction<number[]>>;
  handleSlotRange: (range: number[]) => void;
};

const initialState: CalendarPageState = {
  outletId: -1,
  setOutletId: unimplementedFunction,

  zoneId: undefined,
  setZoneId: unimplementedFunction,

  activeSelectedOutletResources: [],
  resourceIds: [],
  setResourceIds: unimplementedFunction,

  activeSelectedOutletEmployees: [],
  employeeIds: [],
  setEmployeeIds: unimplementedFunction,

  presetEmployeesObject: undefined,
  presetResourcesObject: undefined,

  categoryIds: [],
  setCategoryIds: unimplementedFunction,

  calendarView: CalendarView.ONE_DAY,
  setCalendarView: unimplementedFunction,

  calendarEditMode: CalendarEditMode.SERVICE,
  setCalendarEditMode: unimplementedFunction,

  isSingleView: false,

  date: DateTime.now(),
  setDate: unimplementedFunction,

  isNonBusinessHoursModalOpen: false,
  setIsNonBusinessHoursModalOpen: unimplementedFunction,

  appointmentStaffId: 0,
  setAppointmentStaffId: unimplementedFunction,

  appointmentStartTime: DateTime.now(),
  setAppointmentStartTime: unimplementedFunction,

  isHomeServiceOrganization: false,

  isDefaultCalendar: true, // Is Main calendar tab
  setIsDefaultCalendar: unimplementedFunction,

  zoomLevel: DEFAULT_ZOOM_LEVEL_INDEX,
  zoomIn: unimplementedFunction,
  zoomOut: unimplementedFunction,
  setZoomLevel: unimplementedFunction,
  slotRange: [0, 24],
  setSlotRange: unimplementedFunction,
  handleSlotRange: unimplementedFunction,

  numOfColumns: 0,
  setNumOfColumns: unimplementedFunction,

  maxNumberOfColumns: 0,
};

const CalendarPageContext = createContext<CalendarPageState>(initialState);

export default CalendarPageContext;

function CalendarPageContextProvider({ children }: { children: ReactNode }) {
  const calendarPresets = useSelector(selectAllCalendarPresets);
  const defaultPreset = calendarPresets.find(([id]) => id === "DEFAULT");

  const [calendarEditMode, setCalendarEditMode] = useState(CalendarEditMode.SERVICE);

  const [isDefaultCalendar, setIsDefaultCalendar] = useState(true);

  const organization = useSelector(selectOrganization) as Organization;

  const calendarEmployees = useSelector(selectAllCalendarEmployees) as Employee[];

  const initialOutlet = useSelector(selectFirstActiveOutlet) as Outlet;
  const defaultOutletId = defaultPreset ? defaultPreset[1]["outletId"] : initialOutlet?.id ?? -1;

  const isHomeServiceOrganization = organization?.industry === Industry.BeautyHomeService;

  const [outletId, setOutletId] = useState<number>(
    isHomeServiceOrganization ? initialOutlet?.id : defaultOutletId
  );

  const [zoneId, setZoneId] = useState<number | undefined>(undefined);

  const selectedOutlet = useSelector(selectOutletById(outletId)) as Outlet;
  const outletResources = useSelector(selectActiveOutletResourcesByOutletId(outletId));
  const outletResourceIds = outletResources.map((resource) => resource.id);

  const initialZoomLevel = defaultPreset
    ? defaultPreset[1]["zoomLevel"] === undefined
      ? DEFAULT_ZOOM_LEVEL_INDEX
      : defaultPreset[1]["zoomLevel"] ?? DEFAULT_ZOOM_LEVEL_INDEX
    : DEFAULT_ZOOM_LEVEL_INDEX;

  // NOTE: We had to replace _ with "" because the outletId is stored as string separated by _ in the defaultPreset object
  const defaultPresetEmployees = defaultPreset
    ? defaultPreset[1]?.["employees"]?.[String(outletId).replace("_", "")] ||
      defaultPreset[1]["employeeIds"]
    : [];

  const [presetEmployeesObject, setPresetEmployeesObject] = useState(
    defaultPreset?.[1]?.["employees"] || {}
  );

  const [presetResourcesObject, setPresetResourcesObject] = useState(
    defaultPreset?.[1]?.["resources"] || {}
  );

  useEffect(() => {
    if (isHomeServiceOrganization && initialOutlet) {
      if (outletId !== initialOutlet.id) {
        setOutletId(initialOutlet.id);
      }
    }
  }, [organization, outletId, initialOutlet, isHomeServiceOrganization]);

  useEffect(() => {
    if (defaultPreset) {
      if (!areObjectsEqual(presetEmployeesObject, defaultPreset[1]?.["employees"])) {
        setPresetEmployeesObject(defaultPreset[1]?.["employees"] || {});
      }

      if (!areObjectsEqual(presetResourcesObject, defaultPreset[1]?.["resources"])) {
        setPresetResourcesObject(defaultPreset[1]?.["resources"] || {});
      }
    }
  }, [defaultPreset, presetEmployeesObject, presetResourcesObject]);

  // NOTE: We had to replace _ with "" because the outletId is stored as string separated by _ in the defaultPreset object
  const defaultPresetResources = defaultPreset
    ? defaultPreset[1]?.["resources"]?.[String(outletId).replace("_", "")] ||
      defaultPreset[1]["resourceIds"]
    : [];

  const isActiveEmployee = (id: number) => {
    // Because when toggling outlets, the selected outlet is not updated immediately and would cause removing selected employees from the newly selected tab if the employees does not exist in the new tab's outlet 💎 Thank you Khalid for the fix
    if (selectedOutlet?.id === outletId) {
      selectedOutlet?.activeEmployees.includes(id);
    }
    return true;
  };

  const isActiveResource = (id: number) => {
    // Because when toggling outlets, the selected outlet is not updated immediately and would cause removing selected resources from the newly selected tab if the resources does not exist in the new tab's outlet 💎 Thank you Khalid for the fix
    if (selectedOutlet?.id === outletId) {
      outletResourceIds.includes(id);
    }
    return true;
  };

  const defaultPresetActiveEmployees = defaultPresetEmployees.filter((id) =>
    defaultOutletId === -1 ? [] : isActiveEmployee(id)
  );

  const defaultPresetActiveResources =
    defaultPresetResources?.filter((id) => (defaultOutletId === -1 ? [] : isActiveResource(id))) ||
    [];

  const initialActiveEmployees = defaultPreset
    ? defaultPresetActiveEmployees
    : initialOutlet?.activeEmployees || [];

  const initialActiveResources = defaultPreset ? defaultPresetActiveResources : outletResourceIds;

  const [employeeIds, setEmployeeIds] = useState(initialActiveEmployees);

  const [resourceIds, setResourceIds] = useState(initialActiveResources);

  // To handle the case where the default preset has employees that are not active
  const setEmployeeIdsThatAreActive = (ids: number[]) => {
    const activeEmployeeIds = ids.filter(isActiveEmployee);
    setEmployeeIds(activeEmployeeIds);
  };

  const setResourceIdsThatAreActive = (ids: number[]) => {
    const activeResourceIds = ids.filter(isActiveResource);
    setResourceIds(activeResourceIds);
  };

  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  const [calendarView, setCalendarView] = useState(
    defaultPreset
      ? defaultPreset[1]["calendarView"] || initialState.calendarView
      : initialState.calendarView
  );
  const [date, setDate] = useState(DateTime.now());

  const [isNonBusinessHoursModalOpen, setIsNonBusinessHoursModalOpen] = useState<boolean>(false);

  const [appointmentStaffId, setAppointmentStaffId] = useState<Number>(0);

  const [appointmentStartTime, setAppointmentStartTime] = useState<DateTime>(DateTime.now());

  const [zoomLevel, setZoomLevel] = useState(initialZoomLevel);

  const [numOfColumns, setNumOfColumns] = useState<number>(
    defaultPreset
      ? defaultPreset[1]["numOfColumns"] || initialActiveEmployees.length + resourceIds.length
      : initialActiveEmployees.length + initialActiveResources.length
  );
  const activeSelectedOutletEmployees = useMemo(() => {
    // Cannot use isActiveEmployee because it will not detect changes in selectedOutlet and i cannot include selectedOutlet in the dependency array it will complain
    if (zoneId) {
      const zoneEmployees = calendarEmployees.filter((employee) => employee.zones.includes(zoneId));
      const filteredEmployees = zoneEmployees
        .filter((employee) => employeeIds.includes(employee.id))
        .map((employee) => employee.id);
      return filteredEmployees;
    } else {
      return employeeIds.filter((id) =>
        selectedOutlet?.id ? selectedOutlet?.activeEmployees.includes(id) : true
      );
    }
  }, [calendarEmployees, employeeIds, selectedOutlet?.activeEmployees, selectedOutlet?.id, zoneId]);

  const activeSelectedOutletResources = useMemo(
    () =>
      // Cannot use isActiveResource because it will not detect changes in selectedOutlet and i cannot include selectedOutlet in the dependency array it will complain
      resourceIds.filter((id) => (selectedOutlet?.id ? outletResourceIds.includes(id) : true)),
    [resourceIds, selectedOutlet, outletResourceIds]
  );

  const maxNumberOfColumns =
    activeSelectedOutletEmployees.length + activeSelectedOutletResources.length;

  const [slotRange, setSlotRange] = useState(
    defaultPreset ? defaultPreset[1]["slotRange"] ?? [0, 24] : [0, 24]
  );

  const handleSlotRange = (range: number[]) => {
    if (range[0] === range[1]) return;
    setSlotRange(range);
  };

  const zoomOut = useCallback(
    () =>
      setZoomLevel((currentLevel) => {
        if (currentLevel < ZOOM_LEVELS.length - 1) return currentLevel + 1;
        else return currentLevel;
      }),
    []
  );

  const zoomIn = useCallback(
    () =>
      setZoomLevel((currentLevel) => {
        if (currentLevel > 0) return currentLevel - 1;
        else return currentLevel;
      }),
    []
  );

  return (
    <CalendarPageContext.Provider
      value={{
        outletId,
        setOutletId,
        zoneId,
        setZoneId,
        activeSelectedOutletResources,
        resourceIds,
        setResourceIds: setResourceIdsThatAreActive,
        activeSelectedOutletEmployees,
        employeeIds,
        setEmployeeIds: setEmployeeIdsThatAreActive,

        presetEmployeesObject,
        presetResourcesObject,

        categoryIds,
        setCategoryIds,

        calendarView,
        setCalendarView,

        calendarEditMode,
        setCalendarEditMode,

        isSingleView:
          calendarView === CalendarView.THREE_DAYS || calendarView === CalendarView.WEEK,

        date,
        setDate,

        isNonBusinessHoursModalOpen,
        setIsNonBusinessHoursModalOpen,

        appointmentStaffId,
        setAppointmentStaffId,

        appointmentStartTime,
        setAppointmentStartTime,

        isHomeServiceOrganization,
        isDefaultCalendar,
        setIsDefaultCalendar,

        zoomLevel,
        zoomIn,
        zoomOut,
        setZoomLevel,

        numOfColumns,
        setNumOfColumns,

        maxNumberOfColumns,

        slotRange,
        setSlotRange,
        handleSlotRange,
      }}
    >
      {children}
    </CalendarPageContext.Provider>
  );
}

export { CalendarPageContextProvider };
