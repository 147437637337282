import useIsMobileView from "hooks/useIsMobileView";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  appendAllCompletedAppointmentsData,
  getAllCompletedAppointmentsData,
} from "store/sales/salesSlice";

import { formatDate, timePeriodOptions } from "../utils";

export const AppointmentInvoiceStatusOptions = {
  PAID: "PAID",
  UNPAID: "UNPAID",
  PARTIALLY: "PARTIALLY",
};

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

const initialState = {
  dateRange: timePeriodOptions[3][1],
  setDateRange: unimplementedFunction,

  page: 1,
  setPage: unimplementedFunction,

  outletIds: [],
  setOutletIds: unimplementedFunction,

  createdByIds: [],
  setCreatedByIds: unimplementedFunction,

  statusOptions: [],
  setStatusOptions: unimplementedFunction,

  zoneIds: [],
  setZoneIds: unimplementedFunction,

  areaIds: [],
  setAreaIds: unimplementedFunction,

  bookingSourceOptions: [],
  setBookingSourceOptions: unimplementedFunction,

  handleLoadMore: unimplementedFunction,

  clearAllFilters: unimplementedFunction,
};

const CompletedAppointmentsContext = createContext(initialState);

export default CompletedAppointmentsContext;

function CompletedAppointmentsContextProvider({ children }) {
  const isMobileView = useIsMobileView();

  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState(initialState.dateRange);
  const [page, setPage] = useState(initialState.page);

  const [outletIds, setOutletIds] = useState([]);

  const [createdByIds, setCreatedByIds] = useState(initialState.createdByIds);

  const [statusOptions, setStatusOptions] = useState([]);

  const [zoneIds, setZoneIds] = useState([]);

  const [areaIds, setAreaIds] = useState([]);

  const [bookingSourceOptions, setBookingSourceOptions] = useState(
    initialState.bookingSourceOptions
  );

  const handleLoadMore = async (pageNumber) => {
    setPage(pageNumber);
  };

  const clearAllFilters = () => {
    setPage(1);
    setOutletIds([]);
    setCreatedByIds([]);
    setStatusOptions([]);
    setBookingSourceOptions([]);
    setZoneIds([]);
    setAreaIds([]);
  };

  useEffect(() => {
    const startDate = formatDate(dateRange[0]);
    const endDate = formatDate(dateRange[1]);

    if (isMobileView && page !== 1) {
      dispatch(
        appendAllCompletedAppointmentsData({
          startDate,
          endDate,
          outlets: outletIds,
          zones: zoneIds,
          areas: areaIds,
          createdByIds,
          status: statusOptions.map((status) => status.toUpperCase()),
          bookingSource: bookingSourceOptions,
          page,
        })
      );
    } else {
      dispatch(
        getAllCompletedAppointmentsData({
          startDate,
          endDate,
          outlets: outletIds,
          zones: zoneIds,
          areas: areaIds,
          createdByIds,
          status: statusOptions.map((status) => status.toUpperCase()),
          bookingSource: bookingSourceOptions,
          page,
        })
      );
    }
  }, [
    dispatch,
    dateRange,
    outletIds,
    createdByIds,
    statusOptions,
    page,
    zoneIds,
    areaIds,
    bookingSourceOptions,
    isMobileView,
  ]);

  return (
    <CompletedAppointmentsContext.Provider
      value={{
        dateRange,
        setDateRange,

        page,
        setPage,

        outletIds,
        setOutletIds,

        createdByIds,
        setCreatedByIds,

        zoneIds,
        setZoneIds,

        areaIds,
        setAreaIds,

        statusOptions,
        setStatusOptions,

        bookingSourceOptions,
        setBookingSourceOptions,

        handleLoadMore,

        clearAllFilters,
      }}
    >
      {children}
    </CompletedAppointmentsContext.Provider>
  );
}

export { CompletedAppointmentsContextProvider };
