import { RootState } from "store";

import { SliceStatus } from "../utils";
import { addOnsAdapter } from "./addOnsSlice";

export const selectAddOnsLoading = (state: RootState) =>
  state.addOns.status === SliceStatus.LOADING;

export const selectAddOnsState = (state: RootState) => state.addOns;

const { selectById, selectAll } = addOnsAdapter.getSelectors(selectAddOnsState);

export const selectAllAddOns = (state: RootState) =>
  selectAll(state).sort((a, b) => (a?.value || 0) - (b?.value || 0));

export const selectAddOnById = (id: number | string) => (state: RootState) => selectById(state, id);
