import { List } from "@bookpeep/ui";
import { IconButton, TableCell, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

export const IconTableCell = styled(TableCell)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "4.187rem",
  },
  padding: 0,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  margin: 0,
  marginRight: theme.spacing(4),
  padding: 0,
  borderRadius: 2,
  cursor: "grab",
  "&:hover": {
    borderRadius: 2,
  },
}));

export const StyledListIcon = styled(
  List,
  dontForwardProps("fontSize", "width")
)(({ theme, fontSize = "64px", width = "30px" }) => ({
  fontSize,
  marginRight: theme.spacing(0.625),
  width,
}));
