import { Box, styled } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import { useEffect, useState } from "react";
import { useSelector } from "store";
import { selectClientById } from "store/clients/clientsSlice";
import http from "utils/http";

import { TopBookedCategories } from "./TopBookedCategories";
import { TopBookedStaff } from "./TopBookedStaff";
import { TopPurchasedProducts } from "./TopPurchasedProducts";

export type TopProduct = {
  name: string;
  totalPurchases: number;
  lastPurchasedDate: string;
};

export type TopStaff = {
  employee: string;
  lastBookedOn: string;
  topServices: string[];
};

export type TopCategory = {
  industryCategory: number;
  name: string;
  lastBookedOn: string;
  bookings: number;
};

type ClientOverviewData = {
  categories: TopCategory[];
  staff: TopStaff[];
  products: TopProduct[];
};

type ClientPerformanceProps = {
  clientId: number;
};

const ClientPerformance = ({ clientId }: ClientPerformanceProps) => {
  const [overviewData, setOverviewData] = useState<Nullable<ClientOverviewData>>(null);

  const client = useSelector(selectClientById(clientId));

  const { overviewLink = "" } = client || {};

  useEffect(() => {
    if (overviewLink && !overviewData)
      http.get(overviewLink).then((response) => setOverviewData(response.data));
  }, [overviewLink, overviewData]);

  return (
    <ClientPerformanceWrapper
      display="flex"
      justifyContent="space-between"
      p={3}
      flexWrap="wrap"
      gap={4}
    >
      {overviewData ? (
        <>
          <TopBookedCategories categories={overviewData?.categories || []} />
          <TopBookedStaff staff={overviewData?.staff || []} />
          <TopPurchasedProducts products={overviewData?.products || []} />
          <Box />
        </>
      ) : (
        <CentredSpinnerBox />
      )}
    </ClientPerformanceWrapper>
  );
};

const ClientPerformanceWrapper = styled(Box)(() => ({
  "& > div": {
    flex: "1 0 48%",
  },
}));

export default ClientPerformance;
