import { InputAdornment, TextField, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const PeepPriceField = ({
  name,
  label,
  onChange,
  value,
  error,
  helperText,
  placeholder,
  isPercentage,
  disabled = false,
  fullWidth = false,
  inputRef,
  type = "number",
  ...props
}) => {
  const { t } = useTranslation("translation");

  return (
    <StyledTextfield
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{isPercentage ? "%" : t("currency")}</InputAdornment>
        ),
      }}
      type={type}
      fullWidth={fullWidth}
      label={label}
      className="general-text-field"
      name={name}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      error={error}
      helperText={helperText}
      disabled={disabled}
      {...props}
      inputRef={inputRef}
    />
  );
};

export default PeepPriceField;

const StyledTextfield = styled(TextField)(({ theme }) => ({
  transition: "0.4s",
  "& .MuiInputAdornment-outlined": {
    borderRight: `1px solid ${theme.palette.border.lightGray}`,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#E6EFFD",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },

  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },

  p: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.text.placeholder,
  },
  input: {
    backgroundColor: "#FFFFFF",
  },
}));
