import { PageTabs } from "components/common";
import { useTranslation } from "react-i18next";
import { PurchasableItemOption } from "types/PurchasableItem";

import PurchasableItemsPackagesPanel from "./PurchasableItemsPackagesPanel";
import PurchasableItemsProductsPanel from "./PurchasableItemsProductsPanel";
import PurchasableItemsServicesPanel from "./PurchasableItemsServicesPanel";
import PurchasableItemsSubscriptionsPanel from "./PurchasableItemsSubscriptionsPanel";
import PurchasableItemsVouchersPanel from "./PurchasableItemsVouchersPanel";

type PurchasableItemsTabsProps = {
  tabsToHide?: PurchasableItemOption[];
};

const PurchasableItemsTabs = ({ tabsToHide }: PurchasableItemsTabsProps) => {
  const { t } = useTranslation(["offers", "services", "inventory", "subscriptions", "vouchers"]);

  const tabs = [
    {
      label: t("services:services"),
      component: <PurchasableItemsServicesPanel />,
      type: PurchasableItemOption.Service,
    },
    {
      label: t("offers:packages"),
      component: <PurchasableItemsPackagesPanel />,
      type: PurchasableItemOption.Package,
    },
    {
      label: t("inventory:products"),
      component: <PurchasableItemsProductsPanel />,
      type: PurchasableItemOption.Product,
    },
    {
      label: t("subscriptions:subscriptions"),
      component: <PurchasableItemsSubscriptionsPanel />,
      type: PurchasableItemOption.Subscription,
    },
    {
      label: t("vouchers:vouchers"),
      component: <PurchasableItemsVouchersPanel />,
      type: PurchasableItemOption.Voucher,
    },
  ];

  const filteredTabs = tabs
    .filter((tab) => !tabsToHide?.includes(tab.type))
    .map((tab, index) => ({ id: index, ...tab }));

  return (
    <PageTabs
      tabs={filteredTabs}
      withRoutes={false}
      withContainer={false}
      tabsProps={{
        allowScrollButtonsMobile: true,
        scrollButtons: "auto",
      }}
    />
  );
};

export default PurchasableItemsTabs;
