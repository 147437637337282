import { PhoneNumberUtil } from "google-libphonenumber";
import { countries } from "resources";

/**
 * creating a custom yup test method to validate phoneNumber,
 * NOTE: the form schema should have a field called countryCode for this to work
 *
 * @param {string} message - a string which will be used as an error message if the test failed
 * @return returns a test function which returns a boolean or an error
 *
 * Example:
 *
 * yup.addMethod(yup.mixed,"isValidPhoneNumberForRegion", isValidPhoneNumberForRegion);
 */

function isValidPhoneNumberForRegion(message) {
  return this.test("isValidPhoneNumberForRegion", message, function (value) {
    const { path, createError } = this;

    if (value) {
      const phoneUtil = PhoneNumberUtil.getInstance();

      const countryCode = countries.find(
        (country) =>
          country.prefix === (this.parent.countryCode.replace("+", "") || "965")
      ); // if countryCode is undefine for any possessed reason

      // if the value is not a valid number the parsing will explode and ruin the validation of all the fields
      try {
        //  we need to parse phone number value to be able to use phoneUtil functions
        const mobileNumber = phoneUtil.parse(
          `+${countryCode.prefix}${value}`,
          countryCode.code
        );

        const isPossibleMobileNumber = phoneUtil.isPossibleNumber(mobileNumber);

        const isValidMobileNumber = phoneUtil.isValidNumber(mobileNumber);

        if (!isPossibleMobileNumber || !isValidMobileNumber) {
          return createError({
            path,
            message: message ?? "Invalid phone number",
          });
        }
      } catch {
        return createError({
          path,
          message: message ?? "Invalid phone number",
        });
      }
      return true;
    }
    return true;
  });
}

export default isValidPhoneNumberForRegion;
