import { Grid } from "@mui/material";
import LoyaltyCardRewardChip from "components/common/Chips/LoyaltyCardRewardChip";
import { useSelector } from "store";
import { selectAppointmentLoyaltyCardByAppointmentIdAndLoyaltyCardId } from "store/appointments/appointmentsSelectors";
import { AppointmentWithArticleDetails } from "types/Appointment";

import FlexServiceItem from "./FlexServiceItem";
import PackageItem from "./PackageItem";
import ServiceItem from "./ServiceItem";

type AppointmentArticleProps = {
  article: AppointmentWithArticleDetails["articles"][0];
  appointmentId: AppointmentWithArticleDetails["id"];
};

const AppointmentArticle = ({ article, appointmentId }: AppointmentArticleProps) => {
  const hasUsedLoyaltyReward = !!article.rewardLoyaltyCard;

  const rewardLoyaltyCardDetails = useSelector(
    selectAppointmentLoyaltyCardByAppointmentIdAndLoyaltyCardId(
      appointmentId || 0,
      article.rewardLoyaltyCard || 0
    )
  );

  return (
    <Grid item container md={12}>
      {article?.packageVariant ? (
        <Grid item container justifyContent="space-between" alignItems="center" md={12}>
          <PackageItem article={article} />
        </Grid>
      ) : article?.serviceVariant ? (
        <Grid item container justifyContent="space-between" alignItems="center" md={12} mb={2}>
          {article?.slots.map((slot, idx) => {
            return <ServiceItem slot={slot} article={article} key={idx} />;
          })}
        </Grid>
      ) : (
        <Grid item container justifyContent="space-between" alignItems="center" md={12} mb={2}>
          {article?.slots.map((slot, idx) => {
            return <FlexServiceItem slot={slot} article={article} key={idx} />;
          })}
        </Grid>
      )}

      {hasUsedLoyaltyReward && rewardLoyaltyCardDetails && (
        <Grid item xs={12} pt={1}>
          <LoyaltyCardRewardChip programName={rewardLoyaltyCardDetails.name} />
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentArticle;
