import { Typography } from "@mui/material";
import { getInvoiceStatusColor } from "helpers/styles";
import { useTranslation } from "react-i18next";
import { InvoiceStatus } from "types/Checkout";
import { InvoiceType } from "types/Invoice";

import StatusChip from "./StatusChip";

type InvoiceStatusChipProps = {
  status: InvoiceStatus;
  type: InvoiceType;
};

const InvoiceStatusChip = ({ status, type }: InvoiceStatusChipProps) => {
  const { t } = useTranslation("sales");

  if (type === InvoiceType.Refund && status.toUpperCase() !== InvoiceStatus.Void)
    return (
      <Typography fontSize="0.75rem" color="error">
        {t("refundInvoice")}
      </Typography>
    );

  return <StatusChip label={t(status.toLowerCase())} statusColor={getInvoiceStatusColor(status)} />;
};

export default InvoiceStatusChip;
