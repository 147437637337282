import { PurchasableItemOption } from "types/PurchasableItem";

import PurchasableItemsSelectorProvider from "./PurchasableItemsSelectorContext";
import type { PurchasableItemsSelectorContextValues } from "./PurchasableItemsSelectorContext";
import PurchasableItemsTabs from "./PurchasableItemsTabs";

export type PurchasableItemsSelectorProps = PurchasableItemsSelectorContextValues & {
  tabsToHide?: PurchasableItemOption[];
};

const PurchasableItemsSelector = ({
  tabsToHide,
  onSelectItem,

  shouldHighlightSelectedItems = false,
  shouldAllowSelectAllCategoryItems = false,
  onSelectAllCategoryItems,
  selectedItems,

  clientId = null,
  outletId = null,

  shouldShowPromotionTag = false,
  shouldDisableLoyaltyIneligibleItems = false,
}: PurchasableItemsSelectorProps) => {
  return (
    <PurchasableItemsSelectorProvider
      values={{
        onSelectItem,
        shouldHighlightSelectedItems,
        shouldAllowSelectAllCategoryItems,
        onSelectAllCategoryItems,
        selectedItems,

        clientId,
        outletId,

        shouldShowPromotionTag,
        shouldDisableLoyaltyIneligibleItems,
      }}
    >
      <PurchasableItemsTabs tabsToHide={tabsToHide} />
    </PurchasableItemsSelectorProvider>
  );
};

export default PurchasableItemsSelector;
