import { Button, Stack, Typography } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { Invoice, InvoiceLogAction, InvoiceStatus, InvoiceType } from "types/Checkout";
import { PeepPayLinkStatus } from "types/Deposit";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import PeepPayPaymentStatusChip from "./PeepPayPaymentStatusChip";

type InvoiceStatusDetailsSectionProps = {
  invoiceBalanceBasedOnPayments: number;
  invoice: Invoice;
};

const InvoiceStatusDetailsSection = ({
  invoiceBalanceBasedOnPayments,
  invoice,
}: InvoiceStatusDetailsSectionProps) => {
  const { t } = useTranslation("invoices");

  const { setCheckoutModalView } = useCheckoutModal();

  const { originalInvoiceId, refundInvoiceId } = useCheckoutFormContext();

  const originalInvoice = useSelector(selectInvoiceById(originalInvoiceId || -1));

  const refundInvoice = useSelector(selectInvoiceById(refundInvoiceId || -1));

  const { hasCheckout } = useSelector(selectAllPermissions);

  const isInvoiceFullyPaid = invoiceBalanceBasedOnPayments === 0;

  const firstPendingPeepPayment = invoice.pendingPayments?.[0];

  const getConfirmText = () => {
    const invoiceTotal = parseFloat(invoice.total);
    const invoiceBalance = parseFloat(invoice.balance);

    if (invoice.status === InvoiceStatus.Void) return t("void");
    if (invoiceBalance === invoiceTotal && invoiceTotal !== 0) return t("unpaid");
    else if (invoiceBalance > 0 && invoiceBalance < invoiceTotal) return t("partialPayment");
    else if (
      invoiceBalance === 0 &&
      invoice.type === InvoiceType.Sale &&
      invoice.status !== InvoiceStatus.Refunded
    )
      return t("completed");
    else if (
      invoice.type === InvoiceType.Refund ||
      (invoice.type === InvoiceType.Sale && invoice.status === InvoiceStatus.Refunded)
    ) {
      const originalInvoiceArticlesCount = originalInvoice?.articles?.length || 0;
      const refundInvoiceArticlesCount = refundInvoice?.articles?.length || 0;

      const isFullyRefunded = originalInvoiceArticlesCount === refundInvoiceArticlesCount;

      return isFullyRefunded ? t("fullyRefunded") : t("partiallyRefunded");
    } else if (invoice.status === InvoiceStatus.Refunded) return t("refunded");
    else return "";
  };

  const canCheckout = hasCheckout.editAccess;

  const showPayButton = () => {
    let balance = parseFloat(invoice.balance);
    const total = parseFloat(invoice.total);

    if (firstPendingPeepPayment && firstPendingPeepPayment.status === PeepPayLinkStatus.Pending) {
      balance -= parseFloat(firstPendingPeepPayment.amount);
    }
    //If invoice refunded or void
    if (invoice.status === InvoiceStatus.Void || invoice.status === InvoiceStatus.Refunded) {
      return false;
    }

    //If invoice total is not 0 and full balance not paid
    else if (balance === total && balance !== 0) {
      return true;
    }

    // if full balance less than total and more than 0 "Partially Paid"
    else if (balance < total && balance > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getDescriptionText = () => {
    const balance = parseFloat(invoice.balance);

    const lastPayment = invoice.payments.length
      ? invoice.payments[invoice.payments.length - 1]
      : {
          createdAt: invoice.createdAt,
          createdBy: invoice.createdBy,
        };

    if (invoice.status === InvoiceStatus.Void) {
      const voidInvoiceLog = invoice.logs.find((log) => log.action === InvoiceLogAction.VOID);

      if (voidInvoiceLog) {
        return (
          <Trans
            i18nKey="invoices:voidedOn"
            values={{
              number: invoice.number,
              createdBy: voidInvoiceLog.createdBy,
              createdAt: DateTime.fromISO(voidInvoiceLog.createdAt).toFormat(
                "EEEE, dd MMMM yyyy hh:mm a"
              ),
            }}
            components={[
              <Typography
                key="0"
                display="inline"
                variant="h5"
                fontSize="0.875rem"
                fontWeight={600}
                color="primary"
              />,
              <Typography
                key="1"
                display="inline"
                variant="primary"
                fontSize="0.875rem"
                fontWeight={600}
                color="text.primary"
              />,
            ]}
          />
        );
      } else {
        return "";
      }
    } else if (
      balance === 0 &&
      invoice.type === InvoiceType.Sale &&
      invoice.status !== InvoiceStatus.Refunded &&
      invoice.status !== InvoiceStatus.PartiallyRefunded
    ) {
      return (
        <Trans
          i18nKey="invoices:fullPaymentReceived"
          values={{
            createdBy: lastPayment.createdBy,
            createdAt: DateTime.fromISO(lastPayment.createdAt).toFormat(
              "EEEE, dd MMMM yyyy hh:mm a"
            ),
          }}
          components={[
            <Typography
              key="0"
              display="inline"
              variant="primary"
              fontSize="0.875rem"
              fontWeight={600}
              color="primary"
            />,
            <Typography
              key="1"
              display="inline"
              variant="primary"
              fontSize="0.875rem"
              fontWeight={600}
              color="text.primary"
            />,
          ]}
        />
      );
    } else if (
      (invoice.type === InvoiceType.Refund ||
        invoice.status === InvoiceStatus.Refunded ||
        invoice.status === InvoiceStatus.PartiallyRefunded) &&
      refundInvoice
    ) {
      return (
        <Trans
          i18nKey="invoices:lastUpdated"
          values={{
            createdBy: refundInvoice.createdBy,
            createdAt: DateTime.fromISO(refundInvoice?.updatedAt).toFormat(
              "EEEE, dd MMMM yyyy hh:mm a"
            ),
          }}
          components={[
            <Typography
              key="0"
              display="inline"
              variant="primary"
              fontSize="0.875rem"
              fontWeight={600}
              color="primary"
            />,
            <Typography
              key="1"
              display="inline"
              variant="primary"
              fontSize="0.875rem"
              fontWeight={600}
              color="text.primary"
            />,
          ]}
        />
      );
    } else return "";
  };

  const shouldShowInvoiceCompletedIcon =
    isInvoiceFullyPaid && invoice.status === InvoiceStatus.Paid;

  const handleAddPayment = () => setCheckoutModalView(CheckoutModalView.InvoiceAddPayments);

  const shouldShowPeepPayPaymentStatusChip = invoice.pendingPayments?.some(
    (pendingPayment) =>
      Boolean(pendingPayment.peepPaymentInvoice) &&
      pendingPayment?.status &&
      pendingPayment.status !== PeepPayLinkStatus.Canceled
  );

  return (
    <Stack alignItems="center" justifyContent="flex-start" spacing={3} flexGrow={1}>
      <img
        src={shouldShowInvoiceCompletedIcon ? "/images/check-mark.svg" : "/images/invoice-icon.svg"}
        width="56px"
        height="54px"
        alt="invoice"
      />

      <Typography align="center" variant="h3">
        {getConfirmText()}
      </Typography>

      {getDescriptionText() && (
        <Typography
          variant="primary"
          fontSize="0.875rem"
          fontWeight={400}
          color="text.label"
          align="center"
        >
          {getDescriptionText()}
        </Typography>
      )}

      {shouldShowPeepPayPaymentStatusChip && firstPendingPeepPayment && invoice.client && (
        <PeepPayPaymentStatusChip pendingPeepPayment={firstPendingPeepPayment} />
      )}

      {canCheckout && showPayButton() && (
        <Button onClick={handleAddPayment} fullWidth>
          {t("payNow")}
        </Button>
      )}
    </Stack>
  );
};

export default InvoiceStatusDetailsSection;
