import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const onlinePaymentsAdapter = createEntityAdapter();

const initialState = onlinePaymentsAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = onlinePaymentsAdapter.getSelectors(
  (state) => state.onlinePayments
);

export const selectAllOnlinePayments = selectAll;

export const selectOnlinePaymentById = (id) => (state) => selectById(state, id);

export const selectOnlinePaymentsByAppointmentId = (id) => (state) => {
  const onlinePayments = selectAllOnlinePayments(state);

  return onlinePayments.filter((onlinePayment) => onlinePayment.appointment === id);
};

export const onlinePaymentsSlice = createSlice({
  name: "onlinePayments",
  initialState,
  reducers: {
    upsertOnlinePayments(state, { payload }) {
      onlinePaymentsAdapter.upsertMany(state, payload);
    },
  },
  extraReducers: {},
});
export const { upsertOnlinePayments } = onlinePaymentsSlice.actions;

export default onlinePaymentsSlice.reducer;
