import {
  InputAdornment,
  TextField as MuiTextField,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  styled,
} from "@mui/material";
import { dontForwardProps } from "helpers/styles";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const PeepTimeField = ({ value = DateTime.now(), onChange, error, helperText, ...props }) => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState(""); // to store am or pm
  const [time, setTime] = useState(""); // to store hours and minutes

  const [invalidTimeMessage, setInvalidTimeMessage] = useState("");

  useEffect(() => {
    // setting the values of period and time based on value prop which is a luxon dateTime instance
    const valueTime = value ? value.toFormat("hhmm") : "";
    const valuePeriod = value ? value.toFormat("a") : "";

    setTime(valueTime);
    setPeriod(valuePeriod);
  }, [setTime, setPeriod, value]);

  useEffect(() => {
    // Creates a DateTime from an input string and format string
    if (!time || !period) {
      setInvalidTimeMessage("");
    } else {
      const dt = DateTime.fromFormat(time + period, "hhmma");

      const isInvalidTime = dt.invalidReason;

      setInvalidTimeMessage(isInvalidTime ? t("invalidTimeMessage") : "");
    }
  }, [time, period, onChange, setInvalidTimeMessage, t]);

  const handleDoneEditing = (newPeriod) => {
    // it should be 00 to 23
    const parsedTime = parseInt(time);
    const isValidTimeString = parsedTime >= 0 && parsedTime <= 23;

    const isAcceptableHoursOnlyPattern = time.length < 3 && isValidTimeString;

    const formattedHours = isAcceptableHoursOnlyPattern && time.length === 1 ? `0${time}` : time;

    const formattedTime = isAcceptableHoursOnlyPattern ? `${formattedHours}00` : time;

    const dt = DateTime.fromFormat(formattedTime + newPeriod, "hhmma");

    const isInvalidTime = dt.invalidReason;

    if (!isInvalidTime && dt.toMillis() !== value.toMillis()) onChange(dt);
  };

  const handleTimeChange = (event) => {
    const newValue = event.target.value;
    // remove the colon ":" from current value to format the time from hh:mm to hhmm
    const trimmedValue = newValue.replace(":", "");

    // only accepting four digits, so any character that is not a digit will be ignored
    const isAcceptableTimePattern = trimmedValue.match(/^\d{0,4}$/);

    if (isAcceptableTimePattern) setTime(trimmedValue);
  };

  // formats the time to hh:mm format to be displayed on the TextField
  const formattedTime = useMemo(() => {
    if (time.length < 3) return time;
    else return `${time.substring(0, 2)}:${time.substring(2)}`;
  }, [time]);

  const handlePeriodChange = (_event, newPeriod) => {
    // NOTE! we need this condition because the way MUI is built is absurd, when you double click on the selected value it will unselect it and the newPeriod would be null..
    // therefore, we need this condition, to ensure that we'll always have a selected period and when you double click on the selected period it will not be unselected

    if (newPeriod) {
      setPeriod(newPeriod);
      handleDoneEditing(newPeriod);
    }
  };

  const handleBlur = () => {
    handleDoneEditing(period);
  };

  return (
    <TextField
      value={formattedTime}
      onChange={handleTimeChange}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ToggleButtonGroup
              value={period}
              exclusive
              onChange={handlePeriodChange}
              size="large"
              disabled={props.disabled}
            >
              <ToggleButton value="AM">{t("am")}</ToggleButton>
              <ToggleButton value="PM" isRightButton>
                {t("pm")}
              </ToggleButton>
            </ToggleButtonGroup>
          </InputAdornment>
        ),
      }}
      error={error || !!invalidTimeMessage}
      helperText={helperText || invalidTimeMessage}
      {...props}
    />
  );
};

export default PeepTimeField;

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    padding: 0,
  },

  "& .Mui-error": {
    color: theme.palette.error.main,
    "& > fieldset, & *": {
      borderColor: theme.palette.error.main + "!important",
    },
  },
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  maxHeight: theme.spacing(6),

  backgroundColor: theme.palette.bg.secondary,
}));

const ToggleButton = styled(
  MuiToggleButton,
  dontForwardProps("isRightButton")
)(({ theme, isRightButton }) => ({
  borderRadius: isRightButton ? theme.spacing(0, 1, 1, 0) : theme.spacing(0),
  borderColor: "#4048D666",
  color: theme.palette.text.placeholder,
  textTransform: "uppercase",

  "&.MuiToggleButton-root.Mui-selected": {
    borderLeftColor: "#4048D666",
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.primary.main,
  },
}));
