import { RedeemableSubscriptionItemOption } from "types/Subscription";

/**
 * This type guard is used to check if a purchasable item type can have a subscription redemption or not.
 * @param purchaseableItemType
 * @returns
 */

export function isRedeemableSubscriptionItemOption(
  option: unknown
): option is RedeemableSubscriptionItemOption {
  return Boolean(option);
}
