import humanizeDayDuration from "helpers/humanizeDayDuration";
import { useTranslation } from "react-i18next";
import { PurchasableItemOption } from "types/PurchasableItem";
import { Subscription } from "types/Subscription";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItem from "../PurchasableItem";

type SubscriptionsPurchasableItemsProps = {
  subscriptions: Subscription[];
};

const SubscriptionsPurchasableItems = ({ subscriptions }: SubscriptionsPurchasableItemsProps) => {
  const { t } = useTranslation("checkout");

  const { onSelectItem, selectedItems, shouldHighlightSelectedItems } =
    usePurchasableItemsSelector();

  const selectedSubscriptionIds = selectedItems?.[PurchasableItemOption.Subscription] || [];

  return (
    <>
      {subscriptions.map((subscription) => {
        const subscriptionSubtitle = `${subscription.itemName} x ${subscription.count}, ${t(
          "expiresIn"
        )} - ${humanizeDayDuration(subscription.validityInDays)} `;

        const shouldHighlight =
          shouldHighlightSelectedItems && selectedSubscriptionIds.includes(subscription.id);

        return (
          <PurchasableItem
            key={`Subscription-${subscription.id}-${subscription.name}`}
            isHighlighted={shouldHighlight}
            title={subscription.name}
            subtitle={subscriptionSubtitle}
            price={subscription.price}
            handleClick={() => onSelectItem(subscription, PurchasableItemOption.Subscription)}
          />
        );
      })}
    </>
  );
};

export default SubscriptionsPurchasableItems;
