import { RootState } from "store";

import { SliceStatus } from "../utils";
import { clientScalpTypesAdapter } from "./clientScalpTypesSlice";

export const selectClientScalpTypesLoading = (state: RootState) =>
  state.clientScalpTypes.status === SliceStatus.LOADING;

export const selectClientScalpTypesState = (state: RootState) => state.clientScalpTypes;

const { selectAll } = clientScalpTypesAdapter.getSelectors(selectClientScalpTypesState);

export const selectAllClientScalpTypes = selectAll;
