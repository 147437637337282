import { Box, Typography, TypographyProps } from "@mui/material";
import getFormattedDuration from "helpers/getFormattedDuration";
import usePromotionDiscountSummary from "hooks/usePromotionDiscountSummary";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { InvoiceArticle } from "types/Checkout";
import { InvoicePurchasableItemType } from "types/Invoice";

type InvoiceArticleItemDescriptionProps = {
  article: InvoiceArticle;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
  showDuration?: boolean;
};

// ⚠️ WARNING: This component is used for both invoice and refund invoice, modify with caution 🍇 -Anfal The Greatest

const InvoiceArticleItemDescription = ({
  article,
  titleProps,
  subtitleProps,
  showDuration = false,
}: InvoiceArticleItemDescriptionProps) => {
  const { t } = useTranslation(["checkout"]);

  const getArticleSubtitle = () => {
    switch (article.purchasedItem.purchasableType) {
      case InvoicePurchasableItemType.Service:
        return "";
      case InvoicePurchasableItemType.Package:
        return `${article.noOfServices} ${t("checkout:services")}`;
      case InvoicePurchasableItemType.Product:
      case InvoicePurchasableItemType.Subscription:
        return article.articleSecondaryName;
      case InvoicePurchasableItemType.Voucher:
        return `${t("checkout:expiresOn", {
          date: DateTime.fromISO(article.articleSecondaryName).toFormat("dd MMM yyyy"),
        })}`;
      default:
        return "";
    }
  };

  const getAdditionalDetails = () => {
    switch (article.purchasedItem.purchasableType) {
      case InvoicePurchasableItemType.Service:
        return article.details.map((detail) => (
          <AdditionalDetails key={detail.id} {...subtitleProps}>
            {`${detail.name}, ${t("checkout:with")} ${detail.employee} ${
              showDuration ? `, ${getFormattedDuration(detail?.duration)}` : ""
            }`}
          </AdditionalDetails>
        ));

      case InvoicePurchasableItemType.Package:
        return article.details.map((detail, index) => (
          <AdditionalDetails key={detail.id} {...subtitleProps}>
            {`${detail.name}, ${t("checkout:with")} ${article.details[index].employee} ${
              showDuration ? `, ${getFormattedDuration(article?.details[index].duration)}` : ""
            }`}
          </AdditionalDetails>
        ));

      default:
        return null;
    }
  };

  const { getPromotionDiscountSummary } = usePromotionDiscountSummary();

  const promotionDiscountSummary = getPromotionDiscountSummary(
    article?.promotionDiscountType,
    Number(article.promotionDiscountValue)
  );

  const hasPromotion = article?.promotion;
  const promotionDetails =
    article?.promotionName && promotionDiscountSummary
      ? `${article?.promotionName} (${promotionDiscountSummary})`
      : "promotion name not found";

  return (
    <>
      <Typography variant="h5" fontWeight={700} {...titleProps}>
        {article.articleName}
      </Typography>

      <Typography variant="primary" color="text.label" fontWeight={700} {...subtitleProps}>
        {getArticleSubtitle()}
      </Typography>

      {getAdditionalDetails()}

      {hasPromotion && (
        <Typography
          pt={0.5}
          variant="primary"
          fontWeight={700}
          color="text.secondary"
          display="block"
        >
          {promotionDetails}
        </Typography>
      )}

      {article.subscriptionRedemption && (
        <Box display="flex" flexDirection="column" mt={1}>
          <Typography variant="primary" fontWeight={700} color="text.secondary">
            {article.subscriptionRedemption.name}
          </Typography>

          <Typography variant="primary" color="text.label" fontWeight={700}>
            {t("checkout:creditsLeft", { count: article.subscriptionRedemption.credits })}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default InvoiceArticleItemDescription;

const AdditionalDetails = (props: TypographyProps) => (
  <Typography variant="primary" color="text.label" display="block" fontWeight={700} {...props} />
);
