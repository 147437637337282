import { UserReading } from "@bookpeep/ui";
import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AddPurchasableItemsHeader = () => {
  const { t } = useTranslation("checkout");

  return (
    <>
      <Box display="flex" alignItems="center" p={3}>
        <UserReading sx={{ mr: 1 }} />

        <Typography variant="h2">{t("addItems")}</Typography>
      </Box>

      <Divider variant="fullWidth" />
    </>
  );
};

export default AddPurchasableItemsHeader;
