import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllAreas, selectAreasAreLoading } from "store/slices/areas/areasSelector";
import { selectAllActiveZones } from "store/slices/zones/zonesSelectors";
import { Area } from "types/Area";

import { SearchableSelectField } from "./common";
import AreaStatusChip from "./common/AreaStatusChip";
import { Option, SearchableSelectFieldProps } from "./common/SearchableSelectField";

export type AreaAutocompleteAdditionalProps = {
  shouldGroupOptionsByZone?: boolean;
  shouldShowOnlyAreasWithZone?: boolean;
  shouldShowAnotherZoneChip?: boolean;
  selectedZoneId?: Nullable<number>;
};
export type AreaAutocompleteProps = Optional<SearchableSelectFieldProps, "options"> &
  AreaAutocompleteAdditionalProps;

const AreaAutocomplete = ({
  selectedZoneId,
  shouldGroupOptionsByZone,
  shouldShowAnotherZoneChip,
  shouldShowOnlyAreasWithZone = false,
  ...searchableSelectFieldProps
}: AreaAutocompleteProps) => {
  const { t } = useTranslation("appointments");

  const { value } = searchableSelectFieldProps;

  const areas = useSelector(selectAllAreas);
  const zones = useSelector(selectAllActiveZones);

  const areAreasLoading = useSelector(selectAreasAreLoading);

  const getAreaZoneName = (area: Area) => {
    return zones.find((zone) => zone.id === area.zone)?.name;
  };

  const getGroupByValue = (area: Area) => {
    if (shouldGroupOptionsByZone) {
      return getAreaZoneName(area) || t("areasWithUnassignedZone");
    }

    return undefined;
  };

  const selectedArea = areas.find((area) => area.id === value);

  const checkIsAreaWithAnotherZoneThanSelectedArea = (area: Area) => {
    return (
      (shouldShowAnotherZoneChip &&
        !!area.zone &&
        selectedZoneId &&
        area.zone !== selectedZoneId) ||
      false
    );
  };

  const getAreaStatusChip = (area: Area) => {
    if (shouldShowOnlyAreasWithZone && !area.zone) return <AreaStatusChip status="unsupported" />;

    if (checkIsAreaWithAnotherZoneThanSelectedArea(area))
      return <AreaStatusChip status="anotherZone" />;

    return null;
  };

  const options: Option[] = areas.map((area) => ({
    value: area.id,
    label: area.name,
    groupBy: getGroupByValue(area),
    isDisabled:
      (shouldShowOnlyAreasWithZone && !area.zone) ||
      checkIsAreaWithAnotherZoneThanSelectedArea(area),
    additionalInfo: getAreaStatusChip(area),
  }));

  // sort options by not disabled first
  options.sort((a, b) => {
    if (a.isDisabled && !b.isDisabled) return 1;
    if (!a.isDisabled && b.isDisabled) return -1;
    return 0;
  });

  // sort options if they are grouped by zone, so that the ones that are unassigned are at the end of the list
  if (shouldGroupOptionsByZone) {
    options.sort((a, b) => {
      if (a.groupBy === t("areasWithUnassignedZone")) return 1;
      if (b.groupBy === t("areasWithUnassignedZone")) return -1;
      return 0;
    });
  }

  const shouldApplyGroupBy = shouldGroupOptionsByZone;

  const handleGroupBy = (option: Option) => {
    return option.groupBy || "";
  };

  return (
    <SearchableSelectField
      disablePortal={false}
      {...searchableSelectFieldProps}
      value={selectedArea?.id || null}
      options={options}
      areOptionsLoading={areAreasLoading}
      groupBy={shouldApplyGroupBy ? handleGroupBy : undefined}
    />
  );
};

export default AreaAutocomplete;
