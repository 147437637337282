import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { packageCategoryAdapter } from "./packageCategoriesSlice";

const { selectById, selectAll } = packageCategoryAdapter.getSelectors(
  (state: RootState) => state.packageCategoriesV2
);

export const selectAllPackageCategories = selectAll;

export const selectPackageCategoryById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectPackageCategoriesLoading = (state: RootState) =>
  state.packageCategoriesV2.status === SliceStatus.LOADING;