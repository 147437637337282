import { Box, Grid, Stack, Typography } from "@mui/material";
import { ItemPrice } from "components/common";
import InvoiceStatusChip from "components/common/InvoiceStatusChip";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { ClientInvoice } from "types/Client";

type ClientInvoicesHistoryItemProps = {
  invoice: ClientInvoice;
};

const ClientInvoicesHistoryItem = ({ invoice }: ClientInvoicesHistoryItemProps) => {
  const { t } = useTranslation(["translation", "appointments"]);

  const { createdAt, number, status, total, outlet } = invoice;

  const formattedDate = DateTime.fromISO(createdAt).toFormat("EEEE, dd MMMM yyyy, h:mm a");

  return (
    <Grid container spacing={2} px={3}>
      <Grid item md={9} xs={12}>
        <Typography variant="h3" display="block">
          {`${t("appointments:invoice")} #${number}`}
        </Typography>
        <Stack gap={2}>
          <Typography variant="primary" color="text.label">
            {formattedDate}
          </Typography>

          <Typography variant="h5" color="text.label">
            {outlet.name}
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={3} xs={12} flexGrow={1}>
        <Stack gap={1} flexGrow={1}>
          <Box textAlign="center">
            <ItemPrice
              originalPrice={Number(total)}
              finalPrice={Number(total)}
              alignItems="center"
              finalPriceProps={{
                variant: "h3",
                fontWeight: 500,
                color: "primary",
              }}
            />

            <Typography variant="h3">{t("total")}</Typography>
          </Box>

          <InvoiceStatusChip status={status} type={invoice.type} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ClientInvoicesHistoryItem;
