import { AddPlus } from "@bookpeep/ui";
import { Box, Button, Typography, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type CheckoutPaymentMethodProps = {
  title: string | ReactNode;
  subtitle?: string;

  endIcon?: ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  shouldShowApply?: boolean;

  handleClick: () => void;
};

// ⚡️ Warning this component is used in Refund modal too, be careful when changing it -Anfal
const CheckoutPaymentMethod = ({
  title,
  subtitle = "",

  endIcon: icon,
  isSelected = false,
  disabled = false,
  shouldShowApply = true,

  handleClick,
}: CheckoutPaymentMethodProps) => {
  const { t } = useTranslation("checkout");

  return (
    <PaymentMethodButton
      variant="outlined"
      disabled={disabled}
      onClick={handleClick}
      fullWidth
      isSelected={isSelected}
      endIcon={
        isSelected && icon
          ? icon
          : shouldShowApply && (
              <Box display="flex" flexDirection="row" alignItems="center">
                <AddPlus />
                <Typography color="primary" pl={0.5}>
                  {t("apply")}
                </Typography>
              </Box>
            )
      }
    >
      <Box display="flex" gap={1} alignItems="center">
        <Typography variant="primary" fontWeight={700} align="left" color="secondary">
          {title}
        </Typography>

        {subtitle && (
          <Typography variant="primary" align="left" color="primary">
            {subtitle}
          </Typography>
        )}
      </Box>
    </PaymentMethodButton>
  );
};

export default CheckoutPaymentMethod;

type PaymentMethodButtonProps = {
  isSelected?: boolean;
};

const PaymentMethodButton = styled(
  Button,
  dontForwardProps("isSelected")
)<PaymentMethodButtonProps>(({ theme, isSelected }) => ({
  borderColor: "#B3B6EF",
  backgroundColor: theme.palette.bg.blank,

  justifyContent: "space-between",

  height: "56px",
  padding: theme.spacing(2),

  "& .MuiButton-endIcon": {
    display: "none",
  },

  "&:hover > .MuiButton-endIcon": {
    display: "inherit",
  },

  ...(isSelected && {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.bg.secondary,

    "& .MuiButton-endIcon": {
      display: "inherit",
    },
  }),
}));
