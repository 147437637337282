import { Box, Drawer, DrawerProps } from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { dontForwardProps } from "helpers/styles";

export const NAV_DRAWER_WIDTH = 248;

type DrawerWrapperProps = {
  children: React.ReactNode;
  toggleSideNavMenu: () => void;
  isSideNavMenuOpen: boolean;
};

function DrawerWrapper({ children, toggleSideNavMenu, isSideNavMenuOpen }: DrawerWrapperProps) {
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav">
      <SideNavMenuDrawerMobile
        container={container}
        variant="temporary"
        open={isSideNavMenuOpen}
        onClose={toggleSideNavMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
        }}
      >
        {children}
      </SideNavMenuDrawerMobile>

      <SideNavMenuDrawerDesktop
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
        }}
        open={isSideNavMenuOpen}
        onClose={toggleSideNavMenu}
      >
        {children}
      </SideNavMenuDrawerDesktop>
    </Box>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.main,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
  width: `calc(${theme.spacing(9)} + 1px)`,
  backgroundColor: theme.palette.primary.main,
});

const SideNavMenuDrawerDesktop = styled(
  Drawer,
  dontForwardProps("open")
)<DrawerProps & { open: boolean }>(({ theme, open }) => ({
  width: NAV_DRAWER_WIDTH,
  position: "relative",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNavMenuDrawerMobile = styled(Drawer)(({ theme }) => ({
  ".MuiPaper-root": {
    width: NAV_DRAWER_WIDTH,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default DrawerWrapper;
