import { createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./organizationApi";

const initialState = {
  isLoading: false,
  isRequestPending: false,
  error: "",
};

export const getOrganization = createThunkFromApi(
  "organization/getOrganization",
  api.getOrganization
);

export const createOrganization = createThunkFromApi(
  "organization/createOrganization",
  api.createOrganization
);

export const updateOrganization = createThunkFromApiWithType(
  "organization/updateOrganization",
  api.updateOrganization
);

export const deleteOrganization = createThunkFromApi(
  "organization/removeOrganization",
  api.removeOrganization
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState,

  extraReducers: (reducers) => {
    reducers
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getOrganization.fulfilled, (_, { payload }) => {
        const organization = payload;

        return {
          ...organization,
          isLoading: false,
          error: "",
        };
      })

      .addCase(getOrganization.rejected, (state, { payload }) => {
        return {
          ...state,
          error: payload?.detail,
        };
      })

      .addCase(createOrganization.fulfilled, (state, { payload }) => {
        const organization = payload;

        return {
          ...state,
          ...organization,
        };
      })

      .addCase(updateOrganization.pending, (state) => {
        state.isRequestPending = true;
      })
      
      .addCase(updateOrganization.fulfilled, (state, { payload }) => {
        const organization = payload;
        const modifiedState = { ...state, isRequestPending: false };

        return {
          ...modifiedState,
          ...organization,
        };
      })

      .addCase(updateOrganization.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(deleteOrganization.fulfilled, (state) => {
        delete state.organization;
        return state;
      });
  },
});

export const selectOrganization = ({ organization }) => organization;
export const selectOrganizationLoading = (state) => state.organization.isLoading;

export default organizationSlice.reducer;
