import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./employeesShiftsApi";

const ShiftsAdapter = createEntityAdapter();

const initialState = ShiftsAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = ShiftsAdapter.getSelectors((state) => state.shifts);

export const selectAllShifts = selectAll;

export const selectShiftById = (id) => (state) => selectById(state, id);

export const getAllShifts = createThunkFromApi("shifts/getAllShifts", api.getAllShifts);

export const getShift = createThunkFromApi("shifts/getShift", api.getShift);

export const createShift = createThunkFromApi("shifts/createShift", api.createShift);

export const updateShift = createThunkFromApi("shifts/updateShift", api.updateShift);

export const deleteShift = createThunkFromApi("shifts/removeShift", api.removeShift);

export const employeesShiftsSlice = createSlice({
  name: "shifts",
  initialState,

  extraReducers: {
    [getAllShifts.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllShifts.fulfilled](state, payload) {
      state.isLoading = false;
      state.error = "";

      ShiftsAdapter.setAll(state, payload);
    },
    [getAllShifts.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getShift.fulfilled]: (state, { payload }) => {
      ShiftsAdapter.setOne(state, payload);
    },

    [createShift.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createShift.fulfilled]: (state, { payload }) => {
      ShiftsAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createShift.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [updateShift.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateShift.fulfilled]: (state, { payload }) => {
      ShiftsAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updateShift.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [deleteShift.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deleteShift.fulfilled]: (state, { meta: { arg } }) => {
      const { id } = arg;
      ShiftsAdapter.removeOne(state, id);

      state.isRequestPending = false;
    },
    [deleteShift.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default employeesShiftsSlice.reducer;
