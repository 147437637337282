import { Box, Typography } from "@mui/material";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPackageCategoriesWithServiceVariantsByOutletId } from "store/selectors";

import ServicesSearchFieldContext from "../../ServicesSearchFieldContext";
import PackageCategory from "./PackageCategory";

// StaffId should be from form context
// const filterPackagesByStaffId = (staffId) => (_package) =>
//   staffId ? _package?.staff?.includes(staffId) : true;

// const filterCategoryPackagesByStaffId = (staffId) => (category) =>
//   category.packages?.filter(filterPackagesByStaffId(staffId));

const filterOutEmptyCategories = (category) => !!category.packages?.length;

const renderCategoryPackages = (category, date, outletId) => (
  <PackageCategory key={category.id} category={category} date={date} outletId={outletId} />
);

const filterPackagesByOutletId = (outletId) => (pkg) => pkg.outlets.includes(outletId);

const filterCategoryPackagesByOutletId = (outlet) => (category) => {
  const filteredPackages = category.packages?.filter(filterPackagesByOutletId(outlet));

  return { ...category, packages: filteredPackages };
};

const PackagesPanel = () => {
  const { t } = useTranslation("appointments");

  const { outletId, date } = useContext(CalendarPageContext);

  const formattedCalendarDateToIso = date.toFormat("yyyy-MM-dd");

  const { query } = useContext(ServicesSearchFieldContext);

  const allPackageCategories = useSelector(
    selectAllPackageCategoriesWithServiceVariantsByOutletId(null)
  );

  const filterCategoryByPackageNameOrCode = (category) => {
    if (!query) return category;

    const filteredPackagesResults = category.packages.filter(
      (_package) =>
        _package.name.toLowerCase().includes(query.toLowerCase().trim()) ||
        _package.referenceCode.toLowerCase().includes(query.toLowerCase())
    );

    return {
      ...category,
      packages: filteredPackagesResults,
    };
  };

  const packageCategories = allPackageCategories
    .map(filterCategoryByPackageNameOrCode)
    .map(filterCategoryPackagesByOutletId(outletId))
    .filter(filterOutEmptyCategories)
    .map((category) => renderCategoryPackages(category, formattedCalendarDateToIso, outletId));

  const showNoServicesMessage = !packageCategories.length;

  return (
    <Box>
      {showNoServicesMessage ? (
        <Typography p={3}>{t("noMatchingResults")} </Typography>
      ) : (
        packageCategories
      )}
    </Box>
  );
};

export default PackagesPanel;
