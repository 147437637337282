import { LoadingButton } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import getFormattedPrice from "helpers/getFormattedPrice";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { getClient } from "store/clients/clientsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import {
  createInvoice,
  createRefundInvoice,
  updateInvoice,
} from "store/slices/checkoutInvoices/checkoutInvoicesSlice";
import { CreateInvoiceArticleArgs, InvoiceStatus } from "types/Checkout";
import { PurchasableItemOption } from "types/PurchasableItem";
import useNotify from "utils/notifications/useNotify";

import { useCheckoutFormContext } from "./CheckoutFormContext";
import { getCheckoutStatus } from "./utils";

const ConfirmSaleSection = () => {
  const { t } = useTranslation(["checkout", "invoices"]);
  const dispatch = useDispatch();
  const notify = useNotify();

  const { invoiceId } = useCheckoutModal();
  const { balance, totalDue, isRefundInvoice, handleSubmit, watch, reset, setValue } =
    useCheckoutFormContext();

  const { notes, payments, walletPayments, refundInvoice } = watch();

  const invoice = useSelector(selectInvoiceById(invoiceId || 0));

  const status = getCheckoutStatus(totalDue, balance);

  const isCreatingInvoice = !invoice;

  const isPartiallyPayingExistingInvoice = !!invoice && !isRefundInvoice;

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { setCheckoutModalView, openCheckoutModalWithInvoiceDetailsMode } = useCheckoutModal();

  const switchToPaymentSection = () => {
    isRefundInvoice
      ? setCheckoutModalView(CheckoutModalView.Refund)
      : invoiceId
      ? setCheckoutModalView(CheckoutModalView.InvoiceAddPayments)
      : setCheckoutModalView(CheckoutModalView.Payment);
  };

  const handleConfirmCheckout = handleSubmit((data) => {
    setIsRequestLoading(true);
    const {
      existingClient,
      newClient,
      appointment,
      notes,
      articles,
      outlet,
      deletedArticles,
      payments,
      voucherRedemptions,
      walletPayments,
      refund,
    } = data;

    const articlesFlattenedByQuantity: CreateInvoiceArticleArgs[] = [];

    articles.forEach((article) => {
      const { purchasableItemType, finalPrice } = article;

      const formattedFinalPrice = getFormattedPrice(finalPrice);
      const roundedFinalPrice = Number(formattedFinalPrice);

      if (purchasableItemType === PurchasableItemOption.Product) {
        const quantity = article.quantity || 1;
        for (let i = 0; i < quantity; i++) {
          articlesFlattenedByQuantity.push({
            ...article,
            finalPrice: roundedFinalPrice,
          });
        }
      } else {
        articlesFlattenedByQuantity.push({ ...article, finalPrice: roundedFinalPrice });
      }
    });

    const submittedData = {
      client: existingClient || newClient || null,
      appointment,
      notes,
      articles: articlesFlattenedByQuantity,
      outlet,
      deletedArticles,
      payments,
      voucherRedemptions,
      walletPayments,
      refund,
    };

    dispatch(createInvoice(submittedData))
      .unwrap()
      .then((invoice) => {
        setIsRequestLoading(false);
        reset();
        if (invoice.client) {
          setValue("existingClient.id", invoice.client);
          dispatch(getClient(invoice.client));
        }

        openCheckoutModalWithInvoiceDetailsMode({ invoiceId: invoice.id });

        notify(
          t("entitySuccessfullyCreated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          }),
          "success"
        );
      })
      .catch((error) => {
        setIsRequestLoading(false);

        notify(
          `${t("entityFailedToBeCreated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          })} ${error?.detail ?? ""}`,
          "error"
        );
      });
  });

  const handleConfirmSaleForExistingInvoice = () => {
    if (!invoiceId || !invoice) return;

    const submittedData = {
      notes,
      payments,
      walletPayments,
    };
    setIsRequestLoading(true);

    dispatch(
      updateInvoice({
        id: invoice?.id,
        data: submittedData,
      })
    )
      .unwrap()
      .then((invoice) => {
        // Need to reset to remove payments from the frontend
        reset();

        if (invoice.client) setValue("existingClient.id", invoice.client);

        openCheckoutModalWithInvoiceDetailsMode({ invoiceId: invoice.id });

        notify(
          t("entitySuccessfullyUpdated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          }),
          "success"
        );

        setIsRequestLoading(false);
      })
      .catch((error) => {
        setIsRequestLoading(false);

        notify(
          `${t("entityFailedToBeUpdated", {
            entity: t("invoices:invoice"),
            ns: "translation",
          })} ${error?.detail ?? ""}`,
          "error"
        );
      });
  };

  const handleConfirmRefund = () => {
    if (refundInvoice) {
      setIsRequestLoading(true);

      dispatch(createRefundInvoice(refundInvoice))
        .unwrap()
        .then((invoice) => {
          // Need to reset to remove payments from the frontend
          reset();

          if (invoice.client) {
            setValue("existingClient.id", invoice.client);

            // we need to refetch the client so that if the refund was to client credit the client credit is updated
            dispatch(getClient(invoice?.client));
          }

          openCheckoutModalWithInvoiceDetailsMode({ invoiceId: invoice.id });

          notify(
            t("entitySuccessfullyCreated", {
              entity: t("checkout:refundInvoice"),
              ns: "translation",
            }),
            "success"
          );

          setIsRequestLoading(false);
        })
        .catch((error) => {
          setIsRequestLoading(false);

          notify(
            `${t("entityFailedToBeCreated", {
              entity: t("checkout:refundInvoice"),
              ns: "translation",
            })} ${error?.detail ?? ""}`,
            "error"
          );
        });
    } else {
      throw new Error("Wanna confirm what Bro! No refundInvoice defined! 🤨");
    }
  };

  const getConfirmButtonAction = () => {
    if (isRefundInvoice) return handleConfirmRefund();

    if (isPartiallyPayingExistingInvoice) return handleConfirmSaleForExistingInvoice();

    if (isCreatingInvoice) return handleConfirmCheckout();

    throw new Error("Wanna confirm what Bro! No action defined for this case! 🤨");
  };

  return (
    <Grid container justifyContent="center" alignItems="center" p={3} spacing={3}>
      <Grid
        item
        mt={10}
        xs={12}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <img src="/images/invoice.png" width="45px" height="45px" alt="invoice" />
      </Grid>

      <Grid item xs={12}>
        <Typography align="center" variant="h3">
          {t(
            isRefundInvoice
              ? getConfirmRefundText(
                  invoice?.articles?.length || 0,
                  refundInvoice?.articlesToRefund.length || 0
                )
              : getConfirmSaleText(status)
          )}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          fullWidth
          loading={isRequestLoading}
          onClick={() => getConfirmButtonAction()}
        >
          {t(isRefundInvoice ? "checkout:confirmRefund" : "checkout:confirmSale")}
        </LoadingButton>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Button variant="text" onClick={switchToPaymentSection}>
          {t(isRefundInvoice ? "checkout:backToRefund" : "checkout:backToPayments")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmSaleSection;

const getConfirmSaleText = (status: InvoiceStatus) => {
  if (status === InvoiceStatus.Unpaid) return "checkout:unpaid";
  else if (status === InvoiceStatus.Free) return "checkout:noPayment";
  else if (status === InvoiceStatus.Partially) return "partialPayment";
  else return "checkout:fullPayment";
};

const getConfirmRefundText = (
  originalInvoiceArticleCount: number,
  refundedArticlesCount: number
) => {
  if (originalInvoiceArticleCount > refundedArticlesCount)
    return "checkout:partialRefundWillBeIssued";
  else return "checkout:fullRefundWillBeIssued";
};
