import { Stack, Typography } from "@mui/material";
import { getFormattedPrice } from "helpers/getFormattedPrice";
import { sortByDateDescending } from "helpers/sort";
import { camelize } from "humps";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";
import { Appointment, AppointmentLogActionOptions } from "types/Appointment";

import AppointmentAdditionalInfoSection from "./AppointmentAdditionalInfoSection";

type AppointmentHistoryLogsProps = {
  appointmentId: Appointment["id"];
  logs: Appointment["logs"];
};

const AppointmentHistoryLogs = ({ appointmentId, logs }: AppointmentHistoryLogsProps) => {
  const { t } = useTranslation();

  const logsList = sortByDateDescending(logs, "createdAt")?.map(
    ({ action, createdBy, createdAt, isEmployee, description }, idx) => {
      let isDescriptionString = false;

      try {
        JSON.parse(description);
      } catch (e) {
        isDescriptionString = true;
      }

      let paymentMethod = "";

      if (!isDescriptionString) {
        paymentMethod = !isDescriptionString ? JSON.parse(description).payment_method : "";
      }

      const isPeepPay = paymentMethod === "Peep Pay";

      return (
        <Typography
          key={idx}
          variant="secondary"
          color="text.primary"
          sx={{ whiteSpace: "initial" }}
          fontWeight={400}
        >
          <Trans
            i18nKey={getLogTextTranslationKey(action, isEmployee, description, isPeepPay)}
            values={{
              description:
                [
                  AppointmentLogActionOptions.Cancel,
                  AppointmentLogActionOptions.Checkout,
                  AppointmentLogActionOptions.Create,
                  AppointmentLogActionOptions.Edit,
                ].includes(action) && description
                  ? description
                  : null,

              action: t(camelize(action.toLowerCase())),
              id: appointmentId,
              createdBy,
              createdAt: DateTime.fromISO(createdAt).toFormat("EEE DD hh:mm a"),
              amount:
                description &&
                !isDescriptionString &&
                action !== AppointmentLogActionOptions.UpdatedPricing &&
                `${t("currency")} ${getFormattedPrice(
                  JSON.parse(description).amount
                )} (${paymentMethod})`,
              name:
                description &&
                action === AppointmentLogActionOptions.UpdatedPricing &&
                JSON.parse(description).name,
              oldPrice:
                description &&
                action === AppointmentLogActionOptions.UpdatedPricing &&
                `${t("currency")} ${getFormattedPrice(JSON.parse(description).old_price)} `,
              newPrice:
                description &&
                action === AppointmentLogActionOptions.UpdatedPricing &&
                `${t("currency")} ${getFormattedPrice(JSON.parse(description).new_price)}`,
            }}
            components={[
              <Typography
                key="0"
                display="inline"
                variant="h5"
                fontSize="0.875rem"
                fontWeight={600}
              />,
            ]}
          />
        </Typography>
      );
    }
  );

  return (
    <AppointmentAdditionalInfoSection
      key="appointmentHistoryLogs"
      title={t("appointmentHistory")}
      description={<Stack gap={1.5}>{logsList}</Stack>}
    />
  );
};

export default AppointmentHistoryLogs;

export const getLogTextTranslationKey = (
  action: AppointmentLogActionOptions,
  isEmployee?: boolean,
  description?: string,
  isPeepPay?: boolean
) => {
  if (action === "DEPOSIT ADDED" || (action === "DEPOSIT REMOVED" && !isPeepPay)) {
    return "depositAppointmentLog";
  } else if (action === AppointmentLogActionOptions.UpdatedPricing) {
    return "updatedPricingLog";
  }

  if (isPeepPay && action === "DEPOSIT REMOVED") return "linkCancelledLog";

  if (description) {
    return "appointmentV2Log";
  }

  if (isEmployee) {
    return "appointmentLog";
  }

  return "onlineAppointmentLog";
};
