//Cant use selector inside PDF component, so passing props

import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { getImageSrc } from "helpers/getImageSrc";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { VoucherPurchaseInvoice } from "types/VoucherPurchase";

import { styles } from "./styles";

type GiftVoucherPDFProps = {
  organizationData: { name: string; avatar: string };
  voucherPurchase: VoucherPurchaseInvoice;
  voucherName: string;
};

const GiftVoucherPDF = ({
  organizationData,
  voucherPurchase,
  voucherName,
}: GiftVoucherPDFProps) => {
  const { t } = useTranslation("vouchers");

  // Calculate font size based on characters count of voucher name
  const textLength = voucherPurchase.name.length;
  const baseFontSize = 50;
  const sizeMultiplier = 0.3;

  const dynamicFontSize = Math.max(baseFontSize - sizeMultiplier * textLength, 10);

  return (
    <Document>
      <Page size="A5">
        <View style={styles.pageBackground}>
          <Image
            src="/images/gift-voucher-bg.png"
            style={{ width: "100%", height: "100%", objectFit: "fill" }}
          />
        </View>

        <View style={{ ...styles.topHalf }}>
          {organizationData.avatar && (
            <Image src={getImageSrc(organizationData.avatar)} style={styles.logo} />
          )}

          <Text
            //@ts-expect-error
            style={{
              fontWeight: "700",
              fontSize: "12px",
              color: "#C6C8F3",
              fontFamily: "Quicksand",
              paddingTop: "6px",
              paddingBottom: "6px",
            }}
          >
            {organizationData.name}
          </Text>

          <Text
            //@ts-expect-error
            style={{
              fontWeight: "700",
              fontSize: `${dynamicFontSize}px`,
              color: "#FFFFFF",
              fontFamily: "Cairo",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              padding: "40px",
            }}
          >
            {voucherName}
          </Text>
        </View>

        <View style={styles.bottomHalf}>
          <Text
            //@ts-expect-error
            style={{
              fontWeight: "700",
              fontSize: "14px",
              color: "#0A1F44",
              fontFamily: "Cairo",
            }}
          >
            {voucherPurchase.redeemableBy.firstName} {voucherPurchase.redeemableBy.lastName}
          </Text>

          <Text
            //@ts-expect-error
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: "#848FA1",
              fontFamily: "Quicksand",
            }}
          >
            {t("from")} {voucherPurchase.purchasedBy}
          </Text>

          <Text
            style={{
              fontSize: "12px",
              color: "#848FA1",
              fontFamily: "Cairo",
            }}
          >
            {voucherPurchase.gift.message}
          </Text>

          <View style={styles.codeSection}>
            <Text
              //@ts-expect-error
              style={{ fontWeight: "700", fontSize: "12px" }}
            >
              {voucherPurchase.code}
            </Text>
          </View>

          <Text
            //@ts-expect-error
            style={{
              fontWeight: "400",
              fontSize: "12px",
              color: "#6C798F",
              fontFamily: "Quicksand",
            }}
          >
            {t("expiry")}: {DateTime.fromISO(voucherPurchase.expiresOn).toFormat("dd MMMM yyyy")}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default GiftVoucherPDF;
