import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";

type RefundInvoiceTabSubtitleProps = {
  createdBy: string;
  createdAt: string;
};

const RefundInvoiceTabSubtitle = ({ createdBy, createdAt }: RefundInvoiceTabSubtitleProps) => (
  <Trans
    i18nKey="invoices:refundWasIssued"
    values={{
      createdBy,
      createdAt: DateTime.fromISO(createdAt).toFormat("EEEE, dd MMMM yyyy hh:mm a"),
    }}
    components={[
      <Typography
        key="0"
        display="inline"
        variant="primary"
        fontSize="0.875rem"
        fontWeight={600}
        color="primary"
      />,
      <Typography
        key="1"
        display="inline"
        variant="primary"
        fontSize="0.875rem"
        fontWeight={600}
        color="text.primary"
      />,
    ]}
  />
);

export default RefundInvoiceTabSubtitle;
