import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import ResponsiveModal from "components/common/Modals/ResponsiveModal";
import getFormattedPrice from "helpers/getFormattedPrice";
import useIsMobileView from "hooks/useIsMobileView";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import peepSubscriptionsApi from "./Settings/PeepSubscriptions/peepSubscriptionsApi";
import PeepSubscriptionsTermsAndConditions from "./Settings/PeepSubscriptions/PeepSubscriptionsTermsAndConditions";
import { TierInvoice } from "./Settings/PeepSubscriptions/PeepSubscriptionsTypes";

export const ServiceSuspended = ({ invoice }: { invoice?: TierInvoice }) => {
  const { t } = useTranslation(["peepSubscriptions", "translation", "clients"]);

  const isMobileView = useIsMobileView();

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const openPaymentUrl = async () => {
    setIsPaymentLoading(true);

    peepSubscriptionsApi.getInvoicePaymentUrl(invoice?.id || 0).then(({ data }) => {
      window.open(data.payment.paymentURL, "_self");
    });
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={2}
    >
      <ResponsiveModal
        title="Terms and Conditions"
        open={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        mobileActions=""
      >
        <Box display="flex" flexDirection="column" py={1} px={4}>
          <Box
            overflow="scroll"
            maxHeight={isMobileView ? "80vh" : "60vh"}
            textAlign="right"
            sx={{ direction: "rtl" }}
            pb={10}
          >
            <PeepSubscriptionsTermsAndConditions />
          </Box>

          <Box
            width="100%"
            justifyContent="flex-end"
            position="sticky"
            bottom="0"
            display="flex"
            gap={1}
            bgcolor="bg.blank"
            py={2}
          >
            <Button
              disabled={isPaymentLoading}
              variant="outlined"
              color="error"
              onClick={() => setIsTermsModalOpen(false)}
            >
              {t("translation:cancel")}
            </Button>
            <LoadingButton onClick={openPaymentUrl} loading={isPaymentLoading}>
              {t("translation:settingsPage.smsReminders.acceptAndPay")}
            </LoadingButton>
          </Box>
        </Box>
      </ResponsiveModal>

      <img alt="Service is suspended" src="/images/suspended.svg" />
      <Typography variant="h1">{t("serviceSuspended")}</Typography>
      <Typography>{t(invoice ? "pleaseSettleInvoice" : "clients:pleaseContactUs")}</Typography>
      {invoice && <Typography>{t("contactAdmin")}</Typography>}
      {invoice && (
        <>
          <Typography>
            Balance:{" "}
            <Typography display="inline" fontWeight={700} color="#F43319">
              {`${t("translation:currency")} ${getFormattedPrice(invoice.total)}`}
            </Typography>
          </Typography>
          <Button
            onClick={() => {
              setIsTermsModalOpen(true);
            }}
          >
            {t("payNow")}
          </Button>
        </>
      )}
    </Box>
  );
};
