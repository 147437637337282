import { RootState } from "store";

import { SliceStatus } from "../utils";
import { expenseTypesAdapter } from "./expenseTypesSlice";

export const selectExpenseTypeLoading = (state: RootState) =>
  state.expenseTypes.status === SliceStatus.LOADING;

export const selectExpenseTypesState = (state: RootState) => {
  return state.expenseTypes;
};

const { selectAll, selectById } = expenseTypesAdapter.getSelectors(selectExpenseTypesState);

export const selectAllExpenseTypes = selectAll;

export const selectExpenseTypeById = (id: number | string) => (state: RootState) =>
  selectById(state, id);
