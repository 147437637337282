import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./servicesApi";

const servicesAdapter = createEntityAdapter();

const initialState = servicesAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: null,
  optimisticCache: null,
});

export const getAllServices = createThunkFromApi("services/getAllServices", api.getAllServices);

export const createService = createThunkFromApi("services/createService", api.createService);

export const getService = createThunkFromApi("services/getService", api.getService);

export const updateService = createAsyncThunk(
  "services/updateService",
  async (updateServiceArgs, { rejectWithValue }) => {
    try {
      const { id, submittedData } = updateServiceArgs;
      const response = await api.updateService(id, submittedData);

      return { ...response.data, oldCategory: updateServiceArgs.oldCategory ?? null };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401 || status === 400) return rejectWithValue(data.detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateServicePosition = createThunkFromApi(
  "services/updateServicePosition",
  api.updateServicePosition
);

export const deleteService = createAsyncThunk(
  "services/removeService",
  async (deleteServiceArgs, { rejectWithValue }) => {
    try {
      const { id, categoryId } = deleteServiceArgs;

      await api.removeService(id);

      return { id, categoryId };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401 || status === 404 || status === 400) return rejectWithValue(data.detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllServices.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllServices.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;

      servicesAdapter.upsertMany(state, payload);
    },
    [getAllServices.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
    [createService.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createService.fulfilled]: (state, { payload }) => {
      servicesAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },

    [createService.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updateService.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateService.fulfilled]: (state, { payload }) => {
      servicesAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });

      state.isRequestPending = false;
    },
    [updateService.rejected]: (state) => {
      state.isRequestPending = false;
    },

    // [updateServicePosition.pending]: (state) => {
    //   // state.optimisticCache = selectAllServices({ services: state });
    // },
    [updateServicePosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updateServicePosition.rejected]: (state) => {
      servicesAdapter.setAll(state.services);
      state.optimisticCache = null;
    },
    [deleteService.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deleteService.fulfilled]: (state, { payload }) => {
      servicesAdapter.upsertOne(state, payload);
      state.isRequestPending = false;
    },
    [deleteService.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [getService.pending]: (state) => {
      state.isLoading = true;
    },
    [getService.fulfilled]: (state, { payload }) => {
      servicesAdapter.upsertOne(state, payload);
      state.isLoading = false;
    },
    [getService.rejected]: (state, { payload }) => {
      state.isLoading = false;
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export default servicesSlice.reducer;
