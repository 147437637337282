import { Typography } from "@mui/material";

type ExistingClientAdditionalInfoProps = {
  title: string;
  value: string;
};

const ExistingClientAdditionalInfo = ({ title, value }: ExistingClientAdditionalInfoProps) => {
  return (
    <Typography variant="h5" color="text.label" display="flex" alignItems="center" gap={1}>
      {title}
      <Typography variant="primary" color="text.placeholder" fontWeight={500} fontSize="0.875rem">
        {value}
      </Typography>
    </Typography>
  );
};

export default ExistingClientAdditionalInfo;
