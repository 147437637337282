/**
 * A function that turns list into string separated by comma.
 *
 * Example: ["apple", "orange", "banana"] => "apple,orange,banana"
 *
 * @param array array of strings you want to compound
 * @returns The given array items combined into a single string divided by commas
 */
export default function stringifyArray(array: string[]) {
  return array.length < 1 ? "" : array.toString();
}
