import { Stack, StackProps, Typography } from "@mui/material";
import React from "react";

type HomeServiceAppointmentFormSectionProps = {
  shouldHideTitle?: boolean;
  title: string;
  subtitle?: JSX.Element;

  icon: JSX.Element;
  isDisabled: boolean;

  actionButton?: React.ReactNode;

  children?: React.ReactNode;
  containerProps?: StackProps;
};

const DISABLED_COLOR = "#B6BCC7";
const ACTIVE_COLOR = "#848FA1";

const HomeServiceAppointmentFormSection = ({
  shouldHideTitle = false,
  title,
  subtitle,
  icon,
  isDisabled,
  actionButton,
  children,
  containerProps,
}: HomeServiceAppointmentFormSectionProps) => {
  return (
    <Stack
      gap={2}
      bgcolor={isDisabled ? "bg.secondary" : "bg.blank"}
      px={3}
      pt={shouldHideTitle ? 0 : 2}
      pb={2}
      {...containerProps}
    >
      <Stack direction="row" alignItems="center">
        <Stack
          display={shouldHideTitle ? "none" : "flex"}
          direction="row"
          gap={1}
          alignItems="center"
          sx={{
            "& svg": {
              color: isDisabled ? DISABLED_COLOR : ACTIVE_COLOR,
              fontSize: 16,
            },
          }}
        >
          {icon}

          <Typography
            fontWeight={500}
            fontSize="0.875rem"
            textTransform="uppercase"
            sx={{
              color: isDisabled ? DISABLED_COLOR : "text.primary",
            }}
          >{`${title}${isDisabled ? ":" : ""}`}</Typography>

          {subtitle}
        </Stack>

        <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
          {actionButton}
        </Stack>
      </Stack>

      {children}
    </Stack>
  );
};

export default HomeServiceAppointmentFormSection;
