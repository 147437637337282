import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import { DateTime } from "luxon";
import type { RootState } from "store";
import { EmployeeBlockTime } from "types/BlockTime";

import api, {
  CreateEmployeeBlockTimeArguments,
  CreateEmployeeBlockTimeResponse,
  DeleteEmployeeBlockTimeArguments,
  DjangoDetailResponse,
  EmployeeBlockTimeResponse,
  EmployeesBlockTimeArguments,
  UpdateEmployeeBlockTimeArguments,
  UpdateEmployeeBlockTimeResponse,
} from "./employeeBlockTimesApi";

const employeeBlockTimesAdapter = createEntityAdapter<EmployeeBlockTime>();

const initialState = employeeBlockTimesAdapter.getInitialState<{
  isLoading: boolean;
  isRequestPending: boolean;
  error: string;
}>({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = employeeBlockTimesAdapter.getSelectors<RootState>(
  (state) => state.employeeBlockTimes
);

export const selectEmployeeBlockTimeById = (id: number) => (state: RootState) =>
  selectById(state, id);

export const selectAllEmployeeBlockTimes = selectAll;

export const selectEmployeeBlockTimesByDateRange =
  (startDate: DateTime, endDate: DateTime) => (state: RootState) => {
    const allEmployeeBlockTimes = selectAllEmployeeBlockTimes(state);

    return allEmployeeBlockTimes.filter((blockTime) => {
      const blockStartTimeDate = DateTime.fromISO(blockTime.startTime);
      const blockEndTimeDate = DateTime.fromISO(blockTime.endTime);
      return (
        startDate.startOf("day") <= blockStartTimeDate && endDate.endOf("day") >= blockEndTimeDate
      );
    });
  };

export const getAllEmployeeBlockTimes = createAsyncThunk<
  EmployeeBlockTimeResponse,
  EmployeesBlockTimeArguments,
  {
    rejectValue: string;
  }
>(
  "employeeBlockTimes/getAllEmployeeBlockTimes",
  async (employeeBlockTimesArgs, { rejectWithValue }) => {
    try {
      const { startDate, endDate, outletId } = employeeBlockTimesArgs;

      const response = await api.getEmployeeBlockTimes({ startDate, endDate, outletId });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createEmployeeBlockTime = createAsyncThunk<
  CreateEmployeeBlockTimeResponse,
  CreateEmployeeBlockTimeArguments,
  {
    rejectValue: string;
  }
>(
  "employeeBlockTimes/createEmployeeBlockTime",
  async (employeeBlockTimesArgs, { rejectWithValue }) => {
    try {
      const { data } = employeeBlockTimesArgs;

      const response = await api.createEmployeeBlockTime(data);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateEmployeeBlockTime = createAsyncThunk<
  UpdateEmployeeBlockTimeResponse,
  UpdateEmployeeBlockTimeArguments,
  {
    rejectValue: string;
  }
>(
  "employeeBlockTimes/updateEmployeeBlockTime",
  async (employeeBlockTimesArgs, { rejectWithValue }) => {
    try {
      const { data, id } = employeeBlockTimesArgs;

      const response = await api.updateEmployeeBlockTime(data, id);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteEmployeeBlockTime = createAsyncThunk<
  null,
  DeleteEmployeeBlockTimeArguments,
  {
    rejectValue: string;
  }
>("employeeBlockTimes/deleteEmployeeBlockTime", async ({ data }, { rejectWithValue }) => {
  try {
    const response = await api.deleteEmployeeBlockTime(data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const employeeBlockTimesSlice = createSlice({
  name: "employeeBlockTimes",
  initialState,
  reducers: {},
  extraReducers: (reducers) =>
    reducers
      .addCase(getAllEmployeeBlockTimes.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(getAllEmployeeBlockTimes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        employeeBlockTimesAdapter.setAll(state, payload);
      })
      .addCase(getAllEmployeeBlockTimes.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })

      .addCase(createEmployeeBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(createEmployeeBlockTime.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        employeeBlockTimesAdapter.upsertOne(state, payload);
      })
      .addCase(createEmployeeBlockTime.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })

      .addCase(updateEmployeeBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(updateEmployeeBlockTime.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        employeeBlockTimesAdapter.upsertOne(state, payload);
      })
      .addCase(updateEmployeeBlockTime.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })

      .addCase(deleteEmployeeBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(deleteEmployeeBlockTime.fulfilled, (state, { meta }) => {
        const id = meta.arg.data.id;

        state.isLoading = false;
        state.isRequestPending = false;
        employeeBlockTimesAdapter.removeOne(state, String(id));
      })
      .addCase(deleteEmployeeBlockTime.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      }),
});

export default employeeBlockTimesSlice.reducer;
