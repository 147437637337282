import { Grid } from "@mui/material";
import { getFormattedPrice } from "helpers/getFormattedPrice";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectClientById } from "store/clients/clientsSlice";
import { Client } from "types/Client";

import ClientSubscriptionTags from "./ClientSubscriptionTags";
import ClientTag from "./ClientTag";
import ClientVoucherTags from "./ClientVoucherTags";

type ClientTagsProps = {
  clientId: number;
  outletId?: number;
};

const ClientTags = ({ clientId, outletId }: ClientTagsProps) => {
  const { t } = useTranslation(["translation", "appointments", "client"]);

  const client = useSelector(selectClientById(clientId)) as Client;

  const { appointmentsData, invoicesData, offlineWallet } = client || {};

  const shouldShowUnpaidBalanceChip = !!invoicesData?.totalUnpaid;

  return (
    <Grid container spacing={1} flexWrap="wrap">
      <Grid item flex={1}>
        <ClientTag label={t("appointments:bookings")} value={appointmentsData?.appointmentsCount} />
      </Grid>

      <Grid item flex={1}>
        <ClientTag
          label={t("appointments:spending")}
          value={`${t("currency")}\u00A0${getFormattedPrice(invoicesData?.totalSales)}`}
        />
      </Grid>

      <Grid item flex={1}>
        <ClientTag label={t("credits")} value={`${t("currency")}\u00A0${offlineWallet?.amount}`} />
      </Grid>

      {shouldShowUnpaidBalanceChip && (
        <Grid item flex={1}>
          <ClientTag
            label={t("unpaidStatus")}
            value={`${t("currency")}\u00A0${invoicesData?.totalUnpaid}`}
            isValueColorPrimary={false}
          />
        </Grid>
      )}

      <ClientSubscriptionTags clientId={clientId} outletId={outletId} />

      <ClientVoucherTags clientId={clientId} outletId={outletId} />
    </Grid>
  );
};

export default memo(ClientTags);
