import { ChevronLeft } from "@bookpeep/ui";
import { Button, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

type BackButtonProps = {
  text: string;
  redirectTo: string;
};

const BackButton = ({ text, redirectTo }: BackButtonProps) => {
  return (
    <LinkButton disableRipple disableFocusRipple startIcon={<ChevronLeft />}>
      <Typography fontWeight={500}>
        <Link to={redirectTo}>{text}</Link>
      </Typography>
    </LinkButton>
  );
};

export default BackButton;

const LinkButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.text.secondary,
  padding: 0,
  minHeight: "auto",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "& a": {
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },
}));
