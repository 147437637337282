import { createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./organizationOnlineProfileApi";

const initialState = {
  isLoading: true,
  isRequestPending: false,
  error: "",
};

export const getOrganizationOnlineProfile = createThunkFromApi(
  "organization/getOrganizationOnlineProfile",
  api.getOrganizationOnlineProfile
);

export const updateOrganizationOnlineProfile = createThunkFromApi(
  "organization/updateOrganizationOnlineProfile",
  api.updateOrganizationOnlineProfile
);

export const organizationOnlineProfileSlice = createSlice({
  name: "organizationOnlineProfile",
  initialState,

  extraReducers: {
    [getOrganizationOnlineProfile.pending]: (state) => {
      state.isLoading = true;
    },

    [getOrganizationOnlineProfile.fulfilled]: (_, { payload }) => {
      const organizationOnlineProfile = payload;

      return {
        ...organizationOnlineProfile,
        isLoading: false,
        error: "",
      };
    },

    [getOrganizationOnlineProfile.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [updateOrganizationOnlineProfile.pending]: (state) => {
      state.isRequestPending = true;
      state.isLoading = true;
    },
    [updateOrganizationOnlineProfile.fulfilled]: (state, { payload }) => {
      const organizationOnlineProfile = payload;
      const modifiedState = { ...state, isRequestPending: false, isLoading: false };

      return {
        ...modifiedState,
        ...organizationOnlineProfile,
      };
    },

    [updateOrganizationOnlineProfile.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export const selectOrganizationOnlineProfile = ({ organizationOnlineProfile }) =>
  organizationOnlineProfile;
export const selectOrganizationOnlineProfileLoading = (state) => state.organization.isLoading;

export default organizationOnlineProfileSlice.reducer;
