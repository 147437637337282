import { DialogContent, Divider } from "@mui/material";
import { ReactNode } from "react";
import { ActivityLogDataType, BasicDetailsDataType } from "types/DetailsWithLogs";

import ActivityLogSection from "./ActivityLogSection";
import BasicDetailsData from "./BasicDetailsData";
import DetailsModalHeader from "./DetailsModalHeader";

type ShowDetailsWithLogsModalProps = {
  modalTitle: string;

  detailsSubTitle?: string;
  detailsData?: BasicDetailsDataType[];

  activityLogSubtitle?: string;
  activityLogData?: ActivityLogDataType[];

  descriptionSection?: ReactNode;
  emptyModalSection?: ReactNode;

  onClose: () => void;
};

const ShowDetailsWithLogsModal = ({
  modalTitle,
  detailsSubTitle,
  detailsData,
  activityLogSubtitle,
  activityLogData,
  descriptionSection,
  emptyModalSection,
  onClose,
}: ShowDetailsWithLogsModalProps) => {
  const showBasicDetailsSection = !!detailsSubTitle && !!detailsData;

  const showActivityLogSection = !!activityLogSubtitle && !!activityLogData;

  const showDescriptionSection = !!descriptionSection;

  return (
    <>
      <DetailsModalHeader title={modalTitle} onClose={onClose} />

      <DialogContent
        sx={{
          boxShadow: "divider",
          p: 3,
        }}
      >
        {showDescriptionSection && (
          <>
            {descriptionSection} <Divider sx={{ mt: 3, color: "#CED2DA)" }} />
          </>
        )}

        {emptyModalSection ? (
          emptyModalSection
        ) : (
          <>
            {showBasicDetailsSection && (
              <BasicDetailsData detailsData={detailsData} detailsSubTitle={detailsSubTitle} />
            )}

            {showActivityLogSection && (
              <>
                <Divider />

                <ActivityLogSection
                  activityLogData={activityLogData}
                  activityLogSubtitle={activityLogSubtitle}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
    </>
  );
};

export default ShowDetailsWithLogsModal;
