import { FormArticle } from "../utils";

export const getAppointmentServicesCount = (articles: FormArticle[]): number => {
  return articles.reduce((currentTotal, article) => {
    const hasService = article.serviceVariantId !== null;
    const hasPackage = article.packageVariantId !== null;
    const hasFlexService = article.flexService !== null;

    if (hasService || hasFlexService) {
      return currentTotal + 1;
    }

    if (hasPackage) {
      return currentTotal + article.slots.length;
    }

    return currentTotal;
  }, 0);
};

export const getAppointmentUniqueStaffCount = (articles: FormArticle[]): number => {
  const staffIds = articles.map((article) => article.slots.map((slot) => slot.staffId)).flat();

  return new Set(staffIds).size;
};

export const checkIfAppointmentHasAtLeastOneService = (articles: FormArticle[]): boolean => {
  return (
    !!articles.length &&
    articles.some(
      (article) => article.serviceVariantId || article.packageVariantId || article.flexService
    )
  );
};
