import { OutletFeaturedServiceType } from "types/OutletFeaturedService";
import http from "utils/http";

export type OutletFeaturedServiceArguments = Omit<OutletFeaturedServiceType, "id">;

const OutletFeaturedServicesApi = {
  getOutletFeaturedServices: async () =>
    http.get<OutletFeaturedServiceType[]>(`services/v2/outlet_featured_services/`),

  createOutletFeaturedService: async (data: FormData) =>
    http.post<OutletFeaturedServiceType>(`services/v2/outlet_featured_services/`, data),

  updateOutletFeaturedService: async ({ id, data }: { id: number; data: FormData }) =>
    http.put<OutletFeaturedServiceType>(`services/v2/outlet_featured_services/${id}/`, data),

  deleteOutletFeaturedService: async ({ id }: { id: number }) =>
    http.delete(`services/v2/outlet_featured_services/${id}/`),
};

export default OutletFeaturedServicesApi;
