import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { StockTransaction } from "types/Stock";

import {
  createStockDown,
  createStockTransfer,
  createStockUp,
  getProductData,
  updateProduct,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";
import api from "./stockTransactionsApi";

export const stockTransactionsAdapter = createEntityAdapter<StockTransaction>({
  selectId: (stockTransaction) => stockTransaction.id,
});

const initialState = stockTransactionsAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
});

export const getAllStockTransactions = createThunkFromApiWithType(
  "stocks/getAllStockTransactions",
  api.getStockTransactions
);

export const appendAllStockTransactions = createThunkFromApiWithType(
  "stocks/appendAllStockTransactions",
  api.getStockTransactions
);

export const getStockTransactionsExcel = createThunkFromApiWithType(
  "expenses/getStockTransactionsExcel",
  api.getStockTransactionsExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const stockTransactionsSlice = createSlice({
  name: "stockTransactions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllStockTransactions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllStockTransactions.fulfilled, (state, { payload }) => {
      stockTransactionsAdapter.setAll(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(getAllStockTransactions.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllStockTransactions.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllStockTransactions.fulfilled, (state, { payload }) => {
      stockTransactionsAdapter.upsertMany(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendAllStockTransactions.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(getProductData.fulfilled, (state, { payload: { stockTransactions } }) => {
      stockTransactionsAdapter.setAll(state, stockTransactions);
      // state.status = SliceStatus.IDLE;
    });
    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { stockTransactions } = payload;

      stockTransactionsAdapter.setAll(state, stockTransactions);
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { stockTransactions } }) => {
      stockTransactionsAdapter.setAll(state, stockTransactions);
      state.status = SliceStatus.IDLE;
    });
    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { stockTransactions } }) => {
      stockTransactionsAdapter.setAll(state, stockTransactions);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { stockTransactions } }) => {
      stockTransactionsAdapter.setAll(state, stockTransactions);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default stockTransactionsSlice.reducer;
