import type { RootState } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";
import { Resource, ResourceWithOutletDetails } from "types/Resource";
import { isDefined } from "types/typeGuards/PrimitiveTypeGuards";

import { selectServiceById } from "../servicesV2/servicesSelectors";
import { SliceStatus } from "../utils";
import { resourcesAdapter } from "./resourcesSlice";

export const selectResourcesLoading = (state: RootState) =>
  state.resources.status === SliceStatus.LOADING;

export const selectIsResourceRequestPending = (state: RootState) =>
  state.resources.isRequestPending === SliceStatus.LOADING;

export const selectResourcesTotalPages = (state: RootState) => state.resources.totalPages;

export const selectResourcesState = (state: RootState) => state.resources;

const { selectById, selectAll } = resourcesAdapter.getSelectors(selectResourcesState);

// WARNING: This selector returns all resources, including deprecated ones
export const selectAllResources = selectAll;

export const selectAllNotDeprecatedResources = (state: RootState) => {
  const allResources = selectAllResources(state);

  const notDeprecatedResources = allResources.filter((resource) => !resource.deprecated);

  return notDeprecatedResources;
};

export const selectAllResourcesWithDetails = (state: RootState): ResourceWithOutletDetails[] => {
  const allNotDeprecatedResources = selectAllNotDeprecatedResources(state);

  const resourceIdsOfCurrentPage = state.resources.page;

  // Note that we need to do this so that the order of the resources is the same as the order of the resourceIdsOfCurrentPage, for them to be displayed in the same order as the resourceIdsOfCurrentPage and be ordered as expected
  const resourcesOfCurrentPage = resourceIdsOfCurrentPage
    .map((resourceId) => {
      const resource = allNotDeprecatedResources?.find((resource) => resource?.id === resourceId);
      return resource;
    })
    .filter(isDefined);

  const resourcesWithDetails = resourcesOfCurrentPage.map((resource: Resource) => ({
    ...resource,
    outlet: selectOutletById(resource.outlet)(state),
  }));

  return resourcesWithDetails;
};

export const selectResourceById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectResourcesByOutletId = (outletId: number) => (state: RootState) => {
  return selectAllNotDeprecatedResources(state).filter((resource) => resource.outlet === outletId);
};

/**
 * If serviceId is null, it means that we want to get all the resources of the outlet
 * @param outletId
 * @param serviceId
 * @returns
 */
export const selectResourcesByOutletIdAndServiceId =
  (outletId: number, serviceId: number | null) => (state: RootState) => {
    const outletResources = selectResourcesByOutletId(outletId)(state);

    if (!serviceId) return outletResources;

    const service = serviceId ? selectServiceById(serviceId)(state) : null;

    if (serviceId && !service) return [];

    return outletResources.filter((resource) => service?.resources.includes(resource.id));
  };

export const selectActiveResourcesByOutletIdAndServiceId =
  (outletId: number, serviceId: number | null) => (state: RootState) => {
    const resourcesByOutletIdAndServiceId = selectResourcesByOutletIdAndServiceId(
      outletId,
      serviceId
    )(state);

    const activeResources = resourcesByOutletIdAndServiceId.filter((resource) => resource.active);

    return activeResources;
  };

export const selectActiveOutletResourcesByOutletId =
  (id: number | string) => (state: RootState) => {
    const allNotDeprecatedResources = selectAllNotDeprecatedResources(state);

    const activeOutletResources = allNotDeprecatedResources.filter(
      (resource) => resource.outlet === id && resource.active
    );

    return activeOutletResources;
  };
