import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./permissionSectionsApi";

const permissionAdapter = createEntityAdapter();

const { selectAll } = permissionAdapter.getSelectors(
  (state) => state.permissionSections
);

export const selectAllPermissionSections = selectAll;

export const selectedPermissions = (state) => {
  return state.permissionSections.selectedPermissions;
};

const initialState = permissionAdapter.getInitialState({
  isLoading: false,
  error: "",
  selectedPermissions: [],
});

export const getAllPermissionSections = createThunkFromApi(
  "permissionSections/getAllPermissionSections",
  api.getAllPermissionSections
);

const permissionSectionsSlice = createSlice({
  name: "permissionSections",
  initialState,
  reducers: {
    setSelectedPermissions: (state, { payload }) => {
      state.selectedPermissions = payload;
    },
    updateSelectedPermissions: (state, { payload }) => {
      const index = _.findIndex(
        state.selectedPermissions,
        (o) => o.type === payload.type
      );
      state.selectedPermissions.splice(index, 1, payload);
    },
  },
  extraReducers: {
    [getAllPermissionSections.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllPermissionSections.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      permissionAdapter.setAll(state, payload);
    },

    [getAllPermissionSections.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export const { setSelectedPermissions, updateSelectedPermissions } =
  permissionSectionsSlice.actions;

export default permissionSectionsSlice.reducer;
