/**
 * This function is used to convert the value to undefined if it is not a number
 *
 * @param value
 * @returns the value if it is a number, otherwise undefined
 */

const convertNoneNumberToUndefined = (value: any) => (isNaN(value) ? null : value);

export default convertNoneNumberToUndefined;
