import { Box, Stack, TableCell } from "@mui/material";
import { SortAsc, SortDesc } from "assets/icon";
import { theme } from "configs";
import { useEffect, useState } from "react";

import { HeaderOrder, Order, TableHeader } from "./TableTypes";

interface PeepTableHeaderProps {
  orderBy?: string[];
  header: TableHeader;
  headerCount: number;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string, order: Order) => void;
}

function PeepTableHeader({ orderBy, header, headerCount, onRequestSort }: PeepTableHeaderProps) {
  const [order, setOrder] = useState<Order>("");

  useEffect(() => {
    if (orderBy) {
      setOrder("");
      const itemIndex = orderBy.findIndex((item) => item.includes(header.key));

      if (itemIndex !== -1) {
        setOrder(orderBy[itemIndex][0] === "-" ? HeaderOrder.DESC : HeaderOrder.ASC);
      }
    }
  }, [orderBy, header.key]);

  const createSortHandler =
    (property: string, order: string) => (event: React.MouseEvent<unknown>) => {
      if (header.isSortable && onRequestSort) {
        let newOrder: Order = "";

        switch (order) {
          case HeaderOrder.ASC:
            newOrder = HeaderOrder.DESC;
            break;
          case HeaderOrder.DESC:
            newOrder = "";
            break;
          case "":
            newOrder = HeaderOrder.ASC;
            break;
        }
        setOrder(newOrder);
        onRequestSort(event, property, newOrder);
      }

      if (header.isSortable && !onRequestSort)
        throw new Error("You need to pass onRequestSort to be able to sort");
    };

  return (
    <TableCell
      key={header.key}
      align={header.align}
      onClick={orderBy ? createSortHandler(header.key, order) : undefined}
      width={!!header.width ? header.width : `calc(100 - ${headerCount})%`}
    >
      <Box
        flexDirection="row"
        sx={{
          display: header.isSortable ? "flex" : null,
          cursor: header.isSortable ? "pointer" : "default",
        }}
      >
        <Box
          my="auto"
          sx={{
            "&:hover": {
              borderBottom: header.isSortable ? "1px solid" : null,
              borderColor: header.isSortable ? theme.palette.primary.main : null,
            },
          }}
        >
          {header.name}
        </Box>

        {header.isSortable && (
          <Stack direction="column" justifyContent="center" alignItems="center" paddingLeft={0.5}>
            <SortAsc
              fontSize="large"
              sx={{
                fill: `${order === HeaderOrder.ASC ? "#4048D6" : "#f5f5f5"}`,
                position: "relative",
                top: 10,
              }}
            />

            <SortDesc
              fontSize="large"
              sx={{
                fill: `${order === HeaderOrder.DESC ? "#4048D6" : "#f5f5f5"}`,
                position: "absolute",
                bottom: -5,
              }}
            />
          </Stack>
        )}
      </Box>
    </TableCell>
  );
}

export default PeepTableHeader;
