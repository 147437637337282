import { Heart } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { getSlotDurationInMinutes } from "helpers/getSlotDurationInMinutes";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "store/employees/employeesSlice";
import { selectResourceById } from "store/selectors";
import { selectServiceById } from "store/slices/servicesV2/servicesSelectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";

const ServiceRow = ({ slot }) => {
  const serviceVariant = useSelector(selectServiceVariantById(slot?.serviceVariant));

  const service = useSelector(selectServiceById(serviceVariant?.service));
  const staff = useSelector(selectEmployeeById(slot?.staff));
  const resource = useSelector(selectResourceById(slot?.resource));

  const staffName = staff?.user
    ? `${staff.user.firstName}${staff.user.lastName && ` ${staff.user.lastName}`}`
    : staff.profile
    ? `${staff.profile.firstName}${staff.profile.lastName && ` ${staff.profile.lastName}`}`
    : "";

  const description = `${serviceVariant?.name}, ${getFormattedDuration(
    getSlotDurationInMinutes(slot)
  )}`;

  const resourceName = resource ? `, ${resource.name}` : "";

  const articleResourceDescription = `${staffName}${resourceName}`;

  if (!service) return <div>Loading..</div>;
  return (
    <Box display="flex" justifyContent="space-between" minWidth="100%" py={1}>
      <Box>
        <Typography variant="h5" fontWeight={500} noWrap sx={{ py: 0.5 }}>
          {service?.name}
        </Typography>

        <Typography variant="secondary" color="text.label">
          {description}
        </Typography>

        <Box display="flex" alignItems="center" noWrap>
          <Typography variant="secondary" color="text.label">
            {articleResourceDescription}
          </Typography>

          {slot.isStaffSelectedByClient && <Heart sx={{ fontSize: "12px" }} solid color="error" />}
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceRow;
