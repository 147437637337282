import { Warning } from "@bookpeep/ui";
import { Button, Tooltip, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Gear, HamburgerMenu } from "assets/icon";
import NotificationsCenter from "components/Notifications/NotificationsCenter";
import SideMenuDrawer from "components/SideNavMenu/SideMenuDrawer";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import { MenuPagesReturnType } from "pages";
import { MouseEvent, TouchEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDispatch } from "store";
import { logout, requestVerifyEmail, selectAuth } from "store/auth/authSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectAllOnlineBookingStatuses } from "store/selectors";
import auth from "utils/auth";
import useNotify from "utils/notifications/useNotify";

import peepLogo from "../../assets/images/peep-logo.svg";
import ManageOnlineBookingStatus from "./ManageOnlineBookingStatus";
import NavListItem from "./NavListItem";

type AppNavBarProps = {
  navigationItems: MenuPagesReturnType;
};

const AppNavBar = ({ navigationItems }: AppNavBarProps) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const notify = useNotify();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const dispatch = useDispatch<GenericDispatch>();
  const { t } = useTranslation(["translation", "authentication"]);
  const onlineBookingStatus = useSelector(selectAllOnlineBookingStatuses);
  const { hasManageAcceptingOnlineBookingsAccess } = useSelector(selectAllPermissions);
  const organization = useSelector(selectOrganization);
  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization?.industry || 6);

  const { emailVerified, isOwnerOrAdmin } = useSelector(selectAuth);
  const { hasPendingPayment } = useSelector(selectOrganization);

  const isMenuOpen = Boolean(menuAnchor);

  const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleVerifyClick = () => {
    setIsRequestLoading(true);
    dispatch(requestVerifyEmail({}))
      .unwrap()
      .then(() => {
        notify(t("authentication:verificationEmailSent"), "success");
        setIsRequestLoading(false);
      })
      .catch((error: any) => {
        notify(`${t("authentication:verificationEmailFailed")}: ${error ?? ""}`, "error");
        setIsRequestLoading(false);
      });
  };

  const closeUserMenu = () => setMenuAnchor(null);

  const goToProfilePage = () => {
    closeUserMenu();
    navigate("/profile");
  };

  const handleLogout = () => {
    navigate("/login");
    dispatch(logout());
  };

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleTouchOpenNavMenu = (event: TouchEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => setAnchorElNav(null);

  const mainSectionNavListItems = navigationItems
    .filter((item) => !item.isInBottomSection)
    .map((item) => <NavListItem key={item.title} item={item} />);

  const absolutePath = path.split("/");

  const navigationItemWithChildren = navigationItems.find(
    (item) => item.showChildren && item.children
  );

  const navigationItemsWithChildren = navigationItems.concat(
    navigationItemWithChildren?.children || []
  );

  const currentRoute = navigationItemsWithChildren
    .filter((item) => item !== navigationItemWithChildren)
    .find((item) => absolutePath.includes(item.path || ""));

  const shouldShowManageOnlineBookingStatus =
    onlineBookingStatus.length > 0 &&
    !!hasManageAcceptingOnlineBookingsAccess?.editAccess &&
    !isHomeServiceOrganization;

  return (
    <AppBar position="sticky" sx={{ px: { lg: 2, xs: 0.5 }, boxShadow: "none", zIndex: 100 }}>
      <Toolbar disableGutters>
        <Box
          display={{ xs: "none", lg: "flex", cursor: "pointer" }}
          alignItems="center"
          onClick={() => navigate("/")}
        >
          <img style={{ width: "45px" }} src={peepLogo} alt="peep logo" />
        </Box>

        {/* Mobile Nav */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
          <Box display="flex" alignItems="center">
            <StyledIconButton
              size="large"
              onClick={handleOpenNavMenu}
              onTouchStart={handleTouchOpenNavMenu}
            >
              <HamburgerMenuStyle color="inherit" width="20" height="14" viewBox="0 0 20 14" />
            </StyledIconButton>

            <Typography fontWeight={500} textAlign="left">
              {t(currentRoute?.title || "home")}
            </Typography>
          </Box>

          <SideMenuDrawer
            navigationItems={navigationItems}
            isSideNavMenuOpen={!!anchorElNav}
            toggleSideNavMenu={handleCloseNavMenu}
          />
        </Box>

        {/* End Mobile Nav */}

        <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }} px={2} columnGap={1}>
          {mainSectionNavListItems}
        </Box>

        <Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={{ xs: 0, lg: 1 }}>
            {shouldShowManageOnlineBookingStatus && <ManageOnlineBookingStatus />}

            <NotificationsCenter />

            {isOwnerOrAdmin && (
              <IconButton
                sx={{
                  display: { xs: "none", lg: "flex" },
                  p: { xs: 0.5 },
                }}
                size="large"
                onClick={() => navigate("/settings")}
              >
                <Gear sx={{ width: "20px", height: "20px", color: "white" }} />
              </IconButton>
            )}

            {hasPendingPayment && (
              <Tooltip title={t("authentication:paymentPending")}>
                <IconButton
                  sx={{ p: { xs: 0.5 } }}
                  color="warning"
                  onClick={() => navigate("settings/peep-subscription")}
                >
                  <Warning sx={{ width: "20px", height: "20px" }} />
                </IconButton>
              </Tooltip>
            )}

            {!emailVerified && (
              <Button variant="text" disabled={isRequestLoading} onClick={handleVerifyClick}>
                <Typography
                  fontWeight="bold"
                  variant="link"
                  fontSize="0.875rem"
                  color={isRequestLoading ? "lightgray" : "white"}
                >
                  {t("authentication:verifyAccount")}
                </Typography>
              </Button>
            )}

            <Avatar
              sx={{ width: "30px", height: "30px" }}
              component={IconButton}
              onClick={openUserMenu}
            />

            <StyledMenu anchorEl={menuAnchor} open={isMenuOpen} onClose={closeUserMenu}>
              <MenuItem disabled>{auth.userFullName}</MenuItem>

              <MenuItem onClick={goToProfilePage}>{t("profile")}</MenuItem>

              <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
            </StyledMenu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default AppNavBar;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingRight: 0,
}));

const HamburgerMenuStyle = styled(HamburgerMenu)(({ theme }) => ({
  display: "flex",
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "150px",
    marginRight: theme.spacing(2),
    boxShadow: theme.shadows.deepCard,
  },
}));
