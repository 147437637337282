import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type NotesFieldProps = {
  isDisabled?: boolean;
  value: string;
  handleChange: (newValue: string) => void;
};

const NotesField = ({ value, isDisabled = false, handleChange }: NotesFieldProps) => {
  const { t } = useTranslation("client");

  const [notes, setNotes] = useState(value);

  // This useEffect is used to update the notes state when the value prop changes because it is not updating the notes state when the value prop changes, example when they update the note from the manage client form, it was not re-rendering the updated note in the notes field
  useEffect(() => {
    if (!value) return;

    setNotes(value);
  }, [value]);

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) =>
    setNotes(event.target.value);

  const handleSubmit = () => handleChange(notes);

  return (
    <Stack key="notes-field" borderRadius={1} bgcolor="bg.secondary" p={3}>
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <Typography variant="primary" color="text.label" fontWeight={500}>
          {t("notes")}
        </Typography>
      </Stack>

      <TextField
        key="notes"
        value={notes}
        onChange={handleChangeText}
        onBlur={handleSubmit}
        placeholder={t("enterNotesHere")}
        fullWidth
        multiline
        rows={5}
        inputProps={{
          maxLength: "3000000",
        }}
        disabled={isDisabled}
      />
    </Stack>
  );
};

export default NotesField;
