import mixpanel from "mixpanel-browser";
import auth from "utils/auth";

export const trackEvent = (eventName, properties) => {
  const user = auth.user;
  if (user && user.isTracked) {
    mixpanel.get_group("Partner", user.organizationId).set({ $name: user.organizationName });
    mixpanel.track(eventName, {
      ...properties,
      "Partner Id": `${user.organizationId}`,
      "Partner Name": user.organizationName,
    });
  }
};

export const setUserForTracking = () => {
  const user = auth.user;
  if (user && user.isTracked) {
    mixpanel.identify(user.id);
    mixpanel.set_group("Partner", user.organizationId);
    mixpanel.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $email: user.email,
      "Is Employee": user.isEmployee,
      "Is Owner": user.isOwnerOrAdmin && !user.isEmployee,
      "Is Admin": user.isOwnerOrAdmin && user.isEmployee,
      "Partner Id": user.organizationId,
      "Partner Name": user.organizationName,
    });
  }
};
