import ClientBeautyProfile from "components/Clients/ClientProfile/ClientDetails/ClientBeautyProfile/ClientBeautyProfile";
import ClientPerformance from "components/Clients/ClientProfile/ClientHistory/ClientPerformance";
import { PageTabs } from "components/common";
import { useTranslation } from "react-i18next";

import AppointmentsHistoryTab from "./AppointmentsHistoryTab";
import ClientInfoTab from "./ClientInfoTab";
import ClientInvoicesHistoryTab from "./ClientInvoicesHistoryTab";

type AppointmentDrawerClientProfileTabsProps = {
  clientId: number;
};

const AppointmentDrawerClientProfileTabs = ({
  clientId,
}: AppointmentDrawerClientProfileTabsProps) => {
  const { t } = useTranslation("client");

  const tabs = [
    {
      id: 0,
      label: t("profile"),
      component: <ClientBeautyProfile clientId={clientId} />,
    },
    {
      id: 1,
      label: t("performance"),
      component: <ClientPerformance clientId={clientId} />,
    },
    {
      id: 2,
      label: t("appointmentsHistory"),
      component: <AppointmentsHistoryTab clientId={clientId} />,
    },
    {
      id: 3,
      label: t("invoices"),
      component: <ClientInvoicesHistoryTab clientId={clientId} />,
    },
    {
      id: 4,
      label: t("basicInfo"),
      component: <ClientInfoTab clientId={clientId} />,
    },
  ];

  return (
    <PageTabs
      tabs={tabs}
      withRoutes={false}
      withContainer={false}
      tabsProps={{
        allowScrollButtonsMobile: true,
        scrollButtons: "auto",
      }}
    />
  );
};

export default AppointmentDrawerClientProfileTabs;
