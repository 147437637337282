import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { City } from "types/Area";

import { SliceStatus } from "../utils";
import api from "./citiesApi";

export const citiesAdapter = createEntityAdapter<City>({
  selectId: (city) => city.id,
});

const initialState = citiesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getCities = createThunkFromApiWithType("cities/getCities", api.getCities);

export const citiesSlice = createSlice({
  name: "cities",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getCities.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getCities.fulfilled, (state, { payload }) => {
      citiesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getCities.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default citiesSlice.reducer;
