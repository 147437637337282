import { DoubleChevronLeft, DoubleChevronRight } from "@bookpeep/ui";
import { Box, BoxProps, Divider, IconButton, List } from "@mui/material/";
import { styled, useTheme } from "@mui/material/styles";
import { dontForwardProps } from "helpers/styles";
import type { MenuPagesReturnType } from "pages";
import { useSelector } from "react-redux";
import { selectAuth } from "store/auth/authSlice";

import DrawerWrapper from "./DrawerWrapper";
import LogoSection from "./LogoSection";
import NavListItem from "./NavListItem";

type SideMenuDrawerProps = {
  navigationItems: MenuPagesReturnType;
  toggleSideNavMenu: () => void;
  isSideNavMenuOpen: boolean;
};

const SideMenuDrawer = ({
  navigationItems,
  toggleSideNavMenu,
  isSideNavMenuOpen,
}: SideMenuDrawerProps) => {
  const user = useSelector(selectAuth);
  const theme = useTheme();

  const getToggleMenuIcon = () => {
    if (theme.direction === "ltr") {
      return isSideNavMenuOpen ? <DoubleChevronLeft /> : <DoubleChevronRight />;
    } else {
      return isSideNavMenuOpen ? <DoubleChevronRight /> : <DoubleChevronLeft />;
    }
  };

  const mainSectionNavListItems = navigationItems
    .filter((item) => !item.isInBottomSection)
    .map((item) => (
      <NavListItem
        key={item.title}
        item={item}
        toggleSideNavMenu={toggleSideNavMenu}
        isSideNavMenuOpen={isSideNavMenuOpen}
      />
    ));

  const bottomSectionNavListItems = navigationItems
    .filter((item) => item.isInBottomSection)
    .map((item) => (
      <NavListItem
        key={item.title}
        item={item}
        toggleSideNavMenu={toggleSideNavMenu}
        isSideNavMenuOpen={isSideNavMenuOpen}
      />
    ));

  return (
    <DrawerWrapper toggleSideNavMenu={toggleSideNavMenu} isSideNavMenuOpen={isSideNavMenuOpen}>
      <LogoSection shouldShowFullLogo={isSideNavMenuOpen} />

      <Box flexDirection="column" display="flex" justifyContent="space-between" flexGrow={1}>
        <Box>
          <NavList>{mainSectionNavListItems}</NavList>
        </Box>

        <Box>
          {user.isOwnerOrAdmin && (
            <>
              <BottomSectionDivider />
              <NavList>{bottomSectionNavListItems}</NavList>
              <BottomSectionDivider />
            </>
          )}

          <DrawerFooter isSideNavMenuOpen={isSideNavMenuOpen}>
            <ToggleDrawerIconButton onClick={toggleSideNavMenu}>
              {getToggleMenuIcon()}
            </ToggleDrawerIconButton>
          </DrawerFooter>
        </Box>
      </Box>
    </DrawerWrapper>
  );
};

export default SideMenuDrawer;

const DrawerFooter = styled(
  Box,
  dontForwardProps("isSideNavMenuOpen")
)<BoxProps & { isSideNavMenuOpen: boolean }>(({ theme, isSideNavMenuOpen }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: isSideNavMenuOpen ? "flex-end" : "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ToggleDrawerIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const NavList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  margin: theme.spacing(0, 1),
}));

const BottomSectionDivider = styled(Divider)({ borderColor: "#595DE3" });
