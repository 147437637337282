import http from "utils/http";

const packageVariantsApi = {
  getAllServiceVariants: async () => http.get("services/variants/"),

  getServiceVariant: async (id) => http.get(`services/variants/${id}/`),

  createServiceVariant: async (data) => http.post("services/variants/", data),

  updateServiceVariant: async (id, data) =>
    http.put(`services/variants/${id}/`, data),

  removeServiceVariant: async (id) => http.delete(`services/variants/${id}/`),
};

export default packageVariantsApi;
