import { Skeleton, Stack, Typography } from "@mui/material";

import AppointmentAdditionalInfoSection from "./AppointmentAdditionalInfoSection";

const AppointmentHistoryLogsAppointmentHistoryLogsSkeleton = () => {
  const logsList = Array.from({ length: 2 }).map((_, index) => {
    return (
      <Typography
        key={index}
        variant="secondary"
        color="text.primary"
        sx={{ whiteSpace: "initial" }}
        fontWeight={400}
      >
        <Skeleton variant="text" width={index * 350} />
        <Skeleton variant="text" width={index * 450} />
      </Typography>
    );
  });

  return (
    <AppointmentAdditionalInfoSection
      key="appointmentHistoryLogs"
      title={<Skeleton variant="text" width={100} />}
      description={<Stack gap={1.5}>{logsList}</Stack>}
    />
  );
};

export default AppointmentHistoryLogsAppointmentHistoryLogsSkeleton;
