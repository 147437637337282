import { Box, Grid } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useSelector } from "store";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";

import CheckoutPaymentsSummary from "../CheckoutPaymentsSummary";
import CheckoutRefundInvoiceArticlesSection from "./CheckoutRefundInvoiceArticlesSection";

const CheckoutRefund = () => {
  const { invoiceId } = useCheckoutModal();
  const invoice = useSelector(selectInvoiceById(invoiceId ?? -1));

  if (!invoice) throw new Error("Invoice not found");

  return (
    <Box p={4} display="grid" gap={3}>
      <CheckoutRefundInvoiceArticlesSection invoice={invoice} />

      <Grid container justifyContent={{ md: "flex-end" }}>
        <Grid item md={5} xs={12} pr={{ md: 4 }}>
          <CheckoutPaymentsSummary invoice={invoice} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutRefund;
