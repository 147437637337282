import { Invoice, InvoiceArticle } from "types/Checkout";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutRefundInvoiceArticle from "./CheckoutRefundInvoiceArticle";

type CheckoutRefundInvoiceArticlesSectionProps = {
  invoice: Invoice;
};

const CheckoutRefundInvoiceArticlesSection = ({
  invoice,
}: CheckoutRefundInvoiceArticlesSectionProps) => {
  const { watch, setValue } = useCheckoutFormContext();

  const { refundInvoice } = watch();

  const { articlesToRefund = [] } = refundInvoice || {};

  const checkIsArticleBeingRefunded = (articleId: InvoiceArticle["id"]) =>
    articlesToRefund?.includes(articleId);

  const handleToggleRefund = (articleId: InvoiceArticle["id"]) => {
    const isArticleBeingRefunded = checkIsArticleBeingRefunded(articleId);

    if (isArticleBeingRefunded) {
      // Remove article from articlesToRefund
      const filteredArticlesToRefund = articlesToRefund?.filter((id) => id !== articleId);
      setValue("refundInvoice.articlesToRefund", filteredArticlesToRefund);
      // reset refund refundPayments
      setValue("refundInvoice.refundPayments", []);
    } else {
      // Add article to articlesToRefund
      setValue("refundInvoice.articlesToRefund", [...articlesToRefund, articleId]);
    }
  };

  return (
    <>
      {invoice?.articles.map((article) => (
        <CheckoutRefundInvoiceArticle
          key={article.id}
          invoiceArticle={article}
          isSelected={checkIsArticleBeingRefunded(article.id)}
          onToggleRefund={() => handleToggleRefund(article.id)}
        />
      ))}
    </>
  );
};

export default CheckoutRefundInvoiceArticlesSection;
