import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./invoicePaymentsApi";

const invoicePaymentsAdapter = createEntityAdapter();

const initialState = invoicePaymentsAdapter.getInitialState({
  isLoading: false,
  error: "",
});

const { selectById, selectAll } = invoicePaymentsAdapter.getSelectors(
  (state) => state.invoicePayments
);

export const selectAllInvoicePayments = selectAll;

export const selectInvoicePaymentById = (id) => (state) =>
  selectById(state, id);

export const selectInvoicePaymentLoading = (state) => state.isLoading;

export const getAllInvoicePayments = createThunkFromApi(
  "invoicePayments/getAllInvoicePayments",
  api.getAllInvoicePayments
);

export const getInvoicePayment = createThunkFromApi(
  "invoicePayments/getInvoicePayment",
  api.getInvoicePayment
);

export const createInvoicePayment = createThunkFromApi(
  "invoicePayments/createInvoicePayment",
  api.createInvoicePayment
);

export const updateInvoicePayment = createThunkFromApi(
  "invoicePayments/updateInvoicePayment",
  api.updateInvoicePayment
);

export const deleteInvoicePayment = createThunkFromApi(
  "invoicePayments/removeInvoicePayment",
  api.removeInvoicePayment
);

export const invoicePaymentsSlice = createSlice({
  name: "invoicePayments",
  initialState,
  extraReducers: {
    [getAllInvoicePayments.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllInvoicePayments.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      invoicePaymentsAdapter.setAll(state, payload.invoicePayments);
    },

    [getAllInvoicePayments.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getInvoicePayment.fulfilled]: (state, { payload }) => {
      invoicePaymentsAdapter.setOne(state, payload);
    },

    [createInvoicePayment.fulfilled]: (state, { payload }) => {
      invoicePaymentsAdapter.addOne(state, payload);
    },

    [updateInvoicePayment.fulfilled]: (state, { payload }) => {
      invoicePaymentsAdapter.updateOne(state, {
        id: payload.id,
        changes: payload.changes,
      });
    },

    [deleteInvoicePayment.fulfilled]: (state, { payload: id }) => {
      invoicePaymentsAdapter.removeOne(state, id);
    },
  },
});

export default invoicePaymentsSlice.reducer;
