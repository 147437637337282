export enum SubscriptionSidebarStatusOptions {
  allSubscriptions = "ALL SUBSCRIPTIONS",
  sold = "SOLD",
}

// Disaster Alert: make sure no one messes with the type, create should have its own type, this type is used for the store and brief subscription api response. i'm not sure what's the best approach here. - Anfal
export type Subscription = {
  id: number;

  name: string;
  itemName: string;
  description?: string;

  validityInDays: number;
  price: string;
  count: number;

  online: boolean;
  active: boolean;
  deprecated: boolean;

  organization: number;
  serviceVariant?: Nullable<number>;
  packageVariant?: Nullable<number>;

  serviceVariants?: number[];

  sellingOutlets: number[];
  redeemingOutlets: number[];

  createdAt?: string;
  updatedAt?: string;

  purchaseCount: number;
};

// used for client subscriptions
export type ClientSubscriptionPurchase = {
  id: number;
  name: string;
  itemName: string;

  serviceVariant: Nullable<number>;
  serviceVariants: number[];
  packageVariant: Nullable<number>;

  remainingRedemptionsCount: number;
  totalCreditsCount: number;

  validityInDays: number;

  createdAt: string;

  // has been purchased online with a user account with a mobile phone number that matches the PP client's phone number
  isRelatedToOnlineProfile: boolean;
  outlets: number[];
};

export type ProcessedSubscriptionPurchase = ClientSubscriptionPurchase & {
  expirationDate: string;
};

export enum RedeemableSubscriptionItemOption {
  ServiceVariants = "serviceVariants",
  PackageVariant = "packageVariant",
}
