import { Close } from "@bookpeep/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, IconButton, TextField } from "@mui/material";
import ResponsiveModal from "components/common/Modals/ResponsiveModal";
import useFormValidation from "hooks/useFormValidation";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { GiftVoucherPurchase } from "types/VoucherPurchase";
import { z } from "zod";

import EmailGiftVoucherModalActionButtons from "./EmailGiftVoucherModalActionButtons";
import emailGiftVoucherSchema from "./emailGiftVoucherSchema";

type VouchersEditModalProps = {
  giftVoucherPurchase: GiftVoucherPurchase;
  isEmailModalOpen: boolean;
  setIsEmailModalOpen: Dispatch<SetStateAction<boolean>>;
};

export type EmailGiftVoucherFormInput = z.infer<typeof emailGiftVoucherSchema>;

const EmailGiftVoucherModal = ({
  giftVoucherPurchase,
  isEmailModalOpen,
  setIsEmailModalOpen,
}: VouchersEditModalProps) => {
  const { t } = useTranslation("vouchers");

  const methods = useForm<EmailGiftVoucherFormInput>({
    defaultValues: {
      giftVoucherId: 0,
      email: "",
    },
    resolver: zodResolver(emailGiftVoucherSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setValue, register, formState } = methods;

  useEffect(() => {
    setValue("giftVoucherId", giftVoucherPurchase.id);
    setValue("email", giftVoucherPurchase.email);
  }, [setValue, giftVoucherPurchase]);

  const registerValidation = useFormValidation(formState, { ns: "vouchers" });

  return (
    <FormProvider {...methods}>
      <ResponsiveModal
        title={t("sendEmail")}
        onClose={() => setIsEmailModalOpen(false)}
        open={isEmailModalOpen}
        mobileActions={
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsEmailModalOpen(false)}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        <Box width="100%" gap={3}>
          <Box p={3}>
            <TextField
              fullWidth
              label={t("email")}
              {...register("email")}
              {...registerValidation("email")}
            />
          </Box>

          <EmailGiftVoucherModalActionButtons onClose={() => setIsEmailModalOpen(false)} />
        </Box>
      </ResponsiveModal>
    </FormProvider>
  );
};

export default EmailGiftVoucherModal;
