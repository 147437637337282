import stringifyArray from "helpers/stringifyArray";
import http from "utils/http";

const clientsApi = {
  getClientSearchResults: async ({ query, resultCount }) =>
    http.get(`clients/clients_search/?query=${query.trim()}&result_count=${resultCount}`),

  getAllClients: async ({ page, tagOptions, ordering, searchQuery }) =>
    http.get(
      `clients/clients/?page=${page}${
        tagOptions && !!tagOptions.length
          ? `&tags=${stringifyArray(tagOptions.join().split(","))}`
          : ""
      }${ordering && !!ordering.length ? `&order_by=${ordering[0]}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  getClient: async (id) => http.get(`clients/clients/${id}/`),

  createClient: async ({ data }) => http.post("clients/clients/", data),

  updateClient: async ({ id, data }) => http.put(`clients/clients/${id}/`, data),

  removeClient: async (id) => http.delete(`clients/clients/${id}/`),

  discountUpdate: async ({ id, data }) => http.put(`clients/${id}/update-discount/`, data),

  createWalletTransaction: async ({ data }) => http.post(`wallets/deduct/`, data),

  getWalletDetails: async ({ id }) => http.get(`wallets/details/${id}/`),
};

export default clientsApi;
