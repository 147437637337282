import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/industryCategories/industryCategoriesApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { IndustryCategory } from "types/IndustryCategory";

import { SliceStatus } from "../utils";

export const industryCategoryAdapter = createEntityAdapter<IndustryCategory>({
  selectId: (industryCategory) => industryCategory.id,
});

const initialState = industryCategoryAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getIndustryCategories = createThunkFromApiWithType(
  "industryCategories/getIndustryCategories",
  api.getIndustryCategories
);

export const industryCategoriesSlice = createSlice({
  name: "industryCategories",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getIndustryCategories.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getIndustryCategories.fulfilled, (state, { payload }) => {
      industryCategoryAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getIndustryCategories.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default industryCategoriesSlice.reducer;
