import { Box, Button, Divider, Stack, Typography, styled } from "@mui/material";
import { DepositIcon, PeepPayIcon } from "assets/icon";
import SideBarFilterCheckbox from "components/common/SideBarFilterCheckbox";
import { dontForwardProps } from "helpers/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PeepPayLinkType } from "store/slices/peepPayLinks/peepPayLinksApi";
import { PeepPayLinkStatus } from "types/Deposit";

import PeepPayLinksContext from "./PeepPayLinksContext";

const PeepPayLinksSideBarFilters = () => {
  const { t } = useTranslation("home");

  const { setPage, linkStatuses, setLinkStatuses, type, setType, setOrdering } =
    useContext(PeepPayLinksContext);

  function handleSelectLinkStatus(option: string): () => void {
    return () => {
      if (linkStatuses.includes(PeepPayLinkStatus[option as keyof typeof PeepPayLinkStatus])) {
        setLinkStatuses(
          linkStatuses.filter(
            (status) => status !== PeepPayLinkStatus[option as keyof typeof PeepPayLinkStatus]
          )
        );
      } else {
        setLinkStatuses([
          ...linkStatuses,
          PeepPayLinkStatus[option as keyof typeof PeepPayLinkStatus],
        ]);
      }
      setPage(1);
    };
  }

  function handleSwitchType(option: string): () => void {
    return () => {
      setType(PeepPayLinkType[option as keyof typeof PeepPayLinkType]);
      setOrdering([]);
      setPage(1);
    };
  }

  return (
    <>
      <Box>
        <Box pb={2}>
          <Typography variant="primary" color="#848FA1" fontWeight="bold">
            {t("peepPayLinks").toUpperCase()}
          </Typography>
        </Box>

        {Object.keys(PeepPayLinkType).map((option) => (
          <StatusButton
            key={option}
            active={type === PeepPayLinkType[option as keyof typeof PeepPayLinkType]}
            variant="text"
            color="secondary"
            fullWidth
            onClick={handleSwitchType(option)}
          >
            <Box display="flex" flexDirection="row" alignItems="center" height="100%">
              <DepositIcon fontSize="small" sx={{ mr: 1, fill: "#4048D6", stroke: "#4048D6" }} />

              <Typography variant="primary" fontWeight={700}>
                {t(option)}
              </Typography>
            </Box>
          </StatusButton>
        ))}
      </Box>

      <Divider variant="fullWidth" sx={{ my: 3 }} />

      <Box>
        <Box pb={2}>
          <Typography variant="primary" color="#848FA1" fontWeight="bold">
            {t("linkStatus").toUpperCase()}
          </Typography>
        </Box>

        {Object.keys(PeepPayLinkStatus).map((option) => (
          <SideBarFilterCheckbox
            key={option}
            isChecked={linkStatuses.includes(
              PeepPayLinkStatus[option as keyof typeof PeepPayLinkStatus]
            )}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <PeepPayIcon
                  viewBox="0 0 56 24"
                  sx={{
                    width: 56,
                    height: 24,
                  }}
                />
                <Typography variant="primary" fontWeight={700}>
                  {t(option.toLowerCase())}
                </Typography>
              </Stack>
            }
            value={option}
            onClick={handleSelectLinkStatus(option)}
          />
        ))}
      </Box>
    </>
  );
};

export default PeepPayLinksSideBarFilters;

type StatusButtonProps = {
  active: boolean;
};

const StatusButton = styled(
  Button,
  dontForwardProps("active")
)<StatusButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.bg.secondary : "transparent",
  color: active ? theme.palette.text.secondary : theme.palette.text.primary,
  minHeight: "auto",
  "&:hover": {
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.text.secondary,
  },

  justifyContent: "flex-start",
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
