/**
 * if value is null or undefined, return "-" else return value with prefix or suffix if provided
 * @param value
 * @param options
 * @returns
 */

type Options = {
  suffix?: string;
  prefix?: string;
  emptyValueRepresentation?: string;
};

const renderNullableValue = (
  value: string | null | undefined | number,
  options?: Options
): string => {
  const { prefix = "", suffix = "", emptyValueRepresentation = "-" } = options || {};

  return value ? `${prefix}${value}${suffix}` : emptyValueRepresentation;
};
export default renderNullableValue;
