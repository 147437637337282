import { PackageCategory } from "types/Package";
import http from "utils/http";

type PackageCategoryArguments = Omit<PackageCategory, "id">;

const packageCategoriesApi = {
  getAllPackageCategories: async () =>
    http.get<PackageCategory[]>(`organizations/v2/package_categories/`),

  createPackageCategory: async ({ data }: { data: PackageCategoryArguments }) =>
    http.post<PackageCategory>("organizations/v2/package_categories/", data),

  updatePackageCategory: async ({ id, data }: { id: number; data: PackageCategoryArguments }) =>
    http.put<PackageCategory>(`organizations/v2/package_categories/${id}/`, data),

  updatePackageCategoryPosition: async ({ data }: { data: PackageCategory[] }) =>
    http.put<PackageCategory[]>(`organizations/v2/update_package_category_positions/`, data),

  removePackageCategory: async ({ id }: { id: number }) =>
    http.delete(`organizations/v2/package_categories/${id}/`),
};

export default packageCategoriesApi;
