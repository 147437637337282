import { Drawer, Grid } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useIsMobileView from "hooks/useIsMobileView";
import AppointmentForm from "pages/CalendarPage/components/AppointmentForm";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { AppointmentDrawerMode } from "store/slices/appointmentDrawer/appointmentDrawerSlice";

import AppointmentDetails from "./AppointmentDetails";
import AppointmentDrawerClientProfile from "./AppointmentDrawerClientProfile";
import ToggleActiveDrawerSideSection from "./AppointmentDrawerClientProfile/AppointmentDrawerClientProfileTabs/ToggleActiveDrawerSideSection";
import AppointmentDrawerExpansionButton from "./AppointmentDrawerExpansionButton";
import AppointmentDrawerHeader from "./AppointmentDrawerHeader";
import { COLLAPSED_DRAWER_WIDTH_FOR_DESKTOP, EXPANDED_DRAWER_WIDTH_FOR_DESKTOP } from "./constants";

const AppointmentDrawer = () => {
  const isMobileView = useIsMobileView();
  const { pathname } = useLocation();

  const {
    closeAppointmentDrawer,
    isAppointmentDrawerOpen,
    appointmentId,
    outletId,
    clientId,
    appointmentDrawerView,
    isAppointmentDrawerExpanded,
    isAppointmentLoading,
    setIsAppointmentDrawerExpanded,
  } = useAppointmentDrawer();

  const handleToggleDrawerExpansion = () =>
    setIsAppointmentDrawerExpanded(!isAppointmentDrawerExpanded);

  const appointmentDetails = useSelector(selectCheckoutAppointmentById(appointmentId ?? undefined));

  const currentClient = appointmentDetails ? appointmentDetails?.client : clientId;

  const currentOutlet = appointmentDetails ? appointmentDetails.outlet : outletId;

  const paperStyles = useMemo(
    () => getPaperStyles(isAppointmentDrawerExpanded),
    [isAppointmentDrawerExpanded]
  );

  const shouldShowAppointmentDrawerExpansionButton =
    !isMobileView && isAppointmentDrawerOpen && currentClient;

  const shouldShowClientProfileSide = isAppointmentDrawerExpanded && currentClient && currentOutlet;

  const shouldShowAppointmentSide = isMobileView ? !isAppointmentDrawerExpanded : true;

  const shouldShowToggleActiveDrawerSideSection = isMobileView && currentClient;

  const isViewAppointmentMode = appointmentDrawerView === AppointmentDrawerMode.ViewAppointment;

  const shouldCloseAppointmentDrawerBecauseOfRouteChange = useMemo(
    () =>
      !pathname.includes("calendar") &&
      isAppointmentDrawerOpen &&
      (appointmentDrawerView === AppointmentDrawerMode.CreateAppointment ||
        appointmentDrawerView === AppointmentDrawerMode.EditAppointment),

    [pathname, isAppointmentDrawerOpen, appointmentDrawerView]
  );

  // This useEffect is required because Click away listener cannot be used to handle create and edit AppointmentDrawerModes because it will close the drawer when you click on the select status field
  useEffect(() => {
    if (shouldCloseAppointmentDrawerBecauseOfRouteChange) {
      closeAppointmentDrawer();
    }
  }, [shouldCloseAppointmentDrawerBecauseOfRouteChange, closeAppointmentDrawer]);

  // TODO: replace this with skeleton loader
  if (isAppointmentLoading) return <CentredSpinnerBox />;

  return (
    <Drawer
      variant={isViewAppointmentMode ? "temporary" : "persistent"}
      anchor={isMobileView ? "bottom" : "right"}
      onClose={closeAppointmentDrawer}
      open={isAppointmentDrawerOpen}
      PaperProps={{ sx: paperStyles }}
      sx={{
        overflow: "hidden",
      }}
    >
      <AppointmentDrawerHeader />

      {shouldShowToggleActiveDrawerSideSection && (
        <ToggleActiveDrawerSideSection
          isAppointmentSectionActive={isAppointmentDrawerExpanded}
          handleToggleActiveDrawerSide={handleToggleDrawerExpansion}
        />
      )}

      <Grid container flexGrow={1} overflow="hidden">
        {shouldShowClientProfileSide && (
          <Grid
            item
            xs={12}
            md={5.5}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            height="100%"
            borderRight="1.5px solid rgba(0, 0, 0, 0.12)"
          >
            <AppointmentDrawerClientProfile clientId={currentClient} outletId={currentOutlet} />
          </Grid>
        )}

        {shouldShowAppointmentSide && (
          <Grid
            item
            xs={12}
            md={isAppointmentDrawerExpanded ? 6.5 : 12}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            height="100%"
          >
            {appointmentDrawerView === AppointmentDrawerMode.CreateAppointment && (
              <AppointmentForm
                onClose={closeAppointmentDrawer}
                existingClientActionButton={
                  shouldShowAppointmentDrawerExpansionButton ? (
                    <AppointmentDrawerExpansionButton
                      isExpanded={isAppointmentDrawerExpanded}
                      onClick={handleToggleDrawerExpansion}
                    />
                  ) : undefined
                }
              />
            )}

            {appointmentDrawerView === AppointmentDrawerMode.EditAppointment && (
              <AppointmentForm
                onClose={closeAppointmentDrawer}
                existingClientActionButton={
                  shouldShowAppointmentDrawerExpansionButton ? (
                    <AppointmentDrawerExpansionButton
                      isExpanded={isAppointmentDrawerExpanded}
                      onClick={handleToggleDrawerExpansion}
                    />
                  ) : undefined
                }
              />
            )}

            {appointmentDrawerView === AppointmentDrawerMode.ViewAppointment && appointmentId && (
              <AppointmentDetails
                appointmentId={appointmentId}
                existingClientActionButton={
                  shouldShowAppointmentDrawerExpansionButton ? (
                    <AppointmentDrawerExpansionButton
                      isExpanded={isAppointmentDrawerExpanded}
                      onClick={handleToggleDrawerExpansion}
                    />
                  ) : undefined
                }
              />
            )}
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default AppointmentDrawer;

const getPaperStyles = (isExpanded: boolean) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  height: "100vh",
  maxHeight: "-webkit-fill-available",
  width: {
    xs: "100vw",
    md: isExpanded ? EXPANDED_DRAWER_WIDTH_FOR_DESKTOP : COLLAPSED_DRAWER_WIDTH_FOR_DESKTOP,
  },
  boxShadow: "7px 4px 46px 0px #00000040",

  borderTopLeftRadius: {
    md: 0,
    xs: 4,
  },
  borderTopRightRadius: {
    md: 0,
    xs: 4,
  },
});
