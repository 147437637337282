import type { RootState } from "store";
import { ProductBrandDetails } from "types/Brand";

import { SliceStatus } from "../utils";
import { productBrandsAdapter } from "./productBrandsSlice";

export const selectProductBrandsLoading = (state: RootState) =>
  state.productBrands.status === SliceStatus.LOADING;

export const selectProductBrandsTotalPages = (state: RootState) => state.productBrands.totalPages;

export const selectProductBrandsState = (state: RootState) => {
  return state.productBrands;
};

const { selectById, selectAll } = productBrandsAdapter.getSelectors(selectProductBrandsState);

export const selectAllProductBrands = selectAll;

export const selectProductBrandById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectAllProductBrandsForList = (state: RootState) => {
  const brands: ProductBrandDetails[] = [];
  state.productBrands.page.forEach((brandId) => {
    const brand = selectById(state, brandId);
    if (brand) brands.push(brand);
  });

  return brands;
};
