import BasicNailsCategory from "assets/images/Category=BasicNails.svg";
import BridalCategory from "assets/images/Category=Bridal.svg";
import CurlyCareCategory from "assets/images/Category=CurlyCare.svg";
import CurlyStylingCategory from "assets/images/Category=CurlyStyling.svg";
import HairCareCategory from "assets/images/Category=HairCare.svg";
import HairColoringCategory from "assets/images/Category=HairColoring.svg";
import HairCutCategory from "assets/images/Category=HairCut.svg";
import HairStyleCategory from "assets/images/Category=HairStyle.svg";
import LashesCategory from "assets/images/Category=Lashes.svg";
import MakeupCategory from "assets/images/Category=Makeup.svg";
import MassageCategory from "assets/images/Category=Massage.svg";
import NailExtensionsCategory from "assets/images/Category=NailExtensions.svg";
import NailPolishCategory from "assets/images/Category=NailPolish.svg";
import NailCategory from "assets/images/Category=Nails.svg";
import NailTreatmentCategory from "assets/images/Category=NailTreatment.svg";
import PermanentMakeupCategory from "assets/images/Category=PermanentMakeup.svg";
import PersonalCareCategory from "assets/images/Category=PersonalCare.svg";
import SpaCategory from "assets/images/Category=Spa.svg";
import { CSSProperties } from "react";

export const getIndustryCategoryIconById = (
  id: number,
  style: CSSProperties = { width: "45px" }
) => {
  switch (id) {
    case 1:
      return <img style={{ ...style }} src={MakeupCategory} alt="Makeup" />;
    case 2:
      return <img style={{ ...style }} src={HairColoringCategory} alt="Hair Coloring" />;
    case 3:
      return <img style={{ ...style }} src={MassageCategory} alt="Massage" />;
    case 4:
      return <img style={{ ...style }} src={HairCareCategory} alt="Hair Care" />;
    case 5:
      return <img style={{ ...style }} src={LashesCategory} alt="Lashes" />;
    case 6:
      return <img style={{ ...style }} src={HairCutCategory} alt="Hair Cut" />;
    case 7:
      return <img style={{ ...style }} src={PermanentMakeupCategory} alt="Permanent Makeup" />;
    case 8:
      return <img style={{ ...style }} src={BridalCategory} alt="Bridal" />;
    case 9:
      return <img style={{ ...style }} src={PersonalCareCategory} alt="Personal Care" />;
    case 10:
      return <img style={{ ...style }} src={SpaCategory} alt="Spa" />;
    case 11:
      return <img style={{ ...style }} src={NailCategory} alt="Nails" />;
    case 12:
      return <img style={{ ...style }} src={HairStyleCategory} alt="Hair Style" />;
    case 13:
      return <img style={{ ...style }} src={BasicNailsCategory} alt="Basic Nails" />;
    case 14:
      return <img style={{ ...style }} src={NailTreatmentCategory} alt="Nail Treatment" />;
    case 15:
      return <img style={{ ...style }} src={NailPolishCategory} alt="Nail Polish" />;
    case 16:
      return <img style={{ ...style }} src={NailExtensionsCategory} alt="Nail Extensions" />;
    case 17:
      return <img style={{ ...style }} src={CurlyStylingCategory} alt="Curly Styling" />;
    case 18:
      return <img style={{ ...style }} src={CurlyCareCategory} alt="Curly Care" />;

    default:
      return <img style={{ ...style }} src={NailCategory} alt="Nails" />;
  }
};
