import { Box, Button, Typography, styled } from "@mui/material";
import CreatedByFilteredItem from "components/common/Chips/CreatedByFilteredItem";
import StatusFilteredItem from "components/common/Chips/StatusFilteredItem";
import useIsMobileView from "hooks/useIsMobileView";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllAreas } from "store/slices/areas/areasSelector";
import { AppointmentSource } from "types/Appointment";

import AppointmentsOverviewContext from "./AppointmentsOverviewContext";

const FiltersSection = () => {
  const { t } = useTranslation(["home", "translation"]);

  const isMobileView = useIsMobileView();

  const areasState = useSelector(selectAllAreas);

  const {
    invoiceStatusOptions,
    setInvoiceStatusOptions,

    depositOptions,
    setDepositOptions,

    bookingSourceOptions,
    setBookingSourceOptions,

    setPage,

    createdBy,
    setCreatedBy,

    areas,
    setAreas,

    clearAllFilters,
  } = useContext(AppointmentsOverviewContext);

  const removeInvoiceStatusByName = (statusOptionToBeRemove: string) => () => {
    setInvoiceStatusOptions(
      invoiceStatusOptions.filter((status) => status !== statusOptionToBeRemove)
    );
  };

  const removeArea = (areaToBeRemoved: number) => () => {
    setAreas(areas.filter((area) => area !== areaToBeRemoved));
  };

  const removeCreatedById = (id: number) => () => {
    setCreatedBy(createdBy.filter((employeeId) => employeeId !== id));
  };

  const removeDepositOptionByName = (depositOptionToRemove: string) => () => {
    setDepositOptions(depositOptions.filter((option) => option !== depositOptionToRemove));
  };

  const removeBookingSourceByName = (bookingSourceToRemove: AppointmentSource) => () => {
    setPage(1);
    setBookingSourceOptions(
      bookingSourceOptions.filter((source) => source !== bookingSourceToRemove)
    );
  };

  const shouldShowFilterSection =
    invoiceStatusOptions.length > 0 ||
    depositOptions.length > 0 ||
    createdBy.length > 0 ||
    bookingSourceOptions.length > 0;

  if (!shouldShowFilterSection) return null;

  return (
    <SectionWrapper isMobileView={isMobileView}>
      <FilterItem>
        {!!areas.length && (
          <>
            <Typography mr={1} variant="secondary" color="text.label">
              {t("home:area")}:
            </Typography>

            {areas.map((area) => {
              const foundArea = areasState.find((areaObj) => areaObj.id === area);
              return (
                <StatusFilteredItem
                  chipColor="#EBF3FE"
                  key={foundArea?.name || area}
                  label={foundArea?.name || "-"}
                  onDelete={removeArea(area)}
                />
              );
            })}
          </>
        )}
      </FilterItem>

      <FilterItem>
        {!!invoiceStatusOptions.length && (
          <>
            <Typography mr={1} variant="secondary" color="text.label">
              {t("home:paymentStatus")}:
            </Typography>

            {invoiceStatusOptions.map((status) => (
              <StatusFilteredItem
                chipColor="#EBF3FE"
                key={status}
                label={t(status)}
                onDelete={removeInvoiceStatusByName(status)}
              />
            ))}
          </>
        )}
      </FilterItem>

      <FilterItem>
        {!!bookingSourceOptions.length && (
          <>
            <Typography mr={1} variant="secondary" color="text.label">
              {t("translation:bookingSource")}:
            </Typography>

            {bookingSourceOptions.map((source) => (
              <StatusFilteredItem
                chipColor="#EBF3FE"
                key={source}
                label={t(source.toLocaleLowerCase(), { ns: "translation" })}
                onDelete={removeBookingSourceByName(source)}
              />
            ))}
          </>
        )}
      </FilterItem>

      <FilterItem>
        {!!depositOptions.length && (
          <>
            <Typography mr={1} variant="secondary" color="text.label">
              {t("home:deposit")}:
            </Typography>

            {depositOptions.map((option) => (
              <StatusFilteredItem
                chipColor="#EBF3FE"
                key={option}
                label={t(option)}
                onDelete={removeDepositOptionByName(option)}
              />
            ))}
          </>
        )}
      </FilterItem>

      <FilterItem>
        {!!createdBy.length && (
          <>
            <Typography mr={1} variant="secondary" color="text.label">
              {t("translation:createdBy")}:
            </Typography>

            {createdBy.map((employeeId) => (
              <CreatedByFilteredItem
                chipColor="#EBF3FE"
                key={employeeId}
                employeeId={employeeId}
                onDelete={removeCreatedById(employeeId)}
              />
            ))}
          </>
        )}
      </FilterItem>

      <FilterItem>
        <StyledButton variant="text" disableRipple onClick={clearAllFilters}>
          {t("translation:clearAll")}
        </StyledButton>
      </FilterItem>
    </SectionWrapper>
  );
};

export default FiltersSection;

type SectionWrapperProps = {
  isMobileView: boolean;
};

const SectionWrapper = styled(Box)<SectionWrapperProps>(({ theme, isMobileView }) => ({
  backgroundColor: theme.palette.common.white,
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  margin: isMobileView ? 0 : theme.spacing(3),
  marginBottom: theme.spacing(3),

  borderRadius: 5,
  [theme.breakpoints.down("md")]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    height: "65px",
    overflowY: "hidden",
  },
}));

const FilterItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
}));

const StyledButton = styled(Button)(() => ({
  fontSize: "1rem",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
