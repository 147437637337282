import { CalendarCard } from "./Calendar";
import { Invoice, OnlinePayment } from "./Checkout";
import { AppointmentDeposit } from "./Deposit";
import { LoyaltyProgram } from "./LoyaltyProgram";
import { PackageVariantWithPriceAndDuration } from "./Package";
import { DiscountOption } from "./promotions";
import { ServiceVariantWithService } from "./ServiceV2";

export enum AppointmentStatusOptions {
  New = "NEW",
  Arrived = "ARRIVED",
  Started = "STARTED",
  Completed = "COMPLETED",
  Canceled = "CANCELLED",
  Waiting = "WAITING",
  Confirmed = "CONFIRMED",
  NoShow = "NO SHOW",
}

export type AppointmentTimeRange = {
  startTime: string;
  endTime: string;
};

export enum AppointmentLogActionOptions {
  Create = "CREATE",
  Edit = "EDIT",
  Cancel = "CANCEL",
  Checkout = "CHECKOUT",
  DepositAdded = "DEPOSIT ADDED",
  DepositRemoved = "DEPOSIT REMOVED",
  UpdatedPricing = "UPDATED PRICING",
}

export type AppointmentLog = {
  id: number;
  action: AppointmentLogActionOptions;
  createdBy: string;
  isEmployee: boolean;
  createdAt: string;
  appointment: number;
};

export enum AppointmentSource {
  PP = "PP",
  OB = "OB",
  MP = "MP",
}

export type Appointment = {
  id: number;
  articles: number[];

  date: string;
  appointmentTimeRange: AppointmentTimeRange;

  status: AppointmentStatusOptions;
  createdOnline: boolean;
  source: AppointmentSource;

  createdAt: string;
  updatedAt: string;

  notes: Nullable<string>;

  organization: number;
  outlet: number;

  client: Nullable<number>;
  onlineProfile: Nullable<number>;

  area: Nullable<number>;
  address: Nullable<number>;

  total: string;
  isAwaitingOnlinePayment: boolean;

  // invoices is an array of god knows what
  invoices?: number[];
  onlineInvoices?: number[];

  logs: AppointmentLog[];

  deposit: Nullable<AppointmentDeposit>;
  discountCode: Nullable<DiscountCode>;

  rewardLoyaltyCards: CheckoutLoyaltyCard[];
};

export type CheckoutLoyaltyCard = {
  id: number;
  name: LoyaltyProgram["name"];
  isMpUser: boolean;
  totalEarningsToReward: LoyaltyProgram["totalEarningsToReward"];
  earningsType: LoyaltyProgram["earningsType"];
  amountToPunch: LoyaltyProgram["amountToPunch"];
  totalEarnedPunches: number;
};

export type GetAppointmentDetailsResponse = {
  appointment: Appointment;
  articles: AppointmentArticleWithSlot[];
  slots: AppointmentArticleSlot[];
  invoices: Invoice[];
  onlinePayments: OnlinePayment[];
  calendarCards: CalendarCard[];
  deposit: AppointmentDeposit;
  discountCode: DiscountCode;
};

export type DiscountCode = {
  code: string;
  discountType: DiscountOption;
  discountValue: string;
  isPeepDiscount: boolean;
};

export type AppointmentWithArticleDetails = Omit<Appointment, "articles"> & {
  articles: AppointmentArticleWithDetails[];
};

type Staff = {
  id: number;
  firstName: string;
  lastName: string;
};

export type AppointmentArticleSlotStaff = {
  id: number;
  hasCalendar: boolean;
  profile: Staff;
  user: Staff;
};

export type AppointmentArticleSlot = {
  id: number;
  isStaffSelectedByClient: boolean;
  price: string;
  start: string;
  end: string;
  createdAt: string;
  updatedAt: string;
  serviceVariant: number;
  organization: number;
  article: number;
  staff: number;
  resource: number;
};

type SubscriptionRedemption = {
  id: number;
  name: string;
  subscriptionPurchaseId: number;
};

export type AppointmentArticle = {
  id: number;
  appointment: number;
  slots: number[];
  totalDuration: number;

  serviceVariant: Nullable<number>;
  packageVariant: Nullable<number>;
  flexService: Nullable<number>;

  originalPrice: string;
  markedPrice: Nullable<string>;
  finalPrice: string;

  promotion: Nullable<number>;
  subscriptionRedemption: Nullable<SubscriptionRedemption>;
  rewardLoyaltyCard: Nullable<number>;

  creationSource: AppointmentSource;
  createdAt: string;
  updatedAt: string;
};

export type AppointmentArticleWithDetails = Omit<
  AppointmentArticle,
  "serviceVariant" | "packageVariant" | "slots"
> & {
  serviceVariant: Nullable<ServiceVariantWithService>;
  packageVariant: Nullable<PackageVariantWithPriceAndDuration>;
  slots: AppointmentArticleSlot[];
};

export type AppointmentArticleWithSlot = Omit<AppointmentArticle, "slots"> & {
  slots: AppointmentArticleSlot[];
};

export type AppointmentHome = {
  id: number;
  status: AppointmentStatusOptions;

  date: string;
  startTime: string;

  total: number;
  deposit: number;
  outlet: string;
  area: Nullable<string>;
  zone: Nullable<string>;

  createdOnline: boolean;
  source: AppointmentSource;

  client: {
    id: number;
    firstName: string;
    lastName: string;
  };

  invoice: {
    id: number;
    prefix: string;
    status: string;
  };

  createdAt: string;
};

//  the response form using appointment details endpoint
export type AppointmentDetailsResponse = Omit<Appointment, "articles"> & {
  articles: AppointmentDetailsResponseArticle[];
};

export type AppointmentDetailsResponseArticle = Omit<AppointmentArticle, "slots"> & {
  slots: AppointmentArticleSlot[];
};
