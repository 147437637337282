import { Box, Typography } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useCheckoutFormContext } from "modals/CheckoutModal/CheckoutFormContext";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectInvoiceById } from "store/slices/checkoutInvoices/checkoutInvoicesSelector";

import InvoiceTab from "./InvoiceTab";
import OriginalInvoiceTabSubtitle from "./OriginalInvoiceTabSubtitle";
import RefundInvoiceTabSubtitle from "./RefundInvoiceTabSubtitle";

const InvoiceTabs = () => {
  const { t } = useTranslation("invoices");

  const { activeTabInvoiceId, setInvoiceId } = useCheckoutModal();

  const { originalInvoiceId, refundInvoiceId } = useCheckoutFormContext();

  const refundInvoice = useSelector(selectInvoiceById(refundInvoiceId ?? -1));
  const originalInvoice = useSelector(selectInvoiceById(originalInvoiceId ?? -1));

  const getRefundInvoiceSubtitle = () => {
    if (refundInvoice)
      return (
        <RefundInvoiceTabSubtitle
          createdBy={refundInvoice?.createdBy || ""}
          createdAt={refundInvoice?.createdAt}
        />
      );
    else return <Typography>no refund invoice</Typography>;
  };

  const getOriginalInvoiceSubtitle = () => {
    if (originalInvoice) {
      // No payment was made because the invoice is free
      if (Number(originalInvoice.total) === 0)
        return (
          <OriginalInvoiceTabSubtitle
            createdBy={originalInvoice.createdBy || ""}
            createdAt={originalInvoice.createdAt || ""}
          />
        );

      const lastPayment = originalInvoice?.payments?.length
        ? originalInvoice.payments[originalInvoice.payments.length - 1]
        : null;

      const deposit = originalInvoice.deposit;

      const lastOnlinePayment = originalInvoice?.onlinePayments?.length
        ? originalInvoice?.onlinePayments[originalInvoice?.onlinePayments.length - 1]
        : null;

      if (lastPayment) {
        return (
          <OriginalInvoiceTabSubtitle
            createdBy={lastPayment?.createdBy || ""}
            createdAt={lastPayment?.createdAt || ""}
          />
        );
      } else {
        if (deposit)
          return <OriginalInvoiceTabSubtitle createdBy="" createdAt={deposit?.createdAt || ""} />;
        else {
          if (lastOnlinePayment)
            return (
              <OriginalInvoiceTabSubtitle
                createdBy=""
                createdAt={lastOnlinePayment?.createdAt || ""}
              />
            );
          else
            return (
              <OriginalInvoiceTabSubtitle
                createdBy={originalInvoice?.createdBy || ""}
                createdAt={originalInvoice?.createdAt || ""}
              />
            );
        }
      }
    } else return <Typography>no original invoice</Typography>;
  };

  const handleInvoiceTabClick = (invoiceId: Nullable<number>) => {
    if (!invoiceId) return;
    setInvoiceId(invoiceId);
  };

  return (
    <Box p={3} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h5" color="text.label">{`${t("invoices")}:`}</Typography>

      <InvoiceTab
        title={`${t("invoice")} #${refundInvoice?.number}`}
        subtitle={getRefundInvoiceSubtitle()}
        isActive={activeTabInvoiceId === refundInvoiceId}
        onClick={() => handleInvoiceTabClick(refundInvoiceId || null)}
      />

      <InvoiceTab
        title={`${t("invoice")} #${originalInvoice?.number}`}
        subtitle={getOriginalInvoiceSubtitle()}
        isActive={activeTabInvoiceId === originalInvoiceId}
        onClick={() => handleInvoiceTabClick(originalInvoiceId || null)}
      />
    </Box>
  );
};

export default InvoiceTabs;
