import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { updateArticleSlotPrice } from "store/appointments/appointmentsSlice";
import { selectArticleById } from "store/articles/articlesSlice";

import StartsFromChip from "../Chips/StartsFromChip";
import ConfirmationDialogModal, { ConfirmationModalSeverity } from "../ConfirmationDialogModal";
import PriceField from "../PriceField";

type EditArticleModalProps = {
  isEditArticleModalOpen: boolean;
  setIsEditArticleModalOpen: (state: boolean) => void;
  originalPrice: number;
  articleId: number;
  slotId: number;
};

export default function EditArticleModal({
  isEditArticleModalOpen,
  setIsEditArticleModalOpen,
  articleId,
  slotId,
}: EditArticleModalProps) {
  const { t } = useTranslation("appointments");

  const dispatch = useDispatch();

  const article = useSelector(selectArticleById(articleId));

  const originalPrice = Number(article.originalPrice);

  const [updatedPrice, setUpdatedPrice] = useState(Number(article.markedPrice));

  const isValid = updatedPrice >= originalPrice;

  const handleUpdatePrice = () => {
    dispatch(
      //@ts-expect-error
      updateArticleSlotPrice({
        data: {
          article_id: slotId,
          price: updatedPrice.toString(),
          redeemReward: article?.rewardLoyaltyCard,
        },
      })
    );
    setIsEditArticleModalOpen(false);
  };

  return (
    <ConfirmationDialogModal
      confirmButtonSeverity={ConfirmationModalSeverity.Moderate}
      isOpen={isEditArticleModalOpen}
      onClose={() => {
        setIsEditArticleModalOpen(false);
      }}
      onConfirmButtonClick={() => handleUpdatePrice()}
      shouldDisableConfirmButton={!isValid}
      title={t("updatePrice")}
      description={
        <Box display="flex" alignItems="center" gap={3}>
          <StartsFromChip price={originalPrice} />

          <PriceField
            value={updatedPrice}
            label={t("updatedPrice")}
            onChange={setUpdatedPrice}
            error={!isValid}
            helperText={!isValid && t("cantBeLess")}
          />
        </Box>
      }
    />
  );
}
