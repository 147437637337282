import { RootState } from "store";

import { depositsAdapter } from "./depositsSlice";

export const selectDepositState = (state: RootState) => {
  return state.deposits;
};

const { selectAll, selectById } = depositsAdapter.getSelectors(selectDepositState);

export const selectAllDeposits = selectAll;

export const selectDepositById = (id: number) => (state: RootState) => selectById(state, id);

export const selectDepositsByAppointmentId = (id: number) => (state: RootState) => {
  const deposits = selectAllDeposits(state);

  return deposits.find((deposit) => deposit.appointment === id);
};
