import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ResourceBlockTime } from "types/BlockTime";

import api from "./resourceBlockTimesApi";

export const resourceBlockTimesAdapter = createEntityAdapter<ResourceBlockTime>();

const initialState = resourceBlockTimesAdapter.getInitialState<{
  isLoading: boolean;
  isRequestPending: boolean;
  error: string;
}>({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

export const getAllResourceBlockTimes = createThunkFromApiWithType(
  "resourceBlockTimes/getAllResourceBlockTimes",
  api.getResourceBlockTimes
);

export const createResourceBlockTime = createThunkFromApiWithType(
  "resourceBlockTimes/createBlockTime",
  api.createResourceBlockTime
);

export const updateResourceBlockTime = createThunkFromApiWithType(
  "resourceBlockTimes/updateResourceBlockTime",
  api.updateResourceBlockTime
);

export const deleteResourceBlockTime = createThunkFromApiWithType(
  "resourceBlockTimes/deleteResourceBlockTime",
  api.deleteResourceBlockTime
);

export const resourceBlockTimesSlice = createSlice({
  name: "resourceBlockTimes",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllResourceBlockTimes.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(getAllResourceBlockTimes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        resourceBlockTimesAdapter.setAll(state, payload);
      })
      .addCase(getAllResourceBlockTimes.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })
      .addCase(createResourceBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(createResourceBlockTime.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        resourceBlockTimesAdapter.upsertOne(state, payload);
      })
      .addCase(createResourceBlockTime.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })
      .addCase(updateResourceBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(updateResourceBlockTime.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isRequestPending = false;
        resourceBlockTimesAdapter.upsertOne(state, payload);
      })
      .addCase(updateResourceBlockTime.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })
      .addCase(deleteResourceBlockTime.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(deleteResourceBlockTime.fulfilled, (state, { meta }) => {
        const id = meta.arg.id;
        state.isLoading = false;
        state.isRequestPending = false;
        resourceBlockTimesAdapter.removeOne(state, String(id));
      })
      .addCase(deleteResourceBlockTime.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      });
  },
});

export default resourceBlockTimesSlice.reducer;
