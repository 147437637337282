import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { VoucherPurchase } from "types/VoucherPurchase";

import { SliceStatus } from "../utils";
import api from "./voucherPurchasesApi";

export const voucherPurchasesAdapter = createEntityAdapter<VoucherPurchase>({
  selectId: (voucherPurchase) => voucherPurchase.id,
});

const initialState = voucherPurchasesAdapter.getInitialState<{
  status: SliceStatus;
  detailStatus: SliceStatus;
  next: string;
  previous: string;
  totalPages: number;
  results: VoucherPurchase[];
}>({
  status: SliceStatus.IDLE,
  detailStatus: SliceStatus.IDLE,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
});

export const getVoucherPurchases = createThunkFromApiWithType(
  "voucherPurchases/getVoucherPurchases",
  api.getVoucherPurchases
);

export const appendVoucherPurchases = createThunkFromApiWithType(
  "voucherPurchases/appendVoucherPurchases",
  api.getVoucherPurchases
);

export const getVoucherPurchaseDetails = createThunkFromApiWithType(
  "voucherPurchases/getVoucherDetails",
  api.getVoucherPurchaseDetails
);

export const extendVoucherPurchase = createThunkFromApiWithType(
  "voucherPurchases/extendVoucherPurchase",
  api.extendVoucherPurchase
);

export const voucherPurchasesSlice = createSlice({
  name: "voucherPurchases",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getVoucherPurchases.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getVoucherPurchases.fulfilled, (state, { payload }) => {
      voucherPurchasesAdapter.setAll(state, payload.results);
      state.totalPages = payload.totalPages;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getVoucherPurchases.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendVoucherPurchases.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendVoucherPurchases.fulfilled, (state, { payload }) => {
      voucherPurchasesAdapter.upsertMany(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendVoucherPurchases.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getVoucherPurchaseDetails.pending, (state) => {
      state.detailStatus = SliceStatus.LOADING;
    });

    reducers.addCase(getVoucherPurchaseDetails.fulfilled, (state, { payload }) => {
      voucherPurchasesAdapter.upsertOne(state, payload);
      state.detailStatus = SliceStatus.IDLE;
    });

    reducers.addCase(getVoucherPurchaseDetails.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(extendVoucherPurchase.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(extendVoucherPurchase.fulfilled, (state, { payload }) => {
      voucherPurchasesAdapter.upsertOne(state, payload)
      state.status = SliceStatus.IDLE;
    })
    reducers.addCase(extendVoucherPurchase.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default voucherPurchasesSlice.reducer;
