import { Box, Typography, styled } from "@mui/material";
import { VoucherIcon } from "assets/icon";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectActiveVouchersByClientIdAndOutletId,
  selectRedeemableVouchersByClientIdAndOutletIdAndServiceVariantIds,
} from "store/clients/clientSelectors";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import RedeemVoucherModal from "./ApplyVoucherFormModal";
import { getCartServiceVariantIds } from "./utils";

const RedeemVoucherButton = () => {
  const { t } = useTranslation("vouchers");

  const [isRedeemVoucherModalOpen, setIsRedeemVoucherModalOpen] = useState(false);

  const { watch } = useCheckoutFormContext();

  const { existingClient, outlet, articles } = watch();
  const existingClientId = existingClient?.id || null;

  const cartServiceVariantIds = getCartServiceVariantIds(articles);

  const clientActiveVouchers = useSelector(
    selectActiveVouchersByClientIdAndOutletId(existingClientId ?? 0, outlet)
  );

  const clientRedeemableVouchers = useSelector(
    selectRedeemableVouchersByClientIdAndOutletIdAndServiceVariantIds(
      existingClientId ?? 0,
      outlet,
      cartServiceVariantIds
    )
  );

  const clientActiveVouchersTotal = clientActiveVouchers.length;

  const clientRedeemableVouchersTotal = clientRedeemableVouchers.length;

  const handleClickApplyVoucher = () => {
    setIsRedeemVoucherModalOpen(true);
  };

  const handleCloseRedeemVoucherModal = () => {
    setIsRedeemVoucherModalOpen(false);
  };

  return (
    <>
      <RedeemVoucherModal
        isOpen={isRedeemVoucherModalOpen}
        onClose={handleCloseRedeemVoucherModal}
      />

      <ApplyVoucherBox onClick={handleClickApplyVoucher}>
        <VoucherIcon color="primary" />

        <Box flexGrow={1}>
          <Typography variant="primary" fontWeight={700}>
            {t("applyVoucher")}
          </Typography>
        </Box>

        <Typography variant="primary" color="primary" fontWeight={500}>
          <Trans
            i18nKey="vouchers:applicableVouchersOutOf"
            values={{ clientRedeemableVouchersTotal, clientActiveVouchersTotal }}
            components={[
              <Typography
                key="applicable-vouchers-out-of"
                display="inline"
                variant="primary"
                color="text.placeholder"
              />,
            ]}
          />
        </Typography>
      </ApplyVoucherBox>
    </>
  );
};

export default RedeemVoucherButton;

const ApplyVoucherBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  columnGap: theme.spacing(2),

  padding: theme.spacing(2),

  border: "1px solid #B3B6EF",
  borderRadius: theme.spacing(0.5),

  cursor: "pointer",

  "&:hover": {
    boxShadow: "none",
    backgroundColor: theme.palette.bg.secondary,
  },

  // Disable selecting text, because when we click RedeemVoucherButton it is awkwardly selecting the text.
  MozUserSelect: "none" /* firefox */,
  WebkitUserSelect: "none" /* Safari */,
  msUserSelect: "none" /* IE*/,
  userSelect: "none" /* Standard syntax */,
}));
