import { getFormattedPrice } from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";
import { DiscountOption } from "types/promotions";

/**
 *  Get promotion discount summary Example: 10% off or KD 10 Off

 * @returns a an object with a function that returns the promotion discount summary
 */

/**
 * Implementation notes:
 * - I had to use a hook here because i needed to use the translation hook inside the function
 * - I made the hook return the function to be able to use it inside loops instead of returning the string directly by passing props to the hook which will violate the rules of hooks.
 * This way we can use the hook inside loops and pass the props to the function inside the loop.
 *
 * Yours truly,
 * Anfal The Great 💎
 *
 */
const usePromotionDiscountSummary = () => {
  const { t } = useTranslation(["translation", "promotions"]);

  const getPromotionDiscountSummary = (
    discountType: Nullable<DiscountOption>,
    value: Nullable<number>
  ) => {
    if (!discountType || !value) return "";

    const discountAmount =
      discountType === DiscountOption.Percentage
        ? `${value.toFixed(0)}%`
        : `${t("translation:currency")} ${getFormattedPrice(value)}`;

    const promotionDiscountSummary = `${discountAmount} ${t("promotions:off")}`;

    return promotionDiscountSummary;
  };

  return { getPromotionDiscountSummary };
};

export default usePromotionDiscountSummary;
