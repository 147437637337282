import { CheckoutArticle } from "modals/CheckoutModal/CheckoutFormContext";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { getAppointmentDetails } from "store/appointments/appointmentsSlice";
import { selectCheckoutModal, selectResourcesByOutletId } from "store/selectors";
import {
  CheckoutModalView,
  initiateCheckoutModal,
  initiateCheckoutModalWithInvoiceDetailsMode,
  switchCheckoutModalView,
  switchInvoiceId,
  terminateCheckoutModal,
} from "store/slices/checkout/checkoutSlice";

import { translateAppointmentArticleToCheckoutArticle } from "./../modals/CheckoutModal/utils";
import useIsMobileView from "./useIsMobileView";

type OpenCheckoutModalOptions = Parameters<typeof initiateCheckoutModal>[0];

type OpenInvoiceModeOptions = Parameters<typeof initiateCheckoutModalWithInvoiceDetailsMode>[0];

const useCheckoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const canGoBack = window.history.state && window.history.state.idx > 0;

  //go back to previous route
  const goBack = () => navigate(-1);

  const hasFetchedAppointment = useRef(false);

  const { outletId, invoiceId, isModalOpen, currentView, appointmentId } =
    useSelector(selectCheckoutModal);

  const outletNotDeprecatedResources = useSelector(selectResourcesByOutletId(outletId ?? 0));

  const [activeTabInvoiceId, setActiveTabInvoiceId] = useState(invoiceId);

  const [checkoutArticles, setCheckoutArticles] = useState<CheckoutArticle[]>([]);

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId ?? undefined));

  const isMobileView = useIsMobileView();

  useEffect(() => {
    if (
      (!appointment && appointmentId && !hasFetchedAppointment.current) ||
      (appointment && appointment.id !== appointmentId)
    ) {
      //@ts-expect-error
      dispatch(getAppointmentDetails(appointmentId));
      hasFetchedAppointment.current = true;
    } else if (appointment && !hasFetchedAppointment.current) {
      const articles = appointment.articles.map((article) =>
        translateAppointmentArticleToCheckoutArticle(
          article,
          outletNotDeprecatedResources,
          appointment?.discountCode
        )
      );

      setCheckoutArticles(articles);
      hasFetchedAppointment.current = true;
    }
  }, [appointmentId, appointment, dispatch, outletNotDeprecatedResources]);

  const isDefaultDesktopView =
    !isMobileView &&
    (currentView === CheckoutModalView.Client || currentView === CheckoutModalView.Cart);

  const openCheckoutModal = (openCheckoutModalOptions: OpenCheckoutModalOptions) => {
    dispatch(initiateCheckoutModal(openCheckoutModalOptions));
  };

  const closeCheckoutModal = () => {
    if (pathname.includes("invoice")) {
      canGoBack ? goBack() : navigate("/");
    }

    dispatch(terminateCheckoutModal());
  };

  const setCheckoutModalView = (view: CheckoutModalView) => {
    dispatch(switchCheckoutModalView(view));
  };

  const setInvoiceId = (invoiceId: Nullable<number>) => {
    if (!invoiceId) return;

    setActiveTabInvoiceId(invoiceId);
    dispatch(switchInvoiceId(invoiceId));
  };

  const openCheckoutModalWithInvoiceDetailsMode = (
    openInvoiceModeOptions: OpenInvoiceModeOptions
  ) => {
    setActiveTabInvoiceId(openInvoiceModeOptions.invoiceId);
    dispatch(initiateCheckoutModalWithInvoiceDetailsMode(openInvoiceModeOptions));
  };

  return {
    invoiceId,
    setInvoiceId,

    outletId,
    appointmentId,
    checkoutArticles,

    checkoutModalView: currentView,
    isDefaultDesktopView,
    setCheckoutModalView,

    activeTabInvoiceId,
    setActiveTabInvoiceId,

    isCheckoutModalOpen: isModalOpen,
    openCheckoutModal,
    closeCheckoutModal,

    openCheckoutModalWithInvoiceDetailsMode,
  };
};

export default useCheckoutModal;
