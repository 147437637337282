import http from "utils/http";

function stringifyId(outletId) {
  return outletId === 0 ? "" : outletId.toString();
}

const appointmentsOverviewApi = {
  getAppointmentsOverview: async (outletId) =>
    http.get(`appointments/appointments_overview/?outlet_id=${stringifyId(outletId)}`),
};

export default appointmentsOverviewApi;
