import { ChevronDown, ChevronUp } from "@bookpeep/ui";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { dontForwardProps } from "helpers/styles";
import { useEffect, useState } from "react";

const PeepServicesAccordion = ({
  title,
  description,
  secondaryAction,
  children,
  draggableIcon,
  color,
  open,
  icon = null,
  subtitle = "",
  itemCount,
  setExpandedList = () => { },
}) => {
  const [expandAccordion, setExpandAccordion] = useState(open);

  const handleExpand = () => {
    setExpandAccordion(!expandAccordion);
    setExpandedList();
  };

  useEffect(() => {
    setExpandAccordion(!!open);
  }, [open]);

  return (
    <StyledAccordion expanded={expandAccordion}>
      <StyledAccordionSummary
        expandIcon={
          <>
            <Typography variant="h4" pr={.5} >
              {itemCount}
            </Typography>
            {expandAccordion ? (
              <ChevronUp onClick={handleExpand} />
            ) : (
              <ChevronDown onClick={handleExpand} />
            )}

            {secondaryAction}
          </>
        }
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
      >
        {draggableIcon}

        <Box display="flex" alignItems="center" flexGrow={1} onClick={handleExpand} columnGap={1}>
          {icon}

          <Typography variant="h3">{title} </Typography>

          {subtitle && <Typography variant="h4" fontWeight={300}>{` ${subtitle}`}</Typography>}

          {description && (
            <Typography color="common.gray" variant="h5">
              {description}
            </Typography>
          )}
        </Box>
      </StyledAccordionSummary>

      <StyledAccordionDetails color={color}>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default PeepServicesAccordion;

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: 0,
  boxShadow: theme.shadows.card,
  borderRadius: theme.spacing(0.75),
  minHeight: "64px",
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  "& .MuiAccordionSummary-content": {
    margin: 0,
    paddingBottom: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transform: "none",
    WebkitTransition: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    WebkitTransition: "none",
    transform: "none",
  },

  paddingLeft: 0,
  paddingRight: 0,
}));

const StyledAccordionDetails = styled(
  AccordionDetails,
  dontForwardProps("color")
)(({ color }) => ({
  padding: 0,
  borderLeft: `3px solid ${color}`,
}));
