import {
  AlmostRegular,
  Canceller,
  Ghost,
  Lost,
  Loyal,
  NoShower,
  OneTimer,
  Regular,
  SuperLoyal,
} from "assets/icon";
import { CLIENT_LOYALTY_STATUS } from "types/ClientAnalytic";

export const getClientStatusIcon = (status: string) => {
  switch (status) {
    case CLIENT_LOYALTY_STATUS.ONE_TIMER:
      return <OneTimer viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.ALMOST_REGULAR:
      return <AlmostRegular viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.REGULAR:
      return <Regular viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.LOYAL:
      return <Loyal viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.SUPER_LOYAL:
      return <SuperLoyal viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.LOST:
      return <Lost viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.GHOST:
      return <Ghost viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.CANCELLER:
      return <Canceller viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    case CLIENT_LOYALTY_STATUS.NO_SHOWER:
      return <NoShower viewBox="0 0 20 20" sx={{ height: 20, width: 20 }} />;
    default:
      return null;
  }
};
