import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ClientSkinType } from "types/Client";

import { SliceStatus } from "../utils";
import api from "./clientSkinTypesApi";

export const clientSkinTypesAdapter = createEntityAdapter<ClientSkinType>({
  selectId: (client) => client.id,
});

const initialState = clientSkinTypesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllClientSkinTypes = createThunkFromApiWithType(
  "clientSkinTypes/getAllClientSkinTypes",
  api.getAllClientSkinTypes
);

export const clientSkinTypesSlice = createSlice({
  name: "clientSkinTypes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload ? SliceStatus.LOADING : SliceStatus.IDLE;
    },
  },
  extraReducers: (reducers) => {
    reducers.addCase(getAllClientSkinTypes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllClientSkinTypes.fulfilled, (state, { payload }) => {
      clientSkinTypesAdapter.setAll(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllClientSkinTypes.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export const { setLoading } = clientSkinTypesSlice.actions;

export default clientSkinTypesSlice.reducer;
