import { AppointmentStatusOptions } from "types/Appointment";

export const isAppointmentCompleted = (appointmentStatus) => {
  return appointmentStatus === AppointmentStatusOptions.Completed;
};

export const isAppointmentEditable = (appointmentStatus) => {
  return (
    appointmentStatus !== AppointmentStatusOptions.Canceled &&
    appointmentStatus !== AppointmentStatusOptions.NoShow
  );
};

export const totalPrice = (appointment) => {
  const articlesTotalPrice = !!appointment?.articles?.length
    ? appointment?.articles?.reduce(
        (partialTotal, article) => partialTotal + parseFloat(article.finalPrice),
        0
      )
    : 0;
  return articlesTotalPrice;
};

export const totalServices = (appointment) => {
  const articlesSlotsCount = !!appointment?.articles?.length
    ? appointment?.articles?.reduce(
        (partialTotal, article) => partialTotal + article?.slots?.length,
        0
      )
    : 0;
  return articlesSlotsCount;
};

export const totalDurationInMinutes = (appointment) => {
  const articlesDurationInMinutes = appointment?.articles.map((article) => article.totalDuration);

  const articlesDuration = !!appointment?.articles?.length
    ? articlesDurationInMinutes.reduce(
        (minutesAccumulator, minutes) => minutesAccumulator + minutes,
        0
      )
    : 0;
  return articlesDuration;
};
