import { Box, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { NotificationCardProps } from "./NotificationCardType";

const NotificationCard = ({
  shouldDisplayDot = false,
  notification,
  backgroundColor = "bg.blank",
  showDivider = false,
  shouldShowDateTimeOfEvent = true,
  descriptionIcon,
  icon,
  onClick,
  showDescription = true,
}: NotificationCardProps) => {
  const { t } = useTranslation("notifications");
  const { title, subtitle, description, startDate, endDate, createdAt } = notification;

  const timeSinceCreation = DateTime.fromISO(createdAt).toRelative();

  const eventDate = DateTime.fromISO(startDate).toFormat("dd MMMM yyyy");

  const eventStartTime = DateTime.fromISO(startDate).toFormat("t");

  const eventEndTime = DateTime.fromISO(endDate).toFormat("t");

  const cardTitle = (
    <Typography pl={2} variant="h4" color="primary" textTransform="capitalize" flexWrap="wrap">
      {shouldDisplayDot && "● "} {title.toLowerCase()}
    </Typography>
  );

  const dateTimeOfEvent = t("notificationEventDateTime", {
    eventDate,
    eventStartTime,
    eventEndTime,
  });

  return (
    <Box flexDirection="column" flexGrow={1}>
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        bgcolor={backgroundColor}
        py={1}
        sx={{ cursor: "pointer" }}
        onClick={onClick}
      >
        {icon && <Box pl={2}>{icon}</Box>}

        <Box flexGrow={1}>
          <Box display="flex" justifyContent="space-between" flexGrow={1}>
            <Box pb={0.5}>{cardTitle}</Box>

            <Box textAlign="right" pr={2}>
              <Typography variant="secondary" color="text.placeholder">
                {timeSinceCreation}
              </Typography>
            </Box>
          </Box>

          <Box px={2}>
            <Typography variant="primary" display="block" fontWeight={500}>
              {shouldShowDateTimeOfEvent && dateTimeOfEvent}
            </Typography>

            <Typography variant="primary" display="block" fontWeight={500}>
              {subtitle}
            </Typography>

            {showDescription && (
              <Box display="flex" alignItems="center" gap={0.5} pt={0.5}>
                {descriptionIcon}

                <Typography variant="primary" color="text.label" fontWeight={500}>
                  {description}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {showDivider && (
        <Box px={2} pt={1}>
          <Divider variant="fullWidth" />
        </Box>
      )}
    </Box>
  );
};

export default NotificationCard;
