import DateAdapter from "@mui/lab/AdapterLuxon";
import { DateTime } from "luxon";

const SUNDAY = 7;

const getStartOfFirstWeekInMonth = (date: DateTime) => {
  const startOfMonth = date.startOf("month");

  if (startOfMonth.weekday === SUNDAY) return startOfMonth;
  else return startOfMonth.minus({ days: startOfMonth.weekday });
};

const getEndOfLastWeekInMonth = (date: DateTime) => {
  const endOfMonth = date.endOf("month");

  if (endOfMonth.weekday === SUNDAY) return endOfMonth.plus({ days: 6 });
  else return endOfMonth.plus({ days: SUNDAY - 1 - endOfMonth.weekday });
};

/**
 * This date adapter extends the default MUI adapter to set Sunday as the first day of the week in the calendar pickers
 */
export default class CustomDateAdapter extends DateAdapter {
  // This function returns the names displayed on top of the pickers
  // For now we can assume Sunday will always be the start of the week
  getWeekdays = () => ["S", "M", "T", "W", "T", "F", "S"];

  // This function returns an array of date arrays, in which each array represents a week
  // MUI hides dates that are not in the selected month, we don't have to deal with it
  getWeekArray = (date: DateTime) => {
    const firstDay = getStartOfFirstWeekInMonth(date);
    const lastDay = getEndOfLastWeekInMonth(date);

    const { days: numberOfDays } = lastDay.diff(firstDay, "days").toObject();

    const weeks: DateTime[][] = [[]];

    // Loop for as many days as should be
    new Array<number>(Math.round(numberOfDays!)).fill(0).forEach((_, increment) => {
      if (weeks[weeks.length - 1].length < 7) {
        // Last week array still not filled
        weeks[weeks.length - 1].push(firstDay.plus({ days: increment }));
      } else {
        // Last week array is filled, start a new week
        weeks.push([firstDay.plus({ days: increment })]);
      }
    });

    return weeks;
  };
}

// ==========================
// ===== ORIGINAL CODES =====
// ==========================

// getWeekdays = () => {
//   return Info.weekdaysFormat("narrow", { locale: this.locale });
// };

// getWeekArray = (date: DateTime) => {
//   const { days } = date
//     .endOf("month")
//     .endOf("week")
//     .diff(date.startOf("month").startOf("week"), "days")
//     .toObject();

//   const weeks: DateTime[][] = [];
//   new Array<number>(Math.round(days!))
//     .fill(0)
//     .map((_, i) => i)
//     .map((day) => date.startOf("month").startOf("week").plus({ days: day }))
//     .forEach((v, i) => {
//       if (i === 0 || (i % 7 === 0 && i > 6)) {
//         weeks.push([v]);
//         return;
//       }

//       weeks[weeks.length - 1].push(v);
//     });

//   return weeks;
// };
