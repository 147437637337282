import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./packagesApi";

const packageAdapter = createEntityAdapter();

const initialState = packageAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
  optimisticCache: null,
});

const { selectAll } = packageAdapter.getSelectors((state) => state.packages);

export const getAllPackages = createThunkFromApi("packages/getAllPackages", api.getAllPackages);

export const getPackage = createThunkFromApi("packages/getPackage", api.getPackage);

export const createPackage = createThunkFromApi("packages/createPackage", api.createPackage);

export const updatePackage = createAsyncThunk(
  "packages/updatePackage",
  async (updatePackageArgs, { rejectWithValue }) => {
    try {
      const { id, submittedData, oldCategory } = updatePackageArgs;

      const response = await api.updatePackage(id, submittedData);

      return { ...response.data, oldCategory };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401) return rejectWithValue(data.detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updatePackagePosition = createThunkFromApi(
  "services/updatePackagePosition",
  api.updatePackagePosition
);

export const deletePackage = createAsyncThunk(
  "packages/removePackage",
  async (deletePackageArgs, { rejectWithValue }) => {
    try {
      const { id, categoryId } = deletePackageArgs;

      await api.removePackage(id);

      return { id, categoryId };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 401 || status === 404 || status === 400) return rejectWithValue(data.detail);
      }

      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const packagesSlice = createSlice({
  name: "packages",
  initialState,
  extraReducers: {
    [getAllPackages.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPackages.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      packageAdapter.setAll(state, payload);
    },
    [getAllPackages.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getPackage.fulfilled]: (state, { payload }) => {
      packageAdapter.setOne(state, payload);
    },

    [createPackage.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createPackage.fulfilled]: (state, { payload }) => {
      packageAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createPackage.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [updatePackage.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updatePackage.fulfilled]: (state, { payload }) => {
      packageAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updatePackage.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [updatePackagePosition.pending]: (state) => {
      state.optimisticCache = selectAll({ packages: state });
    },
    [updatePackagePosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updatePackagePosition.rejected]: (state) => {
      packageAdapter.setAll(state.packages);
      state.optimisticCache = null;
    },
    [deletePackage.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deletePackage.fulfilled]: (state, { payload: { id } }) => {
      packageAdapter.removeOne(state, id);
      state.isRequestPending = false;
    },
    [deletePackage.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default packagesSlice.reducer;
