import { Box } from "@mui/material";
import ClientSummary from "components/ClientSection/ExistingClientInfo/ClientSummary";

import AppointmentDrawerClientProfileTabs from "./AppointmentDrawerClientProfileTabs";

type AppointmentDrawerClientProfileProps = {
  clientId: number;
  outletId: number;
};

const AppointmentDrawerClientProfile = ({
  clientId,
  outletId,
}: AppointmentDrawerClientProfileProps) => {
  return (
    <>
      <Box px={3} pt={1} pb={2} boxShadow="divider">
        <ClientSummary
          clientId={clientId}
          outletId={outletId}
          shouldShowToggleTagsVisibilityButton
          initialShouldShowClientTags
        />
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          // To make the content scrollable
          minHeight: "0px",
          overflow: "scroll",
        }}
      >
        <AppointmentDrawerClientProfileTabs clientId={clientId} />
      </Box>
    </>
  );
};

export default AppointmentDrawerClientProfile;
