import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Expense } from "types/Expense";

import { SliceStatus } from "../utils";
import api from "./expensesApi";

export const expensesAdapter = createEntityAdapter<Expense>({ selectId: (expense) => expense.id });

const initialState = expensesAdapter.getInitialState<{
  status: SliceStatus;
  count: number;
  next: string;
  previous: string;
  totalPages: number;
  results: Expense[];
  totalAmount: number;
}>({
  status: SliceStatus.IDLE,
  count: 0,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
  totalAmount: 0,
});

export const getAllExpenses = createThunkFromApiWithType(
  "expenses/getAllExpenses",
  api.getExpenses
);

export const appendAllExpenses = createThunkFromApiWithType(
  "expenses/appendExpenses",
  api.getExpenses
);

export const createExpense = createThunkFromApiWithType(
  "expenses/createExpense",
  api.createExpense
);

export const updateExpense = createThunkFromApiWithType(
  "expenses/updateExpense",
  api.updateExpense
);

export const getExpensesExcel = createThunkFromApiWithType(
  "expenses/getExpensesExcel",
  api.getExpensesExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const expensesSlice = createSlice({
  name: "expenses",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllExpenses.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllExpenses.fulfilled, (state, { payload }) => {
      expensesAdapter.setAll(state, payload.results);
      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.totalAmount = payload.totalAmount;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllExpenses.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllExpenses.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllExpenses.fulfilled, (state, { payload }) => {
      expensesAdapter.upsertMany(state, payload.results);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.totalAmount = payload.totalAmount;
    });

    reducers.addCase(appendAllExpenses.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createExpense.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createExpense.fulfilled, (state, { payload }) => {
      expensesAdapter.upsertOne(state, payload);

      state.count = state.count + 1;
      state.totalAmount = Number(state.totalAmount) + Number(payload.amount);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createExpense.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateExpense.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateExpense.fulfilled, (state, { payload }) => {
      expensesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateExpense.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default expensesSlice.reducer;
