import { Grid } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useSelector } from "store";
import { selectActiveVouchersByClientIdAndOutletId } from "store/clients/clientSelectors";
import { selectClientById } from "store/clients/clientsSlice";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { Client } from "types/Client";
import { PurchasableItemOption } from "types/PurchasableItem";

import AddPaymentMethodsSection from "./AddPaymentMethodsSection";
import AddPurchasableItems from "./AddPurchasableItems";
import CheckoutClientDiscountSection from "./CheckoutClientDiscountSection";
import CheckoutClientSection from "./CheckoutClientSection";
import { useCheckoutFormContext } from "./CheckoutFormContext";
import CheckoutVouchersSection from "./CheckoutVouchersSection";
import ConfirmSaleSection from "./ConfirmSaleSection";
import InvoiceSideMenuActionsSection from "./InvoiceSideMenuActionsSection";

const CheckoutSideBarActions = () => {
  const { checkoutModalView, isDefaultDesktopView, invoiceId } = useCheckoutModal();

  const { watch } = useCheckoutFormContext();

  const { articles, existingClient, outlet } = watch();
  const existingClientId = existingClient?.id || null;

  const client = useSelector(selectClientById(existingClientId)) as Nullable<Client>;

  const clientActiveVouchers = useSelector(
    selectActiveVouchersByClientIdAndOutletId(existingClientId ?? 0, outlet)
  );

  const shouldShowAddPurchasableItems =
    checkoutModalView === CheckoutModalView.AddItems || isDefaultDesktopView;

  const shouldShowAddPaymentMethods =
    checkoutModalView === CheckoutModalView.Payment ||
    checkoutModalView === CheckoutModalView.InvoiceAddPayments ||
    checkoutModalView === CheckoutModalView.Refund;

  const shouldShowConfirmSale =
    checkoutModalView === CheckoutModalView.ConfirmSale ||
    checkoutModalView === CheckoutModalView.ConfirmRefund;

  const shouldShowInvoiceActions = checkoutModalView === CheckoutModalView.Invoice;

  const hasServiceInCart = articles.some(
    (article) => article.purchasableItemType === PurchasableItemOption.Service
  );

  const hasActiveVouchers = clientActiveVouchers.length > 0;
  const shouldShowClientVouchersSection =
    !!existingClientId &&
    hasActiveVouchers &&
    shouldShowAddPaymentMethods &&
    hasServiceInCart &&
    !invoiceId;

  const shouldShowClientDiscountSection =
    shouldShowAddPaymentMethods && client && Number(client?.discountAmount) > 0 && !invoiceId;

  return (
    <Grid
      item
      order={{ xs: 1, md: 2 }}
      md={4}
      xs={12}
      bgcolor="bg.blank"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: checkoutModalView === CheckoutModalView.Cart ? "30%" : "100%",
        minHeight: checkoutModalView === CheckoutModalView.Cart ? "10%" : "100%",
        zIndex: 10000,
        width: "100%",
      }}
    >
      <CheckoutClientSection />

      {shouldShowClientDiscountSection && <CheckoutClientDiscountSection />}

      {shouldShowClientVouchersSection && <CheckoutVouchersSection />}

      {shouldShowAddPurchasableItems && <AddPurchasableItems />}

      {shouldShowAddPaymentMethods && <AddPaymentMethodsSection />}

      {shouldShowConfirmSale && <ConfirmSaleSection />}

      {shouldShowInvoiceActions && <InvoiceSideMenuActionsSection />}
    </Grid>
  );
};

export default CheckoutSideBarActions;
