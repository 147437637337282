import { Box, Typography } from "@mui/material";

type InvoiceTabProps = {
  isActive: boolean;
  title: string;
  onClick: () => void;
  subtitle: JSX.Element;
};

const InvoiceTab = ({ isActive, title, onClick, subtitle }: InvoiceTabProps) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        borderLeft: (theme) => (isActive ? `2px solid ${theme.palette.primary.main}` : "none"),
      }}
      onClick={onClick}
      px={3}
      py={1}
      gap={0.5}
    >
      <Typography
        variant="h5"
        fontWeight={700}
        color={isActive ? "primary.main" : "text.secondary"}
      >
        {title}
      </Typography>

      {subtitle}
    </Box>
  );
};

export default InvoiceTab;
