import { User } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import { ClipboardList } from "assets/icon";
import { theme } from "configs";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectEmployeeById } from "store/employees/employeesSlice";

import type { TopStaff } from "./ClientPerformance";

export const TopBookedStaff = ({ staff }: { staff: TopStaff[] }) => {
  const { t } = useTranslation("client");

  return (
    <Box display="flex" flexDirection="column" gap={2.5}>
      <Typography
        variant="primary"
        sx={{ textTransform: "uppercase" }}
        color="#848FA1"
        fontWeight={700}
        letterSpacing="0.03em"
      >
        {t("topStaff")}
      </Typography>
      {staff.length ? (
        staff.map((staff) => <TopBookedStaffItem key={staff.employee} staff={staff} />)
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.label,
            fontWeight: "500",
          }}
        >
          {t("dataGeneratedAfterAppointment")}
        </Typography>
      )}
    </Box>
  );
};

export const TopBookedStaffItem = ({ staff }: { staff: TopStaff }) => {
  const { t } = useTranslation("client");

  const employee = useSelector(selectEmployeeById(staff.employee));

  return (
    <Box display="flex" gap={1.5} justifyContent="space-between">
      <Box display="flex" gap={1}>
        <Box
          sx={{
            backgroundColor: "#64C22A",
            borderRadius: theme.spacing(1),
            width: 40,
            height: 40,
            padding: theme.spacing(0.5),
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <User sx={{ color: "white" }} fontSize="large" />
        </Box>
        <Box>
          <Typography fontWeight={700}>
            {employee?.profile?.firstName || employee?.user?.firstName}{" "}
            {employee?.profile?.lastName || employee?.user?.lastName}
          </Typography>
          <Typography color="#6C798F">
            <ClipboardList viewBox="0 0 12 12" sx={{ fontSize: 14 }} />{" "}
            {staff.topServices.join(", ")}
          </Typography>
        </Box>
      </Box>
      <Box alignItems="flex-end" textAlign="right">
        <Typography variant="h5" color="#6C798F">
          {t("lastBooked")}
        </Typography>
        <Typography>
          {new Date(staff.lastBookedOn).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </Typography>
      </Box>
    </Box>
  );
};
