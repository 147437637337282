export default function PeepSubscriptionsTermsAndConditions() {
  return (
    <>
      <h4>عقد تقديم خدمات تتمثل بنظام الكتروني</h4>

      <p>
        مالك البرنامج الالكتروني : شركة بييب سولوشنز لتصميم المواقع الالكترونية و هي متخصصة في تدشين
        الانظمة الالكترونية التي توفر لأصحاب الاعمال مكنة ادارة اعمالهم بحجز المواعيد الخاصة
        بعملائهم و متابعة الاعمال الادارية المتمثله في تقديم تقارير أداء مبيعات يوميه و أسبوعية و
        إدارة المخزون و اصدار الفوتير، كذلك توفير رزنامة للاعمال مع خاصية اضافة الموظفين بها مع
        توفير حسابات للدخول و جميع هذه الخدمات تكون متوافقة مع الباقة المشترك بها من قبل أصحاب
        الاعمال . أصحاب الأعمال: المقصود بهم في هذا العقد الشركات والمؤسسات والأشخاص العاملين في
        مجال التجارة و تقديم الخدمات ويقوموا باستخدام النظام الالكتروني بموجب اتفاقية تقديم خدمات
        مبرمة مع مالك النظام على أن يمكن اصحاب الاعمال من ادارة اعمالهم المتمثله بحجز المواعيد
        الخاصة بعملائهم و متابعة الاعمال الادارية المتمثله في تقديم تقارير أداء مبيعات يوميه و
        أسبوعية و كذلك توفير رزنامة للاعمال مع خاصية اضافة الموظفين بها مع توفير حسابات للدخول و
        جميع هذه الخدمات تكون متوافقة مع الباقة المشترك بها من قبل أصحاب الاعمال . العميل :
        المقصــود به في هذا العقد هو الشخص الذي يقوم باستخدام النظام الالكتروني بصفته عميل لأصحاب
        الأعمال و تنشأ بينه و بينهم علاقة قانونية مباشرة لا يكون مالك النظام الالكتروني طرفا فيها .
        الخدمات : المقصــود بهــا فـي هـذا العقد كافة الخدمات الممكن استخدامها من خلال النظام
        الالكتروني المنشأ من قبل مالك البرنامج الالكتروني و التي تمكن اصحاب الاعمال من ادارة أعمالهم
        اليومية .
      </p>

      <h4>تمهيــــــد</h4>

      <p>
        ( مالك البرنامج ) شركة بييب سولوشنز لتصميم المواقع الالكترونية هي شركة متخصصة في مجال
        الانظمة الإلكترونية، وقامت بتدشين برنامج الكتروني ، و تهدف من خلاله إلى أن يكون هذا البرنامج
        نظام متكامل يخول أصحاب الأعمال من ادارة اعمالهم اليومية المتمثله بحجز المواعيد الخاصة
        بعملائهم و متابعة الاعمال الادارية المتمثله في تقديم تقارير أداء مبيعات يوميه و أسبوعية و
        إدارة المخزون و اصدار الفوتير، كذلك توفير رزنامة للاعمال مع خاصية اضافة الموظفين بها مع
        توفير حسابات للدخول و جميع هذه الخدمات تكون متوافقة مع الباقة المشترك بها من قبل أصحاب
        الاعمال . -ويعتبر البرنامج نظام آمن حيث انه يوجد حماية للسيرفرات متوافقة مع معايير و متطلبات
        الأمن السيبراني كما انه سهل الاستخدام و يمكن أصحاب الأعمال من ادارة اعمالهم اليومية من خلاله
        و ذلك نظير التزام أصحاب الأعمال بسداد رسوم الاشتراك الشهرية الى مالك البرنامج وفقا للباقات
        التي سوف ترد في العقد ، ورغبةً من (أصحـاب الأعمـال ) من الاستفادة من الخدمات التي يقدمها
        البرنامج ، و رغبة من كل طرف في تحديد حقوقه والتزاماته تجاه الآخر لخدمة مصالحهما المشتركة
        وفقاً لأصول التعامل التجاري الجيد لتكون هذه العلاقة متفقة مع مقتضيات أحكام القانون والنظم
        التجارية ذات الصلة بحركة التجارة المعمول بها، لذا اتفقا الطرفان على تنفيذ ما يلي :
      </p>

      <ol type="1">
        <li>
          بموجب هذا الاتفاق يقوم ( مالك البرنامج ) بتوفير نظام الكتروني آمن سهل الاستخدام يمكن
          (أصحـاب الأعمـال) من القيام بادارة اعمالهم اليومية وفقا للباقات التاليه :
          <br></br>
          <ol type="a">
            <li>
              باقة الاشتراك الأساسي بقيمة 25 دك شهريا تسدد قبل تفعيل الخدمة على أن تخدم موقع واحد مع
              امكانية اضافة 5 موظفين في الرزنامة مع تفعيل 8 حسابات للدخول و تقديم تقارير اداء
              أسبوعية و 250 رسالة تذكير نصية.
            </li>

            <li>
              باقة الاشتراك الاصلي بقيمة 35 دك شهريا تسدد قبل تفعيل الخدمة على أن تخدم موقعين مع
              امكانية اضافة 10 موظفين في الرزنامة مع تفعيل 14 حساب للدخول و تقديم تقاريرأداء مبيعات
              يومية و أسبوعية و 500 رسالة تذكير نصية .
            </li>

            <li>
              باقة اشتراك وايد اصلي : بقيمة 45 دك شهريا تسدد قبل تفعيل الخدمة على أن تخدم 5 مواقع مع
              امكانية اضافة 20 موظف في الرزنامة مع تفعيل 25 حساب للدخول و تقديم تقاريرأداء مبيعات
              يومية و أسبوعية و 1000 رسالة تذكير نصية . علما بأن هذه الباقات وردت على سبيل المثال لا
              الحصر و سوف يتم ارفاق تفصيلها لكل باقة على حدة .
            </li>
          </ol>
          <br></br>
          على أن تسدد الاشتراكات الشهرية قبل تفعيل الخدمة أو عند موعد التجديد نظيرقيام مالك البرنامج
          بتقديم الخدمات الالكترونيه التي تخول أصحاب الاعمال من إدارة مواعيدهم و تنسيق جدولهم مع
          عملاءهم ومتابعة الاعمال الادارية المتمثله في تقديم تقارير أداء مبيعات يوميه و أسبوعية و
          كذلك توفير رزنامة للاعمال مع خاصية اضافة الموظفين بها مع توفير حسابات للدخول و جميع هذه
          الخدمات تكون متوافقة مع الباقة المشترك بها من قبل أصحاب الاعمال .
        </li>

        <br></br>
        <li>
          يحق لمالك البرنامج خصم مستحقاته الماليه و المتمثلة بالاشتراك الشهري من المبالغ المسدده من
          عملاء اصحاب الاعمال مباشرة دون الرجوع اليه و يعد هذا البند تفويضا له بذلك.
        </li>

        <br></br>
        <li>
          يحق لمالك البرنامج إيقاف الخدمة حال عدم سداد قيمة الإشتراك الشهري من قبل اصحاب الأعمال .
        </li>

        <br></br>
        <li>
          حال قيام اصحاب الاعمال بأرسال روابط سداد لعملائهم عبر البرنامج فأنه يترتب على هذه الخدمة
          رسوم قدرها 500 فلس لصالحه يحق له خصمها مباشرة من المبالغ المترصدة لصالح أصحاب الأعمال و
          تكون الرسوم حقا خالصا لمالك البرنامج حال الغاء الخدمة من قبل عميل أصحاب الأعمال .
        </li>

        <br></br>
        <li>
          يقوم البرنامج بتفعيل بوابة السداد الالكتروني ( payment gateway) حتي يتمكن اصحاب الاعمال من
          توفير خدمة حجز الخدمات لعملائهم من خلاله وفقا لأنظمة البنك المركزي بهذا الشأن على أن تحول
          المبالغ في اليوم التالي الى الحساب البنكي لأصحاب الأعمال مخصوما منها عمولة مالك البرنامج
          عن كل عملية وهي 500 فلس و كذلك الاشتراك الشهري مالم يكن مدفوعا .
        </li>

        <br></br>
        <li>
          يخصص ( مالك البرنامج ) لـــ (أصحـاب الأعمـال ) صفحة بالبرنامج و لوحة تحكم ويمنحه أسم
          مستخدم و رقم سري لتمكينه للدخول من خلالها إلى التطبيق ، مع منحه الأذونات اللازمة لإدارة
          جدوله و مواعيده .
        </li>

        <br></br>
        <li>
          يحق لأصحاب الأعمال ترقية الباقة الى باقة اعلى منها متى ما شاءوا في اي يوم من الشهر على أن
          تحسب عليه رسوم الباقة الجديدة من تاريخ الترقية .
        </li>

        <br></br>
        <li>
          لا يحق لأصحاب الاعمال تخفيض باقتهم الى باقه أدني أثناء سريان الأشتراك الشهري على أن يسري
          التخفيض بعد مرور ثلاثين يوماً من تاريخ الاشتراك.
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بعدم مسؤلية مالك البرنامج عن كافة الانشطة التي يقومون بها عبر
          البرنامج الالكتروني اذ انه لا يعدو سوى اداة الكترونية تخول اصحاب الاعمال من ادارة اعمالهم
          من خلاله و يكونون مسؤليين عن المحتوى وعدم انتهاك حقوق الملكية الفكريه دون أدنى مسؤلية على
          مالك البرنامج .
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بعدم مسؤلية مالك البرنامج عن كافة الانشطة التي يقومون بها عبر
          البرنامج الالكتروني اذ انه لا يعدو سوى اداة الكترونية تخول اصحاب الاعمال من ادارة اعمالهم
          من خلاله و يكونون مسؤليين عن المحتوى وعدم انتهاك حقوق الملكية الفكريه دون أدنى مسؤلية على
          مالك البرنامج . يلتزم أصحاب الأعمال بسداد الاشتراك الشهري على حسب الباقة المختاره من قبل
          أصحاب الاعمال و ذلك نظير الخدمات الالكترونيه المقدمة من قبل مالك البرنامج و المبينه في صدر
          هذا العقد.
        </li>

        <br></br>
        <li>
          يحق لاصحاب الاعمال طلب تزويدهم ببيانات عملائهم الذين تلقوا الخدمة من خلال النظام
          الالكتروني على أن يقوم مالك النظام بتزويدهم بتلك البيانات خلال 30 يوما من تاريخ طلبها – و
          في حال طلب أصحاب الاعمال لبيانات مضى عليها اكثر من 6 شهور فأنه يحق لمالك البرنامج تمديد
          فترة ال 30 يوما الى مدد اضافية لحين توفير البيانات المطلوبة.
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بمسئوليتهم عن جودة الخدمات المعروضة بصفحته على المنصة و يكون مسئول
          عن أي قصور في الخدمات تجاه عملاءه .
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بتنفيذهم قرارات و لوائح وزارة التجارة والصناعة المتعلقة بتقديم
          الخدمات من حيث الجودة و الاحترافية و الاشتراطات الصحية سعيا للتوافق مع قرارات الجهات
          المعنية.
        </li>

        <br></br>
        <li>
          يقر (مالك البرنامج ) بتقديمه للخدمات على النحو الذي يحوز على رضاء اصحاب الاعمال و ذلك بشكل
          احترافي يضمن جودة الخدمات مع توفير سيرفرات لحفظ البيانات بما يضمن حفظها مع امكانية
          استعادتها عند الضرورة .
        </li>

        <br></br>
        <li>
          ( مالك البرنامج ) غير مسئول عن أي إخلال يحدث من (أصحـاب الأعمـال ) بأي من إلتزاماته
          التعاقدية قبل الطرف الآخر ، وتكون العلاقة بين (أصحـاب الأعمـال ) وعملاءه علاقة مباشر
        </li>

        <br></br>
        <li>
          يُحظر على (أصحـاب الأعمـال )عرض خدمة لا يسمح بتقديمها في دولة الكويت بحسب قوانين ولوائح
          وزارة التجارة والصناعة وكذلك القوانين المعمول بها داخل دولة الكويت.
        </li>

        <br></br>
        <li>
          يُحظر على (أصحـاب الأعمـال ) رفع أي صور مخلة للآداب العامة أو مخالفة للقوانين على صفحته
          بالبرنامج، ويكون لمالك البرنامج كامل الحق في إزالة أي صورة تتنافى مع الشروط والآداب
          العامة.
        </li>

        <br></br>
        <li>
          يحق لمالك البرنامج في أي وقت رفع أسم (أصحـاب الأعمـال ) من الموقع في حالة عدم إلتزام
          (أصحـاب الأعمـال ) بكافة الإلتزامات الواردة ببنود هذا العقد ، وذلك لحين تصحيح الخطأ إذا
          كان قابلاً للتصحيح.
        </li>

        <br></br>
        <li>
          يلتزم ( مالك البرنامج و أصحـاب الأعمـال ) بعدم الإفصاح عن كافة المعلومات السرية المتبادلة
          بين الطرفين والإحتفاظ بسرية تلك المعلومات خاصةً فيما يتعلق بأمور التقنية والبرمجة الخاصة
          بالتطبيق وطرق وسياسة سير العمل وعدم نشرها للعامة لأي سبب خلال فترة التعاقد أو بعد
          إنتهائها.
        </li>

        <br></br>
        <li>
          لا يعد أي من أطراف الإتفاقية مسئولاً عن أي تأخير او إخفاق في تنفيذ أياً من إلتزاماته
          الواردة بهذا العقد بسبب حالات القوة القاهرة الخارجة عن إرادته وتشمل على سبيل المثال لا
          الحصر : إنقطاع شبكة الإنترنت عن الخدمات الإلكترونية المقدمة من ( مالك البرنامج ) أو لا قدر
          الله الزلازل والحروب وكافة الكوارث الطبيعية والثورات وأعمال الشغب وغيره.
        </li>

        <br></br>
        <li>
          يلتزم الطرفان بتنفيذ العقد طبقاً لأحكامه وبما يتفق مع قواعد العرف التجاري وبطريقة تلائم ما
          يتطلبه حسن النية ويشمل هذا الالتزام ما يعتبر من مستلزمات العقد طبقاً للتعامل التجاري.
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بإجراء التحديثات لكافة المعلومات الخاصة بالخدمات ويتعهد بإدخال
          البيانات الصحيحة بحيث تكون مطابقة لما هي عليه بالواقع الفعلي وذلك من خلال لوحة تحكم التي
          سيمنح ( مالك البرنامج) لـ (أصحـاب الأعمـال ) الأذن بالتعامل معها.
        </li>

        <br></br>
        <li>
          يقر (أصحـاب الأعمـال ) بأحقية ( مالك البرنامج) في إستخدام علامته التجارية وشعاره في
          التسويق والإعلان عن التطبيق.
        </li>

        <br></br>
        <li>جميع المراسلات والمخاطبات بين الطرفين تكون عبر الإيميل المسجل لدى المنصة .</li>

        <br></br>
        <li>
          يخضع هذا العقد لأحكام القانون الكويتي، وفي حالة وجود أي خلاف أو نزاع بين طرفي العقد حول
          تنفيذ أو تفسير هذا العقد يتم حله وديا بين الطرفين، وفي حالة تعذر الحل الودي يكون القضاء
          الكويتي هو المختص بنظر النزاع.
        </li>
      </ol>
    </>
  );
}
