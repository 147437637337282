import { Card, IconButton, styled } from "@mui/material";
import { Add, Minus } from "assets/icon";
import getValueWithinAllowedRange from "helpers/getValueWithinAllowedRange";

import NumberField, { NumberFieldProps } from "./NumberField";

type IncrementalCounterProps = NumberFieldProps & {
  textColor?: string;
};

const IncrementalCounter = ({
  textColor = "text.primary",
  ...numberFieldProps
}: IncrementalCounterProps) => {
  const {
    value,
    onChange,

    min = 1,
    max = Infinity,
  } = numberFieldProps;

  const callIncrementBy = (increment: number) => () => {
    const valueWithinAllowedRange = getValueWithinAllowedRange(value + increment, min, max);

    onChange(valueWithinAllowedRange);
  };

  const isAllowedToIncrementInfinitely = max === Infinity;

  const shouldDisableMinusButton = value <= min;

  const shouldDisablePlusButton = !isAllowedToIncrementInfinitely && value >= max;

  return (
    <IncrementalCounterWrapper>
      <CounterButton disabled={shouldDisableMinusButton} onClick={callIncrementBy(-1)}>
        <Minus />
      </CounterButton>

      <NumberField
        fullWidth
        variant="standard"
        InputProps={{
          disableUnderline: true,
          sx: (theme) => ({
            input: {
              color: textColor,

              fontSize: "2rem",
              fontWeight: 700,
              textAlign: "center",
              borderColor: theme.palette.bg.secondary,
              backgroundColor: theme.palette.bg.secondary,
            },
          }),
        }}
        {...numberFieldProps}
      />

      <CounterButton disabled={shouldDisablePlusButton} onClick={callIncrementBy(1)}>
        <Add />
      </CounterButton>
    </IncrementalCounterWrapper>
  );
};

export default IncrementalCounter;

const CounterButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: "white",
  width: "48px",
  height: "48px",
  padding: 0,
  cursor: "pointer",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "white",
  },
}));

const IncrementalCounterWrapper = styled(Card)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.bg.secondary,
  padding: theme.spacing(2),
  border: "1px solid #B3B6EF",
  borderRadius: "0.75rem",
}));
