import { Filter } from "@bookpeep/ui";
import { Box, Grid, MenuItem, TextField, styled } from "@mui/material";
import DatePickerButton from "components/Sales/DatePickerButton";
import { PeepSearchField } from "components/common";
import CustomIconButton from "components/common/Buttons/CustomIconButton";
import FiltersDrawer from "components/common/Drawers/FiltersDrawer";
import BookingSourceFilter from "components/common/Filters/BookingSourceFilter";
import CreatedByFilter from "components/common/Filters/CreatedByFilter";
import DepositFilter from "components/common/Filters/DepositFilter";
import StatusFilter from "components/common/Filters/StatusFilter";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useIsMobileView from "hooks/useIsMobileView";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectAllOutlets } from "store/outlets/outletsSlice";
import { selectAllActiveZones } from "store/slices/zones/zonesSelectors";
import { AppointmentSource } from "types/Appointment";

import AppointmentsOverviewContext, {
  AppointmentDurationOptions,
} from "./AppointmentsOverviewContext";

const AppointmentOverviewToolbar = () => {
  const { t } = useTranslation("home");

  const organization = useSelector(selectOrganization);

  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization.industry || 0);

  const isMobileView = useIsMobileView();

  const {
    dateRange,
    setDateRange,

    outletId,
    setOutletId,

    duration,

    searchQuery,
    handleInputChange,

    invoiceStatusOptions,
    setInvoiceStatusOptions,

    bookingSourceOptions,
    setBookingSourceOptions,

    depositOptions,
    setDepositOptions,

    setPage,

    zone,
    setZone,

    createdBy,
    setCreatedBy,

    clearAllFilters,
  } = useContext(AppointmentsOverviewContext);

  const outletsState = useSelector(selectAllOutlets);

  const zonesState = useSelector(selectAllActiveZones);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const openFilterDrawer = () => setIsFilterDrawerOpen(true);
  const closeFilterDrawer = () => setIsFilterDrawerOpen(false);

  const onChangeOutlet = (event: { target: { value: any } }) => setOutletId(event.target.value);

  const onChangeZone = (event: { target: { value: any } }) => setZone(event.target.value);

  const isCustomDurationSelected = duration === AppointmentDurationOptions.Custom;

  return (
    <PageWrapper isMobileView={isMobileView}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid container item xs={12} md={7} spacing={2}>
          {isCustomDurationSelected && (
            <Grid item md={4} xs={6} order={{ xs: 2, md: 1 }}>
              <DatePickerButton onConfirm={setDateRange} dateRange={dateRange} />
            </Grid>
          )}

          {isHomeServiceOrganization ? (
            <Grid item md={4} xs={isCustomDurationSelected ? 6 : 12} order={{ xs: 2, md: 2 }}>
              <TextField
                select
                fullWidth
                label={t("zone")}
                value={zone || 0}
                onChange={onChangeZone}
                placeholder="zone"
              >
                <MenuItem value={0}>All Zones</MenuItem>
                {zonesState.map((zone) => (
                  <MenuItem value={zone.id} key={zone.id}>
                    {zone.deprecated ? `${zone.name} (${t("deleted")})` : zone.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <Grid item md={4} xs={isCustomDurationSelected ? 6 : 12} order={{ xs: 2, md: 2 }}>
              <TextField
                select
                fullWidth
                label={t("location")}
                value={outletId || 0}
                onChange={onChangeOutlet}
                placeholder="location"
              >
                <MenuItem value={0}>All Locations</MenuItem>
                {outletsState.map((outlet) => (
                  <MenuItem value={outlet.id} key={outlet.id}>
                    {outlet.deprecated ? `${outlet.name} (${t("deleted")})` : outlet.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item md={4} xs={12} order={{ xs: 1, md: 3 }}>
            <PeepSearchField
              fullWidth
              label={t("searchByReferenceOrClient")}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
              value={searchQuery}
              onClear={() => handleInputChange("")}
            />
          </Grid>
        </Grid>

        <Grid item md={4} xs={0} order={{ xs: 4, md: 3 }} />

        <Grid item md={1} xs={12} order={{ xs: 3, md: 4 }}>
          <CustomIconButton
            maxWidth="100%"
            label="Filters"
            variant="outlined"
            icon={<Filter />}
            onClick={openFilterDrawer}
          />
        </Grid>
      </Grid>

      <FiltersDrawer
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
        onClear={clearAllFilters}
        filterComponents={[
          // <AreaFilter
          //   key={"areas"}
          //   // @ts-expect-error
          //   value={areas}
          //   setValue={(newValue: number[]) => {
          //     setPage(1);
          //     setAreas(newValue);
          //   }}
          // />,

          <StatusFilter
            key="status_filter"
            statusOptionsFor="invoicesLog"
            // @ts-expect-error
            value={invoiceStatusOptions}
            setValue={(newValue: string[]) => {
              setPage(1);
              setInvoiceStatusOptions(newValue);
            }}
            forInvoicesLog={true}
          />,

          <BookingSourceFilter
            key="source_filter"
            // @ts-expect-error
            value={bookingSourceOptions}
            setValue={(newValue: AppointmentSource[]) => {
              setPage(1);
              setBookingSourceOptions(newValue);
            }}
          />,

          <DepositFilter
            key="deposit_filter"
            // @ts-expect-error
            value={depositOptions}
            setValue={(newValue: string[]) => {
              setPage(1);
              setDepositOptions(newValue);
            }}
          />,

          <CreatedByFilter
            key="created_by_filter"
            // @ts-expect-error
            value={createdBy}
            setValue={(newValue: number[]) => {
              setPage(1);
              setCreatedBy(newValue);
            }}
          />,
        ]}
      />
    </PageWrapper>
  );
};

export default AppointmentOverviewToolbar;

type PageWrapperProps = {
  isMobileView: boolean;
};

const PageWrapper = styled(Box)<PageWrapperProps>(({ theme, isMobileView }) => ({
  paddingLeft: isMobileView ? theme.spacing(0) : theme.spacing(3),
  paddingRight: isMobileView ? theme.spacing(0) : theme.spacing(3),
}));
