import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ClientScalpType } from "types/Client";

import { SliceStatus } from "../utils";
import api from "./clientScalpTypesApi";

export const clientScalpTypesAdapter = createEntityAdapter<ClientScalpType>({
  selectId: (client) => client.id,
});

const initialState = clientScalpTypesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllClientScalpTypes = createThunkFromApiWithType(
  "clientScalpTypes/getAllClientScalpTypes",
  api.getAllClientScalpTypes
);

export const clientScalpTypesSlice = createSlice({
  name: "clientScalpTypes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.status = action.payload ? SliceStatus.LOADING : SliceStatus.IDLE;
    },
  },
  extraReducers: (reducers) => {
    reducers.addCase(getAllClientScalpTypes.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllClientScalpTypes.fulfilled, (state, { payload }) => {
      clientScalpTypesAdapter.setAll(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllClientScalpTypes.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export const { setLoading } = clientScalpTypesSlice.actions;

export default clientScalpTypesSlice.reducer;
