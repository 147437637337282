import { Box, Typography, styled } from "@mui/material";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { ServiceVariantActivePromotionItem } from "types/ActivePromotion";
import { ServiceVariant, ServiceVariantPricingType } from "types/ServiceV2";
import { ClientSubscriptionPurchase } from "types/Subscription";

import PurchasableItem from "./PurchasableItem";

type PurchasableItemWithMultiVariantsProps = {
  title: string;

  variants: ServiceVariant[];
  variantSubscriptions: ClientSubscriptionPurchase[];
  variantPromotions: ServiceVariantActivePromotionItem[];
  selectedVariantIds?: number[];
  handleClick: (variant: ServiceVariant) => void;

  shouldDisableLoyaltyIneligibleItems: boolean;
  helperText?: string;
};

const PurchasableItemWithMultiVariants = ({
  title,
  variants,
  handleClick,
  selectedVariantIds = [],
  variantSubscriptions = [],
  variantPromotions = [],
  shouldDisableLoyaltyIneligibleItems,
  helperText,
}: PurchasableItemWithMultiVariantsProps) => {
  return (
    <PurchasableItemWithMultiVariantsBox>
      <Typography p={2} pt={0} variant="primary" display="block" fontWeight={500} noWrap>
        {title}
      </Typography>

      {variants.map((variant) => {
        const doesVariantHaveSubscription = variantSubscriptions.some((subscription) =>
          subscription.serviceVariants.includes(variant.id)
        );

        const doesVariantHaveActivePromotion = variantPromotions.some(
          (promotion) => promotion.serviceVariant === variant.id || promotion.hasAllServices
        );

        return (
          <PurchasableItem
            key={`Variant-${variant.id}-${variant.name}`}
            isSingleVariant={false}
            isHighlighted={selectedVariantIds.includes(variant.id)}
            helperText={helperText}
            isDisabled={variant.isLoyaltyEligible && shouldDisableLoyaltyIneligibleItems}
            title={`${variant.name}, ${getFormattedDuration(variant.duration)}`}
            price={variant.price}
            shouldShowPricingFrom={variant.pricingType === ServiceVariantPricingType.From}
            shouldShowSubscriptionTag={doesVariantHaveSubscription}
            shouldShowPromotionTag={doesVariantHaveActivePromotion}
            handleClick={() => handleClick(variant)}
          />
        );
      })}
    </PurchasableItemWithMultiVariantsBox>
  );
};

export default PurchasableItemWithMultiVariants;

const PurchasableItemWithMultiVariantsBox = styled(Box)(({ theme }) => ({
  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));
