import { checkIsHomeServiceOrganization } from "helpers/industries";
import { menuPages } from "pages";
import { useSelector } from "react-redux";
import { selectAuth } from "store/auth/authSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";

import AppNavBar from "./AppNavBar";

const AppNavMenu = () => {
  const user = useSelector(selectAuth);
  const myRole = useSelector(selectAllPermissions);

  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization?.industry);

  return <AppNavBar navigationItems={menuPages(user, myRole, isHomeService)} />;
};

export default AppNavMenu;
