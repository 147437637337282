import { LoadingButton } from "@mui/lab";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

function DeleteActions({ handleClose, handleDelete, disableDelete = false, loading }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Button onClick={handleClose} fullWidth variant="outlined">
          {t("cancel")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <LoadingButton
          disabled={disableDelete}
          onClick={handleDelete}
          fullWidth
          color="error"
          variant="contained"
          loading={loading}
        >
          {t("delete")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default DeleteActions;
