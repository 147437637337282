import http from "utils/http";

import downloadResponse from "./downloadResponse";

// The backend expects an empty string not an empty array string
function stringifyArray(array) {
  return array.length < 1 ? "" : array.toString();
}

const salesApi = {
  getSalesDasboardData: async ({ startDate, endDate, outlet }) =>
    http.get(`sales/sales_dashboard/?start_date=${startDate}&end_date=${endDate}&outlet=${outlet}`),

  getServicesExcel: async ({
    startDate,
    endDate,
    outlets,
    employees,
    categories,
    services,
    page,
    hasSubscriptionRedemption,
  }) =>
    http
      .get(
        `sales/sales_services/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
          outlets
        )}&employees=${stringifyArray(employees)}&categories=${stringifyArray(
          categories
        )}&services=${stringifyArray(services)}${
          hasSubscriptionRedemption !== null
            ? `&has_subscription_redemption=${hasSubscriptionRedemption}`
            : ""
        }&excel_download=true&page=${page}`,
        {
          responseType: "arraybuffer",
          transformRequest: [],
          transformResponse: [],
        }
      )
      .then((response) => {
        downloadResponse(response);
      }),

  getServicesLogData: async ({
    startDate,
    endDate,
    outlets,
    areas,
    zones,
    employees,
    categories,
    services,
    page,
    hasSubscriptionRedemption,
  }) =>
    http.get(
      `sales/sales_services/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
        outlets
      )}&employees=${stringifyArray(employees)}&categories=${stringifyArray(
        categories
      )}&zones=${stringifyArray(zones)}&areas=${stringifyArray(areas)}&services=${stringifyArray(
        services
      )}${
        hasSubscriptionRedemption !== null
          ? `&has_subscription_redemption=${hasSubscriptionRedemption}`
          : ""
      }&page=${page}`
    ),

  getCompletedAppointmentsExcel: async ({
    startDate,
    endDate,
    outlets,
    status,
    createdByIds,
    bookingSource = [],
    page,
  }) =>
    http
      .get(
        `sales/sales_appointments/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
          outlets
        )}${
          createdByIds && !!createdByIds.length ? `&created_by=${createdByIds}` : ""
        }&status=${stringifyArray(
          status
        )}&booking_source=${bookingSource}&excel_download=true&page=${page}`,
        {
          responseType: "arraybuffer",
          transformRequest: [],
          transformResponse: [],
        }
      )
      .then((response) => {
        downloadResponse(response);
      }),

  getCompletedAppointmentsData: async ({
    startDate,
    endDate,
    outlets,
    createdByIds,
    status,
    zones,
    areas,
    bookingSource = [],
    page,
  }) =>
    http.get(
      `sales/sales_appointments/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
        outlets
      )}&zones=${stringifyArray(zones)}&areas=${stringifyArray(areas)}&status=${stringifyArray(
        status
      )}&booking_source=${bookingSource}${
        createdByIds && !!createdByIds.length ? `&created_by=${createdByIds}` : ""
      }&page=${page}`
    ),

  getInvoiceExcel: async ({
    startDate,
    endDate,
    outlets,
    status,
    paymentType,
    type,
    bookingSource = [],
    page,
  }) =>
    http
      .get(
        `sales/sales_invoices/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
          outlets
        )}&status=${stringifyArray(status)}&type=${stringifyArray(
          type
        )}&payment_type=${paymentType}&booking_source=${bookingSource}&excel_download=true&page=${page}`,
        {
          responseType: "arraybuffer",
          transformRequest: [],
          transformResponse: [],
        }
      )
      .then((response) => {
        downloadResponse(response);
      }),

  getInvoicesData: async ({
    startDate,
    endDate,
    outlets,
    status,
    type,
    paymentType,
    zones,
    areas,
    bookingSource = [],
    page,
    searchQuery,
  }) =>
    http.get(
      `sales/sales_invoices/?start_date=${startDate}&end_date=${endDate}&outlets=${stringifyArray(
        outlets
      )}&status=${stringifyArray(status)}&type=${stringifyArray(type)}&payment_type=${
        paymentType || ""
      }&booking_source=${bookingSource}&zones=${stringifyArray(zones)}&areas=${stringifyArray(
        areas
      )}&search=${searchQuery}&page=${page}`
    ),
};

export default salesApi;
