import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum CheckoutModalView {
  AddItems = "AddItems",
  Cart = "Cart",
  Client = "Client",
  Payment = "Payment",
  ConfirmSale = "ConfirmSale",
  Invoice = "Invoice",
  InvoiceAddPayments = "InvoiceAddPayments",
  Refund = "Refund",
  ConfirmRefund = "ConfirmRefund",
}

const initialState = {
  isModalOpen: false,

  appointmentId: null as Nullable<number>,
  outletId: null as Nullable<number>,

  invoiceId: null as Nullable<number>,

  currentView: CheckoutModalView.AddItems,
};

type OpenCheckoutModalOptions = {
  appointmentId?: Nullable<number>;

  outletId: number;
};

type OpenInvoiceModeOptions = {
  invoiceId: number;
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,

  reducers: {
    initiateCheckoutModal: (state, { payload }: PayloadAction<OpenCheckoutModalOptions>) => {
      const { appointmentId = null, outletId } = payload;

      state.appointmentId = appointmentId;

      state.outletId = outletId;

      state.isModalOpen = true;
    },

    initiateCheckoutModalWithInvoiceDetailsMode: (
      state,
      { payload }: PayloadAction<OpenInvoiceModeOptions>
    ) => {
      const { invoiceId } = payload;

      state.currentView = CheckoutModalView.Invoice;
      state.invoiceId = invoiceId;
      state.isModalOpen = true;
    },

    terminateCheckoutModal: () => {
      return initialState;
    },

    switchCheckoutModalView: (state, { payload }: PayloadAction<CheckoutModalView>) => {
      state.currentView = payload;
    },

    switchInvoiceId: (state, { payload }: PayloadAction<Nullable<number>>) => {
      state.invoiceId = payload;
    },
  },
});

export const {
  initiateCheckoutModal,
  terminateCheckoutModal,
  switchCheckoutModalView,
  initiateCheckoutModalWithInvoiceDetailsMode,
  switchInvoiceId,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
