import { Box, Divider, Grid, styled } from "@mui/material";
import NoAccessGraphic from "components/common/NoAccessGraphic";
import TableSideBar from "components/common/PeepTable/TableSideBar";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

import { PeepPayLinksContextProvider } from "./PeepPayLinksContext";
import PeepPayLinksSideBarFilters from "./PeepPayLinksSideBarFilters";
import PeepPayLinksTable from "./PeepPayLinksTable";
import PeepPayLinksToolbar from "./PeepPayLinksToolbar";

type PeepPayLinksTabProps = {
  doesOrgHavePeepPay: boolean;
};

const PeepPayLinksTab = ({ doesOrgHavePeepPay }: PeepPayLinksTabProps) => {
  const { t } = useTranslation(["invoices", "home"]);

  const isMobileView = useIsMobileView();

  if (!doesOrgHavePeepPay)
    return <NoAccessGraphic description={t("invoices:contactSalesToManagePeepPay")} />;

  return (
    <PeepPayLinksContextProvider>
      <PageWrapper isMobileView={isMobileView}>
        {isMobileView && (
          <Grid item xs={12} my={1} mb={2}>
            <TableSideBar
              toOpenTitle={t("home:allPeepPayLinks")}
              toCloseTitle={t("home:backToPeepPayLinks")}
            >
              <PeepPayLinksSideBarFilters />
            </TableSideBar>
          </Grid>
        )}

        <PeepPayLinksToolbar />

        {!isMobileView && <Divider variant="fullWidth" sx={{ m: 3 }} />}

        <PeepPayLinksTable />
      </PageWrapper>
    </PeepPayLinksContextProvider>
  );
};

export default PeepPayLinksTab;

type PageWrapperProps = {
  isMobileView: boolean;
};

const PageWrapper = styled(Box)<PageWrapperProps>(({ theme, isMobileView }) => ({
  minHeight: "100vh",
  backgroundColor: theme.palette.bg.secondary,
  padding: isMobileView ? theme.spacing(1) : theme.spacing(3),
}));
