import { Box, Divider, Grid, styled } from "@mui/material";
import TableSideBar from "components/common/PeepTable/TableSideBar";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";

import AppointmentOverviewToolbar from "./AppointmentOverviewToolbar";
import { AppointmentsOverviewContextProvider } from "./AppointmentsOverviewContext";
import AppointmentsOverviewSideBarFilters from "./AppointmentsOverviewSideBarFilters";
import AppointmentsOverviewTable from "./AppointmentsOverviewTable";
import FiltersSection from "./FiltersSection";

const HomeAppointmentsTab = () => {
  const { t } = useTranslation(["home", "authentication"]);

  const isMobileView = useIsMobileView();

  return (
    <AppointmentsOverviewContextProvider>
      <PageWrapper isMobileView={isMobileView}>
        {isMobileView && (
          <Grid item xs={12} my={1} mb={2}>
            <TableSideBar toOpenTitle={t("timePeriod")} toCloseTitle={t("backToAppointments")}>
              <AppointmentsOverviewSideBarFilters />
            </TableSideBar>
          </Grid>
        )}

        <AppointmentOverviewToolbar />

        {!isMobileView && <Divider variant="fullWidth" sx={{ m: 3 }} />}

        <FiltersSection />

        <AppointmentsOverviewTable />
      </PageWrapper>
    </AppointmentsOverviewContextProvider>
  );
};

export default HomeAppointmentsTab;

type PageWrapperProps = {
  isMobileView: boolean;
};

const PageWrapper = styled(Box)<PageWrapperProps>(({ theme, isMobileView }) => ({
  minHeight: "100vh",
  backgroundColor: theme.palette.bg.secondary,
  padding: isMobileView ? theme.spacing(1) : theme.spacing(3),
}));
