import { DiscountOption } from "types/promotions";

type GetFinalPriceAfterDiscountArgs = {
  originalPrice: number;
  discountValue: number;
  discountOption: DiscountOption;
  shouldAllowNegativeFinalPrice?: boolean;
};

/**
 * Calculates the final price after discount based on the original price, discount value, discount option and shouldAllowNegativeFinalPrice
 * @param {keyof GetFinalPriceAfterDiscountArgs} args
 * @returns
 */

export const getFinalPriceAfterDiscount = ({
  originalPrice,
  discountValue,
  discountOption,
  shouldAllowNegativeFinalPrice = false,
}: GetFinalPriceAfterDiscountArgs) => {
  const finalPrice =
    discountOption === DiscountOption.Percentage
      ? (originalPrice * (1 - discountValue / 100)).toFixed(3)
      : (originalPrice - discountValue).toFixed(3);

  const finalPriceAsNumber = Number(finalPrice);

  const priceAfterDiscount = shouldAllowNegativeFinalPrice
    ? finalPriceAsNumber
    : Math.max(0, finalPriceAsNumber);

  return priceAfterDiscount;
};
