import { Autocomplete, TextField } from "@mui/material";
import FilteredItem from "components/common/Chips/FilteredItem";
import { DepositBooleanOptions } from "pages/HomePage/HomeAppointmentsTab/AppointmentsOverviewContext";
import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

type DepositFilterProps = {
  unconfirmedValue: number[];
  setUnconfirmedValue: Dispatch<SetStateAction<string[]>>;
};

const DepositFilter = ({ unconfirmedValue, setUnconfirmedValue }: DepositFilterProps) => {
  const { t } = useTranslation();

  const depositOptions = Object.values(DepositBooleanOptions).map((option) =>
    t(option.toLowerCase())
  );

  const addSelectedOptions = (_: SyntheticEvent<Element, Event>, newDepositOptions: string[]) =>
    setUnconfirmedValue(newDepositOptions);

  return (
    <Autocomplete
      disableClearable={true}
      multiple
      options={depositOptions}
      getOptionLabel={(option) => option}
      //@ts-expect-error
      value={unconfirmedValue}
      onChange={addSelectedOptions}
      renderInput={(params) => <TextField focused={true} label={t("deposit")} {...params} />}
      renderTags={(selectedOptions, getTagProps) =>
        selectedOptions.map((option, index) => (
          <FilteredItem
            variant="outlined"
            color="primary"
            label={option}
            {...getTagProps({ index })}
            key={option}
          />
        ))
      }
    />
  );
};

export default DepositFilter;
