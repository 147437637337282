import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/servicesV2/servicesApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ServiceDetail } from "types/ServiceV2";

import { SliceStatus } from "../utils";

export const servicesAdapter = createEntityAdapter<ServiceDetail>({
  selectId: (service) => service.id,
});

const { selectAll } = servicesAdapter.getSelectors();

const initialState = servicesAdapter.getInitialState<{
  status: SliceStatus;
  isRequestPending: SliceStatus;
  optimisticCache: Nullable<ServiceDetail[]>;
}>({
  status: SliceStatus.IDLE,
  isRequestPending: SliceStatus.IDLE,
  optimisticCache: null,
});

export const getServiceDetails = createThunkFromApiWithType(
  "servicesV2/getServiceDetails",
  api.getServiceDetails
);

export const getAllServices = createThunkFromApiWithType(
  "servicesV2/getAllServices",
  api.getAllServices,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const createService = createThunkFromApiWithType(
  "servicesV2/createService",
  api.createService
);

export const updateService = createThunkFromApiWithType(
  "servicesV2/updateService",
  api.updateService
);

export const updateServicePosition = createThunkFromApiWithType(
  "servicesV2/updateServicePosition",
  api.updateServicePosition
);

export const updateServiceMpPosition = createThunkFromApiWithType(
  "servicesV2/updateServiceMpPosition",
  api.updateServiceMpPosition
);

export const deleteService = createThunkFromApiWithType(
  "servicesV2/deleteService",
  api.removeService
);

export const servicesSlice = createSlice({
  name: "servicesV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllServices.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllServices.fulfilled, (state, { payload }) => {
      servicesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllServices.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createService.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(createService.fulfilled, (state, { payload }) => {
      const { service } = payload;

      servicesAdapter.upsertOne(state, service);

      state.isRequestPending = SliceStatus.IDLE;
    });

    reducers.addCase(createService.rejected, (state) => {
      return { ...state, isRequestPending: SliceStatus.FAILED };
    });

    reducers.addCase(updateService.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(updateService.fulfilled, (state, { payload }) => {
      const { service } = payload;

      servicesAdapter.upsertOne(state, service);

      state.isRequestPending = SliceStatus.IDLE;
    });
    reducers.addCase(getServiceDetails.fulfilled, (state, { payload }) => {
      const { service } = payload;

      servicesAdapter.upsertOne(state, service);
    });

    reducers.addCase(updateService.rejected, (state) => {
      return { ...state, isRequestPending: SliceStatus.FAILED };
    });

    reducers.addCase(
      updateServicePosition.pending,
      (
        state,
        {
          meta: {
            arg: { data },
          },
        }
      ) => {
        const servicesV2 = selectAll(state);
        state.optimisticCache = servicesV2;

        servicesAdapter.upsertMany(state, data);
      }
    );

    reducers.addCase(updateServicePosition.fulfilled, (state, { payload }) => {
      state.optimisticCache = null;
      servicesAdapter.upsertMany(state, payload);
    });

    reducers.addCase(updateServicePosition.rejected, (state) => {
      if (state.optimisticCache) {
        servicesAdapter.setAll(state, state.optimisticCache);
        state.optimisticCache = null;
      }
    });

    reducers.addCase(
      updateServiceMpPosition.pending,
      (
        state,
        {
          meta: {
            arg: { data },
          },
        }
      ) => {
        const servicesV2 = selectAll(state);
        state.optimisticCache = servicesV2;

        servicesAdapter.upsertMany(state, data);
      }
    );

    reducers.addCase(updateServiceMpPosition.fulfilled, (state, { payload }) => {
      state.optimisticCache = null;
      servicesAdapter.upsertMany(state, payload);
    });

    reducers.addCase(updateServiceMpPosition.rejected, (state) => {
      if (state.optimisticCache) {
        servicesAdapter.setAll(state, state.optimisticCache);
        state.optimisticCache = null;
      }
    });

    reducers.addCase(deleteService.pending, (state) => {
      state.isRequestPending = SliceStatus.LOADING;
    });

    reducers.addCase(deleteService.fulfilled, (state, { payload: { service } }) => {
      servicesAdapter.upsertOne(state, service);

      state.isRequestPending = SliceStatus.IDLE;
    });

    reducers.addCase(deleteService.rejected, (state) => {
      return { ...state, isRequestPending: SliceStatus.FAILED };
    });
  },
});

export default servicesSlice.reducer;
