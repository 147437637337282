import { PeepPayLinkStatus } from "types/Deposit";

export const getPeepPayLinkStatusTranslationKey = (status: PeepPayLinkStatus) => {
  switch (status) {
    case PeepPayLinkStatus.Pending:
      return "pending";

    case PeepPayLinkStatus.Paid:
      return "paid";

    case PeepPayLinkStatus.Expired:
      return "expired";

    case PeepPayLinkStatus.Canceled:
      return "canceled";

    default:
      return "pending";
  }
};
