import { Box } from "@mui/material";
import ClientSection, { NewClient } from "components/ClientSection/ClientSection";
import { useFormContext } from "react-hook-form";

import { VoucherGiftFields } from "./voucherGiftSchema";

const GiftFormClientSection = () => {
  const { setValue, watch, formState } = useFormContext<VoucherGiftFields>();

  const { existingClient, newClient } = watch();
  const existingClientId = existingClient?.id || null;

  const setClientId = (clientId: Nullable<number>) => {
    if (clientId) setValue("existingClient.id", clientId);
    else setValue("existingClient", null);
  };

  const setNewClient = (newClient: Nullable<NewClient>) => {
    setValue("newClient", newClient);
  };

  return (
    <Box p={3} boxShadow="divider">
      <ClientSection
        allowedToChangeClient={true}
        existingClientId={existingClientId || null}
        setExistingClientId={setClientId}
        newClient={newClient}
        setNewClient={setNewClient}
        shouldShowNoClient={false}
        shouldTriggerValidation={formState.isSubmitting}
        shouldShowTags={false}
        disablePortalForAutocomplete={false}
      />
    </Box>
  );
};

export default GiftFormClientSection;
