import { DateTime } from "luxon";
import type { RootState } from "store";

import { resourceBlockTimesAdapter } from "./resourceBlockTimesSlice";

const { selectById, selectAll } = resourceBlockTimesAdapter.getSelectors<RootState>(
  (state) => state.resourceBlockTimes
);

export const selectResourceBlockTimeById = (id: number) => (state: RootState) =>
  selectById(state, id);

export const selectAllResourceBlockTimes = selectAll;

export const selectResourceBlockTimesByDateRange =
  (startDate: DateTime, endDate: DateTime) => (state: RootState) => {
    const allResourceBlockTimes = selectAllResourceBlockTimes(state);

    return allResourceBlockTimes.filter((blockTime) => {
      const blockStartTimeDate = DateTime.fromISO(blockTime.startTime);
      const blockEndTimeDate = DateTime.fromISO(blockTime.endTime);
      return (
        startDate.startOf("day") <= blockStartTimeDate && endDate.endOf("day") >= blockEndTimeDate
      );
    });
  };
