import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";

type ItemPriceProps = BoxProps & {
  originalPrice: number;
  finalPrice: number;
  quantity?: number;
  finalPriceProps?: TypographyProps;
  originalPriceProps?: TypographyProps;
  isPricingTypeFrom?: boolean;
  shouldDisplayZeroAsFree?: boolean;
  isNegative?: boolean;
};

const ItemPrice = ({
  originalPrice,
  finalPrice,
  quantity = 1,
  flexDirection = "column",
  alignItems = "flex-end",
  finalPriceProps,
  originalPriceProps,
  isPricingTypeFrom = false,
  shouldDisplayZeroAsFree = true,
  isNegative = false,

  ...boxProps
}: ItemPriceProps) => {
  const { t } = useTranslation("checkout");

  const hasAppliedDiscount = originalPrice > finalPrice;

  const isFree = finalPrice === 0;

  const finalPriceToDisplay = () => {
    if (isFree && shouldDisplayZeroAsFree) return t("free");

    return `${t("currency")}\u00A0${getFormattedPrice(finalPrice * quantity)}`;
  };

  return (
    <Box
      display="flex"
      flexDirection={flexDirection}
      alignItems={alignItems}
      columnGap={1}
      {...boxProps}
    >
      <Typography
        order={flexDirection === "row" ? 2 : 1}
        variant="secondary"
        color={isFree || hasAppliedDiscount ? "text.secondary" : "text.primary"}
        textTransform={isFree ? "uppercase" : "none"}
        fontSize="1rem"
        fontWeight={700}
        noWrap
        {...finalPriceProps}
      >
        {isPricingTypeFrom && (
          <Typography variant="secondary" color="text.placeholder" fontSize="1rem">
            {t("from")}{" "}
          </Typography>
        )}

        {`${isNegative ? "-" : ""}\u00A0${finalPriceToDisplay()}`}
      </Typography>

      {hasAppliedDiscount && (
        <Typography
          order={flexDirection === "row" ? 1 : 2}
          variant="secondary"
          color="text.placeholder"
          fontWeight={500}
          sx={{ textDecoration: "line-through" }}
          {...originalPriceProps}
        >{`${isNegative ? "-" : ""}\u00A0${t("currency")}\u00A0${getFormattedPrice(
          originalPrice * quantity
        )}`}</Typography>
      )}
    </Box>
  );
};

export default ItemPrice;
