import { Box } from "@mui/material";

type ColoredDotProps = {
  color: string;
};

const ColoredDot = ({ color }: ColoredDotProps) => {
  return (
    <Box
      sx={{
        minHeight: "12px !important",
        minWidth: "12px !important",
        backgroundColor: color,
        borderRadius: "50%",
        display: "inline-block",
      }}
    />
  );
};

export default ColoredDot;
