import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ProductCategory } from "types/ProductCategory";

import {
  appendAllProductsData,
  createProduct,
  createStockDown,
  createStockTransfer,
  createStockUp,
  getAllProductsData,
  getProductData,
  updateProduct,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";
import api from "./productCategoriesApi";

export const productCategoriesAdapter = createEntityAdapter<ProductCategory>({
  selectId: (productCategory) => productCategory.id,
});

const initialState = productCategoriesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllProductCategories = createThunkFromApiWithType(
  "productCategories/getAllProductCategories",
  api.getAllProductCategories
);

export const getProductCategory = createThunkFromApiWithType(
  "productCategories/getProductCategory",
  api.getProductCategory
);

export const createCategory = createThunkFromApiWithType(
  "productCategories/createCategory",
  api.createCategory,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);


export const updateProductCategory = createThunkFromApiWithType(
  "productCategories/updateProductCategory",
  api.updateCategory
);

export const deleteProductCategory = createThunkFromApiWithType(
  "productCategories/deleteProductCategory",
  api.deleteCategory
);

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllProductCategories.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductCategories.fulfilled, (state, { payload }) => {
      productCategoriesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductCategories.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getProductData.fulfilled, (state, { payload: { category } }) => {
      productCategoriesAdapter.upsertOne(state, category);
    });

    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { category } = payload;

      productCategoriesAdapter.upsertOne(state, category);
    });

    reducers.addCase(getAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductsData.fulfilled, (state, { payload }) => {
      productCategoriesAdapter.upsertMany(state, payload.results.categories);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductsData.fulfilled, (state, { payload }) => {
      productCategoriesAdapter.upsertMany(state, payload.results.categories);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendAllProductsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProduct.fulfilled, (state, { payload: { category } }) => {
      productCategoriesAdapter.upsertOne(state, category);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createCategory.fulfilled, (state, { payload }) => {
      productCategoriesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });


    reducers.addCase(updateProductCategory.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateProductCategory.fulfilled, (state, { payload }) => {
      productCategoriesAdapter.upsertOne(state, payload);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateProductCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED }
    });

    reducers.addCase(deleteProductCategory.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteProductCategory.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        productCategoriesAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteProductCategory.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED }
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { category } }) => {
      productCategoriesAdapter.upsertOne(state, category);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { category } }) => {
      productCategoriesAdapter.upsertOne(state, category);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { category } }) => {
      productCategoriesAdapter.upsertOne(state, category);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default productCategoriesSlice.reducer;
