import { ClientAnalytic } from "types/ClientAnalytic";
import http from "utils/http";

type ClientAnalyticsResponse = {
  results: ClientAnalytic[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
};

const clientAnalyticsApi = {
  getClientAnalytics: async ({
    page,
    days,
    statusOption,
    outletId,
    ordering,
    searchQuery,
  }: {
    page: number;
    days: Nullable<number>;
    statusOption: string;
    outletId: number;
    ordering?: string[];
    searchQuery?: string;
  }) =>
    http.get<ClientAnalyticsResponse>(
      `organization_settings/client_analytics/?page=${page}${
        outletId !== 0 ? `&outlet=${outletId}` : ""
      }${days ? `&days=${days}` : ""}${statusOption ? `&status=${statusOption}` : ""}${
        ordering && !!ordering.length ? `&order_by=${ordering[0]}` : ""
      }${searchQuery && !statusOption && !days ? `&search=${searchQuery}` : ""}`
    ),
};

export default clientAnalyticsApi;
