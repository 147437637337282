import { InvoicePurchasableItemType } from "types/Invoice";
import { PurchasableItemOption } from "types/PurchasableItem";
import { isStringIsoDate, makeFieldRequired } from "utils/formValidation";
import { z } from "zod";

import { refundInvoiceSchema } from "./checkoutRefundSchema";

export const purchasableItemsSchema = z
  .object({
    serviceVariant: z.number().nullable(),
    packageVariant: z.number().nullable(),
    product: z.number().nullable(),
    subscription: z.number().nullable(),
    voucher: z.number().nullable(),
    // the invoice response has purchasableType, but the create invoice request doesn't
    purchasableType: z.nativeEnum(InvoicePurchasableItemType).optional(),
  })
  .partial()
  .superRefine(({ serviceVariant, packageVariant, product, subscription, voucher }, ctx) => {
    // at least one field is required,
    if (!serviceVariant && !packageVariant && !product && !subscription && !voucher) {
      return ctx.addIssue({
        code: "custom",
        path: ["serviceVariant", "packageVariant", "product", "subscription", "voucher"],
        message: "mixed.required",
      });
    }
    // if more than one field is provided, it's invalid
    if (
      (serviceVariant && packageVariant) ||
      (serviceVariant && product) ||
      (packageVariant && product) ||
      (subscription && product) ||
      (subscription && packageVariant) ||
      (subscription && serviceVariant) ||
      (voucher && product) ||
      (voucher && packageVariant) ||
      (voucher && serviceVariant) ||
      (voucher && subscription)
    ) {
      return ctx.addIssue({
        code: "custom",
        path: ["serviceVariant", "packageVariant", "product", "subscription", "voucher"],
        message: "custom.invalid",
      });
    }
  });

export const articleDetailPurchasableItemsSchema = z
  .object({
    // only one is required, at a time
    serviceVariant: z.number().nullable(),
    product: z.number().nullable(),
    subscription: z.number().nullable(),
    voucher: z.number().nullable(),
  })
  .partial()
  .superRefine(({ serviceVariant, product, subscription, voucher }, ctx) => {
    // at least one field is required,
    if (!serviceVariant && !product && !subscription && !voucher) {
      return ctx.addIssue({
        code: "custom",
        path: ["serviceVariant", "product", "subscription", "voucher"],
        message: "mixed.required",
      });
    }
    // if more than one field is provided, it's invalid
    if (
      (serviceVariant && product) ||
      (serviceVariant && subscription) ||
      (product && subscription) ||
      (voucher && product) ||
      (voucher && subscription) ||
      (voucher && serviceVariant)
    ) {
      return ctx.addIssue({
        code: "custom",
        path: ["serviceVariant", "product", "subscription", "voucher"],
        message: "mixed.required",
      });
    }
  });

export const checkoutArticleSchema = z
  .object({
    // extra fields for the frontend only
    title: z.string(),
    subtitle: z.string(),
    subtitleAdditionalInfo: z.string().optional(),
    // end of extra fields

    quantity: z.number().min(1).optional(),

    finalPrice: z.number(),
    markedPrice: z.number(), // for appointment it should be the original price
    originalPrice: z.number(),

    // Field on the frontend only
    discountValue: z.number().default(0),

    purchasedItem: purchasableItemsSchema,
    purchasableItemType: z.nativeEnum(PurchasableItemOption),

    // used to redeem a subscription
    subscriptionPurchaseId: z.number().min(1).nullable().optional(),
    // used to store if there was a subscription redemption when the appointment was booked online
    subscriptionRedemptionId: z.number().min(1).nullable().optional(),

    appointmentArticleId: z.number().nullable().default(null).optional(),

    appointmentArticlePromotionId: z.number().nullable().default(null).optional(),
    promotionId: z.number().nullable().default(null).optional(),

    rewardLoyaltyCard: z.number().nullable().default(null).optional(),

    details: z.array(
      z
        .object({
          purchasedItem: articleDetailPurchasableItemsSchema,

          saleTime: z.string().refine(isStringIsoDate),
          duration: z.number(),
          saleEmployee: z
            .number()
            .optional()
            .superRefine((value, ctx) => {
              if (value === undefined) {
                return ctx.addIssue({
                  code: "custom",
                  message: "mixed.required",
                });
              }
            }),

          // NOTE: we are assuming that when resource is null then it is not required, but if it was it will be set to undefined until user selects a value then throw the error
          resource: z.number().nullable().optional(),
        })
        .superRefine(({ purchasedItem, resource }, ctx) => {
          // NOTE: we are assuming that when resource is null then it is not required, but if it was it will be set to undefined until user selects a value then throw the error
          if (purchasedItem.serviceVariant && resource === undefined) {
            ctx.addIssue({
              path: ["resource"],
              code: "custom",
              message: "mixed.required",
            });
          }
        })
    ),
  })
  .superRefine(({ purchasableItemType, quantity }, ctx) => {
    if (purchasableItemType === PurchasableItemOption.Product) makeFieldRequired(quantity, ctx);
  });

export const voucherRedemptionSchema = z
  .object({
    voucherPurchase: z.number().min(1),
    value: z.coerce.number().positive(), // this accepts numbers as a string and numbers by checking if converting the value to a number returns a valid number
  })
  .superRefine(({ voucherPurchase, value }, ctx) => {
    makeFieldRequired(voucherPurchase, ctx);
    makeFieldRequired(value, ctx);
  });

const newClientSchema = z.object({
  firstName: z.string().max(255),
  lastName: z.string().max(255).optional(),

  phoneCountryCode: z.string(),
  phoneNumber: z.string(),
});

const existingClientSchema = z.object({
  id: z.number().min(1),
});

const checkoutFormSchema = z
  .object({
    // Invoice id
    id: z.number().optional(),

    // number: z.number().optional(),
    // status: z.nativeEnum(InvoiceStatus).optional(),

    articles: z.array(checkoutArticleSchema),

    outlet: z.number(),

    notes: z.string(),

    appointment: z.number().nullable(),
    deletedArticles: z.array(z.number()),

    newClient: newClientSchema.nullable(),
    existingClient: existingClientSchema.nullable(),

    // extra fields for the frontend only
    isClientRequired: z.boolean().optional(),
    appliedClientSpecialDiscount: z.number().optional().default(0),
    paymentAmount: z.number(),

    voucherRedemptions: z.array(voucherRedemptionSchema),

    payments: z.array(
      z
        .object({
          amount: z.number(),
          paymentType: z.number().nullable(),
          peepPay: z
            .object({
              phoneNumber: z.string(),
              phoneCountryCode: z.string(),
            })
            .nullable(),
        })
        .superRefine(({ paymentType, peepPay }, ctx) => {
          if (!paymentType && !peepPay) {
            return ctx.addIssue({
              code: "custom",
              path: ["paymentType"],
              message: "mixed.required",
            });
          }
        })
    ),

    // for the create invoice request only,
    walletPayments: z
      .array(
        z.object({
          amount: z.number(),
          walletType: z.string(),
          walletId: z.number(),
        })
      )
      .default([]),

    refund: z
      .object({
        wallet: z.boolean(),
        paymentType: z.number().nullable(),
        amount: z.number(),
      })
      .optional(),

    //  Refund Invoice Fields
    refundInvoice: refundInvoiceSchema.optional(),
  })
  .superRefine(({ articles, newClient, existingClient }, ctx) => {
    // Vouchers and subscriptions require a client, throw an error if there is no client and there is a voucher or subscription

    const hasSubscription = articles.some(
      (article) => article.purchasableItemType === PurchasableItemOption.Subscription
    );

    const hasVoucher = articles.some(
      (article) => article.purchasableItemType === PurchasableItemOption.Voucher
    );

    const newClientConsideredEmpty = !newClient || !newClient.firstName || !newClient.phoneNumber;

    const hasNoClient = newClientConsideredEmpty && !existingClient;

    const shouldDisplayClientRequired = hasNoClient && (hasVoucher || hasSubscription);

    if (shouldDisplayClientRequired)
      return ctx.addIssue({
        code: "custom",
        path: ["isClientRequired"],
        message: hasVoucher ? "custom.voucherClientRequired" : "custom.subscriptionClientRequired",
      });
  });

export default checkoutFormSchema;
