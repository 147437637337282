import { FormControlLabel, FormControlLabelProps, Switch, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { dontForwardProps } from "helpers/styles";
import { useEffect, useState } from "react";

type PeepSwitchProps = {
  name: string;
  setValue: any;
  label?: string;
  switchVariant?: boolean;
  checked?: boolean;
  onChange?: () => void;
  labelColor?: string;
};

const PeepSwitchDeprecated = ({
  name,
  setValue,
  label,
  switchVariant,
  checked = false,
  onChange,
  labelColor,
  ...props
}: PeepSwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  const theme = useTheme();

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChecked: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const isAvailable = event.target.checked;
    setIsChecked(isAvailable);
    setValue(name, isAvailable);
  };

  return label ? (
    <StyledFormControlLabel
      labelColor={labelColor ? labelColor : theme.palette.text.label}
      control={
        <StyledSwitch
          name={name}
          onChange={switchVariant ? onChange : (e) => handleChecked(e)}
          checked={switchVariant ? checked : isChecked}
          disableRipple
          {...props}
        />
      }
      label={label}
    />
  ) : (
    <StyledSwitch
      name={name}
      onChange={switchVariant ? onChange : (e) => handleChecked(e)}
      checked={switchVariant ? checked : isChecked}
      disableRipple
      {...props}
    />
  );
};

export default PeepSwitchDeprecated;

export const StyledFormControlLabel = styled(
  FormControlLabel,
  dontForwardProps("labelColor")
)<FormControlLabelProps & { labelColor: string }>(({ theme, labelColor }) => ({
  marginLeft: 0,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    ".MuiTypography-root": {
      paddingLeft: "0 !important",
      fontSize: 14,
    },
  },
  "& .MuiFormControlLabel-label": {
    color: labelColor && labelColor,
    fontWeight: 500,
    paddingLeft: theme.spacing(2),
  },
}));

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: "46px",
  height: 31,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginTop: 2,
    marginRight: 0,
    marginBottom: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#43A047",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 28,
    height: 28,
  },
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#E9E9EA",
    opacity: 1,
    // @ts-expect-error
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
