import { Badge, IconButton, Popover, Typography } from "@mui/material";
import { BellIcon } from "assets/icon";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectNewNotificationsCount, selectNewNotificationsIds } from "store/selectors";
import { updateNotificationsStatus } from "store/slices/notifications/notificationsSlice";

import NotificationToast from "../NotificationToast";
import NotificationsList from "./NotificationsList";

const NotificationsCenter = () => {
  const { t } = useTranslation("notifications");

  const dispatch = useDispatch();

  const [notificationsCenterAnchor, setNotificationsCenterAnchor] =
    useState<HTMLButtonElement | null>(null);

  const newNotificationsCount = useSelector(selectNewNotificationsCount);

  const newNotificationsIds = useSelector(selectNewNotificationsIds);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setNotificationsCenterAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setNotificationsCenterAnchor(null);
    dispatch(updateNotificationsStatus({ notificationIds: newNotificationsIds }));
  };

  const isNotificationsCenterOpen = Boolean(notificationsCenterAnchor);

  return (
    <>
      <NotificationToast />

      <IconButton sx={{ p: { xs: 0.5 } }} size="large" onClick={handleOpen}>
        <Badge badgeContent={newNotificationsCount} color="error">
          <BellIcon sx={{ width: "20px", height: "20px", color: "white" }} />
        </Badge>
      </IconButton>

      {isNotificationsCenterOpen && (
        <Popover
          open={isNotificationsCenterOpen}
          anchorEl={notificationsCenterAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: (theme) => theme.shadows.deepCard,
              borderRadius: (theme) => theme.spacing(1),
            },
          }}
        >
          <Typography variant="h4" px={2} pt={2}>
            {t("notifications")}
          </Typography>

          <NotificationsList handleClose={handleClose} />
        </Popover>
      )}
    </>
  );
};

export default NotificationsCenter;
