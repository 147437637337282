import i18next from "i18next";

import andifyArray from "./andifyArray";

/**
 * A function that humanizes number of days.
 *
 * Example: 7 => "1 week"
 * Example: 60 => "2 months"
 *
 * @param int number of days to be humanized
 * @returns String representing human duration from number of days
 */
export default function humanizeDayDuration(numOfDays: number) {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;

  let numberOfDays = numOfDays;

  while (numberOfDays) {
    if (numberOfDays >= 365) {
      years++;
      numberOfDays -= 365;
    } else if (numberOfDays >= 30) {
      months++;
      numberOfDays -= 30;
    } else if (numberOfDays >= 7) {
      weeks++;
      numberOfDays -= 7;
    } else {
      days++;
      numberOfDays--;
    }
  }

  const duration = [];
  if (years > 0) duration.push(i18next.t("subscriptions:year", { count: years }));
  if (months > 0) duration.push(i18next.t("subscriptions:month", { count: months }));
  if (weeks > 0) duration.push(i18next.t("subscriptions:week", { count: weeks }));
  if (days > 0) duration.push(i18next.t("subscriptions:day", { count: days }));

  return andifyArray(duration, i18next.t("and"));
}
