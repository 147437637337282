import { reportingObserverIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,

    integrations: [
      Sentry.browserTracingIntegration(),

      reportingObserverIntegration(),

      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.4,

    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
