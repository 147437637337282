import { Box, Typography } from "@mui/material";
import { StatusChip } from "components/common";
import OnlineBookingChip from "components/common/Chips/OnlineBookingChip";
import { getAppointmentStatusIcon } from "helpers/getAppointmentStatusIcon";
import getFormattedPrice from "helpers/getFormattedPrice";
import { getInvoiceStatusColor } from "helpers/styles";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { AppointmentHome } from "types/Appointment";

export const appointmentsOverviewRows = (
  appointments: AppointmentHome[],
  handleOpenAppointmentDetailDrawer: any,
  t: any
) => {
  function capitalize(word: string) {
    const lower = word.toLowerCase();
    return word[0] + lower.slice(1);
  }

  return appointments.map((appointment) => {
    const { invoice, client } = appointment;

    return {
      reference: {
        component: (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ textDecoration: "none" }}
            onClick={() => handleOpenAppointmentDetailDrawer(appointment.id)}
          >
            <Typography color="primary">{appointment.id}</Typography>
            {appointment.createdOnline ? (
              <OnlineBookingChip minimal={true} source={appointment?.source} />
            ) : null}
          </Box>
        ),
        align: "left",
      },

      status: {
        component: (
          <Box display="flex" alignItems="center" pt={0.5}>
            {getAppointmentStatusIcon(appointment.status)}

            <Box px={1}>
              <Typography variant="primary">{capitalize(appointment.status)}</Typography>
            </Box>
          </Box>
        ),
        align: "left",
      },

      client: {
        component: (
          <Typography variant="primary">
            {client && client.firstName ? `${client.firstName} ${client.lastName}` : t("walkIn")}
          </Typography>
        ),
        align: "left",
      },

      appointmentDate: {
        component: (
          <Typography variant="primary">
            {DateTime.fromISO(appointment.startTime).toFormat("dd MMM yyyy - t")}
          </Typography>
        ),
        align: "left",
      },

      location: {
        component: <Typography variant="primary">{appointment.outlet}</Typography>,
        align: "left",
      },

      area: {
        component: (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography fontWeight={800} variant="primary">
              {appointment.area ? appointment.area : "-"}
            </Typography>
            <Typography variant="primary">{appointment.zone ? appointment.zone : "-"}</Typography>
          </Box>
        ),
        align: "left",
      },

      price: {
        component: (
          <Typography variant="primary">
            {" "}
            {`${t("currency")} ${getFormattedPrice(appointment.total)}`}
          </Typography>
        ),
        align: "left",
      },

      deposit: {
        component: (
          <Typography variant="primary">
            {appointment.deposit === 0
              ? "-"
              : `${t("currency")} ${getFormattedPrice(appointment.deposit)}`}
          </Typography>
        ),
        align: "left",
      },

      invoice: {
        component: invoice ? (
          <Link style={{ textDecoration: "none" }} to={`/invoice/${invoice.id}`}>
            <Typography variant="primary" color="primary" fontWeight={500}>
              {invoice.prefix}
            </Typography>
          </Link>
        ) : (
          "-"
        ),
        align: "left",
      },

      payment: {
        component: invoice ? (
          <StatusChip
            label={t(invoice.status)}
            statusColor={getInvoiceStatusColor(invoice.status)}
          />
        ) : (
          "-"
        ),
        align: "left",
      },

      createdAt: {
        component: (
          <Typography variant="primary">
            {DateTime.fromISO(appointment.createdAt).toFormat("dd MMM yyyy - t")}
          </Typography>
        ),
        align: "left",
      },
    };
  });
};
