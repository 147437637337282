import http from "utils/http";

type SupplierContactArguments = {
  name: SupplierContact["name"];
  jobTitle?: SupplierContact["jobTitle"];
  email?: SupplierContact["email"];
  phoneNumber?: SupplierContact["phoneNumber"];
  phoneCountryCode?: SupplierContact["phoneCountryCode"];
};

type AddressArguments = {
  country: string;
  line1?: string;
  line2?: string;
};

type DeletedSupplierContacts = {
  id: number;
};

export type CreateSupplierArguments = {
  name: Supplier["name"];
  email?: Supplier["email"];
  phoneNumber?: Supplier["phoneNumber"];
  phoneCountryCode?: Supplier["phoneCountryCode"];
  address: AddressArguments;
  supplierContacts: SupplierContactArguments[];
};

type SupplierUpdateArguments = CreateSupplierArguments & {
  deletedContacts: DeletedSupplierContacts[];
};

type SupplierResponse = {
  supplier: Supplier;
  supplierContacts: SupplierContact[];
};

type UpdateSupplierResponse = SupplierResponse & {
  deletedContacts: DeletedSupplierContacts[];
};

export type SupplierListResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  results: {
    suppliers: Supplier[];
    supplierContacts: SupplierContact[];
  };
};

type SupplierListArguments = {
  pageSize?: number;
  searchQuery?: string;
  page?: number;
  ordering?: string;
};

const suppliersApi = {
  getSupplier: async (id: number) => http.get<SupplierResponse>(`suppliers/suppliers/${id}/`),

  getAllSuppliers: async () => http.get<Supplier[]>(`suppliers/brief/`),

  getSuppliers: async ({ page, pageSize, searchQuery, ordering }: SupplierListArguments) =>
    http.get<SupplierListResponse>(
      `suppliers/suppliers/${page ? `?page=${page}` : ""}${
        pageSize ? `?page_size=${pageSize}` : ""
      }${ordering ? `&order_by=${ordering}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}`
    ),

  createSupplier: async ({ data }: { data: CreateSupplierArguments }) =>
    http.post<SupplierResponse>("suppliers/suppliers/", data),

  updateSupplier: async ({ id, data }: { id: number; data: SupplierUpdateArguments }) =>
    http.put<UpdateSupplierResponse>(`suppliers/suppliers/${id}/`, data),

  removeSupplier: async ({ id }: { id: number }) => http.delete(`suppliers/suppliers/${id}/`),
};

export default suppliersApi;
