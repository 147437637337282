import * as i18next from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

export enum Languages {
  arabic = "ar",
  english = "en",
}

const fallbackLng = (code: string): string[] => {
  if (!code || code !== ("en" || "ar")) return [Languages.english];
  else return [Languages.english];
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,

    interpolation: {
      escapeValue: false,
    },
  });

i18next.loadLanguages(["en", "ar"]);

// we need to load "validation" namespace to avoid weird loading behavior on the forms that use useFormValidation Hook on the first visit after the initial load. So instead of having to load the namespace on every form, we load it here once and for all - Anfal The Great 💎
i18next.loadNamespaces([
  "validation",
  "client",
  "subscriptions",
  "calendar",
  "checkout",
  "appointments",
  "notifications",
]);

export default i18next;
