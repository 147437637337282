export enum Industry {
  Beauty = 1,
  Sports = 2,
  Other = 3,
  Clinics = 4,
  Spa = 5,
  BeautyHomeService = 6,
}

export const BeautyIndustries = [Industry.Beauty, Industry.Spa, Industry.BeautyHomeService];

// Home services may include industries more than beauty eventually
export const HomeServiceIndustries = [Industry.BeautyHomeService];
