import { ActivePromotion } from "types/ActivePromotion";
import http from "utils/http";

type ActivePromotionsArguments = { date: string; outletId: number };

const activePromotionsApi = {
  getActivePromotions: async ({ date, outletId }: ActivePromotionsArguments) =>
    http.get<ActivePromotion[]>(`promotions/active/?outlet=${outletId}&date=${date}`),
};

export default activePromotionsApi;
