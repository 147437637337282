import { Box, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

type DetailsRowProps = {
  label: string;
  value: string | undefined;
  icon?: ReactNode;
  primaryColor?: boolean;
};

const DetailsRow = ({ label, value, icon, primaryColor }: DetailsRowProps) => {
  return (
    <>
      <Grid item xs={6} md={4}>
        <Typography variant="primary" color="#848FA1" fontWeight={700} fontSize="0.875rem">
          {label}
        </Typography>
      </Grid>

      <Grid item xs={6} md={8}>
        <Box display="flex">
          {!!icon && icon}
          <Typography
            variant="primary"
            color={primaryColor ? "primary" : ""}
            fontWeight={700}
            fontSize="0.875rem"
          >
            {value}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default DetailsRow;
