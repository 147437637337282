import { Box } from "@mui/material";
import { PurchasableItemOption } from "types/PurchasableItem";

type CartItemImageProps = {
  purchasableItemType: PurchasableItemOption;
};

const cartItemImage = [
  {
    purchasableItemType: PurchasableItemOption.Subscription,
    imgAltText: "Subscription icon in cart item",
    imgSrc: "/images/subscriptionInCheckoutCart.svg",
  },
  {
    purchasableItemType: PurchasableItemOption.Voucher,
    imgAltText: "Voucher icon in cart item",
    imgSrc: "/images/voucherInCheckoutCart.svg",
  },
];

const CartItemImage = ({ purchasableItemType }: CartItemImageProps) => {
  const { imgAltText = "", imgSrc = "" } =
    cartItemImage.find((item) => item.purchasableItemType === purchasableItemType) || {};

  return (
    <Box
      component="img"
      sx={{
        objectFit: "cover",
      }}
      alt={imgAltText}
      src={imgSrc}
    />
  );
};

export default CartItemImage;
