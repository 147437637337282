import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getErrorMessage } from "helpers/errors";
import type { RootState } from "store";
import auth from "utils/auth";

import createThunkFromApiWithType from "../utils/createThunkFromApiWithType";
import api, {
  ChangePasswordArguments,
  DjangoDetailResponse,
  ForgotPasswordArguments,
  ForgotPasswordResponse,
  LoginArguments,
  LoginResponse,
  RequestVerifyArguments,
  ResetPasswordArguments,
  ResetPasswordResponse,
  ValidateTokenArguments,
  ValidateTokenResponse,
  VerifyArguments,
  VerifyResponse,
} from "./authApi";

const {
  id,
  email = "",
  firstName = "",
  lastName = "",
  phoneCountryCode = "",
  phoneNumber = "",

  dailyPerformanceEmail = false,
  weeklyPerformanceEmail = false,
  onlineBookingConfirmationEmail = false,

  emailVerified = false,
  phoneVerified = false,

  isOwnerOrAdmin = false,
  isEmployee = false,
  employeeId = null,
} = auth.user || {};

const initialState = {
  id,
  email,
  firstName,
  lastName,
  phoneCountryCode,
  phoneNumber,

  dailyPerformanceEmail,
  weeklyPerformanceEmail,
  onlineBookingConfirmationEmail,

  emailVerified,
  phoneVerified,

  isOwnerOrAdmin,
  isEmployee,
  employeeId,

  isLoading: false,
  isRequestPending: false,
};

export const selectAuth = (state: RootState) => state.auth;
export const selectIsLoggedIn = () => auth.isLoggedIn;
export const selectAuthIsLoading = (state: RootState) => state.auth.isLoading;
export const selectAuthIsRequestPending = (state: RootState) => state.auth.isRequestPending;
export const selectAuthenticatedUserEmployeeId = (state: RootState) => state.auth.employeeId;

export const login = createAsyncThunk<
  LoginResponse,
  LoginArguments,
  { rejectValue: string | AxiosResponse }
>("auth/login", async (loginArgs, { rejectWithValue }) => {
  try {
    const { username, password, shouldRememberUser } = loginArgs;
    const response = await api.login({ username, password });
    const user = response.data;

    auth.login(user, shouldRememberUser);

    return response.data;
  } catch (error) {
    auth.logout();

    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const changePassword = createAsyncThunk<
  DjangoDetailResponse,
  ChangePasswordArguments,
  { rejectValue: string | AxiosResponse }
>("auth/changePassword", async (changePasswordArgs, { rejectWithValue }) => {
  try {
    const response = await api.changePassword(changePasswordArgs);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 400)
        return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const verifyEmail = createAsyncThunk<
  VerifyResponse,
  VerifyArguments,
  { rejectValue: string | AxiosResponse }
>("auth/verifyEmail", async (verifyEmailArgs, { rejectWithValue }) => {
  try {
    const response = await api.verifyEmail(verifyEmailArgs);
    if (auth.user) {
      const updatedUser = { ...auth.user, emailVerified: true };
      auth.updateUser(updatedUser);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 400)
        return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const requestVerifyEmail = createAsyncThunk<
  VerifyResponse,
  RequestVerifyArguments,
  { rejectValue: string | AxiosResponse }
>("auth/requestVerifyEmail", async (_, { rejectWithValue }) => {
  try {
    const response = await api.requestVerifyEmail();

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 400)
        return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const forgotPassword = createAsyncThunk<
  ForgotPasswordResponse,
  ForgotPasswordArguments,
  { rejectValue: string | AxiosResponse }
>("auth/forgotPassword", async (forgotPasswordArgs, { rejectWithValue }) => {
  try {
    const response = await api.forgotPassword(forgotPasswordArgs);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
      return rejectWithValue(data);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const resetPassword = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordArguments,
  { rejectValue: string | AxiosResponse }
>("auth/resetPassword", async (resetPasswordArgs, { rejectWithValue }) => {
  try {
    const response = await api.resetPassword(resetPasswordArgs);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401) return rejectWithValue((data as DjangoDetailResponse).detail);
      return rejectWithValue(data);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const validateResetToken = createAsyncThunk<
  ValidateTokenResponse,
  ValidateTokenArguments,
  { rejectValue: string | AxiosResponse }
>("auth/validateResetToken", async (validateTokenArgs, { rejectWithValue }) => {
  try {
    const response = await api.validateResetToken(validateTokenArgs);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;

      if (status === 401 || status === 404)
        return rejectWithValue((data as DjangoDetailResponse).detail);
    }

    return rejectWithValue(getErrorMessage(error));
  }
});

export const updateUser = createThunkFromApiWithType("auth/updateUser", api.updateUser, {
  handleResponse: ({ response }) => {
    auth.updateUser(response.data);
  },
});

export const logout = createAction("auth/logout", () => {
  auth.logout();
  return { payload: null };
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadSession: (state, { payload }: { payload: LoginResponse }) => {
      return { ...state, ...payload, isRequestPending: false, isLoading: false };
    },
  },
  extraReducers: (reducers) => {
    reducers
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isRequestPending = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        return { ...state, ...payload, isRequestPending: false, isLoading: false };
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.isRequestPending = false;
      })

      .addCase(updateUser.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(updateUser.fulfilled, (state, { payload }) => {
        return { ...state, ...payload, isRequestPending: false, isLoading: false };
      })

      .addCase(updateUser.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(verifyEmail.fulfilled, (state) => {
        return { ...state, emailVerified: true };
      })

      .addCase(changePassword.pending, (state) => {
        state.isRequestPending = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isRequestPending = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.isRequestPending = true;
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isRequestPending = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isRequestPending = false;
        state.isLoading = false;
      });
  },
});

export const { loadSession } = authSlice.actions;

export default authSlice.reducer;
