import { Box } from "@mui/material";
import { PeepSearchField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectCategoriesWithRetailProductsByOutletId,
  selectProductCategoriesLoading,
} from "store/selectors";
import { ProductCategoryWithProducts } from "types/ProductCategory";
import { PurchasableItemOption } from "types/PurchasableItem";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItemCategory from "../PurchasableItemCategory";
import ProductsPurchasableItems from "./ProductsPurchasableItems";

const PurchasableItemsProductsPanel = () => {
  const { t } = useTranslation(["checkout", "inventory"]);

  const { outletId, shouldAllowSelectAllCategoryItems, selectedItems, onSelectAllCategoryItems } =
    usePurchasableItemsSelector();

  const [searchQuery, setSearchQuery] = useState("");

  const productCategoriesWithProducts = useSelector(
    selectCategoriesWithRetailProductsByOutletId(outletId || 0)
  );

  const areProductCategoriesLoading = useSelector(selectProductCategoriesLoading);

  const processedQuery = searchQuery.toLowerCase().trim();

  const filteredCategories = productCategoriesWithProducts
    .map(filterCategoryByProductName(processedQuery))
    .filter(filterOutEmptyCategories);

  if (areProductCategoriesLoading) return <CentredSpinnerBox />;

  if (!productCategoriesWithProducts.length)
    return <EmptyGraphicSection description={t("checkout:noProductsAvailable")} />;

  return (
    <Box p={3}>
      <Box pb={3}>
        <PeepSearchField
          placeholder={t("checkout:searchByProductName")}
          onClear={() => setSearchQuery("")}
          fullWidth
          value={searchQuery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>

      {filteredCategories.length ? (
        filteredCategories.map((productCategory) => {
          const totalProductsWithinCategoryCount = productCategory.productsCount;

          const totalSelectedProductsWithinCategoryCount = productCategory.products.filter(
            (productItem) =>
              selectedItems?.[PurchasableItemOption.Product]?.includes(productItem.id)
          ).length;

          return (
            <PurchasableItemCategory
              key={`${productCategory.id}-${productCategory.name}`}
              name={productCategory.name as string}
              totalItemsDescription={`${productCategory.products.length} ${t(
                "inventory:products"
              )}`}
              items={<ProductsPurchasableItems products={productCategory.products} />}
              shouldAllowSelectAllCategoryItems={shouldAllowSelectAllCategoryItems}
              areAllItemsSelected={
                totalProductsWithinCategoryCount === totalSelectedProductsWithinCategoryCount
              }
              handleClickSelectAllCategoryItems={
                shouldAllowSelectAllCategoryItems && onSelectAllCategoryItems
                  ? () => {
                      onSelectAllCategoryItems(
                        productCategory.products,
                        PurchasableItemOption.Product
                      );
                    }
                  : undefined
              }
            />
          );
        })
      ) : (
        <EmptyGraphicSection description={t("noProducts")} />
      )}
    </Box>
  );
};

export default PurchasableItemsProductsPanel;

const filterOutEmptyCategories = (category: ProductCategoryWithProducts) =>
  !!category?.products.length;

const filterByProductNameOrBarcode = (searchQuery: string) => (product: Product) =>
  product.name.toLowerCase().trim().includes(searchQuery) ||
  product.barcode?.toLowerCase().trim().includes(searchQuery) ||
  product.sku?.toLowerCase().trim().includes(searchQuery);

const filterCategoryByProductName =
  (searchQuery: string) => (category: ProductCategoryWithProducts) => {
    if (!searchQuery) return category;

    const filteredProductsByName = category.products.filter(
      filterByProductNameOrBarcode(searchQuery)
    );

    return {
      ...category,
      products: filteredProductsByName,
    };
  };
