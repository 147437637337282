type SlotWithStaff = {
  staff: number;
};

type SlotWithStaffId = {
  staffId: number;
};

// can have either staffId or staff key because it depends on the shape of the article "either backend data or form data"
type Slot = SlotWithStaff | SlotWithStaffId;

type Article = {
  slots: Slot[];
};

// type StaffKeyName can either be the key of SlotWithStaff or SlotWithStaffId
type StaffKeyNameType = keyof SlotWithStaff | keyof SlotWithStaffId;

type getIsStaffAssignedToTheAppointmentProps = {
  articles: Article[];
  authenticatedUserEmployeeId: Nullable<number> | undefined;
  staffKeyName?: StaffKeyNameType;
};

/*
 * use this function with wisdom.. or you'll be sad and you'll be sorry -Anfal "The Tears Saver"
 * This function is used to check if the authenticated user is assigned as a staff to the appointment
 * you need to be aware of the shape of the articles slots "either backend data or form data" to know which staffKeyName to use
 */

const getIsStaffAssignedToTheAppointment = ({
  articles,
  authenticatedUserEmployeeId,
  staffKeyName = "staff",
}: getIsStaffAssignedToTheAppointmentProps) => {
  if (!authenticatedUserEmployeeId) return false;

  return articles.some((article) =>
    article.slots.some((slot) => slot[staffKeyName as keyof Slot] === authenticatedUserEmployeeId)
  );
};
export default getIsStaffAssignedToTheAppointment;
