import { CheckIcon } from "assets/icon";

import CheckoutPaymentMethod from "../AddPaymentMethodsSection/CheckoutPaymentMethod";

type CheckoutRefundAdditionalPaymentMethodProps = {
  title: string;
  isSelected: boolean;
  onClick: () => void;
};

const CheckoutRefundAdditionalPaymentMethod = ({
  title,
  isSelected,
  onClick,
}: CheckoutRefundAdditionalPaymentMethodProps) => {
  return (
    <CheckoutPaymentMethod
      title={title}
      handleClick={onClick}
      isSelected={isSelected}
      endIcon={<CheckIcon />}
      shouldShowApply={false}
    />
  );
};

export default CheckoutRefundAdditionalPaymentMethod;
