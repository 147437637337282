import { SearchableSelectField } from "components/common";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectPaymentTypesByMethod } from "store/paymentType/paymentTypesSelectors";
import { Client } from "types/Client";

type PaymentTypeDropdownProps = {
  client: Client;
};

const PaymentTypeDropdown = ({ client }: PaymentTypeDropdownProps) => {
  const { t } = useTranslation(["deposits", "invoices"]);

  const { hasPeepPay } = useSelector(selectOrganization) as Organization;
  const { hasPeepPaySendAccess } = useSelector(selectAllPermissions);

  const shouldShowPeepPayOption = hasPeepPay && hasPeepPaySendAccess.editAccess;

  const paymentTypes = useSelector(selectPaymentTypesByMethod("forPos"));

  const { formState, setValue } = useFormContext();

  const { errors } = formState;

  const paymentOptions = paymentTypes.map((paymentType) => ({
    value: paymentType.id,
    label: paymentType.label,
  }));

  const peepPayOption = { value: -2, label: t("invoices:peepPay") };
  const walletOption = { value: -1, label: t("deposits:wallet") };

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Nullable<number>>(null);

  //Combine wallet option with payment type option
  const allPaymentMethods = [
    ...(shouldShowPeepPayOption ? [peepPayOption] : []),
    walletOption,
    ...paymentOptions,
  ];

  const resetPeepPay = () => setValue("peepPay", null);

  const resetWallet = () => setValue("wallet", null);

  const resetPaymentType = () => setValue("paymentType", null);

  // Assign wallet to use value -1, if selected assign wallet if not assign payment type
  const handleSelectValue = (value: Nullable<number>) => {
    if (value === -2) {
      setValue("peepPay", {
        phoneNumber: client?.phoneNumber,
        phoneCountryCode: client?.phoneCountryCode,
      });

      resetWallet();
      resetPaymentType();
    } else if (value === -1) {
      setValue("wallet", true);

      resetPaymentType();
      resetPeepPay();
    } else {
      setValue("paymentType", value);

      resetWallet();
      resetPeepPay();
    }

    setSelectedPaymentMethod(value);
  };

  return (
    <SearchableSelectField
      disablePortal={false}
      error={!!errors.paymentType}
      helperText={t(errors?.paymentType?.message)}
      label={t("paymentMethod")}
      fullWidth
      options={allPaymentMethods}
      handleSelectValue={(value: Nullable<number>) => handleSelectValue(value)}
      value={selectedPaymentMethod}
    />
  );
};

export default PaymentTypeDropdown;
