import { Typography, TypographyProps } from "@mui/material";
import { Languages } from "configs/i18next";
import { useTranslation } from "react-i18next";

type InvoiceArticlesHeaderItemProps = {
  titleTranslation: string;
  align: TypographyProps["align"];
};

const InvoiceArticlesHeaderItem = ({ titleTranslation, align }: InvoiceArticlesHeaderItemProps) => {
  const { t } = useTranslation("invoices");

  return (
    <>
      <Typography variant="h4" align={align}>
        {t(titleTranslation, { lng: Languages.english })}
      </Typography>

      <Typography variant="h5" color="text.label" fontWeight={700} align={align} component="p">
        {t(titleTranslation, { lng: Languages.arabic })}
      </Typography>
    </>
  );
};

export default InvoiceArticlesHeaderItem;
