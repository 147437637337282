import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getSubscriptionExpirationDate } from "helpers/getSubscriptionExpirationDate";
import type { RootState } from "store";
import { updateAppointment } from "store/appointments/appointmentsSlice";
import clientAddressesApi from "store/clients/clientAddressesApi";
import { WalletType } from "types/Checkout";
import { Client, ProcessedClient } from "types/Client";
import {
  ProcessedSubscriptionPurchase,
  RedeemableSubscriptionItemOption,
} from "types/Subscription";

import createThunkFromApiWithType from "../utils/createThunkFromApiWithType";
import api from "./clientsApi";
import {
  getSortedSubscriptionsByEarliestExpiration,
  getSubscriptionsByItem,
  processClient,
} from "./utils";

const clientsAdapter = createEntityAdapter<ProcessedClient>();

const initialState = clientsAdapter.getInitialState({
  isClientLoading: false,
  areClientsLoading: false,
  isRequestPending: false,

  optimisticCache: null,

  totalPages: 1,
  count: 0,
  error: "",
});

const { selectById, selectAll } = clientsAdapter.getSelectors((state: RootState) => state.clients);

export const selectAllClients = selectAll;

export const selectClientById = (id: Nullable<number> | undefined) => (state: RootState) =>
  typeof id === "number" ? selectById(state, id) : null;

export const selectClientCount = (state: RootState) => state.clients.count;

export const selectClientTotalPages = (state: RootState) => state.clients.totalPages;

export const selectActiveSubscriptionByClientId =
  (id: Nullable<number> | undefined) => (state: RootState) => {
    const client = selectClientById(id)(state);

    if (!client) return [];

    return client?.subscriptionsData || [];
  };

export const selectActiveSubscriptionByClientIdAndOutletId =
  (clientId: Nullable<number> | undefined, outletId: Nullable<number> | undefined) =>
  (state: RootState): ProcessedSubscriptionPurchase[] => {
    if (!clientId || !outletId) return [];

    const clientSubscriptions = selectActiveSubscriptionByClientId(clientId)(state);

    const activeSubscriptionByClientIdAndOutletId =
      clientSubscriptions.filter((subscription) => subscription.outlets.includes(outletId)) || [];

    const activeSubscriptionsWithExpirationDate = activeSubscriptionByClientIdAndOutletId.map(
      (subscription) => ({
        ...subscription,
        expirationDate: getSubscriptionExpirationDate(
          subscription.createdAt,
          subscription.validityInDays
        ).toISO(),
      })
    );

    return getSortedSubscriptionsByEarliestExpiration(activeSubscriptionsWithExpirationDate);
  };

export const selectActiveSubscriptionByClientIdAndOutletIdAndItemId =
  (
    clientId: Nullable<number> | undefined,
    outletId: number,
    itemId: number,
    itemType: RedeemableSubscriptionItemOption
  ) =>
  (state: RootState) => {
    if (!clientId) return [];

    const clientActiveSubscriptions = selectActiveSubscriptionByClientIdAndOutletId(
      clientId || 0,
      outletId
    )(state);

    const itemSubscriptions = getSubscriptionsByItem(clientActiveSubscriptions, itemId, itemType);

    // filtering is ruining the order of subscriptions by expiration date, we need to sort them again
    return getSortedSubscriptionsByEarliestExpiration(itemSubscriptions);
  };

export const selectIsClientLoading = (state: RootState) => state.clients.isClientLoading;

export const selectAllClientsAreLoading = (state: RootState) => state.clients.areClientsLoading;

export const getAllClients = createThunkFromApiWithType("clients/getAllClients", api.getAllClients);

export const appendAllClients = createThunkFromApiWithType(
  "clients/appendAllClients",
  api.getAllClients
);

export const getClientSearchResults = createThunkFromApiWithType(
  "clients/getClientSearchResults",
  api.getClientSearchResults
);

export const getClient = createThunkFromApiWithType("clients/getClient", api.getClient);

export const createClient = createThunkFromApiWithType("clients/createClient", api.createClient);

export const updateClient = createThunkFromApiWithType("clients/updateClient", api.updateClient);

export const deleteClient = createThunkFromApiWithType("clients/removeClient", api.removeClient);

export const getClientAddresses = createThunkFromApiWithType(
  "clients/getClientAddress",
  clientAddressesApi.getClientAddresses
);

export const updateClientAddress = createThunkFromApiWithType(
  "clients/updateClientAddress",
  clientAddressesApi.updateClientAddress
);

export const createClientAddress = createThunkFromApiWithType(
  "clients/createClientAddress",
  clientAddressesApi.createClientAddress
);

export const deleteClientAddress = createThunkFromApiWithType(
  "clients/deleteClientAddress",
  clientAddressesApi.deleteClientAddress
);

export const updateClientDiscount = createThunkFromApiWithType(
  "clients/discountUpdate",
  api.discountUpdate
);

export const createWalletTransaction = createThunkFromApiWithType(
  "clients/createWalletTransaction",
  api.createWalletTransaction
);

export const getWalletDetails = createThunkFromApiWithType(
  "clients/getWalletDetails",
  api.getWalletDetails
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    upsertClients(state, { payload }) {
      const clientsWithFullName = payload.map(processClient);
      clientsAdapter.upsertMany(state, clientsWithFullName);
    },
  },
  extraReducers: (reducers) => {
    reducers
      .addCase(getAllClients.pending, (state) => {
        state.areClientsLoading = true;
      })

      .addCase(getAllClients.fulfilled, (state, { payload }) => {
        state.areClientsLoading = false;
        state.error = "";

        const clientsWithFullName = payload.results.map(processClient);

        clientsAdapter.setAll(state, clientsWithFullName);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })

      .addCase(getAllClients.rejected, (state, { payload }) => {
        return {
          ...state,
          // @ts-expect-error
          error: payload?.detail,
        };
      })

      .addCase(appendAllClients.pending, (state) => {
        state.areClientsLoading = true;
      })

      .addCase(appendAllClients.fulfilled, (state, { payload }) => {
        state.areClientsLoading = false;
        state.error = "";

        const clientsWithFullName = payload.results.map(processClient);

        clientsAdapter.upsertMany(state, clientsWithFullName);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })

      .addCase(appendAllClients.rejected, (state, { payload }) => {
        return {
          ...state,
          // @ts-expect-error
          error: payload?.detail,
        };
      })

      .addCase(getClientSearchResults.pending, (state) => {
        state.areClientsLoading = true;
      })

      .addCase(getClientSearchResults.fulfilled, (state, { payload }) => {
        state.areClientsLoading = false;

        state.error = "";

        const clientsWithFullName = payload.map((rawClient: Client) => ({
          ...rawClient,
          fullName: `${rawClient.firstName} ${rawClient.lastName}`,
        }));

        clientsAdapter.upsertMany(state, clientsWithFullName);
      })

      .addCase(getClientSearchResults.rejected, (state, { payload }) => {
        state.areClientsLoading = false;

        return {
          ...state,
          // @ts-expect-error
          error: payload?.detail,
        };
      })

      .addCase(getClient.pending, (state) => {
        state.isClientLoading = true;
      })

      .addCase(getClient.fulfilled, (state, { payload }) => {
        state.error = "";

        const client = processClient(payload);
        clientsAdapter.upsertOne(state, client);
        state.isClientLoading = false;
      })

      .addCase(getClient.rejected, (state, { payload }) => {
        state.isClientLoading = false;
        // @ts-expect-error
        state.error = payload?.detail;
      })

      .addCase(createClient.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(createClient.fulfilled, (state, { payload }) => {
        const client = processClient(payload);

        clientsAdapter.addOne(state, client);
        state.isRequestPending = false;
      })

      .addCase(createClient.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(updateClient.pending, (state, { meta: { arg } }) => {
        state.isRequestPending = true;
        const { id, data } = arg;
        // @ts-expect-error
        state.optimisticCache = selectClientById(id)({ clients: state });

        clientsAdapter.updateOne(state, {
          id,
          changes: data,
        });
      })

      .addCase(updateClient.fulfilled, (state) => {
        state.isRequestPending = false;
        state.optimisticCache = null;
      })

      .addCase(updateClient.rejected, (state) => {
        // @ts-expect-error
        clientsAdapter.setOne(state, state.optimisticCache);
        state.isRequestPending = false;
        state.optimisticCache = null;
      })

      .addCase(deleteClient.fulfilled, (state, { payload: id }) => {
        clientsAdapter.removeOne(state, id);
      })

      .addCase(updateAppointment.fulfilled, (state, { payload }) => {
        if (payload.client) {
          const clientToUpdate = state.entities[payload.client];
          // @ts-expect-error
          clientsAdapter.upsertOne(state, {
            id: payload.client,
            changes: {
              ...clientToUpdate,
            },
          });
        }
      })

      // Update Client Special Discount
      .addCase(updateClientDiscount.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(updateClientDiscount.fulfilled, (state, { meta: { arg } }) => {
        // @ts-expect-error
        clientsAdapter.upsertOne(state, {
          id: Number(arg.id),
          discountAmount: arg.data.discountAmount,
        });
        state.isRequestPending = false;
      })

      .addCase(updateClientDiscount.rejected, (state) => {
        state.isRequestPending = false;
      })

      // Deduct Wallet Transaction
      .addCase(createWalletTransaction.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(createWalletTransaction.fulfilled, (state, { meta: { arg } }) => {
        const { id, data, online } = arg;

        const clientToUpdate = clientsAdapter.getSelectors().selectById(state, id!);

        if (!clientToUpdate) return;

        let newUpdatedClient = {};

        if (online) {
          newUpdatedClient = {
            id: Number(id),
            onlineWallet: {
              id: data.wallet,
              amount: clientToUpdate.onlineWallet.amount - data.amount,
            },
          };
        } else {
          newUpdatedClient = {
            id: Number(id),
            offlineWallet: {
              id: data.wallet,
              amount:
                data.action === "TOPUP"
                  ? clientToUpdate.offlineWallet.amount + data.amount
                  : clientToUpdate.offlineWallet.amount - data.amount,
            },
          };
        }

        clientsAdapter.updateOne(state, {
          id: Number(id),
          changes: {
            ...newUpdatedClient,
          },
        });

        state.isRequestPending = false;
      })

      .addCase(createWalletTransaction.rejected, (state) => {
        state.isRequestPending = false;
      })

      // Get Wallet History Details
      .addCase(getWalletDetails.pending, (state) => {
        state.isRequestPending = false;
      })

      .addCase(getWalletDetails.fulfilled, (state, { payload, meta: { arg } }) => {
        let newUpdatedClient = {};

        if (payload.walletType === WalletType.Online) {
          newUpdatedClient = {
            id: Number(arg.clientId),
            onlineWallet: {
              ...payload,
              amount: payload.credit,
            },
          };
        } else {
          newUpdatedClient = {
            id: Number(arg.clientId),
            offlineWallet: {
              ...payload,
              amount: payload.credit,
            },
          };
        }

        // @ts-expect-error
        clientsAdapter.upsertOne(state, {
          ...newUpdatedClient,
        });

        state.isRequestPending = false;
      })

      .addCase(getWalletDetails.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(getClientAddresses.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(getClientAddresses.fulfilled, (state, { payload, meta: { arg } }) => {
        // @ts-expect-error
        clientsAdapter.upsertOne(state, {
          id: arg.clientId,
          addresses: payload,
        });
        state.isRequestPending = false;
      })

      .addCase(getClientAddresses.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(updateClientAddress.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(updateClientAddress.fulfilled, (state, { payload, meta: { arg } }) => {
        const { client: clientId } = arg;
        const clientToUpdate = clientsAdapter.getSelectors().selectById(state, clientId!);

        clientsAdapter.updateOne(state, {
          id: clientId!,
          changes: {
            addresses: [...(clientToUpdate?.addresses || [])].map((address) =>
              address.id === payload.id ? payload : address
            ),
          },
        });

        state.isRequestPending = false;
      })

      .addCase(updateClientAddress.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(createClientAddress.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(createClientAddress.fulfilled, (state, { payload, meta: { arg } }) => {
        const { client: clientId } = arg;
        const clientToUpdate = clientsAdapter.getSelectors().selectById(state, clientId!);

        clientsAdapter.updateOne(state, {
          id: clientId!,
          changes: {
            addresses: [payload, ...(clientToUpdate?.addresses ?? [])],
          },
        });
        state.isRequestPending = false;
      })

      .addCase(createClientAddress.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(deleteClientAddress.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(deleteClientAddress.fulfilled, (state, { meta: { arg } }) => {
        const { client: clientId, id: deletedAddressId } = arg;
        const clientToUpdate = clientsAdapter.getSelectors().selectById(state, clientId!);

        if (!clientToUpdate) return;

        const clientAddressesWithoutTheDeletedAddress = [
          ...(clientToUpdate?.addresses ?? []),
        ].filter((address) => address.id !== deletedAddressId);

        clientsAdapter.updateOne(state, {
          id: clientToUpdate.id,
          changes: {
            ...clientToUpdate,
            addresses: clientAddressesWithoutTheDeletedAddress,
          },
        });

        state.isRequestPending = false;
      })

      .addCase(deleteClientAddress.rejected, (state) => {
        state.isRequestPending = false;
      });
  },
});

export const { upsertClients } = clientsSlice.actions;
export default clientsSlice.reducer;
