import {
  checkIfArticleHasSpecialDiscount,
  getArticleSpecialDiscountPercentage,
} from "helpers/articles";
import getFormattedDuration from "helpers/getFormattedDuration";
import getServiceSubtitle from "helpers/getServiceSubtitle";
import { getSlotDurationInMinutes } from "helpers/getSlotDurationInMinutes";
import {
  AppointmentArticleSlot,
  AppointmentArticleWithDetails,
  DiscountCode,
} from "types/Appointment";
import { InvoiceStatus } from "types/Checkout";
import { PurchasableItemOption } from "types/PurchasableItem";
import { Resource } from "types/Resource";

import type { CheckoutArticle, CheckoutCartFormInputs } from "./CheckoutFormContext";

type CheckoutFormArticle = CheckoutCartFormInputs["articles"][number];
type CheckoutFormPayment = CheckoutCartFormInputs["payments"][number];

/**
 * Validate that the value is a valid number before calculating the total
 * @param total
 * @param value
 * @returns
 */

const getValidatedTotal = (total: number, value: number) => {
  if (isNaN(value) || !isFinite(value))
    throw new Error(`Invalid value ${value}, it should be a valid number`);

  return total + value;
};

/**
 * This function should be called in a articles.reducer() function to calculate the total price of items during checkout.
 * @param currentTotal
 * @param article
 * @returns total price of items
 */

export const callGetCheckoutTotalPrice = (
  currentTotal: number,
  article: CheckoutFormArticle
): number => {
  return getValidatedTotal(currentTotal, Number(article.finalPrice * (article.quantity || 1)));
};

/**
 * This function should be called in a articles.reducer() function to calculate the total marked price of items during checkout.
 * @param currentTotal
 * @param article
 * @returns
 */

export const callGetCheckoutTotalMarkedPrice = (
  currentTotal: number,
  article: CheckoutFormArticle
): number => {
  // If you have a special discount just like app price, use the original price
  if (
    checkIfArticleHasSpecialDiscount({
      markedPrice: article.markedPrice || 0,
      originalPrice: article.originalPrice,
      finalPrice: article.finalPrice,
    })
  ) {
    return getValidatedTotal(currentTotal, Number(article.originalPrice * (article.quantity || 1)));
  }

  return getValidatedTotal(currentTotal, Number(article.markedPrice * (article.quantity || 1)));
};

/**
 * This function should be called in a payments.reducer() function to calculate the total payments during checkout.
 * @param currentTotal
 * @param payment
 * @returns total balance
 */

export const callGetCheckoutTotalBalanceAmount = (
  currentTotal: number,
  payment: CheckoutFormPayment
): number => {
  return getValidatedTotal(currentTotal, payment.amount);
};

/**
 * This function returns the status of the checkout
 * @param totalDue
 * @param balance
 * @returns  checkout status
 */

export const getCheckoutStatus = (totalDue: number, balance: number): InvoiceStatus => {
  if (balance === 0) return InvoiceStatus.Paid;
  else if (totalDue > balance && balance > 0) return InvoiceStatus.Partially;
  else if (totalDue === 0 && balance === 0) return InvoiceStatus.Free;
  else if (totalDue === balance) return InvoiceStatus.Unpaid;
  else return InvoiceStatus.Paid;
};

/**
 * This function takes an appointment article and converts it to a checkout article
 * @param article
 * @returns checkout article
 */
export const translateAppointmentArticleToCheckoutArticle = (
  article: AppointmentArticleWithDetails,
  activeResources: Resource[],
  appliedDiscountCode: Nullable<DiscountCode> = null
): CheckoutArticle => {
  const title = article.serviceVariant
    ? article.serviceVariant?.service.name
    : article.packageVariant?.name || "";

  const subtitle = article.serviceVariant
    ? getServiceSubtitle(
        article.serviceVariant?.service.name,
        article.serviceVariant.name,
        article.serviceVariant.duration
      )
    : `${getFormattedDuration(article.packageVariant?.totalDuration || 0)}`;

  const purchasedItem = article.serviceVariant
    ? {
        serviceVariant: article.serviceVariant?.id,
      }
    : { packageVariant: article.packageVariant?.id };

  const getInitialResourceId = (slot: AppointmentArticleSlot) => {
    //  We need to clear the resource it is  deprecated or is not within the current outlet, Note that I had to use undefined because of the way we set whether the resource is required or not on the checkout form, undefined for required unset resource, whereas null for not needing a resource
    if (slot.resource) {
      return !!activeResources?.find((activeResource) => activeResource.id === slot.resource)
        ? slot.resource
        : undefined;
    }

    return null;
  };

  const getDiscountValue = () => {
    if (appliedDiscountCode) {
      const discountPercentage =
        ((Number(article.markedPrice) - Number(article.finalPrice)) / Number(article.markedPrice)) *
        100;

      const formattedDiscountValue = Number(discountPercentage.toFixed(2));
      return formattedDiscountValue;
    } else if (article.rewardLoyaltyCard) {
      const discountPercentage =
        ((Number(article.markedPrice) - Number(article.finalPrice)) / Number(article.markedPrice)) *
        100;

      return Number(discountPercentage.toFixed(2));
    } else if (
      // Example: app price discount
      checkIfArticleHasSpecialDiscount({
        markedPrice: article.markedPrice || 0,
        originalPrice: article.originalPrice,
        finalPrice: article.finalPrice,
      })
    ) {
      return getArticleSpecialDiscountPercentage({
        finalPrice: article.finalPrice,
        originalPrice: article.originalPrice,
      });
    } else {
      return 0;
    }
  };

  return {
    title,
    subtitle,
    purchasedItem,
    quantity: 1,
    purchasableItemType: article.serviceVariant
      ? PurchasableItemOption.Service
      : PurchasableItemOption.Package,

    finalPrice: Number(article.finalPrice),
    originalPrice: Number(article.originalPrice),
    markedPrice: Number(article.markedPrice),
    discountValue: getDiscountValue(),

    appointmentArticleId: article.id,
    promotionId: article.promotion,
    appointmentArticlePromotionId: article.promotion,
    subscriptionPurchaseId: article.subscriptionRedemption
      ? article.subscriptionRedemption.subscriptionPurchaseId
      : null,
    subscriptionRedemptionId: article.subscriptionRedemption
      ? article.subscriptionRedemption.id
      : null,

    rewardLoyaltyCard: article.rewardLoyaltyCard,

    details: article.slots.map((slot) => ({
      purchasedItem: {
        product: null,
        serviceVariant: slot.serviceVariant,
      },
      saleTime: slot.start,
      duration: getSlotDurationInMinutes(slot),
      saleEmployee: slot.staff,

      resource: getInitialResourceId(slot),
    })),
  };
};
