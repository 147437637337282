import http from "utils/http";

const packageVariantsApi = {
  getAllPackageVariants: async () => http.get("packages/variants/"),

  getPackageVariant: async (id) => http.get(`packages/variants/${id}/`),

  createPackageVariant: async (data) => http.post("packages/variants/", data),

  updatePackageVariant: async ({ id, data }) => {
    return http.put(`packages/variants/${id}/`, data);
  },

  removePackageVariant: async (id) => http.delete(`packages/variants/${id}/`),
};

export default packageVariantsApi;
