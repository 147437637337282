import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import { DateAdapter, theme } from "configs";
import { Settings } from "luxon";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import AppSkeleton from "./App.skeleton";
import { NotificationAlertContextProvider } from "./providers/notification";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

Settings.defaultZone = "Asia/Kuwait";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<AppSkeleton />}>
            <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
              <BrowserRouter>
                <NotificationAlertContextProvider>
                  <App />
                </NotificationAlertContextProvider>
              </BrowserRouter>
            </ErrorBoundary>
          </Suspense>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
