import { AddPlus } from "@bookpeep/ui";
import {
  Box,
  Button,
  StepLabel as MuiStepLabel,
  Step,
  StepContent,
  Stepper,
  TextField,
  styled,
} from "@mui/material";
import { ServicesIcon } from "assets/icon";
import OnlineBookingChip from "components/common/Chips/OnlineBookingChip";
import getIsTheOnlyStaffAssignedToTheAppointment from "helpers/getIsTheOnlyStaffAssignedToTheAppointment";
import AppointmentDetailsToolbar from "modals/AppointmentDrawer/AppointmentDetails/AppointmentDetailsToolbar";
import { useContext } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAppointmentById } from "store/appointments/appointmentsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import auth from "utils/auth";

import AppointmentFormContext from "../AppointmentFormContext";
import HomeServiceAppointmentFormSection from "../HomeServicesAppointmentForm/HomeServiceAppointmentFormSection";
import { createNewFormArticle } from "../utils";
import ArticleItem from "./ArticleItem";
import DatePickerButton from "./DatePickerButton";
import FlexServiceChip from "./FlexServiceChip";

const ServiceSection = () => {
  const { t } = useTranslation("appointments");

  const { register, watch, setValue } = useFormContext();
  const { closeAndResetForm } = useContext(AppointmentFormContext);
  const { appointmentId } = watch();

  const appointment = useSelector(selectAppointmentById(appointmentId));

  const isEditingAppointment = !!useWatch({ name: "appointmentId" });

  const { fields, append } = useFieldArray({ name: "articles" });

  const allArticles = useWatch({ name: "articles" });

  const createdOnline = useWatch({ name: "createdOnline" });

  const { hasPersonalAppointment, hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const isTheOnlyStaffAssignedToTheAppointment = getIsTheOnlyStaffAssignedToTheAppointment({
    articles: allArticles,
    authenticatedUserEmployeeId,
    staffKeyName: "staffId",
  });

  const canEditAppointmentDate =
    hasNonPersonalAppointment.editAccess ||
    (isTheOnlyStaffAssignedToTheAppointment && hasPersonalAppointment.editAccess);

  const hasOnlyOneArticle = allArticles.length === 1;

  const shouldShowStepper = !hasOnlyOneArticle;

  const shouldDisableEditingDate = isEditingAppointment && !canEditAppointmentDate;

  const canAssignSelfOnly = !hasNonPersonalAppointment.editAccess && !!authenticatedUserEmployeeId;

  const addNewArticle = () => {
    const lastArticleSlots = allArticles[allArticles.length - 1].slots;
    const endTime = lastArticleSlots[lastArticleSlots.length - 1].endTime;

    const staffId = canAssignSelfOnly ? authenticatedUserEmployeeId : null;

    append(createNewFormArticle({ startTime: endTime, staffId }));
  };

  const handleChangeStatus = (status) => {
    setValue("status", status);
  };

  const handleCloseDrawer = () => {
    closeAndResetForm();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
      {isEditingAppointment && (
        <Box pb={2} width="100%">
          <AppointmentDetailsToolbar
            appointmentId={appointmentId}
            shouldShowEditButton={false}
            handleChangeStatus={handleChangeStatus}
            handleAfterStatusUpdate={handleCloseDrawer}
            shouldShowAllSelectableStatuses
          />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        {!createdOnline && (
          <HomeServiceAppointmentFormSection
            title={t("services")}
            icon={<ServicesIcon viewBox="0 0 16 16" />}
            containerProps={{
              px: 0,
            }}
          />
        )}

        <DatePickerButton disabled={shouldDisableEditingDate} />

        {createdOnline && <OnlineBookingChip source={appointment?.source} />}
      </Box>

      <Box
        py={1}
        overflow="scroll"
        sx={{ "&>:not(:last-child)": { marginBottom: 3.25 } }}
        width="100%"
      >
        <Stepper orientation="vertical" nonLinear>
          {allArticles.map((article, articleIndex) => (
            <Step key={articleIndex} expanded orientation="vertical">
              {shouldShowStepper ? (
                <StepLabel sx={{ p: 0 }}>{!!article.flexService && <FlexServiceChip />}</StepLabel>
              ) : (
                !!article.flexService && (
                  <Box pl={4}>
                    <FlexServiceChip />
                  </Box>
                )
              )}
              <StepContent
                sx={{
                  pt: article.flexService ? 3 : 0,
                }}
              >
                <ArticleItem
                  // The key must be the uniquely generated id for the field by useFieldArray hook or else it will have behavior issues. Don't mess with this.. #please - Anfal
                  key={fields?.[articleIndex]?.id || articleIndex}
                  article={article}
                  articleIndex={articleIndex}
                  articleCount={allArticles.length}
                  addNewArticle={addNewArticle}
                />
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <AddServiceButton
          variant="text"
          color="primary"
          startIcon={<AddPlus />}
          onClick={addNewArticle}
        >
          {t("addService")}
        </AddServiceButton>
      </Box>

      <Box width="100%" px={3} pt={1}>
        <TextField
          label={t("appointments:appointmentNotes")}
          {...register("notes")}
          placeholder={t("appointments:notesPlaceholder")}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default ServiceSection;

const AddServiceButton = styled(Button)(() => ({
  fontWeight: 700,
}));

const StepLabel = styled(MuiStepLabel)(({ theme }) => ({
  "& .MuiStepIcon-root": {
    fill: "transparent",
    border: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
  },

  "& .MuiStepIcon-text": {
    fill: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "0.75rem",
  },
}));
