import { Button } from "@mui/material";
import { ACTIVE_INVOICE_STATUSES } from "constants/invoice";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";

type ViewInvoiceButtonProps = {
  appointmentId: number;
};

const ViewInvoiceButton = ({ appointmentId }: ViewInvoiceButtonProps) => {
  const { t } = useTranslation("appointments");

  const { closeAppointmentDrawer } = useAppointmentDrawer();
  const { openCheckoutModalWithInvoiceDetailsMode } = useCheckoutModal();

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));

  const handleViewInvoice = useCallback(() => {
    const activeInvoices = appointment?.invoices?.filter((invoice) =>
      ACTIVE_INVOICE_STATUSES.includes(invoice.status)
    );

    if (activeInvoices?.length) {
      openCheckoutModalWithInvoiceDetailsMode({
        invoiceId: activeInvoices[0].id,
      });
      closeAppointmentDrawer();
    }
  }, [appointment?.invoices, closeAppointmentDrawer, openCheckoutModalWithInvoiceDetailsMode]);

  return (
    <Button
      disableRipple
      disableTouchRipple
      fullWidth
      variant="text"
      color="primary"
      onClick={handleViewInvoice}
      sx={{
        minWidth: 0,
        paddingRight: 0,
        textDecoration: "underline",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      {t("viewInvoice")}
    </Button>
  );
};

export default ViewInvoiceButton;
