import { AppointmentLog } from "types/Appointment";
import { AppointmentDeposit, PeepPayDepositArguments } from "types/Deposit";
import http from "utils/http";

export enum DepositRemovalOptions {
  REFUND = "REFUND",
  NEGLECT = "NEGLECT",
}

export type CreateDepositArguments = {
  appointment: number;
  paymentType: number;
  peepPay: PeepPayDepositArguments;
  wallet: boolean;
  amount: number;
};

type UpdateDepositArgument = {
  option: string;
};

type CreateDepositResponse = {
  deposit: AppointmentDeposit;
  logs: AppointmentLog[];
};

type UpdateDepositResponse = Omit<CreateDepositResponse, "deposit">;

const depositsApi = {
  createDeposit: async ({ data }: { data: CreateDepositArguments }) =>
    http.post<CreateDepositResponse>(`deposits/deposits/`, data),

  updateDeposit: async ({ id, data }: { id: number; data: UpdateDepositArgument }) =>
    http.put<UpdateDepositResponse>(`deposits/deposits/${id}/`, data),
};

export default depositsApi;
