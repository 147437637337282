import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum AppointmentDrawerMode {
  CreateAppointment = "CreateAppointment",
  EditAppointment = "EditAppointment",
  ViewAppointment = "ViewAppointment",
}

export enum HomeServicesAppointmentStep {
  Client = "Client",
  Area = "Area",
  Services = "Services",
  Address = "Address",
}

type AppointmentDrawerState = {
  isDrawerOpen: boolean;
  isDrawerExpanded: boolean;

  appointmentId: Nullable<number>;

  outletId: Nullable<number>;

  clientId: Nullable<number>;

  currentView: Nullable<AppointmentDrawerMode>;

  activeHomeServiceFormStep: Nullable<HomeServicesAppointmentStep>;
};

const initialState: AppointmentDrawerState = {
  isDrawerOpen: false,
  isDrawerExpanded: false,

  appointmentId: null,

  outletId: null,

  clientId: null,

  currentView: null,

  activeHomeServiceFormStep: null,
};

export type OpenAppointmentDrawerViewModeOptions = {
  appointmentId: number;

  outletId?: AppointmentDrawerState["outletId"];
};

export type OpenAppointmentDrawerCreateModeOptions = {
  clientId?: AppointmentDrawerState["clientId"];

  outletId: number;
};

export type OpenAppointmentDrawerEditModeOptions = {
  appointmentId: number;

  outletId: number;

  clientId: AppointmentDrawerState["clientId"];

  homeServiceFormStep?: HomeServicesAppointmentStep;
};

export type UpdateAppointmentDrawerClientPayload = Nullable<number>;

const appointmentDrawerSlice = createSlice({
  name: "appointmentDrawer",
  initialState,

  reducers: {
    initiateAppointmentDrawerViewMode: (
      state,
      { payload }: PayloadAction<OpenAppointmentDrawerViewModeOptions>
    ) => {
      const { appointmentId, outletId = null } = payload;

      state.appointmentId = appointmentId;

      state.outletId = outletId;
      state.currentView = AppointmentDrawerMode.ViewAppointment;

      state.isDrawerOpen = true;
    },

    initiateAppointmentDrawerCreateMode: (
      state,
      { payload }: PayloadAction<OpenAppointmentDrawerCreateModeOptions>
    ) => {
      const { outletId, clientId } = payload;

      state.currentView = AppointmentDrawerMode.CreateAppointment;
      state.outletId = outletId;

      if (clientId) state.clientId = clientId;

      state.isDrawerOpen = true;

      state.activeHomeServiceFormStep = HomeServicesAppointmentStep.Client;
    },

    initiateAppointmentDrawerEditMode: (
      state,
      { payload }: PayloadAction<OpenAppointmentDrawerEditModeOptions>
    ) => {
      const { appointmentId, outletId } = payload;

      state.currentView = AppointmentDrawerMode.EditAppointment;
      state.appointmentId = appointmentId;
      state.outletId = outletId;
      state.isDrawerOpen = true;

      state.activeHomeServiceFormStep =
        payload.homeServiceFormStep || HomeServicesAppointmentStep.Services;
    },

    updateAppointmentDrawerClient: (
      state,
      { payload }: PayloadAction<UpdateAppointmentDrawerClientPayload>
    ) => {
      state.clientId = payload;
    },

    updateIsDrawerExpanded: (state, { payload }: PayloadAction<boolean>) => {
      state.isDrawerExpanded = payload;
    },

    terminateAppointmentDrawer: () => {
      return initialState;
    },

    updateAppointmentDrawerOutlet: (state, { payload }: PayloadAction<Nullable<number>>) => {
      state.outletId = payload;
    },
  },
});

export const {
  initiateAppointmentDrawerViewMode,
  initiateAppointmentDrawerCreateMode,
  initiateAppointmentDrawerEditMode,
  terminateAppointmentDrawer,

  updateAppointmentDrawerClient,
  updateAppointmentDrawerOutlet,
  updateIsDrawerExpanded,
} = appointmentDrawerSlice.actions;

export default appointmentDrawerSlice.reducer;
