import { getSubscriptionExpirationDate } from "helpers/getSubscriptionExpirationDate";
import { callSortAscendingByKey } from "helpers/sort";
import { Client, ProcessedClient } from "types/Client";
import {
  ClientSubscriptionPurchase,
  ProcessedSubscriptionPurchase,
  RedeemableSubscriptionItemOption,
} from "types/Subscription";

/**
 * This function should process client data by injecting fullName and subscriptionsData with expirationDate
 * @param rawClient
 * @returns ProcessedClient
 */

export const processClient = (rawClient: Client): ProcessedClient => {
  const clientData = {
    fullName: `${rawClient.firstName} ${rawClient.lastName}`,
    ...rawClient,
  };

  // we need this because the client search result endpoint doesn't return subscriptionsData and we don't want to erase the existing subscriptionsData in the store, when the order if requests is possessed
  return {
    ...clientData,
    subscriptionsData: clientData.subscriptionsData
      ? getProcessedSubscriptionsWithExpirationDate(clientData.subscriptionsData)
      : [],
  };
};

/**
 * This function should inject expirationDate to ClientSubscriptionPurchase
 * and it returns SortedSubscriptionsByEarliestExpiration
 *
 * @param subscriptionsData array
 * @returns SortedSubscriptionsByEarliestExpiration with expirationDate injected
 */

export const getProcessedSubscriptionsWithExpirationDate = (
  subscriptionsData: ClientSubscriptionPurchase[]
): ProcessedSubscriptionPurchase[] => {
  if (!subscriptionsData?.length) return [];

  const subscriptionsDataWithExpirationDate = subscriptionsData?.map((subscription) => ({
    ...subscription,

    // convert expirationDate to ISO string to avoid having un-serializable date object console error
    expirationDate: getSubscriptionExpirationDate(
      subscription.createdAt,
      subscription.validityInDays
    ).toISO(),
  }));

  const sortedSubscriptionsByEarliestExpiration = getSortedSubscriptionsByEarliestExpiration(
    subscriptionsDataWithExpirationDate
  );

  return sortedSubscriptionsByEarliestExpiration;
};

/**
 * This function should sort subscriptions by earliest expiration date (ascending order)
 * @param subscriptionsDataWithExpirationDate
 * @returns
 */

export const getSortedSubscriptionsByEarliestExpiration = (
  subscriptionsDataWithExpirationDate: ProcessedSubscriptionPurchase[]
) => {
  const sortedSubscriptionsByEarliestExpiration = subscriptionsDataWithExpirationDate.sort(
    callSortAscendingByKey("expirationDate")
  );

  return sortedSubscriptionsByEarliestExpiration;
};

/**
 * This function should filter subscriptions by item id and item type
 * @param activeSubscriptions
 * @param itemId
 * @param itemType
 * @returns filteredSubscriptionsByItem
 */

export const getSubscriptionsByItem = (
  activeSubscriptions: ProcessedSubscriptionPurchase[],
  itemId: number,
  itemType: RedeemableSubscriptionItemOption
) => {
  let filteredSubscriptionsByItem;

  if (itemType === RedeemableSubscriptionItemOption.PackageVariant) {
    filteredSubscriptionsByItem = activeSubscriptions.filter(
      (subscription) => subscription[itemType] === itemId
    );
  } else {
    filteredSubscriptionsByItem = activeSubscriptions.filter((subscription) => {
      if (subscription[itemType]) {
        return subscription[itemType].includes(itemId);
      }
      return false;
    });
  }

  return filteredSubscriptionsByItem;
};
