import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";

import {
  appendAllProductsData,
  createProduct,
  createStockDown,
  createStockTransfer,
  createStockUp,
  getAllProductsData,
  getProductData,
  updateProduct,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";
import api, { SupplierListResponse } from "./suppliersApi";

export const suppliersAdapter = createEntityAdapter<Supplier>({
  selectId: (supplier) => supplier.id,
});

const initialState = suppliersAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: SupplierListResponse["totalPages"];
  page: number[];
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  page: [],
});

export const deleteSupplier = createThunkFromApiWithType(
  "suppliers/removeSupplier",
  api.removeSupplier
);

export const createSupplier = createThunkFromApiWithType(
  "suppliers/createSupplier",
  api.createSupplier
);

export const updateSupplier = createThunkFromApiWithType(
  "suppliers/updateSupplier",
  api.updateSupplier
);

export const getSuppliersBasedOnCurrentPage = createThunkFromApiWithType(
  "suppliers/getSuppliers",
  api.getSuppliers
);

export const getAllSuppliers = createThunkFromApiWithType(
  "suppliers/getAllSuppliers",
  api.getAllSuppliers
);

export const getSuppliersForInfiniteLoading = createThunkFromApiWithType(
  "suppliers/getSuppliersForInfiniteLoading",
  api.getSuppliers
);

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getProductData.fulfilled, (state, { payload: { suppliers } }) => {
      suppliersAdapter.upsertMany(state, suppliers);
    });

    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { suppliers } = payload;
      suppliersAdapter.upsertMany(state, suppliers);
    });

    reducers.addCase(getAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductsData.fulfilled, (state, { payload }) => {
      suppliersAdapter.upsertMany(state, payload.results.suppliers);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductsData.fulfilled, (state, { payload }) => {
      suppliersAdapter.upsertMany(state, payload.results.suppliers);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createSupplier.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createSupplier.fulfilled, (state, { payload }) => {
      const { supplier } = payload;

      suppliersAdapter.upsertOne(state, supplier);
      state.page = [supplier.id, ...state.page];

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createSupplier.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateSupplier.fulfilled, (state, { payload }) => {
      const { supplier } = payload;

      suppliersAdapter.upsertOne(state, supplier);
    });

    reducers.addCase(getSuppliersForInfiniteLoading.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getSuppliersForInfiniteLoading.fulfilled, (state, { payload }) => {
      suppliersAdapter.upsertMany(state, payload.results.suppliers);
      state.page = [...state.page, ...payload.results.suppliers.map((supplier) => supplier.id)];

      state.totalPages = payload.totalPages;
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSuppliersForInfiniteLoading.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(getSuppliersBasedOnCurrentPage.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getSuppliersBasedOnCurrentPage.fulfilled, (state, { payload }) => {
      suppliersAdapter.upsertMany(state, payload.results.suppliers);
      state.page = payload.results.suppliers.map((supplier) => supplier.id);

      state.totalPages = payload.totalPages;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getSuppliersBasedOnCurrentPage.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(getAllSuppliers.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllSuppliers.fulfilled, (state, { payload }) => {
      suppliersAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllSuppliers.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProduct.fulfilled, (state, { payload: { suppliers } }) => {
      suppliersAdapter.upsertMany(state, suppliers);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(deleteSupplier.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteSupplier.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        suppliersAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteSupplier.rejected, (state) => ({
      ...state,
      status: SliceStatus.FAILED,
    }));

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { suppliers } }) => {
      suppliersAdapter.upsertMany(state, suppliers);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { suppliers } }) => {
      suppliersAdapter.upsertMany(state, suppliers);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { suppliers } }) => {
      suppliersAdapter.upsertMany(state, suppliers);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default suppliersSlice.reducer;
