import { OutletOnlineBookingAcceptanceStatus } from "types/Outlet";
import http from "utils/http";

type UpdateOnlineBookingsStatusArguments = Pick<
  OutletOnlineBookingAcceptanceStatus,
  "isAcceptingOnlineBookings"
> & {
  outletId: number;
};

const onlineBookingStatusApi = {
  getOnlineBookingsStatus: async () =>
    http.get<OutletOnlineBookingAcceptanceStatus[]>(`organization_settings/online_booking_status/`),

  updateOnlineBookingsStatus: async ({
    outletId,
    isAcceptingOnlineBookings,
  }: UpdateOnlineBookingsStatusArguments) =>
    http.put<OutletOnlineBookingAcceptanceStatus>(
      `organization_settings/online_booking_status/${outletId}/`,
      { isAcceptingOnlineBookings }
    ),
};

export default onlineBookingStatusApi;
