import AppointmentStatusSelect from "components/AppointmentStatusSelect";
import { AppointmentStatusOptions } from "types/Appointment";

import AppointmentDetailsStatusChip from "./AppointmentDetailsStatusChip";

type AppointmentStatusProps = {
  appointmentStatus: AppointmentStatusOptions;
  shouldDisableStatusSelect: boolean;
  handleChangeStatus: (status: AppointmentStatusOptions) => void;
  shouldShowAllSelectableStatuses?: boolean;
};

const AppointmentStatus = ({
  appointmentStatus,
  shouldDisableStatusSelect,
  handleChangeStatus,
  shouldShowAllSelectableStatuses = false,
}: AppointmentStatusProps) => {
  const shouldShowAppointmentStatusSelect =
    shouldShowAllSelectableStatuses ||
    (appointmentStatus !== AppointmentStatusOptions.Completed &&
      appointmentStatus !== AppointmentStatusOptions.Canceled &&
      appointmentStatus !== AppointmentStatusOptions.NoShow);

  return (
    <>
      {shouldShowAppointmentStatusSelect ? (
        <AppointmentStatusSelect
          value={appointmentStatus}
          onChange={handleChangeStatus}
          disabled={shouldDisableStatusSelect}
        />
      ) : (
        <AppointmentDetailsStatusChip appointmentStatus={appointmentStatus} />
      )}
    </>
  );
};

export default AppointmentStatus;
