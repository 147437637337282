import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./outletsApi";

const outletAdapter = createEntityAdapter();

const initialState = outletAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll, selectIds } = outletAdapter.getSelectors((state) => state.outlets);

export const selectAllOutlets = selectAll;

export const selectAllOutletIds = selectIds;

export const selectAllActiveOutletsIds = createSelector(selectAll, (allOutlets) =>
  allOutlets.filter((outlet) => !outlet.deprecated).map((outlet) => outlet.id)
);

export const selectAllActiveOutlets = createSelector(selectAll, (allOutlets) =>
  allOutlets.filter((outlet) => !outlet.deprecated)
);

export const selectFirstActiveOutlet = createSelector(
  selectAllActiveOutlets,
  (activeOutlets) => activeOutlets[0]
);

export const selectOutletById = (id) => (state) => selectById(state, id);

export const selectSecondaryImagesByOutletId = (id) => (state) => selectById(state, id).images;

export const selectOutletLoading = (state) => state.outlets.isLoading;

export const getAllOutlets = createThunkFromApi("outlets/getAllOutlets", api.getAllOutlets);

export const getOutlet = createThunkFromApi("outlets/getOutlet", api.getOutlet);

export const createOutlet = createAsyncThunk(
  "outlets/createOutlet",
  async (createOutletArgs, { rejectWithValue }) => {
    try {
      const { submittedData, handleClose } = createOutletArgs;
      const response = await api.createOutlet(submittedData);

      handleClose();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateOutlet = createAsyncThunk(
  "outlets/updateOutlet",
  async (updateOutletArgs, { rejectWithValue }) => {
    try {
      const { id, submittedData, handleClose } = updateOutletArgs;
      const response = await api.updateOutlet(id, submittedData);

      handleClose && handleClose();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteOutlet = createAsyncThunk(
  "outlets/removeOutlet",
  async (deleteOutletArgs, { rejectWithValue }) => {
    try {
      const { id, handleCloseDelete } = deleteOutletArgs;
      await api.removeOutlet(id);

      handleCloseDelete();
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const createOutletSecondaryImage = createThunkFromApi(
  "outlets/createOutletSecondaryImage",
  api.createSecondaryImage
);

export const removeOutletSecondaryImage = createThunkFromApi(
  "outlets/removeOutletSecondaryImage",
  api.removeSecondaryImage
);

export const outletsSlice = createSlice({
  name: "outlets",
  initialState,
  extraReducers: {
    [getAllOutlets.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllOutlets.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      outletAdapter.setAll(state, payload);
    },

    [getAllOutlets.rejected]: (state, { payload }) => {
      state.isLoading = false;
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getOutlet.fulfilled]: (state, { payload }) => {
      outletAdapter.setOne(state, payload);
    },

    [createOutlet.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createOutlet.fulfilled]: (state, { payload }) => {
      outletAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createOutlet.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updateOutlet.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updateOutlet.fulfilled]: (state, { payload }) => {
      outletAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updateOutlet.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [deleteOutlet.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deleteOutlet.fulfilled]: (state, { payload: { id } }) => {
      outletAdapter.removeOne(state, id);
      state.isRequestPending = false;
    },
    [deleteOutlet.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [createOutletSecondaryImage.fulfilled]: (state, { payload: image }) => {
      state.entities[image.outlet].images.push(image);
    },

    [removeOutletSecondaryImage.fulfilled]: (state, { meta: { arg } }) => {
      const { id: imageId, outletId } = arg;

      state.entities[outletId].images = state.entities[outletId].images.filter(
        (image) => image.id !== imageId
      );
    },
  },
});

export default outletsSlice.reducer;
