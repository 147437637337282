import { Stack } from "@mui/material";
import { ItemPrice, StatusChip } from "components/common";
import { useTranslation } from "react-i18next";

type PriceSectionProps = {
  originalPrice: number;
  finalPrice: number;
  hasSubscriptionRedemption: boolean;
  isPricingTypeFrom?: boolean;
};

const PriceSection = ({
  originalPrice,
  finalPrice,
  hasSubscriptionRedemption,
  isPricingTypeFrom = false,
}: PriceSectionProps) => {
  const { t } = useTranslation("translation");

  return (
    <Stack justifyContent="flex-start" alignItems="flex-end" spacing={2}>
      <ItemPrice
        originalPrice={originalPrice}
        finalPrice={finalPrice}
        isPricingTypeFrom={isPricingTypeFrom}
      />

      {hasSubscriptionRedemption && (
        <StatusChip
          label={t("subscriptionApplied")}
          statusColor="#E4FAF7"
          size="small"
          sx={{
            maxWidth: "fit-content",
            alignSelf: "flex-end",
          }}
        />
      )}
    </Stack>
  );
};

export default PriceSection;
