import { RootState } from "store";

import { SliceStatus } from "../utils";
import { clientSkinTypesAdapter } from "./clientSkinTypesSlice";

export const selectClientSkinTypesLoading = (state: RootState) =>
  state.clientSkinTypes.status === SliceStatus.LOADING;

export const selectClientSkinTypesState = (state: RootState) => state.clientSkinTypes;

const { selectAll } = clientSkinTypesAdapter.getSelectors(selectClientSkinTypesState);

export const selectAllClientSkinTypes = selectAll;
