import { ServiceVariant } from "types/Service";
import { ServiceCategoryWithAllDetails, ServiceWithVariants } from "types/ServiceV2";

export const filterOutEmptyCategories = (category: ServiceCategoryWithAllDetails) =>
  !!category.services.length;

export const filterOutEmptyServices = (service: ServiceWithVariants) => !!service.variants.length;

export const filterOutServiceNameResults =
  (filteredServicesIds: number[]) => (service: ServiceWithVariants) =>
    !filteredServicesIds.includes(service.id);

export const filterByServiceNameOrCode = (searchQuery: string) => (service: ServiceWithVariants) =>
  service.name.toLowerCase().includes(searchQuery) ||
  service.referenceCode.toLowerCase().includes(searchQuery);

export const filterServiceByVariantName = (searchQuery: string) => (variant: ServiceVariant) =>
  variant.name.toLowerCase().includes(searchQuery);

export const filterVariantsWithinService =
  (searchQuery: string) => (service: ServiceWithVariants) => {
    if (!searchQuery) return service;

    const filteredVariants = service.variants.filter(filterServiceByVariantName(searchQuery));

    return { ...service, variants: filteredVariants };
  };

/**
 * This function will filter out categories that don't have any services or service variants that match the search query.
 * It will also filter out any services that neither the service name nor its variants match the search query.
 * @param searchQuery
 * @returns filtered category
 */
export const filterCategoryByServiceOrVariantName =
  (searchQuery: string) => (category: ServiceCategoryWithAllDetails) => {
    if (!searchQuery) return category;

    const filteredServicesByName = category.services.filter(filterByServiceNameOrCode(searchQuery));
    const filteredServicesIds = filteredServicesByName.map((service) => service.id);

    // this will return the service if one of its variants matches the query
    const filteredServicesByVariantName = category.services
      .map(filterVariantsWithinService(searchQuery))
      .filter(filterOutEmptyServices)
      .filter(filterOutServiceNameResults(filteredServicesIds));

    const filteredServices = [...filteredServicesByName, ...filteredServicesByVariantName];

    return {
      ...category,
      services: filteredServices,
    };
  };
