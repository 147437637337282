import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
  Tooltip,
  styled,
} from "@mui/material";
import { getEmployeeFullName } from "helpers/employees";
import { dontForwardProps } from "helpers/styles";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectActiveEmployeesByOutletId,
  selectAllEmployees,
  selectAppointmentFormEmployeesByOutletIdAndZoneId,
} from "store/employees/employeesSlice";
import { selectServiceEmployeesDetailsById } from "store/selectors";
import { selectServiceVariantById } from "store/slices/serviceVariantsV2/serviceVariantsSelectors";
import Employee from "types/Employee";

import { FormArticleSlot } from "../../utils";

type EmployeeAutoCompleteProps = {
  value: FormArticleSlot["staffId"];
  onChange: (number: FormArticleSlot["staffId"]) => void;

  outletId: number | null;

  serviceVariantIdToFilterBy?: FormArticleSlot["serviceVariantId"];
  zoneIdToFilterBy?: Nullable<number>;

  error?: boolean;
  helperText?: string | null;

  disabled?: boolean;

  disableClearable?: boolean;

  startIcon?: ReactNode | null;

  hasAllEmployees?: boolean;

  hasAllOutletEmployees?: boolean;

  tooltipTitle?: string;

  warning?: boolean;
};

export default function EmployeeAutoComplete(props: EmployeeAutoCompleteProps) {
  const { t } = useTranslation();

  const {
    value,
    onChange,
    outletId,
    serviceVariantIdToFilterBy,
    zoneIdToFilterBy = undefined,
    error,
    helperText,
    disabled,
    disableClearable = false,
    startIcon = null,
    hasAllEmployees = false,
    hasAllOutletEmployees = false,
    tooltipTitle = "",
    warning = false,
  } = props;

  /**
   * Employees filtering priority and strategies:
   * 1. hasAllEmployees -> all organization employees
   * 2. hasAllOutletEmployees -> employees filtered by outlet
   * 3. calendar employees filtered by outlet (which is the default)
   */
  const employees = useSelector(
    hasAllEmployees
      ? selectAllEmployees
      : hasAllOutletEmployees
      ? selectActiveEmployeesByOutletId(outletId)
      : selectAppointmentFormEmployeesByOutletIdAndZoneId(outletId, zoneIdToFilterBy || undefined)
  ) as Employee[];

  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantIdToFilterBy || 0));

  // If the already selected employee is not in the filtered list of employees, include it and if it changes it cannot be selected again
  const filteredEmployees = employees.filter(
    (employee) => employee.services.includes(serviceVariant?.service || 0) || employee.id === value
  );

  const serviceEmployees = useSelector(
    selectServiceEmployeesDetailsById(serviceVariant?.service || 0, outletId || 0)
  ) as Employee[];

  const allEmployeesRelatedToService =
    serviceEmployees.length > 0 ? serviceEmployees : filteredEmployees;

  const selectedEmployee = employees.find((employee) => employee.id === value);

  const handleChange = (_: unknown, employee: Employee | null) => {
    if (employee) onChange(employee.id);
    else onChange(null);
  };

  const renderOption = (props: object, employee: Employee) => (
    <MenuItem {...props} key={employee.id}>
      {getEmployeeFullName(employee)}
    </MenuItem>
  );

  return (
    <Autocomplete
      disabled={disabled}
      size="small" // should be default in theme but it don't work
      value={selectedEmployee || null}
      onChange={handleChange}
      options={serviceVariant ? allEmployeesRelatedToService : employees}
      getOptionLabel={getEmployeeFullName}
      renderOption={renderOption}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          InputProps={
            startIcon
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <Tooltip title={tooltipTitle}>
                      <InputAdornment position="start">{startIcon}</InputAdornment>
                    </Tooltip>
                  ),
                }
              : { ...params.InputProps }
          }
          label={t("staff")}
          error={error}
          warning={warning}
          helperText={helperText}
        />
      )}
    />
  );
}

type CustomTextFieldProps = TextFieldProps & {
  warning?: boolean;
};
const CustomTextField = styled(
  TextField,
  dontForwardProps("warning")
)<CustomTextFieldProps>(({ theme, warning }) => ({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: warning && "#FF9800",
  },
  "&:hover .MuiOutlinedInput-input": { borderColor: warning && "#FF9800" },
  "&:hover .MuiInputLabel-root": { borderColor: warning && "#FF9800" },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: warning && "#FF9800",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    borderColor: warning && "#FF9800",
  },
  "& .css-1o0qud-MuiFormHelperText-root": {
    color: warning && "#FF9800",
  },
}));
