import { Stack, Typography } from "@mui/material";
import { PeepPayIcon } from "assets/icon";
import getFormattedPrice from "helpers/getFormattedPrice";
import { DateTime } from "luxon";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import { PeepPayLinkStatus } from "types/Deposit";
import { PeepPayLinkWithDetail } from "types/PeepPayLink";

import CopyLinkButton from "./CopyLinkButton";
import { getPeepPayLinkStatusTranslationKey } from "./utils";

export const peepPayLinkRows = (
  peepPayLinks: PeepPayLinkWithDetail[],
  t: TFunction,
  handleOpenAppointmentDetailDrawer: (appointmentId: number) => void,
  isDeposit: boolean
) => {
  return peepPayLinks.map((peepPayLink) => {
    const isInActiveStatus =
      peepPayLink.status === PeepPayLinkStatus.Expired ||
      peepPayLink.status === PeepPayLinkStatus.Canceled;

    const shouldShowCopyLinkButton = !isInActiveStatus;

    return {
      reference: {
        component: isDeposit ? (
          <Typography key="appointment_id" variant="primary" color="primary" fontWeight={500}>
            {peepPayLink.saleId}
          </Typography>
        ) : (
          <Link
            key="invoice_id"
            style={{ textDecoration: "none" }}
            to={`/invoice/${peepPayLink.saleId}`}
          >
            <Typography variant="primary" color="primary" fontWeight={500}>
              {peepPayLink.saleId}
            </Typography>
          </Link>
        ),
        align: "left",
        action: () =>
          isDeposit ? handleOpenAppointmentDetailDrawer(peepPayLink.saleId) : undefined,
      },

      clientName: {
        component: (
          <Typography variant="primary" fontWeight={500}>
            {peepPayLink.clientName}
          </Typography>
        ),
        align: "left",
      },

      createdAt: {
        component: (
          <Stack gap={0.5}>
            <Typography variant="primary" fontWeight={700}>
              {DateTime.fromISO(peepPayLink.createdAt).toFormat("d MMMM yyyy")}
            </Typography>

            <Typography variant="primary" fontWeight={500}>
              {DateTime.fromISO(peepPayLink.createdAt).toFormat("h:mm a")}
            </Typography>
          </Stack>
        ),
        align: "left",
      },

      mobile: {
        component: (
          <Typography variant="primary" fontWeight={500}>
            {peepPayLink.phoneNumber}
          </Typography>
        ),
        align: "left",
      },

      location: {
        component: (
          <Typography variant="primary" fontWeight={500}>
            {peepPayLink.outlet?.name || "-"}
          </Typography>
        ),
        align: "left",
      },

      amount: {
        component: (
          <Typography variant="primary" fontWeight={500}>{`${t("currency")} ${getFormattedPrice(
            peepPayLink.amount
          )}`}</Typography>
        ),
        align: "left",
      },

      status: {
        component: (
          <Stack gap={0.5} direction="row" alignItems="center">
            <PeepPayIcon
              viewBox="0 0 56 24"
              sx={{
                width: 56,
                height: 18,
              }}
            />
            <Typography variant="primary" color={isInActiveStatus ? "error.main" : "text.primary"}>
              {t(getPeepPayLinkStatusTranslationKey(peepPayLink.status))}
            </Typography>
          </Stack>
        ),
        align: "left",
      },

      action: {
        component: shouldShowCopyLinkButton ? <CopyLinkButton uuid={peepPayLink.uuid} /> : null,
        align: "left",
      },
    };
  });
};
