import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import downloadResponse from "store/sales/downloadResponse";
import stockTransactionsApi from "store/slices/stockTransactions/stockTransactionsApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";

import { SliceStatus } from "../utils";
import api from "./productsApi";

export const productsAdapter = createEntityAdapter<Product>({
  selectId: (product) => product.id,
});

// page will store the product that should be listed in the 'List Page' [10 products at a time], while the products adapter will store all products with minimal information
// once the product list is fetched the products extra information/details will be added onto their matching object in the product entity
const initialState = productsAdapter.getInitialState<{
  status: SliceStatus;
  detailStatus: SliceStatus;
  totalPages: number;
  page: number[];
}>({
  status: SliceStatus.IDLE,
  detailStatus: SliceStatus.IDLE,
  totalPages: 0,
  page: [],
});

export const deleteProduct = createThunkFromApiWithType(
  "products/deleteProduct",
  api.removeProduct
);

export const getProductData = createThunkFromApiWithType("products/productDetails", api.getProduct);

export const getAllProductsData = createThunkFromApiWithType(
  "products/productList",
  api.getProducts,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const getAllProducts = createThunkFromApiWithType(
  "products/getAllProducts",
  api.getAllProducts,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const getProductsExcel = createThunkFromApiWithType(
  "expenses/getExpensesExcel",
  api.getProductsExcel,
  {
    handleResponse: ({ response }) => {
      downloadResponse(response);
    },
  }
);

export const appendAllProductsData = createThunkFromApiWithType(
  "products/appendProductList",
  api.getProducts,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const createProduct = createThunkFromApiWithType(
  "products/createProduct",
  api.createProduct
);

export const createStockDown = createThunkFromApiWithType(
  "stocks/createStockDown",
  stockTransactionsApi.createStockDown
);

export const createStockUp = createThunkFromApiWithType(
  "stocks/createStockUp",
  stockTransactionsApi.createStockUp
);

export const createStockTransfer = createThunkFromApiWithType(
  "stocks/createStockTransfer",
  stockTransactionsApi.createStockTransfer
);

export const updateProduct = createThunkFromApiWithType(
  "products/updateProduct",
  api.updateProduct
);

export const productsSlice = createSlice({
  name: "products",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getProductData.pending, (state) => {
      state.detailStatus = SliceStatus.LOADING;
    });

    reducers.addCase(getProductData.fulfilled, (state, { payload: { product } }) => {
      productsAdapter.upsertOne(state, product);
      state.detailStatus = SliceStatus.IDLE;
    });

    reducers.addCase(getProductData.rejected, (state) => {
      return { ...state, detailStatus: SliceStatus.FAILED };
    });

    reducers.addCase(getAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductsData.fulfilled, (state, { payload }) => {
      productsAdapter.upsertMany(state, payload.results.products);
      state.page = payload.results.products.map((product) => product.id);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(getAllProductsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getAllProducts.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProducts.fulfilled, (state, { payload }) => {
      productsAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProducts.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(appendAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductsData.fulfilled, (state, { payload }) => {
      productsAdapter.upsertMany(state, payload.results.products);
      state.page = [...state.page, ...payload.results.products.map((product) => product.id)];
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendAllProductsData.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProduct.fulfilled, (state, { payload }) => {
      const { product } = payload;

      productsAdapter.upsertOne(state, product);
      state.page = [product.id, ...state.page];

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { product } }) => {
      productsAdapter.upsertOne(state, product);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { product } = payload;

      productsAdapter.upsertOne(state, product);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { product } }) => {
      productsAdapter.upsertOne(state, product);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { product } }) => {
      productsAdapter.upsertOne(state, product);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
    reducers.addCase(updateProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(deleteProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteProduct.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        productsAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default productsSlice.reducer;
