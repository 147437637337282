import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

import StatusChip from "../StatusChip";

type DiscountCodeChipProps = {
  code: string;
};

const DiscountCodeChip = ({ code }: DiscountCodeChipProps) => {
  return (
    <StatusChip
      statusColor="#B3B6EF"
      size="small"
      sx={{
        maxWidth: "fit-content",
        alignSelf: "flex-end",
      }}
      label={
        <Typography variant="secondary" fontWeight={500}>
          <Trans
            i18nKey="appointments:discountCodeApplied"
            values={{ code }}
            components={[
              <Typography
                key="discount-code-applied"
                display="inline"
                variant="secondary"
                fontWeight={700}
              />,
            ]}
          />
        </Typography>
      }
    />
  );
};

export default DiscountCodeChip;
