import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useCheckoutModal from "hooks/useCheckoutModal";
import { CheckoutModal } from "modals";
import AppointmentDrawer from "modals/AppointmentDrawer/AppointmentDrawer";
import routes from "pages";
import { CalendarPageContextProvider } from "pages/CalendarPage/CalendarPageContext";
import { AppointmentFormContextProvider } from "pages/CalendarPage/components/AppointmentForm/AppointmentFormContext";
import { ServiceSuspended } from "pages/ServiceSuspended";
import peepSubscriptionsApi from "pages/Settings/PeepSubscriptions/peepSubscriptionsApi";
import { TierInvoice } from "pages/Settings/PeepSubscriptions/PeepSubscriptionsTypes";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { GenericDispatch } from "store";
import { selectAuth, selectIsLoggedIn } from "store/auth/authSlice";
import { getUserPreference } from "store/calendarPresets/calendarPresetsSlice";
import { getAllClients } from "store/clients/clientsSlice";
import { getAllClosedDates } from "store/closedDates/closedDatesSlice";
import { getAllEmployees, selectEmployeeLoading } from "store/employees/employeesSlice";
import {
  getMyPermissions,
  selectAllPermissions,
  selectAllPermissionsLoading,
  setOwnerOrAdminPermissions,
} from "store/myPermissions/myPermissionsSlice";
import {
  getOrganization,
  selectOrganization,
  selectOrganizationLoading,
} from "store/organization/organizationSlice";
import { getOrganizationOnlineProfile } from "store/organizationOnlineProfile/organizationOnlineProfileSlice";
import { getOrganizationOpeningHours } from "store/organizationOpeningHours/organizationOpeningHoursSlice";
import { getAllOutlets, selectOutletLoading } from "store/outlets/outletsSlice";
import { getAllPaymentTypes } from "store/paymentType/paymentTypesSlice";
import { selectResourcesLoading } from "store/selectors";
import { getAreas } from "store/slices/areas/areasSlice";
import { getCities } from "store/slices/cities/citiesSlice";
import { getAllClientHairTypes } from "store/slices/clientHairTypes/clientHairTypesSlice";
import { getAllClientPreferredBeverageTypes } from "store/slices/clientPreferredBeverages/clientPreferredBeveragesSlice";
import { getAllClientScalpTypes } from "store/slices/clientScalpTypes/clientScalpTypesSlice";
import { getAllClientSkinTypes } from "store/slices/clientSkinTypes/clientSkinTypesSlice";
import { getAllExpenseTypes } from "store/slices/expenseTypes/expenseTypesSlice";
import { getFlexServices } from "store/slices/flexBooking/flexBookingSlice";
import { getIndustryCategories } from "store/slices/industryCategories/industryCategoriesSlice";
import { getLoyaltiesBrief } from "store/slices/loyaltyPrograms/loyaltyProgramsSlice";
import { getNotifications } from "store/slices/notifications/notificationsSlice";
import { getAllOnlineBookingsStatuses } from "store/slices/onlineBookingStatus/onlineBookingStatusSlice";
import { getAllOutletFeaturedServices } from "store/slices/outletFeaturedServices/outletFeaturedServicesSlice";
import { getAllPackageCategories } from "store/slices/packageCategoriesV2/packageCategoriesSlice";
import { getAllPackages } from "store/slices/packagesV2/packagesSlice";
import { getAllPackageVariants } from "store/slices/packageVariantsV2/packageVariantsSlice";
import { getAllProductBrands } from "store/slices/productBrands/productBrandsSlice";
import { getAllProductCategories } from "store/slices/productCategories/productCategoriesSlice";
import { getAllProducts } from "store/slices/products/productsSlice";
import { getAllResources } from "store/slices/resources/resourcesSlice";
import { getAllServiceCategories } from "store/slices/serviceCategoriesV2/serviceCategoriesSlice";
import { getAllServices } from "store/slices/servicesV2/servicesSlice";
import { getAllServiceVariants } from "store/slices/serviceVariantsV2/serviceVariantsSlice";
import { getAllSubscriptions } from "store/slices/subscriptions/subscriptionsSlice";
import { getAllSuppliers } from "store/slices/suppliers/suppliersSlice";
import { getTags } from "store/slices/tags/tagsSlice";
import { getAllVouchers } from "store/slices/vouchers/vouchersSlice";
import { getZones } from "store/slices/zones/zonesSlice";
import { setUserForTracking } from "tracking";

const UNAUTHENTICATED_ROUTES = ["/", "/login", "/forgot-password", "/reset-password"];

function App() {
  const dispatch = useDispatch<GenericDispatch>();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectAuth);
  const organization = useSelector(selectOrganization);
  const arePermissionsLoading = useSelector(selectAllPermissionsLoading);
  const isOrganizationLoading = useSelector(selectOrganizationLoading);
  const userPermissions = useSelector(selectAllPermissions);

  const areOutletsLoading = useSelector(selectOutletLoading) as boolean;

  const areEmployeesLoading = useSelector(selectEmployeeLoading) as boolean;

  const areResourcesLoading = useSelector(selectResourcesLoading);

  const areCalendarRelatedRequiredRequestsLoading = useRef(true);

  const hasTerminatedInitialAppLoadingRef = useRef(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [subscriptionInvoices, setSubscriptionInvoices] = useState<TierInvoice[] | undefined>();

  const { isCheckoutModalOpen } = useCheckoutModal();
  const { isAppointmentDrawerOpen } = useAppointmentDrawer();

  const myRole = useSelector(selectAllPermissions);

  if (user && organization) {
    setUserForTracking();
  }

  useEffect(() => {
    if (!isLoggedIn && !checkIsUnauthenticatedRoute(pathname)) {
      navigate("/");
    }
  }, [isLoggedIn, navigate, pathname]);

  async function loadSubscriptionInvoice() {
    const response = await peepSubscriptionsApi.getSubscriptionActiveTierInvoices();
    setSubscriptionInvoices(response.data);
  }

  useEffect(() => {
    if (isLoggedIn) {
      loadSubscriptionInvoice();

      dispatch(getUserPreference({}));

      if (user.isEmployee && !user.isOwnerOrAdmin) {
        dispatch(getMyPermissions({})); // This endpoint is only for regular employees
      } else {
        dispatch(setOwnerOrAdminPermissions());
      }

      dispatch(getAllClients({ page: 1, tagOptions: [], ordering: [], searchQuery: "" }));
      dispatch(getAllEmployees());
      dispatch(getOrganization());
      dispatch(getAllOutlets());
      dispatch(getAllPaymentTypes());
      dispatch(getAllClosedDates());
      dispatch(getAllProductCategories({}));
      dispatch(getAllProductBrands({}));
      dispatch(getAllProducts({}));
      dispatch(getAllSuppliers({}));
      dispatch(getAllSubscriptions({}));
      dispatch(getAllVouchers({}));
      dispatch(getAllResources({}));
      dispatch(getLoyaltiesBrief({}));
      dispatch(getAllClientHairTypes({}));
      dispatch(getAllClientSkinTypes({}));
      dispatch(getAllClientPreferredBeverageTypes({}));
      dispatch(getAllClientScalpTypes({}));

      //Version 2 Requests
      dispatch(getAllServiceCategories({}));
      dispatch(getAllServices({}));
      dispatch(getAllServiceVariants({}));
      dispatch(getAllPackageCategories({}));
      dispatch(getAllPackages({}));
      dispatch(getAllPackageVariants({}));
      dispatch(getIndustryCategories({}));
      dispatch(getTags({}));
      dispatch(getCities({}));
      dispatch(getAreas({}));
      dispatch(getZones({}));

      dispatch(getFlexServices({}));

      dispatch(getNotifications({}));

      if (user.isOwnerOrAdmin && !user.isEmployee) {
        dispatch(getAllOutletFeaturedServices({}));
        dispatch(getOrganizationOpeningHours());
        dispatch(getOrganizationOnlineProfile());
      }

      if (user.isOwnerOrAdmin || userPermissions.hasExpenses.viewAccess) {
        dispatch(getAllExpenseTypes({}));
      }

      if (user.isOwnerOrAdmin || userPermissions.hasManageAcceptingOnlineBookingsAccess.viewAccess)
        dispatch(getAllOnlineBookingsStatuses({}));
    }
  }, [
    dispatch,
    isLoggedIn,
    user,
    userPermissions.hasExpenses.viewAccess,
    userPermissions.hasManageAcceptingOnlineBookingsAccess.viewAccess,
  ]);

  const isHomeService = checkIsHomeServiceOrganization(organization?.industry);

  const routesUsed = routes(user, myRole, isHomeService);

  const routesBasedOnUserPermissions = useRoutes(routesUsed);

  useEffect(() => {
    if (
      isLoggedIn &&
      !isOrganizationLoading &&
      !arePermissionsLoading &&
      !areOutletsLoading &&
      !areEmployeesLoading &&
      !areResourcesLoading
    ) {
      if (areCalendarRelatedRequiredRequestsLoading.current) {
        areCalendarRelatedRequiredRequestsLoading.current = false;
        hasTerminatedInitialAppLoadingRef.current = true;
      }
    }
  });

  if (
    isLoggedIn &&
    ((user.isEmployee && arePermissionsLoading) ||
      isOrganizationLoading ||
      areCalendarRelatedRequiredRequestsLoading.current)
  )
    return <CentredSpinnerBox />;

  if (isLoggedIn && organization && !organization.hasActiveSubscription) {
    return <ServiceSuspended invoice={subscriptionInvoices?.[0]} />;
  }

  return (
    <CalendarPageContextProvider>
      <AppointmentFormContextProvider>
        {routesBasedOnUserPermissions}

        {isCheckoutModalOpen && <CheckoutModal />}

        {isAppointmentDrawerOpen && <AppointmentDrawer />}
      </AppointmentFormContextProvider>
    </CalendarPageContextProvider>
  );
}

export default App;

/**
 * returns the route without the last part of the pathname (e.g. /dashboard/clients -> /dashboard)
 * @param pathname
 * @returns
 */

const getCurrentPathWithoutLastPart = (pathname: string) => {
  const pathRgx = /\//g;
  const childRouteCount = ((pathname || "").match(pathRgx) || []).length;
  return childRouteCount > 1 ? pathname.slice(0, pathname.lastIndexOf("/")) : pathname;
};

const checkIsUnauthenticatedRoute = (pathname: string) => {
  const route = getCurrentPathWithoutLastPart(pathname);

  if (route === "/") return true;

  const isPathnameUnauthenticatedRoute = UNAUTHENTICATED_ROUTES.some(
    (path) => path !== "/" && route.includes(path)
  );

  return isPathnameUnauthenticatedRoute;
};
