import { ClientWallet } from "types/ClientWallet";
import http from "utils/http";

export type SubscriptionListResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalCredits: number;
  totalPages: number;
  results: ClientWallet[];
};

type ClientsWalletsListResponse = {
  page?: number;
  ordering?: string;
  searchQuery?: string;
  walletType?: string;
};

const clientsWalletsApi = {
  getClientsWallets: async ({
    page,
    ordering,
    searchQuery,
    walletType,
  }: ClientsWalletsListResponse) =>
    http.get<SubscriptionListResponse>(
      `wallets/organization/?page=${page}${ordering ? `&order_by=${ordering}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }${walletType ? `&wallet_type=${walletType}` : ""}`
    ),
};

export default clientsWalletsApi;
