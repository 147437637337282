import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/serviceVariantsV2/serviceVariantsApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ServiceVariant } from "types/ServiceV2";

import {
  createService,
  deleteService,
  getServiceDetails,
  updateService,
} from "../servicesV2/servicesSlice";
import { SliceStatus } from "../utils";

export const serviceVariantsAdapter = createEntityAdapter<ServiceVariant>({
  selectId: (serviceVariant) => serviceVariant.id,
});

const initialState = serviceVariantsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllServiceVariants = createThunkFromApiWithType(
  "serviceVariantsV2/getAllServiceVariants",
  api.getAllServiceVariants,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const serviceVariantsSlice = createSlice({
  name: "serviceVariantsV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllServiceVariants.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllServiceVariants.fulfilled, (state, { payload }) => {
      serviceVariantsAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllServiceVariants.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(getServiceDetails.fulfilled, (state, { payload: { variants } }) => {
      serviceVariantsAdapter.upsertMany(state, variants);
    });

    reducers.addCase(createService.fulfilled, (state, { payload }) => {
      const { variants } = payload;

      serviceVariantsAdapter.upsertMany(state, variants);
    });

    reducers.addCase(updateService.fulfilled, (state, { payload }) => {
      const { variants } = payload;
      serviceVariantsAdapter.upsertMany(state, variants);
    });

    reducers.addCase(deleteService.fulfilled, (state, { payload: { variants } }) => {
      serviceVariantsAdapter.upsertMany(state, variants);

      state.status = SliceStatus.IDLE;
    });
  },
});

export default serviceVariantsSlice.reducer;
