import { useTheme } from "@mui/material";
export const StickyBottomActions = () => {
  const theme = useTheme();

  return {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2.5, 2),
    zIndex: "10",
    borderTop: `1px solid ${theme.palette.common.gray}25`,
  };
};
