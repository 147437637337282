import { getAppointmentStatusIcon } from "helpers/getAppointmentStatusIcon";
import { useTranslation } from "react-i18next";
import { AppointmentStatusOptions } from "types/Appointment";

import AppointmentActionChip from "./AppointmentActionChip";

type AppointmentDetailsStatusChipProps = {
  appointmentStatus: AppointmentStatusOptions;
};

const AppointmentDetailsStatusChip = ({ appointmentStatus }: AppointmentDetailsStatusChipProps) => {
  const { t } = useTranslation("translation");

  return (
    <AppointmentActionChip
      icon={getAppointmentStatusIcon(appointmentStatus)}
      label={t(appointmentStatus.toLowerCase())}
      variant="outlined"
    />
  );
};

export default AppointmentDetailsStatusChip;
