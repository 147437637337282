import type { CheckoutLoyaltyCard } from "./Appointment";
import { AppointmentDeposit, PeepPayDepositArguments, PeepPayLinkStatus } from "./Deposit";
import { InvoicePurchasableItemType } from "./Invoice";
import Outlet from "./Outlet";
import { PeepTransaction } from "./PeepTransaction";
import { DiscountOption } from "./promotions";
import { PurchasableItemOption } from "./PurchasableItem";
import { VoucherPurchaseInvoice } from "./VoucherPurchase";
import { VoucherRedemption } from "./VoucherRedemption";

export enum InvoiceStatus {
  Paid = "PAID",
  Unpaid = "UNPAID",
  Partially = "PARTIALLY",
  Refunded = "REFUNDED",
  PartiallyRefunded = "PARTIALLY REFUNDED",
  Void = "VOID",
  Archived = "ARCHIVED",
  Free = "FREE", // This is not an actual status on the backend, it is used to indicate that the invoice is free
}

type PurchasableServiceVariant = { serviceVariant?: Nullable<number> };
type PurchasableProduct = { product?: Nullable<number> };
type PurchasablePackageVariant = { packageVariant?: Nullable<number> };

type InvoicePurchasedItem = {
  id: number;
  purchasableType: InvoicePurchasableItemType;

  serviceVariant: Nullable<number>;
  packageVariant: Nullable<number>;
  product: Nullable<number>;
  subscription: Nullable<number>;
  voucher: Nullable<number>;
  peepPurchasable: Nullable<number>;
};

export type CreateInvoiceArticleArgs = {
  title: string;
  subtitle: string;
  quantity?: number;
  finalPrice: number;
  originalPrice: number;

  subscriptionPurchaseId?: Nullable<number>;

  purchasableItemType: PurchasableItemOption;
  purchasedItem: PurchasableServiceVariant | PurchasableProduct | PurchasablePackageVariant;

  details: {
    purchasedItem: PurchasableServiceVariant | PurchasableProduct;
    saleTime: string;
    duration: number;
    saleEmployee?: number;
    resource?: Nullable<number>;
  }[];
};

export type NewClient = {
  firstName: string;
  lastName?: string;
  phoneNumber: string;
  phoneCountryCode: string;
};

export type ExistingClient = {
  id: number;
};

type CreateInvoicePaymentArgs = {
  amount: number;
  paymentType: Nullable<number>;
  peepPay: Nullable<PeepPayDepositArguments>;
};

type CreateInvoiceVoucherRedemption = {
  voucherPurchase: number;
  value: NumericalString;
};

type Refund = {
  wallet: boolean;
  paymentType: Nullable<number>;
  amount: NumericalString;
};

export type CreateInvoiceArgs = {
  articles: CreateInvoiceArticleArgs[];

  outlet: number;

  notes: string;

  appointment: Nullable<number>;
  deletedArticles: number[];

  client: NewClient | ExistingClient | null;

  voucherRedemption?: CreateInvoiceVoucherRedemption[];

  payments: CreateInvoicePaymentArgs[];

  refund?: Refund;
};

export enum ArticleRefundBlockReason {
  Subscription = "Subscription",
  Voucher = "Voucher",
}

export type InvoiceArticle = {
  id: number;
  // NOTE: this is wrong this field should be here, investigate later when you have time
  purchasableItemType: PurchasableItemOption;

  finalPrice: string;
  originalPrice: string;
  creditPrice: string;
  markedPrice: string;

  purchasedItem: InvoicePurchasedItem;

  details: InvoiceArticleDetail[];

  invoice: number;

  subscriptionRedemption: Nullable<InvoiceArticleSubscriptionRedemption>;

  articleName: string;
  articleSecondaryName: string;

  totalDuration: number;
  noOfServices: number;
  quantity?: number;

  promotion: Nullable<number>;
  promotionName: Nullable<string>;
  promotionDiscountType: Nullable<DiscountOption>;
  promotionDiscountValue: Nullable<string>;

  refundBlockedBy: Nullable<ArticleRefundBlockReason>;

  peepGiftCredit: Nullable<number>;

  createdAt: string;
  updatedAt: string;
};

// TODO: cleanup this file
// type ArticleDetailPurchasedItem = {
//   id: number;
//   purchasableType: PurchasableItemOption;
//   serviceVariant: Nullable<number>;
//   packageVariant: Nullable<number>;
//   product: Nullable<number>;
// };

type InvoiceArticleSubscriptionRedemption = {
  name: string;
  credits: number;
};

// doesn't look anything like the request to create an invoice
type InvoiceArticleDetail = {
  id: number;
  duration: number;
  name: string;
  employee: string;
  resource?: number;
};

type InvoiceOutlet = {
  id: Outlet["id"];
  name: Outlet["name"];
};

export enum InvoiceType {
  Sale = "SALE",
  Refund = "REFUND",
}
export type Invoice = {
  id: number;

  client: Nullable<number>;
  onlineProfile: Nullable<number>;
  clientFullName: string;
  clientPhoneNumber: string;
  clientPhoneCountryCode: string;

  outlet: InvoiceOutlet;
  appointment: Nullable<number>;
  notes: string;
  date: string;

  articles: InvoiceArticle[];

  number: number;
  status: InvoiceStatus;
  type: InvoiceType;

  createdBy: Nullable<string>;
  createdAt: string;
  updatedAt: string;

  balance: string;
  total: string;

  createdOnline: boolean;
  isAwaitingOnlinePayment: boolean;

  originalInvoice: Nullable<number>;
  refundInvoice: InvoiceRefundInvoice;

  deposit: AppointmentDeposit;
  onlinePayments: Nullable<OnlinePayment[]>;
  peepTransactions: Nullable<PeepTransaction[]>;
  payments: InvoicePayment[];
  voucherRedemptions: Nullable<VoucherRedemption[]>;

  // peep payments reside here inside pendingPayments
  pendingPayments: Nullable<InvoicePayment[]>;

  loyaltyCards: CheckoutLoyaltyCard[];

  // TODO: FIX THIS TYPE
  voucherPurchases: Nullable<VoucherPurchaseInvoice[]>;

  refund: Nullable<InvoicePayment[]>;

  logs: InvoiceLog[];
};

type InvoiceRefundInvoice = {
  id: number;
  number: string;
  createdAt: string;
  createdBy: string;
};

export enum InvoiceLogAction {
  VOID = "VOID",
  REFUND = "REFUND",
}

type InvoiceLog = {
  id: number;
  action: InvoiceLogAction;
  createdAt: string;
  createdBy: string;
  invoice: number;
};

type InvoicePaymentType = {
  id: number;
  label: string;
  forExpenses: boolean;
  forPos: boolean;
  deprecated: boolean;
  createdAt: string;
  updatedAt: string;
  organization: number;
};

export enum WalletType {
  Online = "ONLINE",
  Offline = "OFFLINE",
}

export type InvoicePayment = {
  id: number;
  amount: string;

  paymentType: Nullable<InvoicePaymentType>;

  wallet: Nullable<WalletType>;

  peepPaymentInvoice: Nullable<number>;

  // For pending payments it will have a value
  status: Nullable<PeepPayLinkStatus>;

  createdBy: string;
  createdAt: string;
  updatedAt: string;
  invoice: number;
};

export type OnlinePayment = {
  id: number;
  appointment: number;
  client: number;
  invoice: number;

  authCode: string;

  orderNumber: string;
  paymentId: string;
  paymentType: string;
  trackId: string;
  tranId: string;

  postDate: string;
  ref: string;
  result: string;
  status: string;

  commission: string;
  total: string;

  createdAt: string;
  updatedAt: string;
};

export type RefundInvoicePayment =
  | { amount: number; paymentType: Nullable<number> }
  | {
      amount: number;
      wallet: boolean;
    };

export type CreateRefundInvoiceArgs = {
  originalInvoice: number;
  articlesToRefund: number[];
  notes: string;
  refundPayments: RefundInvoicePayment[];
};

export type RefundArticle = {
  id: number;
  articleName: string;
  articleSecondaryName: string;
  totalDuration: Nullable<number>;
  creditPrice: string;
  soldForPrice: string;
  finalPrice: string;
  createdAt: string;
  updatedAt: string;
  invoice: number;
  invoiceArticle: number;
};

type RefundInvoiceOriginalInvoice = {
  id: number;
  number: number;
  lastPayment: InvoicePayment;
  createdBy: Nullable<string>;
};

// The response of the create refund invoice endpoint
export type RefundInvoice = {
  id: number;
  refundArticles: RefundArticle[];
  payments: InvoicePayment[];
  balance: string;
  outlet: InvoiceOutlet;
  originalInvoice: RefundInvoiceOriginalInvoice;
  date: string;
  number: number;
  type: InvoiceType;
  status: InvoiceStatus;
  clientFullName: string;
  clientCountryCode: string;
  clientPhoneNumber: string;
  total: string;
  notes: string;
  createdOnline: boolean;
  isAwaitingOnlinePayment: boolean;
  createdAt: string;
  updatedAt: string;
  appointment: Nullable<number>;
  client: Nullable<number>;
  onlineProfile: Nullable<number>;
  createdBy: number;
};
