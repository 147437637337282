

import http from "utils/http";

import {
  FlexService,
  FlexServiceResponse
} from "types/FlexBooking"

const flexBookngApi = {
  getFlexServices: async () => http.get<FlexServiceResponse[]>("flex_bookings/flex_services/"),
  createFlexService: async (service: FlexService) => http.post<FlexServiceResponse>("flex_bookings/flex_services/", service),
  updateFlexService: async ({ id, ...service }: FlexService) => http.put<FlexServiceResponse>(`flex_bookings/flex_services/${id}/`, service),
};

export default flexBookngApi;
