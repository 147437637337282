import { ListItemIcon, MenuItem, Tooltip, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

const PeepMenuItem = ({
  label,
  onClick,
  hoverBgColor,
  hoverLabelColor,
  labelColor,
  icon,
  disabled = false,
  disabledMessage = "",
  ...props
}) => {
  return (
    <Tooltip title={(disabled && disabledMessage) || ""}>
      <StyledMenuItem
        disabled={disabled}
        onClick={onClick}
        hoverBgColor={hoverBgColor}
        hoverLabelColor={hoverLabelColor}
        labelColor={labelColor}
        {...props}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}

        {label}
      </StyledMenuItem>
    </Tooltip>
  );
};

const StyledMenuItem = styled(
  MenuItem,
  dontForwardProps("hoverBgColor", "hoverLabelColor", "labelColor")
)(({ theme, hoverBgColor, hoverLabelColor, labelColor }) => ({
  color: labelColor && labelColor,

  [theme.breakpoints.down("md")]: {
    borderBottom: `1px solid ${theme.palette.common.gray}25`,
    fontSize: 16,
    fontWeight: 500,
    padding: theme.spacing(2.5, 0),
    minHeight: "initial",
    "&:last-child": {
      borderBottom: "none",
    },
  },

  "&:hover": {
    backgroundColor: hoverBgColor && hoverBgColor,
    color: hoverLabelColor ? hoverLabelColor : theme.palette.primary.main,
  },
}));

export default PeepMenuItem;
