import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getErrorMessage } from "helpers/errors";
import { DateTime, Interval } from "luxon";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./closedDatesApi";

const closedDateAdapter = createEntityAdapter();

const initialState = closedDateAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = closedDateAdapter.getSelectors((state) => state.closedDates);

export const selectAllClosedDates = selectAll;

export const selectClosedDateById = (id) => (state) => selectById(state, id);

export const selectClosedDateLoading = (state) => state.isLoading;

export const selectClosedDatesByDateRangeAndOutlet = (startDate, endDate, outletId) => (state) => {
  const allClosedDates = selectAllClosedDates(state);

  return allClosedDates.filter((closedDate) => {
    const closedDateStart = DateTime.fromISO(closedDate.startDate);
    const closedDateEnd = DateTime.fromISO(closedDate.endDate);

    const closedDateInterval = Interval.fromDateTimes(closedDateStart, closedDateEnd);

    const calendarInterval = Interval.fromDateTimes(startDate, endDate);

    return (
      (closedDateInterval.intersection(calendarInterval) ||
        calendarInterval.contains(closedDateStart) ||
        calendarInterval.contains(closedDateEnd)) &&
      closedDate.outlets.includes(outletId)
    );
  });
};

export const getAllClosedDates = createThunkFromApi(
  "closedDates/getAllClosedDates",
  api.getAllClosedDates
);

export const getClosedDate = createThunkFromApi("closedDates/getClosedDate", api.getClosedDate);

export const createClosedDate = createAsyncThunk(
  "closedDates/createClosedDate",
  async (createClosedDateArgs, { rejectWithValue }) => {
    try {
      const { submittedData, handleClose } = createClosedDateArgs;
      const response = await api.createClosedDate(submittedData);

      handleClose();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateClosedDate = createAsyncThunk(
  "closedDates/updateClosedDate",
  async (updateClosedDateArgs, { rejectWithValue }) => {
    try {
      const { id, submittedData, handleClose } = updateClosedDateArgs;
      const response = await api.updateClosedDate(id, submittedData);

      handleClose();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteClosedDate = createAsyncThunk(
  "closedDates/removeClosedDate",
  async (deleteClosedDateArgs, { rejectWithValue }) => {
    try {
      const { id, handleCloseDelete } = deleteClosedDateArgs;
      await api.removeClosedDate(id);

      handleCloseDelete();
      return { id };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
        if (status === 401) return rejectWithValue(data.detail);
      }
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const closedDatesSlice = createSlice({
  name: "closedDates",
  initialState,
  extraReducers: {
    [getAllClosedDates.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllClosedDates.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.error = "";
      closedDateAdapter.setAll(state, payload);
    },

    [getAllClosedDates.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getClosedDate.fulfilled]: (state, { payload }) => {
      closedDateAdapter.setOne(state, payload);
    },

    [createClosedDate.pending]: (state) => {
      state.isRequestPending = true;
    },

    [createClosedDate.fulfilled]: (state, { payload }) => {
      closedDateAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createClosedDate.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updateClosedDate.pending]: (state) => {
      state.isRequestPending = true;
    },

    [updateClosedDate.fulfilled]: (state, { payload }) => {
      closedDateAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },

    [updateClosedDate.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [deleteClosedDate.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deleteClosedDate.fulfilled]: (state, { payload: { id } }) => {
      closedDateAdapter.removeOne(state, id);
      state.isRequestPending = false;
    },
    [deleteClosedDate.rejected]: (state) => {
      state.isRequestPending = false;
    },
  },
});

export default closedDatesSlice.reducer;
