import { Box } from "@mui/material";
import { theme } from "configs";
import { useSelector } from "react-redux";
import { selectIndustryCategoryById } from "store/slices/industryCategories/industryCategoriesSelectors";
import { IndustryCategory } from "types/IndustryCategory";
import { getIndustryCategoryIconById } from "utils/getIndustryCategoryIcon";

type IndustryCategoryIconProps = {
  industryCategory?: IndustryCategory;
  industryCategoryId?: number;
  color?: string;
  dimension?: number;
};

const IndustryCategoryIcon = ({
  industryCategory,
  industryCategoryId,
  color,
  dimension = 40,
}: IndustryCategoryIconProps) => {
  const industryCategoryFromId = useSelector(selectIndustryCategoryById(industryCategoryId || -1));

  if (!industryCategory && !industryCategoryFromId) return null;

  return (
    <Box
      sx={{
        backgroundColor: color || industryCategory?.color || industryCategoryFromId?.color,
        borderRadius: theme.spacing(1),
        width: dimension,
        height: dimension,
        padding: theme.spacing(0.5),
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {getIndustryCategoryIconById((industryCategoryId || industryCategory?.id)!)}
    </Box>
  );
};

export default IndustryCategoryIcon;
