import { Grid } from "@mui/material";
import { styled } from "@mui/system";

const SettingPageContainer = styled(Grid)(({ theme }) => ({
  maxWidth: "800px",
  paddingTop: theme.spacing(3),
  margin: "auto",
  marginBottom: theme.spacing(6.25),
  "&:last-child": {
    marginBottom: 0,
  },
}));

export default SettingPageContainer;
