import { DateTime } from "luxon";

export const getSlotDurationInMinutes = (slot) => {
  const startTimeInSeconds = DateTime.fromISO(slot.start ? slot.start : slot.startTime).toSeconds();

  const endTimeInSeconds = DateTime.fromISO(slot.end ? slot.end : slot.endTime).toSeconds();

  const slotDurationInMinutes = (endTimeInSeconds - startTimeInSeconds) / 60;

  const roundedDurationAsInteger = Math.trunc(slotDurationInMinutes);

  return roundedDurationAsInteger;
};
