import { DateTime } from "luxon";

// format a time string, into time format (default: hh:mm a) taking into account the timezone
const formatTime = (dateTime: string | DateTime) => {
  if (typeof dateTime === "string") {
    return DateTime.fromJSDate(new Date(dateTime)).toFormat("t a"); // 12:00 AM
  }
  return dateTime.toFormat("t a"); // 12:00 AM
};

export default formatTime;
