import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { notificationAdapter } from "./notificationsSlice";

export const selectNotificationsLoading = (state: RootState) =>
  state.notifications.status === SliceStatus.LOADING;

export const selectNotificationsState = (state: RootState) => state.notifications;

const { selectAll } = notificationAdapter.getSelectors(selectNotificationsState);

export const selectAllNotifications = selectAll;

export const selectNotificationsTimestamp = (state: RootState) => state.notifications.timestamp;

export const selectAllNewNotifications = (state: RootState) =>
  selectAllNotifications(state).filter((notification) => !notification.isRead);

export const selectAllReadNotifications = (state: RootState) =>
  selectAllNotifications(state).filter((notification) => notification.isRead);

export const selectNewNotificationsIds = (state: RootState) =>
  selectAllNewNotifications(state).map((notification) => notification.id);

export const selectNewNotificationsCount = (state: RootState) =>
  selectAllNewNotifications(state).length;
