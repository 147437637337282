import { Box, Stack, Typography } from "@mui/material";
import { PeepPayIcon } from "assets/icon";
import { useTranslation } from "react-i18next";

import DialogModal from "../DialogModal";
import NoAccessGraphic from "../NoAccessGraphic";

type ManagePeepPayModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ManagePeepPayModal = ({ isOpen, onClose }: ManagePeepPayModalProps) => {
  const { t } = useTranslation("invoices");

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <PeepPayIcon
            viewBox="0 0 56 24"
            sx={{
              width: 56,
              height: 24,
            }}
          />

          <Typography variant="h1">{t("invoices:peepPay")}</Typography>
        </Stack>
      }
      hasTitleOnTop
    >
      <Box py={2}>
        <NoAccessGraphic description={t("contactSalesToManagePeepPay")} />
      </Box>
    </DialogModal>
  );
};

export default ManagePeepPayModal;
