import stringifyArray from "helpers/stringifyArray";
import {
  CreateStockDownArguments,
  CreateStockTransferArguments,
  CreateStockUpArguments,
  StockTransaction,
} from "types/Stock";
import http from "utils/http";

import { ProductResponse } from "../products/productsApi";

type StockTransactionResponse = {
  results: StockTransaction[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
};

const stockTransactionsApi = {
  createStockDown: async ({ data }: { data: CreateStockDownArguments }) =>
    http.post<ProductResponse>("stocks/stock_down/", data),

  createStockUp: async ({ data }: { data: CreateStockUpArguments }) =>
    http.post<ProductResponse>("stocks/stock_up/", data),

  createStockTransfer: async ({ data }: { data: CreateStockTransferArguments }) =>
    http.post<ProductResponse>("stocks/transfers/", data),

  getStockTransactions: async ({
    page,
    startDate,
    endDate,
    outletId,
    transactionType,
    stockUpReason,
    stockDownReason,
    createdByIds,
    suppliersIds,
    searchQuery,
  }: {
    page: number;
    startDate: string;
    endDate: String;
    outletId: number;
    transactionType: string;
    stockUpReason: string[];
    stockDownReason: string[];
    createdByIds: number[];
    suppliersIds: number[];
    searchQuery: string;
  }) =>
    http.get<StockTransactionResponse>(
      `stocks/transactions/?page=${page}&start_date=${startDate}&end_date=${endDate}${outletId !== 0 ? `&outlet=${outletId}` : ""
      }${transactionType !== "All History" ? `&type=${transactionType}` : ""}${stockUpReason && !!stockUpReason.length
        ? `&stock_up_reason=${stringifyArray(stockUpReason)}`
        : ""
      }${stockDownReason && !!stockDownReason.length
        ? `&stock_down_reason=${stringifyArray(stockDownReason)}`
        : ""
      }${createdByIds && !!createdByIds.length ? `&created_by=${createdByIds}` : ""}
      ${suppliersIds && !!suppliersIds.length ? `&suppliers=${suppliersIds}` : ""}
      ${searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  getStockTransactionsExcel: async ({
    page,
    startDate,
    endDate,
    outletId,
    transactionType,
    stockUpReason,
    stockDownReason,
    createdByIds,
    suppliersIds,
    searchQuery,
  }: {
    page: number;
    startDate: string;
    endDate: String;
    outletId: number;
    transactionType: string;
    stockUpReason: string[];
    stockDownReason: string[];
    createdByIds: number[];
    suppliersIds: number[];
    searchQuery: string;
  }) =>
    http.get<StockTransactionResponse>(
      `stocks/transactions/?page=${page}&start_date=${startDate}&end_date=${endDate}${outletId !== 0 ? `&outlet=${outletId}` : ""
      }${transactionType !== "All History" ? `&type=${transactionType}` : ""}${stockUpReason && !!stockUpReason.length
        ? `&stock_up_reason=${stringifyArray(stockUpReason)}`
        : ""
      }${stockDownReason && !!stockDownReason.length
        ? `&stock_down_reason=${stringifyArray(stockDownReason)}`
        : ""

      }
      ${createdByIds && !!createdByIds.length ? `&created_by=${createdByIds}` : ""}
      ${suppliersIds && !!suppliersIds.length ? `&suppliers=${suppliersIds}` : ""}
      ${searchQuery ? `&search=${searchQuery}` : ""}
      
      &excel_download=true`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),
};

export default stockTransactionsApi;
