import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * createsAsyncThunk that handles rejection
 * https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * @param {string} name - a string action type value
 * @param {number} apiCall - the api request
 *
 * @return returns a standard Redux thunk action creator that handles rejection
 *
 * Example:
 * export const createOrganization = createThunkFromApi("organization/createOrganization",api.createOrganization);
 */

const createThunkFromApi = (name, apiCall, handleResponse) =>
  createAsyncThunk(name, async (args, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await apiCall(args); // Notice here you would need to adjust the way api calls work to take in a single object with values

      handleResponse && handleResponse(response, thunkAPI);

      if (args?.onThunkSuccess) args.onThunkSuccess();

      return response.data;
    } catch (error) {
      if (args?.onThunkCatch) args.onThunkCatch();

      return rejectWithValue(error.response.data);
    }
  });

export default createThunkFromApi;
