import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { ACTIVE_INVOICE_STATUSES } from "constants/invoice";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useAppointmentEditAccess from "hooks/useAppointmentEditAccess";
import useAppointmentSummary from "hooks/useAppointmentSummary";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectDepositById } from "store/selectors";
import { PeepPayLinkStatus } from "types/Deposit";

import { isAppointmentCompleted } from "../utils";

type AppointmentDetailsFooterProps = {
  appointmentId: number;
};

const AppointmentDetailsFooter = ({ appointmentId }: AppointmentDetailsFooterProps) => {
  const { t } = useTranslation("appointments");
  const { hasCheckout } = useSelector(selectAllPermissions);

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));
  const { canEditAppointment, isAppointmentEditable } = useAppointmentEditAccess({
    appointmentId: appointment?.id,
  });

  const deposit = useSelector(selectDepositById(appointment?.deposit?.id || 0));

  const peepPayLink = deposit?.peepPay;
  const hasPendingPeepPayLink = peepPayLink?.status === PeepPayLinkStatus.Pending;

  const shouldDisabledAppointmentCheckout = isAppointmentEditable && hasPendingPeepPayLink;
  const { openCheckoutModal, openCheckoutModalWithInvoiceDetailsMode } = useCheckoutModal();

  const { closeAppointmentDrawer, openAppointmentDrawerEditMode } = useAppointmentDrawer();

  const appointmentSummary = useAppointmentSummary({ appointmentId });

  const handleViewInvoice = useCallback(() => {
    const activeInvoices = appointment?.invoices?.filter((invoice) =>
      ACTIVE_INVOICE_STATUSES.includes(invoice.status)
    );

    if (activeInvoices?.length) {
      openCheckoutModalWithInvoiceDetailsMode({
        invoiceId: activeInvoices[0].id,
      });
      closeAppointmentDrawer();
    }
  }, [appointment?.invoices, closeAppointmentDrawer, openCheckoutModalWithInvoiceDetailsMode]);

  if (!appointment) return null;

  const hasUnselectedFlexServices = appointment.articles.some(
    (article) => article.flexService && !article.serviceVariant
  );

  const shouldShowPickAServiceButton =
    hasCheckout.editAccess && hasUnselectedFlexServices && isAppointmentEditable;

  const shouldShowCheckoutButton =
    hasCheckout.editAccess && isAppointmentEditable && !hasUnselectedFlexServices;

  const shouldShowViewInvoiceButton =
    hasCheckout.editAccess && isAppointmentCompleted(appointment.status);

  const handleCheckout = () => {
    openCheckoutModal({
      outletId: appointment.outlet,
      appointmentId: appointment.id,
    });

    closeAppointmentDrawer();
  };

  const handleFlexServiceSelection = () => {
    openAppointmentDrawerEditMode({
      appointmentId: appointment.id,
      outletId: appointment.outlet,
      clientId: appointment.client,
    });
  };

  const shouldShowOnlyCloseButton =
    !shouldShowCheckoutButton && !shouldShowViewInvoiceButton && !shouldShowPickAServiceButton;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" py={1}>
          {appointmentSummary}
        </Typography>
      </Grid>

      {shouldDisabledAppointmentCheckout && (
        <Grid item xs={12}>
          <FormHelperText error>{t("cannotCheckoutBecauseOfPendingPeepPayment")}</FormHelperText>
        </Grid>
      )}

      <Grid item xs={12} md={shouldShowOnlyCloseButton ? 12 : 6}>
        <Button fullWidth variant="outlined" onClick={closeAppointmentDrawer}>
          {t("close")}
        </Button>
      </Grid>

      {shouldShowCheckoutButton && (
        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            onClick={handleCheckout}
            variant="contained"
            disabled={shouldDisabledAppointmentCheckout}
          >
            {t("checkout")}
          </Button>
        </Grid>
      )}

      {shouldShowPickAServiceButton && (
        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            onClick={handleFlexServiceSelection}
            variant="contained"
            disabled={!canEditAppointment}
          >
            {t("pickAService")}
          </Button>
        </Grid>
      )}

      {shouldShowViewInvoiceButton && (
        <Grid item xs={12} md={6}>
          <Button fullWidth onClick={handleViewInvoice}>
            {t("viewInvoice")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentDetailsFooter;
