import { Box, Popover, Typography } from "@mui/material";
import { SectionTitle } from "components/common";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectAllOnlineBookingStatuses } from "store/selectors";
import { getAllOnlineBookingsStatuses } from "store/slices/onlineBookingStatus/onlineBookingStatusSlice";

import ManageOnlineBookingStatusButton from "./ManageOnlineBookingStatusButton";
import OnlineBookingStatusList from "./OnlineBookingStatusList";
import { OnlineBookingStatus } from "./types";
import { selectOrganization } from "store/organization/organizationSlice";
import { checkIsHomeServiceOrganization } from "helpers/industries";

const ManageOnlineBookingStatus = () => {
  const { t } = useTranslation("onlineBooking");
  const dispatch = useDispatch();

  const onlineBookingStatus = useSelector(selectAllOnlineBookingStatuses);

  const organization = useSelector(selectOrganization);

  const isHomeService = checkIsHomeServiceOrganization(organization?.industry);

  const [isOnlineBookingStatusOpen, setIsOnlineBookingStatusOpen] = useState(false);

  const [onlineBookingStatusAnchor, setOnlineBookingStatusAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    dispatch(getAllOnlineBookingsStatuses({}));
    setIsOnlineBookingStatusOpen(true);
    setOnlineBookingStatusAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setOnlineBookingStatusAnchor(null);
    setIsOnlineBookingStatusOpen(false);
  };

  const hasOnlineBookingStatus = onlineBookingStatus.length > 0;

  const getCumulativeOnlineBookingStatus = () => {
    if (onlineBookingStatus.every((outlet) => outlet.isAcceptingOnlineBookings))
      return OnlineBookingStatus.Active;
    else if (onlineBookingStatus.every((outlet) => !outlet.isAcceptingOnlineBookings))
      return OnlineBookingStatus.Inactive;

    return OnlineBookingStatus.PartiallyActive;
  };

  if (!hasOnlineBookingStatus) return null;

  const onlineBookingStatusForAllLocations = getCumulativeOnlineBookingStatus();

  return (
    <>
      <ManageOnlineBookingStatusButton
        onClick={handleOpen}
        onlineBookingStatusForAllLocations={onlineBookingStatusForAllLocations}
      />

      {isOnlineBookingStatusOpen && (
        <Popover
          open={isOnlineBookingStatusOpen}
          anchorEl={onlineBookingStatusAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: (theme) => theme.shadows.deepCard,
              borderRadius: (theme) => theme.spacing(1),
              maxWidth: (theme) => theme.spacing(32.5),
              padding: (theme) => theme.spacing(2),
            },
          }}
        >
          <SectionTitle>{t("onlineBookingStatus")}</SectionTitle>

          <OnlineBookingStatusList />

          <Box pt={2}>
            <Typography variant="primary" color="text.placeholder">
              {t(
                isHomeService
                  ? "homeServiceOnlineBookingStatusDescription"
                  : "onlineBookingStatusDescription"
              )}
            </Typography>
          </Box>
        </Popover>
      )}
    </>
  );
};

export default ManageOnlineBookingStatus;
