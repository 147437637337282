/**
 * Format the price to 3 decimal places, if it has decimals otherwise return the price as is without formatting it.
 *
 * @param price
 *
 * @returns formatted price
 */

export const getFormattedPrice = (price: string | number): number | string => {
  const parsedNumber = typeof price === "number" ? price : parseFloat(price);

  if (isNaN(parsedNumber)) return 0;
  const formattedNumber = parsedNumber.toFixed(3);

  const formattedPrice = parsedNumber % 1 === 0 ? parsedNumber : formattedNumber;

  return formattedPrice;
};

export default getFormattedPrice;
