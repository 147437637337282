import { Box, Button, Grid } from "@mui/material";
import useCheckoutModal from "hooks/useCheckoutModal";
import { useTranslation } from "react-i18next";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import InvoiceMoreOptionsButton from "./InvoiceMoreOptionsButton";

const InvoiceSideMenuActionsFooter = () => {
  const { t } = useTranslation("invoices");

  const { reset } = useCheckoutFormContext();
  const { closeCheckoutModal } = useCheckoutModal();

  const handleDoneButtonClick = () => {
    reset();
    closeCheckoutModal();
  };

  return (
    <Box width="100%" boxShadow="topDivider">
      <Grid container spacing={3} justifyContent="space-between" p={3}>
        <Grid item xs={6}>
          <InvoiceMoreOptionsButton />
        </Grid>

        <Grid item xs={6}>
          <Button fullWidth variant="contained" onClick={handleDoneButtonClick}>
            {t("done")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceSideMenuActionsFooter;
