import { addMethod, number } from "yup";

declare module "yup" {
  interface NumberSchema {
    requiredPositiveNumber(message?: string): NumberSchema;
  }
}

addMethod(
  number,
  "requiredPositiveNumber",
  function requiredPositiveNumber(message?: string) {
    return this.test("requiredPositiveNumber", message || "mixed.required", function (value) {
      // If no value is provided true is returned in similar fashion as string().email()
      if (!value && value !== 0) return true;

      const { path, createError } = this;

      if (value > 0) {
        return true;
      } else {
        return createError({ message: "mixed.required", path });
      }
    });
  }
);
