import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllActiveOutlets } from "store/outlets/outletsSlice";
import Outlet from "types/Outlet";

import FormSelectItem from "./FormSelectItem";
import PeepAccordion from "./PeepAccordion";

type FormLocationsSectionProps = {
  title?: string;
  description?: string;
  selectedOutletIds: number[];
  setSelectedOutletIds: (outletIds: number[]) => void;

  isSingleSelect?: boolean;
  shouldDisableOptions?: boolean;
  optionsDescription?: React.ReactNode;
};

/**
 * Passing `isSingleSelect` as true will make the component behave as a radio group
 * @param title
 * @param description
 * @param selectedOutletIds
 * @param setSelectedOutletIds
 * @param isSingleSelect
 * @param shouldDisableOptions
 * @param optionsDescription
 *
 * @returns
 */

export default function FormLocationsSection({
  title,
  description,
  selectedOutletIds,
  setSelectedOutletIds,
  isSingleSelect = false,
  shouldDisableOptions = false,
  optionsDescription,
}: FormLocationsSectionProps) {
  const { t } = useTranslation(["translation"]);

  const activeOutlets = useSelector(selectAllActiveOutlets) as Outlet[];

  const checkIsOutletSelected = (outletId: number) => selectedOutletIds.includes(outletId);

  const checkAreAllActiveOutletsSelected = () =>
    activeOutlets.every((outlet) => selectedOutletIds.includes(outlet.id));

  const handleClickSelectAllLocations = () => {
    if (checkAreAllActiveOutletsSelected()) {
      setSelectedOutletIds([]);
    } else {
      const allActiveOutletIds = activeOutlets.map((outlet) => outlet.id);
      setSelectedOutletIds(allActiveOutletIds);
    }
  };

  const handleSelectOutlet = (outletId: number) => {
    if (checkIsOutletSelected(outletId)) {
      const filteredOutlets = selectedOutletIds?.filter((id) => id !== outletId);
      setSelectedOutletIds(filteredOutlets);
    } else {
      const newOutlets = [...selectedOutletIds, outletId];
      setSelectedOutletIds(newOutlets);
    }
  };

  return (
    <PeepAccordion
      title={title || t("locations")}
      description={description}
      defaultExpanded
      withUnderline
    >
      <Box p={{ md: 3, xs: 2 }}>
        {optionsDescription}

        <Grid container alignItems="center" spacing={{ md: 3, xs: 2 }}>
          {!isSingleSelect && (
            <Grid item xs={12} md={6}>
              <FormSelectItem
                value={checkAreAllActiveOutletsSelected()}
                onChange={handleClickSelectAllLocations}
                label={t("selectAllLocations")}
              />
            </Grid>
          )}

          {activeOutlets.map((outlet) => (
            <Grid item xs={12} md={6} key={outlet.id}>
              <FormSelectItem
                value={checkIsOutletSelected(outlet.id)}
                onChange={() => handleSelectOutlet(outlet.id)}
                label={outlet.name}
                isSingleSelect={isSingleSelect}
                isDisabled={shouldDisableOptions}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </PeepAccordion>
  );
}
