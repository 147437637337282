import { Box } from "@mui/material";
import { useState } from "react";
import type { CheckoutLoyaltyCard } from "types/Appointment";

import InvoiceLoyaltyCard from "./InvoiceLoyaltyCard";
import InvoiceVouchersScrollArrows from "./InvoiceLoyaltyCardsScrollArrows";

type InvoiceLoyaltyCardsSectionProps = {
  loyaltyCards: CheckoutLoyaltyCard[];
  clientName?: string;
  clientPhoneNumber?: string;
};

const InvoiceLoyaltyCardsSection = ({
  loyaltyCards,
  clientName,
  clientPhoneNumber,
}: InvoiceLoyaltyCardsSectionProps) => {
  const [selectedLoyaltyCardIndex, setSelectedLoyaltyCardIndex] = useState(0);

  const shouldShowScrollArrows = loyaltyCards.length > 1;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={3}
      p={3}
      order={{ xs: 2, md: 1 }}
    >
      <InvoiceLoyaltyCard
        clientName={clientName}
        clientPhoneNumber={clientPhoneNumber}
        loyaltyCard={loyaltyCards[selectedLoyaltyCardIndex]}
      />

      {shouldShowScrollArrows && (
        <InvoiceVouchersScrollArrows
          shouldDisableLeftArrow={selectedLoyaltyCardIndex === 0}
          shouldDisableRightArrow={selectedLoyaltyCardIndex === loyaltyCards.length - 1}
          currentLoyaltyCardIndex={selectedLoyaltyCardIndex}
          setCurrentLoyaltyCardIndex={setSelectedLoyaltyCardIndex}
          loyaltyCardsLength={loyaltyCards.length}
        />
      )}
    </Box>
  );
};

export default InvoiceLoyaltyCardsSection;
