import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllServiceVariantsWithService } from "store/selectors";
import { Voucher } from "types/Voucher";
import { VoucherPurchase } from "types/VoucherPurchase";

type Vouchers = {
  vouchers: Voucher[];
  voucherPurchases?: never;
};

type VoucherPurchases = {
  vouchers?: never;
  voucherPurchases: VoucherPurchase[];
};

type NeitherVouchersNorVoucherPurchases = {
  vouchers?: never;
  voucherPurchases?: never;
};

type UseVouchersWithRedeemableItemsTitleProps =
  | Vouchers
  | VoucherPurchases
  | NeitherVouchersNorVoucherPurchases;

/**
 * This hook has been created to add the subtitle of the vouchers, because we cannot use translation t function inside redux selectors or inside regular functions, therefore, I felt the need to create a hook to handle this mess - The one and Only, Anfal The Great 💎
 *
 *
 * For now we only have services as redeemable items, but in the future we might have other redeemable items, we would have to modify this hook to be able to handle multiple redeemable items.
 *
 * We have multiple cases for the subtitle of the vouchers:
 * 1. If the voucher has all services, then the subtitle will be "All services"
 * 2. If the voucher has only one service, then the subtitle will be the name of the service
 * 3. If the voucher has more than one service, then the subtitle will be the number of services
 *
 *
 *
 */

export default function useVouchersWithRedeemableItemsTitle({
  vouchers,
  voucherPurchases,
}: UseVouchersWithRedeemableItemsTitleProps) {
  const { t } = useTranslation("checkout");

  if (!vouchers && !voucherPurchases)
    throw new Error("You must pass either vouchers or voucherPurchases to the hook");

  const allServiceVariantsWithService = useSelector(selectAllServiceVariantsWithService);

  const getServiceWithVariantTitle = (serviceVariantId: number) => {
    const serviceVariant = allServiceVariantsWithService.find(
      (serviceVariant) => serviceVariant?.id === serviceVariantId
    );

    if (!serviceVariant) return t("serviceNameUnavailable");

    const serviceVariantName = serviceVariant?.name || "";
    const serviceName = serviceVariant?.service?.name || "";

    if (serviceVariantName === serviceName) return serviceVariantName;
    else return `${serviceName} - ${serviceVariantName}`;
  };

  const getRedeemableItemsName = (voucher: Voucher | VoucherPurchase) => {
    // For now we only have services as redeemable items, the condition must be changed when we add more redeemable items
    if (voucher.hasAllServices) return t("allServices");
    else {
      if (voucher.serviceVariants.length === 1)
        return getServiceWithVariantTitle(voucher.serviceVariants[0]);
      else if (voucher.serviceVariants.length > 1)
        return `${voucher.serviceVariants.length} ${t("services")}`;

      return t("serviceNameUnavailable");
    }
  };

  if (vouchers) {
    const vouchersWithRedeemableItemsTitle = vouchers?.map((voucher) => ({
      ...voucher,
      redeemableItemsTitle: getRedeemableItemsName(voucher),
    }));
    return {
      vouchersWithRedeemableItemsTitle,
    };
  }

  if (voucherPurchases) {
    const voucherPurchasesWithRedeemableItemsTitle = voucherPurchases?.map((voucherPurchase) => ({
      ...voucherPurchase,
      redeemableItemsTitle: getRedeemableItemsName(voucherPurchase),
    }));

    return {
      voucherPurchasesWithRedeemableItemsTitle,
    };
  }

  return {};
}
