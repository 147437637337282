import { ServiceCategory } from "types/ServiceV2";
import http from "utils/http";

type ServiceCategoryArguments = Omit<ServiceCategory, "id">;

const serviceCategoriesApi = {
  getAllServiceCategories: async () =>
    http.get<ServiceCategory[]>(`organizations/v2/service_categories/`),

  createServiceCategory: async ({ data }: { data: ServiceCategoryArguments }) =>
    http.post<ServiceCategory>("organizations/v2/service_categories/", data),

  updateServiceCategory: async ({ id, data }: { id: number; data: ServiceCategoryArguments }) =>
    http.put<ServiceCategory>(`organizations/v2/service_categories/${id}/`, data),

  updateServiceCategoryPosition: async ({ data }: { data: ServiceCategory[] }) =>
    http.put<ServiceCategory[]>(`organizations/v2/update_service_category_positions/`, data),

  removeServiceCategory: async ({ id }: { id: number }) =>
    http.delete(`organizations/v2/service_categories/${id}/`),
};

export default serviceCategoriesApi;
