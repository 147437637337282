import { User } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddNewClientForm from "./AddNewClientForm";
import ClientsSearch from "./ClientsSearch";
import ExistingClientInfo from "./ExistingClientInfo";

export type NewClient = {
  firstName: string;
  lastName?: string;

  phoneNumber: string;
  phoneCountryCode: string;
};

export type EditClientInfo = NewClient & {
  id: number;
};

export type SetExistingClientId = (clientId: Nullable<number>) => void;
export type ClientSectionProps = {
  newClient?: Nullable<NewClient>;
  setNewClient?: (newClient: Nullable<NewClient>) => void;
  setUpdatedClientData?: (newClient: Nullable<EditClientInfo>) => void;

  existingClientId: Nullable<number>;
  setExistingClientId?: SetExistingClientId;

  outletId?: number;

  allowedToChangeClient: boolean;
  shouldShowNoClient?: boolean;

  error?: boolean;
  helperText?: string;

  shouldTriggerValidation: boolean;

  searchClientsSectionHeader?: React.ReactNode;
  addNewClientSectionHeader?: React.ReactNode;

  shouldShowTags?: boolean;
  disablePortalForAutocomplete?: boolean;
  canEditClientDetails?: boolean;

  existingClientActionButton?: React.ReactNode;
  onClientReset?: () => void;
};

function ClientSection({
  newClient,
  setNewClient,
  setUpdatedClientData,

  existingClientId,
  setExistingClientId,

  outletId,

  allowedToChangeClient,
  shouldShowNoClient = false,

  error = false,
  helperText = "",

  shouldTriggerValidation = false,

  searchClientsSectionHeader,
  addNewClientSectionHeader,

  shouldShowTags = true,
  disablePortalForAutocomplete = true,
  // The ability to open edit client name, phone number form
  canEditClientDetails = false,

  existingClientActionButton,
  onClientReset,
}: ClientSectionProps) {
  const { t } = useTranslation(["appointments", "checkout"]);

  const [isClientFormOpen, setIsClientFormOpen] = useState(false);

  if (isClientFormOpen && !setNewClient)
    throw new Error("setNewClient is required to open the client form.");

  const activateClientForm = (searchQuery?: string) => {
    const isPhoneNumber = Number(searchQuery);

    // NOTE: We need to check if there is no existing client id so that we do not override the existing client data on the edit client form
    !existingClientId &&
      setNewClient &&
      setNewClient({
        phoneNumber: isPhoneNumber && searchQuery ? searchQuery : "",
        phoneCountryCode: "KW",
        firstName: "",
      });

    setIsClientFormOpen(true);
  };

  const resetClient = () => {
    if (onClientReset) onClientReset();
    else {
      setExistingClientId && setExistingClientId(null);
      setNewClient && setNewClient(null);

      setIsClientFormOpen(false);
    }
  };

  if (isClientFormOpen && setNewClient && newClient) {
    return (
      <AddNewClientForm
        clientId={existingClientId || 0}
        setUpdatedClientData={setUpdatedClientData}
        newClient={newClient}
        setNewClient={setNewClient}
        handleChangeClient={resetClient}
        shouldTriggerValidation={shouldTriggerValidation}
        addNewClientSectionHeader={addNewClientSectionHeader}
      />
    );
  }

  if (shouldShowNoClient && !existingClientId)
    return <Typography variant="h2">{t("checkout:walkIn")}</Typography>;

  return (
    <Box minHeight="10%" maxHeight="30%">
      {existingClientId ? (
        <ExistingClientInfo
          allowEdit={canEditClientDetails}
          clientId={existingClientId}
          outletId={outletId}
          handleChangeClient={resetClient}
          showChangeClientButton={allowedToChangeClient}
          shouldShowTags={shouldShowTags}
          activateClientForm={activateClientForm}
          existingClientActionButton={existingClientActionButton}
        />
      ) : (
        <>
          {searchClientsSectionHeader || (
            <Box display="flex" alignItems="center" pb={2}>
              <User sx={{ mr: 1 }} />

              <Typography variant="h2">{t("appointments:chooseClient")}</Typography>
            </Box>
          )}

          {setExistingClientId && (
            <ClientsSearch
              setSelectedClientId={setExistingClientId}
              activateNewClientForm={activateClientForm}
              error={error}
              helperText={helperText}
              disablePortalForAutocomplete={disablePortalForAutocomplete}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default memo(ClientSection);
