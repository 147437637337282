import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { packagesAdapter } from "./packagesSlice";

const { selectById, selectAll } = packagesAdapter.getSelectors(
  (state: RootState) => state.packagesV2
);

export const selectAllPackages = (state: RootState) => selectAll(state);

export const selectAllActivePackages = (state: RootState) =>
  selectAllPackages(state).filter((_package) => !_package.deprecated);

export const selectPackageById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const isPackageRequestPending = (state: RootState) =>
  state.packagesV2.isRequestPending === SliceStatus.LOADING;


export const selectPackagesByCategoryId = (categoryId: string | number) => (state: RootState) => {
  const allPackages = selectAllActivePackages(state);

  return allPackages.filter((pkg) => pkg.category === Number(categoryId));
};
