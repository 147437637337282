import type { RootState } from "store";

import { selectOnlineServicesByIndustryCategory } from "../servicesV2/servicesSelectors";
import { SliceStatus } from "../utils";
import { industryCategoryAdapter } from "./industryCategoriesSlice";

const { selectById, selectAll } = industryCategoryAdapter.getSelectors(
  (state: RootState) => state.industryCategories
);

export const selectIndustryCategoryById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectAllIndustryCategories = selectAll;

export const selectAllIndustryCategoriesWithAllData =
  (outletId?: Nullable<number>) => (state: RootState) => {
    const allCategories = selectAll(state);
    return allCategories.map((category) => {
      const filteredServices = selectOnlineServicesByIndustryCategory(category.id)(state).filter(
        (service) => (outletId ? service.outlets.includes(outletId) : true)
      );

      return {
        ...category,
        services: filteredServices,
      };
    });
  };

export const selectIndustryCategoriesIsLoading = (state: RootState) =>
  state.industryCategories.status === SliceStatus.LOADING;
