import ResponsiveDetailsModal from "components/common/ResponsiveDetailsModal";
import ShowDetailsWithLogsModal from "components/common/ShowDetailsWithLogsModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectVoucherWithDetailsById } from "store/selectors";
import { BasicDetailsDataType } from "types/DetailsWithLogs";
import { UnNormalizedVoucher } from "types/Voucher";

import { getActiveVoucherDetailsData } from "./helpers";

type VouchersDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  voucherId: number;
};

const VouchersDetailsModal = ({ isOpen, onClose, voucherId }: VouchersDetailsModalProps) => {
  const { t } = useTranslation("vouchers");

  const voucherDetails = useSelector(
    selectVoucherWithDetailsById(voucherId)
  ) as UnNormalizedVoucher;

  const activeVoucherDetailsData: BasicDetailsDataType[] = getActiveVoucherDetailsData(
    voucherDetails,
    t
  );

  return (
    <ResponsiveDetailsModal isOpen={isOpen} onClose={onClose}>
      <ShowDetailsWithLogsModal
        modalTitle={t("details")}
        detailsSubTitle={t("voucherDetails").toUpperCase()}
        detailsData={activeVoucherDetailsData}
        onClose={onClose}
      />
    </ResponsiveDetailsModal>
  );
};

export default VouchersDetailsModal;
