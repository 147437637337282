import Role from "types/Role";
import http from "utils/http";

type PermissionsResponse = Role;

const myPermissionsApi = {
  getMyPermissions: async () => http.get<PermissionsResponse>(`roles/role/`),
};

export default myPermissionsApi;
