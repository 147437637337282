import { setLocale } from "yup";

setLocale({
  mixed: {
    notType: (values) => ({ key: "mixed.notType", values }),
    required: (values) => ({ key: "mixed.required", values }),
  },
  string: {
    email: (values) => ({ key: "string.email", values }),
    min: (values) => ({ key: "string.min", values }),
  },
  number: {
    min: (values) => ({ key: "number.min", values }),
  },
});
