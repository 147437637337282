import stringifyArray from "helpers/stringifyArray";
import { Promotion, PromotionDetail, PromotionSchedule } from "types/promotions";
import http from "utils/http";

type PromotionResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  results: Promotion[];
};

type PromotionCreateOrUpdateBaseFields = Omit<PromotionDetail, "id" | "schedule">;

export type PromotionCreateOrUpdateArguments = Optional<
  PromotionCreateOrUpdateBaseFields,
  "description" | "serviceVariants" | "packageVariants" | "products" | "globalPromotionDiscounts"
>;

export type ScheduleUpdatePromotionArguments = Omit<PromotionSchedule, "id" | "status">;

const promotionsApi = {
  getPromotions: async ({
    page,
    outletId,
    statusSelection,
    ordering,
    searchQuery,
  }: {
    page: number;
    outletId: number;
    statusSelection: string[];
    ordering: string;
    searchQuery: string;
  }) =>
    http.get<PromotionResponse>(
      `promotions/promotions/?page=${page}${
        statusSelection && !!statusSelection.length
          ? `&status=${stringifyArray(statusSelection.join().split(","))}`
          : ""
      }${outletId !== 0 ? `&outlet=${outletId}` : ""}${ordering ? `&order_by=${ordering}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  createPromotion: async ({ data }: { data: PromotionCreateOrUpdateArguments }) =>
    http.post<Promotion>(`promotions/promotions/`, data),

  updatePromotion: async ({ id, data }: { id: number; data: PromotionCreateOrUpdateArguments }) =>
    http.put<Promotion>(`promotions/promotions/${id}/`, data),

  deletePromotion: async ({ id }: { id: number }) => http.delete(`promotions/promotions/${id}/`),

  scheduleUpdatePromotion: async ({
    id,
    data,
  }: {
    id: number;
    data: ScheduleUpdatePromotionArguments;
  }) => http.put(`promotions/schedule/${id}/`, data),

  getPromotionDetails: async ({ id }: { id: number }) =>
    http.get<PromotionDetail>(`promotions/promotions/${id}/`),
};

export default promotionsApi;
