import { ChipProps, Chip as MuiChip, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

type StatusChipProps = ChipProps & {
  label: ChipProps["label"];
  statusColor: ChipProps["color"] | string;
};

const StatusChip = ({ label, statusColor, ...props }: StatusChipProps) => {
  return <Chip label={label} statusColor={statusColor} {...props} />;
};

export default StatusChip;

const Chip = styled(
  MuiChip,
  dontForwardProps("statusColor")
)<StatusChipProps>(({ statusColor, size, theme }) => ({
  backgroundColor: statusColor,
  borderRadius: theme.spacing(1),

  minWidth: theme.spacing(10),
  minHeight: size === "small" ? theme.spacing(3) : theme.spacing(4.5),

  fontWeight: 500,
  fontSize: "0.75rem",
  lineHeight: "20px",
  textTransform: "capitalize",

  [theme.breakpoints.down("md")]: {
    minWidth: theme.spacing(8.5),
    minHeight: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: "0.688rem",
    height: "auto",
  },
}));
