import { Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AuthBackground } from "./common";

const ErrorBoundaryFallback = () => {
  const { t } = useTranslation("errors");

  return (
    <AuthBackground>
      <Container maxWidth="sm">
        <Stack
          maxHeight="100vh"
          justifyContent="space-around"
          alignItems="center"
          spacing={3}
          pt={{ md: 12, xs: 8 }}
        >
          <Typography variant="h1" fontSize={{ md: "2.5rem", xs: "2rem" }} textAlign="center">
            {t("somethingWentWrong")}
          </Typography>

          <img alt="Nothing" src="/images/LatoofaWriting.svg" width="200px" />

          <Typography variant="h3" textAlign="center" display="block">
            {t("reloadThePage")}
          </Typography>
        </Stack>
      </Container>
    </AuthBackground>
  );
};

export default ErrorBoundaryFallback;
