import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ClientWallet } from "types/ClientWallet";

import { SliceStatus } from "../utils";
import api from "./clientsWalletsApi";

export const clientsWalletsAdapter = createEntityAdapter<ClientWallet>({
  selectId: (clientWallet) => clientWallet.id,
});

const initialState = clientsWalletsAdapter.getInitialState<{
  status: SliceStatus;
  detailStatus: SliceStatus;
  count: number;
  totalCredits: number;
  totalPages: number;
  next: string;
  previous: string;
  results: ClientWallet[];
}>({
  status: SliceStatus.IDLE,
  count: 0,
  totalCredits: 0,
  totalPages: 0,
  detailStatus: SliceStatus.IDLE,
  next: "",
  previous: "",
  results: [],
});

export const getClientsWallets = createThunkFromApiWithType(
  "clientsWallets/getClientsWallets",
  api.getClientsWallets
);

export const appendClientsWallets = createThunkFromApiWithType(
  "clientsWallets/appendClientsWallets",
  api.getClientsWallets
);

export const clientsWalletsSlice = createSlice({
  name: "clientsWallets",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Get all Clients Wallets reducers
    reducers.addCase(getClientsWallets.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getClientsWallets.fulfilled, (state, { payload }) => {
      clientsWalletsAdapter.setAll(
        state,
        payload.results.filter((wallet) => wallet.client.id !== 0)
      );

      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.totalCredits = payload.totalCredits;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getClientsWallets.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Append clients wallets reducers
    reducers.addCase(appendClientsWallets.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendClientsWallets.fulfilled, (state, { payload }) => {
      clientsWalletsAdapter.upsertMany(
        state,
        payload.results.filter((wallet) => wallet.client.id !== 0)
      );

      state.totalPages = payload.totalPages;
      state.count = payload.count;
      state.totalCredits = payload.totalCredits;

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendClientsWallets.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default clientsWalletsSlice.reducer;
