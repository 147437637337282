/**
 * This function takes the response and then downloads the file
 *
 * @param response
 */

function downloadResponse(response: any) {
  const binaryData = [];

  binaryData.push(response.data);

  const filename = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(
    new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );

  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();
}

export default downloadResponse;
