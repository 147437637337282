import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { ProductBrandDetails } from "types/Brand";

import {
  appendAllProductsData,
  createProduct,
  createStockDown,
  createStockTransfer,
  createStockUp,
  getAllProductsData,
  getProductData,
  updateProduct,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";
import api from "./productBrandsApi";

export const productBrandsAdapter = createEntityAdapter<ProductBrandDetails>({
  selectId: (productBrand) => productBrand.id,
});

const initialState = productBrandsAdapter.getInitialState<{
  status: SliceStatus;
  next: string;
  previous: string;
  totalPages: number;
  results: ProductBrandDetails[];
  page: number[];
}>({
  status: SliceStatus.IDLE,
  next: "",
  previous: "",
  totalPages: 0,
  results: [],
  page: [],
});

export const createProductBrand = createThunkFromApiWithType(
  "productBrands/createProductBrand",
  api.createProductBrand
);

export const getAllProductBrands = createThunkFromApiWithType(
  "productBrands/getAllProductBrands",
  api.getAllProductBrands
);

export const getBrands = createThunkFromApiWithType(
  "productBrands/getProductBrands",
  api.getBrands
);

export const appendBrands = createThunkFromApiWithType(
  "productBrands/appendAllBrands",
  api.getBrands
);

export const getProductBrandDetails = createThunkFromApiWithType(
  "productBrands/getProductBrandDetails",
  api.getProductBrandDetails
);

export const updateBrand = createThunkFromApiWithType("productBrands/updateBrand", api.updateBrand);

export const deleteBrand = createThunkFromApiWithType("productBrands/deleteBrand", api.deleteBrand);

export const productBrandsSlice = createSlice({
  name: "productBrands",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Get all brief brands reducers
    reducers.addCase(getAllProductBrands.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductBrands.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductBrands.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // Get all paginated brands reducers
    reducers.addCase(getBrands.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getBrands.fulfilled, (state, { payload }) => {
      productBrandsAdapter.setAll(state, payload.results.results);

      state.page = payload.results.results.map((brand) => brand.id);
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(getBrands.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Append brands reducers
    reducers.addCase(appendBrands.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendBrands.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertMany(state, payload.results.results);

      state.page = [...state.page, ...payload.results.results.map((brand) => brand.id)];
      state.status = SliceStatus.IDLE;
      state.totalPages = payload.totalPages;
    });

    reducers.addCase(appendBrands.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // Create brand reducers
    reducers.addCase(createProductBrand.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProductBrand.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertOne(state, payload);

      state.page = [payload.id, ...state.page];
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProductBrand.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Update brand reducer
    reducers.addCase(updateBrand.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateBrand.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateBrand.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // Delete brand reducer
    reducers.addCase(deleteBrand.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteBrand.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        productBrandsAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(deleteBrand.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    // end
    reducers.addCase(getProductData.fulfilled, (state, { payload: { brand } }) => {
      if (brand?.id) productBrandsAdapter.upsertOne(state, brand);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getProductData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { brand } = payload;

      if (brand?.id) productBrandsAdapter.upsertOne(state, brand);
    });

    reducers.addCase(getAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductsData.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertMany(state, payload.results.brands);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductsData.fulfilled, (state, { payload }) => {
      productBrandsAdapter.upsertMany(state, payload.results.brands);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProduct.fulfilled, (state, { payload: { brand } }) => {
      if (brand && brand?.id) productBrandsAdapter.upsertOne(state, brand);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { brand } }) => {
      if (brand && brand.id) productBrandsAdapter.upsertOne(state, brand);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { brand } }) => {
      if (brand && brand.id) productBrandsAdapter.upsertOne(state, brand);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { brand } }) => {
      if (brand && brand.id) productBrandsAdapter.upsertOne(state, brand);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default productBrandsSlice.reducer;
