import { Box } from "@mui/material";

import InvoiceSwitcherDot from "./InvoiceSwitcherDot";

type SwitcherDotOption = {
  id: number;
  onClick: () => void;
  isActive: boolean;
};

type InvoiceSwitcherDotsProps = {
  options: SwitcherDotOption[];
  isVertical?: boolean;
};

const InvoiceSwitcherDots = ({ options, isVertical = true }: InvoiceSwitcherDotsProps) => {
  return (
    <Box
      display="flex"
      flexDirection={isVertical ? "column" : "row"}
      gap={1}
      alignItems={isVertical ? "center" : "flex-start"}
    >
      {options.map((option) => (
        <InvoiceSwitcherDot
          key={option.id}
          isActive={option.isActive}
          handleClick={option.onClick}
        />
      ))}
    </Box>
  );
};

export default InvoiceSwitcherDots;
