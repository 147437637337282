import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import {
  createPackage,
  deletePackage,
  updatePackage,
  updatePackagePosition,
} from "../packages/packagesSlice";
import api from "./packageCategoriesApi";

const packageCategoryAdapter = createEntityAdapter();

const initialState = packageCategoryAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
  optimisticCache: null,
});

const { selectAll } = packageCategoryAdapter.getSelectors((state) => state.packageCategories);

export const getAllPackageCategories = createThunkFromApi(
  "packageCategories/getAllPackageCategories",
  api.getAllPackageCategories
);

export const getPackageCategory = createThunkFromApi(
  "packageCategories/getPackageCategory",
  api.getPackageCategory
);

export const updatePackageCategoryPosition = createThunkFromApi(
  "packageCategory/updatePackageCategoryPosition",
  api.updatePackageCategoryPosition
);

export const createPackageCategory = createThunkFromApi(
  "packageCategories/createPackageCategory",
  api.createPackageCategory
);

export const updatePackageCategory = createThunkFromApi(
  "packageCategories/updatePackageCategory",
  api.updatePackageCategory
);

export const deletePackageCategory = createThunkFromApi(
  "packageCategories/removePackageCategory",
  api.removePackageCategory
);

export const packageCategoriesSlice = createSlice({
  name: "packageCategories",
  initialState,

  extraReducers: {
    [getAllPackageCategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllPackageCategories.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = "";
      packageCategoryAdapter.setAll(state, payload);
    },
    [getAllPackageCategories.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },

    [getPackageCategory.fulfilled]: (state, { payload }) => {
      packageCategoryAdapter.setOne(state, payload);
    },

    [updatePackageCategoryPosition.pending]: (state, { meta: { arg } }) => {
      state.optimisticCache = selectAll({ packageCategories: state });

      packageCategoryAdapter.upsertMany(state, arg);
    },
    [updatePackageCategoryPosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updatePackageCategoryPosition.rejected]: (state) => {
      packageCategoryAdapter.setAll(state, state.optimisticCache);
      state.optimisticCache = null;
    },

    // [updatePackagePosition.pending]: (state, { meta: { arg } }) => {
    //   const categoryId = arg.length ? arg[0].category : null;
    //   state.optimisticCache = selectPackageCategoryById(categoryId)({
    //     packageCategories: state,
    //   });

    //   const modifiedPackages = state.optimisticCache.packages.map((pkg) => ({
    //     ...pkg,
    //     position: arg.find((packagePosition) => packagePosition.id === pkg.id).position,
    //   }));

    //   packageCategoryAdapter.upsertOne(state, {
    //     id: categoryId,
    //     packages: modifiedPackages,
    //   });
    // },
    [updatePackagePosition.fulfilled]: (state) => {
      state.optimisticCache = null;
    },
    [updatePackagePosition.rejected]: (state) => {
      packageCategoryAdapter.setOne(state, state.optimisticCache);
      state.optimisticCache = null;
    },

    [createPackageCategory.pending]: (state) => {
      state.isRequestPending = true;
    },
    [createPackageCategory.fulfilled]: (state, { payload }) => {
      packageCategoryAdapter.addOne(state, payload);
      state.isRequestPending = false;
    },
    [createPackageCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [updatePackageCategory.pending]: (state) => {
      state.isRequestPending = true;
    },
    [updatePackageCategory.fulfilled]: (state, { payload }) => {
      packageCategoryAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
      state.isRequestPending = false;
    },
    [updatePackageCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },
    [deletePackageCategory.pending]: (state) => {
      state.isRequestPending = true;
    },
    [deletePackageCategory.fulfilled]: (state, { meta: { arg } }) => {
      packageCategoryAdapter.removeOne(state, arg);
      state.isRequestPending = false;
    },
    [deletePackageCategory.rejected]: (state) => {
      state.isRequestPending = false;
    },

    [createPackage.fulfilled]: (state, { payload }) => {
      const categoryToAddPackage = packageCategoryAdapter
        .getSelectors()
        .selectById(state, payload.category);

      const newCategoryPackages = [...categoryToAddPackage.packages, payload];

      packageCategoryAdapter.updateOne(state, {
        id: payload.category,
        changes: { ...categoryToAddPackage, packages: newCategoryPackages },
      });
    },

    [updatePackage.fulfilled]: (state, { payload }) => {
      // handle the cases when we don't update category
      if (payload.category === payload.oldCategory || !payload.oldCategory) {
        const categoryToUpdate = packageCategoryAdapter
          .getSelectors()
          .selectById(state, payload.category);

        packageCategoryAdapter.updateOne(state, {
          id: payload.category,
          changes: {
            ...categoryToUpdate,
            packages: categoryToUpdate.packages.map((pkg) => {
              if (pkg.id === payload.id) return payload;
              return pkg;
            }),
          },
        });
      } else {
        const oldCategoryToUpdate = packageCategoryAdapter
          .getSelectors()
          .selectById(state, payload.oldCategory);

        const newCategoryToUpdate = packageCategoryAdapter
          .getSelectors()
          .selectById(state, payload.category);

        packageCategoryAdapter.updateOne(state, {
          id: payload.oldCategory,
          changes: {
            ...oldCategoryToUpdate,
            packages: oldCategoryToUpdate.packages.filter((pkg) => pkg.id !== payload.id),
          },
        });

        packageCategoryAdapter.updateOne(state, {
          id: payload.category,
          changes: {
            ...newCategoryToUpdate,
            packages: [...newCategoryToUpdate.packages, payload],
          },
        });
      }
    },

    [deletePackage.fulfilled]: (state, { payload: { id, categoryId } }) => {
      const categoryToDeletePackage = packageCategoryAdapter
        .getSelectors()
        .selectById(state, categoryId);

      const modifiedPackages = categoryToDeletePackage.packages.filter((pkg) => pkg.id !== id);

      packageCategoryAdapter.updateOne(state, {
        id: categoryId,
        changes: {
          ...categoryToDeletePackage,
          packages: modifiedPackages,
        },
      });
    },
  },
});

export default packageCategoriesSlice.reducer;
