import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { AppointmentDeposit } from "types/Deposit";

import { SliceStatus } from "../utils";
import api from "./depositsApi";

export const depositsAdapter = createEntityAdapter<AppointmentDeposit>({
  selectId: (deposit: AppointmentDeposit) => deposit.id,
});

const initialState = depositsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const createDeposit = createThunkFromApiWithType(
  "deposits/createDeposit",
  api.createDeposit
);

export const updateDeposit = createThunkFromApiWithType(
  "deposits/updateDeposit",
  api.updateDeposit
);

export const depositsSlice = createSlice({
  name: "deposits",
  initialState,

  reducers: {
    upsertDeposits(state, { payload }) {
      depositsAdapter.upsertOne(state, payload);
    },
  },

  extraReducers: (reducers) => {
    reducers.addCase(createDeposit.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createDeposit.fulfilled, (state, { payload }) => {
      depositsAdapter.upsertOne(state, payload.deposit);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createDeposit.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateDeposit.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      updateDeposit.fulfilled,
      (
        state,
        {
          payload,
          meta: {
            arg: { id },
          },
        }
      ) => {
        depositsAdapter.removeOne(state, id);

        state.status = SliceStatus.IDLE;
      }
    );

    reducers.addCase(updateDeposit.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export const { upsertDeposits } = depositsSlice.actions;

export default depositsSlice.reducer;
