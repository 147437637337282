import { SubscriptionPurchase } from "types/SubscriptionPurchase";
import http from "utils/http";

type SubscriptionPurchasesResponse = {
  results: {
    results: SubscriptionPurchase[];
  };
  next: Nullable<string>;
  previous: Nullable<string>;
  totalPages: number;
};

const subscriptionPurchasesApi = {
  getSubscriptionPurchases: async ({
    page,
    ordering,
    searchQuery,
  }: {
    page: number;
    ordering: string;
    searchQuery: string;
  }) =>
    http.get<SubscriptionPurchasesResponse>(
      `subscriptions/purchases/?page=${page}${ordering ? `&order_by=${ordering}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),
  extendSubscriptionPurchase: async ({
    id,
    validityInDays,
  }: {
    id: number;
    validityInDays: number;
  }) => http.put(`/subscriptions/extend_purchase/${id}/`, { validityInDays }),

  getSubscriptionPurchasesExcel: async ({
    page,
    searchQuery,
  }: {
    page: number;
    searchQuery: string;
  }) =>
    http.get<SubscriptionPurchasesResponse>(
      `subscriptions/purchases/?page=${page}${
        searchQuery ? `&search=${searchQuery}` : ""
      }&excel_download=true`,
      {
        responseType: "arraybuffer",
        transformRequest: [],
        transformResponse: [],
      }
    ),
};

export default subscriptionPurchasesApi;
