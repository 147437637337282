import { Box, Stack } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import useIsMobileView from "hooks/useIsMobileView";
import { useSelector } from "store";
import {
  selectInvoiceById,
  selectIsCheckoutInvoiceLoading,
} from "store/slices/checkoutInvoices/checkoutInvoicesSelector";
import { InvoiceStatus } from "types/Checkout";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import InvoiceLoyaltyCardSection from "./InvoiceLoyaltyCardSection";
import InvoicePurchasedVouchersSection from "./InvoicePurchasedVouchersSection";
import InvoiceSideMenuActionsFooter from "./InvoiceSideMenuActionsFooter";
import InvoiceStatusDetailsSection from "./InvoiceStatusDetailsSection";
import InvoiceTabs from "./InvoiceTabs";

const InvoiceSideMenuActionsSection = () => {
  const isMobileView = useIsMobileView();
  const { invoiceId } = useCheckoutModal();

  const { shouldShowInvoiceTabs } = useCheckoutFormContext();

  const isInvoiceLoading = useSelector(selectIsCheckoutInvoiceLoading);
  const invoice = useSelector(selectInvoiceById(invoiceId ?? -1));

  const loyaltyCards = invoice?.loyaltyCards || [];
  const hasLoyaltyCards = Boolean(loyaltyCards.length);

  const { balance } = useCheckoutFormContext();

  if (isInvoiceLoading) return <CentredSpinnerBox />;

  if (!invoice) return null;

  const hasVoucherPurchase =
    invoice.voucherPurchases &&
    invoice.voucherPurchases.length > 0 &&
    invoice.status === InvoiceStatus.Paid;

  return (
    <>
      <Stack gap={3} flexGrow={1} overflow="scroll">
        {hasVoucherPurchase && (
          <Box boxShadow="divider">
            <InvoicePurchasedVouchersSection
              client={invoice.client}
              voucherPurchases={invoice?.voucherPurchases ?? []}
            />
          </Box>
        )}

        <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} m={3}>
          <InvoiceStatusDetailsSection invoice={invoice} invoiceBalanceBasedOnPayments={balance} />
        </Box>

        {shouldShowInvoiceTabs && <InvoiceTabs />}

        {hasLoyaltyCards && (
          <InvoiceLoyaltyCardSection
            clientPhoneNumber={invoice.clientPhoneNumber}
            clientName={invoice.clientFullName}
            loyaltyCards={loyaltyCards}
          />
        )}
      </Stack>

      {/* For Mobile It will appear as FullWidth modal footer on src/modals/CheckoutModal/CheckoutModal.tsx */}
      {!isMobileView && <InvoiceSideMenuActionsFooter />}
    </>
  );
};

export default InvoiceSideMenuActionsSection;
