import { Box, Typography } from "@mui/material";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { DateTime } from "luxon";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { memo, useContext, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectActivePromotionsLoading,
  selectLatestPackageVariantActivePromotionByPackageVariantId,
  selectPackageVariantById,
} from "store/selectors";

import SlotForm from "./SlotForm";

type PackageArticleItemProps = { articleIndex: number; addNewArticle: () => void };

function PackageArticleItem({ articleIndex, addNewArticle }: PackageArticleItemProps) {
  const { watch, setValue } = useFormContext();

  const { outletId, date } = useContext(CalendarPageContext);

  const today = DateTime.now();

  const isDateInThePast = date.endOf("day") < today.endOf("day");

  const formattedIsoDate = date.toFormat("yyyy-MM-dd");

  const article = watch(`articles.${articleIndex}`);

  const pkg = useSelector(selectPackageVariantById(article?.packageVariantId));

  const packageVariantLatestPromotion = useSelector(
    selectLatestPackageVariantActivePromotionByPackageVariantId(article?.packageVariantId || "")
  );

  const arePackageVariantLatestPromotionsFetchedLoading = useSelector(
    selectActivePromotionsLoading(outletId, formattedIsoDate)
  );

  const articlePromotion = article?.promotion;

  const isEditingAppointment = !!useWatch({ name: "appointmentId" });

  useEffect(() => {
    if (!isEditingAppointment) {
      if (article?.packageVariant) {
        if (
          packageVariantLatestPromotion &&
          !arePackageVariantLatestPromotionsFetchedLoading &&
          !isDateInThePast
        ) {
          if (articlePromotion !== packageVariantLatestPromotion.promotion) {
            setValue(`articles.${articleIndex}.promotion`, packageVariantLatestPromotion.promotion);
            setValue(
              `articles.${articleIndex}.finalPrice`,
              getFinalPriceAfterDiscount({
                originalPrice: article.price,
                discountOption: packageVariantLatestPromotion.discountType,
                discountValue: packageVariantLatestPromotion.value,
              })
            );
          }
        } else if (article && articlePromotion !== null) {
          setValue(`articles.${articleIndex}.promotion`, null);
          setValue(`articles.${articleIndex}.finalPrice`, article.price);
        }
      }
    }
  }, [
    isEditingAppointment,
    arePackageVariantLatestPromotionsFetchedLoading,
    article,
    articleIndex,
    articlePromotion,
    isDateInThePast,
    packageVariantLatestPromotion,
    setValue,
  ]);

  return (
    <Box bgcolor="bg.secondary" p={3} borderRadius={1}>
      <Typography variant="h4">{pkg?.name}</Typography>

      {article?.slots.map(({ serviceVariantId }: any, slotIndex: number) => (
        <Box key={serviceVariantId} mt={4}>
          <SlotForm
            articleIndex={articleIndex}
            slotIndex={slotIndex}
            disableVariantField
            addNewArticle={addNewArticle}
          />
        </Box>
      ))}
    </Box>
  );
}

export default memo(PackageArticleItem);
