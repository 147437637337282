import { InputAdornment, Typography } from "@mui/material";

import NumberField, { NumberFieldProps } from "./NumberField";

export type DiscountFieldProps = Omit<
  NumberFieldProps,
  "min" | "max" | "maxDecimalPlaces" | "fallbackValue"
>;

const DiscountField = ({ ...discountFieldProps }: DiscountFieldProps) => {
  return (
    <NumberField
      {...discountFieldProps}
      max={100}
      min={0}
      maxDecimalPlaces={2}
      fallbackValue={0}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              lineHeight="24px"
              color="text.placeholder"
            >
              %
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DiscountField;
