import { Grid } from "@mui/material";

type InvoiceArticleRowProps = {
  quantityColumnContent: JSX.Element;
  itemsDescriptionColumnContent: JSX.Element;
  priceColumnContent: JSX.Element;
};

const InvoiceArticleRow = ({
  quantityColumnContent,
  itemsDescriptionColumnContent,
  priceColumnContent,
}: InvoiceArticleRowProps) => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={2.5}>
        {quantityColumnContent}
      </Grid>

      <Grid item xs={6}>
        {itemsDescriptionColumnContent}
      </Grid>

      <Grid item xs={3.5}>
        {priceColumnContent}
      </Grid>
    </Grid>
  );
};

export default InvoiceArticleRow;
