import { RootState } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";
import { selectPaymentTypeById } from "store/paymentType/paymentTypesSlice";
import { UnNormalizedExpense } from "types/Expense";
import Outlet from "types/Outlet";

import { selectExpenseTypeById } from "../expenseTypes/expenseTypesSelectors";
import { SliceStatus } from "../utils";
import { expensesAdapter } from "./expensesSlice";

export const selectExpenseLoading = (state: RootState) =>
  state.expenses.status === SliceStatus.LOADING;

export const selectExpensesTotalPages = (state: RootState) => state.expenses.totalPages;

export const selectExpensesTotalAmount = (state: RootState) => state.expenses.totalAmount;

export const selectExpensesCount = (state: RootState) => state.expenses.count;

export const selectExpenseState = (state: RootState) => {
  return state.expenses;
};

const { selectAll, selectById } = expensesAdapter.getSelectors(selectExpenseState);

export const selectAllExpenses = selectAll;

export const selectExpenseById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectAllExpensesWithDetails = (state: RootState): UnNormalizedExpense[] => {
  const expenses = selectAll(state);

  return expenses.map((expense) => ({
    ...expense,
    outlet: selectOutletById(expense.outlet)(state) as Outlet,
    paymentType: selectPaymentTypeById(expense.paymentType)(state) as PaymentMethodType,
    expenseType: selectExpenseTypeById(expense.expenseType)(state),
  }));
};

export const selectExpensesWithDetailsById =
  (id: number | string) =>
  (state: RootState): UnNormalizedExpense | undefined => {
    const expense = selectById(state, id);

    if (!!expense)
      return {
        ...expense,
        outlet: selectOutletById(expense.outlet)(state) as Outlet,
        paymentType: selectPaymentTypeById(expense.paymentType)(state) as PaymentMethodType,
        expenseType: selectExpenseTypeById(expense.expenseType)(state),
      };

    return undefined;
  };
