import { ChevronUp } from "@bookpeep/ui";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  styled,
} from "@mui/material";
import truncate from "helpers/truncate";
import { useTranslation } from "react-i18next";
import { ServiceCategory } from "types/ServiceV2";

type PurchasableItemCategoryProps = {
  name: ServiceCategory["name"];
  color?: ServiceCategory["color"];

  items: JSX.Element;
  totalItemsDescription: string;

  shouldAllowSelectAllCategoryItems?: boolean;
  shouldDisableSelectAllCategoryItemsButton?: boolean;

  handleClickSelectAllCategoryItems?: () => void;
  areAllItemsSelected?: boolean;
};

const PurchasableItemCategory = ({
  name,
  color,
  totalItemsDescription,

  shouldAllowSelectAllCategoryItems,
  shouldDisableSelectAllCategoryItemsButton,
  handleClickSelectAllCategoryItems,
  areAllItemsSelected,

  items,
}: PurchasableItemCategoryProps) => {
  const { t } = useTranslation("services");
  const shouldShowCategoryColor = !!color;

  if (
    (shouldAllowSelectAllCategoryItems && !handleClickSelectAllCategoryItems) ||
    (!shouldAllowSelectAllCategoryItems && handleClickSelectAllCategoryItems)
  )
    throw new Error(
      "You must pass both shouldAllowSelectAllCategoryItems and handleClickSelectAllCategoryItems props or none of them"
    );

  return (
    <Accordion
      sx={{
        marginBottom: 1,
      }}
    >
      <AccordionSummary expandIcon={<ChevronUp />}>
        <Box display="flex" flex={1} alignItems="center" columnGap={2}>
          {shouldShowCategoryColor && (
            <Box width={30} height={30} borderRadius="50%" bgcolor={color} flexShrink={0} />
          )}

          <Box>
            <Typography variant="h5">
              {truncate({ string: name, maxCharacters: 40 }).string}
            </Typography>

            <Typography variant="secondary">{totalItemsDescription}</Typography>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          paddingTop: 2,
          paddingBottom: 1,
          px: 1,
        }}
      >
        {shouldAllowSelectAllCategoryItems && (
          <Button
            variant="text"
            size="small"
            onClick={handleClickSelectAllCategoryItems}
            disabled={shouldDisableSelectAllCategoryItemsButton}
            sx={{ mb: 1 }}
          >
            {t(areAllItemsSelected ? "unselectAll" : "selectAll")}
          </Button>
        )}

        {items}
      </AccordionDetails>
    </Accordion>
  );
};

export default PurchasableItemCategory;

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.bg.secondary,
  borderRadius: theme.shape.borderRadius,
}));
