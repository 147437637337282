import { Chip, styled } from "@mui/material";
import { dontForwardProps } from "helpers/styles";

type StyledChipProps = {
  chipColor?: string;
};

export const StyledChip = styled(
  Chip,
  dontForwardProps("chipColor")
)<StyledChipProps>(({ theme, chipColor }) => ({
  boxShadow: theme.shadows.card,
  width: "100%",
  elevation: 2,
  backgroundColor: chipColor ? chipColor : "#ffffff",
  fontWeight: 500,
  borderRadius: 0,
  marginLeft: 10,
  marginRight: 10,

  "& .MuiChip-deleteIcon": {
    color: "##4E5D78",
    height: "20px",
    width: "20px",
  },
  "& .MuiChip-label": {
    color: "#0A1F44",
    fontWeight: 500,
    fontSize: ".875rem",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "#EBF3FE",
    boxShadow: "none",
    borderRadius: "4px",
  },
}));
