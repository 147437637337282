import { ChevronDown } from "@bookpeep/ui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

type PeepAccordionProps = {
  title: string;
  description?: string;
  icon?: JSX.Element;

  error?: React.ReactNode;
  children: React.ReactNode;

  defaultExpanded?: boolean;
  withUnderline?: boolean;
};

const PeepAccordion = ({
  title,
  description,
  icon,
  error,
  children,
  defaultExpanded = false,
  withUnderline = false,
}: PeepAccordionProps) => {
  return (
    <StyledAccordion defaultExpanded={defaultExpanded}>
      <StyledAccordionSummary
        expandIcon={<ChevronDown />}
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
      >
        {icon}

        <Box display="block">
          <Title variant="h3">{title}</Title>

          {description && (
            <Description color="common.gray" variant="h5">
              {description}
            </Description>
          )}

          {error && <Box pt={2}>{error}</Box>}
        </Box>
      </StyledAccordionSummary>

      {withUnderline && <Divider variant="fullWidth" />}

      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  // @ts-expect-error
  boxShadow: theme.shadows.card,
  borderRadius: theme.spacing(0.75),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 2),
    minHeight: "initial",
    ".MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  ".MuiAccordionSummary-content": {
    alignItems: "center",
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

export default PeepAccordion;
