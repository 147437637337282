import { CalendarCard } from "types/Calendar";
import http from "utils/http";

type CalendarCardsArguments = {
  startDate: string;
  endDate: string;
  outletId: string;
};

export type ArticleSlotUpdateArguments = {
  start: string;
  end: string;
  staff?: number;
  id: number;
};

const calendarApi = {
  getCalendarCards: async ({ startDate, endDate, outletId }: CalendarCardsArguments) =>
    http.get<CalendarCard[]>(
      `appointments/v2/calendar/?start_date=${startDate}&end_date=${endDate}&outlet=${outletId}`
    ),

  updateCalendarCard: async ({ id, ...data }: ArticleSlotUpdateArguments) =>
    http.put<CalendarCard>(`appointments/v2/booked_slots/${id}/`, data),

  updateAppointmentStartOnCalendar: async ({ id, ...data }: ArticleSlotUpdateArguments) =>
    http.put<CalendarCard[]>(`appointments/v2/appointments/${id}/update_start_time/`, data),
};

export default calendarApi;
