import { FormHelperText, Stack, Typography } from "@mui/material";
import { PeepPayIcon } from "assets/icon";
import { ConfirmationDialogModal } from "components/common";
import {
  ConfirmationDialogModalProps,
  ConfirmationModalSeverity,
} from "components/common/ConfirmationDialogModal";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";
import PeepPayPhoneNumberSection, {
  type SetPeepPayPhoneNumberFunction,
} from "./PeepPayPhoneNumberSection";

type SendPeepPayLinkModalProps = Omit<ConfirmationDialogModalProps, "title"> & {
  isOpen: boolean;
  onClose: () => void;

  phoneCountryCode: string;
  phoneNumber: string;
  setPeepPayPhoneNumber: SetPeepPayPhoneNumberFunction;
  helperText: string;

  amountToCharge: number;
};

const SendPeepPayLinkModal = ({
  isOpen,
  onClose,
  phoneCountryCode,
  phoneNumber,
  setPeepPayPhoneNumber,
  amountToCharge,
  helperText,

  ...confirmationDialogModalProp
}: SendPeepPayLinkModalProps) => {
  const { t } = useTranslation(["invoices", "checkout"]);

  return (
    <ConfirmationDialogModal
      isOpen={isOpen}
      onClose={onClose}
      {...confirmationDialogModalProp}
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <PeepPayIcon
            viewBox="0 0 56 24"
            sx={{
              width: 56,
              height: 24,
            }}
          />

          <Typography variant="h1">{t("checkout:sendPeepPayLink")}</Typography>
        </Stack>
      }
      hasTitleOnTop
      confirmButtonSeverity={ConfirmationModalSeverity.Moderate}
    >
      {!!helperText && <FormHelperText error>{helperText}</FormHelperText>}

      <Stack gap={3} py={4}>
        <Stack direction="row" gap={4} alignItems="center" boxShadow="divider" pb={3}>
          <Typography variant="primary" color="text.label" fontWeight={700}>
            {t("checkout:amountToCharge")}
          </Typography>

          <Typography variant="primary" fontWeight={700}>
            {`${t("currency")}\u00A0${getFormattedPrice(amountToCharge)}`}
          </Typography>
        </Stack>

        <PeepPayPhoneNumberSection
          phoneCountryCode={phoneCountryCode}
          phoneNumber={phoneNumber}
          setPeepPayPhoneNumber={setPeepPayPhoneNumber}
        />
      </Stack>
    </ConfirmationDialogModal>
  );
};

export default SendPeepPayLinkModal;
