import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { LocationIcon } from "assets/icon";
import AreaAutocomplete, {
  type AreaAutocompleteAdditionalProps,
} from "components/AreaAutocomplete";
import useFormValidation from "hooks/useFormValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { createClientAddress, updateClientAddress } from "store/clients/clientsSlice";
import { ClientAddress } from "types/Client";
import useNotify from "utils/notifications/useNotify";

import { ClientAddressFormFields, clientAddressFormSchema } from "./clientAddressFormSchema";

type ClientAddressFormProps = {
  clientId: number;
  address?: ClientAddress;

  handleSaveForm?: (address: ClientAddress) => void;
  handleCancelEditForm?: () => void;
  areaAutoCompleteProps?: AreaAutocompleteAdditionalProps;
  closeForm: () => void;
};

const ClientAddressForm = ({
  clientId,
  address,
  handleSaveForm,
  handleCancelEditForm,
  closeForm,
  areaAutoCompleteProps,
}: ClientAddressFormProps) => {
  const { t } = useTranslation(["client", "translation"]);
  const notify = useNotify();
  const dispatch = useDispatch();

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { register, formState, reset, handleSubmit, watch, setValue } =
    useForm<ClientAddressFormFields>({
      resolver: zodResolver(clientAddressFormSchema),
      defaultValues: {
        ...address,
        client: clientId,
      },
    });

  const isEditMode = !!address;

  const registerValidation = useFormValidation(formState, { ns: "client" });

  const handleCloseForm = () => {
    closeForm();
    reset();
  };

  const handleCancelClick = () => {
    handleCloseForm();
    handleCancelEditForm && handleCancelEditForm();
  };

  const handleSaveClick = handleSubmit((data: ClientAddressFormFields) => {
    setIsRequestLoading(true);
    if (isEditMode) {
      dispatch(updateClientAddress(data))
        .unwrap()
        .then((addressResponse) => {
          notify(
            t("translation:entitySuccessfullyUpdated", {
              entity: t("address"),
            }),
            "success"
          );
          handleCloseForm();
          setIsRequestLoading(false);

          handleSaveForm && handleSaveForm({ ...addressResponse });
        })
        .catch((error) => {
          notify(
            t("translation:entityFailedToBeUpdated", {
              entity: t("address"),
              error: error?.detail ?? "",
            }),
            "error"
          );
          setIsRequestLoading(false);
        });
    } else {
      dispatch(createClientAddress(data))
        .unwrap()
        .then((addressResponse) => {
          notify(
            t("translation:entitySuccessfullyCreated", {
              entity: t("address"),
            }),
            "success"
          );
          setIsRequestLoading(false);
          handleCloseForm();
          handleSaveForm && handleSaveForm({ ...addressResponse });
        })
        .catch((error) => {
          notify(
            t("translation:failedToCreate", {
              entity: t("address"),
              error: error?.detail ?? "",
            }),
            "error"
          );
          setIsRequestLoading(false);
        });
    }
  });

  const handleChangeArea = (newValue: Nullable<number>) => {
    if (newValue) setValue("area", newValue);
    else reset({ area: undefined });
  };

  return (
    <Stack borderRadius={1} bgcolor="bg.secondary" p={3} gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <LocationIcon viewBox="0 0 16 16" color="primary" fontSize="small" />

        <Typography variant="secondary" color="text.label" fontWeight={500}>
          {isEditMode ? t("editAddress") : t("newAddress")}
        </Typography>
      </Stack>

      <form>
        <Grid container spacing={2} pt={3}>
          <Grid item md={6} xs={12}>
            <AreaAutocomplete
              label={t("requiredField", { fieldName: t("forms.area") })}
              {...registerValidation("area", t("forms.area"))}
              value={watch("area")}
              handleSelectValue={handleChangeArea}
              {...areaAutoCompleteProps}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label={t("requiredField", { fieldName: t("forms.block") })}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("forms.block") })}
              {...register("block")}
              {...registerValidation("block", t("forms.block"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("requiredField", { fieldName: t("forms.street") })}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("forms.street") })}
              {...register("street")}
              {...registerValidation("street", t("forms.street"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("forms.avenue")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("forms.avenue") })}
              {...register("avenue")}
              {...registerValidation("avenue", t("forms.avenue"))}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("requiredField", { fieldName: t("forms.buildingNameNumber") })}
              placeholder={t("fieldPlaceholder", { fieldName: t("forms.buildingNameNumber") })}
              {...register("building")}
              {...registerValidation("building", t("forms.buildingNameNumber"))}
              fullWidth
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label={t("forms.flat")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("forms.flat") })}
              {...register("flatNumber")}
              {...registerValidation("flatNumber", t("forms.flat"))}
              fullWidth
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              label={t("forms.floor")}
              placeholder={t("numberFieldPlaceholder", { fieldName: t("forms.floor") })}
              {...register("floor")}
              {...registerValidation("floor", t("forms.floor"))}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>

      <Stack direction="row" spacing={2} pt={3} justifyContent="flex-end">
        <Button variant="text" onClick={handleCancelClick}>
          {t("cancel")}
        </Button>

        <LoadingButton color="primary" onClick={handleSaveClick} loading={isRequestLoading}>
          {t("save")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ClientAddressForm;
