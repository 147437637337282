import { Box, Grid, Typography } from "@mui/material";
import { BasicDetailsDataType } from "types/DetailsWithLogs";

import DetailsRow from "./DetailsRow";

type BasicDetailsDataProps = {
  detailsData: BasicDetailsDataType[];
  detailsSubTitle: string;
};

const BasicDetailsData = ({ detailsData, detailsSubTitle }: BasicDetailsDataProps) => {
  return (
    <>
      <Box pb={2}>
        <Typography variant="primary" color="#848FA1" fontWeight={700} letterSpacing="0.08em">
          {detailsSubTitle.toUpperCase()}
        </Typography>
      </Box>

      <Grid container spacing={2} pb={3}>
        {detailsData.map((data) => (
          <DetailsRow
            key={data.id}
            label={data?.label}
            value={data?.value}
            icon={data?.icon}
            primaryColor={data?.primaryColor}
          />
        ))}
      </Grid>
    </>
  );
};

export default BasicDetailsData;
