import { DateTime } from "luxon";

/**
 * This function checks if the date string is in the past. (end of the day so that same day is not considered as past)
 * @param date
 * @returns
 */

export function checkIsDateInThePast(date: string) {
  const dateAsDateTime = DateTime.fromISO(date);
  const today = DateTime.now();

  // if date is in the past, return empty array
  return dateAsDateTime.endOf("day") < today.endOf("day");
}
