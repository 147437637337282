import { Box, Stack } from "@mui/material";
import ClientSection from "components/ClientSection";
import { checkIsHomeServiceOrganization } from "helpers/industries";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useAppointmentEditAccess from "hooks/useAppointmentEditAccess";
import { DateTime } from "luxon";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCheckoutAppointmentById } from "store/appointments/appointmentsSelectors";
import { selectOrganization } from "store/organization/organizationSlice";
import { HomeServicesAppointmentStep } from "store/slices/appointmentDrawer/appointmentDrawerSlice";

import AppointmentAdditionalInfoSection from "./AppointmentAdditionalInfoSection";
import AppointmentArticle from "./AppointmentArticle";
import AppointmentDetailsSkeleton from "./AppointmentDetails.skeleton";
import AppointmentDetailsAddressSection from "./AppointmentDetailsAddressSection";
import AppointmentDetailsFooter from "./AppointmentDetailsFooter";
import AppointmentDetailsToolbar from "./AppointmentDetailsToolbar";
import AppointmentHistoryLogs from "./AppointmentHistoryLogs";

type AppointmentDetailsProps = {
  appointmentId: number;

  existingClientActionButton: React.ReactNode;
};

const AppointmentDetails = ({
  appointmentId,

  existingClientActionButton,
}: AppointmentDetailsProps) => {
  const { t } = useTranslation();

  const organization = useSelector(selectOrganization) as Organization;
  const { openAppointmentDrawerEditMode } = useAppointmentDrawer();

  const appointment = useSelector(selectCheckoutAppointmentById(appointmentId));
  const isHomeServiceOrganization = checkIsHomeServiceOrganization(organization?.industry);
  const { canEditAppointment } = useAppointmentEditAccess({
    appointmentId: appointment?.id,
  });

  const handleEditAppointmentAddress = () => {
    if (appointment)
      openAppointmentDrawerEditMode({
        appointmentId,
        outletId: appointment.outlet,
        clientId: appointment?.client,
        homeServiceFormStep: HomeServicesAppointmentStep.Address,
      });
  };

  const sortedArticles = useMemo(() => {
    const appointmentArticlesCopy = appointment ? [...appointment?.articles] : [];

    return appointmentArticlesCopy.sort((a, b) => {
      const startA = DateTime.fromISO(a.slots[0].start);
      const startB = DateTime.fromISO(b.slots[0].start);
      return startA.toMillis() - startB.toMillis();
    });
  }, [appointment]);

  if (!appointment) return <AppointmentDetailsSkeleton />;

  return (
    <Stack height="100%" minHeight="100%">
      <Box boxShadow="divider" p={3}>
        <ClientSection
          existingClientId={appointment.client}
          shouldShowNoClient={!appointment.client}
          allowedToChangeClient={false}
          shouldTriggerValidation={false}
          shouldShowTags={false}
          existingClientActionButton={existingClientActionButton}
        />
      </Box>

      {isHomeServiceOrganization && (
        <Box boxShadow="divider" p={3}>
          <AppointmentDetailsAddressSection
            clientId={appointment.client}
            addressId={appointment.address}
            areaId={appointment.area}
            handleModifyClick={canEditAppointment ? handleEditAppointmentAddress : undefined}
          />
        </Box>
      )}

      <Stack overflow="scroll" width="100%" px={3} pb={2} flexGrow={1} gap={2}>
        <Box width="100%" py={2}>
          <AppointmentDetailsToolbar appointmentId={appointmentId} />
        </Box>

        <Box flexGrow={1} pb={3}>
          <Stack gap={2.5}>
            {sortedArticles.map((article, idx) => {
              return (
                <AppointmentArticle article={article} appointmentId={appointment.id} key={idx} />
              );
            })}
          </Stack>
        </Box>

        {appointment.notes && (
          <AppointmentAdditionalInfoSection
            title={t("appointmentNotes")}
            description={appointment.notes}
          />
        )}

        {!!appointment.logs?.length && (
          <AppointmentHistoryLogs appointmentId={appointment.id} logs={appointment.logs} />
        )}
      </Stack>

      <Box p={3} boxShadow="topDivider">
        <AppointmentDetailsFooter appointmentId={appointment.id} />
      </Box>
    </Stack>
  );
};

export default memo(AppointmentDetails);
