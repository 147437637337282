import { Button, Grid, Typography } from "@mui/material";
import { getAllSlotsInAppointment } from "helpers/getAllSlotsInAppointment";
import { getFormattedDuration } from "helpers/getFormattedDuration";
import { getFormattedPrice } from "helpers/getFormattedPrice";
import { getSlotDurationInMinutes } from "helpers/getSlotDurationInMinutes";
import { useContext, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import AppointmentFormContext from "../AppointmentFormContext";
import SubmitAppointmentFormButton from "./SubmitAppointmentFormButton";

type AppointmentFormSummaryProps = {
  onClose: () => void;
  shouldShowSubmitButton?: boolean;
  shouldShowAppointmentSummary?: boolean;

  actionButton?: React.ReactNode;
};

const AppointmentFormSummary = ({
  onClose,

  shouldShowSubmitButton = true,
  shouldShowAppointmentSummary = true,
  actionButton,
}: AppointmentFormSummaryProps) => {
  const { t } = useTranslation(["translation", "appointments"]);

  const { closeAndResetForm } = useContext(AppointmentFormContext);

  const articles = useWatch({ name: "articles" });

  const totalDurationInMinutes = useMemo(() => {
    const allSlots = getAllSlotsInAppointment(articles);

    // @ts-expect-error
    const totalMinutes = allSlots.map((slot) => getSlotDurationInMinutes(slot));
    // @ts-expect-error
    const sumOfAllSlots = totalMinutes.reduce((minutesAccumulator, minutes) => {
      return minutesAccumulator + minutes;
    }, 0);

    return sumOfAllSlots;
  }, [articles]);

  const price = useMemo(() => {
    return articles.reduce(
      // @ts-expect-error
      (partialTotal, article) => partialTotal + Number(article?.finalPrice),
      0
    );
  }, [articles]);

  const serviceCount = useMemo(() => {
    // @ts-expect-error
    return articles.reduce((partialTotal, article) => {
      const hasService = article.serviceVariantId !== null;
      const hasPackage = article.packageVariantId !== null;

      if (hasService) {
        return partialTotal + 1;
      }
      if (hasPackage) {
        return partialTotal + article.slots.length;
      }

      return partialTotal;
    }, 0);
  }, [articles]);

  const appointmentSummary = `${t("total")} ${t("currency")} ${getFormattedPrice(
    price
  )} (${getFormattedDuration(totalDurationInMinutes)}), ${serviceCount} ${t("services")} `;

  const handleDiscard = () => {
    closeAndResetForm();
    onClose();
  };

  return (
    <Grid container spacing={1}>
      {shouldShowAppointmentSummary && (
        <Grid item xs={12}>
          <Typography variant="h4" align="center" mb={1}>
            {appointmentSummary}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Button fullWidth variant="outlined" onClick={handleDiscard}>
          {t("appointments:discard")}
        </Button>
      </Grid>

      <Grid item xs={6}>
        {shouldShowSubmitButton ? <SubmitAppointmentFormButton onClose={onClose} /> : actionButton}
      </Grid>
    </Grid>
  );
};

export { AppointmentFormSummary };
