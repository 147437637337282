import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const InfinitePagination = ({ totalPages, loading, page, loadMore }) => {
  const { t } = useTranslation();
  const hasMorePages = page !== totalPages;

  if (totalPages <= 0) {
    return null;
  }

  return (
    <Grid container p={2}>
      <Grid item xs={12} sx={{ marginBottom: "80px" }}>
        {hasMorePages ? (
          <LoadingButton
            loading={loading}
            onClick={() => loadMore(page + 1)}
            fullWidth
            variant="outlined"
          >
            {t("showMore")}
          </LoadingButton>
        ) : (
          <Typography textAlign="center" component="p" variant="primary" color="text.label">
            {t("noMoreData")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default InfinitePagination;
