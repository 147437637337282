import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { OutletFeaturedServiceType } from "types/OutletFeaturedService";

import { SliceStatus } from "../utils";
import api from "./outletFeaturedServicesApi";

export const featuredServicesAdapter = createEntityAdapter<OutletFeaturedServiceType>({
  selectId: (featuredService) => featuredService.id,
});

const initialState = featuredServicesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllOutletFeaturedServices = createThunkFromApiWithType(
  "outletFeaturedServices/getAllOutletFeaturedServices",
  api.getOutletFeaturedServices
);

export const createOutletFeaturedService = createThunkFromApiWithType(
  "outletFeaturedServices/createOutletFeaturedService",
  api.createOutletFeaturedService
);

export const updateOutletFeaturedService = createThunkFromApiWithType(
  "outletFeaturedServices/updateOutletFeaturedService",
  api.updateOutletFeaturedService
);

export const deleteOutletFeaturedService = createThunkFromApiWithType(
  "outletFeaturedServices/deleteOutletFeaturedService",
  api.deleteOutletFeaturedService
);

export const outletFeaturedServicesSlice = createSlice({
  name: "outletFeaturedServices",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    // Get all featured services reducers
    reducers.addCase(getAllOutletFeaturedServices.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllOutletFeaturedServices.fulfilled, (state, { payload }) => {
      featuredServicesAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllOutletFeaturedServices.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // create featured service reducers
    reducers.addCase(createOutletFeaturedService.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createOutletFeaturedService.fulfilled, (state, { payload }) => {
      featuredServicesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createOutletFeaturedService.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // update featured service reducers
    reducers.addCase(updateOutletFeaturedService.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(updateOutletFeaturedService.fulfilled, (state, { payload }) => {
      featuredServicesAdapter.upsertOne(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(updateOutletFeaturedService.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    // delete featured service reducers
    reducers.addCase(deleteOutletFeaturedService.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      deleteOutletFeaturedService.fulfilled,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        state.status = SliceStatus.IDLE;
        featuredServicesAdapter.removeOne(state, id);
      }
    );

    reducers.addCase(deleteOutletFeaturedService.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });
  },
});

export default outletFeaturedServicesSlice.reducer;
