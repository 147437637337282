import { User } from "@bookpeep/ui";
import { Box, BoxProps, Typography } from "@mui/material";

type AppointmentFormClientSectionHeaderProps = BoxProps & {
  title: string;
};

const AppointmentFormClientSectionHeader = ({
  title,
  ...boxProps
}: AppointmentFormClientSectionHeaderProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1} {...boxProps}>
      <User color="disabled" />

      <Typography variant="h5" textTransform="uppercase" fontSize="0.875rem">
        {title}
      </Typography>
    </Box>
  );
};

export default AppointmentFormClientSectionHeader;
