import { createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "utils/createThunkFromApi";

import api from "./industriesApi";

const initialState = {
  industries: [],

  isLoading: false,
  error: "",
};

export const getAllIndustries = createThunkFromApi(
  "industries/getAllIndustries",
  api.getAllIndustries
);

const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllIndustries.pending]: (state) => {
      state.isLoading = true;
    },

    [getAllIndustries.fulfilled]: (_, { payload }) => {
      const industries = payload;

      return {
        industries,
        isLoading: false,
        error: "",
      };
    },

    [getAllIndustries.rejected]: (state, { payload }) => {
      return {
        ...state,
        error: payload?.detail,
      };
    },
  },
});

export const selectAllIndustries = ({ industries }) => industries;

export default industriesSlice.reducer;
