import * as yup from "yup";

export const forgetPasswordSchema = (t) =>
  yup
    .object({
      email: yup
        .string()
        .email(t("validation.email"))
        .required(t("validation.emailRequired")),
    })
    .required();

export default forgetPasswordSchema;
