import stringifyArray from "helpers/stringifyArray";
import { AppointmentHome, AppointmentSource } from "types/Appointment";
import http from "utils/http";

type AppointmentOverviewResponse = {
  results: AppointmentHome[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  todaysCount: number;
  upcomingCount: number;
};

const appointmentsOverviewApi = {
  getAppointmentsOverview: async ({
    page,
    startDate,
    endDate,
    zone,
    outletId,
    duration,
    statusOptions,
    invoiceStatusOptions,
    bookingSourceOptions,
    depositOptions,
    ordering,
    searchQuery,
    createdBy,
    areas,
  }: {
    page: number;
    startDate: string;
    endDate: string;
    zone: number;
    outletId: number;
    duration: string;
    statusOptions: string[];
    invoiceStatusOptions: string[];
    bookingSourceOptions: AppointmentSource[];
    depositOptions: string[];
    ordering: string[];
    searchQuery: string;
    createdBy: number[];
    areas: number[];
  }) =>
    http.get<AppointmentOverviewResponse>(
      `appointments/appointments_overview/?page=${page}${
        outletId !== 0 ? `&outlet=${outletId}` : ""
      }${zone !== 0 ? `&zone=${zone}` : ""}&duration=${duration}${
        statusOptions && !!statusOptions.length
          ? `&status=${stringifyArray(statusOptions.join().split(","))}`
          : ""
      }${
        invoiceStatusOptions && !!invoiceStatusOptions.length
          ? `&invoice_status=${stringifyArray(invoiceStatusOptions.join().split(","))}`
          : ""
      }${areas && !!areas.length ? `&areas=${stringifyArray(areas.join().split(","))}` : ""}${
        bookingSourceOptions && !!bookingSourceOptions.length
          ? `&booking_source=${stringifyArray(bookingSourceOptions.join().split(","))}`
          : ""
      }${
        depositOptions && !!depositOptions.length
          ? `&has_deposit=${stringifyArray(depositOptions.join().split(","))}`
          : ""
      }${ordering && !!ordering.length ? `&order_by=${ordering[0]}` : ""}${
        searchQuery ? `&search=${searchQuery}` : ""
      }${startDate && endDate ? `&start_date=${startDate.trim()}&end_date=${endDate.trim()}` : ""}${
        createdBy && !!createdBy.length
          ? `&created_by=${stringifyArray(createdBy.join().split(","))}`
          : ""
      }`
    ),
};

export default appointmentsOverviewApi;
