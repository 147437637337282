export const tableHeaders = (t: any, showAreaAndZone: boolean = false) => [
  {
    align: "left",
    key: "reference",
    name: t("reference"),
    width: "10%",
  },
  {
    align: "left",
    key: "status",
    name: t("status"),
    width: "11%",
  },
  {
    align: "left",
    key: "client",
    name: t("client"),
    width: "12%",
  },
  {
    align: "left",
    key: "appointmentDate",
    name: t("startDate"),
    isSortable: true,
    width: "12%",
  },
  {
    align: "left",
    key: showAreaAndZone ? "area" : "location",
    name: t(showAreaAndZone ? "area" : "location"),
    width: "9%",
  },
  {
    align: "left",
    key: "price",
    name: t("price"),
    isSortable: true,
    width: "8%",
  },
  {
    align: "left",
    key: "deposit",
    name: t("deposit"),
    width: "8%",
  },
  {
    align: "left",
    key: "invoice",
    name: t("invoice"),
    width: "8%",
  },
  {
    align: "left",
    key: "payment",
    name: t("payment"),
    width: "9%",
  },
  {
    align: "left",
    key: "createdAt",
    name: t("createdAt"),
    isSortable: true,
    width: "12%",
  },
];
