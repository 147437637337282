import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
  appendAllProductsData,
  createProduct,
  createStockDown,
  createStockTransfer,
  createStockUp,
  getAllProductsData,
  getProductData,
  updateProduct,
} from "../products/productsSlice";
import { SliceStatus } from "../utils";

export const outletStocksAdapter = createEntityAdapter<OutletStock>({
  selectId: (outletStock) => outletStock.id,
});

const initialState = outletStocksAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const outletStocksSlice = createSlice({
  name: "outletStocks",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getProductData.fulfilled, (state, { payload: { outletStocks } }) => {
      outletStocksAdapter.upsertMany(state, outletStocks);
    });

    reducers.addCase(updateProduct.fulfilled, (state, { payload }) => {
      const { outletStocks } = payload;

      outletStocksAdapter.upsertMany(state, outletStocks);
    });

    reducers.addCase(getAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllProductsData.fulfilled, (state, { payload }) => {
      outletStocksAdapter.upsertMany(state, payload.results.outletStocks);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(appendAllProductsData.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(appendAllProductsData.fulfilled, (state, { payload }) => {
      outletStocksAdapter.upsertMany(state, payload.results.outletStocks);
      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(appendAllProductsData.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(createProduct.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createProduct.fulfilled, (state, { payload: { outletStocks } }) => {
      outletStocksAdapter.upsertMany(state, outletStocks);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createProduct.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockDown.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockDown.fulfilled, (state, { payload: { outletStocks } }) => {
      outletStocksAdapter.upsertMany(state, outletStocks);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockDown.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockUp.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockUp.fulfilled, (state, { payload: { outletStocks } }) => {
      outletStocksAdapter.upsertMany(state, outletStocks);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockUp.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createStockTransfer.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(createStockTransfer.fulfilled, (state, { payload: { outletStocks } }) => {
      outletStocksAdapter.upsertMany(state, outletStocks);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(createStockTransfer.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });
  },
});

export default outletStocksSlice.reducer;
