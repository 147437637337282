import { Box, Drawer, Grid } from "@mui/material";
import { PurchasableItemsSelector } from "components/common";
import { PurchasableItemsSelectorProps } from "components/common/PurchasableItemsSelector/PurchasableItemsSelector";
import useIsMobileView from "hooks/useIsMobileView";

import PurchasableItemsDrawerHeader from "./PurchasableItemsDrawerHeader";

type PurchasableItemsDrawerProps = PurchasableItemsSelectorProps & {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
};

const PurchasableItemsDrawer = ({
  title,
  isOpen,
  onClose,

  ...purchasableItemsSelectorProps
}: PurchasableItemsDrawerProps) => {
  const isMobileView = useIsMobileView();

  return (
    <Drawer
      anchor={isMobileView ? "bottom" : "right"}
      onClose={onClose}
      open={isOpen}
      PaperProps={{ sx: paperStyles }}
      sx={{ zIndex: 99999 }}
    >
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12}>
          <PurchasableItemsDrawerHeader title={title} onClose={onClose} />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box flexGrow={1} overflow="scroll">
            <PurchasableItemsSelector {...purchasableItemsSelectorProps} />
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default PurchasableItemsDrawer;

const paperStyles = {
  maxWidth: { md: "27.5rem", xs: "100%" },
  width: "100%",
  height: "100vh",
  borderTopLeftRadius: {
    md: 0,
    xs: 4,
  },
  borderTopRightRadius: {
    md: 0,
    xs: 4,
  },
};
