import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { PeepTransaction } from "types/PeepTransaction";

export const peepTransactionsAdapter = createEntityAdapter<PeepTransaction>(
  {selectId: (peepTransaction) => peepTransaction.id}
);

const initialState = peepTransactionsAdapter.getInitialState<{
  isLoading: boolean,
  isRequestPending: boolean,
  error: string,
}>({
  isLoading: false,
  isRequestPending: false,
  error: 'string'
})

export const peepTransactionsSlice = createSlice({
  name: "peepTransactions",
  initialState,
  reducers: {
    upsertPeepTransactions(state, { payload }) {
      peepTransactionsAdapter.upsertMany(state, payload);
    },
  }
})

export const {upsertPeepTransactions} = peepTransactionsSlice.actions

export default peepTransactionsSlice.reducer;
