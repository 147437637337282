import http from "utils/http";

const employeesApi = {
  getAllShifts: async () => http.get("shifts/shifts/"),

  getShift: async (id) => http.get(`shifts/shifts/${id}/`),

  createShift: async ({ data }) => http.post("shifts/shifts/", data),

  updateShift: async ({ id, data }) => http.put(`shifts/shifts/${id}/`, data),

  removeShift: async ({ id }) => http.delete(`shifts/shifts/${id}/`),
};

export default employeesApi;
