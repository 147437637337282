import { Box, Button, Divider, Typography, styled } from "@mui/material";
import { AppointmentIcon } from "assets/icon";
import SideBarFilterCheckbox from "components/common/SideBarFilterCheckbox";
import { getAppointmentStatusIcon } from "helpers/getAppointmentStatusIcon";
import { dontForwardProps } from "helpers/styles";
import { camelize } from "humps";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAppointmentsCount } from "store/selectors";
import { AppointmentStatusOptions } from "types/Appointment";

import AppointmentsOverviewContext, {
  AppointmentDurationOptions,
} from "./AppointmentsOverviewContext";

const APPOINTMENT_STATUS_OPTIONS = [
  AppointmentStatusOptions.New,
  AppointmentStatusOptions.Waiting,
  AppointmentStatusOptions.Confirmed,
  AppointmentStatusOptions.Arrived,
  AppointmentStatusOptions.Started,
  AppointmentStatusOptions.Completed,
  AppointmentStatusOptions.NoShow,
  AppointmentStatusOptions.Canceled,
];

const AppointmentsOverviewSideBarFilters = () => {
  const { t } = useTranslation(["home", "translation"]);

  const { today, upcoming } = useSelector(selectAppointmentsCount);

  const {
    duration,
    setDuration,

    statusOptions,
    setStatusOptions,

    setPage,
  } = useContext(AppointmentsOverviewContext);

  const getAppointmentsCount = (option: string) => {
    const optionFormatted = option.toUpperCase();
    if (optionFormatted === AppointmentDurationOptions.Upcoming) {
      return upcoming;
    } else if (optionFormatted === AppointmentDurationOptions.Today) {
      return today;
    } else return "";
  };

  const handleStatusSelection = (selectedStatus: string) => {
    if (statusOptions.includes(selectedStatus)) {
      setStatusOptions(statusOptions.filter((status) => status !== selectedStatus.toUpperCase()));
    } else {
      setStatusOptions([...statusOptions, selectedStatus.toUpperCase()]);
    }
    setPage(1);
  };

  return (
    <>
      <Box>
        <Box pb={2}>
          <Typography variant="primary" color="#848FA1" fontWeight="bold">
            {t("home:appointments").toUpperCase()}
          </Typography>
        </Box>

        {Object.keys(AppointmentDurationOptions).map((option) => (
          <DurationButton
            active={
              duration ===
              AppointmentDurationOptions[option as keyof typeof AppointmentDurationOptions]
            }
            variant="text"
            color="secondary"
            fullWidth
            key={option}
            onClick={() => {
              setPage(1);
              setDuration(
                AppointmentDurationOptions[option as keyof typeof AppointmentDurationOptions]
              );
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center" height="100%">
              <AppointmentIcon sx={{ width: 16, height: 16, mr: 1 }} />

              <Typography variant="primary" fontWeight={700}>
                {t(option)}
              </Typography>

              <Typography color="bg.lightGray" sx={{ ml: 1 }}>
                {getAppointmentsCount(option)}
              </Typography>
            </Box>
          </DurationButton>
        ))}
      </Box>

      <Divider variant="fullWidth" sx={{ my: 3 }} />

      <Box>
        <Box pb={2}>
          <Typography variant="primary" color="#848FA1" fontWeight="bold">
            {t("home:appointmentStatus").toUpperCase()}
          </Typography>
        </Box>

        {APPOINTMENT_STATUS_OPTIONS.map((status) => (
          <SideBarFilterCheckbox
            key={status}
            isChecked={statusOptions.includes(status)}
            label={
              <Box display="flex" flexDirection="row" alignItems="center" height="100%" gap={2}>
                {getAppointmentStatusIcon(status, "small")}

                <Typography variant="primary" fontWeight={700}>
                  {t(`translation:${camelize(status.toLowerCase())}`)}
                </Typography>
              </Box>
            }
            value={status}
            onClick={() => handleStatusSelection(status)}
          />
        ))}
      </Box>
    </>
  );
};

export default AppointmentsOverviewSideBarFilters;

type DurationButtonProps = {
  active: boolean;
};

const DurationButton = styled(
  Button,
  dontForwardProps("active")
)<DurationButtonProps>(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.bg.secondary : "transparent",
  color: active ? theme.palette.text.secondary : theme.palette.text.primary,
  minHeight: "auto",
  "&:hover": {
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.text.secondary,
  },

  justifyContent: "flex-start",
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));
