import { Resource, ResourceDetails, UpdateResourceArgs } from "types/Resource";
import http from "utils/http";

type ResourceListResponse = {
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
  results: Resource[];
};

type CreateResourceRequestArgs = {
  name: Resource["name"];
  description?: Resource["description"];
  online: Resource["online"];
  outlet: Resource["outlet"];
};

type UpdateResourceRequestArgs = {
  id: Resource["id"];
  resource: UpdateResourceArgs;
};

const resourcesApi = {
  getResourceDetails: async ({ id }: { id: number }) =>
    http.get<ResourceDetails>(`resources/resources/${id}/`),

  getAllResources: async () => http.get<Resource[]>("resources/brief/"),

  getResources: async ({
    page,
    outlet,
    ordering,
    searchQuery,
  }: {
    page?: number;
    outlet?: number;
    ordering?: string;
    searchQuery?: string;
  }) =>
    http.get<ResourceListResponse>(
      `resources/resources/${page ? `?page=${page}` : ""}${
        outlet && outlet !== 0 ? `&outlet=${outlet}` : ""
      }${ordering ? `&order_by=${ordering}` : ""}${searchQuery ? `&search=${searchQuery}` : ""}`
    ),

  createResource: async (resource: CreateResourceRequestArgs) =>
    http.post<Resource>("resources/resources/", resource),

  updateResource: async ({ id, resource }: UpdateResourceRequestArgs) =>
    http.put<Resource>(`resources/resources/${id}/`, resource),

  deleteResource: async ({ id }: { id: number }) => http.delete(`resources/resources/${id}/`),
};

export default resourcesApi;
