import { RootState } from "store";

import { SliceStatus } from "../utils";
import { featuredServicesAdapter } from "./outletFeaturedServicesSlice";

export const selectOutletFeaturedServicesLoading = (state: RootState) =>
  state.outletFeaturedServices.status === SliceStatus.LOADING;

export const selectOutletFeaturedServicesState = (state: RootState) => {
  return state.outletFeaturedServices;
};

const { selectAll, selectById } = featuredServicesAdapter.getSelectors(
  selectOutletFeaturedServicesState
);

export const selectAllOutletFeaturedServices = selectAll;

export const selectOutletFeaturedServiceById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectAllOutletFeaturedServicesByOutletId =
  (outletId: number) => (state: RootState) => {
    return selectAllOutletFeaturedServices(state).filter(
      (featuredService) => featuredService.outlet === outletId
    );
  };
