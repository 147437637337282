import { ChevronLeft, ChevronRight } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";

type InvoiceLoyaltyCardsScrollArrowsProps = {
  shouldDisableLeftArrow: boolean;
  shouldDisableRightArrow: boolean;
  currentLoyaltyCardIndex: number;
  setCurrentLoyaltyCardIndex: (index: number) => void;
  loyaltyCardsLength: number;
};

const InvoiceLoyaltyCardsScrollArrows = ({
  shouldDisableLeftArrow,
  shouldDisableRightArrow,
  currentLoyaltyCardIndex,
  setCurrentLoyaltyCardIndex,
  loyaltyCardsLength,
}: InvoiceLoyaltyCardsScrollArrowsProps) => {
  const decrementCurrentLoyaltyCardIndex = () => {
    setCurrentLoyaltyCardIndex(currentLoyaltyCardIndex - 1);
  };

  const incrementCurrentLoyaltyCardIndex = () => {
    setCurrentLoyaltyCardIndex(currentLoyaltyCardIndex + 1);
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        disabled={shouldDisableLeftArrow}
        onClick={decrementCurrentLoyaltyCardIndex}
        color="primary"
      >
        <ChevronLeft />
      </IconButton>

      <Typography variant="primary" fontWeight={500} color="text.label">
        <Typography variant="primary" fontWeight={500} color="primary" display="inline">
          {currentLoyaltyCardIndex + 1}
        </Typography>
        {` / ${loyaltyCardsLength}`}
      </Typography>

      <IconButton
        disabled={shouldDisableRightArrow}
        onClick={incrementCurrentLoyaltyCardIndex}
        color="primary"
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default InvoiceLoyaltyCardsScrollArrows;
