import Outlet from "types/Outlet";
import { ServiceVariantWithService } from "types/ServiceV2";
import { VoucherPurchase } from "types/VoucherPurchase";
import { VoucherRedemptionDetailWithoutCode } from "types/VoucherRedemption";
import http from "utils/http";

type VoucherResponse = {
  results: VoucherPurchase[];
  next: Nullable<string>;
  previous: Nullable<string>;
  totalPages: number;
};

export type VoucherDetailsResponse = Omit<VoucherPurchase, "serviceVariants" | "outlets"> & {
  serviceVariants?: ServiceVariantWithService[];
  outlets: Outlet[];
  createdAt: string;
  hasAllServices: boolean;
  purchasedByClient: {
    id: number;
    fullName: string;
  };
  outletSold: {
    id: number;
    name: string;
  };
  redemptions: VoucherRedemptionDetailWithoutCode[];
};

const voucherPurchasesApi = {
  getVoucherPurchases: async ({
    page,
    ordering,
    searchQuery,
  }: {
    page: number;
    ordering: string;
    searchQuery: string;
  }) =>
    http.get<VoucherResponse>(
      `vouchers/voucher-purchases/?page=${page}${ordering ? `&order_by=${ordering}` : ""}${searchQuery ? `&search=${searchQuery}` : ""
      }`
    ),

  // TODO: THIS <VoucherPurchase> is not correct, it should be <VoucherDetailsResponse>, but then the slice would complain about the type mismatch I don't have time to fix right now
  getVoucherPurchaseDetails: async ({ id }: { id: number }) =>
    http.get<VoucherPurchase>(`vouchers/voucher-purchases/${id}/`),

  extendVoucherPurchase: async ({ id, validityInDays }: { id: number; validityInDays: number }) =>
    http.put(`/vouchers/extend_purchase/${id}/`, { validityInDays }),
};

export default voucherPurchasesApi;
