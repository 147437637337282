import { ChevronDown, ChevronUp } from "@bookpeep/ui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { dontForwardProps } from "helpers/styles";
import { useState } from "react";

const StyledAccordion = styled(
  Accordion,
  dontForwardProps("categoryForModal", "serviceForModal", "variantForModal")
)(({ theme, categoryForModal, serviceForModal, variantForModal }) => ({
  padding: categoryForModal || serviceForModal || variantForModal ? 0 : theme.spacing(3, 0),
  borderBottom: serviceForModal && "1px solid lightGray",
  borderTop: variantForModal && "1px solid lightGray",
  backgroundColor: variantForModal && "#F5F4F4",
}));

const StyledAccordionSummary = styled(
  AccordionSummary,
  dontForwardProps("isService", "categoryForModal", "serviceForModal", "variantForModal")
)(({ theme, isService, categoryForModal, serviceForModal, variantForModal }) => ({
  marginRight: theme.spacing(3),
  paddingLeft: isService
    ? theme.spacing(5)
    : serviceForModal
    ? theme.spacing(5)
    : variantForModal
    ? theme.spacing(9)
    : 0,
  padding: categoryForModal && theme.spacing(3, 0),

  "& .MuiAccordionSummary-content": {
    margin: 0,
    paddingLeft: "12px",

    paddingBottom: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transform: "none",
    WebkitTransition: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    WebkitTransition: "none",
    transform: "none",
  },
  paddingRight: 0,
}));

const StyledAccordionDetails = styled(
  AccordionDetails,
  dontForwardProps("isServiceCategory", "categoryForModal", "expandAccordion")
)(({ isServiceCategory, dotcolor, categoryForModal, expandAccordion }) => ({
  padding: 0,
  borderLeft: isServiceCategory && `3px solid ${dotcolor}`,
  "& .MuiAccordionDetails-root": {
    paddingLeft: 0,
  },
  borderTop: categoryForModal && expandAccordion && "1px solid lightGray",
}));

const StyledTypography = styled(
  Typography,
  dontForwardProps("isService")
)(({ theme, isService }) => ({
  paddingLeft: isService ? theme.spacing(1) : 0,
  fontSize: isService ? "14px" : "16px",
  fontWeight: isService ? 700 : 500,
}));

const StyledCheckbox = styled(
  Checkbox,
  dontForwardProps("isService")
)(({ theme, isService }) => ({
  paddingLeft: isService && theme.spacing(0.75),
}));

const PeepCheckboxAccordion = ({
  title,
  description,
  children,
  alternateAction,
  isServiceCategory,
  isService,
  isVariant,
  dotColor,
  checkbox,
  noCheckbox,
  forModal,
  defaultExpanded = false,
  disableExpand = false,
  alwaysExpanded = false,
}) => {
  const [expandAccordion, setExpandAccordion] = useState(alwaysExpanded ? true : defaultExpanded);

  const handleExpand = () => {
    setExpandAccordion(alwaysExpanded ? true : !expandAccordion);
  };

  const categoryForModal = forModal && isServiceCategory;
  const serviceForModal = forModal && isService;
  const variantForModal = forModal && isVariant;

  return (
    <StyledAccordion
      expanded={alwaysExpanded ? true : expandAccordion}
      categoryForModal={categoryForModal}
      serviceForModal={serviceForModal}
      variantForModal={variantForModal}
    >
      <StyledAccordionSummary
        expandIcon={
          <>
            {alternateAction}
            {isServiceCategory && (
              <>
                {expandAccordion ? (
                  <ChevronUp color="primary" onClick={handleExpand} />
                ) : (
                  <ChevronDown onClick={handleExpand} />
                )}
              </>
            )}
          </>
        }
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
        categoryForModal={categoryForModal}
        serviceForModal={serviceForModal}
        variantForModal={variantForModal}
      >
        {checkbox ? (
          checkbox
        ) : noCheckbox ? null : (
          <StyledCheckbox size="medium" isService={isService} />
        )}

        {isServiceCategory && (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Box ml={1} mr={1} bgcolor={dotColor} height="30px" width="30px" borderRadius={10} />
          </Box>
        )}

        <Box
          {...(!disableExpand && { onClick: handleExpand })}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          width="100%"
          pl={isServiceCategory && 0.3}
        >
          <StyledTypography isService={isService}>{title}</StyledTypography>
          {description && (
            <Typography color="common.gray" variant="h5">
              {description}
            </Typography>
          )}
        </Box>
      </StyledAccordionSummary>

      <StyledAccordionDetails
        dotcolor={dotColor}
        isServiceCategory={isServiceCategory}
        categoryForModal={categoryForModal}
        expandAccordion={expandAccordion}
      >
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default PeepCheckboxAccordion;
