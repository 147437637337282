import { Box } from "@mui/material";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import NavHeaderSkeleton from "layouts/NavHeader.skeleton";
import { ACCESS_TOKEN } from "utils/auth";

export default function AppSkeleton() {
  const doesUserTokenExist = localStorage.getItem(ACCESS_TOKEN);

  if (!doesUserTokenExist)
    return (
      <Box display="flex" height="100%" justifyContent="center" flexDirection="column">
        <Box flexGrow={1}>
          <CentredSpinnerBox flexGrow={1} />
        </Box>
      </Box>
    );

  return (
    <Box display="flex" height="100%">
      <Box flexGrow={1}>
        <NavHeaderSkeleton />

        <CentredSpinnerBox flexGrow={1} />
      </Box>
    </Box>
  );
}
