import { RootState } from "store";

import { SliceStatus } from "../utils";
import { clientPreferredBeveragesAdapter } from "./clientPreferredBeveragesSlice";

export const selectClientPreferredBeveragesLoading = (state: RootState) =>
  state.clientPreferredBeverages.status === SliceStatus.LOADING;

export const selectClientPreferredBeveragesState = (state: RootState) =>
  state.clientPreferredBeverages;

const { selectAll } = clientPreferredBeveragesAdapter.getSelectors(
  selectClientPreferredBeveragesState
);

export const selectAllClientPreferredBeverages = selectAll;
