import { ChevronDown } from "@bookpeep/ui";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { getAppointmentStatusIcon } from "helpers/getAppointmentStatusIcon";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentStatusOptions } from "types/Appointment";

const APPOINTMENT_STATUS_SELECT_OPTIONS = [
  AppointmentStatusOptions.New,
  AppointmentStatusOptions.Confirmed,
  AppointmentStatusOptions.Waiting,
  AppointmentStatusOptions.Arrived,
  AppointmentStatusOptions.Started,
  AppointmentStatusOptions.NoShow,
];

type AppointmentStatusSelectProps = {
  value: AppointmentStatusOptions;
  onChange: (newStatus: AppointmentStatusOptions) => void;
  disabled?: boolean;
};

function AppointmentStatusSelect({ value, onChange, disabled }: AppointmentStatusSelectProps) {
  const { t } = useTranslation();

  const changeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = event.target.value as AppointmentStatusOptions;
    onChange(selectedStatus);
  };

  return (
    <RoundTextField
      select
      label={t("status")}
      value={value}
      onChange={changeStatus}
      fullWidth
      disabled={disabled}
      SelectProps={{
        IconComponent: SelectIconComponent,
        renderValue: () => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,

              "& svg": {
                fontSize: "20px",
              },
            }}
          >
            {getAppointmentStatusIcon(value)}

            <Typography sx={{ fontSize: "0.75rem", fontWeight: 500 }}>
              {t(value.toLowerCase())}
            </Typography>
          </Box>
        ),
      }}
    >
      {APPOINTMENT_STATUS_SELECT_OPTIONS.map((status) => (
        <MenuItem key={status} value={status}>
          <ListItemIcon>{getAppointmentStatusIcon(status)}</ListItemIcon>

          <ListItemText primaryTypographyProps={{ fontSize: "0.75rem", fontWeight: 500 }}>
            {t(status.toLowerCase())}
          </ListItemText>
        </MenuItem>
      ))}
    </RoundTextField>
  );
}

export default AppointmentStatusSelect;

const RoundTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    minHeight: "0px",
  },

  "& .MuiOutlinedInput-root": {
    minHeight: "0px",
    height: theme.spacing(4.25),
  },

  "& .MuiInputLabel-root": {
    overflow: "hidden",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderRadius: "50px",
    borderColor: "#DFDFDF",
  },
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "#DFDFDF",
  },
  "&:hover .MuiInputLabel-root": {
    borderColor: "#DFDFDF",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DFDFDF",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    borderColor: "#DFDFDF",
  },
}));

const SelectIconComponent = () => (
  <ChevronDown
    sx={{
      position: "absolute",
      right: 10,
      display: "inline-block",
      color: "#C0C0C0",
      height: "16px",
      pointerEvents: "none",
    }}
  />
);
