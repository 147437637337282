import { useEffect, useState } from "react";
import { UseFormReset } from "react-hook-form";
import { AssertsShape } from "yup/lib/object";

/**
 * @param resetFunction method - react-hook-form reset method
 * @param onReset - an optional callback to run after the form is reset
 *
 * @returns resetForm - method to reset the form
 */

export default function useResetForm<FormInputs extends AssertsShape<any>>(
  resetFunction: UseFormReset<FormInputs>,
  onReset?: () => void
) {
  const [shouldResetForm, setShouldResetForm] = useState(false);

  useEffect(() => {
    if (shouldResetForm) resetFunction();

    return () => {
      setShouldResetForm(false);
    };
  }, [resetFunction, shouldResetForm]);

  const resetForm = () => {
    setShouldResetForm(true);

    onReset && onReset();
  };

  return resetForm;
}

// TODO: Dear Teammate, the type of resetFunction is not accurate, my time is limited, let's fix it some other time. - Anfal The Great

// NOTE: If you want to reset a specific form field to some value, you'd have to fix the type of resetFunction - Anfal The Busy Soul
