import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Invoice } from "types/Checkout";
import { PeepPayLinkStatus } from "types/Deposit";

import InvoiceRowItem from "./InvoiceRowItem";
import InvoiceRowPriceItem from "./InvoiceRowPriceItem";

type InvoicePendingPaymentsSectionProps = { invoice: Invoice };

const InvoicePendingPaymentsSection = ({ invoice }: InvoicePendingPaymentsSectionProps) => {
  const { t } = useTranslation("invoices");

  return (
    <>
      <InvoiceRowItem title="invoices:pendingPayments" />

      {invoice.pendingPayments
        ?.filter((pendingPayment) => pendingPayment.status === PeepPayLinkStatus.Pending)
        .map((pendingPayment) => (
          <InvoiceRowPriceItem
            key={pendingPayment.id}
            title={t("invoices:peepPay")}
            isTitleTranslated={false}
            amount={Number(pendingPayment.amount)}
            description={DateTime.fromISO(pendingPayment.createdAt).toFormat(
              "EEEE, dd MMMM hh:mm a"
            )}
          />
        ))}
    </>
  );
};

export default InvoicePendingPaymentsSection;
