import { ChevronDown } from "@bookpeep/ui";
import {
  AccordionSummary,
  Box,
  Accordion as MuiAccordion,
  Typography,
  styled,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import PeepAddIconButton from "./PeepAddIconButton";
import SectionTitle from "./SectionTitle";

type FormItemsAccordionProps = {
  title: string;

  handleClickAddItems: () => void;

  children: ReactNode;

  summary?: ReactNode;
};

function FormItemsAccordion({
  title,
  handleClickAddItems,
  children,
  summary,
}: FormItemsAccordionProps) {
  const { t } = useTranslation(["translation", "promotions"]);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const handleToggleAccordion = () => {
    setIsAccordionExpanded((previousState) => !previousState);
  };

  return (
    <Accordion expanded={isAccordionExpanded} onChange={handleToggleAccordion}>
      <AccordionSummary expandIcon={<ChevronDown color="primary" />}>
        <SectionTitle>{title}</SectionTitle>

        <Typography variant="primary" color="primary" fontWeight={700} px={1}>
          {t(isAccordionExpanded ? "promotions:hide" : "promotions:show")}
        </Typography>
      </AccordionSummary>

      <Box>{children}</Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" pt={1}>
        <PeepAddIconButton label={t("promotions:addItems")} onClick={handleClickAddItems} />

        {!!summary && summary}
      </Box>
    </Accordion>
  );
}

export default FormItemsAccordion;

const Accordion = styled(MuiAccordion)({
  backgroundColor: "transparent",

  "& .MuiAccordionSummary-root": {
    padding: 0,
    margin: 0,
  },

  "& .MuiAccordionSummary-content": {
    justifyContent: "space-between",
  },
});
