import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { LoyaltyProgram } from "types/LoyaltyProgram";

import { SliceStatus } from "../utils";
import api from "./loyaltyProgramsApi";

export const loyaltyProgramsAdapter = createEntityAdapter<LoyaltyProgram>({
  selectId: (loyaltyProgram) => loyaltyProgram.id,
});

const initialState = loyaltyProgramsAdapter.getInitialState<{
  status: SliceStatus;
  totalPages: number;
  page: number[];
  isRequestPending: SliceStatus;
}>({
  status: SliceStatus.IDLE,
  totalPages: 0,
  page: [],
  isRequestPending: SliceStatus.IDLE,
});

export const getLoyaltiesBrief = createThunkFromApiWithType(
  "loyaltyPrograms/getLoyaltiesBrief",
  api.getLoyaltyProgramsBrief
);

export const getLoyaltyPrograms = createThunkFromApiWithType(
  "loyaltyPrograms/getLoyaltyPrograms",
  api.getLoyaltyPrograms
);

export const appendLoyaltyPrograms = createThunkFromApiWithType(
  "loyaltyPrograms/appendLoyaltyPrograms",
  api.getLoyaltyPrograms
);

export const createLoyaltyProgram = createThunkFromApiWithType(
  "loyaltyPrograms/createLoyaltyProgram",
  api.createLoyaltyProgram
);

export const updateLoyaltyProgram = createThunkFromApiWithType(
  "loyaltyPrograms/updateLoyaltyProgram",
  api.updateLoyaltyProgram
);

export const deleteLoyaltyProgram = createThunkFromApiWithType(
  "loyaltyPrograms/deleteLoyaltyProgram",
  api.deleteLoyaltyProgram
);

export const loyaltyProgramsSlice = createSlice({
  name: "loyaltyPrograms",
  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      // GetAll LoyaltyProgramBrief reducers
      .addCase(getLoyaltiesBrief.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getLoyaltiesBrief.fulfilled, (state, { payload }) => {
        loyaltyProgramsAdapter.upsertMany(state, payload);

        state.status = SliceStatus.IDLE;
      })
      .addCase(getLoyaltiesBrief.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      // GetAll LoyaltyProgram reducers
      .addCase(getLoyaltyPrograms.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getLoyaltyPrograms.fulfilled, (state, { payload }) => {
        loyaltyProgramsAdapter.upsertMany(state, payload.results);

        state.page = payload.results.map((loyaltyProgram) => loyaltyProgram.id);
        state.status = SliceStatus.IDLE;
        state.totalPages = payload.totalPages;
      })
      .addCase(getLoyaltyPrograms.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      // Append LoyaltyProgram reducers
      .addCase(appendLoyaltyPrograms.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(appendLoyaltyPrograms.fulfilled, (state, { payload }) => {
        loyaltyProgramsAdapter.upsertMany(state, payload.results);

        state.page = [...state.page, ...payload.results.map((loyaltyProgram) => loyaltyProgram.id)];
        state.status = SliceStatus.IDLE;
        state.totalPages = payload.totalPages;
      })
      .addCase(appendLoyaltyPrograms.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      })

      // Create LoyaltyProgram reducers
      .addCase(createLoyaltyProgram.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(createLoyaltyProgram.fulfilled, (state, { payload: { loyaltyProgram } }) => {
        loyaltyProgramsAdapter.upsertOne(state, loyaltyProgram);

        state.page = [loyaltyProgram.id, ...state.page];
        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(createLoyaltyProgram.rejected, (state) => {
        return { ...state, isRequestPending: SliceStatus.FAILED };
      })

      // Update LoyaltyProgram reducer
      .addCase(updateLoyaltyProgram.pending, (state) => {
        state.isRequestPending = SliceStatus.LOADING;
      })
      .addCase(updateLoyaltyProgram.fulfilled, (state, { payload }) => {
        loyaltyProgramsAdapter.upsertOne(state, payload.loyaltyProgram);

        state.isRequestPending = SliceStatus.IDLE;
      })
      .addCase(updateLoyaltyProgram.rejected, (state) => {
        return { ...state, isRequestPending: SliceStatus.FAILED };
      })

      // Delete LoyaltyProgram reducer
      .addCase(deleteLoyaltyProgram.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(
        deleteLoyaltyProgram.fulfilled,
        (
          state,
          {
            meta: {
              arg: { id },
            },
          }
        ) => {
          state.page = state.page.filter((p) => p !== id);
          loyaltyProgramsAdapter.updateOne(state, { id, changes: { deprecated: true } });

          state.status = SliceStatus.IDLE;
        }
      )
      .addCase(deleteLoyaltyProgram.rejected, (state) => {
        return { ...state, status: SliceStatus.FAILED };
      });
  },
});

export default loyaltyProgramsSlice.reducer;
