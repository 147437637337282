import { Grid, Skeleton, Typography } from "@mui/material";

const ExistingClientInfoSkeleton = () => {
  return (
    <Grid container alignItems="center" spacing={1.5}>
      <Grid item md={6} xs={12}>
        <Typography variant="h2">
          <Skeleton variant="text" width="100%" />
        </Typography>

        <Typography variant="h5" color="text.label">
          <Skeleton variant="text" width="50%" />
        </Typography>
      </Grid>

      <Grid item md={6} xs={12}></Grid>

      {Array.from({ length: 4 }, (_, index) => (
        <Grid item md={6} xs={6} key={index}>
          <Skeleton variant="rectangular" height={24} width="100%" />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExistingClientInfoSkeleton;
