import http from "utils/http";

import { Zone } from "types/Zone";

export type WorkingHourCreate = {
  id: number;
  dayOfWeek?: string;
  startTime: string | null;
  endTime: string | null;
};

const zonesApi = {
  getZones: async () => http.get<Zone[]>("zones/zones/"),
  createZone: async (
    zone: Omit<Zone, "id" | "workingHours" | "deprecated" | "hasUpcomingAppointments">
  ) => http.post<Zone>("zones/zones/", zone),
  updateZone: async ({
    id,
    ...zone
  }: Omit<Zone, "workingHours" | "deprecated" | "hasUpcomingAppointments">) =>
    http.put<Zone>(`zones/zones/${id}/`, zone),
  getZoneDetails: async (id: number) => http.get<Zone>(`zones/zones/${id}`),
  deprecateZone: async ({ id }: { id: number }) => http.delete(`zones/zones/${id}`),
  updateWorkingHours: async ({
    zoneId,
    workingHours,
  }: {
    zoneId: number;
    workingHours: WorkingHourCreate[];
  }) => http.put<WorkingHourCreate[]>(`zones/zone-working-hours/${zoneId}/`, workingHours),
  updateOnlineBookingsStatus: async ({
    zoneId,
    isAcceptingOnlineBookings,
  }: {
    zoneId: number;
    isAcceptingOnlineBookings: boolean;
  }) => http.put(`zones/zones/${zoneId}/`, { isAcceptingOnlineBookings }),
};

export default zonesApi;
