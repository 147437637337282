import type { RootState } from "store";
import { VoucherPurchase } from "types/VoucherPurchase";

import { selectClientById } from "./clientsSlice";

export const selectActiveVouchersByClientId =
  (id: number | string) =>
  (state: RootState): VoucherPurchase[] => {
    const client = selectClientById(Number(id))(state);

    if (!client) return [];

    return client?.vouchersData || [];
  };

export const selectActiveVouchersByClientIdAndOutletId =
  (clientId: number, outletId: number) =>
  (state: RootState): VoucherPurchase[] => {
    const clientActiveVouchers = selectActiveVouchersByClientId(clientId)(state);

    return clientActiveVouchers.filter((voucher: VoucherPurchase) =>
      voucher.outlets.includes(outletId)
    );
  };

export const selectRedeemableVouchersByClientIdAndOutletIdAndServiceVariantIds =
  (clientId: number, outletId: number, cartServiceVariantIds: number[]) =>
  (state: RootState): VoucherPurchase[] => {
    const clientActiveVouchers = selectActiveVouchersByClientIdAndOutletId(
      clientId,
      outletId
    )(state);

    const redeemableVouchers = clientActiveVouchers.filter((voucher: VoucherPurchase) => {
      if (voucher.hasAllServices) return true;
      else {
        // check if any of the cart service variant ids are in the voucher service variant ids
        return cartServiceVariantIds.some((cartServiceVariantId) =>
          voucher.serviceVariants.includes(cartServiceVariantId)
        );
      }
    });

    return redeemableVouchers;
  };

export const selectWalletDetailsByClientId =
  (clientId: number | string, isOnline: boolean) => (state: RootState) => {
    const client = selectClientById(Number(clientId))(state);

    if (client) {
      if (isOnline && client.onlineWallet) return client.onlineWallet;
      else if (!isOnline && client.offlineWallet) return client.offlineWallet;
    }
  };

export const selectClientAddressByClientIdAndAddressId =
  (clientId: number, addressId: number) => (state: RootState) => {
    const client = selectClientById(clientId)(state);

    if (!client) return;

    return client?.addresses?.find((address) => address.id === addressId);
  };

export const selectClientAddressesByClientId = (clientId: number) => (state: RootState) => {
  const client = selectClientById(clientId)(state);

  if (!client) return;

  return client?.addresses?.filter((address) => !address.deprecated) || [];
};
