import { yupResolver } from "@hookform/resolvers/yup";
import combineDateToTime from "helpers/combineDateToTime";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import { DateTime } from "luxon";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericDispatch } from "store";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectResourcesByOutletId } from "store/selectors";
import { getActivePromotions } from "store/slices/activePromotions/activePromotionsSlice";
import { AppointmentStatusOptions, GetAppointmentDetailsResponse } from "types/Appointment";
import auth from "utils/auth";
import { appointmentFormSchema } from "utils/formValidation/schemas";
import useNotify from "utils/notifications/useNotify";

import {
  AppointmentFormValues,
  FormClient,
  compileAppointment,
  createAppointmentFormDefaultValues,
  translateAppointmentToFormAppointment,
} from "./utils";

const unimplementedFunction = () => {
  throw new Error("This function can't be called before it's initialization");
};

type StartNewAppointmentArgs = {
  staffId?: number;
  startTime?: DateTime;
  resourceId?: number;
  client?: FormClient;
};

type AppointmentFormState = {
  startNewAppointment: (args: StartNewAppointmentArgs) => void;
  editAppointment: (appointmentResponse: GetAppointmentDetailsResponse) => void;
  closeAndResetForm: () => void;
  isRequestPending: boolean;
  setIsRequestPending: Dispatch<SetStateAction<boolean>>;

  setAppointmentDate: (date: DateTime) => void;
};

const initialState: AppointmentFormState = {
  startNewAppointment: unimplementedFunction,
  editAppointment: unimplementedFunction,
  closeAndResetForm: unimplementedFunction,

  isRequestPending: false,
  setIsRequestPending: unimplementedFunction,

  setAppointmentDate: unimplementedFunction,
};

const AppointmentFormContext = createContext<AppointmentFormState>(initialState);

export default AppointmentFormContext;

function AppointmentFormContextProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation(["translation", "appointments"]);
  const dispatch = useDispatch<GenericDispatch>();
  const navigate = useNavigate();
  const notify = useNotify();
  const { isAppointmentDrawerOpen, openAppointmentDrawerCreateMode, closeAppointmentDrawer } =
    useAppointmentDrawer();

  const { hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const canAssignSelfOnly = !hasNonPersonalAppointment.editAccess && !!authenticatedUserEmployeeId;

  const { setOutletId, date, outletId, setDate } = useContext(CalendarPageContext);

  const outletNotDeprecatedResources = useSelector(selectResourcesByOutletId(outletId ?? 0));

  const methods = useForm<AppointmentFormValues>({
    defaultValues: canAssignSelfOnly
      ? createAppointmentFormDefaultValues({ staffId: authenticatedUserEmployeeId })
      : createAppointmentFormDefaultValues(),
    // @ts-expect-error
    resolver: yupResolver(appointmentFormSchema(t)),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { setValue, reset, watch } = methods;

  const calendarDateToIsoFormat = date.toFormat("yyyy-MM-dd");

  useEffect(() => {
    if (
      DateTime.fromISO(DateTime.now().toFormat("yyyy-MM-dd")) <=
        DateTime.fromISO(calendarDateToIsoFormat) &&
      isAppointmentDrawerOpen
    ) {
      dispatch(getActivePromotions({ date: calendarDateToIsoFormat, outletId }));
    }
  }, [outletId, dispatch, calendarDateToIsoFormat, isAppointmentDrawerOpen]);

  const closeAndResetForm = useCallback(() => {
    reset(
      canAssignSelfOnly
        ? createAppointmentFormDefaultValues({ staffId: authenticatedUserEmployeeId })
        : createAppointmentFormDefaultValues()
    );

    closeAppointmentDrawer();
    navigate("/calendar");
  }, [authenticatedUserEmployeeId, canAssignSelfOnly, closeAppointmentDrawer, navigate, reset]);

  const startNewAppointment = ({
    staffId,
    startTime,
    resourceId,
    client,
  }: StartNewAppointmentArgs) => {
    if (isAppointmentDrawerOpen) return;

    const articleData = createAppointmentFormDefaultValues({
      staffId,
      startTime,
      resourceId,
      client,
    });

    openAppointmentDrawerCreateMode({
      outletId,
      clientId: client?.id,
    });

    reset(articleData);
  };

  const editAppointment = (appointmentResponse: GetAppointmentDetailsResponse) => {
    const { appointment } = appointmentResponse;

    if (
      appointment.status === AppointmentStatusOptions.Completed ||
      appointment.status === AppointmentStatusOptions.Canceled
    ) {
      notify(t("cannotEditCompletedAppointment:cannotEditCompletedAppointment"), "error");
      return;
    }

    const compiledAppointment = compileAppointment(
      appointmentResponse.appointment,
      appointmentResponse.articles,
      appointmentResponse.slots
    );

    const formValues = translateAppointmentToFormAppointment(
      compiledAppointment,
      outletNotDeprecatedResources
    );

    setOutletId(appointmentResponse.appointment.outlet);
    setDate(DateTime.fromFormat(appointmentResponse.appointment.date, "yyyy-MM-dd"));
    reset(formValues);
  };

  const setAppointmentDate = (date: DateTime) => {
    const articles = watch("articles").map((article) => ({
      ...article,
      slots: article.slots.map((slot) => {
        const startTime = combineDateToTime(date, slot.startTime);
        const endTime = combineDateToTime(date, slot.endTime);
        return { ...slot, startTime, endTime };
      }),
    }));

    setValue("articles", articles);
  };

  return (
    <AppointmentFormContext.Provider
      value={{
        startNewAppointment,
        editAppointment,
        closeAndResetForm,

        isRequestPending,
        setIsRequestPending,

        setAppointmentDate,
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </AppointmentFormContext.Provider>
  );
}

export { AppointmentFormContextProvider };
