import http from "utils/http";

export type SMSReminder = {
  id: number;
  isSmsActive: boolean;
  appointmentConfirmation: boolean;
  appointmentReminder: boolean;
  appointmentReminderTime: number;
  appointmentReschedule: boolean;
  smsCredit: number;
  organization: number;
};

export type UpdateSMSReminderArguments = {
  id?: number;
  isSmsActive?: boolean;
  appointmentConfirmation?: boolean;
  appointmentReminder?: boolean;
  appointmentReminderTime?: number;
  appointmentReschedule?: boolean;
  smsCredit?: number;
  organization?: number;
};

const smsRemindersApi = {
  getSmsReminderOptions: async () => http.get<SMSReminder>("organization_settings/sms_settings/"),
  updateSmsReminderOptions: async (data: UpdateSMSReminderArguments) => http.put<SMSReminder>("organization_settings/sms_settings/", data)
};

export default smsRemindersApi;