import { Box, Grid, styled } from "@mui/material";
import PeepTable from "components/common/PeepTable/PeepTable";
import TableSideBar from "components/common/PeepTable/TableSideBar";
import { TableItem } from "components/common/PeepTable/TableTypes";
import InfinitePagination from "components/common/Tables/InfinitePagination";
import Pagination from "components/common/Tables/Pagination";
import useAppointmentDrawer from "hooks/useAppointmentDrawer";
import useIsMobileView from "hooks/useIsMobileView";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectArePeepPayLinksLoading,
  selectPeepPayLinksTotalPages,
  selectPeepPayLinksWithDetails,
} from "store/selectors";
import { PeepPayLinkType } from "store/slices/peepPayLinks/peepPayLinksApi";

import PeepPayLinksContext from "../PeepPayLinksContext";
import PeepPayLinksSideBarFilters from "../PeepPayLinksSideBarFilters";
import { peepPayLinkRows } from "./peepPayLinkRows";
import { tableHeaders } from "./tableHeaders";

const PeepPayLinksTable = () => {
  const { t } = useTranslation("home");

  const { openAppointmentDrawerViewMode } = useAppointmentDrawer();

  const isMobileView = useIsMobileView();

  const results = useSelector(selectPeepPayLinksWithDetails) || [];
  const isLoading = useSelector(selectArePeepPayLinksLoading);
  const totalPages = useSelector(selectPeepPayLinksTotalPages);

  const {
    page,
    setPage,
    ordering,
    setOrdering,
    searchQuery,
    type,
    handlePageChange,
    handleLoadMore,
  } = useContext(PeepPayLinksContext);

  const handleOpenAppointmentDetailDrawer = (appointmentId: number) => {
    openAppointmentDrawerViewMode({
      appointmentId,
    });
  };

  const isDeposit = type === PeepPayLinkType.Deposit;

  const items = peepPayLinkRows(
    results,
    t,
    handleOpenAppointmentDetailDrawer,
    isDeposit
  ) as TableItem[];

  return (
    <PageWrapper>
      <Grid container>
        <Grid container item xs={12} spacing={1}>
          {!isMobileView && (
            <Grid item sm={3} md={2.5} lg={2} xs={12}>
              <TableSideBar
                toOpenTitle={t("allPeepPayLinks")}
                toCloseTitle={t("backToPeepPayLinks")}
              >
                <PeepPayLinksSideBarFilters />
              </TableSideBar>
            </Grid>
          )}

          <Grid item sm={12} md={9.5} lg={10} xs={12}>
            <TableWrapper height="100%" sx={{ backgroundColor: "white" }}>
              <PeepTable
                maxHeight="100%"
                isLoading={isLoading}
                headers={tableHeaders(t)}
                items={items}
                ordering={ordering}
                setOrdering={setOrdering}
                setPage={setPage}
                emptySectionTitle={searchQuery ? t("noResultsFound") : t("noPeepPayLinksAvailable")}
              />
            </TableWrapper>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <InfinitePagination
              totalPages={totalPages}
              page={page}
              loadMore={(e: number) => handleLoadMore(e)}
              loading={isLoading}
            />
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Pagination
              count={totalPages}
              page={page}
              setPage={(e: number) => {
                handlePageChange(e);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default PeepPayLinksTable;

const PageWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  [theme.breakpoints.down("md")]: {
    padding: 0,
    ".MuiTableCell-root": {
      minWidth: "112px",
    },
  },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  borderRadius: "10px",
}));
