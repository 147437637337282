import { PurchasableItemOption } from "types/PurchasableItem";
import { VoucherPurchase } from "types/VoucherPurchase";

import { CheckoutArticle } from "../CheckoutFormContext";

/**
 * This function returns the service variant ids of the services in the cart
 * @param articles
 * @returns
 */

export function getCartServiceVariantIds(articles: CheckoutArticle[]) {
  const servicesInCart = articles.filter(
    (article) =>
      article.purchasableItemType === PurchasableItemOption.Service &&
      !isNaN(Number(article.purchasedItem?.serviceVariant))
  );

  const cartServiceVariantIds = servicesInCart.map((article) =>
    Number(article.purchasedItem.serviceVariant)
  );

  return cartServiceVariantIds;
}

/**
 * This return the array of serviceVariantIds that are redeemable by the voucher
 * @param voucher
 * @param articles
 * @returns
 */

function getCartServicesThatAreRedeemableByVoucher(
  voucher: VoucherPurchase,
  articles: CheckoutArticle[]
) {
  const cartServiceVariantIds = getCartServiceVariantIds(articles);

  const voucherServiceVariantIds = voucher.serviceVariants;

  const cartServicesThatAreRedeemableByVoucher = cartServiceVariantIds.filter(
    (cartServiceVariantId) =>
      voucher.hasAllServices || voucherServiceVariantIds.includes(cartServiceVariantId)
  );

  return cartServicesThatAreRedeemableByVoucher;
}

/**
 * This function returns the total redeemable amount by the voucher based on the applicable services in the cart
 * @param voucher
 * @param articles
 * @returns
 */

function getCartServicesTotalRedeemableAmountByVoucher(
  voucher: VoucherPurchase,
  articles: CheckoutArticle[]
) {
  const cartServicesThatAreRedeemableByVoucher = getCartServicesThatAreRedeemableByVoucher(
    voucher,
    articles
  );

  const totalRedeemableAmountByVoucher = articles.reduce((totalPrice, article) => {
    if (
      article.purchasableItemType === PurchasableItemOption.Service &&
      cartServicesThatAreRedeemableByVoucher.includes(Number(article.purchasedItem?.serviceVariant))
    ) {
      return totalPrice + article.finalPrice || 0;
    } else return totalPrice;
  }, 0);

  // compare with voucher available amount
  if (voucher.availableBalance >= totalRedeemableAmountByVoucher)
    return totalRedeemableAmountByVoucher;
  else return voucher.availableBalance;
}

/**
 * This function returns the maximum redeemable amount based on the cart redeemable items, voucher available balance and the balance of the checkout payments, considering other applied vouchers.
 * @param voucher
 * @param articles
 * @param balance checkout balance
 * @param appliedVoucherPurchases
 * @param appliedVoucherRedemptions
 *
 * @returns maximum redeemable amount
 */

export function getMaxRedeemableAmountBasedOnCartAndBalance(
  voucher: VoucherPurchase | null,
  articles: CheckoutArticle[],
  balance: number
) {
  const totalPriceOfCartItemsRedeemableBySelectedVoucher = voucher
    ? getCartServicesTotalRedeemableAmountByVoucher(voucher, articles)
    : 0;

  const selectedVoucherAvailableBalance = Number(voucher?.availableBalance) || 0;

  if (balance === 0) return 0;

  if (selectedVoucherAvailableBalance > totalPriceOfCartItemsRedeemableBySelectedVoucher) {
    if (balance >= totalPriceOfCartItemsRedeemableBySelectedVoucher)
      return totalPriceOfCartItemsRedeemableBySelectedVoucher;

    if (balance >= selectedVoucherAvailableBalance) return selectedVoucherAvailableBalance;
  }

  if (selectedVoucherAvailableBalance <= totalPriceOfCartItemsRedeemableBySelectedVoucher) {
    if (balance >= selectedVoucherAvailableBalance) return selectedVoucherAvailableBalance;
  }

  if (
    balance <= selectedVoucherAvailableBalance &&
    balance <= totalPriceOfCartItemsRedeemableBySelectedVoucher
  )
    return balance;

  // fallback value tto get proper return type as number and for cases that are not covered by the above conditions, this should never happen "i guess.." - Anfal The Great 4-4-2023
  return 0;
}
