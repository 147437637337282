import { Box, Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import ExistingClientInfoSkeleton from "components/ClientSection/ExistingClientInfo/ExistingClientInfoSkeleton";
import { memo } from "react";

import AppointmentDetailsToolbarSkeleton from "./AppointmentDetailsToolbar/AppointmentDetailsToolbar.skeleton";
import AppointmentHistoryLogsAppointmentHistoryLogsSkeleton from "./AppointmentHistoryLogs.skeleton";

const AppointmentDetailsSkeleton = () => {
  return (
    <Stack height="100%" minHeight="100%">
      <Box boxShadow="divider" p={3}>
        <ExistingClientInfoSkeleton />
      </Box>

      <Stack overflow="scroll" width="100%" px={3} pb={2} flexGrow={1} gap={2}>
        <Stack width="100%" py={2} gap={3}>
          <AppointmentDetailsToolbarSkeleton />

          <Typography variant="h1">
            <Skeleton variant="text" width="35%" />
          </Typography>
        </Stack>

        <Stack flexGrow={1} pb={3} gap={2}>
          {Array.from({ length: 4 }, (_, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item md={2} xs={12}>
                <Typography variant="secondary" display="block">
                  <Skeleton variant="text" width="100%" />
                </Typography>

                <Typography variant="secondary" color="text.label">
                  <Skeleton variant="text" width="80%" />
                </Typography>

                <Typography variant="secondary" color="text.label">
                  <Skeleton variant="text" width="90%" />
                </Typography>
              </Grid>

              <Grid item md={8} xs={12}></Grid>

              <Grid item md={2} xs={12}>
                <Typography variant="h5" display="flex" justifyContent="flex-end">
                  <Skeleton variant="text" height={40} width="100%" />
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>

        <AppointmentHistoryLogsAppointmentHistoryLogsSkeleton />
      </Stack>

      <Box p={3} boxShadow="topDivider" textAlign="center">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" py={1} display="flex" justifyContent="center">
              <Skeleton variant="text" width={190} />
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button fullWidth variant="outlined" disabled>
              <Skeleton variant="rectangular" />
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Button fullWidth variant="contained" disabled>
              <Skeleton variant="rectangular" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default memo(AppointmentDetailsSkeleton);
