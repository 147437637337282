import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { getEmployeeFullName } from "helpers/employees";
import { selectAuth } from "store/auth/authSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectAllOwners } from "store/owners/ownersSlice";
import auth from "utils/auth";
import createThunkFromApi from "utils/createThunkFromApi";

import createThunkFromApiWithType from "../utils/createThunkFromApiWithType";
import api from "./employeesApi";

const employeesAdapter = createEntityAdapter();

const initialState = employeesAdapter.getInitialState({
  isLoading: false,
  isRequestPending: false,
  error: "",
});

const { selectById, selectAll } = employeesAdapter.getSelectors((state) => state.employees);

export const selectAllEmployees = selectAll;

export const selectEmployeeById = (id) => (state) => selectById(state, id);

export const selectEmployeeFullNameById = (id) => (state) => {
  const employee = selectById(state, id);
  if (!employee) return null;
  return getEmployeeFullName(employee);
};

export const selectEmployeeLoading = (state) => state.employees.isLoading;

export const selectAllActiveEmployees = createSelector(selectAll, (employees) =>
  employees.filter((employee) => !employee.deprecated)
);

export const selectUsersFromEmployees = (state) => {
  const employeeUsers = selectAll(state)
    .filter((employee) => !employee.deprecated && !!employee.user)
    .map((employee) => employee.user);

  const ownerUsers = selectAllOwners(state).map((owner) => owner.user);

  const currentUser = selectAuth(state);

  return [...new Set([currentUser, ...ownerUsers, ...employeeUsers])];
};

export const selectUserByIdFromEmployees = (userId) =>
  createSelector(selectUsersFromEmployees, (employees) =>
    employees.filter((user) => Boolean(user)).find((user) => user.id === userId)
  );

export const selectActiveEmployeesByOutletId = (outletId) =>
  createSelector(selectAllActiveEmployees, (activeEmployees) =>
    activeEmployees.filter((employee) => employee.outlets.includes(outletId))
  );

export const selectEmployeesByIds = (ids, outletId) =>
  createSelector(selectCalendarEmployeesByOutletId(outletId), (activeEmployees) =>
    activeEmployees.filter((employee) => ids.includes(employee.id))
  );

export const selectAllCalendarEmployees = createSelector(selectAll, (employees) =>
  employees.filter((employee) => !employee.deprecated && employee.hasCalendar)
);

export const selectAllCalendarEmployeesCurrentAuthenticatedUserCanAccess = (state) => {
  const allCalendarEmployees = selectAllCalendarEmployees(state);

  const { hasPersonalAppointment, hasNonPersonalAppointment } = selectAllPermissions(state);

  const canViewAllEmployees = hasNonPersonalAppointment.viewAccess;

  const canViewThemselvesOnly = hasPersonalAppointment.viewAccess && !canViewAllEmployees;

  if (canViewAllEmployees) {
    return allCalendarEmployees;
  } else if (canViewThemselvesOnly) {
    return allCalendarEmployees.filter((employee) => employee.id === auth.userEmployeeId);
  } else {
    return []; // if none of the above is true, then the user can't access any employee for some possessed reason 🤨
  }
};

export const selectCalendarEmployeesByOutletId = (outletId) =>
  createSelector(selectAllCalendarEmployeesCurrentAuthenticatedUserCanAccess, (activeEmployees) =>
    activeEmployees.filter((employee) => employee.outlets.includes(outletId))
  );

export const selectAppointmentFormEmployeesByOutletIdAndZoneId = (outletId, zoneId) =>
  createSelector(selectAllCalendarEmployees, (activeEmployees) =>
    activeEmployees.filter((employee) => {
      if (zoneId) {
        return employee.outlets.includes(outletId) && employee.zones.includes(zoneId);
      }
      return employee.outlets.includes(outletId);
    })
  );

export const getAllEmployees = createThunkFromApi("employees/getAllEmployees", api.getAllEmployees);

export const getEmployee = createThunkFromApi("employees/getEmployee", api.getEmployee);

export const createEmployee = createThunkFromApiWithType(
  "employees/createEmployee",
  api.createEmployee
);

export const updateEmployee = createThunkFromApiWithType(
  "employees/updateEmployee",
  api.updateEmployee
);

export const deleteEmployee = createThunkFromApiWithType(
  "employees/removeEmployee",
  api.removeEmployee
);

export const employeesSlice = createSlice({
  name: "employees",
  initialState,

  extraReducers: (reducers) => {
    reducers
      .addCase(getAllEmployees.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(getAllEmployees.fulfilled, (state, payload) => {
        state.isLoading = false;
        state.error = "";

        employeesAdapter.setAll(state, payload);
      })

      .addCase(getAllEmployees.rejected, (state, { payload }) => {
        return {
          ...state,
          error: payload?.detail,
        };
      })

      .addCase(getEmployee.fulfilled, (state, { payload }) => {
        employeesAdapter.setOne(state, payload);
      })

      .addCase(createEmployee.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(createEmployee.fulfilled, (state, { payload }) => {
        employeesAdapter.addOne(state, payload);
        state.isRequestPending = false;
      })

      .addCase(createEmployee.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(updateEmployee.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        employeesAdapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });
        state.isRequestPending = false;
      })

      .addCase(updateEmployee.rejected, (state) => {
        state.isRequestPending = false;
      })

      .addCase(deleteEmployee.pending, (state) => {
        state.isRequestPending = true;
      })

      .addCase(deleteEmployee.fulfilled, (state, { meta: { arg } }) => {
        const { id } = arg;
        employeesAdapter.removeOne(state, id);

        state.isRequestPending = false;
      })
      .addCase(deleteEmployee.rejected, (state) => {
        state.isRequestPending = false;
      });
  },
});

export default employeesSlice.reducer;
