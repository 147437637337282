import { Box, Grid } from "@mui/material";
import { PeepAddIconButton } from "components/common";
import useCheckoutModal from "hooks/useCheckoutModal";
import useIsMobileView from "hooks/useIsMobileView";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";
import { PurchasableItemOption } from "types/PurchasableItem";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPaymentsSummary from "../CheckoutPaymentsSummary";
import CheckoutCartItem from "./CheckoutCartItem";

const CheckoutCart = () => {
  const { t } = useTranslation("checkout");
  const isMobileView = useIsMobileView();

  const { cartItemFields, removeCartItem, setValue, watch } = useCheckoutFormContext();

  const { setCheckoutModalView, checkoutModalView, isDefaultDesktopView } = useCheckoutModal();

  const handleAddItems = () => {
    setCheckoutModalView(CheckoutModalView.AddItems);
  };

  const articleAppointmentCount = cartItemFields.filter(
    (article) => !!article.appointmentArticleId
  ).length;

  const handleRemoveItem = (index: number) => {
    const appointmentArticleId = cartItemFields[index].appointmentArticleId;
    if (articleAppointmentCount === 1 && !!appointmentArticleId) return;

    removeCartItem(index);

    if (!!appointmentArticleId)
      setValue("deletedArticles", [...watch("deletedArticles"), appointmentArticleId]);

    // must reset payments when an item is removed
    setValue("payments", []);

    // Reset applied voucherRedemptions when a service is removed.
    // When vouchers support other items, this condition must be modified accordingly
    const isService = cartItemFields[index].purchasableItemType === PurchasableItemOption.Service;
    if (isService) setValue("voucherRedemptions", []);
  };

  const shouldShowAddItemsButton = !isMobileView && checkoutModalView === CheckoutModalView.Payment;

  const shouldShowPaymentsSummary =
    !(checkoutModalView === CheckoutModalView.AddItems || isDefaultDesktopView) && !isMobileView;

  return (
    <Box p={{ xs: 1, md: 4 }}>
      {cartItemFields.map((field, cartItemIndex) => (
        <CheckoutCartItem
          key={field.id}
          purchasableItem={field.purchasedItem}
          purchasableItemType={field.purchasableItemType}
          cartItemIndex={cartItemIndex}
          disableRemove={articleAppointmentCount === 1 && !!field.appointmentArticleId}
          removeItem={() => handleRemoveItem(cartItemIndex)}
        />
      ))}

      <Grid container justifyContent="space-between" pt={2}>
        <Grid item md={4} xs={12}>
          {shouldShowAddItemsButton && (
            <PeepAddIconButton label={t("addItemsToSale")} onClick={handleAddItems} />
          )}
        </Grid>

        {shouldShowPaymentsSummary && (
          <Grid item md={5} xs={12} pr={{ md: 4 }}>
            <CheckoutPaymentsSummary />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CheckoutCart;
