import { Heart } from "@bookpeep/ui";
import { Grid } from "@mui/material";
import { PeepTimeField } from "components/common";
import { getFinalPriceAfterDiscount } from "helpers/getFinalPriceAfterDiscount";
import { DateTime } from "luxon";
import CalendarPageContext from "pages/CalendarPage/CalendarPageContext";
import { memo, useContext, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import {
  selectActivePromotionsLoading,
  selectLatestServiceVariantActivePromotionByServiceVariantId,
  selectServiceVariantByIdWithService,
} from "store/selectors";
import { selectAreaById } from "store/slices/areas/areasSelector";
import { selectDoesTimeHaveConflict } from "store/slices/calendar/calendarSelectors";
import { AppointmentSource } from "types/Appointment";
import auth from "utils/auth";

import AppointmentFormContext from "../../AppointmentFormContext";
import { AppointmentFormValues, FormArticleSlot } from "../../utils";
import { ServicesSearchField } from "../ServicesSearchField";
import {
  OnSelectPackageVariantFunction,
  OnSelectServiceVariantFunction,
} from "../ServicesSearchField/ServicesSearchFieldContext";
import EmployeeAutoComplete from "./EmployeeAutoComplete";
import ResourceAutoComplete from "./ResourceAutoComplete";

function useField<T>(fieldName: string, articleIndex: number, slotIndex?: number) {
  const { watch, setValue } = useFormContext();

  const fieldPath =
    typeof slotIndex === "number" && slotIndex > -1
      ? `articles.${articleIndex}.slots.${slotIndex}.${fieldName}`
      : `articles.${articleIndex}.${fieldName}`;

  const fieldValue = watch(fieldPath);
  const setFieldValue = (newValue: T | null) => setValue(fieldPath, newValue);

  return [fieldValue, setFieldValue] as [T, (value: T | null) => void];
}

type SlotFormProps = {
  articleIndex: number;
  slotIndex?: number;

  disableVariantField?: boolean;

  onChangeVariant?: (type: "service" | "package") => void;
  addNewArticle: () => void;
};

const calculateEndTime = (startTime: DateTime, duration: number) => {
  const supposedEndTime = startTime.plus({ minutes: duration });

  // prevent overnight slots
  if (supposedEndTime.hasSame(startTime, "day")) return supposedEndTime;
  else return startTime.endOf("day");
};

function SlotForm({
  articleIndex,
  slotIndex = 0,
  disableVariantField,
  addNewArticle,
}: SlotFormProps) {
  const { t } = useTranslation("appointments");
  const { outletId, date } = useContext(CalendarPageContext);

  const today = DateTime.now();

  const isDateInThePast = date.endOf("day") < today.endOf("day");

  const formattedIsoDate = date.toFormat("yyyy-MM-dd");

  const {
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext<AppointmentFormValues>();

  const { area: areaId } = watch();

  const area = useSelector(selectAreaById(areaId || 0));

  const isEditingAppointment = !!useWatch({ name: "appointmentId" });

  const { isRequestPending } = useContext(AppointmentFormContext);

  const { hasPersonalAppointment, hasNonPersonalAppointment } = useSelector(selectAllPermissions);

  const authenticatedUserEmployeeId = auth.userEmployeeId;

  const [articlePromotion, setArticlePromotion] = useField<number>("promotion", articleIndex);

  const [articleServiceVariant, setArticleServiceVariantId] = useField<number>(
    "serviceVariantId",
    articleIndex
  );

  const [articleCreationSource, setArticleCreationSource] = useField<AppointmentSource>(
    "creationSource",
    articleIndex
  );

  const [articlePackageVariantId, setArticlePackageVariantId] = useField<number>(
    "packageVariantId",
    articleIndex
  );

  const [articlePrice, setArticlePrice] = useField<number>("price", articleIndex);

  const setArticleFinalPrice = useField<number>("finalPrice", articleIndex)[1];

  const setArticleSlots = useField<FormArticleSlot[]>("slots", articleIndex)[1];

  const [slotServiceVariantId, setSlotServiceVariantId] = useField<number>(
    "serviceVariantId",
    articleIndex,
    slotIndex
  );
  const setArticleFlexService = useField<Nullable<number>>("flexService", articleIndex)[1];

  const serviceVariantLatestPromotion = useSelector(
    selectLatestServiceVariantActivePromotionByServiceVariantId(slotServiceVariantId || "")
  );

  const areServiceVariantLatestPromotionsFetchedLoading = useSelector(
    selectActivePromotionsLoading(outletId, formattedIsoDate)
  );

  // If there's a valid promotion for service variant for the appointment date, apply it to the article and modify the final otherwise reset the article promotion and final price, packages are handled on another file (PackageArticleItem.ts)
  useEffect(() => {
    if (!isEditingAppointment) {
      if (!disableVariantField && articleServiceVariant) {
        if (
          serviceVariantLatestPromotion &&
          !areServiceVariantLatestPromotionsFetchedLoading &&
          !isDateInThePast
        ) {
          if (articlePromotion !== serviceVariantLatestPromotion.promotion) {
            setArticlePromotion(serviceVariantLatestPromotion.promotion);
            setArticleFinalPrice(
              getFinalPriceAfterDiscount({
                originalPrice: articlePrice,
                discountOption: serviceVariantLatestPromotion.discountType,
                discountValue: serviceVariantLatestPromotion.value,
              })
            );
          }
        } else if (articlePromotion !== null) {
          setArticlePromotion(null);
          setArticleFinalPrice(articlePrice);
        }
      }
    }
  }, [
    areServiceVariantLatestPromotionsFetchedLoading,
    articlePrice,
    articlePromotion,
    articleServiceVariant,
    disableVariantField,
    isDateInThePast,
    serviceVariantLatestPromotion,
    setArticleFinalPrice,
    setArticlePromotion,
    isEditingAppointment,
  ]);

  const slotServiceVariant = useSelector(
    selectServiceVariantByIdWithService(slotServiceVariantId || 0)
  );

  const [isStaffSelectedByClient, setIsStaffSelectedByClient] = useField<boolean>(
    "isStaffSelectedByClient",
    articleIndex,
    slotIndex
  );

  const [staffId, setStaffId] = useField<number>("staffId", articleIndex, slotIndex);

  const [isResourceRequired, setIsResourceRequired] = useField<boolean>(
    "isResourceRequired",
    articleIndex,
    slotIndex
  );
  const [resourceId, setResourceId] = useField<Nullable<number> | undefined>(
    "resourceId",
    articleIndex,
    slotIndex
  );

  const [startTime, setStartTime] = useField<DateTime>("startTime", articleIndex, slotIndex);
  const [endTime, setEndTime] = useField<DateTime>("endTime", articleIndex, slotIndex);
  const currentSlotId = useField<number>("id", articleIndex, slotIndex)[0];

  const articleSlotsConflicts = useSelector(
    selectDoesTimeHaveConflict(startTime, endTime, staffId, resourceId || undefined, currentSlotId)
  );

  const selectServiceAndModifyEndTime: OnSelectServiceVariantFunction = (
    serviceVariant,
    finalPrice,
    isResourceRequired = false
  ) => {
    setArticleServiceVariantId(serviceVariant?.id || null);
    setArticlePackageVariantId(null);
    setArticleFlexService(null);
    setArticleCreationSource(
      articleServiceVariant !== serviceVariant?.id ? AppointmentSource.PP : articleCreationSource
    );

    setArticlePrice(parseFloat(serviceVariant?.price ?? "0") || 0);

    setArticleFinalPrice(finalPrice || 0);

    setSlotServiceVariantId(serviceVariant?.id || null);

    setIsResourceRequired(isResourceRequired);

    // This is required to show the form error message that the resource is required when selecting a service variant that requires a resource
    if (isResourceRequired && !resourceId && !!serviceVariant) {
      setResourceId(null);
    }

    clearErrors(`articles.${articleIndex}.slots.${slotIndex}.serviceVariantId`);

    if (serviceVariant) {
      // prevent overnight slots
      const endTime = calculateEndTime(startTime, serviceVariant.duration);
      setEndTime(endTime);
    } else {
      // the service variant was removed, need to update end time top match start time, so that it does not ruin the appointment duration calculation
      setEndTime(startTime);
    }

    addNewArticle();
  };

  const selectPackageAndInitializeSlots: OnSelectPackageVariantFunction = (
    packageVariant,
    finalPrice
  ) => {
    let slots = [];
    if (!packageVariant) {
      setArticleSlots([]);
      setArticleServiceVariantId(null);
      setArticlePackageVariantId(null);
      setArticleFlexService(null);

      setArticlePrice(0);
      setArticleFinalPrice(0);
      return;
    }

    if (packageVariant.parallel) {
      slots = packageVariant.serviceVariants.map((serviceVariant) => ({
        isStaffSelectedByClient: false,
        serviceVariantId: serviceVariant.id,
        staffId: null,
        startTime,
        resourceId: null,
        isResourceRequired: serviceVariant.service.needResources,
        endTime: calculateEndTime(startTime, serviceVariant.duration), // prevent overnight slots
      }));
    } else {
      let timeBubble = startTime;

      slots = packageVariant.serviceVariants.map((serviceVariant) => {
        const startTime = timeBubble;
        timeBubble = calculateEndTime(startTime, serviceVariant.duration);

        const isStartTimeAtMidnight = startTime.equals(startTime.endOf("day"));

        return {
          isStaffSelectedByClient: false,
          serviceVariantId: serviceVariant.id,
          staffId: null,
          startTime: isStartTimeAtMidnight ? startTime.minus({ minutes: 4 }) : startTime,
          endTime: timeBubble,
          resourceId: null,
          isResourceRequired: serviceVariant.service.needResources,
        };
      });
    }

    setArticleCreationSource(
      articlePackageVariantId !== packageVariant?.id ? AppointmentSource.PP : articleCreationSource
    );
    setArticleSlots(slots);
    setArticleServiceVariantId(null);
    setArticleFlexService(null);
    setArticlePackageVariantId(packageVariant?.id || null);

    setArticlePrice(packageVariant?.price || 0);
    setArticleFinalPrice(finalPrice || 0);

    clearErrors(`articles.${articleIndex}.slots.${slotIndex}.serviceVariantId`);

    addNewArticle();
  };

  const handleStaffChange = (id: number | null) => {
    setStaffId(id);
    setIsStaffSelectedByClient(false);

    clearErrors(`articles.${articleIndex}.slots.${slotIndex}.staffId`);
  };

  const handleResourceChange = (id: number | null) => {
    setResourceId(id);

    clearErrors(`articles.${articleIndex}.slots.${slotIndex}.resourceId`);
  };

  const setStartTimeAndModifyEndTime = (startTime: DateTime) => {
    const endTimeBasedOnDuration = startTime.plus({ minutes: slotServiceVariant?.duration });

    setStartTime(startTime);

    // prevent overnight slots
    if (endTimeBasedOnDuration.hasSame(startTime, "day")) setEndTime(endTimeBasedOnDuration);
    else setEndTime(startTime.endOf("day"));
  };

  const isUserAssignedToTheSlot = staffId === authenticatedUserEmployeeId;

  const canEditSlot =
    hasNonPersonalAppointment.editAccess ||
    (isUserAssignedToTheSlot && hasPersonalAppointment.editAccess);

  const isEmptySlot = !slotServiceVariantId;

  const canAssignSelfOnly = !hasNonPersonalAppointment.editAccess && !!authenticatedUserEmployeeId;

  const shouldDisableEditing = !isEmptySlot && !canEditSlot;

  const shouldDisableEmployeeSelection = shouldDisableEditing || canAssignSelfOnly;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ServicesSearchField
          serviceVariantId={slotServiceVariantId}
          onSelectServiceVariant={selectServiceAndModifyEndTime}
          onSelectPackageVariant={selectPackageAndInitializeSlots}
          staffIdToFilterBy={staffId}
          resourceToFilterBy={resourceId}
          disabled={disableVariantField || shouldDisableEditing}
          error={!!errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.serviceVariantId}
          helperText={
            errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.serviceVariantId?.message
          }
        />
      </Grid>

      <Grid item xl={6} md={12} xs={12}>
        <PeepTimeField
          disabled={shouldDisableEditing}
          label={t("start")}
          value={startTime}
          onChange={setStartTimeAndModifyEndTime}
          placeholder={t("timePlaceholder")}
          error={!!errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.startTime}
          // @ts-expect-error
          helperText={errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.startTime?.message}
          fullWidth
        />
      </Grid>

      <Grid item xl={6} md={12} xs={12}>
        <PeepTimeField
          disabled={shouldDisableEditing}
          label={t("end")}
          value={endTime}
          onChange={setEndTime}
          placeholder={t("timePlaceholder")}
          error={!!errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.endTime}
          // @ts-expect-error
          helperText={errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.endTime?.message}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} md={!slotServiceVariant || isResourceRequired ? 6 : 12}>
        <EmployeeAutoComplete
          disabled={shouldDisableEmployeeSelection}
          value={staffId}
          onChange={handleStaffChange}
          outletId={outletId!}
          serviceVariantIdToFilterBy={slotServiceVariantId}
          zoneIdToFilterBy={area?.zone || null}
          error={!!errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.staffId}
          warning={!isRequestPending && articleSlotsConflicts.staff}
          helperText={
            articleSlotsConflicts.staff && !isRequestPending
              ? t("staffOccupied")
              : errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.staffId?.message
          }
          startIcon={
            <Heart
              onClick={() => {
                if (staffId !== 0) setIsStaffSelectedByClient(!isStaffSelectedByClient);
              }}
              sx={{ fontSize: "16px", ml: 1, cursor: "pointer" }}
              solid={isStaffSelectedByClient}
              color={isStaffSelectedByClient ? "error" : "disabled"}
            />
          }
          tooltipTitle={t("setStaffAsRequested")}
        />
      </Grid>

      {(!slotServiceVariant || isResourceRequired) && (
        <Grid item xs={12} md={6}>
          <ResourceAutoComplete
            disabled={shouldDisableEditing}
            outletId={outletId}
            serviceId={slotServiceVariant?.service?.id || null}
            value={resourceId || null}
            handleSelectValue={handleResourceChange}
            error={!!errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.resourceId}
            warning={!isRequestPending && articleSlotsConflicts.resource}
            helperText={
              articleSlotsConflicts.resource && !isRequestPending
                ? t("resourceOccupied")
                : errors?.articles?.[articleIndex]?.slots?.[slotIndex]?.resourceId?.message
            }
          />
        </Grid>
      )}
    </Grid>
  );
}

export default memo(SlotForm);
