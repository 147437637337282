import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "store/auth/authSlice";

import auth from "./auth";

function useMustBeAuthenticated(mustBeAuthenticated: boolean) {
  const { isLoading } = useSelector(selectAuth);
  const navigate = useNavigate();

  const isAuthenticated = auth.isLoggedIn && !isLoading;

  const shouldRedirect = mustBeAuthenticated ? !isAuthenticated : isAuthenticated;
  const redirectPath = mustBeAuthenticated ? "/login" : "/";

  useEffect(() => {
    if (shouldRedirect) navigate(redirectPath);
  }, [shouldRedirect, redirectPath, navigate]);
}

export default useMustBeAuthenticated;
