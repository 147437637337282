import { RootState } from "store";
import { selectAllPaymentTypes } from "store/paymentType/paymentTypesSlice";

/**
 * This Selector should be used for operations related to expenses payment methods
 */
export const selectExpensesPaymentTypes = (state: RootState) => {
  const allPaymentTypes = selectAllPaymentTypes(state) as PaymentMethodType[];

  return allPaymentTypes.filter((paymentType) => paymentType.forExpenses);
};

/**
 * This Selector should be used for operations related to POS payment methods (Checking out a sale - Checking out an appointment)
 */
export const selectPosPaymentTypes = (state: RootState) => {
  const allPaymentTypes = selectAllPaymentTypes(state) as PaymentMethodType[];

  return allPaymentTypes.filter((paymentType) => paymentType.forPos);
};

export const selectPaymentTypesByMethod = (method: "forExpenses" | "forPos") => (state: RootState) => {
  if (method === "forExpenses") {
    return selectExpensesPaymentTypes(state);
  }
  return selectPosPaymentTypes(state);
};
