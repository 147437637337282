import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { Notification } from "types/Notification";

import { SliceStatus } from "../utils";
import api from "./notificationsApi";

export const notificationAdapter = createEntityAdapter<Notification>({
  selectId: (notification) => notification.id,
});

const initialState = notificationAdapter.getInitialState<{
  status: SliceStatus;
  timestamp: Nullable<string>;
}>({
  status: SliceStatus.IDLE,
  timestamp: null,
});

const HANDLE_WEBSOCKET_UPDATES = "notifications/handleWebSocketUpdates";

export const handleWebSocketUpdates = createAction(HANDLE_WEBSOCKET_UPDATES, (notification) => {
  return { payload: { notification } };
});

export const getNotifications = createThunkFromApiWithType(
  "notifications/getNotifications",
  api.getNotifications
);

export const updateNotificationsStatus = createThunkFromApiWithType(
  "notifications/updateNotificationsStatus",
  api.updateNotificationsStatus
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(handleWebSocketUpdates, (state, action) => {
      notificationAdapter.upsertMany(state, action.payload);
    });

    reducers.addCase(getNotifications.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(
      getNotifications.fulfilled,
      (state, { payload: { notifications, timestamp } }) => {
        notificationAdapter.upsertMany(state, notifications);
        state.status = SliceStatus.IDLE;
        state.timestamp = timestamp;
      }
    );

    reducers.addCase(getNotifications.rejected, (state) => {
      state.status = SliceStatus.FAILED;
    });

    reducers.addCase(updateNotificationsStatus.fulfilled, (state, { payload }) => {
      notificationAdapter.upsertMany(state, payload);
      state.status = SliceStatus.IDLE;
    });
  },
});

export default notificationsSlice.reducer;
