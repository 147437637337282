import { Box, BoxProps, Typography, styled } from "@mui/material";
import { CheckIcon, VoucherIcon } from "assets/icon";
import { StatusChip } from "components/common";
import getFormattedPrice from "helpers/getFormattedPrice";
import { dontForwardProps } from "helpers/styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { VoucherPurchase } from "types/VoucherPurchase";

import type { VoucherPurchaseWithStatusAndRedeemableItemsTitle } from "./AvailableVouchersSection";

export enum AvailableVoucherStatus {
  Inapplicable = "Inapplicable",
  Selected = "Selected",
  Unselected = "Unselected",
  Applied = "Applied",
}

type AvailableVoucherItemProps = {
  purchasedVoucher: VoucherPurchaseWithStatusAndRedeemableItemsTitle;
  voucherStatus: AvailableVoucherStatus;
  onClick: (voucherPurchase: VoucherPurchase) => void;
  disabled?: boolean;
};

const AvailableVoucherItem = ({
  purchasedVoucher,
  onClick,
  voucherStatus,
  disabled = false,
}: AvailableVoucherItemProps) => {
  const { t } = useTranslation(["translation", "vouchers"]);

  const voucherAvailableBalance = `${t("translation:currency")} ${getFormattedPrice(
    purchasedVoucher.availableBalance
  )}`;

  const title = `${voucherAvailableBalance} ${purchasedVoucher.name}`;

  const voucherCode = ` ${t("vouchers:code")}: ${purchasedVoucher.code}`;

  const expirationDate = DateTime.fromISO(purchasedVoucher.expiresOn).toFormat("dd MMMM yyyy");
  const voucherExpirationDate = ` ${t("vouchers:expiresOn")}: ${expirationDate}`;

  const description = `${purchasedVoucher.redeemableItemsTitle}, ${voucherCode},  ${voucherExpirationDate}`;

  const handleVoucherClicked = () => {
    if (
      voucherStatus === AvailableVoucherStatus.Inapplicable ||
      voucherStatus === AvailableVoucherStatus.Applied ||
      disabled
    )
      return;

    onClick(purchasedVoucher);
  };

  const getVoucherStatusElement = () => {
    switch (voucherStatus) {
      case AvailableVoucherStatus.Inapplicable:
        return (
          <StatusChip
            size="small"
            label={t("vouchers:inapplicable")}
            statusColor="#FFEED1"
            sx={{
              color: "#FF9800",
              fontWeight: 700,
            }}
          />
        );

      case AvailableVoucherStatus.Selected:
        return <CheckIcon color="inherit" />;

      case AvailableVoucherStatus.Applied:
        return (
          <StatusChip
            size="small"
            label={t("vouchers:applied")}
            statusColor="#E4FAF7"
            sx={{
              color: "#53BFB0",
              fontWeight: 700,
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <AvailableVoucherItemBox
      voucherStatus={voucherStatus}
      onClick={handleVoucherClicked}
      disabled={disabled}
    >
      <VoucherIcon color="inherit" />

      <Box>
        <Typography variant="primary" fontWeight={700}>
          {title}
        </Typography>

        <Typography variant="secondary" display="block">
          {description}
        </Typography>
      </Box>

      <Box flexGrow={1} textAlign="end">
        {getVoucherStatusElement()}
      </Box>
    </AvailableVoucherItemBox>
  );
};

export default AvailableVoucherItem;

type AvailableVoucherItemBoxProps = BoxProps & {
  voucherStatus: AvailableVoucherStatus;
  disabled?: boolean;
};

const AvailableVoucherItemBox = styled(
  Box,
  dontForwardProps("voucherStatus")
)<AvailableVoucherItemBoxProps>(({ theme, voucherStatus, disabled }) => {
  const isSelected = voucherStatus === AvailableVoucherStatus.Selected;

  const isUnselected = voucherStatus === AvailableVoucherStatus.Unselected;

  const isInapplicable = voucherStatus === AvailableVoucherStatus.Inapplicable;

  const isApplied = voucherStatus === AvailableVoucherStatus.Applied;

  const isDisabled = isInapplicable || isApplied || disabled;

  const getTextColor = () => {
    if (isSelected) {
      return theme.palette.common.white;
    } else if (isDisabled) {
      return theme.palette.text.disabled;
    } else {
      return theme.palette.text.primary;
    }
  };

  const getBackgroundColor = () => {
    if (isSelected) {
      return theme.palette.primary.main;
    } else if (isDisabled) {
      return `${(theme.palette.bg as any).disabled} !important`;
    } else {
      return "#FBFBFB";
    }
  };

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: theme.spacing(2),

    padding: theme.spacing(2),

    borderRadius: theme.spacing(0.5),

    color: getTextColor(),

    background: getBackgroundColor(),

    cursor: isDisabled ? "not-allowed" : "pointer",

    "&:hover": {
      boxShadow: "none",
      backgroundColor: isSelected ? theme.palette.primary.dark : theme.palette.bg.secondary,
    },

    "& .MuiSvgIcon-root": {
      color: isUnselected && !isDisabled ? theme.palette.primary.main : "inherit",
    },

    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },

    // Disable selecting text, because when we click on the VoucherItem it is awkwardly selecting the text.
    MozUserSelect: "none" /* firefox */,
    WebkitUserSelect: "none" /* Safari */,
    msUserSelect: "none" /* IE*/,
    userSelect: "none" /* Standard syntax */,
  };
});
