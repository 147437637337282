import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import useCheckoutModal from "hooks/useCheckoutModal";
import usePaymentMethodTitle from "hooks/usePaymentMethodTitle";
import { useCheckoutFormContext } from "modals/CheckoutModal/CheckoutFormContext";
import { useTranslation } from "react-i18next";
import { CheckoutModalView } from "store/slices/checkout/checkoutSlice";

import InvoiceRowPriceItem from "../InvoiceRowPriceItem";

const InvoiceAdditionalPaymentsToBeAdded = () => {
  const { t } = useTranslation("checkout");

  const { checkoutModalView } = useCheckoutModal();
  const { watch, setValue } = useCheckoutFormContext();

  const { getPaymentMethodTitle, arePaymentMethodsLoading } = usePaymentMethodTitle({
    isForPos: true,
  });

  const { payments: additionalPaymentsToBeAddToInvoice, walletPayments } = watch();

  const handelRemovePayment = (paymentIndex: number) => {
    const newPayments = additionalPaymentsToBeAddToInvoice.filter(
      (_, index) => index !== paymentIndex
    );

    setValue("payments", newPayments);
  };

  if (arePaymentMethodsLoading) return <CentredSpinnerBox />;

  return (
    <>
      {additionalPaymentsToBeAddToInvoice.map((payment, index) => {
        const isPeepPayMethod = payment.paymentType === null && !!payment.peepPay;

        return (
          <InvoiceRowPriceItem
            key={`${payment.paymentType}-${index}`}
            title={getPaymentMethodTitle(payment.paymentType, isPeepPayMethod)}
            isTitleTranslated={false}
            hasBottomDivider
            color="primary"
            onRemove={() => handelRemovePayment(index)}
            amount={-Number(payment.amount)}
            shouldDisableRemove={checkoutModalView === CheckoutModalView.ConfirmSale}
          />
        );
      })}

      {walletPayments.map((walletPayment, index) => (
        <InvoiceRowPriceItem
          key={`${walletPayment.walletType}-${index}`}
          title={t(`${walletPayment.walletType.toLowerCase()}Wallet`)}
          isTitleTranslated={false}
          hasBottomDivider
          color="primary"
          onRemove={() => handelRemovePayment(index)}
          amount={-Number(walletPayment.amount)}
          shouldDisableRemove={checkoutModalView === CheckoutModalView.ConfirmSale}
        />
      ))}
    </>
  );
};

export default InvoiceAdditionalPaymentsToBeAdded;
