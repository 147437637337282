import { SearchableSelectField } from "components/common";
import usePromotionDiscountSummary from "hooks/usePromotionDiscountSummary";
import { useTranslation } from "react-i18next";
import {
  PackageVariantActivePromotion,
  ProductActivePromotion,
  ServiceVariantActivePromotion,
} from "types/ActivePromotion";
import {
  arePackageVariantActivePromotions,
  areProductActivePromotions,
  areServiceVariantActivePromotions,
} from "types/typeGuards/ActivePromotionsTypeGuards";

type CheckoutPromotionSelectFieldProps = {
  value: Nullable<number>;
  onChange: (
    value: Nullable<
      ServiceVariantActivePromotion | PackageVariantActivePromotion | ProductActivePromotion
    >
  ) => void;
  promotions:
    | ServiceVariantActivePromotion[]
    | PackageVariantActivePromotion[]
    | ProductActivePromotion[];
  arePromotionsLoading: boolean;

  disabled?: boolean;
};

function CheckoutPromotionSelectField({
  value,
  onChange,
  promotions,
  arePromotionsLoading,
  disabled = false,
}: CheckoutPromotionSelectFieldProps) {
  const { t } = useTranslation(["promotions"]);

  const { getPromotionDiscountSummary } = usePromotionDiscountSummary();

  const NO_PROMOTION = { label: t("promotions:noPromotion"), value: null };

  const promotionOptions = promotions.map((promotionItem) => ({
    label: promotionItem.name,
    value: promotionItem.promotion,
    additionalInfo: getPromotionDiscountSummary(promotionItem.discountType, promotionItem.value),
  }));

  const options = [NO_PROMOTION, ...promotionOptions];

  const handleChange = (value: number | null) => {
    if (value) {
      // I had to repeat the same logic in the following 3 if statements because of the typescript error not being able to implement type narrowing the promotionItem
      if (areServiceVariantActivePromotions(promotions)) {
        const promotion = promotions.find((promotionItem) => promotionItem.promotion === value);
        onChange(promotion || null);
      }
      if (arePackageVariantActivePromotions(promotions)) {
        const promotion = promotions.find((promotionItem) => promotionItem.promotion === value);
        onChange(promotion || null);
      }
      if (areProductActivePromotions(promotions)) {
        const promotion = promotions.find((promotionItem) => promotionItem.promotion === value);
        onChange(promotion || null);
      }
    } else {
      onChange(null);
    }
  };

  return (
    <SearchableSelectField
      label={t("promotions:promotion")}
      placeholder={t("promotions:noPromotion")}
      fullWidth
      options={options}
      areOptionsLoading={arePromotionsLoading}
      noOptionsText={t("promotions:noPromotionFound")}
      handleSelectValue={handleChange}
      value={value}
      disabled={disabled}
    />
  );
}

export default CheckoutPromotionSelectField;
