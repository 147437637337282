import { DateTime } from "luxon";
import { ClientSubscriptionPurchase } from "types/Subscription";

import { getExpirationDate } from "./getExpirationDate";

/**
 * Calculates subscription expiration date
 *
 * @param subscriptionStartDate
 * @param validityInDays
 * @returns the last valid date of the subscription
 *
 */

export const getSubscriptionExpirationDate = (
  subscriptionStartDate: ClientSubscriptionPurchase["createdAt"],
  validityInDays: ClientSubscriptionPurchase["validityInDays"]
): DateTime => {
  if (!DateTime.fromISO(subscriptionStartDate).isValid)
    new Error("Invalid subscription start date");

  const subscriptionExpirationDate = getExpirationDate(
    DateTime.fromISO(subscriptionStartDate),
    validityInDays
  );

  return subscriptionExpirationDate;
};
