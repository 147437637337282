import { StaticDateRangePicker } from "@mui/lab";
import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { StickyBottomActions } from "assets/mixins/StickyBottomActions";
import { useTranslation } from "react-i18next";

import { formatDate, matchTimePeriod, timePeriodOptions } from "../utils";

const DatePickerContent = ({
  localDateRange,
  handleDateRangeChange,
  goToDate,
  closePopover,
  handleConfirm,
  showDateRangeInputs = true,
}) => {
  const { t } = useTranslation("sales");
  return (
    <DatePickerWrapper>
      <Grid container spacing={2}>
        {showDateRangeInputs && (
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label={t("Date Range")}
              value={localDateRange}
              onChange={handleDateRangeChange}
              SelectProps={{
                renderValue: (value) => t(matchTimePeriod(value)),
              }}
            >
              {timePeriodOptions.map(([key, value]) => (
                <MenuItem value={value} key={key}>
                  {t(key)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        {showDateRangeInputs && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("startDate")}
                value={localDateRange[0] && formatDate(localDateRange[0])}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("endDate")}
                value={
                  localDateRange[1] ? formatDate(localDateRange[1]) : formatDate(localDateRange[0])
                }
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <StaticDateRangePickerStyle
            displayStaticWrapperAs="desktop"
            value={localDateRange}
            onChange={goToDate}
            // We are not using an input, but MUI expects one be default
            // If left undefined it would cause a type error
            renderInput={() => <>{null}</>}
          />
        </Grid>
      </Grid>
      <ActionsWrapper>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" onClick={closePopover}>
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={!localDateRange[1]}
              fullWidth
              variant="contained"
              onClick={handleConfirm}
            >
              {t("confirm")}
            </Button>
          </Grid>
        </Grid>
      </ActionsWrapper>
    </DatePickerWrapper>
  );
};

const ActionsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    borderTop: `1px solid ${theme.palette.common.gray}25`,
    ...StickyBottomActions(),
  },
}));

const DatePickerWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(8),
  },
}));

const StaticDateRangePickerStyle = styled(StaticDateRangePicker)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    ".css-170k9md": {
      minHeight: "220px",
    },
    ".css-1xhj18k": {
      flexDirection: "column",
      position: "relative",
    },
    ".css-3zocqb": {
      minHeight: "200px",
    },
    ".css-1n2mv2k": {
      ".MuiTypography-root": {
        width: "30px",
      },
    },
    ".css-i6bazn": {
      ".css-mvmu1r": {
        ".MuiPickersDay-root": {
          width: "30px",
          height: "30px",
        },
      },
    },
    ".css-1tape97": {
      ".css-f7iyql": {
        "> .MuiTypography-root": {
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "24px",
        },
      },
      ":last-child": {
        ".css-f7iyql": {
          justifyContent: "center !important",
          ".MuiIconButton-edgeStart": {
            position: "absolute",
            top: "17px",
            right: "18px",
          },
          ".MuiIconButton-edgeEnd": {
            display: "none",
          },
        },
      },
    },
  },
}));

export default DatePickerContent;
