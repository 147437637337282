import { getPackageVariantTotalDuration } from "helpers/getPackageVariantTotalDuration";
import { useMemo } from "react";
import type { RootState } from "store";
import {
  Package,
  PackageCategoryWithPackagesDetail,
  PackageVariantWithCategoryAndServiceVariantsWithService,
  PackageVariantWithPriceAndDuration,
  PackageVariantWithServiceVariantsAndPriceAndDuration,
  PackageWithVariantWithServiceVariantsWithService,
} from "types/Package";
import { ServiceVariant, ServiceVariantWithService } from "types/ServiceV2";
import { isDefined } from "types/typeGuards/PrimitiveTypeGuards";

import {
  selectAllPackageCategories,
  selectPackageCategoryById,
} from "../packageCategoriesV2/packageCategoriesSelectors";
import { selectAllActivePackages, selectPackageById, selectPackagesByCategoryId } from "../packagesV2/packagesSelectors";
import { selectServiceVariantByIdWithService } from "../servicesV2/servicesSelectors";
import { selectServiceVariantById } from "../serviceVariantsV2/serviceVariantsSelectors";
import { packageVariantsAdapter } from "./packageVariantsSlice";

const { selectById, selectAll } = packageVariantsAdapter.getSelectors(
  (state: RootState) => state.packageVariantsV2
);

export const selectAllPackageVariants = selectAll;

export const selectAllActivePackageVariants = (state: RootState) =>
  selectAllPackageVariants(state).filter((packageVariant) => !packageVariant.deprecated);

export const selectPackageVariantById =
  (id: string | number) =>
    (state: RootState): PackageVariantWithPriceAndDuration | undefined => {
      const packageVariant = selectById(state, id);

      if (!packageVariant) return;

      const serviceVariants: ServiceVariant[] = [];

      packageVariant.serviceVariants.forEach((serviceVariantId) => {
        const foundServiceVariant = selectServiceVariantById(serviceVariantId)(state);
        if (foundServiceVariant) serviceVariants.push(foundServiceVariant);
      });

      return {
        ...packageVariant,
        totalDuration: getPackageVariantTotalDuration(packageVariant, serviceVariants),
      };
    };

export const selectVariantsByPackageId = (packageId: string | number) => (state: RootState) => {
  const allPackageVariants = selectAllActivePackageVariants(state);

  return allPackageVariants.filter(
    (packageVariant) => packageVariant.package === Number(packageId)
  );
};

export const selectPackageByIdWithVariants = (id: number | string) => (state: RootState) => {
  const _package = selectPackageById(id)(state);
  if (!_package) return null;

  return {
    ..._package,
    variants: selectVariantsByPackageId(_package?.id || 0)(state),
  };
};

export const selectServiceVariantsWithServiceByPackageVariantId =
  (id: string | number) =>
    (state: RootState): ServiceVariantWithService[] => {
      const packageVariant = selectPackageVariantById(id)(state);

      if (!packageVariant) return [];

      const serviceVariants: ServiceVariantWithService[] = [];

      packageVariant.serviceVariants.forEach((serviceVariantId) => {
        const foundServiceVariant = selectServiceVariantByIdWithService(serviceVariantId)(state);
        if (foundServiceVariant) serviceVariants.push(foundServiceVariant);
      });

      return serviceVariants;
    };

export const selectPackageVariantByIdWithServiceVariantsAndCategory =
  (id: string | number) =>
    (state: RootState): PackageVariantWithCategoryAndServiceVariantsWithService | undefined => {
      const packageVariant = selectById(state, id);
      if (!packageVariant) return;

      const pkg = selectPackageById(packageVariant.package)(state);

      if (!pkg) return;

      const serviceVariants = selectServiceVariantsWithServiceByPackageVariantId(id)(state);

      const category = selectPackageCategoryById(pkg.category || 0)(state);
      if (!category) return;

      return {
        ...packageVariant,
        serviceVariants,
        category,
        totalDuration: getPackageVariantTotalDuration(packageVariant, serviceVariants),
      };
    };

export const selectPackageVariantByIdWithServiceVariantsAndPriceAndDuration =
  (id: string | number) =>
    (state: RootState): PackageVariantWithServiceVariantsAndPriceAndDuration | undefined => {
      const packageVariantWithPriceAndDuration = selectPackageVariantById(id)(state);
      const serviceVariants = selectServiceVariantsWithServiceByPackageVariantId(id)(state);

      if (!packageVariantWithPriceAndDuration) return;

      return {
        ...packageVariantWithPriceAndDuration,
        serviceVariants,
      };
    };

export const selectPackageWithVariantByIdWithServiceVariants =
  (id: string | number) =>
    (state: RootState): PackageWithVariantWithServiceVariantsWithService | undefined => {
      const _package = selectPackageById(id)(state);
      const packageVariants = selectAll(state).filter((variant) => variant.package === Number(id));

      if (!_package) return;
      if (!packageVariants.length) return;

      const variants = packageVariants
        .map((variant) =>
          selectPackageVariantByIdWithServiceVariantsAndPriceAndDuration(variant.id)(state)
        )
        .filter(isDefined);

      return {
        ..._package,
        variants,
      };
    };

export const selectAllActivePackagesByOutletId =
  (outletId: number | string | null) =>
    (state: RootState): Package[] => {
      const allActivePackages = selectAllActivePackages(state);

      return outletId
        ? allActivePackages.filter(
          (_package) => _package.outlets.includes(Number(outletId)) && !_package.deprecated
        )
        : allActivePackages;
    };

export const selectAllActivePackagesWithVariantsAndWithServiceVariantsByOutletId =
  (outletId: number | string | null) => (state: RootState) => {
    const allActivePackages = selectAllActivePackagesByOutletId(outletId)(state);

    return allActivePackages.map((pkg) =>
      selectPackageWithVariantByIdWithServiceVariants(pkg.id)(state)
    );
  };

export const selectAllPackageCategoriesWithServiceVariantsByOutletId =
  (outletId: number | string | null) =>
    (state: RootState): PackageCategoryWithPackagesDetail[] => {
      const allPackageCategories = selectAllPackageCategories(state);

      const categories = useMemo(() => {
        const allOutletPackagesWithDetails =
          selectAllActivePackagesWithVariantsAndWithServiceVariantsByOutletId(outletId)(state);

        return allPackageCategories.map((category) => ({
          ...category,
          packages: allOutletPackagesWithDetails
            .filter(isDefined)
            .filter((pkg) => pkg?.category === category.id),
        }));
      }, [state, allPackageCategories]);

      return categories;
    };

// TODO: Refactor This To Use The Above Selectors Effectively
export const selectAllPackageCategoriesWithPackagesAndVariantsByOutletId =
  (outletId: number | string | null) => (state: RootState) => {
    const categories = selectAllPackageCategories(state);

    return useMemo(() => {
      const outletPackages = outletId
        ? selectAllActivePackages(state).filter(
          (_package) => _package.outlets.includes(Number(outletId)) && !_package.deprecated
        )
        : selectAllActivePackages(state).filter((_package) => !_package.deprecated);

      const packagesWithVariants = outletPackages.map((_pkg) => {
        const packageVariants = selectAllActivePackageVariants(state).filter(
          (variant) => variant.package === _pkg.id
        );
        const packageVariant = packageVariants.length ? packageVariants[0] : null;

        const serviceVariants = packageVariant
          ? selectServiceVariantsWithServiceByPackageVariantId(packageVariant?.id)(state)
          : [];

        return {
          ..._pkg,
          variants: packageVariant
            ? [
              {
                ...packageVariant,
                totalDuration: getPackageVariantTotalDuration(packageVariant, serviceVariants),
              },
            ]
            : [],
        };
      });

      return categories.map((category) => ({
        ...category,
        packages: packagesWithVariants.filter(
          (_pkg) => _pkg && _pkg.category === category.id && _pkg.variants.length
        ),
      }));
    }, [categories, state]);
  };

export const selectAllPackageCategoriesWithAllData =
  (outletId?: Nullable<number>) => (state: RootState) => {
    const allCategories = selectAllPackageCategories(state);
    return allCategories.map((category) => {
      const filteredPackages = selectPackagesByCategoryId(category.id)(state)
        .filter((pkg) =>
          outletId ? pkg.outlets.includes(outletId) && !pkg.deprecated : !pkg.deprecated
        )
        .map((pkg) => {
          const filteredVariants = selectVariantsByPackageId(pkg.id)(state).filter(
            (variant) => !variant.deprecated
          );

          return {
            ...pkg,
            variants: filteredVariants.map(variant => {
              const packageVariant = selectServiceVariantsWithServiceByPackageVariantId(variant.id)(state);
              return {
                ...variant,
                totalDuration: getPackageVariantTotalDuration(variant, packageVariant),
              };
            }),
          };
        });



      return {
        ...category,
        packages: filteredPackages,
      };
    });
  };