import { Box, Typography } from "@mui/material";
import getFormattedPrice from "helpers/getFormattedPrice";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectClientById } from "store/clients/clientsSlice";
import { selectAllPermissions } from "store/myPermissions/myPermissionsSlice";
import { selectOrganization } from "store/organization/organizationSlice";
import { selectPosPaymentTypes } from "store/paymentType/paymentTypesSelectors";
import { WalletType } from "types/Checkout";

import { useCheckoutFormContext } from "../CheckoutFormContext";
import CheckoutPaymentMethod from "./CheckoutPaymentMethod";
import PeepPayPaymentMethod from "./PeepPayPaymentMethod";

const CheckoutPaymentMethods = () => {
  const { t } = useTranslation(["checkout", "invoices"]);
  const { hasPeepPay } = useSelector(selectOrganization) as Organization;

  const { setValue, watch, balance, isRefundInvoice } = useCheckoutFormContext();

  const paymentMethods = useSelector(selectPosPaymentTypes);
  const { hasPeepPaySendAccess } = useSelector(selectAllPermissions);

  const { payments, paymentAmount, existingClient, walletPayments, refundInvoice } = watch();

  const { refundPayments = [] } = refundInvoice || {};

  const client = useSelector(selectClientById(existingClient?.id || 0));

  const paidFromOnlineWallet = walletPayments
    .filter((walletPayment) => walletPayment.walletType === WalletType.Online)
    .reduce((walletPaidTotal, walletPayment) => walletPaidTotal + walletPayment.amount, 0);

  const paidFromOfflineWallet = walletPayments
    .filter((walletPayment) => walletPayment.walletType === WalletType.Offline)
    .reduce((walletPaidTotal, walletPayment) => walletPaidTotal + walletPayment.amount, 0);

  const clientOfflineWalletId = !!client ? client.offlineWallet?.id || null : null;
  const clientOnlineWalletId = !!client ? client.onlineWallet?.id || null : null;

  const shouldDisableOnlineWalletPaymentMethod =
    !client ||
    !clientOnlineWalletId ||
    client.onlineWallet?.amount < paymentAmount ||
    !paymentAmount ||
    balance <= 0;

  const shouldDisableOfflineWalletPaymentMethod =
    !client ||
    !clientOfflineWalletId ||
    balance <= 0 ||
    (!isRefundInvoice && (client.offlineWallet?.amount < paymentAmount || !paymentAmount));

  const availableOfflineClientCredit =
    !!client && client.offlineWallet ? client.offlineWallet.amount - paidFromOfflineWallet : 0;

  const availableOnlineClientCredit =
    !!client && client.onlineWallet ? client.onlineWallet.amount - paidFromOnlineWallet : 0;

  const shouldDisablePaymentMethod = !paymentAmount || balance <= 0;

  // Show peep pay method so that orgs with no peep pay access can request it
  const shouldShowPeepPayMethod =
    !!client &&
    !isRefundInvoice &&
    ((hasPeepPay && hasPeepPaySendAccess.editAccess) || !hasPeepPay);

  const handleAddPaymentMethod = (paymentMethodId: number) => {
    const newPaymentMethod = {
      paymentType: paymentMethodId,
      amount: paymentAmount,
      peepPay: null,
    };

    if (isRefundInvoice) {
      setValue("refundInvoice.refundPayments", [...refundPayments, newPaymentMethod]);
    } else {
      setValue("payments", [...payments, newPaymentMethod]);
    }
  };

  const handleAddWalletPayment = (walletId: number, walletType: WalletType) => {
    setValue("walletPayments", [
      ...walletPayments,
      {
        walletId,
        amount: paymentAmount,
        walletType,
      },
    ]);
  };

  // Refund is always to the offline wallet, so we should not show the online wallet as a refund method
  const shouldShowOnlineWalletPaymentMethod = !isRefundInvoice;

  const amountToBeRefundedToWallet = isRefundInvoice
    ? refundPayments.reduce(
        (total, refundPayment) => (refundPayment.wallet ? total + refundPayment.amount : total),
        0
      )
    : 0;

  const handleAddRefundToWallet = () => {
    const newPaymentMethod = {
      paymentType: null,
      amount: paymentAmount,
      wallet: true,
    };
    setValue("refundInvoice.refundPayments", [...refundPayments, newPaymentMethod]);
  };

  const handleClickOfflineWallet = () => {
    if (!clientOfflineWalletId) return;

    if (isRefundInvoice) {
      handleAddRefundToWallet();
      return;
    } else {
      handleAddWalletPayment(clientOfflineWalletId, WalletType.Offline);
    }
  };

  const handleClickOnlineWallet = () => {
    if (!clientOnlineWalletId) return;

    if (isRefundInvoice) {
      handleAddRefundToWallet();
      return;
    } else {
      handleAddWalletPayment(clientOnlineWalletId, WalletType.Online);
    }
  };

  const offlineWalletCredit = isRefundInvoice
    ? availableOfflineClientCredit + amountToBeRefundedToWallet
    : availableOfflineClientCredit;

  const onlineWalletCredit = isRefundInvoice
    ? availableOnlineClientCredit + amountToBeRefundedToWallet
    : availableOnlineClientCredit;

  return (
    <Box px={3} display="flex" flexDirection="column" gap={2}>
      <Typography fontWeight={700} fontSize="0.875rem">
        {t("paymentMethod")}
      </Typography>

      <Box display="flex" flexDirection="row" gap={2}>
        <CheckoutPaymentMethod
          key={t("offlineWallet")}
          title={t("offlineWallet")}
          subtitle={`${t("currency")}\u00A0${getFormattedPrice(offlineWalletCredit)}`}
          disabled={shouldDisableOfflineWalletPaymentMethod}
          handleClick={handleClickOfflineWallet}
        />

        {shouldShowOnlineWalletPaymentMethod && (
          <CheckoutPaymentMethod
            key={t("onlineWallet")}
            title={t("onlineWallet")}
            subtitle={`${t("currency")}\u00A0${getFormattedPrice(onlineWalletCredit)}`}
            disabled={shouldDisableOnlineWalletPaymentMethod}
            handleClick={handleClickOnlineWallet}
          />
        )}
      </Box>

      {shouldShowPeepPayMethod && (
        <PeepPayPaymentMethod isDisabled={shouldDisablePaymentMethod} client={client} />
      )}

      {paymentMethods.map((paymentMethod, index) => (
        <CheckoutPaymentMethod
          key={`${paymentMethod.id}-${index}`}
          title={paymentMethod.label}
          disabled={shouldDisablePaymentMethod}
          handleClick={() => handleAddPaymentMethod(paymentMethod.id)}
        />
      ))}
    </Box>
  );
};

export default CheckoutPaymentMethods;
