import http from "utils/http";

const invoiceSequencingApi = {
  getAllInvoiceSequences: async () => http.get("organizations/invoice_sequencing/"),

  getInvoiceSequencing: async (id) => http.get(`organizations/invoice_sequencing/${id}/`),

  updateInvoiceSequencing: async ({ id, data }) =>
    http.put(`organizations/invoice_sequencing/${id}/`, data),
};

export default invoiceSequencingApi;
