import { Box } from "@mui/material";
import { PeepSearchField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectAllPurchasableVouchersByOutletId,
  selectSubscriptionsLoading,
} from "store/selectors";
import { Voucher } from "types/Voucher";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import VouchersPurchasableItems from "./VouchersPurchasableItems";

const PurchasableItemsVouchersPanel = () => {
  const { t } = useTranslation(["checkout"]);

  const [searchQuery, setSearchQuery] = useState("");

  const { outletId } = usePurchasableItemsSelector();

  const vouchers = useSelector(selectAllPurchasableVouchersByOutletId(outletId || 0));

  const areVouchersLoading = useSelector(selectSubscriptionsLoading);

  const processedQuery = searchQuery.toLowerCase().trim();

  const filteredVouchers = vouchers.filter(filterByVoucherName(processedQuery));

  if (areVouchersLoading) return <CentredSpinnerBox />;

  if (!vouchers.length)
    return <EmptyGraphicSection description={t("noVouchersAvailableForTheCurrentLocation")} />;

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  return (
    <Box p={3}>
      <Box pb={3}>
        <PeepSearchField
          placeholder={t("searchByVoucherName")}
          onClear={clearSearchQuery}
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      {filteredVouchers.length ? (
        <VouchersPurchasableItems vouchers={filteredVouchers} />
      ) : (
        <EmptyGraphicSection description={t("noVouchers")} />
      )}
    </Box>
  );
};

export default PurchasableItemsVouchersPanel;

const filterByVoucherName = (searchQuery: string) => (voucher: Voucher) =>
  voucher.name.toLowerCase().includes(searchQuery);
