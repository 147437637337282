import { ExpenseType } from "types/Expense";
import http from "utils/http";

type ExpenseTypeArguments = {
  name: string;
};

type ActiveExpenseTypeResponse = {
  canBeDeleted: boolean;
};

type UpdateExpenseTypeArguments = {
  id: number;
  data: ExpenseTypeArguments;
};

const expenseTypesApi = {
  getExpenseTypes: async () => http.get<ExpenseType[]>(`expenses/expense_types/`),

  createExpenseType: async (data: ExpenseTypeArguments) =>
    http.post<ExpenseType>(`expenses/expense_types/`, data),

  updateExpenseType: async ({ id, data }: UpdateExpenseTypeArguments) =>
    http.put<ExpenseType>(`expenses/expense_types/${id}/`, data),

  deleteExpenseType: async ({ id }: { id: number }) => http.delete(`expenses/expense_types/${id}/`),

  checkActiveExpenseType: async ({ id }: { id: number }) =>
    http.get<ActiveExpenseTypeResponse>(`expenses/expense_type_active_check?expense_type_id=${id}`),
};

export default expenseTypesApi;
