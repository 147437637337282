import { ItemPrice } from "components/common";

type InvoiceArticlePriceProps = {
  originalPrice: number;
  finalPrice: number;
};

const InvoiceArticlePrice = ({ originalPrice, finalPrice }: InvoiceArticlePriceProps) => {
  return (
    <ItemPrice
      shouldDisplayZeroAsFree={false}
      originalPrice={originalPrice}
      finalPrice={finalPrice}
      alignItems="end"
      originalPriceProps={{
        fontWeight: 700,
        color: "text.label",
      }}
    />
  );
};

export default InvoiceArticlePrice;
