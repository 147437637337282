import { RootState } from "store";
import { selectOutletById } from "store/outlets/outletsSlice";

import { selectServiceVariantByIdWithService } from "../servicesV2/servicesSelectors";
import { SliceStatus } from "../utils";
import { voucherPurchasesAdapter } from "./voucherPurchasesSlice";

export const selectVoucherPurchasesLoading = (state: RootState) =>
  state.voucherPurchases.status === SliceStatus.LOADING;

export const selectVoucherPurchaseDetailsLoading = (state: RootState) =>
  state.voucherPurchases.detailStatus === SliceStatus.LOADING;

export const selectVoucherPurchasesTotalPages = (state: RootState) =>
  state.voucherPurchases.totalPages;

export const selectVoucherPurchasesState = (state: RootState) => state.voucherPurchases;

const { selectById, selectAll } = voucherPurchasesAdapter.getSelectors(selectVoucherPurchasesState);

export const selectAllVoucherPurchases = selectAll;

export const selectVoucherPurchaseById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectVoucherPurchaseWithDetailsById = (id: number | string) => (state: RootState) => {
  const voucherPurchase = selectById(state, id);

  return {
    ...voucherPurchase,
    serviceVariants:
      voucherPurchase?.serviceVariants?.map((serviceVariantId: number) =>
        selectServiceVariantByIdWithService(serviceVariantId)(state)
      ) || [],
    outlets: voucherPurchase?.outlets.map(
      (outletId: number) => selectOutletById(outletId)(state) || []
    ),
  };
};
