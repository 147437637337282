/**
 * A function that compounds words together in a grammatically correct way.
 *
 * Example: ["apple", "orange", "banana"] => "apple, orange, and banana"
 *
 * @param array array of strings you want to compound
 * @param and a divider to add to the last item
 * @returns The given array items combined into a single string divided by commas by grammar
 */
export default function andifyArray(array: string[], and: string) {
  if (array.length === 1) return array[0];

  if (array.length === 2) return `${array[0]} ${and} ${array[1]}`;

  return array.reduce((sentence, item, index) => {
    const isLastItem = index === array.length - 1;

    if (!isLastItem) return `${sentence}${item}, `;
    else return `${sentence}${and} ${item}`;
  }, "");
}
