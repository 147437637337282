import {
  Box,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActivityLogDataType } from "types/DetailsWithLogs";

import IconFrame from "./IconFrame";

type ActivityLogSectionProps = {
  activityLogSubtitle: string;
  activityLogData: ActivityLogDataType[];
};

const ActivityLogSection = ({ activityLogSubtitle, activityLogData }: ActivityLogSectionProps) => {
  const { t } = useTranslation("expenses");

  return (
    <>
      <Box py={2}>
        <Typography variant="primary" color="#848FA1" fontWeight={700} letterSpacing="0.08em">
          {activityLogSubtitle}
        </Typography>
      </Box>

      <Grid container spacing={2} pb={2}>
        <Grid item>
          <StepperBox>
            <Stepper orientation="vertical">
              {activityLogData.map((data) => (
                <Step active key={data.step}>
                  <StepLabel StepIconComponent={IconFrame} icon={data.icon}>
                    {data.stepperContent && data.stepperContent}
                  </StepLabel>

                  <StepContent>
                    {data.notes && (
                      <NotesBox>
                        <Typography color="primary" fontWeight={700} display="inline">
                          {` ${t("note")}: `}
                        </Typography>

                        <Typography fontWeight={500} display="inline">
                          {data.notes}
                        </Typography>
                      </NotesBox>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </StepperBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ActivityLogSection;

const StepperBox = styled(Box)(({ theme }) => ({
  "& .MuiStepLabel-labelContainer": {
    marginLeft: theme.spacing(2),
  },
  "& .MuiStepContent-root": {
    marginLeft: theme.spacing(2),
    borderColor: "#E6E9EC",
  },
  "& .MuiCollapse-wrapperInner": {
    display: "flex",
  },
  "& .MuiStepConnector-root": {
    marginLeft: theme.spacing(2),
  },

  "& .MuiStepConnector-line": {
    borderColor: "#E6E9EC",
  },
}));

const NotesBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.bg.secondary,
  width: "100%",
  wordBreak: "break-all",
  maxHeight: "6rem",
  overflowY: "auto",
}));
