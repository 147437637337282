import { DiscountCode } from "types/DiscountCode";
import http from "utils/http";

type DiscountCodesResponse = {
  results: DiscountCode[];
  next: Nullable<string>;
  previous: Nullable<string>;
  count: number;
  totalPages: number;
};

const discountCodesApi = {
  getDiscountCodes: async ({
    page,
    outletId,
    ordering,
    searchQuery,
  }: {
    page: number;
    outletId: number;
    ordering?: string[];
    searchQuery?: string;
  }) =>
    http.get<DiscountCodesResponse>(
      `marketing_tools/discount_codes/?page=${page}${outletId !== 0 ? `&outlets=${outletId}` : ""}${
        ordering && !!ordering.length ? `&order_by=${ordering[0]}` : ""
      }${searchQuery ? `&search=${searchQuery}` : ""}`
    ),
  createDiscountCode: async (
    discountCode: Omit<DiscountCode, "id" | "usageCount" | "deprecated" | "isActive">
  ) => http.post<DiscountCode>("marketing_tools/discount_codes/", discountCode),
  updateDiscountCode: async ({ id, ...discountCode }: { id: number; isActive: boolean }) =>
    http.put<DiscountCode>(`marketing_tools/discount_codes/${id}/`, discountCode),
  deprecateDiscountCode: async ({ id }: { id: number }) =>
    http.delete(`marketing_tools/discount_codes/${id}`),
};

export default discountCodesApi;
