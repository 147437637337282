import { Box } from "@mui/system";
import { ReactNode } from "react";

type NoAccessGraphicProps = {
  description: ReactNode;
  pt?: number;
  pb?: number;
  width?: string;
};

const NoAccessGraphic = ({
  description,
  pt = 10,
  pb = 10,
  width = "304px",
}: NoAccessGraphicProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pt={pt}
      pb={pb}
      gap={3}
    >
      <img alt="No Access graphic" src="/images/noAccessTableState.svg" width={width} />

      {description}
    </Box>
  );
};

export default NoAccessGraphic;
