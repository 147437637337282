// https://en.wikipedia.org/wiki/Areas_of_Kuwait

const kuwaitAreas = [
  {
    en: "Waha",
    ar: "الواحة",
  },
  {
    en: "Taima",
    ar: "تيماء",
  },
  {
    en: "Sulaibiya Agricultural Area",
    ar: "الصليبية المنطقة الزراعية",
  },
  {
    en: "Sulaibiya",
    ar: "الصليبية",
  },
  {
    en: "Subiya",
    ar: "الصبية",
  },
  {
    en: "South Doha / Qairawān",
    ar: "جنوب الدوحة / القيروان",
  },
  {
    en: "Sikrab",
    ar: "السكراب",
  },
  {
    en: "Salmi",
    ar: "السالمي",
  },
  {
    en: "Saad Al Abdullah City",
    ar: "مدينة سعد العبدالله",
  },
  {
    en: "Qasr",
    ar: "القصر",
  },
  {
    en: "Oyoun",
    ar: "العيون",
  },
  {
    en: "Nasseem",
    ar: "النسيم",
  },
  {
    en: "Naeem",
    ar: "النعيم",
  },
  {
    en: "Kabad",
    ar: "كبد",
  },
  {
    en: "Jahra Industrial Area",
    ar: "الجهراء المنطقة الصناعية",
  },
  {
    en: "Jahra",
    ar: "الجهراء",
  },
  {
    en: "Bar Jahra",
    ar: "بر الجهراء",
  },
  {
    en: "Amghara",
    ar: "أمغرة",
  },
  {
    en: "Al Nahda / East Sulaibikhat",
    ar: "النهضة / شرق الصليبخات",
  },
  {
    en: "Abdali",
    ar: "العبدلي",
  },
  {
    en: "Abu Halifa",
    ar: "أبو حليفة",
  },
  {
    en: "Abdullah Port",
    ar: "ميناء عبد الله",
  },
  {
    en: "Ahmadi",
    ar: "الأحمدي",
  },
  {
    en: "Ali As-Salim",
    ar: "علي صباح السالم",
  },
  {
    en: "Aqila",
    ar: "العقيلة",
  },
  {
    en: "Bar Al Ahmadi",
    ar: "بر الأحمدي",
  },
  {
    en: "Bneidar",
    ar: "بنيدر",
  },
  {
    en: "Dhaher",
    ar: "بر الأحمدي",
  },
  {
    en: "Fahaheel",
    ar: "الفحيحيل",
  },
  {
    en: "Fahad Al-Ahmad",
    ar: "فهد الأحمد",
  },
  {
    en: "Hadiya",
    ar: "هدية",
  },
  {
    en: "Jaber Al-Ali",
    ar: "جابر العلي",
  },
  {
    en: "Jawaher Al Wafra",
    ar: "جواخير الوفرة",
  },
  {
    en: "Jilei'a",
    ar: "الجليعة",
  },
  {
    en: "Khairan",
    ar: "الخيران",
  },
  {
    en: "Mahbula",
    ar: "المهبولة",
  },
  {
    en: "Mangaf",
    ar: "المنقف",
  },
  {
    en: "Miqwa'",
    ar: "المقوع",
  },
  {
    en: "New Khairan City",
    ar: "مدينة الخيران الجديدة",
  },
  {
    en: "New Wafra",
    ar: "الوفرة الجديدة",
  },
  {
    en: "Nuwaiseeb",
    ar: "النويصيب",
  },
  {
    en: "Riqqa",
    ar: "الرقة",
  },
  {
    en: "Sabah Al-Ahmad City",
    ar: "مدينة صباح الأحمد",
  },
  {
    en: "Sabah Al Ahmad Sea City",
    ar: "مدينة صباح الأحمد البحرية",
  },
  {
    en: "Sabahiya",
    ar: "الصباحية",
  },
  {
    en: "Shu'aiba (North & South)",
    ar: "الشعيبة",
  },
  {
    en: "South Sabahiya",
    ar: "جنوب الصباحية",
  },
  {
    en: "Wafra",
    ar: "الوفرة",
  },
  {
    en: "Zoor",
    ar: "الزور",
  },
  {
    en: "Abu Al Hasaniya",
    ar: "أبو الحصانية",
  },
  {
    en: "Abu Futaira",
    ar: "أبو فطيرة",
  },
  {
    en: "Adān",
    ar: "العدان",
  },
  {
    en: "Al Qurain",
    ar: "القرين",
  },
  {
    en: "Al-Qusour",
    ar: "القصور",
  },
  {
    en: "Fintās",
    ar: "الفنطاس",
  },
  {
    en: "Funaitīs",
    ar: "الفنيطيس",
  },
  {
    en: "Misīla",
    ar: "المسيلة",
  },
  {
    en: "Mubarak Al-Kabeer",
    ar: "مبارك الكبير",
  },
  {
    en: "Sabah Al-Salem",
    ar: "صباح السالم",
  },
  {
    en: "Sabhān",
    ar: "صبحان",
  },
  {
    en: "South Wista",
    ar: "جنوب وسطي",
  },
  {
    en: "Wista",
    ar: "وسطي",
  },
  {
    en: "Anjafa",
    ar: "أنجفة",
  },
  {
    en: "Bayān",
    ar: "بيان",
  },
  {
    en: "Bi'da",
    ar: "البدع",
  },
  {
    en: "Hawally",
    ar: "حولي",
  },
  {
    en: "Hittin",
    ar: "حطين",
  },
  {
    en: "Jabriya",
    ar: "الجابرية",
  },
  {
    en: "Maidan Hawalli",
    ar: "ميدان حولي",
  },
  {
    en: "Mishrif",
    ar: "مشرف",
  },
  {
    en: "Mubarak Al-Jabir",
    ar: "مبارك الجابر",
  },
  {
    en: "Nigra",
    ar: "النقرة",
  },
  {
    en: "Rumaithiya",
    ar: "الرميثية",
  },
  {
    en: "Salam",
    ar: "سلام",
  },
  {
    en: "Salmiya",
    ar: "السالمية",
  },
  {
    en: "Salwa",
    ar: "سلوى",
  },
  {
    en: "Sha'ab",
    ar: "الشعب",
  },
  {
    en: "Shuhada",
    ar: "الشهداء",
  },
  {
    en: "Siddiq",
    ar: "الصديق",
  },
  {
    en: "South Surra",
    ar: "جنوب السرة",
  },
  {
    en: "Zahra",
    ar: "الزهراء",
  },
  {
    en: "Abdulla Al-Salem",
    ar: "ضاحية عبد الله السالم",
  },
  {
    en: "Adailiya",
    ar: "العديلية",
  },
  {
    en: "Bnaid Al-Qar",
    ar: "بنيد القار",
  },
  {
    en: "Daʿiya",
    ar: "الدعية",
  },
  {
    en: "Dasma",
    ar: "الدسمة",
  },
  {
    en: "Doha",
    ar: "الدوحة",
  },
  {
    en: "Doha Port",
    ar: "ميناء الدوحة",
  },
  {
    en: "Faiha'",
    ar: "الفيحاء",
  },
  {
    en: "Failaka",
    ar: "فيلكا",
  },
  {
    en: "Granada (Kuwait)",
    ar: "غرناطة",
  },
  {
    en: "Jaber Al-Ahmad City",
    ar: "مدينة جابر الأحمد",
  },
  {
    en: "Jibla",
    ar: "جِبْلَة",
  },
  {
    en: "Kaifan",
    ar: "كيفان",
  },
  {
    en: "Khaldiya",
    ar: "الخالدية",
  },
  {
    en: "Mansūriya",
    ar: "المنصورية",
  },
  {
    en: "Mirgab",
    ar: "المرقاب",
  },
  {
    en: "Nahdha",
    ar: "النهضة",
  },
  {
    en: "North West Sulaibikhat",
    ar: "شمال غرب الصليبيخات",
  },
  {
    en: "Nuzha",
    ar: "النزهة",
  },
  {
    en: "Qadsiya",
    ar: "القادسية",
  },
  {
    en: "Qurtuba",
    ar: "قرطبة",
  },
  {
    en: "Rawda",
    ar: "الروضة",
  },
  {
    en: "Shamiya",
    ar: "الشامية",
  },
  {
    en: "Sharq",
    ar: "شرق",
  },
  {
    en: "Shuwaikh",
    ar: "الشويخ",
  },
  {
    en: "Shuwaikh Industrial Area",
    ar: "الشويخ الصناعية",
  },
  {
    en: "Shuwaikh Port",
    ar: "ميناء الشويخ",
  },
  {
    en: "Sulaibikhat",
    ar: "الصليبخات",
  },
  {
    en: "Surra",
    ar: "السرة",
  },
  {
    en: "Umm an Namil Island",
    ar: "جزيرة ام النمل",
  },
  {
    en: "Yarmouk",
    ar: "اليرموك",
  },
  {
    en: "Qibla",
    ar: "قبلة",
  },
  {
    en: "Al Andalous",
    ar: "الأندلس",
  },
  {
    en: "Al Masayel",
    ar: "المسايل",
  },
];

export default kuwaitAreas;
