import http from "utils/http";

const closedDatesApi = {
  getAllClosedDates: async () => http.get("organizations/closed_dates/"),

  getClosedDate: async (id) => http.get(`organizations/closed_dates/${id}/`),

  createClosedDate: async (data) =>
    http.post("organizations/closed_dates/", data),

  updateClosedDate: async (id, data) =>
    http.put(`organizations/closed_dates/${id}/`, data),

  removeClosedDate: async (id) =>
    http.delete(`organizations/closed_dates/${id}/`),
};

export default closedDatesApi;
