import http from "utils/http";

const paymentTypeApi = {
  getAllPaymentTypes: async () => http.get("organizations/payment_types/"),

  getPaymentType: async (id) => http.get(`organizations/payment_types/${id}/`),

  createPaymentType: async (data) =>
    http.post("organizations/payment_types/", data),

  updatePaymentType: async (id, data) =>
    http.put(`organizations/payment_types/${id}/`, data),

  removePaymentType: async (id) =>
    http.delete(`organizations/payment_types/${id}/`),
};

export default paymentTypeApi;
