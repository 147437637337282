import { Button } from "@mui/material";
import { CopyIcon } from "assets/icon";
import { useTranslation } from "react-i18next";

type CopyLinkButtonProps = {
  uuid: string;
};

const CopyLinkButton = ({ uuid }: CopyLinkButtonProps) => {
  const { t } = useTranslation("home");

  const peepPayLink = `${process.env.REACT_APP_PEEP_PAY_URL}/link/${uuid}`;

  return (
    <Button
      startIcon={<CopyIcon viewBox="0 0 16 16" />}
      variant="text"
      sx={{ fontWeight: 500, fontSize: "14px" }}
      onClick={() => navigator.clipboard.writeText(peepPayLink)}
    >
      {t("copyLink")}
    </Button>
  );
};

export default CopyLinkButton;
