import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import api from "store/slices/packageVariantsV2/packageVariantsApi";
import createThunkFromApiWithType from "store/utils/createThunkFromApiWithType";
import { PackageVariant } from "types/Package";

import { createPackage, deletePackage, updatePackage } from "../packagesV2/packagesSlice";
import { SliceStatus } from "../utils";

export const packageVariantsAdapter = createEntityAdapter<PackageVariant>({
  selectId: (serviceVariant) => serviceVariant.id,
});

const initialState = packageVariantsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getAllPackageVariants = createThunkFromApiWithType(
  "packageVariantsV2/getAllPackageVariants",
  api.getAllPackageVariants,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const serviceVariantsSlice = createSlice({
  name: "packageVariantsV2",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers.addCase(getAllPackageVariants.pending, (state) => {
      state.status = SliceStatus.LOADING;
    });

    reducers.addCase(getAllPackageVariants.fulfilled, (state, { payload }) => {
      packageVariantsAdapter.upsertMany(state, payload);

      state.status = SliceStatus.IDLE;
    });

    reducers.addCase(getAllPackageVariants.rejected, (state) => {
      return { ...state, status: SliceStatus.FAILED };
    });

    reducers.addCase(createPackage.fulfilled, (state, { payload }) => {
      const { variants } = payload;

      packageVariantsAdapter.upsertMany(state, variants);
    });

    reducers.addCase(updatePackage.fulfilled, (state, { payload }) => {
      const { variants } = payload;
      packageVariantsAdapter.upsertMany(state, variants);
    });

    reducers.addCase(deletePackage.fulfilled, (state, { payload: { variants } }) => {
      packageVariantsAdapter.upsertMany(state, variants);

      state.status = SliceStatus.IDLE;
    });
  },
});

export default serviceVariantsSlice.reducer;
