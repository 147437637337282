/* eslint-disable no-console */
function report(message: string, meta?: {}) {
  console.log(message, meta);
}

export default report;

/**
 * To implement later (sentry or whatever)
 */
