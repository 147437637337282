import { TextField, TextFieldProps } from "@mui/material";
import { isNaN } from "@sentry/utils";
import getValueWithinAllowedRange from "helpers/getValueWithinAllowedRange";
import { ChangeEvent, useRef, useState } from "react";

export type NumberFieldProps = Partial<Omit<TextFieldProps, "onChange" | "value">> & {
  value: number;
  onChange: (value: number) => void;

  min?: number;
  max?: number;

  fallbackValue?: number;

  maxDecimalPlaces?: number;
  prefix?: string;
};

/**
 * A Piece of Wisdom On How To Save A Life:
 * Dear Developers, this is a super sensitive component.
 *
 * Ruining this component will ruin the inventory system and POS "Sale" system. and probably your life..
 *
 * Please do NOT touch this component unless you know what you are doing and have consulted Anfal "if she did not quit"
 *
 * It's guarded and possessed by the evil demons of the 7th layer of the numerical hell.
 * SO BEWARE! You have been warned.
 *
 * Evil laugh..
 * - Anfal, The Wickedly Evil and The Great 💎
 */

const NumberField = ({
  value,
  onChange,

  min = 0,
  max = Infinity,
  fallbackValue = min,

  maxDecimalPlaces = 0,
  prefix = "",
  ...textFieldProps
}: NumberFieldProps) => {
  if (maxDecimalPlaces < 0) throw new Error("maxDecimalPlaces must be greater than or equal to 0");

  if (max < min)
    throw new Error(
      `The max  value must be greater than or equal to the min value. max: ${max} min: ${min}`
    );

  if (fallbackValue < min || fallbackValue > max)
    throw new Error("The fallbackValue must be within the min and max range");

  const [displayedValue, setDisplayedValue] = useState((value || "").toString());

  const numberInputRef = useRef<HTMLInputElement>(null);

  const isFocused = numberInputRef?.current === document.activeElement;

  const [triggerRerender, setTriggerRerender] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valueWithPrefix = event.target.value;
    setTriggerRerender(true);

    const valueAsString = valueWithPrefix.replace(prefix, "").trim();

    if (maxDecimalPlaces > 0 && valueAsString === ".") {
      updateValue(valueAsString);
      return;
    }

    // if negative number is allowed, allow typing - then numbers (eg -3)
    if (min < 0 && valueAsString === "-") {
      updateValue(valueAsString);
      return;
    }

    if (valueAsString === "") {
      updateValue(valueAsString);
      return;
    }

    // removes non-numerical characters and shit and also prevents entering more than one decimal point
    const valueAsNumber = Number(valueAsString);

    // prevent infinity or NaN
    if (isNaN(valueAsNumber) || !isFinite(valueAsNumber)) return;

    const digitsAfterTheDecimalPoint = valueAsString.split(".")[1]?.length || 0;

    if (digitsAfterTheDecimalPoint > maxDecimalPlaces) return;

    // force the value to be within the max and min range
    const valueWithinAllowedRange = getValueWithinAllowedRange(valueAsNumber, min, max);

    setDisplayedValue(valueAsString);
    onChange(valueWithinAllowedRange);
  };

  const resetToFallbackValue = () => {
    const valueAsNumber = Number(value);
    // force the value to be within the max and min range
    const valueWithinAllowedRange = getValueWithinAllowedRange(valueAsNumber, min, max);
    if (valueWithinAllowedRange !== value) onChange(fallbackValue);

    // if the displayed value is empty, set it to the fallback value
    if (!displayedValue && triggerRerender) onChange(fallbackValue);

    setTriggerRerender(false);
  };

  const updateValue = (newValidValue: string) => {
    //  We have three special cases other than the default case:
    switch (newValidValue) {
      // 1. empty string, which means the user deleted the value
      case "":
        //  we don't call onChange here because we rely on the onBlur event to set the fallback value
        setDisplayedValue("");
        return;

      // 2. negative sign, which means the user wants to enter a negative number
      case "-":
        onChange(min);
        setDisplayedValue("-");
        return;

      // 3. decimal point, which means the user wants to enter a decimal number
      case ".":
        //  avoid decimal point for negative numbers, because we don't know what to do with it.
        if (min < 0) return;

        onChange(min);
        setDisplayedValue(`${min}.`);
        return;

      default:
        setDisplayedValue(newValidValue);
        onChange(Number(newValidValue));
        return;
    }
  };

  return (
    <TextField
      inputRef={numberInputRef}
      onBlur={resetToFallbackValue}
      {...textFieldProps}
      value={`${prefix}${isFocused ? displayedValue : formatPrice(value, maxDecimalPlaces)}`}
      onChange={handleChange}
    />
  );
};

export default NumberField;

// TODO: use this instead of getFormattedPrice one day
/**
 * @param value The price to format

 * @returns A string of the formatted price
 */
export function formatPrice(value: number, maxDecimalPlaces = 0) {
  if (typeof value !== "string" && typeof value !== "number")
    throw new Error(` value "${value}" must be a string or a number but got ${typeof value} `);

  // Had to add the check because when we delete the field and it's empty and it did not fallback to zero for some reason, it is considered NaN and it would crash my soul and the system - Anfal The Wickedly Evil and The Great 💎
  if (value && isNaN(value)) throw new Error(`value "${value}" must be a number`);

  const isValidNumber = value && !isNaN(value);

  const numericValue = isValidNumber ? Number(value) : 0;

  return value % 1 === 0 ? String(value) : numericValue.toFixed(maxDecimalPlaces);
}
