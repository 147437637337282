import { PackageVariant } from "types/Package";
import { ServiceVariant, ServiceVariantWithService } from "types/ServiceV2";

export const getPackageVariantTotalDuration = (
  packageVariant: PackageVariant,
  serviceVariants: ServiceVariant[] | ServiceVariantWithService[]
): number => {
  const servicesDuration = serviceVariants.map((variant) => Number(variant.duration || 0));

  if (packageVariant.parallel) return Math.max(...servicesDuration);
  else
    return servicesDuration.reduce(
      (totalDuration, serviceVariantDuration) =>
        Number(totalDuration) + Number(serviceVariantDuration),
      0
    );
};
