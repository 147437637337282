import { Box, TextField } from "@mui/material";
import ConfirmationDialogModal from "components/common/ConfirmationDialogModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type ConfirmCancelAppointmentModalProps = {
  onClose: () => void;
  handleCancelAppointment: (cancellationReason: string) => void;
  isOpen: boolean;
  isLoading: boolean;
};

const ConfirmCancelAppointmentModal = ({
  onClose,
  handleCancelAppointment,
  isLoading,
  isOpen,
}: ConfirmCancelAppointmentModalProps) => {
  const { t } = useTranslation(["translation", "appointments"]);
  const [cancelationReason, setCancelationReason] = useState("");

  const handleConfirmCancelAppointment = () => {
    handleCancelAppointment(cancelationReason);
    onClose();
  };

  const handleDoNotCancelAppointment = () => {
    setCancelationReason("");
    onClose();
  };

  return (
    <ConfirmationDialogModal
      hasTitleOnTop
      title={t("appointments:confirmAppointmentCancellation")}
      description={
        <Box pt={1}>
          <TextField
            fullWidth
            multiline
            inputProps={{ maxLength: 2000 }}
            minRows={3}
            label={t("translation:cancelationReason")}
            placeholder={t("translation:cancelationReasonPlaceholder")}
            value={cancelationReason}
            onChange={(e) => setCancelationReason(e.target.value)}
          />
        </Box>
      }
      isOpen={isOpen}
      onConfirmButtonClick={handleConfirmCancelAppointment}
      onCancelButtonClick={handleDoNotCancelAppointment}
      confirmButtonLabel={t("translation:yesCancel")}
      cancelButtonLabel={t("translation:dontCancel")}
      cancelButtonVariant="outlined"
      isConfirmButtonLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default ConfirmCancelAppointmentModal;
