import { Box } from "@mui/material";
import { PeepSearchField } from "components/common";
import CentredSpinnerBox from "components/common/CentredSpinnerBox";
import EmptyGraphicSection from "components/common/EmptySectionGraphic";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPackageCategoriesLoading } from "store/slices/packageCategoriesV2/packageCategoriesSelectors";
import { selectAllPackageCategoriesWithServiceVariantsByOutletId } from "store/slices/packageVariantsV2/packageVariantsSelectors";
import {
  PackageCategoryWithPackagesDetail,
  PackageWithVariantWithServiceVariantsWithService,
} from "types/Package";
import { PurchasableItemOption } from "types/PurchasableItem";
import { translateColor } from "utils/categoryColors";

import { usePurchasableItemsSelector } from "../../PurchasableItemsSelectorContext";
import PurchasableItemCategory from "../PurchasableItemCategory";
import PackagesPurchasableItems from "./PackagesPurchasableItems";

const PurchasableItemsPackagesPanel = () => {
  const { t } = useTranslation(["checkout", "packages"]);

  const [searchQuery, setSearchQuery] = useState("");

  const {
    outletId,
    shouldAllowSelectAllCategoryItems,
    selectedItems,
    onSelectAllCategoryItems,
    shouldDisableLoyaltyIneligibleItems,
  } = usePurchasableItemsSelector();

  const packageCategories = useSelector(
    selectAllPackageCategoriesWithServiceVariantsByOutletId(outletId || 0)
  );

  const arePackageCategoriesLoading = useSelector(selectPackageCategoriesLoading);

  const processedQuery = searchQuery.toLowerCase().trim();

  const filteredCategories = packageCategories
    .map(filterCategoryByPackageName(processedQuery))
    .filter(filterOutEmptyCategories);

  if (arePackageCategoriesLoading) return <CentredSpinnerBox />;

  if (!packageCategories.length)
    return <EmptyGraphicSection description={t("noPackagesAvailableForTheCurrentLocation")} />;

  return (
    <Box p={3}>
      <Box pb={3}>
        <PeepSearchField
          placeholder={t("checkout:searchByPackageName")}
          onClear={() => setSearchQuery("")}
          fullWidth
          value={searchQuery}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(e.target.value);
          }}
        />
      </Box>

      {filteredCategories.length ? (
        filteredCategories.map((packageCategory) => {
          const totalPackagesWithinCategoryCount = packageCategory.packages.length;

          const totalSelectedPackagesWithinCategoryCount = packageCategory.packages.filter(
            (packageItem) => {
              const packageVariantIds = packageItem.variants.map((variant) => variant.id).flat();

              const selectedPackageVariantIds = packageVariantIds.some((variantId) =>
                selectedItems?.[PurchasableItemOption.Package]?.includes(variantId)
              );

              return selectedPackageVariantIds;
            }
          ).length;

          // is not all category items are loyalty eligible, then we should not allow select all category items
          const shouldDisableSelectAllCategoryItemsButton =
            shouldDisableLoyaltyIneligibleItems &&
            !packageCategory.packages.every((_package) =>
              _package.variants.every((variant) => !variant.isLoyaltyEligible)
            );

          return (
            <PurchasableItemCategory
              key={`${packageCategory.id}-${packageCategory.name}`}
              name={packageCategory.name as string}
              color={translateColor(packageCategory.color)}
              totalItemsDescription={`${packageCategory.packages.length} ${t("packages:packages")}`}
              items={<PackagesPurchasableItems packages={packageCategory.packages} />}
              shouldAllowSelectAllCategoryItems={shouldAllowSelectAllCategoryItems}
              shouldDisableSelectAllCategoryItemsButton={shouldDisableSelectAllCategoryItemsButton}
              areAllItemsSelected={
                totalPackagesWithinCategoryCount === totalSelectedPackagesWithinCategoryCount
              }
              handleClickSelectAllCategoryItems={
                shouldAllowSelectAllCategoryItems && onSelectAllCategoryItems
                  ? () => {
                      // The function onSelectAllCategoryItems expects an array of variants, so we need to flatten the array of packages
                      const packageVariants = packageCategory.packages
                        .map((packageItem) => packageItem.variants)
                        .flat();

                      onSelectAllCategoryItems(packageVariants, PurchasableItemOption.Package);
                    }
                  : undefined
              }
            />
          );
        })
      ) : (
        <EmptyGraphicSection description={t("noPackages")} />
      )}
    </Box>
  );
};

export default PurchasableItemsPackagesPanel;

const filterOutEmptyCategories = (category: PackageCategoryWithPackagesDetail) =>
  !!category.packages.length;

const filterOutEmptyPackages = (_package: PackageWithVariantWithServiceVariantsWithService) =>
  !!_package.variants.length;

const filterByPackageNameOrCode =
  (searchQuery: string) => (_package: PackageWithVariantWithServiceVariantsWithService) =>
    _package.name.toLowerCase().includes(searchQuery);

const filterCategoryByPackageName =
  (searchQuery: string) => (category: PackageCategoryWithPackagesDetail) => {
    if (!searchQuery) return category;

    const filteredPackagesByNameOrCode = category.packages
      .filter(filterByPackageNameOrCode(searchQuery))
      .filter(filterOutEmptyPackages);

    return {
      ...category,
      packages: filteredPackagesByNameOrCode,
    };
  };
