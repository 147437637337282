import { DepositIcon } from "assets/icon";
import { ActionChip } from "components/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Client } from "types/Client";

import DepositFormModal from "./DepositForm/DepositFormModal";

type AddDepositChipProps = {
  appointmentId: number;
  client: Client;
};

const AddDepositChip = ({ appointmentId, client }: AddDepositChipProps) => {
  const { t } = useTranslation("deposits");

  const [isDepositModalOpen, setIsDepositModalOpen] = useState<boolean>(false);

  return (
    <>
      <ActionChip
        icon={<DepositIcon fontSize="small" sx={{ fill: "#4048D6", stroke: "#4048D6" }} />}
        title={t("deposit")}
        applyActionTitle={t("add")}
        handleApplyAction={() => setIsDepositModalOpen(true)}
        hasAppliedAction={false}
        gap={1}
      />

      <DepositFormModal
        appointmentId={appointmentId}
        isDepositModalOpen={isDepositModalOpen}
        setIsDepositModalOpen={setIsDepositModalOpen}
        client={client}
      />
    </>
  );
};

export default AddDepositChip;
