import { DateTime } from "luxon";

/**
 * This function checks if the value is a valid ISO date string
 * @param value - the value to check
 * @returns boolean
 */

const isStringIsoDate = (value: any) => {
  if (typeof value !== "string") return false;

  return DateTime.fromISO(value).isValid;
};
export default isStringIsoDate;
